<div class="form-wrapper" [formGroup]="airRiskMitigationForm">
    <dtm-ui-select-field
        formControlName="strategicMitigationMeasure"
        [placeholder]="'dtmAdminLibPermits.airRiskMitigation.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.airRiskMitigation.airRiskStrategicMitigationMeasuresLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="risc.value" *ngFor="let risc of AirRiskMitigationType | keyvalue">
            {{ "dtmAdminLibPermits.airRiskMitigation.airRiskMitigationMeasureValueLabel" | transloco : { value: risc.value } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="airRiskMitigationForm.controls.strategicMitigationMeasure; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        formControlName="tacticalAirRiskMitigationMeasure"
        [placeholder]="'dtmAdminLibPermits.airRiskMitigation.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.airRiskMitigation.tacticalAirRiskMitigationMeasuresLabel" | transloco }}</label>
        <p class="select-field-option-header">
            {{ "dtmAdminLibPermits.airRiskMitigation.tacticalAirRiskMitigationMeasuresHeader" | transloco : { type: MissionType.VLOS } }}
        </p>
        <dtm-ui-select-option [value]="TacticalAirRiskMitigationType.VlosSeeAndAvoid">
            {{
                "dtmAdminLibPermits.airRiskMitigation.tacticalRiskMitigationMeasureValueLabel"
                    | transloco : { value: TacticalAirRiskMitigationType.VlosSeeAndAvoid }
            }}
        </dtm-ui-select-option>
        <p class="select-field-option-header">
            {{ "dtmAdminLibPermits.airRiskMitigation.tacticalAirRiskMitigationMeasuresHeader" | transloco : { type: MissionType.BVLOS } }}
        </p>
        <dtm-ui-select-option [value]="risc" *ngFor="let risc of bvlosTacticalRiskMitigationMeasures">
            {{ "dtmAdminLibPermits.airRiskMitigation.tacticalRiskMitigationMeasureValueLabel" | transloco : { value: risc } }}
        </dtm-ui-select-option>

        <div
            class="field-error"
            *dtmUiFieldHasError="airRiskMitigationForm.controls.tacticalAirRiskMitigationMeasure; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-admin-lib-containment-level-reached-control formControlName="containmentReachedLevel">
        <label>{{ "dtmAdminLibPermits.airRiskMitigation.reachedLevelLabel" | transloco }}</label>
        <div
            class="field-error"
            *dtmUiFieldHasError="airRiskMitigationForm.controls.containmentReachedLevel; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-admin-lib-containment-level-reached-control>

    <dtm-ui-select-field [multiple]="true" [formControl]="airRiskMitigationForm.controls.competencies">
        <label>{{ "dtmAdminLibPermits.airRiskMitigation.pilotHeaderLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let competency of competencies$ | ngrxPush" [value]="competency">
            {{ competency.name }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="airRiskMitigationForm.controls.competencies; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-additional-competencies
        formControlName="additionalCompetencies"
        [label]="'dtmAdminLibPermits.airRiskMitigation.additionalPilotCompetenciesLabel' | transloco"
    ></dtm-ui-additional-competencies>
    <dtm-ui-additional-competencies
        formControlName="staffCompetencies"
        [label]="'dtmAdminLibPermits.airRiskMitigation.staffCompetenciesLabel' | transloco"
    ></dtm-ui-additional-competencies>
</div>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibPermits.airRiskMitigation.requiredField" | transloco }}
</ng-template>
