<ng-container *ngrxLet="isProcessing$ as isProcessing">
    <div class="main-container" *ngIf="profile$ | ngrxPush as profile; else selectOperatorTemplate">
        <dtm-admin-lib-profile-top (statusChange)="changeOperatorStatus($event)" [profile]="profile"></dtm-admin-lib-profile-top>
        <div class="content">
            <dtm-admin-lib-profile-details [profile]="profile"></dtm-admin-lib-profile-details>
            <dtm-admin-lib-messages-tile
                [profileId]="profile.id"
                (writeNewMessageOpen)="writeNewMessage(profile)"
            ></dtm-admin-lib-messages-tile>
            <dtm-admin-lib-insurance-policy-tile [profile]="profile"></dtm-admin-lib-insurance-policy-tile>
            <dtm-admin-lib-owner-tile [profile]="profile"></dtm-admin-lib-owner-tile>
            <dtm-admin-lib-administrator-list
                *ngIf="profile.type !== OperatorType.Personal && profile.administrators && profile.administrators.length"
                [administrators]="profile.administrators"
            ></dtm-admin-lib-administrator-list>
            <dtm-admin-lib-operator-details-tile [profile]="profile"></dtm-admin-lib-operator-details-tile>
            <dtm-admin-lib-qualifications
                [qualificationType]="QualificationType.OperationalAuthorizations"
                [titleTile]="'dtmAdminLibOperator.operatorProfile.qualificationsTile.operationalAuthorizationsTitle' | transloco"
                [qualifications]="profile.operationalAuthorizations"
                (qualificationAdd)="addOperationalAuthorization(profile)"
                (qualificationManage)="changeOperationalAuthorization($event, profile)"
                (qualificationRemove)="removeOperationalAuthorization($event, profile)"
            ></dtm-admin-lib-qualifications>
            <dtm-admin-lib-qualifications
                *ngIf="profile.pilotId"
                [qualificationType]="QualificationType.PilotCompetencies"
                [titleTile]="'dtmAdminLibOperator.operatorProfile.qualificationsTile.pilotCompetenciesTitle' | transloco"
                [qualifications]="profile.pilotCompetencies"
                (qualificationAdd)="addPilotCompetency(profile)"
                (qualificationManage)="changePilotCompetency($event, profile)"
                (qualificationRemove)="removePilotCompetency($event, profile)"
                (qualificationsSuspendAll)="suspendAllPilotCompetencies(profile)"
            ></dtm-admin-lib-qualifications>
            <dtm-admin-lib-foreign-competencies
                *ngIf="profile.competencyConfirmations?.length"
                [competencies]="profile.competencyConfirmations"
                (download)="downloadForeignCompetencyDocument($event)"
                (competencyAccept)="acceptCompetencyDocument($event)"
                (competencyReject)="rejectCompetencyDocument($event)"
            ></dtm-admin-lib-foreign-competencies>
            <dtm-admin-lib-attorney-powers-tile
                *ngIf="profile.attorneyPowers && profile.attorneyPowers.length"
                [attorneyPowers]="profile.attorneyPowers"
                (update)="updateAttorneyPower($event, profile)"
                (download)="downloadAttorneyPowerDocument($event, profile)"
            ></dtm-admin-lib-attorney-powers-tile>
            <dtm-admin-lib-administrative-fee-tile
                *ngIf="profile.administrativeFee"
                [administrativeFee]="profile.administrativeFee"
                (download)="downloadAdministrativeFeeDocument(profile)"
            >
            </dtm-admin-lib-administrative-fee-tile>
            <dtm-admin-lib-other-information-tile
                [otherInformation]="profile.otherInformation"
                (otherInformationAdd)="addOtherInformation()"
                (otherInformationEdit)="editOtherInformation($event)"
                (otherInformationDelete)="deleteOtherInformation($event)"
            ></dtm-admin-lib-other-information-tile>
            <dtm-admin-lib-change-log-tile
                [type]="ChangeLogType.Operator"
                [identifier]="profile.operatorNumber"
            ></dtm-admin-lib-change-log-tile>
            <div class="footer-buttons">
                <button type="button" (click)="deleteOperator(profile)" class="button-tertiary">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    <span>{{ "dtmAdminLibOperator.operatorProfile.removeOperator.buttonLabel" | transloco }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #selectOperatorTemplate>
    <dtm-admin-lib-select-operator-hint></dtm-admin-lib-select-operator-hint>
</ng-template>
