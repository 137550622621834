import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    BasicDataModel,
    DialogService,
    ErrorMode,
    Operator,
    Permit,
    PermitUavModel,
    SpecificPermitFlightWizardSteps,
    UavDetailsModel,
    UploadedFile,
} from "@dtm-frontend/shared/ui";
import { WizardActions } from "@dtm-frontend/shared/ui/wizard";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { combineLatest, map, switchMap } from "rxjs";
import { first, tap } from "rxjs/operators";
import { PermitsErrorType } from "../../../models/permits.models";
import { PermitsActions } from "../../../state/permits.actions";
import { PermitsState } from "../../../state/permits.state";
import { KmlPreviewDialogComponent } from "../../shared-form-section-components/kml-preview-dialog/kml-preview-dialog.component";
import { SPECIFIC_PERMIT_FLIGHT_WIZARD_ID } from "../specific-permit-flight-wizard.models";

interface SpecificPermitFlightRequestContentComponentState {
    basicData: BasicDataModel | undefined;
    permit: Permit | undefined;
    selectedOperator: Operator | undefined;
    uavModels: PermitUavModel[] | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-specific-permit-flight-request",
    templateUrl: "./specific-permit-flight-request-content.component.html",
    styleUrls: ["../../styles/shared-permit-wizard-content-styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SpecificPermitFlightRequestContentComponent implements OnInit, OnDestroy {
    protected readonly SPECIFIC_PERMIT_FLIGHT_WIZARD_ID = SPECIFIC_PERMIT_FLIGHT_WIZARD_ID;
    protected readonly SpecificPermitFlightWizardSteps = SpecificPermitFlightWizardSteps;
    protected readonly ErrorMode = ErrorMode;
    protected readonly dtmLocations$ = this.store.select(PermitsState.dtmLocations);
    protected readonly caaUsers$ = this.store.select(PermitsState.caaUsers);
    protected readonly existingPermitDetails$ = this.store.select(PermitsState.permit);
    protected readonly uavModels$ = this.localStore.selectByKey("uavModels");
    protected readonly selectedOperator$ = this.localStore.selectByKey("selectedOperator");
    protected readonly permit$ = this.localStore.selectByKey("permit");
    protected readonly isProcessing$ = this.store.select(PermitsState.isProcessing);
    protected readonly areErrors$ = combineLatest([
        this.store.select(PermitsState.capabilitiesError),
        this.store.select(PermitsState.operatorListError),
        this.store.select(PermitsState.specificPermitsError),
    ]).pipe(map((errors) => errors.some((error) => error)));
    protected readonly operators$ = this.store.select(PermitsState.operators);
    protected readonly competencies$ = this.store.select(PermitsState.competencies);

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<SpecificPermitFlightRequestContentComponentState>,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            basicData: undefined,
            permit: undefined,
            selectedOperator: undefined,
            uavModels: undefined,
        });

        this.store.dispatch([
            new WizardActions.SetActiveStep(SPECIFIC_PERMIT_FLIGHT_WIZARD_ID, SpecificPermitFlightWizardSteps.BasicInfo),
            new WizardActions.EnableSteps(SPECIFIC_PERMIT_FLIGHT_WIZARD_ID, [SpecificPermitFlightWizardSteps.BasicInfo]),
        ]);
    }

    public ngOnInit() {
        this.assignUavModels();
    }

    public ngOnDestroy() {
        this.store.dispatch(PermitsActions.ClearSpecificPermitDetails);
    }

    protected findOperatorByText(searchText: string): void {
        this.store.dispatch(new PermitsActions.GetOperatorList(searchText));
    }

    protected assignOperator(operator: Operator): void {
        this.localStore.patchState({ selectedOperator: operator });
    }

    protected retryFetchData(): void {
        const editedPermitId = this.route.snapshot.params.id;

        if (this.store.selectSnapshot(PermitsState.specificPermitsError) && editedPermitId) {
            this.store.dispatch(new PermitsActions.GetSpecificPermitDetails(editedPermitId));

            return;
        }

        this.store.dispatch(new PermitsActions.RetryFetchData());
    }

    protected setActiveStep(step: SpecificPermitFlightWizardSteps): void {
        this.store.dispatch(new WizardActions.SetActiveStep(SPECIFIC_PERMIT_FLIGHT_WIZARD_ID, step));
    }

    protected enableSteps(steps: SpecificPermitFlightWizardSteps[]): void {
        this.store.dispatch(new WizardActions.EnableSteps(SPECIFIC_PERMIT_FLIGHT_WIZARD_ID, steps));
    }

    protected storeBasicData(basicData: BasicDataModel): void {
        this.localStore.patchState({ basicData: basicData });

        this.setActiveStep(SpecificPermitFlightWizardSteps.UavDetails);
        this.enableSteps([SpecificPermitFlightWizardSteps.UavDetails]);
    }

    protected previewKmlFile(file: UploadedFile): void {
        this.store
            .dispatch(new PermitsActions.GetKmlPreview(file))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.store.selectSnapshot(PermitsState.kmlFileForPreviewError)) {
                    this.toastService.error(this.transloco.translate("dtmAdminLibPermits.container.cannotGetKmlForPreviewErrorMessage"));

                    return;
                }

                this.dialogService.open(KmlPreviewDialogComponent, {
                    data: this.store.selectSnapshot(PermitsState.kmlFileForPreview),
                });
            });
    }

    protected storeUavDetails(uavDetails: UavDetailsModel): void {
        const basicDataState = this.localStore.selectSnapshotByKey("basicData");

        if (!basicDataState) {
            return;
        }

        this.localStore.patchState({ permit: { basicData: basicDataState, uavDetails } });

        this.setActiveStep(SpecificPermitFlightWizardSteps.Summary);
        this.enableSteps([SpecificPermitFlightWizardSteps.Summary]);
    }

    protected addOrUpdatePermit(): void {
        const permitState = this.localStore.selectSnapshotByKey("permit");

        if (!permitState) {
            return;
        }

        const existingPermitId = this.store.selectSnapshot(PermitsState.permit)?.id;
        if (existingPermitId) {
            this.updatePermit(permitState, existingPermitId);

            return;
        }
        this.addPermit(permitState);
    }

    protected goBackToList() {
        this.router.navigate(["permits"]);
    }

    private addPermit(permit: Permit) {
        this.store
            .dispatch(new PermitsActions.AddPermit(permit))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(PermitsState.addPermitError);

                if (error) {
                    let errorMessage = this.transloco.translate("dtmAdminLibPermits.specificPermitRequestContent.addPermitErrorMessage");
                    if (error.type === PermitsErrorType.ForbiddenAssuranceAndIntegrityLevel) {
                        errorMessage = this.transloco.translate(
                            "dtmAdminLibPermits.specificPermitRequestContent.forbiddenAssuranceAndIntegrityLevelError"
                        );
                    }
                    this.toastService.error(errorMessage);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmAdminLibPermits.specificPermitRequestContent.addPermitSuccessMessage")
                );
                this.goBackToList();
            });
    }

    private updatePermit(permit: Permit, permitId: string) {
        this.store
            .dispatch(new PermitsActions.UpdatePermit(permit, permitId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(PermitsState.updatePermitError);

                if (error) {
                    let errorMessage = this.transloco.translate("dtmAdminLibPermits.specificPermitRequestContent.updatePermitErrorMessage");
                    if (error.type === PermitsErrorType.ForbiddenAssuranceAndIntegrityLevel) {
                        errorMessage = this.transloco.translate(
                            "dtmAdminLibPermits.specificPermitRequestContent.forbiddenAssuranceAndIntegrityLevelError"
                        );
                    }

                    this.toastService.error(errorMessage);

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmAdminLibPermits.specificPermitRequestContent.updatePermitSuccessMessage")
                );
                this.goBackToList();
            });
    }

    private assignUavModels() {
        this.existingPermitDetails$
            .pipe(
                RxjsUtils.filterFalsy(),
                first(),
                switchMap((permit) => this.store.dispatch(new PermitsActions.GetOperatorList(permit.basicData.operator.number))),
                switchMap(() => this.store.select(PermitsState.operators)),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((operators) => this.localStore.patchState({ uavModels: operators[0].models }));

        this.selectedOperator$
            .pipe(
                RxjsUtils.filterFalsy(),
                tap((operator) => {
                    if (!operator.models) {
                        return;
                    }

                    this.localStore.patchState({ uavModels: operator.models });
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
