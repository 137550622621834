import {
    Address,
    OperationScenarioCategory,
    OperatorAndPilotQualification,
    OperatorStatus,
    OperatorType,
    PhoneNumber,
    QualificationStatus,
} from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import {
    AdministrativeFee,
    Administrator,
    AssociationInfo,
    AttorneyPower,
    CompanyInfo,
    ForeignCompetencyStatus,
    ForeignCompetencyType,
    LegalGuardian,
    LegalGuardianDetailsUpdate,
    OperatorDetails,
    OperatorInfo,
    OperatorNumber,
    OtherInformation,
    Owner,
} from "../models/operator.models";

export interface LegalGuardianResponseBody {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    address: Address;
    dateOfBirth: string;
    nationality: string;
    signer?: {
        name: string;
        lastName: string;
        dateOfBirth: string;
        personalIdentifier: string;
        signedAt: string;
    };
}

export interface LegalGuardianUpdateRequest {
    address: Address;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    dateOfBirth: string;
    nationality: string;
}

interface OwnerResponseBody extends Owner {
    legalAge?: boolean;
}

interface QualificationResponseBody {
    id: string;
    status: QualificationStatus;
    statusReason?: string;
    operationScenario: OperationScenarioResponseBody;
    expirationDate: string;
}

interface OperationScenarioResponseBody {
    id: string;
    name: string;
    category: OperationScenarioCategory;
}

export interface AttorneyPowerAdministrativeFeeResponseBody {
    feeConfirmationId: string;
    declarationOfExemption: boolean;
    filename: string;
}

interface ForeignCompetencyDocumentResponseBody {
    id: string;
    pilotId: string;
    type: ForeignCompetencyType;
    file: {
        id: string;
        name: string;
        mediaType: string;
    };
    validUntil: string;
    status: ForeignCompetencyStatus;
    statusReason?: string;
}

export interface GetOperatorDetailsResponseBody {
    id: string;
    name: string;
    logoUrl: string;
    status: OperatorStatus;
    suspensionReason: string | null;
    operatorNumber: OperatorNumber | string;
    type: OperatorType;
    insurancePolicyNumber?: string;
    insurancePolicyExpirationDate?: string;
    pilotId: string | null;
    pilotNumber?: string;
    pilot?: boolean;
    owner?: OwnerResponseBody;
    address?: Address;
    operationalAuthorizations: QualificationResponseBody[];
    pilotCompetencies?: QualificationResponseBody[];
    registrator?: OwnerResponseBody;
    companyInfo?: CompanyInfo;
    attorneyPowers?: AttorneyPower[];
    attorneyPowerAdministrativeFee?: AttorneyPowerAdministrativeFeeResponseBody;
    associationInfo?: AssociationInfo;
    otherInformation?: OtherInformation[];
    competencyConfirmations?: ForeignCompetencyDocumentResponseBody[];
    administrators?: Administrator[];
}

export function convertLegalGuardianResponseBodyToLegalGuardian(response: LegalGuardianResponseBody): LegalGuardian {
    return {
        ...response,
        signer: response.signer
            ? {
                  lastName: response.signer.lastName,
                  firstName: response.signer.name,
                  dateOfBirth: new Date(response.signer.dateOfBirth),
                  signedAt: new Date(response.signer.signedAt),
                  personalIdentifier: response.signer.personalIdentifier,
              }
            : undefined,
        dateOfBirth: new Date(response.dateOfBirth),
    };
}

export function convertLegalGuardianDetailsUpdateToLegalGuardianUpdateRequest(
    legalGuardian: LegalGuardianDetailsUpdate
): LegalGuardianUpdateRequest {
    return {
        ...legalGuardian,
        dateOfBirth: DateUtils.getISOStringDate(legalGuardian.dateOfBirth.toISOString()),
    };
}

function convertOperatorDetailsResponseBodyToOwner(response: GetOperatorDetailsResponseBody): Owner {
    if (!response.owner) {
        return response.registrator as Owner;
    }

    return {
        userId: response.owner.userId,
        firstName: response.owner.firstName,
        lastName: response.owner.lastName,
        dateOfBirth: response.owner.dateOfBirth,
        nationality: response.owner.nationality,
        phoneNumber: response.owner.phoneNumber,
        email: response.owner.email,
        status: response.owner.status,
        isLegalAge: response.owner.legalAge,
    };
}

function convertQualificationResponseBodyToOperatorAndPilotQualifications(
    response: QualificationResponseBody[] = []
): OperatorAndPilotQualification[] {
    return (
        response.map((qualification) => ({
            id: qualification.id,
            category: qualification.operationScenario.category,
            expirationDate: new Date(qualification.expirationDate),
            name: qualification.operationScenario.name,
            status: qualification.status,
            statusReason: qualification.statusReason,
        })) ?? []
    );
}

function convertOperatorDetailsResponseBodyToOperatorInfo(response: GetOperatorDetailsResponseBody): OperatorInfo {
    let operatorInfo;

    if (response.address) {
        operatorInfo = {
            name: response.name,
            address: response.address,
        };
    } else if (response.companyInfo) {
        operatorInfo = {
            ...response.companyInfo,
        };
    } else if (response.associationInfo) {
        operatorInfo = {
            ...response.associationInfo,
        };
    }

    return operatorInfo as OperatorInfo;
}

function convertAttorneyPowerAdministrativeFeeResponseBodyToAdministrativeFee(
    response: AttorneyPowerAdministrativeFeeResponseBody
): AdministrativeFee {
    return {
        feeConfirmationId: response.feeConfirmationId,
        isDeclarationOfExemption: response.declarationOfExemption,
        filename: response.filename,
    };
}

export function convertGetOperatorDetailsResponseBodyToOperatorDetails(response: GetOperatorDetailsResponseBody): OperatorDetails {
    return {
        id: response.id,
        status: response.status,
        suspensionReason: response.suspensionReason,
        insurancePolicyNumber: response.insurancePolicyNumber,
        insurancePolicyExpirationDate: response.insurancePolicyExpirationDate,
        pilotId: response.pilotId ?? null,
        pilotNumber: response.pilotNumber,
        owner: convertOperatorDetailsResponseBodyToOwner(response),
        operatorNumber: typeof response.operatorNumber === "string" ? response.operatorNumber : response.operatorNumber.number,
        secret: typeof response.operatorNumber === "string" ? undefined : response.operatorNumber.secret,
        type: response.type,
        name: response.name,
        operatorInfo: convertOperatorDetailsResponseBodyToOperatorInfo(response),
        operationalAuthorizations: convertQualificationResponseBodyToOperatorAndPilotQualifications(response.operationalAuthorizations),
        pilotCompetencies: convertQualificationResponseBodyToOperatorAndPilotQualifications(response.pilotCompetencies),
        attorneyPowers: response.attorneyPowers,
        administrativeFee: response.attorneyPowerAdministrativeFee
            ? convertAttorneyPowerAdministrativeFeeResponseBodyToAdministrativeFee(response.attorneyPowerAdministrativeFee)
            : undefined,
        otherInformation: response.otherInformation ?? [],
        competencyConfirmations:
            response.competencyConfirmations?.map((competency) => ({
                id: competency.id,
                status: competency.status,
                statusReason: competency.statusReason,
                type: competency.type,
                expirationDate: new Date(competency.validUntil),
                file: { name: competency.file.name, id: competency.file.id },
                pilotId: competency.pilotId,
            })) ?? [],
        administrators: response.administrators,
    };
}
