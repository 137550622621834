import { HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import {
    Assignee,
    AttachmentFile,
    ConversationCategoryCode,
    MessageRecipient,
    MessagesError,
    MessagesErrorType,
    OperatorsThread,
    ReceivedMessage,
} from "@dtm-frontend/shared/ui";
import {
    GetOperatorDetailsResponseBody,
    convertGetOperatorDetailsResponseBodyToOperatorDetails,
} from "../../../shared/services/shared-api.converters";
import {
    AddNewMessage,
    ConversationAssignee,
    ConversationCapabilities,
    ConversationThreadsFilterParams,
    NewThread,
    OperatorCapabilities,
    Thread,
    ThreadsWithPages,
} from "../conversation.models";

const DEFAULT_THREADS_LIST_SIZE = 10;

export interface GetThreadsResponseBody {
    totalPages: number;
    totalElements: number;
    size: number;
    content: ThreadsResponseBody[];
    number: number;
    sort: {
        empty: boolean;
        unsorted: boolean;
        sorted: boolean;
    };
    pageable: {
        size: number;
        page: number;
        sort: string;
    };
    numberOfElements: number;
    first: boolean;
    last: boolean;
    empty: boolean;
}

export interface ThreadsResponseBody {
    id: string;
    createdAt: string;
    modifiedAt: string;
    read: boolean;
    closed: boolean;
    name: string;
    hasAttachments: boolean;
    assignee: Assignee | null;
    category: ConversationCategoryCode;
    stakeholder: MessageRecipient;
}

export interface GetConversationCapabilitiesResponseBody {
    categories: ConversationCategoryCode[];
    assignedCategories: ConversationCategoryCode[];
    assignees: ConversationAssigneeResponseBody[];
}

export interface GetOperatorCapabilitiesResponseBody {
    operator: GetOperatorDetailsResponseBody;
    categories: ConversationCategoryCode[];
    assignees: ConversationAssigneeResponseBody[];
}

export interface GetMessagesByThreadResponseBody {
    id: string;
    subject: string;
    content: string;
    createdAt: string;
    sender: {
        userId: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
    };
    recipient: MessageRecipient | null;
    threadId: string;
    attachments?: AttachmentFile[];
}

interface ConversationAssigneeResponseBody {
    userId: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
}

export interface NewThreadRequestPayload {
    subject: string;
    content: string;
    recipient: MessageRecipient;
    category: ConversationCategoryCode;
    attachmentIds?: string[];
}

export interface AddNewMessageToThreadRequestPayload {
    subject: string;
    content: string;
    recipient: MessageRecipient;
    attachmentIds?: string[];
}

export function convertGetThreadsResponseBodyToThreads(response: GetThreadsResponseBody): ThreadsWithPages {
    const newContent = [...response.content].map(
        (thread: ThreadsResponseBody): Thread => ({
            id: thread.id,
            createdAt: thread.createdAt,
            modifiedAt: thread.modifiedAt,
            isRead: thread.read,
            isClosed: thread.closed,
            name: thread.name,
            hasAttachments: thread.hasAttachments,
            assignee: thread.assignee,
            category: thread.category,
            stakeholder: thread.stakeholder,
        })
    );

    return {
        content: newContent,
        pages: { totalElements: response.totalElements, size: response.size, number: response.number, empty: response.empty },
    };
}
export function convertGetThreadsResponseBodyToOperatorThreads(response: GetThreadsResponseBody): OperatorsThread[] {
    const content = response.content;

    return content.map((thread: ThreadsResponseBody) => ({
        id: thread.id,
        modifiedAt: thread.modifiedAt,
        isRead: thread.read,
        isClosed: thread.closed,
        name: thread.name,
        assignee: thread.assignee,
        stakeholderId: thread.stakeholder.id,
        category: thread.category,
    }));
}

function getAssigneesMap(assignees: ConversationAssigneeResponseBody[]): Map<string, ConversationAssignee> {
    return new Map<string, ConversationAssignee>(
        assignees.map((assignee) => [
            assignee.userId,
            { avatarUrl: assignee.avatarUrl, firstName: assignee.firstName, lastName: assignee.lastName },
        ])
    );
}

export function convertGetOperatorCapabilitiesResponseBodyToOperatorCapabilities(
    response: GetOperatorCapabilitiesResponseBody
): OperatorCapabilities {
    const operator = convertGetOperatorDetailsResponseBodyToOperatorDetails(response.operator);

    return { operator, assignees: getAssigneesMap(response.assignees), categories: response.categories };
}

export function convertGetMessagesByThreadResponseBodyToReceivedMessages(response: GetMessagesByThreadResponseBody[]): ReceivedMessage[] {
    return response.map((message) => ({
        id: message.id,
        subject: message.subject,
        content: message.content,
        createdAt: message.createdAt,
        sender: message.sender,
        recipient: message.recipient,
        threadId: message.threadId,
        attachments: message.attachments,
    }));
}

export function convertConversationCapabilitiesResponseBodyToConversationCapabilities(
    response: GetConversationCapabilitiesResponseBody
): ConversationCapabilities {
    return {
        categories: response.categories,
        assignees: getAssigneesMap(response.assignees),
        assignedCategories: response.assignedCategories,
    };
}

export function convertNewThreadToNewThreadRequestPayload(message: NewThread): NewThreadRequestPayload {
    const attachmentIds: string[] | undefined = message.attachmentIdsList;

    const body: NewThreadRequestPayload = {
        subject: message.subject,
        content: message.content,
        recipient: message.recipient,
        category: message.category,
    };

    if (attachmentIds?.length) {
        body.attachmentIds = attachmentIds;
    }

    return body;
}

export function convertNewMessageToAddNewMessageToThreadRequestPayload(message: AddNewMessage): AddNewMessageToThreadRequestPayload {
    const attachmentIds: string[] | undefined = message.attachmentIds;
    const body: AddNewMessageToThreadRequestPayload = {
        subject: message.subject,
        content: message.content,
        recipient: message.recipient,
    };

    if (attachmentIds?.length) {
        body.attachmentIds = attachmentIds;
    }

    return body;
}

export function getThreadsParams(passedParams: ConversationThreadsFilterParams): HttpParams {
    let params = new HttpParams()
        .set("page", `${passedParams.page ?? 0}`)
        .set("size", `${passedParams.pageSize || DEFAULT_THREADS_LIST_SIZE}`);

    if (passedParams.categories?.length) {
        params = params.append("categories", passedParams.categories);
    }

    if (passedParams.searchByText) {
        params = params.append("searchPhrase", passedParams.searchByText);
    }

    if (passedParams.assignment !== undefined) {
        params = params.append("assigned", passedParams.assignment);
    }

    if (passedParams.read !== undefined) {
        params = params.append("read", passedParams.read);
    }

    if (passedParams.closed !== undefined) {
        params = params.append("closed", passedParams.closed);
    }

    if (passedParams.stakeholderId !== undefined) {
        params = params.append("stakeholderId", passedParams.stakeholderId);
    }

    return params;
}

export function getOperatorCapabilitiesParams(operatorId: string, searchPhrase?: string): HttpParams | null {
    if (!searchPhrase) {
        return null;
    }

    return new HttpParams().set("searchPhrase", searchPhrase);
}

export function convertGetMessagesErrorResponseToMessagesError(error: HttpErrorResponse): MessagesError {
    switch (error.status) {
        case HttpStatusCode.NotFound:
            return { type: MessagesErrorType.NotFound };
        default:
            return { type: MessagesErrorType.Unknown };
    }
}
