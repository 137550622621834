import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperatorPilotOtherInformation } from "../../../shared/models/pilot-and-operator.models";

interface PilotOperatorOtherInformationTileComponentState {
    otherInformationList: OperatorPilotOtherInformation[];
}

@Component({
    selector: "dtm-web-app-lib-pilot-operator-other-information-tile[otherInformationList]",
    templateUrl: "./pilot-operator-other-information-tile.component.html",
    styleUrls: ["./pilot-operator-other-information-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotOperatorOtherInformationTileComponent {
    @Input() public set otherInformationList(value: OperatorPilotOtherInformation[] | undefined) {
        this.localStore.patchState({ otherInformationList: value ?? [] });
    }

    protected readonly otherInformationList$ = this.localStore.selectByKey("otherInformationList");

    constructor(private readonly localStore: LocalComponentStore<PilotOperatorOtherInformationTileComponentState>) {
        localStore.setState({ otherInformationList: [] });
    }
}
