<ng-container *ngrxLet="{ qualifications: qualifications$, canManageOperator: canManageOperator$ } as vm">
    <mat-card *ngIf="vm.qualifications.length; else noQualificationsTemplate">
        <dtm-ui-card-header>
            {{ "dtmWebAppLibShared.operatorQualifications.operatorQualificationsHeader" | transloco }}

            <ng-container additionalActions>
                <button [matMenuTriggerFor]="actions" class="button-icon" type="button" (click)="$event.stopPropagation()">
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
                <mat-menu #actions="matMenu">
                    <button type="button" mat-menu-item *ngIf="vm.canManageOperator" (click)="navigateToGetAuthorizations()">
                        <dtm-ui-icon name="add"></dtm-ui-icon>
                        {{ "dtmWebAppLibShared.operatorQualifications.getQualificationsButtonLabel" | transloco }}
                    </button>
                    <button type="button" mat-menu-item (click)="operatorQualificationsShow.next()">
                        <dtm-ui-icon name="information"></dtm-ui-icon>
                        {{ "dtmWebAppLibShared.operatorQualifications.moreInformationLabel" | transloco }}
                    </button>
                </mat-menu>
            </ng-container>
        </dtm-ui-card-header>

        <div class="qualifications">
            <dtm-ui-qualification-badge *ngFor="let qualification of vm.qualifications" [qualification]="qualification">
            </dtm-ui-qualification-badge>
        </div>
    </mat-card>
    <ng-template #noQualificationsTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary" *ngIf="!vm.canManageOperator; else noQualificationsWithActionsTemplate">
            <img imageSlot src="assets/images/no-results.svg" [alt]="'dtmUi.noResults.imageAltText' | transloco" />
            <ng-container titleSlot>{{
                "dtmWebAppLibShared.operatorQualifications.noQualificationsToShowMessage" | transloco
            }}</ng-container>
        </dtm-ui-empty-state>
    </ng-template>
</ng-container>

<ng-template #noQualificationsWithActionsTemplate>
    <dtm-web-app-lib-pilot-operator-empty-card
        [headerText]="'dtmWebAppLibShared.operatorQualifications.operatorQualificationsHeader' | transloco"
        [headerButtonText]="'dtmWebAppLibShared.operatorQualifications.moreInformationLabel' | transloco"
        [actionButtonText]="'dtmWebAppLibShared.operatorQualifications.getQualificationsButtonLabel' | transloco"
        (headerButtonClicked)="operatorQualificationsShow.next()"
        (actionButtonClicked)="navigateToGetAuthorizations()"
        isButtonVisible
    >
        <p textSlot>
            {{ "dtmWebAppLibShared.operatorQualifications.noQualificationInfo" | transloco }}
        </p>
        <img imageSlot src="assets/images/operator-qualifications-empty-state.svg" />
    </dtm-web-app-lib-pilot-operator-empty-card>
</ng-template>
