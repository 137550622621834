import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, firstValueFrom, of } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";
import { NULL_VALUE, SINGLE_SELECT_VALUES } from "../../../shared";
import {
    ConversationFilterFormKeys,
    ConversationThreadFilterValue,
    ConversationThreadsFilterParams,
    NewThreadAssignment,
} from "../../services/conversation.models";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "../../services/conversation.resolver";
import { ConversationActions } from "../../state/conversation.actions";
import { ConversationState } from "../../state/conversation.state";

interface ConversationContainerComponentState {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    filtersQuery: ConversationThreadFilterValue | undefined;
    areFiltersApplied: boolean;
}

interface ConversationQueryParams {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    categories?: string;
    searchByText?: string;
    assignment?: string;
    read?: string;
    closed?: string;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-conversation-container",
    templateUrl: "./conversation-container.component.html",
    styleUrls: ["./conversation-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ConversationContainerComponent implements OnInit, OnDestroy {
    public readonly assignees$ = this.store.select(ConversationState.conversationAssignees);
    public readonly userAssignedCategories$ = this.store.select(ConversationState.userAssignedCategories);
    public readonly threadsList$ = this.store.select(ConversationState.threads);
    public readonly isThreadsListLoading$ = this.store.select(ConversationState.isThreadsListLoading);
    public readonly threadsError$ = this.store.select(ConversationState.threadsError);
    public readonly pagination$ = this.store.select(ConversationState.threadsPages);
    public readonly processingThreadsAssignments$ = this.store.select(ConversationState.processingThreadsAssignments);
    public readonly categories$ = this.store.select(ConversationState.conversationCategories).pipe(RxjsUtils.filterFalsy());
    public readonly initialFilters$ = this.getInitialFilters().pipe(RxjsUtils.filterFalsy(), first());
    public readonly filtersApplied$ = this.localStore.selectByKey("areFiltersApplied");
    public readonly storedAvatarPictures$ = this.store.select(ConversationState.storedAvatarPictures);

    constructor(
        private readonly store: Store,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly localStore: LocalComponentStore<ConversationContainerComponentState>,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {
        localStore.setState({
            [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM],
            filtersQuery: undefined,
            areFiltersApplied: false,
        });
    }

    public async ngOnInit() {
        const initialFilters = await firstValueFrom(this.initialFilters$.pipe(untilDestroyed(this)));
        this.changeFilters(initialFilters);
        this.refreshList();
    }

    public ngOnDestroy() {
        this.store.dispatch(new ConversationActions.ClearConversationData());
    }

    public changePage(event: PageEvent) {
        this.localStore.patchState({
            [PAGE_NUMBER_QUERY_PARAM]: event?.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: event?.pageSize,
        });

        this.navigateByParams();
    }

    public changeAssignee(assignment: NewThreadAssignment) {
        this.store
            .dispatch(new ConversationActions.AssignThread(assignment))
            .pipe(
                switchMap(() => this.store.select(ConversationState.changeThreadAssignmentError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (error) {
                    this.showThreadAssignmentError();
                } else {
                    this.refreshList();
                }
            });
    }

    public refreshList() {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: ConversationThreadsFilterParams) => {
            if (!Object.keys(queryParams).length) {
                return;
            }

            this.store.dispatch(new ConversationActions.GetThreads(queryParams));
        });
    }

    public changeFilters(formValue: ConversationThreadFilterValue) {
        this.localStore.patchState({ filtersQuery: formValue });
        this.navigateByParams(true);
    }

    public changeAreFiltersApplied(value: boolean) {
        this.localStore.patchState({ areFiltersApplied: value });
    }

    public navigateByParams(shouldResetPage?: boolean) {
        const categories = this.localStore.selectSnapshotByKey("filtersQuery")?.[ConversationFilterFormKeys.Categories]?.join(",");
        const searchByText = this.localStore.selectSnapshotByKey("filtersQuery")?.[ConversationFilterFormKeys.SearchByText];
        const assignmentFilters = this.localStore.selectSnapshotByKey("filtersQuery")?.[ConversationFilterFormKeys.Assignment];
        const readFilter = this.localStore.selectSnapshotByKey("filtersQuery")?.[ConversationFilterFormKeys.Read];
        const closedFilter = this.localStore.selectSnapshotByKey("filtersQuery")?.[ConversationFilterFormKeys.Closed];
        const pageIndex = shouldResetPage ? 0 : this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM);

        if (shouldResetPage) {
            this.localStore.patchState({ [PAGE_NUMBER_QUERY_PARAM]: pageIndex });
        }

        let params: ConversationQueryParams = {
            [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
        };

        if (categories?.length) {
            params = { ...params, categories };
        }

        if (searchByText?.length) {
            params = { ...params, searchByText };
        }

        if (assignmentFilters) {
            params = { ...params, assignment: assignmentFilters };
        }

        if (readFilter) {
            params = { ...params, read: readFilter };
        }

        if (closedFilter) {
            params = { ...params, closed: closedFilter };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    private showThreadAssignmentError() {
        this.toastrService.error(this.translocoService.translate("dtmAdminLibConversation.threadsContainer.threadAssignmentFailedMessage"));
    }

    private getInitialFilters(): Observable<ConversationThreadFilterValue> {
        const initialFilters: ConversationThreadFilterValue = {
            categories: this.route.snapshot.queryParams.categories?.split(",") ?? [],
            searchByText: this.route.snapshot.queryParams.searchByText ?? "",
            assignment: this.route.snapshot.queryParams.assignment ?? NULL_VALUE,
            read: this.route.snapshot.queryParams.read ?? NULL_VALUE,
            closed: this.route.snapshot.queryParams.closed ?? NULL_VALUE,
        };

        const areFiltersApplied = !!Object.values(initialFilters).flat().filter(Boolean).length;

        if (areFiltersApplied) {
            return of(initialFilters);
        }

        return this.userAssignedCategories$.pipe(
            RxjsUtils.filterFalsy(),
            map((userCategories) => {
                initialFilters[ConversationFilterFormKeys.Categories] = userCategories;
                initialFilters[ConversationFilterFormKeys.Closed] = SINGLE_SELECT_VALUES.False;

                return initialFilters;
            })
        );
    }
}
