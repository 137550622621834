import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { QualificationStatus } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OtherInformation } from "../../../models/operator.models";

interface OtherInformationTileComponentState {
    areActionsAvailable: boolean;
    otherInformation: OtherInformation[];
}

@Component({
    selector: "dtm-admin-lib-other-information-tile",
    templateUrl: "./other-information-tile.component.html",
    styleUrls: ["./other-information-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OtherInformationTileComponent {
    @Input() public set areActionsAvailable(value: BooleanInput) {
        this.localStore.patchState({ areActionsAvailable: coerceBooleanProperty(value) });
    }
    @Input() public set otherInformation(value: OtherInformation[] | undefined) {
        this.localStore.patchState({ otherInformation: value ?? [] });
    }
    @Output() public readonly otherInformationAdd = new EventEmitter<void>();
    @Output() public readonly otherInformationEdit = new EventEmitter<OtherInformation>();
    @Output() public readonly otherInformationDelete = new EventEmitter<string>();

    protected readonly areActionsAvailable$ = this.localStore.selectByKey("areActionsAvailable");
    protected readonly otherInformation$ = this.localStore.selectByKey("otherInformation");

    constructor(private readonly localStore: LocalComponentStore<OtherInformationTileComponentState>) {
        localStore.setState({ areActionsAvailable: true, otherInformation: [] });
    }

    protected readonly QualificationStatus = QualificationStatus;
}
