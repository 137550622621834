<dtm-ui-wizard-step-wrapper
    (next)="validateFormAndGoToNextStep()"
    [title]="'dtmAdminLibPermits.associationPermitDetails.permitDetailsStepHeader' | transloco"
    [stepsAmount]="3"
    [stepNumber]="2"
    [isBackButtonVisible]="true"
    [nextButtonLabel]="'dtmAdminLibPermits.associationPermitDetails.goToSummaryLabel' | transloco"
    (back)="back.emit()"
    dtmUiInvalidFormScrollable
>
    <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
        <form [formGroup]="permitDetailsForm">
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibPermits.associationPermitDetails.permitNumberLabel" | transloco }}</label>
                <input formControlName="permitNumber" matInput type="text" />
                <div class="field-error" *dtmUiFieldHasError="permitDetailsForm.controls.permitNumber; name: 'maxlength'; error as error">
                    {{
                        "dtmAdminLibPermits.associationPermitDetails.permitNumberMaxLengthError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="permitDetailsForm.controls.permitNumber; name: ['required', 'pattern']">
                    {{ "dtmAdminLibPermits.associationPermitDetails.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-input-field>

            <dtm-ui-date-field [formGroup]="permitDetailsForm.controls.expirationDate">
                <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.expirationDateLabel" | transloco }}</label>
                <mat-date-range-input [rangePicker]="picker" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
                    <input matStartDate formControlName="dateFrom" [placeholder]="datePickerPlaceholder" />
                    <input matEndDate formControlName="dateTo" [placeholder]="datePickerPlaceholder" />
                </mat-date-range-input>
                <div
                    class="field-error"
                    *ngIf="
                        (dateToControl.touched && dateToControl.errors?.required) ||
                        (dateFromControl.touched && dateFromControl.errors?.required)
                    "
                >
                    {{ "dtmAdminLibPermits.associationPermitDetails.requiredFieldError" | transloco }}
                </div>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </dtm-ui-date-field>

            <dtm-ui-textarea-field [maxLength]="FLIGHT_PURPOSE_MAX_LENGTH">
                <label>{{ "dtmAdminLibPermits.associationPermitDetails.flightPurposeLabel" | transloco }}</label>
                <textarea formControlName="flightPurpose" matInput></textarea>
                <div class="field-error" *dtmUiFieldHasError="permitDetailsForm.controls.flightPurpose; name: 'maxlength'; error as error">
                    {{
                        "dtmAdminLibPermits.associationPermitDetails.flightPurposeMaxLengthError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="permitDetailsForm.controls.flightPurpose; name: ['required', 'pattern']">
                    {{ "dtmAdminLibPermits.associationPermitDetails.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-textarea-field>

            <dtm-ui-textarea-field [maxLength]="LOCATIONS_MAX_LENGTH">
                <label>{{ "dtmAdminLibPermits.associationPermitDetails.locationsLabel" | transloco }}</label>
                <textarea formControlName="locations" matInput></textarea>
                <div class="field-error" *dtmUiFieldHasError="permitDetailsForm.controls.locations; name: 'maxlength'; error as error">
                    {{
                        "dtmAdminLibPermits.associationPermitDetails.locationsMaxLengthError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="permitDetailsForm.controls.locations; name: ['required', 'pattern']">
                    {{ "dtmAdminLibPermits.associationPermitDetails.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-textarea-field>
            <dtm-admin-lib-association-permit-operation-type-control formControlName="operationType">
                <div class="field-error" *dtmUiFieldHasError="permitDetailsForm.controls.operationType; name: 'required'">
                    {{ "dtmAdminLibPermits.associationPermitDetails.requiredFieldError" | transloco }}
                </div>
            </dtm-admin-lib-association-permit-operation-type-control>

            <div>
                <p class="sub-header">{{ "dtmAdminLibPermits.associationPermitDetails.minimalCompetenciesHeader" | transloco }}</p>
                <p class="text">{{ "dtmAdminLibPermits.associationPermitDetails.minimalCompetenciesText" | transloco }}</p>
            </div>

            <dtm-admin-lib-confirm-tile formControlName="isInsurance">
                <label>{{ "dtmAdminLibPermits.associationPermitDetails.isInsuranceLabel" | transloco }}</label>
                <div class="field-error" *dtmUiFieldHasError="permitDetailsForm.controls.isInsurance; name: 'required'">
                    {{ "dtmAdminLibPermits.associationPermitDetails.requiredFieldError" | transloco }}
                </div>
            </dtm-admin-lib-confirm-tile>

            <div>
                <p class="sub-header">{{ "dtmAdminLibPermits.associationPermitDetails.additionalConstraintsHeader" | transloco }}</p>
                <p class="text">{{ "dtmAdminLibPermits.associationPermitDetails.additionalConstraintsText" | transloco }}</p>
            </div>
        </form>
    </dtm-ui-loader-container>
</dtm-ui-wizard-step-wrapper>
