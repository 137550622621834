import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ConversationCategory } from "../../services/user-profile.models";

interface ConversationCategoriesComponentState {
    conversationCategories: ConversationCategory[];
    assignedConversationCategories: ConversationCategory[];
}

@Component({
    selector: "dtm-admin-lib-conversation-categories",
    templateUrl: "./conversation-categories.component.html",
    styleUrls: ["./conversation-categories.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationCategoriesComponent {
    @Input() public set conversationCategories(value: ConversationCategory[] | undefined) {
        this.localStore.patchState({ conversationCategories: value ?? [] });
    }

    @Input() public set assignedConversationCategories(value: ConversationCategory[] | undefined) {
        this.localStore.patchState({ assignedConversationCategories: value ?? [] });
        this.conversationCategoryControl.setValue(value?.map((category) => category.id) ?? []);
    }

    @Output() public conversationCategoriesChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    protected readonly conversationCategories$ = this.localStore.selectByKey("conversationCategories");
    protected readonly assignedConversationCategories$ = this.localStore.selectByKey("assignedConversationCategories");

    public readonly conversationCategoryControl = new FormControl<string[]>([], { nonNullable: true });

    constructor(private readonly localStore: LocalComponentStore<ConversationCategoriesComponentState>) {
        this.localStore.setState({
            conversationCategories: [],
            assignedConversationCategories: [],
        });
    }
}
