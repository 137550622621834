import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MAX_TEXT_LENGTH, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { Observable, Subject } from "rxjs";

@Component({
    selector: "dtm-admin-lib-utm-user-reject-document-dialog",
    templateUrl: "./utm-user-reject-document-dialog.component.html",
    styleUrls: ["./utm-user-reject-document-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UtmUserRejectDocumentDialogComponent {
    private readonly reasonSubject = new Subject<string>();
    public readonly reason$ = this.reasonSubject.asObservable();
    protected readonly reasonControl: FormControl<string> = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(MAX_TEXT_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly MAX_TEXT_LENGTH = MAX_TEXT_LENGTH;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            isProcessing$: Observable<boolean>;
        }
    ) {}

    protected confirm() {
        if (this.reasonControl.invalid) {
            this.reasonControl.markAsTouched();

            return;
        }

        this.reasonSubject.next(this.reasonControl.value);
    }
}
