<div class="grid">
    <ng-container *ngIf="!(dashboardDetailsError$ | ngrxPush); else errorTemplate">
        <dtm-admin-lib-conversations-tile
            class="grid-col-4 grid-col-tablet-6 tile"
            [userConversationsStats]="userConversationsStats$ | ngrxPush"
            [assignedCategories]="assignedCategories$ | ngrxPush"
        ></dtm-admin-lib-conversations-tile>
        <dtm-admin-lib-operator-status-tile
            class="grid-col-4 grid-col-tablet-6 tile"
            [operatorStats]="operatorStats$ | ngrxPush"
        ></dtm-admin-lib-operator-status-tile>
        <dtm-admin-lib-users-tile
            class="grid-col-4 grid-col-tablet-6 tile"
            [usersStats]="usersStats$ | ngrxPush"
        ></dtm-admin-lib-users-tile>
    </ng-container>

    <ng-template #errorTemplate>
        <dtm-ui-error [mode]="ErrorMode.Secondary" (reload)="refreshStats()"></dtm-ui-error>
    </ng-template>
</div>
