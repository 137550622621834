export enum CapabilitiesErrorType {
    CannotGetCapabilitiesList = "CannotGetCapabilitiesList",
}

export interface CapabilitiesError {
    type: CapabilitiesErrorType;
}

export interface Capabilities {
    examCodes: string[];
    trainingCodes: string[];
}
