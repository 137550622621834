<div class="dialog-header">
    <div mat-dialog-title>{{ "dtmAdminLibUtmUsers.userProfile.identityDocument.rejectDocumentDialogTitle" | transloco }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <dtm-ui-textarea-field [maxLength]="MAX_TEXT_LENGTH">
        <label>{{ "dtmAdminLibUtmUsers.userProfile.identityDocument.reasonLabel" | transloco }}</label>
        <textarea matInput [formControl]="reasonControl" [rows]="4"></textarea>
        <div class="field-error" *dtmUiFieldHasError="reasonControl; name: ['required', 'pattern']">
            {{ "dtmAdminLibUtmUsers.userProfile.identityDocument.reasonRequiredHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="reasonControl; name: 'maxlength'; error as error">
            {{
                "dtmAdminLibUtmUsers.userProfile.identityDocument.reasonLengthErrorHint"
                    | transloco : { maxLength: error.requiredLength, exceedValue: error.actualLength - error.requiredLength }
            }}
        </div>
    </dtm-ui-textarea-field>
</div>
<div mat-dialog-actions align="end" *ngrxLet="data.isProcessing$ as isProcessing">
    <button type="button" class="button-secondary" [mat-dialog-close]="false">
        {{ "dtmAdminLibUtmUsers.userProfile.identityDocument.cancelLabel" | transloco }}
    </button>
    <button type="button" class="button-warn" [disabled]="isProcessing" (click)="confirm()">
        {{ "dtmAdminLibUtmUsers.userProfile.identityDocument.rejectDocumentConfirmLabel" | transloco }}
    </button>
</div>
