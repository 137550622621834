import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UtmUserStatus } from "../../services/utm-users.models";

interface UtmUserStatusComponentState {
    status: UtmUserStatus | undefined;
}

@Component({
    selector: "dtm-admin-lib-utm-user-status-badge[status]",
    templateUrl: "./utm-user-status-badge.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UtmUserStatusBadgeComponent {
    @Input() public set status(value: UtmUserStatus | undefined) {
        this.localStore.patchState({ status: value });
    }

    protected readonly UtmUserStatus = UtmUserStatus;
    protected readonly status$ = this.localStore.selectByKey("status");

    constructor(private readonly localStore: LocalComponentStore<UtmUserStatusComponentState>) {
        localStore.setState({ status: undefined });
    }
}
