import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged, first } from "rxjs/operators";
import { ChangeLogFilterFormKeys, ChangeLogFilters, ChangeLogFiltersParams } from "../../services/models";

interface ChangeLogFiltersForm {
    type: FormControl<string>;
    identifier: FormControl<string>;
    actionName: FormControl<string>;
    dateFrom: FormControl<Date | null>;
    dateTo: FormControl<Date | null>;
}

interface ChangeLogFiltersComponentState {
    initialFilters: ChangeLogFiltersParams | undefined;
    appliedFiltersLength: number | undefined;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: ChangeLogFilterFormKeys.Type,
        filterLabel: "dtmAdminLibAudit.changeLogFilters.typeLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: ChangeLogFilterFormKeys.Identifier,
        filterLabel: "dtmAdminLibAudit.changeLogFilters.identifierLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: ChangeLogFilterFormKeys.ActionName,
        filterLabel: "dtmAdminLibAudit.changeLogFilters.actionNameLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: ChangeLogFilterFormKeys.DateFrom,
        filterLabel: "dtmAdminLibAudit.changeLogFilters.dateFromLabel",
        type: FilterType.Date,
    },
    {
        key: ChangeLogFilterFormKeys.DateTo,
        filterLabel: "dtmAdminLibAudit.changeLogFilters.dateToLabel",
        type: FilterType.Date,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-change-log-filters",
    templateUrl: "./change-log-filters.component.html",
    styleUrls: ["./change-log-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ChangeLogFiltersComponent {
    @Input() public set initialFilters(value: ChangeLogFiltersParams | undefined) {
        this.localStore.patchState({ initialFilters: value });
    }

    @Output() public filtersChange: EventEmitter<ChangeLogFilters> = new EventEmitter();

    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly appliedFiltersLength$ = this.localStore.selectByKey("appliedFiltersLength");
    protected readonly filtersForm = new FormGroup<ChangeLogFiltersForm>({
        type: new FormControl("", { nonNullable: true }),
        identifier: new FormControl("", { nonNullable: true }),
        actionName: new FormControl("", { nonNullable: true }),
        dateFrom: new FormControl(null),
        dateTo: new FormControl(null),
    });

    constructor(
        private readonly translocoHelper: TranslationHelperService,
        private readonly localStore: LocalComponentStore<ChangeLogFiltersComponentState>
    ) {
        this.localStore.setState({
            appliedFiltersLength: 0,
            initialFilters: undefined,
        });

        this.assignInitialFilters();
        this.watchFormAndEmitFiltersOnChange();
    }

    protected clearFilters() {
        this.filtersForm.reset();
    }

    private assignInitialFilters(): void {
        this.localStore
            .selectByKey("initialFilters")
            .pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
            .subscribe((filters: ChangeLogFiltersParams) => {
                this.filtersForm.patchValue({
                    ...filters,
                    dateFrom: filters.dateFrom ? new Date(filters.dateFrom) : null,
                    dateTo: filters.dateTo ? new Date(filters.dateTo) : null,
                });
                this.setActiveFiltersCount();
            });
    }

    private setActiveFiltersCount(): void {
        this.localStore.patchState({
            appliedFiltersLength: Object.values(this.filtersForm.getRawValue()).flat().filter(FunctionUtils.isTruthy).length,
        });
    }

    private watchFormAndEmitFiltersOnChange(): void {
        this.filtersForm.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(() => {
                this.setActiveFiltersCount();
                this.filtersChange.emit(this.filtersForm.getRawValue());
            });
    }
}
