import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { ChangeLogContainerComponent } from "./components/change-log-container/change-log-container.component";

const routes: Routes = [
    {
        path: "change-log",
        component: ChangeLogContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibAudit.routeTitles.changeLog",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuditRoutingModule {}
