<mat-list-item>
    <a [routerLink]="[redirectLink$ | ngrxPush]" [queryParams]="redirectQueryParams$ | ngrxPush" class="list-item-container">
        <span class="redirect-value">
            <dtm-ui-icon [name]="iconsClassName$ | ngrxPush"></dtm-ui-icon>

            <span class="redirect-text" *ngIf="statsName$ | ngrxPush as statsName">
                {{ statsName }}
                <ng-container *ngIf="statsCount$ | ngrxPush as statsCount"> ({{ statsCount }}) </ng-container>
            </span>
        </span>

        <span class="image"><dtm-ui-icon name="arrow-go"></dtm-ui-icon></span>
    </a>
</mat-list-item>
