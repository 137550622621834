<div class="dialog-header">
    <div mat-dialog-title>{{ "dtmAdminLibExamTrainingResults.header.provideResults" | transloco }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>

<div class="exam-training-center-header">{{ data.results.institution.identificationNumber }} - {{ data.results.institution.name }}</div>

<dtm-ui-loader-container [isShown]="data.isExamTrainingResultProcessing$ | ngrxPush">
    <div mat-dialog-content *ngrxLet="errors$ as errors">
        <div class="card error" *ngIf="errors.length">
            <dtm-ui-card-header [isEditButtonVisible]="false">
                {{ "dtmAdminLibExamTrainingResults.dialog.errorListLabel" | transloco }}

                <ng-container additionalActions>
                    <button type="button" class="button-secondary" (click)="downloadCSV(errors)">
                        <dtm-ui-icon name="download"></dtm-ui-icon>
                        {{ "dtmAdminLibExamTrainingResults.dialog.downloadErrorReportLabel" | transloco }}
                    </button>
                </ng-container>
            </dtm-ui-card-header>
            <dtm-ui-responsive-table [data]="errors">
                <table mat-table desktopTable [dataSource]="errors" multiTemplateDataRows class="dtm-table error-table">
                    <ng-container [matColumnDef]="errorDisplayedColumns[0]">
                        <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibExamTrainingResults.dialog.cellLabel" | transloco }}</th>
                        <td mat-cell *matCellDef="let error">{{ error.cell || "-" }}</td>
                    </ng-container>

                    <ng-container [matColumnDef]="errorDisplayedColumns[1]">
                        <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibExamTrainingResults.dialog.errorTypeLabel" | transloco }}</th>
                        <td mat-cell *matCellDef="let error">{{ error.errorMsg }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="errorDisplayedColumns"></tr>

                    <tr mat-row *matRowDef="let row; columns: errorDisplayedColumns"></tr>
                </table>
                <div *dtmUiMobileTable="let row">
                    <div class="mobile-table-content">
                        <dtm-ui-label-value [label]="'dtmAdminLibExamTrainingResults.dialog.cellLabel' | transloco">
                            <span *ngIf="row.cell; else missingDataTemplate">{{ row.cell }}</span>
                        </dtm-ui-label-value>
                        <dtm-ui-label-value [label]="'dtmAdminLibExamTrainingResults.dialog.errorTypeLabel' | transloco">
                            <span *ngIf="row.errorMsg; else missingDataTemplate">{{ row.errorMsg }}</span>
                        </dtm-ui-label-value>
                    </div>
                </div>
            </dtm-ui-responsive-table>
        </div>

        <div *ngFor="let operator of data.results.operators" class="card">
            <dtm-ui-card-header [isEditButtonVisible]="false">
                {{ operator.name }}
                <span class="operator-number">
                    ({{ "dtmAdminLibExamTrainingResults.dialog.operatorNumber" | transloco : { value: operator.operatorNumber } }}
                    )
                </span>
            </dtm-ui-card-header>
            <dtm-ui-expandable-panel isExpanded [hasHeaderSeparator]="false">
                <h2 headerSlot>{{ "dtmAdminLibExamTrainingResults.dialog.currentCompetenciesHeader" | transloco }}</h2>
                <div class="competencies-container" *ngIf="operator.currentCompetencies.length; else noCompetenciesTemplate">
                    <ng-container
                        *ngFor="let competency of operator.currentCompetencies"
                        [ngTemplateOutlet]="competencyBadgeTemplate"
                        [ngTemplateOutletContext]="{ $implicit: competency }"
                    ></ng-container>
                </div>
            </dtm-ui-expandable-panel>

            <dtm-ui-expandable-panel isExpanded [hasHeaderSeparator]="false">
                <h2 headerSlot>{{ "dtmAdminLibExamTrainingResults.dialog.evaluatedCompetenciesHeader" | transloco }}</h2>
                <div class="competencies-container" *ngIf="operator.evaluatedCompetencies.length; else noCompetenciesTemplate">
                    <ng-container
                        *ngFor="let competency of operator.evaluatedCompetencies"
                        [ngTemplateOutlet]="competencyBadgeTemplate"
                        [ngTemplateOutletContext]="{ $implicit: competency }"
                    ></ng-container>
                </div>
            </dtm-ui-expandable-panel>

            <ng-container *ngIf="operator.trainings?.length">
                <div class="table-header">
                    <dtm-ui-icon name="school"></dtm-ui-icon>
                    {{ "dtmAdminLibExamTrainingResults.dialog.trainingsLabel" | transloco }}
                </div>
                <dtm-ui-responsive-table [data]="operator.trainings">
                    <table mat-table desktopTable [dataSource]="operator.trainings ?? []" multiTemplateDataRows class="dtm-table">
                        <ng-container [matColumnDef]="trainingDisplayedColumns[0]">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "dtmAdminLibExamTrainingResults.dialog.trainingNameLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let result">{{ result.trainingNativeName }}</td>
                        </ng-container>

                        <ng-container [matColumnDef]="trainingDisplayedColumns[1]">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "dtmAdminLibExamTrainingResults.dialog.trainingTimeDurationLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let result">
                                {{ result.startDate | localizeDate }} - {{ result.endDate | localizeDate }}
                            </td>
                        </ng-container>

                        <ng-container [matColumnDef]="trainingDisplayedColumns[2]">
                            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibExamTrainingResults.dialog.trainingId" | transloco }}</th>
                            <td mat-cell *matCellDef="let result">{{ result.identificationNumber }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="trainingDisplayedColumns"></tr>

                        <tr mat-row *matRowDef="let row; columns: trainingDisplayedColumns"></tr>
                    </table>
                    <div *dtmUiMobileTable="let row">
                        <div class="mobile-table-content">
                            <dtm-ui-label-value [label]="'dtmAdminLibExamTrainingResults.dialog.trainingNameLabel' | transloco">
                                <span *ngIf="row.trainingNativeName; else missingDataTemplate">{{ row.trainingNativeName }}</span>
                            </dtm-ui-label-value>
                            <dtm-ui-label-value [label]="'dtmAdminLibExamTrainingResults.dialog.trainingTimeDurationLabel' | transloco"
                                ><span *ngIf="row.startDate && row.endDate; else missingDataTemplate">
                                    {{ row.startDate | localizeDate }} - {{ row.endDate | localizeDate }}
                                </span>
                            </dtm-ui-label-value>
                            <dtm-ui-label-value [label]="'dtmAdminLibExamTrainingResults.dialog.trainingId' | transloco">
                                <span *ngIf="row.identificationNumber; else missingDataTemplate">{{ row.identificationNumber }}</span>
                            </dtm-ui-label-value>
                        </div>
                    </div>
                </dtm-ui-responsive-table>
            </ng-container>

            <ng-container *ngIf="operator.exams?.length">
                <div class="table-header">
                    <dtm-ui-icon name="student"></dtm-ui-icon>
                    {{ "dtmAdminLibExamTrainingResults.dialog.examsLabel" | transloco }}
                </div>
                <dtm-ui-responsive-table [data]="operator.exams">
                    <table mat-table [dataSource]="operator.exams ?? []" multiTemplateDataRows desktopTable class="dtm-table exam-table">
                        <ng-container [matColumnDef]="examDisplayedColumns[0]">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "dtmAdminLibExamTrainingResults.dialog.examNameLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let result">{{ result.examNativeName }}</td>
                        </ng-container>

                        <ng-container [matColumnDef]="examDisplayedColumns[1]">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "dtmAdminLibExamTrainingResults.dialog.examDateLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let result">{{ result.passDate | localizeDate }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="examDisplayedColumns"></tr>

                        <tr mat-row *matRowDef="let row; columns: examDisplayedColumns"></tr>
                    </table>
                    <div *dtmUiMobileTable="let row">
                        <dtm-ui-label-value [label]="'dtmAdminLibExamTrainingResults.dialog.examNameLabel' | transloco">
                            <span *ngIf="row.examNativeName; else missingDataTemplate">{{ row.examNativeName }}</span>
                        </dtm-ui-label-value>
                        <dtm-ui-label-value [label]="'dtmAdminLibExamTrainingResults.dialog.examDateLabel' | transloco">
                            <span *ngIf="row.passDate; else missingDataTemplate">{{ row.passDate | localizeDate }}</span>
                        </dtm-ui-label-value>
                    </div>
                </dtm-ui-responsive-table>
            </ng-container>
        </div>
    </div>
</dtm-ui-loader-container>

<mat-dialog-actions align="end">
    <button type="button" mat-dialog-close class="button-secondary">
        {{ "dtmAdminLibExamTrainingResults.dialog.cancelButtonLabel" | transloco }}
    </button>
    <button
        type="button"
        class="button-primary"
        (click)="save()"
        [disabled]="data.results.errors?.length || data.isExamTrainingResultProcessing$ | ngrxPush"
    >
        {{ "dtmAdminLibExamTrainingResults.dialog.addToResultsLabel" | transloco }}
    </button>
</mat-dialog-actions>

<ng-template #missingDataTemplate>
    <span class="no-data">{{ "dtmAdminLibExamTrainingResults.dialog.missingDataLabel" | transloco }}</span>
</ng-template>

<ng-template #noCompetenciesTemplate>{{ "dtmAdminLibExamTrainingResults.dialog.noCompetenciesLabel" | transloco }}</ng-template>

<ng-template #competencyBadgeTemplate let-competency>
    <div class="competency">
        <ng-container [ngSwitch]="competency.category">
            <dtm-ui-icon *ngSwitchCase="OperationScenarioCategory.Open" name="shield" class="shield"></dtm-ui-icon>
            <dtm-ui-icon *ngSwitchDefault name="shield-cross" class="shield"></dtm-ui-icon>
        </ng-container>
        <div class="competency-value">
            <div class="competency-name-container">
                <p class="competency-name">{{ competency.name }}</p>
                <dtm-ui-popover *ngIf="competency.statusReason" [popoverText]="competency.statusReason"></dtm-ui-popover>
            </div>
            <div class="competency-details">
                <span class="competency-reason" *ngIf="competency.status === QualificationStatus.Suspended; else expirationDateTemplate">
                    {{ "dtmAdminLibExamTrainingResults.dialog.suspendedCompetencyLabel" | transloco }}
                </span>
                <ng-template #expirationDateTemplate>
                    {{
                        "dtmAdminLibExamTrainingResults.dialog.competencyExpirationDateValue"
                            | transloco : { date: competency.expirationDate | localizeDate }
                    }}
                </ng-template>
            </div>
        </div>
    </div>
</ng-template>
