<ng-container *ngrxLet="thread$; let thread">
    <dtm-ui-select-field
        [isClearable]="false"
        [formControl]="categoryControl"
        (valueChange)="categoryChange.emit($event)"
        *ngrxLet="categories$; let categories"
    >
        <label>{{ "dtmUi.conversations.categorySelectLabel" | transloco }}</label>
        <mat-select-trigger *ngrxLet="isProcessing$; let isProcessing">
            <div class="selected-value">
                <mat-spinner *ngIf="isProcessing$ | ngrxPush" color="accent" diameter="24"></mat-spinner>
                <span>
                    {{ "dtmUi.conversations.categoryLabel" | transloco : { value: categoryControl.value } }}
                </span>
            </div>
        </mat-select-trigger>

        <dtm-ui-select-option *ngFor="let category of categories" [value]="category">
            {{ "dtmUi.conversations.categoryLabel" | transloco : { value: category } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
</ng-container>
