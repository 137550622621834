import { ChangeDetectionStrategy, Component } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MIDDLE_PAGE_SIZE_VALUE, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ChangeLogFilters, ChangeLogFiltersParams } from "../../services/models";
import { AuditActions } from "../../state/audit.actions";
import { AuditState } from "../../state/audit.state";

interface ChangeLogContainerComponentState {
    pageNumber: number;
    pageSize: number;
    filters: ChangeLogFiltersParams;
}

@UntilDestroy()
@Component({
    templateUrl: "./change-log-container.component.html",
    styleUrls: ["./change-log-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ChangeLogContainerComponent {
    protected readonly changeLog$ = this.store.select(AuditState.changeLog);
    protected readonly changeLogError$ = this.store.select(AuditState.changeLogError);
    protected readonly isProcessing$ = this.store.select(AuditState.isProcessing);
    protected readonly pagination$ = this.store.select(AuditState.pagination);
    protected readonly filters$ = this.localStore.selectByKey("filters");

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<ChangeLogContainerComponentState>
    ) {
        const queryParams = this.route.snapshot.queryParams;
        const initialFilters: ChangeLogFiltersParams = {
            type: queryParams.type,
            identifier: queryParams.identifier,
            actionName: queryParams.actionName,
            dateFrom: queryParams.dateFrom,
            dateTo: queryParams.dateTo,
        };
        localStore.setState({
            pageNumber: queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            pageSize: queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIDDLE_PAGE_SIZE_VALUE,
            filters: initialFilters,
        });
        this.refreshList();
    }

    protected refreshList() {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: Params) => {
            this.store.dispatch(new AuditActions.GetChangeLog(queryParams));
        });
    }

    protected changePage(page: PageEvent) {
        this.localStore.patchState({ pageNumber: page.pageIndex, pageSize: page.pageSize });
        this.navigateByParams();
    }

    protected applyFilters(filters: ChangeLogFilters) {
        this.localStore.patchState({
            filters: {
                ...filters,
                dateFrom: filters.dateFrom ? filters.dateFrom.toISOString() : null,
                dateTo: filters.dateTo ? filters.dateTo.toISOString() : null,
            },
        });
        this.navigateByParams(true);
    }

    private navigateByParams(shouldResetPage?: boolean) {
        const pageIndex = shouldResetPage ? 0 : this.localStore.selectSnapshotByKey("pageNumber");
        const filters = this.localStore.selectSnapshotByKey("filters");
        const params: Params = {
            ...filters,
            [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: this.localStore.selectSnapshotByKey("pageSize"),
        };

        if (shouldResetPage) {
            this.localStore.patchState({ pageNumber: pageIndex });
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }
}
