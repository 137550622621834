import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";

import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
    Validators,
} from "@angular/forms";

import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-confirm-tile",
    templateUrl: "./confirm-tile-control.component.html",
    styleUrls: ["./confirm-tile-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ConfirmTileControlComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ConfirmTileControlComponent),
            multi: true,
        },
    ],
})
export class ConfirmTileControlComponent implements ControlValueAccessor, Validator {
    @Input() public set isConfirmed(value: boolean | null) {
        this.isConfirmedControl.setValue(value, { emitEvent: false });
        if (!this.validate(this.isConfirmedControl)) {
            this.onChange(this.isConfirmedControl.value);
        }

        this.onValidationChange();
    }

    protected readonly isConfirmedControl = new FormControl<boolean | null>(null, Validators.required);

    protected onChange: (missionType: boolean | null) => void = FunctionUtils.noop;
    protected onTouched: () => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor() {
        this.isConfirmedControl.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
            this.onChange(val);
        });
    }
    public registerOnChange(onChange: (value: boolean | null) => void) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(isConfirmed: boolean | null) {
        this.isConfirmed = isConfirmed;
    }

    public validate(control: AbstractControl) {
        if (control.invalid) {
            return {
                required: true,
            };
        }

        return null;
    }
}
