import { ConversationCategoryCode } from "@dtm-frontend/shared/ui";

export interface DashboardDetails {
    userConversationsStats: UserConversationsStats;
    operatorsStats: OperatorsStats;
    assignedCategories: ConversationCategoryCode[];
    usersStats: UsersStats;
}

export interface UserConversationsStats {
    unreadAssignedCount: number;
    openedAssignedCount: number;
    notAssignedInOwnCategoriesCount: number;
}

export interface OperatorsStats {
    pendingApprovalCount: number;
    approvalInProgressCount: number;
    suspendedCount: number;
    attorneyPowersPendingApprovalCount: number;
    waitingCompetencyConfirmationsCount: number;
}

export interface UsersStats {
    approvalInProgressCount: number;
    waitingWithoutCompletionRequestsCount: number;
    waitingCompletionRequestsCount: number;
    waitingIdentityDocumentsCount: number;
}

export interface DashboardDetailsError {
    type: DashboardDetailsErrorType;
}
export enum DashboardDetailsErrorType {
    Unknown = "Unknown",
}
