import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { MissionType } from "@dtm-frontend/shared/mission";
import {
    DtmLocations,
    FILES_UPLOAD_API_PROVIDER,
    LocalizationType,
    OperationRestrictionsModel,
    RiskAssessmentReference,
    Sail,
    UploadedFile,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { AnimationUtils, FunctionUtils, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged } from "rxjs/operators";
import { sailLevels } from "../../../models/permits.models";
import { PERMITS_ENDPOINTS } from "../../../permits.tokens";
import { operationalRestrictionsUploadApiFactory } from "../../../services/operational-restrictions-upload-api.factory";
import { OperationalRestrictionsUploadApiService } from "../../../services/operational-restrictions-upload-api.service";
import { OPERATIONAL_RESTRICTIONS_API_ENDPOINTS } from "../../../services/operational-restrictions-upload-api.tokens";

export const MAX_PERMIT_NUMBER_LENGTH = 100;
export const MAX_PDRA_NUMBER_LENGTH = 100;
export const MAX_PERMIT_CONFIRMATION_LENGTH = 100;
export const MAX_OPERATION_TARGET_LENGTH = 200;
const ALLOWED_FILE_EXTENSION = [".kml"];

interface ExpirationDateForm {
    dateFrom: FormControl<Date | null>;
    dateTo: FormControl<Date | null>;
}

interface OperationRestrictionsFormComponentState {
    dtmLocations: DtmLocations[] | undefined;
}

interface OperationRestrictionsForm {
    permitNumber: FormControl<string>;
    permitConfirmationNumber: FormControl<string | null>;
    expirationDate: FormGroup<ExpirationDateForm>;
    operationTarget: FormControl<string>;
    localization: FormControl<LocalizationType | null>;
    dtm: FormControl<DtmLocations | null>;
    riskMethodology: FormControl<RiskAssessmentReference | null>;
    sail: FormControl<Sail | null>;
    operationType: FormControl<MissionType | null>;
    isDangerousGoodsTransport: FormControl<boolean | null>;
    kmlFile: FormControl<UploadedFile[]>;
    pdraNumber: FormControl<string | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operation-restrictions-form[dtmLocations]",
    templateUrl: "./operation-restrictions-form.component.html",
    styleUrls: ["../../styles/shared-permit-styles.scss", "./operation-restrictions-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OperationRestrictionsFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OperationRestrictionsFormComponent),
            multi: true,
        },
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: OperationalRestrictionsUploadApiService },
        {
            provide: OPERATIONAL_RESTRICTIONS_API_ENDPOINTS,
            useFactory: operationalRestrictionsUploadApiFactory,
            deps: [PERMITS_ENDPOINTS],
        },
    ],
    animations: [AnimationUtils.slideInAnimation()],
})
export class OperationRestrictionsFormComponent implements ControlValueAccessor, Validators {
    @Input()
    public set dtmLocations(value: DtmLocations[] | undefined) {
        this.localStore.patchState({ dtmLocations: value });
    }
    @Input()
    public set isPermitNumberDisabled(value: BooleanInput) {
        if (coerceBooleanProperty(value)) {
            this.restrictionsForm.controls.permitNumber.disable();

            return;
        }
    }
    @Input()
    public set isPermitConfirmationNumberEnabled(value: BooleanInput) {
        if (coerceBooleanProperty(value)) {
            this.restrictionsForm.controls.permitConfirmationNumber.enable();

            return;
        }
        this.restrictionsForm.controls.permitConfirmationNumber.disable();
    }

    @Output() public readonly kmlFilePreview = new EventEmitter<UploadedFile>();

    protected readonly MAX_OPERATION_TARGET_LENGTH = MAX_OPERATION_TARGET_LENGTH;
    protected readonly dtmLocations$ = this.localStore.selectByKey("dtmLocations");
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly RiskAssessmentReference = RiskAssessmentReference;
    protected readonly sailLevels = sailLevels;
    protected readonly LocalizationType = LocalizationType;
    protected readonly ALLOWED_FILE_EXTENSION = ALLOWED_FILE_EXTENSION;
    protected readonly dateFromControl = new FormControl(null, Validators.required);
    protected readonly dateToControl = new FormControl(null, Validators.required);
    protected readonly restrictionsForm = new FormGroup<OperationRestrictionsForm>({
        permitNumber: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_PERMIT_NUMBER_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        permitConfirmationNumber: new FormControl(
            { value: null, disabled: true },
            {
                validators: [
                    Validators.required,
                    Validators.maxLength(MAX_PERMIT_CONFIRMATION_LENGTH),
                    Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                ],
                nonNullable: true,
            }
        ),
        expirationDate: new FormGroup<ExpirationDateForm>({
            dateFrom: this.dateFromControl,
            dateTo: this.dateToControl,
        }),
        operationTarget: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_OPERATION_TARGET_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        localization: new FormControl(null, Validators.required),
        dtm: new FormControl({ value: null, disabled: true }, Validators.required),
        riskMethodology: new FormControl(null, Validators.required),
        sail: new FormControl(null, Validators.required),
        operationType: new FormControl(null, Validators.required),
        isDangerousGoodsTransport: new FormControl(null, Validators.required),
        kmlFile: new FormControl([], { nonNullable: true, validators: [Validators.required, Validators.maxLength(1)] }),
        pdraNumber: new FormControl<string | null>({ value: null, disabled: true }, [
            Validators.required,
            Validators.maxLength(MAX_PDRA_NUMBER_LENGTH),
        ]),
    });

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: OperationRestrictionsModel) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(
        private readonly translocoHelper: TranslationHelperService,
        private readonly localStore: LocalComponentStore<OperationRestrictionsFormComponentState>
    ) {
        this.localStore.setState({
            dtmLocations: undefined,
        });
        this.restrictionsForm.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.restrictionsForm.getRawValue() as OperationRestrictionsModel);
            this.propagateTouch();
        });

        this.restrictionsForm.controls.localization.valueChanges
            .pipe(distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((value) => this.disableLocalizationControls(value));

        this.restrictionsForm.controls.riskMethodology.valueChanges
            .pipe(distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((value) => this.changeRiskMethodology(value));
    }

    public registerOnChange(fn: (value: OperationRestrictionsModel) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.restrictionsForm.valid) {
            return null;
        }

        return this.restrictionsForm.invalid ? { invalidRestrictions: true } : null;
    }

    public writeValue(value: OperationRestrictionsModel | undefined): void {
        if (value) {
            this.restrictionsForm.reset(
                {
                    permitNumber: value.permitNumber,
                    permitConfirmationNumber: value.permitConfirmationNumber,
                    expirationDate: {
                        dateFrom: value.expirationDate.dateFrom ? new Date(value.expirationDate.dateFrom) : null,
                        dateTo: value.expirationDate.dateTo ? new Date(value.expirationDate.dateTo) : null,
                    },
                    operationTarget: value.operationTarget,
                    localization: value.localization,
                    dtm: value.dtm,
                    kmlFile: value.kmlFile,
                    riskMethodology: value.riskMethodology,
                    sail: value.sail,
                    operationType: value.operationType,
                    isDangerousGoodsTransport: value.isDangerousGoodsTransport,
                    pdraNumber: value.pdraNumber ?? null,
                },
                { emitEvent: false }
            );
            if (value.localization === LocalizationType.Dtm) {
                this.disableLocalizationControls(value.localization);
            }
            if (value.riskMethodology === RiskAssessmentReference.PDRA) {
                this.restrictionsForm.controls.pdraNumber.enable();
            }
        } else {
            this.restrictionsForm.reset();
        }
    }

    protected disableLocalizationControls(localization: LocalizationType): void {
        if (localization === LocalizationType.Dtm) {
            this.restrictionsForm.controls.dtm.enable();
            this.restrictionsForm.controls.kmlFile.disable();
            this.restrictionsForm.controls.kmlFile.reset();
        } else {
            this.restrictionsForm.controls.kmlFile.enable();
            this.restrictionsForm.controls.dtm.disable();
            this.restrictionsForm.controls.dtm.reset();
        }
    }

    protected changeRiskMethodology(selectedValue: RiskAssessmentReference) {
        if (selectedValue !== RiskAssessmentReference.PDRA) {
            this.restrictionsForm.controls.pdraNumber.disable();
            this.restrictionsForm.controls.pdraNumber.reset();

            return;
        }

        if (selectedValue === RiskAssessmentReference.PDRA) {
            this.restrictionsForm.controls.pdraNumber.enable();

            return;
        }
    }

    protected removeFile(): void {
        this.restrictionsForm.controls.kmlFile.setValue([]);
    }
}
