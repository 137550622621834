import { InjectionToken } from "@angular/core";

export interface UserProfileEndpoints {
    getUserProfile: string;
    getProfileCapabilities: string;
    getProfileAvatar: string;
    savePhoneNumber: string;
    saveEmailAddress: string;
    saveConversationCategories: string;
    savePersonalData: string;
    updateProfileLanguage: string;
    resetUserPassword: string;
    manageProfileAvatar: string;
}

export const USER_PROFILE_ENDPOINTS = new InjectionToken<UserProfileEndpoints>("UserProfile endpoints");
