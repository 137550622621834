import { HttpClient, HttpContext, HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { QualificationStatus } from "@dtm-frontend/shared/ui";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";
import { Observable, map, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import {
    AttorneyPowerUpdate,
    ForeignCompetencyDocument,
    ForeignCompetencyStatus,
    NewOperationalAuthorization,
    OperationScenario,
    OperatorDetails,
    OperatorDocumentType,
    OperatorError,
    OperatorErrorType,
    OperatorStatusChange,
    OperatorType,
    PermissionsToGrant,
    QualificationStatusChange,
    SINGLE_SELECT_VALUES,
    UpdateAttorneyPowerErrorType,
    UpdateOperatorDetailsErrorType,
} from "../../shared";
import {
    GetOperatorDetailsResponseBody,
    convertGetOperatorDetailsResponseBodyToOperatorDetails,
} from "../../shared/services/shared-api.converters";
import { OPERATOR_ENDPOINTS, OperatorEndpoints } from "../operator.tokens";
import {
    GetOperatorsListResponseBody,
    OperatorAvailableOperationScenarioResponseBody,
    convertGetOperatorAvailableOperationScenariosResponseBodyToOperationScenarios,
    convertGetOperatorsListResponseBodyToOperatorListWithPages,
} from "./operator-api.converters";
import {
    CapabilitiesErrorType,
    OperatorListWithPages,
    OperatorsCapabilities,
    OperatorsFilterParams,
    QualificationsError,
    QualificationsErrorType,
} from "./operator.models";

@Injectable({
    providedIn: "root",
})
export class OperatorAPIService {
    constructor(private readonly httpClient: HttpClient, @Inject(OPERATOR_ENDPOINTS) private readonly endpoints: OperatorEndpoints) {}

    public getCapabilities(): Observable<OperatorsCapabilities> {
        return this.httpClient
            .get<OperatorsCapabilities>(this.endpoints.getCapabilities)
            .pipe(catchError(() => throwError(() => ({ type: CapabilitiesErrorType.CannotGetCapabilities }))));
    }

    public getOperators(filters: OperatorsFilterParams): Observable<OperatorListWithPages> {
        const params: HttpParams = this.getOperatorsParams(filters);

        return this.httpClient.get<GetOperatorsListResponseBody>(this.endpoints.getOperatorList, { params }).pipe(
            map((response) => convertGetOperatorsListResponseBodyToOperatorListWithPages(response)),
            catchError(() =>
                throwError(() => ({
                    type: OperatorErrorType.Unknown,
                }))
            )
        );
    }

    public addOperationalAuthorization(operatorId: string, newOperationalAuthorizations: NewOperationalAuthorization[]) {
        return this.httpClient
            .post(StringUtils.replaceInTemplate(this.endpoints.addOperationalAuthorization, { id: operatorId }), {
                operationScenarios: newOperationalAuthorizations,
            })
            .pipe(catchError(() => throwError(() => ({ type: QualificationsErrorType.CannotAdd }))));
    }

    public addOtherInformation(operatorId: string, text: string) {
        return this.httpClient
            .post(StringUtils.replaceInTemplate(this.endpoints.addOtherInformation, { id: operatorId }), {
                text,
            })
            .pipe(catchError(() => throwError(() => ({ type: UpdateOperatorDetailsErrorType.Unknown }))));
    }

    public editOtherInformation(operatorId: string, otherInformationId: string, text: string) {
        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.manageOtherInformation, { operatorId, otherInformationId }), {
                text,
            })
            .pipe(catchError(() => throwError(() => ({ type: UpdateOperatorDetailsErrorType.Unknown }))));
    }

    public deleteOtherInformation(operatorId: string, otherInformationId: string) {
        return this.httpClient
            .delete(StringUtils.replaceInTemplate(this.endpoints.manageOtherInformation, { operatorId, otherInformationId }))
            .pipe(catchError(() => throwError(() => ({ type: UpdateOperatorDetailsErrorType.Unknown }))));
    }

    public deleteOperator(operatorId: string) {
        return this.httpClient
            .delete(StringUtils.replaceInTemplate(this.endpoints.manageOperator, { operatorId }))
            .pipe(catchError(() => throwError(() => ({ type: OperatorErrorType.Unknown }))));
    }

    public updateForeignCompetencyStatus(
        pilotId: string,
        competencyId: string,
        status: ForeignCompetencyStatus,
        statusReason: string | null = null
    ) {
        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.updateForeignCompetencyStatus, { pilotId, competencyId }), {
                status,
                ...(statusReason && { statusReason }),
            })
            .pipe(catchError(() => throwError(() => ({ type: OperatorErrorType.Unknown }))));
    }

    public changeOperationalAuthorization(operatorId: string, statusChange: QualificationStatusChange) {
        return this.httpClient
            .put(
                StringUtils.replaceInTemplate(this.endpoints.manageOperationalAuthorization, {
                    id: operatorId,
                    operationalAuthorizationId: statusChange.qualificationId as string,
                }),
                {
                    status: statusChange.status,
                    statusReason: statusChange.reason ?? null,
                }
            )
            .pipe(catchError(() => throwError(() => this.getOperationalAuthorizationStatusChangeError(statusChange.status))));
    }

    public removeOperationalAuthorization(operatorId: string, operationalAuthorizationId: string) {
        return this.httpClient
            .delete(
                StringUtils.replaceInTemplate(this.endpoints.manageOperationalAuthorization, {
                    id: operatorId,
                    operationalAuthorizationId,
                })
            )
            .pipe(catchError(() => throwError(() => ({ type: QualificationsErrorType.CannotRemove }))));
    }

    public getAvailableOperationScenarios(operatorId: string): Observable<OperationScenario[]> {
        return this.httpClient
            .get<OperatorAvailableOperationScenarioResponseBody[]>(
                StringUtils.replaceInTemplate(this.endpoints.getAvailableOperationScenarios, { id: operatorId })
            )
            .pipe(
                map((response) => convertGetOperatorAvailableOperationScenariosResponseBodyToOperationScenarios(response)),
                catchError(() => throwError(() => ({ type: QualificationsErrorType.CannotGetAvailableOperationScenarios })))
            );
    }

    public changeOperatorStatus(changeStatus: OperatorStatusChange): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.changeOperatorStatus, { id: changeStatus.operator.id }), {
                status: changeStatus.newStatus,
                suspensionReason: changeStatus.reason || null,
            })
            .pipe(catchError(() => throwError(() => ({ type: UpdateOperatorDetailsErrorType.CannotChangeStatus }))));
    }

    public getOperatorDetails(operatorId: string, operatorType: OperatorType): Observable<OperatorDetails> {
        let endpoint = "";

        switch (operatorType) {
            case OperatorType.Personal:
                endpoint = this.endpoints.getPersonalOperatorDetails;
                break;
            case OperatorType.Enterprise:
                endpoint = this.endpoints.getEnterpriseOperatorDetails;
                break;
            case OperatorType.Association:
                endpoint = this.endpoints.getAssociationOperatorDetails;
                break;
        }

        if (!endpoint) {
            return throwError(() => ({ type: OperatorErrorType.NoOperatorType }));
        }

        return this.httpClient.get<GetOperatorDetailsResponseBody>(StringUtils.replaceInTemplate(endpoint, { id: operatorId })).pipe(
            map((response) => convertGetOperatorDetailsResponseBodyToOperatorDetails(response)),
            catchError((error) => throwError(() => this.transformOperatorErrorResponse(error)))
        );
    }

    public getFile(id: string, documentId: string, documentType: OperatorDocumentType): Observable<Blob> {
        const endpoint: string =
            documentType === OperatorDocumentType.AttorneyPower
                ? this.endpoints.getAttorneyPowerDocument
                : this.endpoints.getAdministrativeFeeDocument;
        const endpointValues: { [key: string]: string } =
            documentType === OperatorDocumentType.AttorneyPower ? { id, documentId } : { documentId };

        return this.httpClient.get(StringUtils.replaceInTemplate(endpoint, endpointValues), {
            responseType: "blob",
            context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
        });
    }

    public getForeignCompetencyDocument(competencyDocument: ForeignCompetencyDocument): Observable<Blob> {
        return this.httpClient
            .get(
                StringUtils.replaceInTemplate(this.endpoints.getForeignCompetencyDocument, {
                    pilotId: competencyDocument.pilotId,
                    fileId: competencyDocument.file.id,
                }),
                {
                    responseType: "blob",
                    context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
                }
            )
            .pipe(tap((attachment: Blob) => saveAs(attachment, competencyDocument.file.name)));
    }

    public updateAttorneyPower(id: string, attorneyPowerUpdate: AttorneyPowerUpdate): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateAttorneyPower, { id, attorneyPowerId: attorneyPowerUpdate.id }), {
                status: attorneyPowerUpdate.status,
                permissions: attorneyPowerUpdate.permissions,
            })
            .pipe(catchError(() => throwError(() => ({ type: UpdateAttorneyPowerErrorType.CannotUpdate }))));
    }

    public updateAttorneyPowerPermissions(id: string, attorneyPowerId: string, permissions: PermissionsToGrant[]): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateAttorneyPowerPermissions, { id, attorneyPowerId }), {
                permissions,
            })
            .pipe(catchError(() => throwError(() => ({ type: UpdateAttorneyPowerErrorType.CannotUpdate }))));
    }

    private getOperatorsParams(filters: OperatorsFilterParams): HttpParams {
        let params = new HttpParams().set("page", `${filters?.page ?? 0}`);

        if (filters.size) {
            params = params.append("size", filters.size);
        }

        if (filters.searchByText) {
            params = params.append("name", filters.searchByText);
        }

        if (filters.type) {
            params = params.append("type", filters.type);
        }

        if (filters.isDefaultCountryRegistration === SINGLE_SELECT_VALUES.True) {
            params = params.append("registeredInOtherCountry", SINGLE_SELECT_VALUES.False);
        } else if (filters.isDefaultCountryRegistration === SINGLE_SELECT_VALUES.False) {
            params = params.append("registeredInOtherCountry", SINGLE_SELECT_VALUES.True);
        }

        if (filters.status) {
            params = params.append("statuses", `${filters.status}`);
        }

        if (filters.powerOfAttorneyStatus) {
            params = params.append("attorneyPowerStatus", filters.powerOfAttorneyStatus);
        }

        if (filters.isWaitingCompetencyConfirmation) {
            params = params.append("waitingCompetencyConfirmation", filters.isWaitingCompetencyConfirmation);
        }

        return params;
    }

    private transformOperatorErrorResponse(errorResponse: HttpErrorResponse): OperatorError {
        switch (errorResponse.status) {
            case HttpStatusCode.Forbidden:
                return { type: OperatorErrorType.NotAuthorized };
            case HttpStatusCode.NotFound:
                return { type: OperatorErrorType.NotFound };
            default: {
                return { type: OperatorErrorType.Unknown };
            }
        }
    }

    private getOperationalAuthorizationStatusChangeError(status: QualificationStatus): QualificationsError {
        let errorType;
        switch (status) {
            case QualificationStatus.Active:
                errorType = QualificationsErrorType.CannotActivate;
                break;
            case QualificationStatus.Suspended:
                errorType = QualificationsErrorType.CannotSuspend;
                break;

            case QualificationStatus.Withdrawn:
                errorType = QualificationsErrorType.CannotSuspend;
                break;
        }

        return { type: errorType };
    }
}
