import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DisplayCountryService, FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_COUNTRY_CODE, DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged, first } from "rxjs/operators";
import { NULL_VALUE, SINGLE_SELECT_VALUES } from "../../../shared";
import { UtmUserFilters, UtmUserStatus, UtmUsersFilterFormKeys } from "../../services/utm-users.models";

interface UtmUsersFiltersComponentState {
    initialFilters: UtmUserFilters | undefined;
    appliedFiltersLength: number | undefined;
}

interface FiltersForm {
    [UtmUsersFilterFormKeys.SearchText]: FormControl<string>;
    [UtmUsersFilterFormKeys.Statuses]: FormControl<UtmUserStatus[]>;
    [UtmUsersFilterFormKeys.IsDefaultCountry]: FormControl<string | typeof NULL_VALUE>;
    [UtmUsersFilterFormKeys.IsLegalAge]: FormControl<string | typeof NULL_VALUE>;
    [UtmUsersFilterFormKeys.IsWaitingIdentityDocument]: FormControl<string | typeof NULL_VALUE>;
    [UtmUsersFilterFormKeys.IsWaitingCompletionRequest]: FormControl<string | typeof NULL_VALUE>;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: UtmUsersFilterFormKeys.SearchText,
        filterLabel: "dtmAdminLibUtmUsers.filters.searchTextLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: UtmUsersFilterFormKeys.Statuses,
        filterLabel: "dtmAdminLibUtmUsers.filters.statusesLabel",
        filterValueLabel: "dtmAdminLibUtmUsers.filters.statusesValue",
    },
    {
        key: UtmUsersFilterFormKeys.IsDefaultCountry,
        filterLabel: "dtmAdminLibUtmUsers.filters.isDefaultCountryLabel",
        filterValueLabel: "dtmAdminLibUtmUsers.filters.isDefaultCountryValue",
    },
    {
        key: UtmUsersFilterFormKeys.IsLegalAge,
        filterLabel: "dtmAdminLibUtmUsers.filters.isLegalAgeLabel",
        filterValueLabel: "dtmAdminLibUtmUsers.filters.isLegalAgeValue",
    },
    {
        key: UtmUsersFilterFormKeys.IsWaitingIdentityDocument,
        filterLabel: "dtmAdminLibUtmUsers.filters.isWaitingIdentityDocumentLabel",
        filterValueLabel: "dtmAdminLibUtmUsers.filters.isWaitingIdentityDocumentValue",
    },
    {
        key: UtmUsersFilterFormKeys.IsWaitingCompletionRequest,
        filterLabel: "dtmAdminLibUtmUsers.filters.isWaitingCompletionRequestLabel",
        filterValueLabel: "dtmAdminLibUtmUsers.filters.isWaitingCompletionRequestValue",
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-utm-users-filters",
    templateUrl: "./utm-users-filters.component.html",
    styleUrls: ["./utm-users-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UtmUsersFiltersComponent implements OnInit {
    @Input()
    public set initialFilters(value: UtmUserFilters | undefined) {
        this.localStore.patchState({ initialFilters: value });
    }

    @Output() public readonly filtersChange: EventEmitter<UtmUserFilters> = new EventEmitter();

    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly filtersForm = new FormGroup<FiltersForm>({
        [UtmUsersFilterFormKeys.SearchText]: new FormControl("", { nonNullable: true }),
        [UtmUsersFilterFormKeys.Statuses]: new FormControl<UtmUserStatus[]>([], { nonNullable: true }),
        [UtmUsersFilterFormKeys.IsDefaultCountry]: new FormControl(NULL_VALUE, { nonNullable: true }),
        [UtmUsersFilterFormKeys.IsLegalAge]: new FormControl(NULL_VALUE, { nonNullable: true }),
        [UtmUsersFilterFormKeys.IsWaitingIdentityDocument]: new FormControl(NULL_VALUE, { nonNullable: true }),
        [UtmUsersFilterFormKeys.IsWaitingCompletionRequest]: new FormControl(NULL_VALUE, { nonNullable: true }),
    });
    protected readonly appliedFiltersLength$ = this.localStore.selectByKey("appliedFiltersLength");
    protected readonly statuses = Object.values(UtmUserStatus);
    protected readonly SINGLE_SELECT_VALUES = SINGLE_SELECT_VALUES;
    protected readonly defaultCountry = this.displayCountryService.getCountryByCountryCode(DEFAULT_COUNTRY_CODE)?.displayName;
    protected readonly filtersMap = FILTERS_MAP;
    protected readonly NULL_VALUE = NULL_VALUE;

    constructor(
        private readonly localStore: LocalComponentStore<UtmUsersFiltersComponentState>,
        private readonly displayCountryService: DisplayCountryService
    ) {
        this.localStore.setState({
            initialFilters: undefined,
            appliedFiltersLength: NULL_VALUE,
        });
    }

    public ngOnInit() {
        this.updateFiltersMapWithDefaultCountry();
        this.assignInitialFilters();
        this.handleFormChanges();
    }

    protected clearFilters() {
        this.filtersForm.reset();
    }

    private assignInitialFilters() {
        this.localStore
            .selectByKey("initialFilters")
            .pipe(first(), RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((initialFilters) => {
                this.filtersForm.setValue({
                    [UtmUsersFilterFormKeys.SearchText]: initialFilters.searchText ?? "",
                    [UtmUsersFilterFormKeys.Statuses]: initialFilters.statuses ?? [],
                    [UtmUsersFilterFormKeys.IsDefaultCountry]: initialFilters.isDefaultCountry ?? NULL_VALUE,
                    [UtmUsersFilterFormKeys.IsLegalAge]: initialFilters.isLegalAge ?? NULL_VALUE,
                    [UtmUsersFilterFormKeys.IsWaitingIdentityDocument]: initialFilters.isWaitingIdentityDocument ?? NULL_VALUE,
                    [UtmUsersFilterFormKeys.IsWaitingCompletionRequest]: initialFilters.isWaitingCompletionRequest ?? NULL_VALUE,
                });
            });
        this.setActiveFiltersCount();
    }

    private setActiveFiltersCount(): void {
        this.localStore.patchState({
            appliedFiltersLength: Object.values(this.filtersForm.getRawValue()).flat().filter(FunctionUtils.isTruthy).length,
        });
    }

    private handleFormChanges() {
        this.filtersForm.controls.isDefaultCountry.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
            if (value !== null) {
                return;
            }
            this.filtersForm.controls.isDefaultCountry.setValue(NULL_VALUE);
        });

        this.filtersForm.controls.isLegalAge.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
            if (value !== null) {
                return;
            }
            this.filtersForm.controls.isLegalAge.setValue(NULL_VALUE);
        });

        this.filtersForm.controls.isWaitingIdentityDocument.valueChanges
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe((value) => {
                if (value !== null) {
                    return;
                }
                this.filtersForm.controls.isWaitingIdentityDocument.setValue(NULL_VALUE);
            });
        this.filtersForm.controls.isWaitingCompletionRequest.valueChanges
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe((value) => {
                if (value !== null) {
                    return;
                }
                this.filtersForm.controls.isWaitingCompletionRequest.setValue(NULL_VALUE);
            });

        this.filtersForm.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(() => {
                this.setActiveFiltersCount();

                this.filtersChange.emit(this.filtersForm.getRawValue());
            });
    }

    private updateFiltersMapWithDefaultCountry() {
        for (const filter of this.filtersMap) {
            if (filter.key === UtmUsersFilterFormKeys.IsDefaultCountry) {
                filter.filterValueLabelParam = this.defaultCountry;
            }
        }
    }
}
