<div *ngIf="mission$ | ngrxPush as mission; else emptyDataTemplate" class="mission-data-container">
    <ng-container
        *ngTemplateOutlet="
            mission.phase === MissionProcessingPhase.CaaPermitApplication || (mission.category | invoke : isSoraApplication)
                ? caaPermitTemplate
                : defaultTemplate
        "
    ></ng-container>
    <ng-template #caaPermitTemplate>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionData.requestedPeriodLabel" | transloco }}</span>
            <span>{{ "dtmSharedMission.missionData.missionDateRangeDays" | transloco : { days: mission | invoke : getDaysBetween } }}</span>
        </div>
    </ng-template>
    <ng-template #defaultTemplate>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionData.plannedStartTimeLabel" | transloco }}</span>
            <span *ngIf="mission.isRoutePathBased">
                {{ mission.flightStartAtMin | localizeDate : { timeStyle: "short" } }}
                -
                {{ mission.flightStartAtMax | localizeDate : { timeStyle: "short" } }}
            </span>
            <span *ngIf="!mission.isRoutePathBased">
                {{
                    "dtmSharedMission.missionData.plannedStartTimeValueLabel"
                        | transloco : { value: (mission.flightStartAtMin | localizeDate : { timeStyle: "short" }) }
                }}
            </span>
        </div>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionData.plannedLandingTimeLabel" | transloco }}</span>
            <span *ngIf="mission.isRoutePathBased">
                {{ mission.flightFinishAtMin | localizeDate : { timeStyle: "short" } }}
                -
                {{ mission.flightFinishAtMax | localizeDate : { timeStyle: "short" } }}
            </span>
            <span *ngIf="!mission.isRoutePathBased">
                {{
                    "dtmSharedMission.missionData.plannedLandingTimeValueLabel"
                        | transloco : { value: (mission.flightFinishAtMax | localizeDate : { timeStyle: "short" }) }
                }}
            </span>
        </div>
        <div class="mission-field">
            <span class="field-label">{{ "dtmSharedMission.missionData.fullDurationLabel" | transloco }}</span>
            <span>
                <dtm-ui-time-diff-display
                    [firstDate]="mission.flightStartAtMin"
                    [secondDate]="mission.flightFinishAtMax"
                ></dtm-ui-time-diff-display>
            </span>
        </div>
    </ng-template>
    <div class="mission-field">
        <span class="field-label">{{ "dtmSharedMission.missionData.distanceLabel" | transloco }}</span>
        <span *ngIf="mission.isRoutePathBased">
            {{
                mission.distance === undefined
                    ? "-"
                    : ("dtmSharedMission.missionData.distanceValueInKilometers"
                      | transloco : { value: mission.distance | convertToKilometers | localizeNumber })
            }}
        </span>
        <span *ngIf="!mission.isRoutePathBased">-</span>
    </div>
    <div class="mission-field">
        <span class="field-label">{{ "dtmSharedMission.missionData.operatorLabel" | transloco }}</span>
        <span>{{ mission.operatorName ?? "-" }}</span>
    </div>
    <div class="mission-field">
        <span class="field-label">{{ "dtmSharedMission.missionData.pilotLabel" | transloco }}</span>
        <span>{{ mission.pilotName ?? "-" }}</span>
    </div>
    <div class="mission-field">
        <span class="field-label">{{ "dtmSharedMission.missionData.uavLabel" | transloco }}</span>
        <span>{{ mission.uavName ?? "-" }}</span>
    </div>
    <div class="mission-field">
        <span class="field-label">{{ "dtmSharedMission.missionData.uavSerialNumberLabel" | transloco }}</span>
        <span [matTooltip]="mission.uavSerialNumbers | join" [matTooltipDisabled]="mission.uavSerialNumbers.length < 2">
            <ng-container *ngIf="mission.uavSerialNumbers.length == 1">
                {{ mission.uavSerialNumbers[0] }}
            </ng-container>
            <ng-container *ngIf="mission.uavSerialNumbers.length > 1">
                {{ mission.uavSerialNumbers[0] }}, <span class="more-items">+{{ mission.uavSerialNumbers.length - 1 }}</span>
            </ng-container>
        </span>
    </div>
    <div class="mission-field">
        <span class="field-label">{{ "dtmSharedMission.missionData.trackerLabel" | transloco }}</span>
        <span>{{ mission.trackersIdentifiers | join : ", " : "-" }}</span>
    </div>
    <div class="mission-field">
        <span class="field-label">
            {{ "dtmSharedMission.missionData.authorizationBasis.label" | transloco }}
        </span>
        <ng-container *ngIf="mission.category | invoke : getOpenCategory as openCategory">
            <span>
                {{ "dtmSharedMission.missionData.authorizationBasis.openCategoryLabel" | transloco }}
                <span *ngIf="openCategory.scenarioName">- {{ openCategory.scenarioName }}</span>
            </span>
        </ng-container>
        <ng-container *ngIf="mission.category | invoke : getSpecificCategory as specificCategory">
            <span>
                {{ "dtmSharedMission.missionData.authorizationBasis.specificPermitLabel" | transloco }}
                <span [ngSwitch]="specificCategory.specificPermitType">
                    <ng-container *ngSwitchCase="MissionPlanSpecificPermitType.Sts">
                        - {{ (mission.category | invoke : getAsStsCategoryType)?.scenarioName }}
                    </ng-container>
                    <ng-container *ngSwitchCase="MissionPlanSpecificPermitType.Luc">
                        - {{ "dtmSharedMission.missionData.authorizationBasis.lucLabel" | transloco }}
                    </ng-container>
                    <ng-container *ngSwitchCase="MissionPlanSpecificPermitType.Individual">
                        - {{ "dtmSharedMission.missionData.authorizationBasis.individualLabel" | transloco }}
                    </ng-container>
                </span>
            </span>
        </ng-container>
    </div>
    <div class="mission-field">
        <span class="field-label">{{ "dtmSharedMission.missionData.purposeLabel" | transloco }}</span>
        <span>
            {{ (mission.flightPurpose.nameTranslationKey | systemTranslation) || "-" }}
        </span>
    </div>
    <div class="mission-field mission-field-full-column">
        <span class="field-label">{{ "dtmSharedMission.missionData.flightPurposeDescriptionLabel" | transloco }}</span>
        <p>
            {{ mission.flightPurpose.comment ?? "-" }}
        </p>
    </div>
</div>
<ng-template #emptyDataTemplate>
    <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
        <p messageSlot>{{ "dtmSharedMission.missionData.emptyData" | transloco }}</p>
    </dtm-ui-empty-state>
</ng-template>
