<div class="grid">
    <dtm-admin-lib-change-log-filters
        (filtersChange)="applyFilters($event)"
        [initialFilters]="filters$ | ngrxPush"
    ></dtm-admin-lib-change-log-filters>
    <dtm-admin-lib-change-log-list
        [changeLog]="changeLog$ | ngrxPush"
        [isProcessing]="isProcessing$ | ngrxPush"
        [changeLogError]="changeLogError$ | ngrxPush"
        [pagination]="pagination$ | ngrxPush"
        (reload)="refreshList()"
        (pageChange)="changePage($event)"
    ></dtm-admin-lib-change-log-list>
</div>
