import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ConversationCategoryCode, FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctUntilChanged, firstValueFrom } from "rxjs";
import { NULL_VALUE, SINGLE_SELECT_VALUES } from "../../../../shared";
import { ConversationFilterFormKeys, ConversationThreadFilterValue } from "../../../services/conversation.models";

const FILTERS_MAP: FiltersMap[] = [
    {
        key: ConversationFilterFormKeys.Categories,
        filterLabel: "dtmAdminLibConversation.threadContainerFilters.categorySelectionLabel",
        filterValueLabel: "dtmUi.conversations.categoryLabel",
    },
    {
        key: ConversationFilterFormKeys.Assignment,
        filterLabel: "dtmAdminLibConversation.threadContainerFilters.assignmentFormLabel",
        filterValueLabel: "dtmAdminLibConversation.threadContainerFilters.assignedFilterValueLabel",
    },
    {
        key: ConversationFilterFormKeys.Read,
        filterLabel: "dtmAdminLibConversation.threadContainerFilters.readFilterLabel",
        filterValueLabel: "dtmAdminLibConversation.threadContainerFilters.readFilterValueLabel",
    },
    {
        key: ConversationFilterFormKeys.SearchByText,
        filterLabel: "dtmAdminLibConversation.threadContainerFilters.searchLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: ConversationFilterFormKeys.Closed,
        filterLabel: "dtmAdminLibConversation.threadContainerFilters.closedFilterLabel",
        filterValueLabel: "dtmAdminLibConversation.threadContainerFilters.closedFilterValueLabel",
    },
];

interface FiltersContainerComponentState {
    userAssignedCategories: ConversationCategoryCode[] | undefined;
    categories: ConversationCategoryCode[] | undefined;
    filtersCount: number;
    initialFilters: ConversationThreadFilterValue | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-filters-container[categories]",
    templateUrl: "./filters-container.component.html",
    styleUrls: ["./filters-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FiltersContainerComponent implements OnInit {
    @Input() public set categories(value: ConversationCategoryCode[] | undefined) {
        this.localStore.patchState({ categories: value });
    }

    @Input() public set userAssignedCategories(value: ConversationCategoryCode[] | undefined) {
        this.localStore.patchState({ userAssignedCategories: value });
    }

    @Input() public set initialFilters(value: ConversationThreadFilterValue | undefined) {
        this.localStore.patchState({ initialFilters: value });
    }

    @Output() public filtersChange: EventEmitter<ConversationThreadFilterValue> = new EventEmitter();
    @Output() public areFiltersAppliedChange: EventEmitter<boolean> = new EventEmitter();

    public readonly categories$ = this.localStore.selectByKey("categories");
    public readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    public readonly ConversationFilterFormKeys = ConversationFilterFormKeys;
    public readonly FILTERS_MAP = FILTERS_MAP;
    public readonly SINGLE_SELECT_VALUES = SINGLE_SELECT_VALUES;
    public readonly categoryControl = new UntypedFormControl(null);
    public readonly textSearchControl = new UntypedFormControl();
    public readonly assignmentFilterControl = new UntypedFormControl(null);
    public readonly readFilterControl = new UntypedFormControl(null);
    public readonly closedFilterControl = new UntypedFormControl(null);

    public readonly filtersFormGroup = new UntypedFormGroup({
        [ConversationFilterFormKeys.Categories]: this.categoryControl,
        [ConversationFilterFormKeys.SearchByText]: this.textSearchControl,
        [ConversationFilterFormKeys.Assignment]: this.assignmentFilterControl,
        [ConversationFilterFormKeys.Read]: this.readFilterControl,
        [ConversationFilterFormKeys.Closed]: this.closedFilterControl,
    });

    constructor(private localStore: LocalComponentStore<FiltersContainerComponentState>) {
        localStore.setState({
            categories: undefined,
            filtersCount: 0,
            userAssignedCategories: undefined,
            initialFilters: undefined,
        });
    }

    public ngOnInit() {
        this.assignInitialFiltersAndWatchChanges();
    }

    public clearFilters() {
        this.filtersFormGroup.reset({
            [ConversationFilterFormKeys.Categories]: [],
            [ConversationFilterFormKeys.SearchByText]: "",
            [ConversationFilterFormKeys.Assignment]: NULL_VALUE,
            [ConversationFilterFormKeys.Read]: NULL_VALUE,
            [ConversationFilterFormKeys.Closed]: NULL_VALUE,
        });
    }

    private watchFormChanges() {
        this.assignmentFilterControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
            if (value !== null) {
                return;
            }
            this.assignmentFilterControl.setValue(NULL_VALUE);
        });

        this.readFilterControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
            if (value !== null) {
                return;
            }
            this.readFilterControl.setValue(NULL_VALUE);
        });

        this.closedFilterControl.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
            if (value !== null) {
                return;
            }
            this.closedFilterControl.setValue(NULL_VALUE);
        });

        this.filtersFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.updateFiltersCount();

            const valueToEmit = Object.keys(this.filtersFormGroup.value).reduce((value, key) => {
                const keyFormControl = this.filtersFormGroup.get(key);

                if (keyFormControl?.valid) {
                    return { ...value, [key]: keyFormControl?.value };
                }

                return value;
            }, {});

            this.filtersChange.emit(valueToEmit);
        });
    }

    private updateFiltersCount() {
        if (this.filtersFormGroup.valid) {
            const filtersCount = Object.values(this.filtersFormGroup.value).flat().filter(Boolean).length;
            this.localStore.patchState({ filtersCount });
            this.areFiltersAppliedChange.emit(!!filtersCount);
        }
    }

    private async assignInitialFiltersAndWatchChanges() {
        const initialFilters = await firstValueFrom(this.localStore.selectByKey("initialFilters").pipe(RxjsUtils.filterFalsy()));

        this.filtersFormGroup.setValue(initialFilters);
        this.watchFormChanges();
        this.updateFiltersCount();
    }
}
