import { Injectable } from "@angular/core";
import {
    AssociationOperator,
    AssociationPermit,
    CaaContactPerson,
    Competency,
    DtmLocations,
    Operator,
    Page,
    Permit,
} from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { CrossBorderPermit, PermitsError, PermitsErrorType, SpecificPermit, SpecificPermitListWithPages } from "../models/permits.models";
import { getPermitWithCapabilities } from "../services/permit.utils";
import { PermitsApiService } from "../services/permits-api.service";
import { PermitsActions } from "./permits.actions";

interface PermitsStateModel {
    specificPermits: SpecificPermit[] | undefined;
    crossBorderPermits: CrossBorderPermit[] | undefined;
    permit: Permit | undefined;
    specificPermitsError: PermitsError | undefined;
    capabilitiesError: PermitsError | undefined;
    operatorListError: PermitsError | undefined;
    addPermitError: PermitsError | undefined;
    updatePermitError: PermitsError | undefined;
    permitError: PermitsError | undefined;
    updatePermitStatusError: PermitsError | undefined;
    isSpecificPermitsProcessing: boolean;
    specificPermitsPages: Page | undefined;
    operators: Operator[] | undefined;
    dtmLocations: DtmLocations[] | undefined;
    caaUsers: CaaContactPerson[] | undefined;
    competencies: Competency[] | undefined;
    isProcessing: boolean;
    kmlFileForPreview: string | undefined;
    kmlFileForPreviewError: PermitsError | undefined;
    associationOperators: AssociationOperator[] | undefined;
    associationPermit: AssociationPermit | undefined;
}

const defaultState: PermitsStateModel = {
    specificPermits: undefined,
    crossBorderPermits: undefined,
    permit: undefined,
    specificPermitsPages: undefined,
    specificPermitsError: undefined,
    capabilitiesError: undefined,
    operatorListError: undefined,
    addPermitError: undefined,
    updatePermitError: undefined,
    permitError: undefined,
    updatePermitStatusError: undefined,
    isSpecificPermitsProcessing: false,
    dtmLocations: undefined,
    caaUsers: undefined,
    operators: undefined,
    competencies: undefined,
    isProcessing: false,
    kmlFileForPreview: undefined,
    kmlFileForPreviewError: undefined,
    associationOperators: undefined,
    associationPermit: undefined,
};

@State<PermitsStateModel>({
    name: "permits",
    defaults: defaultState,
})
@Injectable()
export class PermitsState {
    constructor(private readonly permitsApiService: PermitsApiService) {}

    @Selector()
    public static isSpecificPermitsProcessing(state: PermitsStateModel): boolean {
        return state.isSpecificPermitsProcessing;
    }

    @Selector()
    public static specificPermits(state: PermitsStateModel): SpecificPermit[] | undefined {
        return state.specificPermits;
    }
    @Selector()
    public static crossBorderPermits(state: PermitsStateModel): CrossBorderPermit[] | undefined {
        return state.crossBorderPermits;
    }

    @Selector()
    public static dtmLocations(state: PermitsStateModel): DtmLocations[] | undefined {
        return state.dtmLocations;
    }

    @Selector()
    public static caaUsers(state: PermitsStateModel): CaaContactPerson[] | undefined {
        return state.caaUsers;
    }

    @Selector()
    public static associationPermit(state: PermitsStateModel): AssociationPermit | undefined {
        return state.associationPermit;
    }

    @Selector()
    public static operators(state: PermitsStateModel): Operator[] | undefined {
        return state.operators;
    }

    @Selector()
    public static competencies(state: PermitsStateModel): DtmLocations[] | undefined {
        return state.competencies;
    }

    @Selector()
    public static specificPermitsError(state: PermitsStateModel): PermitsError | undefined {
        return state.specificPermitsError;
    }

    @Selector()
    public static capabilitiesError(state: PermitsStateModel): PermitsError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static operatorListError(state: PermitsStateModel): PermitsError | undefined {
        return state.operatorListError;
    }

    @Selector()
    public static addPermitError(state: PermitsStateModel): PermitsError | undefined {
        return state.addPermitError;
    }

    @Selector()
    public static updatePermitError(state: PermitsStateModel): PermitsError | undefined {
        return state.updatePermitError;
    }

    @Selector()
    public static updatePermitStatusError(state: PermitsStateModel): PermitsError | undefined {
        return state.updatePermitStatusError;
    }

    @Selector()
    public static isProcessing(state: PermitsStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static specificPermitsPages(state: PermitsStateModel): Page | undefined {
        return state.specificPermitsPages;
    }

    @Selector()
    public static permit(state: PermitsStateModel): Permit | undefined {
        return state.permit;
    }

    @Selector()
    public static permitError(state: PermitsStateModel): PermitsError | undefined {
        return state.permitError;
    }

    @Selector()
    public static kmlFileForPreviewError(state: PermitsStateModel): PermitsError | undefined {
        return state.kmlFileForPreviewError;
    }

    @Selector()
    public static kmlFileForPreview(state: PermitsStateModel): string | undefined {
        return state.kmlFileForPreview;
    }

    @Selector()
    public static associationOperators(state: PermitsStateModel): AssociationOperator[] | undefined {
        return state.associationOperators;
    }

    @Action(PermitsActions.GetSpecificPermits)
    public getSpecificPermitsList(context: StateContext<PermitsStateModel>, action: PermitsActions.GetSpecificPermits) {
        context.patchState({ isSpecificPermitsProcessing: true, specificPermitsError: undefined });

        return this.permitsApiService.getSpecificPermits(action.filtersQuery, action.activeTab).pipe(
            tap((result: SpecificPermitListWithPages) => {
                context.patchState({
                    specificPermits: result.content,
                    specificPermitsPages: {
                        pageSize: result.pageSize,
                        pageNumber: result.pageNumber,
                        totalElements: result.totalElements,
                    },
                });
            }),
            catchError((specificPermitsError) => {
                context.patchState({ specificPermitsError, specificPermits: [] });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isSpecificPermitsProcessing: false,
                });
            })
        );
    }

    @Action(PermitsActions.GetOperatorList)
    public getOperatorList(context: StateContext<PermitsStateModel>, action: PermitsActions.GetOperatorList) {
        context.patchState({ operatorListError: undefined, isProcessing: true });

        return this.permitsApiService.getOperatorList(action.searchText).pipe(
            tap((operators) => {
                context.patchState({ operators });
            }),
            catchError((error) => {
                context.patchState({ operatorListError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.GetAssociationOperatorList)
    public GetAssociationOperatorList(context: StateContext<PermitsStateModel>, action: PermitsActions.GetAssociationOperatorList) {
        context.patchState({ operatorListError: undefined, isProcessing: true });

        return this.permitsApiService.getAssociationOperatorList(action.searchText).pipe(
            tap((associationOperators) => {
                context.patchState({ associationOperators });
            }),
            catchError((error) => {
                context.patchState({ operatorListError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.GetSpecificPermitsCapabilities)
    public getSpecificPermitsCapabilities(context: StateContext<PermitsStateModel>) {
        context.patchState({ capabilitiesError: undefined, isProcessing: true });

        return this.permitsApiService.getSpecificPermitsCapabilities().pipe(
            tap((capabilities) => {
                context.patchState({
                    dtmLocations: capabilities.dtmLocations,
                    competencies: capabilities.competencies,
                    caaUsers: capabilities.caaUsers,
                });
            }),
            catchError((error) => {
                context.patchState({ capabilitiesError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.RetryFetchData)
    public retryFetchData(context: StateContext<PermitsStateModel>) {
        const { capabilitiesError, operatorListError } = context.getState();

        if (capabilitiesError) {
            context.dispatch(new PermitsActions.GetSpecificPermitsCapabilities());
        }

        if (operatorListError) {
            context.dispatch(new PermitsActions.GetOperatorList());
        }
    }

    @Action(PermitsActions.RetryFetchAssociationPermitRequestData)
    public retryFetchAssociationPermitRequestData(context: StateContext<PermitsStateModel>) {
        const { capabilitiesError, operatorListError } = context.getState();

        if (capabilitiesError) {
            context.dispatch(new PermitsActions.GetSpecificPermitsCapabilities());
        }

        if (operatorListError) {
            context.dispatch(new PermitsActions.GetAssociationOperatorList());
        }
    }

    @Action(PermitsActions.AddPermit)
    public addPermit(context: StateContext<PermitsStateModel>, action: PermitsActions.AddPermit) {
        context.patchState({ addPermitError: undefined, isProcessing: true });

        return this.permitsApiService.addPermit(action.applicationPermitData).pipe(
            catchError((error) => {
                context.patchState({ addPermitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.UpdatePermit)
    public updatePermit(context: StateContext<PermitsStateModel>, action: PermitsActions.UpdatePermit) {
        context.patchState({ updatePermitError: undefined, isProcessing: true });

        return this.permitsApiService.updatePermit(action.applicationPermitData, action.permitId).pipe(
            catchError((error) => {
                context.patchState({ updatePermitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.AddCrossBorderPermit)
    public addCrossBorderPermit(context: StateContext<PermitsStateModel>, action: PermitsActions.AddCrossBorderPermit) {
        context.patchState({ addPermitError: undefined, isProcessing: true });

        return this.permitsApiService.addCrossBorderPermit(action.applicationPermitData).pipe(
            catchError((error) => {
                context.patchState({ addPermitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.UpdateCrossBorderPermit)
    public updateCrossBorderPermit(context: StateContext<PermitsStateModel>, action: PermitsActions.UpdateCrossBorderPermit) {
        context.patchState({ updatePermitError: undefined, isProcessing: true });

        return this.permitsApiService.updateCrossBorderPermit(action.applicationPermitData, action.permitId).pipe(
            catchError((error) => {
                context.patchState({ updatePermitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.AddAssociationPermit)
    public addAssociationPermit(context: StateContext<PermitsStateModel>, action: PermitsActions.AddAssociationPermit) {
        context.patchState({ addPermitError: undefined, isProcessing: true });

        return this.permitsApiService.addAssociationPermit(action.applicationPermitData).pipe(
            catchError((error) => {
                context.patchState({ addPermitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
    @Action(PermitsActions.UpdateAssociationPermit)
    public updateAssociationPermit(context: StateContext<PermitsStateModel>, action: PermitsActions.UpdateAssociationPermit) {
        context.patchState({ updatePermitError: undefined, isProcessing: true });

        if (!action.permit.id) {
            return;
        }

        return this.permitsApiService.updateAssociationPermit(action.permit.id, action.permit).pipe(
            catchError((error) => {
                context.patchState({ updatePermitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.GetSpecificPermitDetails)
    public getSpecificPermitDetails(context: StateContext<PermitsStateModel>, action: PermitsActions.GetSpecificPermitDetails) {
        const { dtmLocations, competencies } = context.getState();
        context.patchState({ isProcessing: true, permitError: undefined, permit: undefined });

        if (!dtmLocations || !competencies) {
            context.patchState({ capabilitiesError: { type: PermitsErrorType.CannotGetCapabilities } });

            return;
        }

        return this.permitsApiService.getSpecificPermitDetails(action.permitId).pipe(
            tap((response) => {
                context.patchState({ permit: getPermitWithCapabilities(response, competencies, dtmLocations) });
            }),
            catchError((error) => {
                context.patchState({ permitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.GetCrossBorderSpecificPermitDetails)
    public getCrossBorderSpecificPermitDetails(
        context: StateContext<PermitsStateModel>,
        action: PermitsActions.GetCrossBorderSpecificPermitDetails
    ) {
        const { dtmLocations, competencies } = context.getState();
        context.patchState({ isProcessing: true, permitError: undefined, permit: undefined });

        if (!dtmLocations || !competencies) {
            context.patchState({ capabilitiesError: { type: PermitsErrorType.CannotGetCapabilities } });

            return;
        }

        return this.permitsApiService.getCrossBorderSpecificPermitDetails(action.permitId).pipe(
            tap((response) => {
                context.patchState({ permit: getPermitWithCapabilities(response, competencies, dtmLocations) });
            }),
            catchError((error) => {
                context.patchState({ permitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.GetAssociationPermitDetails)
    public getAssociationPermitDetails(context: StateContext<PermitsStateModel>, action: PermitsActions.GetAssociationPermitDetails) {
        context.patchState({ isProcessing: true, permitError: undefined, associationPermit: undefined });

        return this.permitsApiService.getAssociationPermitDetails(action.permitId).pipe(
            tap((response) => {
                context.patchState({ associationPermit: response });
            }),
            catchError((error) => {
                context.patchState({ permitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(PermitsActions.ClearSpecificPermitDetails)
    public clearSpecificPermitDetails(context: StateContext<PermitsStateModel>) {
        context.patchState({ permit: undefined });

        return EMPTY;
    }

    @Action(PermitsActions.ClearAssociationPermitDetails)
    public clearAssociationPermitDetails(context: StateContext<PermitsStateModel>) {
        context.patchState({ associationPermit: undefined });

        return EMPTY;
    }

    @Action(PermitsActions.UpdatePermitStatus)
    public updatePermitStatus(context: StateContext<PermitsStateModel>, { action, reason, permitId }: PermitsActions.UpdatePermitStatus) {
        context.patchState({ isSpecificPermitsProcessing: true, updatePermitStatusError: undefined });

        return this.permitsApiService.updatePermitStatus(action, reason, permitId).pipe(
            catchError((error) => {
                context.patchState({ updatePermitStatusError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isSpecificPermitsProcessing: false }))
        );
    }

    @Action(PermitsActions.GetKmlPreview)
    public getKmlPreview(context: StateContext<PermitsStateModel>, action: PermitsActions.GetKmlPreview) {
        context.patchState({ isSpecificPermitsProcessing: true, kmlFileForPreviewError: undefined });

        return this.permitsApiService.getKmlFile(action.file).pipe(
            tap((kmlFileForPreview) => context.patchState({ kmlFileForPreview })),
            catchError((error) => {
                context.patchState({ kmlFileForPreviewError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isSpecificPermitsProcessing: false }))
        );
    }
}
