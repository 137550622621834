<ng-container *ngrxLet="data.isProcessing$ as isProcessing">
    <div class="dialog-header">
        <h2 mat-dialog-title>
            {{
                (data.actionType === OtherInformationActionType.Add
                    ? "dtmAdminLibShared.operatorProfile.otherInformationDialog.addHeader"
                    : "dtmAdminLibShared.operatorProfile.otherInformationDialog.editHeader"
                ) | transloco
            }}
        </h2>
        <button type="button" class="button-icon" [matDialogClose]="false" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <dtm-ui-textarea-field [maxLength]="MAX_OTHER_INFORMATION_LENGTH">
            <textarea matInput [formControl]="otherInformationControl" [rows]="8"></textarea>
            <div class="field-error" *dtmUiFieldHasError="otherInformationControl; name: 'maxlength'; error as error">
                {{
                    "dtmAdminLibShared.operatorProfile.otherInformationDialog.otherInformationMaxLengthError"
                        | transloco : { maxLength: error.requiredLength }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="otherInformationControl; name: 'required'">
                {{ "dtmAdminLibShared.operatorProfile.otherInformationDialog.otherInformationRequiredError" | transloco }}
            </div>
        </dtm-ui-textarea-field>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [mat-dialog-close]="false" [disabled]="isProcessing">
            {{ "dtmAdminLibShared.operatorProfile.otherInformationDialog.cancelLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="confirm()" [disabled]="isProcessing">
            {{
                (data.actionType === OtherInformationActionType.Add
                    ? "dtmAdminLibShared.operatorProfile.otherInformationDialog.addLabel"
                    : "dtmAdminLibShared.operatorProfile.otherInformationDialog.editLabel"
                ) | transloco
            }}
        </button>
    </div>
</ng-container>
