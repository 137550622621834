import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ErrorMode } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";
import { DashboardActions } from "../../state/dashboard.actions";
import { DashboardState } from "../../state/dashboard.state";

@Component({
    selector: "dtm-admin-lib-dashboard-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
    protected readonly userConversationsStats$ = this.store.select(DashboardState.userConversationsStats);
    protected readonly operatorStats$ = this.store.select(DashboardState.operatorsStats);
    protected readonly usersStats$ = this.store.select(DashboardState.usersStats);
    protected readonly assignedCategories$ = this.store.select(DashboardState.assignedCategories);
    protected readonly dashboardDetailsError$ = this.store.select(DashboardState.dashboardDetailsError);
    protected readonly ErrorMode = ErrorMode;

    constructor(private readonly store: Store) {}

    protected refreshStats() {
        this.store.dispatch(new DashboardActions.GetDashboardDetails());
    }
}
