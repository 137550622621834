<ng-container *ngrxLet="{ routeData: routeData$ } as vm">
    <ac-map *ngIf="isInitialized$ | ngrxPush" dtmMapPointerManager>
        <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
        <dtm-map-route-viewer-layer
            [routeData]="vm.routeData"
            [drawableFeatures]="routeDrawableFeature"
            [isShown]="true"
            (entityZoom)="flyToGeometry($event)"
        ></dtm-map-route-viewer-layer>
        <!-- TODO: will be implemented later in REJ-2904 -->
        <!-- <div class="layers-controls">-->
        <!--     <dtm-map-layers-with-controls-->
        <!--         isGeoZonesFiltersFullMode-->
        <!--         [missionTimeRange]="missionTimeRange$ | ngrxPush"-->
        <!--         [shouldShowAreaBuffers]="shouldShowAreaBuffers$ | ngrxPush"-->
        <!--         [routes]="vm.routeData | invoke : getRoutesFromRouteData"-->
        <!--         [timeSettingOptions]="{ available: [ZoneTimesSetting.MissionTime], default: ZoneTimesSetting.MissionTime }"-->
        <!--     ></dtm-map-layers-with-controls>-->
        <!-- </div>-->
    </ac-map>
    <dtm-map-controls></dtm-map-controls>
</ng-container>
