import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AssociationOperationType, AssociationPermit, InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { AssociationPermitWizardPermitDetails } from "../../../../models/association-permit.models";

interface AssociationPermitRequestPermitDetailsComponentState {
    isProcessing: boolean;
}

interface ExpirationDateForm {
    dateFrom: FormControl<Date | null>;
    dateTo: FormControl<Date | null>;
}

const PERMIT_NUMBER_MAX_LENGTH = 100;
const FLIGHT_PURPOSE_MAX_LENGTH = 200;
const LOCATIONS_MAX_LENGTH = 2000;

@Component({
    selector: "dtm-admin-lib-association-permit-request-permit-details",
    templateUrl: "./association-permit-request-permit-details.component.html",
    styleUrls: ["./association-permit-request-permit-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AssociationPermitRequestPermitDetailsComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set existingAssociationPermit(value: AssociationPermit | undefined) {
        if (!value) {
            return;
        }
        this.assignPermitDetailsToForm(value);
    }
    @Output() public readonly permitDetailsSave = new EventEmitter<AssociationPermitWizardPermitDetails>();
    @Output() public readonly back = new EventEmitter<void>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly FLIGHT_PURPOSE_MAX_LENGTH = FLIGHT_PURPOSE_MAX_LENGTH;
    protected readonly LOCATIONS_MAX_LENGTH = LOCATIONS_MAX_LENGTH;
    protected readonly dateFromControl = new FormControl(null, Validators.required);
    protected readonly dateToControl = new FormControl(null, Validators.required);
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly permitDetailsForm = new FormGroup({
        permitNumber: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(PERMIT_NUMBER_MAX_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        expirationDate: new FormGroup<ExpirationDateForm>({
            dateFrom: this.dateFromControl,
            dateTo: this.dateToControl,
        }),
        flightPurpose: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(FLIGHT_PURPOSE_MAX_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        locations: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(LOCATIONS_MAX_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        operationType: new FormControl<AssociationOperationType | null>(null, {
            validators: [Validators.required],
        }),
        isInsurance: new FormControl<boolean | null>(null, {
            validators: [Validators.required],
        }),
    });

    constructor(
        private readonly localStore: LocalComponentStore<AssociationPermitRequestPermitDetailsComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        localStore.setState({ isProcessing: false });
    }

    protected validateFormAndGoToNextStep() {
        this.permitDetailsForm.markAllAsTouched();

        if (this.permitDetailsForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.permitDetailsSave.emit(this.permitDetailsForm.getRawValue() as AssociationPermitWizardPermitDetails);
    }

    private assignPermitDetailsToForm(value: AssociationPermit) {
        const { permitNumber, expirationDate, flightPurpose, locations, operationType, isInsurance } = value;
        this.permitDetailsForm.patchValue({
            permitNumber,
            expirationDate,
            flightPurpose,
            locations,
            operationType,
            isInsurance,
        });
        this.permitDetailsForm.controls.permitNumber.disable();
    }
}
