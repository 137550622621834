import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "displayExamCode",
})
export class DisplayExamCodePipe implements PipeTransform {
    public transform(value: string): string {
        return value === "A1A3" ? "A1/A3" : value;
    }
}
