import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AssociationOperator, AssociationPermit, Operator, Permit, UploadedFile } from "@dtm-frontend/shared/ui";
import { DateUtils, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {
    PermitFilterParams,
    PermitStatus,
    PermitsError,
    PermitsErrorType,
    PermitsTab,
    SpecificPermitListWithPages,
    SpecificPermitsCapabilities,
} from "../models/permits.models";
import { PERMITS_ENDPOINTS, PermitsEndpoints } from "../permits.tokens";
import { RawPermitDetails } from "./permit.utils";
import {
    AssociationPermitResponseBody,
    CrossBorderSpecificPermitResponseBody,
    GetKmlLocationResponseBody,
    GetPermitsListResponseBody,
    SpecificPermitWithUavResponseBody,
    convertAssociationPermitFormDataToAssociationSpecificPermitRequestBody,
    convertAssociationPermitResponseBodyToAssociationPermit,
    convertCrossBorderPermitFormDataToCrossBorderPermitRequestPayload,
    convertCrossBorderSpecificPermitResponseBodyToRawPermitDetails,
    convertGetPermitListResponseBodyToPermitListWithPages,
    convertPermitFormDataToSpecificPermitRequestPayload,
    convertSpecificPermitResponseBodyToRawPermitDetails,
} from "./permits-api.converters";

@Injectable({
    providedIn: "root",
})
export class PermitsApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(PERMITS_ENDPOINTS) private readonly endpoints: PermitsEndpoints) {}

    public getSpecificPermits(filters: PermitFilterParams, activeTab: PermitsTab): Observable<SpecificPermitListWithPages> {
        const params: HttpParams = this.getSpecificPermitsParams(filters);
        let endpoint = this.endpoints.managePermits;
        switch (activeTab) {
            case PermitsTab.SpecificPermit:
                endpoint = this.endpoints.managePermits;
                break;
            case PermitsTab.Association:
                endpoint = this.endpoints.manageAssociationPermits;
                break;
            case PermitsTab.CrossBorder:
                endpoint = this.endpoints.manageCrossBorderPermits;
        }

        return this.httpClient.get<GetPermitsListResponseBody>(endpoint, { params }).pipe(
            map((response) => convertGetPermitListResponseBodyToPermitListWithPages(response)),
            catchError(() => throwError(() => ({ type: PermitsErrorType.Unknown })))
        );
    }

    public getOperatorList(searchPhrase?: string): Observable<Operator[]> {
        let params;

        if (searchPhrase) {
            params = new HttpParams().set("searchText", searchPhrase);
        }

        return this.httpClient
            .get<Operator[]>(this.endpoints.getOperatorList, { params })
            .pipe(catchError(() => throwError(() => ({ type: PermitsErrorType.CannotGetOperatorList }))));
    }

    public getAssociationOperatorList(searchPhrase?: string): Observable<AssociationOperator[]> {
        const params = searchPhrase ? new HttpParams().set("searchText", searchPhrase) : undefined;

        return this.httpClient
            .get<AssociationOperator[]>(this.endpoints.getAssociationOperatorList, { params })
            .pipe(catchError(() => throwError(() => ({ type: PermitsErrorType.CannotGetOperatorList }))));
    }

    public getSpecificPermitsCapabilities(): Observable<SpecificPermitsCapabilities> {
        return this.httpClient
            .get<SpecificPermitsCapabilities>(this.endpoints.getSpecificPermitsCapabilities)
            .pipe(catchError(() => throwError(() => ({ type: PermitsErrorType.CannotGetCapabilities }))));
    }

    public addPermit(applicationPermitData: Permit): Observable<void> {
        const payload = convertPermitFormDataToSpecificPermitRequestPayload(applicationPermitData);

        return this.httpClient
            .post<void>(this.endpoints.managePermits, payload)
            .pipe(catchError((error) => throwError(() => this.getAddOrEditSpecificPermitError(error))));
    }

    public updatePermit(applicationPermitData: Permit, permitId: string): Observable<void> {
        const payload = convertPermitFormDataToSpecificPermitRequestPayload(applicationPermitData);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateSpecificPermit, { permitId }), payload)
            .pipe(catchError((error) => throwError(() => this.getAddOrEditSpecificPermitError(error, true))));
    }

    public addCrossBorderPermit(applicationPermitData: Permit): Observable<void> {
        const payload = convertCrossBorderPermitFormDataToCrossBorderPermitRequestPayload(applicationPermitData);

        return this.httpClient
            .post<void>(this.endpoints.manageCrossBorderPermits, payload)
            .pipe(catchError(() => throwError(() => ({ type: PermitsErrorType.CannotSavePermit }))));
    }

    public updateCrossBorderPermit(applicationPermitData: Permit, permitId: string): Observable<void> {
        const payload = convertCrossBorderPermitFormDataToCrossBorderPermitRequestPayload(applicationPermitData);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateCrossBorderPermit, { permitId }), payload)
            .pipe(catchError(() => throwError(() => ({ type: PermitsErrorType.CannotSavePermit }))));
    }

    public addAssociationPermit(applicationPermitData: AssociationPermit): Observable<void> {
        const payload = convertAssociationPermitFormDataToAssociationSpecificPermitRequestBody(applicationPermitData);

        return this.httpClient
            .post<void>(this.endpoints.manageAssociationPermits, payload)
            .pipe(catchError(() => throwError(() => ({ type: PermitsErrorType.CannotSavePermit }))));
    }

    public updateAssociationPermit(permitId: string, applicationPermitData: AssociationPermit): Observable<void> {
        const payload = convertAssociationPermitFormDataToAssociationSpecificPermitRequestBody(applicationPermitData);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.manageAssociationPermitDetails, { id: permitId }), payload)
            .pipe(catchError(() => throwError(() => ({ type: PermitsErrorType.CannotUpdatePermit }))));
    }

    public getSpecificPermitDetails(permitId: string): Observable<RawPermitDetails> {
        return this.httpClient
            .get<SpecificPermitWithUavResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getPermitDetails, { id: permitId }))
            .pipe(
                map((response) => convertSpecificPermitResponseBodyToRawPermitDetails(response)),
                catchError(() => throwError(() => ({ type: PermitsErrorType.CannotGetPermitDetails })))
            );
    }

    public getCrossBorderSpecificPermitDetails(permitId: string): Observable<RawPermitDetails> {
        return this.httpClient
            .get<CrossBorderSpecificPermitResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getCrossBorderPermitDetails, { id: permitId })
            )
            .pipe(
                map((response) => convertCrossBorderSpecificPermitResponseBodyToRawPermitDetails(response)),
                catchError(() => throwError(() => ({ type: PermitsErrorType.CannotGetPermitDetails })))
            );
    }

    public getAssociationPermitDetails(permitId: string): Observable<AssociationPermit> {
        return this.httpClient
            .get<AssociationPermitResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.manageAssociationPermitDetails, { id: permitId })
            )
            .pipe(
                map((response) => convertAssociationPermitResponseBodyToAssociationPermit(response)),
                catchError(() => throwError(() => ({ type: PermitsErrorType.CannotGetPermitDetails })))
            );
    }

    public updatePermitStatus(status: PermitStatus, statusReason: string, permitId: string): Observable<void> {
        const payload = { status, statusReason };

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateSpecificPermitStatus, { id: permitId }), payload)
            .pipe(catchError(() => throwError(() => ({ type: PermitsErrorType.CannotUpdatePermit }))));
    }

    private getSpecificPermitsParams(filters: PermitFilterParams): HttpParams {
        let params = new HttpParams().set("page", `${filters?.page ?? 0}`);

        if (filters.size) {
            params = params.append("size", filters.size);
        }

        if (filters.searchByText) {
            params = params.append("searchPhrase", filters.searchByText);
        }

        if (filters.status) {
            params = params.append("statuses", `${filters.status}`);
        }

        if (filters.dateFrom) {
            params = params.append("validityPeriodStart", DateUtils.getISOStringDate(filters.dateFrom.toISOString()));
        }

        if (filters.dateTo) {
            params = params.append("validityPeriodFinish", DateUtils.getISOStringDate(filters.dateTo.toISOString()));
        }

        return params;
    }

    public getKmlFile(file: UploadedFile): Observable<string> {
        return this.httpClient
            .get<GetKmlLocationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.downloadKmlLocationFile, { kmlFileId: file.id }),
                {
                    observe: "body",
                    context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
                }
            )
            .pipe(
                map((response) => response.originLocationView),
                catchError(() => throwError(() => ({ type: PermitsErrorType.CannotGetKmlFile })))
            );
    }

    private getAddOrEditSpecificPermitError(error: HttpErrorResponse, isUpdate?: boolean): PermitsError {
        const isForbiddenAssuranceAndIntegrityLevel =
            error.error[0]?.fieldErrors[0]?.fieldName === "assuranceAndIntegrityLevel" && error.error[0]?.fieldErrors[0]?.code === "Range";

        if (isForbiddenAssuranceAndIntegrityLevel) {
            return { type: PermitsErrorType.ForbiddenAssuranceAndIntegrityLevel };
        }

        return { type: isUpdate ? PermitsErrorType.CannotUpdatePermit : PermitsErrorType.CannotSavePermit };
    }
}
