import { Injectable } from "@angular/core";
import { CaaConversationNotificationType } from "../models/notification.models";

@Injectable({
    providedIn: "root",
})
export class CaaNotificationsService {
    public getAllowedNotifications(): string[] {
        // NOTE concat when other notification types are allowed in CAA
        return this.getConversationNotificationTypes();
    }

    public getConversationNotificationTypes(): CaaConversationNotificationType[] {
        return Object.values(CaaConversationNotificationType);
    }
}
