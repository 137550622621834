import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Observable, Subject } from "rxjs";
import { OtherInformationActionType } from "../../models/operator.models";

const MAX_OTHER_INFORMATION_LENGTH = 500;

@Component({
    selector: "dtm-admin-lib-add-or-edit-other-information-dialog",
    templateUrl: "./add-or-edit-other-information-dialog.component.html",
    styleUrls: ["./add-or-edit-other-information-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddOrEditOtherInformationDialogComponent {
    private otherInfoTextSubject = new Subject<string>();
    public otherInfoText$ = this.otherInfoTextSubject.asObservable();
    protected readonly OtherInformationActionType = OtherInformationActionType;
    protected readonly MAX_OTHER_INFORMATION_LENGTH = MAX_OTHER_INFORMATION_LENGTH;
    protected readonly otherInformationControl = new FormControl<string>("", {
        validators: [Validators.maxLength(MAX_OTHER_INFORMATION_LENGTH), Validators.required],
        nonNullable: true,
    });
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { isProcessing$: Observable<boolean>; actionType: OtherInformationActionType; existingText?: string },
        private readonly dialogRef: MatDialogRef<AddOrEditOtherInformationDialogComponent>
    ) {
        if (data.existingText) {
            this.otherInformationControl.patchValue(data.existingText);
        }
    }

    protected confirm() {
        if (this.otherInformationControl.invalid) {
            return;
        }

        if (this.data.actionType === OtherInformationActionType.Edit && this.otherInformationControl.value === this.data.existingText) {
            this.dialogRef.close();

            return;
        }

        this.otherInfoTextSubject.next(this.otherInformationControl.value);
    }
}
