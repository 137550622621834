import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { LegacyDateAdapter as DateAdapter } from "@angular/material/legacy-core";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { CustomDateAdapter, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedModule } from "../shared";
import { UtmUserBasicDataTileComponent } from "./components/utm-user-basic-data-tile/utm-user-basic-data-tile.component";
import { UtmUserEditBasicDataDialogComponent } from "./components/utm-user-edit-basic-data-dialog/utm-user-edit-basic-data-dialog.component";
import { UtmUserIdentityDocumentTileComponent } from "./components/utm-user-identity-document-tile/utm-user-identity-document-tile.component";
import { UtmUserLegalGuardianDetailsRequiredTileComponent } from "./components/utm-user-legal-guardian-details-required-tile/utm-user-legal-guardian-details-required-tile.component";
import { UtmUserListComponent } from "./components/utm-user-list/utm-user-list.component";
import { UtmUserProfileComponent } from "./components/utm-user-profile/utm-user-profile.component";
import { UtmUserRejectDocumentDialogComponent } from "./components/utm-user-reject-document-dialog/utm-user-reject-document-dialog.component";
import { UtmUserStatusBadgeComponent } from "./components/utm-user-status-badge/utm-user-status-badge.component";
import { UtmUsersContainerComponent } from "./components/utm-users-container/utm-users-container.component";
import { UtmUsersFiltersComponent } from "./components/utm-users-filters/utm-users-filters.component";
import { UtmUsersApiService } from "./services/utm-users.api.service";
import { UtmUsersState } from "./state/utm-users.state";

@NgModule({
    declarations: [
        UtmUsersContainerComponent,
        UtmUserListComponent,
        UtmUserStatusBadgeComponent,
        UtmUsersFiltersComponent,
        UtmUserProfileComponent,
        UtmUserBasicDataTileComponent,
        UtmUserEditBasicDataDialogComponent,
        UtmUserIdentityDocumentTileComponent,
        UtmUserRejectDocumentDialogComponent,
        UtmUserLegalGuardianDetailsRequiredTileComponent,
    ],
    imports: [
        CommonModule,
        PushModule,
        SharedUiModule,
        LetModule,
        MatTableModule,
        MatPaginatorModule,
        SharedI18nModule,
        NgxsModule.forFeature([UtmUsersState]),
        ReactiveFormsModule,
        MatInputModule,
        SharedModule,
        MatDialogModule,
        MatDatepickerModule,
        MatMenuModule,
    ],
    providers: [
        UtmUsersApiService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibUtmUsers",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
    ],
    exports: [
        UtmUserListComponent,
        UtmUserStatusBadgeComponent,
        UtmUsersFiltersComponent,
        UtmUserProfileComponent,
        UtmUserBasicDataTileComponent,
        UtmUserEditBasicDataDialogComponent,
        UtmUserIdentityDocumentTileComponent,
        UtmUserRejectDocumentDialogComponent,
        UtmUserLegalGuardianDetailsRequiredTileComponent,
    ],
})
export class UtmUsersModule {
    public static forRoot(): ModuleWithProviders<UtmUsersModule> {
        return {
            ngModule: UtmUsersModule,
            providers: [UtmUsersApiService],
        };
    }

    public static forTest(): ModuleWithProviders<UtmUsersModule> {
        return {
            ngModule: UtmUsersModule,
            providers: [
                {
                    provide: UtmUsersApiService,
                    useValue: null,
                },
            ],
        };
    }
}
