import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { LEGAL_AGE, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Observable } from "rxjs";
import { UtmUser, UtmUserError, UtmUserStatus } from "../../services/utm-users.models";

interface UtmUserListComponentStore {
    selectedUserId: string | undefined;
    users: UtmUser[];
    pagination: Page | undefined;
    isListProcessing: boolean;
    userListError: UtmUserError | undefined;
}

@Component({
    selector: "dtm-admin-lib-utm-user-list[users][pagination]",
    templateUrl: "./utm-user-list.component.html",
    styleUrls: ["./utm-user-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UtmUserListComponent {
    @Input()
    public set selectedUserId(value: string | undefined) {
        this.localStore.patchState({ selectedUserId: value });
    }

    @Input()
    public set users(value: UtmUser[]) {
        this.localStore.patchState({ users: value ?? [] });
    }

    @Input()
    public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Input()
    public set isListProcessing(value: BooleanInput) {
        this.localStore.patchState({ isListProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set userListError(value: UtmUserError | undefined) {
        this.localStore.patchState({ userListError: value });
    }

    @Output() protected readonly userSelect: EventEmitter<string> = new EventEmitter<string>();
    @Output() protected readonly pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() protected readonly userListRefresh: EventEmitter<void> = new EventEmitter<void>();

    protected readonly displayedColumns = ["status", "firstName", "lastName", "nationality", "isLegalAge"];
    protected readonly selectedUserId$: Observable<string | undefined> = this.localStore.selectByKey("selectedUserId");
    protected readonly users$ = this.localStore.selectByKey("users");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly isListProcessing$ = this.localStore.selectByKey("isListProcessing");
    protected readonly userListError$ = this.localStore.selectByKey("userListError");
    protected readonly LEGAL_AGE = LEGAL_AGE;
    protected readonly ErrorMode = ErrorMode;
    protected readonly UtmUserStatus = UtmUserStatus;

    constructor(private readonly localStore: LocalComponentStore<UtmUserListComponentStore>) {
        this.localStore.setState({
            selectedUserId: undefined,
            users: [],
            pagination: undefined,
            isListProcessing: false,
            userListError: undefined,
        });
    }

    protected selectUser(userId: string) {
        this.userSelect.emit(userId);
        this.localStore.patchState({
            selectedUserId: userId,
        });
    }
}
