import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM, PhoneNumber } from "@dtm-frontend/shared/ui";

export interface AdminUsersError {
    type: AdminUsersErrorType;
}

export enum AdminUsersErrorType {
    Unknown = "Unknown",
    PhoneNumberConflict = "PhoneNumberConflict",
    EmailConflict = "EmailConflict",
}

export interface AdminUser {
    id: string;
    group: UserGroup;
    firstName: string;
    lastName: string;
    phoneNumber: PhoneNumber;
    email: string;
}

export interface AdminUsersFilter {
    textSearch?: string;
}

export interface AdminUsersQueryParams {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    textSearch?: string;
}

export enum UserGroup {
    UtmUser = "UTM_USER",
    CaaAdmin = "CAA_ADMIN",
}

export interface NewAdminUserValue {
    firstName: string;
    lastName: string;
    phoneNumber: PhoneNumber;
    email: string;
}
