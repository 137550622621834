import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
    AssociationOperator,
    AssociationPermit,
    ButtonTheme,
    CaaContactPerson,
    ConfirmationDialogComponent,
    ContactPerson,
    DialogService,
    InvalidFormScrollableDirective,
} from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AssociationPermitWizardBasicDetails } from "../../../../models/association-permit.models";

interface AssociationPermitRequestBasicDetailsStepComponentState {
    isProcessing: boolean;
    caaContactUsers: CaaContactPerson[];
    associationOperators: AssociationOperator[];
    existingAssociationPermit: AssociationPermit | undefined;
}

interface BasicDataFormGroup {
    associationInfo: FormControl<AssociationOperator | null>;
    operatorContact: FormControl<ContactPerson | null>;
    caaContact: FormControl<CaaContactPerson | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-association-permit-request-basic-details-step[caaContactUsers][associationOperators]",
    templateUrl: "./association-permit-request-basic-details-step.component.html",
    styleUrls: ["./association-permit-request-basic-details-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class AssociationPermitRequestBasicDetailsStepComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set caaContactUsers(value: CaaContactPerson[] | undefined) {
        this.localStore.patchState({ caaContactUsers: value ?? [] });
    }
    @Input() public set associationOperators(value: AssociationOperator[] | undefined) {
        this.localStore.patchState({ associationOperators: value ?? [] });
    }
    @Input() public set existingAssociationPermit(value: AssociationPermit | undefined) {
        this.localStore.patchState({ existingAssociationPermit: value });
        if (!value) {
            return;
        }
        this.assignPermitDetailsToForm(value);
    }

    @Output() public readonly searchTextChange = new EventEmitter<string>();
    @Output() public readonly basicDetailsSave = new EventEmitter<AssociationPermitWizardBasicDetails>();
    @Output() public readonly back = new EventEmitter<void>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly existingAssociationPermit$ = this.localStore.selectByKey("existingAssociationPermit");
    protected readonly caaContactUsers$ = this.localStore.selectByKey("caaContactUsers");
    protected readonly associationOperators$ = this.localStore.selectByKey("associationOperators");
    protected readonly basicDataForm = new FormGroup<BasicDataFormGroup>({
        associationInfo: new FormControl(null, { validators: Validators.required }),
        operatorContact: new FormControl(null, Validators.required),
        caaContact: new FormControl(null, Validators.required),
    });

    constructor(
        private readonly localStore: LocalComponentStore<AssociationPermitRequestBasicDetailsStepComponentState>,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService
    ) {
        localStore.setState({ isProcessing: false, caaContactUsers: [], associationOperators: [], existingAssociationPermit: undefined });
    }

    protected validateFormAndSave() {
        this.basicDataForm.markAllAsTouched();
        if (this.basicDataForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.basicDetailsSave.emit(this.basicDataForm.getRawValue() as AssociationPermitWizardBasicDetails);
    }

    protected caaUserCompare(optionLeft: CaaContactPerson | null, optionRight: CaaContactPerson | null): boolean {
        return optionLeft?.id === optionRight?.id;
    }

    protected goBack() {
        if (!this.basicDataForm.touched) {
            this.back.emit();

            return;
        }

        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmAdminLibPermits.confirmEditionCloseDialog.title"),
                    confirmationText: this.transloco.translate("dtmAdminLibPermits.confirmEditionCloseDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmAdminLibPermits.confirmEditionCloseDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmAdminLibPermits.confirmEditionCloseDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.back.emit());
    }

    private assignPermitDetailsToForm(value: AssociationPermit) {
        const { associationInfo, operatorContact, caaContact } = value;

        this.basicDataForm.patchValue({
            associationInfo,
            operatorContact,
            caaContact,
        });

        this.basicDataForm.controls.associationInfo.disable();
    }
}
