import { HttpParams } from "@angular/common/http";
import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { CentersFilterFormKeys, CentersFilterParams } from "../models/centers.models";
import { ExaminationCenter, ExaminationCenterListWithPages } from "../models/examination-centers.models";
import { TrainingCenter, TrainingCenterListWithPages } from "../models/training-centers.models";

export type GetExaminationCentersListResponseBody = PageResponseBody<ExaminationCenter>;

export function convertExaminationCenterResponseBodyToCenter(response: ExaminationCenter): ExaminationCenter {
    return {
        id: response.id,
        name: response.name,
        identificationNumber: response.identificationNumber,
        administrativeDecisionNumber: response.administrativeDecisionNumber,
        email: response.email,
        phoneNumber: response.phoneNumber,
        address: response.address,
        examCodes: response.examCodes,
        validUntil: new Date(response.validUntil),
    };
}

export function convertGetExaminationCenterListResponseBodyToCenterListWithPages(
    response: GetExaminationCentersListResponseBody
): ExaminationCenterListWithPages {
    return {
        content: response.content.map((centerResponseBody) => convertExaminationCenterResponseBodyToCenter(centerResponseBody)),
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export type GetTrainingCentersListResponseBody = PageResponseBody<TrainingCenter>;

export function convertTrainingCenterResponseBodyToCenter(response: TrainingCenter): TrainingCenter {
    return {
        id: response.id,
        operatorId: response.operatorId,
        operatorNumber: response.operatorNumber,
        name: response.name,
        identificationNumber: response.identificationNumber,
        administrativeDecisionNumber: response.administrativeDecisionNumber,
        trainingCodes: response.trainingCodes,
        validUntil: new Date(response.validUntil),
    };
}

export function convertGetTrainingCenterListResponseBodyToCenterListWithPages(
    response: GetTrainingCentersListResponseBody
): TrainingCenterListWithPages {
    return {
        content: response.content.map((centerResponseBody) => convertTrainingCenterResponseBodyToCenter(centerResponseBody)),
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export function getCentersParams(filters: CentersFilterParams): HttpParams {
    let params = new HttpParams().set("page", `${filters?.page ?? 0}`);

    if (filters.size) {
        params = params.append("size", filters.size);
    }

    const searchPhrase = filters[CentersFilterFormKeys.SearchPhrase];

    if (searchPhrase) {
        params = params.append(CentersFilterFormKeys.SearchPhrase, searchPhrase);
    }

    return params;
}
