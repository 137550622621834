<dtm-ui-editable-card
    [label]="'dtmAdminLibUserProfile.conversationCategories.titleLabel' | transloco"
    editButtonIcon="edit"
    (actionSave)="conversationCategoriesChange.emit(conversationCategoryControl.value)"
>
    <ng-container previewContent *ngrxLet="assignedConversationCategories$ as assignedConversationCategories">
        <div class="email-badges-container" *ngIf="assignedConversationCategories.length; else emptyListTemplate">
            <div class="chip disabled" *ngFor="let assignedConversationCategory of assignedConversationCategories">
                <dtm-ui-icon name="checkbox-circle" class="status-icon"></dtm-ui-icon>
                <span class="statusLabel">
                    {{ assignedConversationCategory.name }}
                </span>
            </div>
        </div>
    </ng-container>
    <ng-container editableContent *ngrxLet="conversationCategories$ as conversationCategories">
        <dtm-ui-select-field [multiple]="true" [formControl]="conversationCategoryControl">
            <label>{{ "dtmAdminLibUserProfile.conversationCategories.selectConversationCategoriesLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let conversationCategory of conversationCategories" [value]="conversationCategory.id">
                {{ conversationCategory.name }}
            </dtm-ui-select-option>
        </dtm-ui-select-field>
    </ng-container>
</dtm-ui-editable-card>
<ng-template #emptyListTemplate>
    {{ "dtmAdminLibUserProfile.conversationCategories.emptyListLabel" | transloco }}
</ng-template>
