import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AuthorizationScopeChipsListComponentState {
    chipsList: string[] | undefined;
}

@Component({
    selector: "dtm-admin-lib-authorization-scope-chips-list[chipsList]",
    templateUrl: "./authorization-scope-chips-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AuthorizationScopeChipsListComponent {
    @Input()
    public set chipsList(value: string[] | undefined) {
        this.localStore.patchState({ chipsList: value });
    }

    @Output() public readonly chipRemove = new EventEmitter<string>();

    protected readonly chipsList$ = this.localStore.selectByKey("chipsList");

    constructor(private readonly localStore: LocalComponentStore<AuthorizationScopeChipsListComponentState>) {
        this.localStore.setState({
            chipsList: undefined,
        });
    }
}
