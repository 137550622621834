import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { AdminUserFormDialogComponent } from "./components/admin-user-form-dialog/admin-user-form-dialog.component";
import { AdminUsersFilterComponent } from "./components/admin-users-filter/admin-users-filter.component";
import { AdminUsersListComponent } from "./components/admin-users-list/admin-users-list.component";
import { AdminUsersComponent } from "./components/admin-users/admin-users.component";
import { AdminUsersApiService } from "./services/admin-users-api.service";
import { AdminUsersState } from "./state/admin-users-state.service";

@NgModule({
    declarations: [AdminUsersComponent, AdminUsersListComponent, AdminUsersFilterComponent, AdminUserFormDialogComponent],
    imports: [
        CommonModule,
        NgxsModule.forFeature([AdminUsersState]),
        PushModule,
        SharedUiModule,
        MatTableModule,
        LetModule,
        MatMenuModule,
        TranslocoModule,
        MatCardModule,
        MatInputModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDialogModule,
    ],
    exports: [AdminUsersListComponent, AdminUsersFilterComponent, AdminUserFormDialogComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibAdminUsers",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: AdminUsersApiService,
            useValue: undefined,
        },
    ],
})
export class AdminUsersModule {
    public static forRoot(): ModuleWithProviders<AdminUsersModule> {
        return {
            ngModule: AdminUsersModule,
            providers: [AdminUsersApiService],
        };
    }

    public static forTest(): ModuleWithProviders<AdminUsersModule> {
        return {
            ngModule: AdminUsersModule,
            providers: [
                {
                    provide: AdminUsersApiService,
                    useValue: null,
                },
            ],
        };
    }
}
