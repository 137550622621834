<div class="filters-wrapper">
    <dtm-ui-filters-container>
        <div class="filters-container" [formGroup]="permitsFiltersForm" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
            <dtm-ui-input-field class="control-search-text">
                <input
                    matInput
                    formControlName="searchByText"
                    type="text"
                    [placeholder]="'dtmAdminLibPermits.permitsFilters.searchByTextPlaceholder' | transloco"
                />
                <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
            </dtm-ui-input-field>
            <dtm-ui-select-field
                multiple
                [placeholder]="'dtmAdminLibPermits.permitsFilters.statusSelectPlaceholder' | transloco"
                formControlName="status"
                class="control-status"
            >
                <label>{{ "dtmAdminLibPermits.permitsFilters.statusLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let status of permitStatuses" [value]="status">
                    {{ "dtmAdminLibPermits.permitsFilters.statusValueLabel" | transloco : { value: status } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <dtm-ui-date-field class="control-date-from">
                <label for="dateFrom">
                    {{ "dtmAdminLibPermits.permitsFilters.dateFromLabel" | transloco }}
                </label>
                <input
                    id="dateFrom"
                    formControlName="dateFrom"
                    matInput
                    [placeholder]="datePickerPlaceholder"
                    [matDatepicker]="dateFromDatepicker"
                />
                <mat-datepicker #dateFromDatepicker></mat-datepicker>
            </dtm-ui-date-field>
            <dtm-ui-date-field class="control-date-to">
                <label for="dateTo">
                    {{ "dtmAdminLibPermits.permitsFilters.dateToLabel" | transloco }}
                </label>
                <input
                    id="dateTo"
                    formControlName="dateTo"
                    matInput
                    [placeholder]="datePickerPlaceholder"
                    [matDatepicker]="dateToDatepicker"
                />
                <mat-datepicker #dateToDatepicker></mat-datepicker>
            </dtm-ui-date-field>
        </div>
    </dtm-ui-filters-container>
    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [formGroup]="permitsFiltersForm"
        [filtersValue]="permitsFiltersForm.value"
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="appliedFiltersLength$ | ngrxPush"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</div>
