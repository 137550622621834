import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OperationScenarioCategory } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

export interface Badge {
    name: string;
    category: OperationScenarioCategory;
}

interface OperatorQualificationsBadgesComponentState {
    badges: Badge[] | undefined;
}

@Component({
    selector: "dtm-admin-lib-operator-qualifications-badges[badges]",
    templateUrl: "./operator-qualifications-badges.component.html",
    styleUrls: ["./operator-qualifications-badges.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorQualificationsBadgesComponent {
    @Input() public set badges(value: Badge[] | undefined) {
        this.localStore.patchState({ badges: value });
    }
    protected readonly badges$ = this.localStore.selectByKey("badges");
    protected readonly OperationScenarioCategory = OperationScenarioCategory;

    constructor(private readonly localStore: LocalComponentStore<OperatorQualificationsBadgesComponentState>) {
        this.localStore.setState({
            badges: undefined,
        });
    }
}
