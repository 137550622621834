import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperatorDetails, OperatorType } from "../../../models/operator.models";

interface ProfileDetailsComponentState {
    profile: OperatorDetails | undefined;
    isSecretNumberVisible: boolean;
    secretMask: string | undefined;
}

@Component({
    selector: "dtm-admin-lib-profile-details[profile]",
    templateUrl: "./profile-details.component.html",
    styleUrls: ["./profile-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ProfileDetailsComponent {
    @Input() public set profile(value: OperatorDetails | undefined) {
        this.localStore.patchState({
            profile: value,
            isSecretNumberVisible: false,
            secretMask: this.getSecretMask(value?.secret),
        });
    }

    public readonly OperatorType = OperatorType;
    public readonly profile$ = this.localStore.selectByKey("profile");
    public readonly isSecretNumberVisible$ = this.localStore.selectByKey("isSecretNumberVisible");
    public readonly secretMask$ = this.localStore.selectByKey("secretMask");

    constructor(private readonly localStore: LocalComponentStore<ProfileDetailsComponentState>) {
        localStore.setState({
            profile: undefined,
            isSecretNumberVisible: false,
            secretMask: undefined,
        });
    }

    public toggleSecretCodeVisibility() {
        const isVisible = this.localStore.selectSnapshotByKey("isSecretNumberVisible");

        this.localStore.patchState({ isSecretNumberVisible: !isVisible });
    }

    private getSecretMask(secretValue: string | undefined): string | undefined {
        if (!secretValue) {
            return;
        }

        return "*".repeat(secretValue.length);
    }
}
