import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { AreaMapContainerComponent } from "./area-map-container/area-map-container.component";

const routes: Routes = [
    {
        path: "area-map",
        component: AreaMapContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibAreaMap.routeTitles.areaMap",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AreaMapRoutingModule {}
