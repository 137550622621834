import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperatorDetails, OperatorStatusChange } from "../../../models/operator.models";

export interface ProfileTopComponentState {
    profile: OperatorDetails | undefined;
}

@Component({
    selector: "dtm-admin-lib-profile-top[profile]",
    templateUrl: "./profile-top.component.html",
    styleUrls: ["./profile-top.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ProfileTopComponent {
    @Input() public set profile(value: OperatorDetails | undefined) {
        this.localStore.patchState({ profile: value });
    }

    @Output() public statusChange = new EventEmitter<OperatorStatusChange>();

    public readonly profile$ = this.localStore.selectByKey("profile");

    constructor(private readonly localStore: LocalComponentStore<ProfileTopComponentState>) {
        localStore.setState({ profile: undefined });
    }
}
