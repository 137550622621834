<dtm-ui-loader-container [isShown]="data.isProcessing$ | ngrxPush">
    <div class="dialog-header">
        <div mat-dialog-title>
            {{ "dtmAdminLibUtmUsers.userProfile.editLabel" | transloco }}
        </div>
        <button type="button" class="button-icon" [matDialogClose]="false">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form [formGroup]="userForm" class="user-form" dtmUiInvalidFormScrollable>
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.firstNameLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="userForm.controls.firstName" required />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="userForm.controls.firstName; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="userForm.controls.firstName; name: 'maxlength'; error as error">
                    {{
                        "dtmAdminLibUtmUsers.userProfile.basicDataEdit.firstNameLengthErrorHint" | transloco : { max: error.requiredLength }
                    }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.lastNameLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="userForm.controls.lastName" required />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="userForm.controls.lastName; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="userForm.controls.lastName; name: 'maxlength'; error as error">
                    {{
                        "dtmAdminLibUtmUsers.userProfile.basicDataEdit.lastNameLengthErrorHint" | transloco : { max: error.requiredLength }
                    }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.emailAddressLabel" | transloco }}</label>
                <input matInput type="email" [formControl]="userForm.controls.email" required />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="userForm.controls.email; name: ['required']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="userForm.controls.email; name: ['email']">
                    {{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.invalidEmailErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-phone-number-field formControlName="phoneNumber">
                <div
                    class="field-error"
                    *dtmUiFieldHasError="userForm.controls.phoneNumber; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="userForm.controls.phoneNumber; name: 'invalidNumber'">
                    {{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.invalidPhoneNumberErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="userForm.controls.phoneNumber; name: 'invalidNumberType'">
                    {{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.invalidNumberTypeErrorHint" | transloco }}
                </div>
            </dtm-ui-phone-number-field>
            <dtm-ui-date-field>
                <label>{{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.dateOfBirthLabel" | transloco }}</label>
                <input
                    formControlName="dateOfBirth"
                    [max]="MAX_DATE_OF_BIRTH"
                    [min]="MIN_DATE_OF_BIRTH"
                    matInput
                    [matDatepicker]="dateOfBirthPicker"
                    *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                    [placeholder]="datePickerPlaceholder"
                />
                <mat-datepicker #dateOfBirthPicker></mat-datepicker>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="userForm.controls.dateOfBirth; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="userForm.controls.dateOfBirth; name: 'matDatepickerMax'">
                    {{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.minAgeErrorHint" | transloco : { minAge: MIN_USER_AGE } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="userForm.controls.dateOfBirth; name: 'matDatepickerMin'; error as error">
                    {{
                        "dtmAdminLibUtmUsers.userProfile.basicDataEdit.minDateOfBirthErrorHint"
                            | transloco : { minDate: error.min | localizeDate }
                    }}
                </div>
            </dtm-ui-date-field>
            <dtm-ui-country-select-field formControlName="nationality">
                <label>
                    {{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.nationalityLabel" | transloco }}
                </label>
            </dtm-ui-country-select-field>
        </form>
    </div>
    <div mat-dialog-actions>
        <button type="button" class="button-primary" (click)="save()">
            {{ "dtmAdminLibUtmUsers.userProfile.saveButtonLabel" | transloco }}
        </button>
    </div>
    <ng-template #requiredErrorTemplate>
        {{ "dtmAdminLibUtmUsers.userProfile.basicDataEdit.requiredFieldErrorHint" | transloco }}
    </ng-template>
</dtm-ui-loader-container>
