<dtm-ui-loader-container *ngrxLet="isCentersProcessing$ as isCentersProcessing" [isShown]="isCentersProcessing">
    <ng-container *ngrxLet="expandedElement$ as expandedElement">
        <ng-container *ngrxLet="displayedColumns$ as displayedColumns">
            <table *ngrxLet="centersList$ as centersList" mat-table [dataSource]="centersList" multiTemplateDataRows class="dtm-table">
                <ng-container matColumnDef="expandHandle">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" class="button-icon" (click)="$event.stopPropagation(); toggleExpandableRow(element)">
                            <dtm-ui-icon
                                name="arrow-right"
                                class="expand-handle"
                                [class.expanded]="element === expandedElement"
                            ></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container [matColumnDef]="displayedColumns[1]">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibCenters.list.nameHeader" | transloco }}</th>
                    <td mat-cell *matCellDef="let center">{{ center.name }}</td>
                </ng-container>
                <ng-container [matColumnDef]="displayedColumns[2]">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibCenters.list.identificationNumberHeader" | transloco }}</th>
                    <td mat-cell *matCellDef="let center">{{ center.identificationNumber }}</td>
                </ng-container>
                <ng-container *ngIf="displayedColumns[3] === 'email'" [matColumnDef]="displayedColumns[3]">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibCenters.list.emailHeader" | transloco }}</th>
                    <td mat-cell *matCellDef="let center">
                        <div class="copyable-value">
                            {{ center.email }}

                            <ng-template
                                [ngTemplateOutlet]="copyButtonWithIconTemplate"
                                [ngTemplateOutletContext]="{
                                    value: center.email
                                }"
                            ></ng-template>
                        </div>
                    </td>
                </ng-container>
                <ng-container *ngIf="displayedColumns[4] === 'phoneNumber'" [matColumnDef]="displayedColumns[4]">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibCenters.list.phoneNumberHeader" | transloco }}</th>
                    <td mat-cell *matCellDef="let center">
                        <div class="copyable-value">
                            {{ center.phoneNumber | formatPhoneNumber }}

                            <ng-template
                                [ngTemplateOutlet]="copyButtonWithIconTemplate"
                                [ngTemplateOutletContext]="{
                                    value: center.phoneNumber | formatPhoneNumber
                                }"
                            ></ng-template>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibCenters.list.actionsHeader" | transloco }}</th>
                    <td mat-cell *matCellDef="let center">
                        <button [matMenuTriggerFor]="actions" class="button-icon" type="button" (click)="$event.stopPropagation()">
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                        <mat-menu #actions="matMenu">
                            <button type="button" mat-menu-item (click)="preview.next(center)">
                                <dtm-ui-icon name="eye"></dtm-ui-icon>
                                {{ "dtmAdminLibCenters.list.previewLabel" | transloco }}
                            </button>
                            <button type="button" mat-menu-item (click)="edit.next(center)">
                                <dtm-ui-icon name="edit"></dtm-ui-icon>
                                {{ "dtmAdminLibCenters.list.editLabel" | transloco }}
                            </button>
                            <button type="button" mat-menu-item (click)="delete.next(center.id)">
                                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                                {{ "dtmAdminLibCenters.list.deleteLabel" | transloco }}
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>
                <ng-container matColumnDef="noResults">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <dtm-ui-error
                            *ngIf="centersListError$ | ngrxPush; else emptyListTemplate"
                            [mode]="ErrorMode.Secondary"
                            (reload)="centersListRefresh.emit()"
                        >
                        </dtm-ui-error>
                        <ng-template #emptyListTemplate>
                            <dtm-ui-no-results></dtm-ui-no-results>
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="expanded-row-content" *ngIf="element === expandedElement" @slideIn>
                            <ng-container
                                *ngIf="(activeTabIndex$ | ngrxPush) === CentersTabType.TrainingOperators; else examinationExpandedTemplate"
                            >
                                <dtm-ui-label-value
                                    class="sub-cell codes-cell"
                                    [label]="'dtmAdminLibCenters.list.expanded.conductingExamsHeader' | transloco"
                                >
                                    <div class="codes-cell-container">
                                        <ng-container *ngIf="element.trainingCodes?.length; else noCodesDataTemplate">
                                            <span *ngFor="let code of element.trainingCodes">{{ code }}</span>
                                        </ng-container>

                                        <ng-template #noCodesDataTemplate>-</ng-template>
                                    </div>
                                </dtm-ui-label-value>

                                <dtm-ui-label-value
                                    class="sub-cell"
                                    [label]="'dtmAdminLibCenters.list.expanded.validUntilHeader' | transloco"
                                    [value]="element.validUntil | localizeDate"
                                ></dtm-ui-label-value>

                                <dtm-ui-label-value
                                    class="sub-cell"
                                    [label]="'dtmAdminLibCenters.list.expanded.administrativeDecisionHeader' | transloco"
                                    [value]="element.administrativeDecisionNumber"
                                ></dtm-ui-label-value>

                                <dtm-ui-label-value
                                    class="sub-cell"
                                    [label]="'dtmAdminLibCenters.list.expanded.operatorNumberLabel' | transloco"
                                    [value]="element.operatorNumber"
                                ></dtm-ui-label-value>
                            </ng-container>

                            <ng-template #examinationExpandedTemplate>
                                <dtm-ui-label-value
                                    class="sub-cell codes-cell"
                                    [label]="'dtmAdminLibCenters.list.expanded.conductingExamsHeader' | transloco"
                                >
                                    <div class="codes-cell-container">
                                        <ng-container *ngIf="element.examCodes?.length; else noCodesDataTemplate">
                                            <span *ngFor="let code of element.examCodes">{{ code }}</span>
                                        </ng-container>

                                        <ng-template #noCodesDataTemplate>-</ng-template>
                                    </div>
                                </dtm-ui-label-value>

                                <dtm-ui-label-value
                                    class="sub-cell"
                                    [label]="'dtmAdminLibCenters.list.expanded.validUntilHeader' | transloco"
                                    [value]="element.validUntil | localizeDate"
                                ></dtm-ui-label-value>

                                <dtm-ui-label-value
                                    class="sub-cell"
                                    [label]="'dtmAdminLibCenters.list.expanded.administrativeDecisionHeader' | transloco"
                                    [value]="element.administrativeDecisionNumber"
                                ></dtm-ui-label-value>
                            </ng-template>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns?.length">
                        <dtm-ui-pagination
                            *ngIf="pagination$ | ngrxPush as pagination"
                            [pagination]="pagination"
                            (page)="pageChange.emit($event)"
                        >
                        </dtm-ui-pagination>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['noResults']"
                    [class.hide-footer]="centersList.length || isCentersProcessing"
                    class="no-results-row"
                >
                    <dtm-ui-no-results></dtm-ui-no-results>
                </tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.expanded]="expandedElement === row"
                    (click)="toggleExpandableRow(row)"
                ></tr>
                <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
                <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!centersList.length" class="pagination-row"></tr>
            </table>
        </ng-container>
    </ng-container>
</dtm-ui-loader-container>

<ng-template #copyButtonWithIconTemplate let-value="value">
    <button
        class="button-icon-small cell-button-icon"
        type="button"
        [cdkCopyToClipboard]="value"
        (cdkCopyToClipboardCopied)="handleValueCopy($event)"
        (click)="$event.stopPropagation()"
        [matTooltip]="'dtmUi.copyToClipboard.hint' | transloco"
    >
        <dtm-ui-icon name="file-copy"> </dtm-ui-icon>
    </button>
</ng-template>
