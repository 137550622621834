<div class="grid">
    <mat-card *ngrxLet="activeTabIndex$ as activeTabIndex">
        <dtm-admin-lib-exam-training-results-header
            [activeTabIndex]="activeTabIndex"
            (tabIndexUpdate)="updateTabIndex($event)"
            (resultsAdd)="openFileUploadDialog($event)"
        >
            <ng-container examList>
                <dtm-admin-lib-exam-training-results-filters
                    [initialFilters]="examResultsFilters$ | ngrxPush"
                    (filtersChange)="navigateByParams($event, ExamTrainingResultsTabType.Exams)"
                ></dtm-admin-lib-exam-training-results-filters>

                <dtm-admin-lib-exam-training-results-list
                    [resultsList]="examResultsList$ | ngrxPush"
                    [pagination]="examResultsPages$ | ngrxPush"
                    [resultsListError]="examResultsListError$ | ngrxPush"
                    [isResultsListProcessing]="isExamResultsListProcessing$ | ngrxPush"
                    [activeTabIndex]="activeTabIndex"
                    (pageChange)="resultsPageChange($event, ExamTrainingResultsFiltersTabKeys.ExamResultsFilters)"
                    (resultsListRefresh)="getExamResultsList()"
                    (deleteResults)="deleteResults($event, ExamTrainingResultsTabType.Exams)"
                ></dtm-admin-lib-exam-training-results-list>
            </ng-container>

            <ng-container trainingList>
                <dtm-admin-lib-exam-training-results-filters
                    [initialFilters]="trainingResultsFilters$ | ngrxPush"
                    (filtersChange)="navigateByParams($event, ExamTrainingResultsTabType.Trainings)"
                ></dtm-admin-lib-exam-training-results-filters>

                <dtm-admin-lib-exam-training-results-list
                    [resultsList]="trainingResultsList$ | ngrxPush"
                    [pagination]="trainingResultsPages$ | ngrxPush"
                    [resultsListError]="trainingResultsListError$ | ngrxPush"
                    [isResultsListProcessing]="isTrainingResultsListProcessing$ | ngrxPush"
                    [activeTabIndex]="activeTabIndex"
                    (pageChange)="resultsPageChange($event, ExamTrainingResultsFiltersTabKeys.TrainingResultsFilters)"
                    (resultsListRefresh)="getTrainingResultsList()"
                    (deleteResults)="deleteResults($event, ExamTrainingResultsTabType.Trainings)"
                ></dtm-admin-lib-exam-training-results-list>
            </ng-container>
        </dtm-admin-lib-exam-training-results-header>
    </mat-card>
</div>
