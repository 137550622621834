<dtm-ui-loader-container *ngrxLet="isListProcessing$ as isListProcessing" [isShown]="isListProcessing">
    <table *ngIf="users$ | ngrxPush as users" [dataSource]="users" multiTemplateDataRows mat-table class="dtm-table utm-users-table">
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibUtmUsers.utmUserList.statusTableHeader" | transloco }}</th>
            <td mat-cell *matCellDef="let user">
                <div class="status">
                    <dtm-admin-lib-utm-user-status-badge [status]="user.status"></dtm-admin-lib-utm-user-status-badge>
                    <ng-container *ngIf="user.status === UtmUserStatus.Waiting">
                        <dtm-ui-content-popover
                            *ngIf="user.isIdentityDocumentCompletionRequested && user.identityDocumentCompletionRequestDate"
                            shouldOpenOnHover
                            [popoverText]="
                                'dtmAdminLibUtmUsers.utmUserList.identityDocumentCompletionRequestedInformation'
                                    | transloco : { requestDate: user.identityDocumentCompletionRequestDate | localizeDate }
                            "
                        >
                            <dtm-ui-icon name="flag-fill" class="identity-document-status"></dtm-ui-icon>
                        </dtm-ui-content-popover>
                        <dtm-ui-content-popover
                            *ngIf="user.isLegalGuardianCompletionRequested && user.legalGuardianCompletionRequestDate"
                            shouldOpenOnHover
                            [popoverText]="
                                'dtmAdminLibUtmUsers.utmUserList.legalGuardianCompletionRequestedInformation'
                                    | transloco : { requestDate: user.legalGuardianCompletionRequestDate | localizeDate }
                            "
                        >
                            <dtm-ui-icon name="flag-fill" class="legal-guardian-status"></dtm-ui-icon>
                        </dtm-ui-content-popover>
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibUtmUsers.utmUserList.firstNameTableHeader" | transloco }}</th>
            <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibUtmUsers.utmUserList.lastNameTableHeader" | transloco }}</th>
            <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="nationality">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibUtmUsers.utmUserList.nationalityTableHeader" | transloco }}</th>
            <td mat-cell *matCellDef="let user">
                <dtm-ui-country-display [country]="user.nationality"></dtm-ui-country-display>
            </td>
        </ng-container>
        <ng-container matColumnDef="isLegalAge">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibUtmUsers.utmUserList.ageTableHeader" | transloco }}</th>
            <td mat-cell *matCellDef="let user">
                <span *ngIf="user.isLegalAge; else notLegalAgeTemplate">{{
                    "dtmAdminLibUtmUsers.utmUserList.aboveLegalAgeLabel" | transloco : { legalAge: LEGAL_AGE }
                }}</span>
                <ng-template #notLegalAgeTemplate>
                    <div class="age-cell">
                        <span>{{ "dtmAdminLibUtmUsers.utmUserList.belowLegalAgeLabel" | transloco : { legalAge: LEGAL_AGE } }}</span>
                        <dtm-ui-icon name="child" class="age-cell-icon"></dtm-ui-icon>
                    </div>
                </ng-template></td
        ></ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-error
                    [mode]="ErrorMode.Secondary"
                    *ngIf="userListError$ | ngrxPush; else emptyListTemplate"
                    (reload)="userListRefresh.emit()"
                >
                </dtm-ui-error>
                <ng-template #emptyListTemplate>
                    <dtm-ui-no-results></dtm-ui-no-results>
                </ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
                </dtm-ui-pagination>
            </td>
        </ng-container>
        <tr
            mat-footer-row
            *matFooterRowDef="['noResults']"
            [class.hide-footer]="users.length || isListProcessing"
            class="no-results-row"
        ></tr>
        <tr
            (click)="selectUser(user.id)"
            mat-row
            *matRowDef="let user; columns: displayedColumns"
            [class.expanded]="(selectedUserId$ | ngrxPush) === user.id"
            class="user-row"
        ></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!users.length" class="pagination-row"></tr>
    </table>
</dtm-ui-loader-container>
