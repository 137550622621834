import { Page, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";

export enum OperationScenarioStatementErrorType {
    Unknown = "Unknown",
    CannotGetOperationScenarioStatements = "CannotGetOperationScenarioStatements",
    CannotGetOperationScenarioStatementAdministrativeFeeDocument = "CannotGetOperatorStatementAdministrativeFeeDocument",
}

interface AttorneyPowerAdministrativeFee {
    isDeclarationOfExemption?: boolean;
    feeDocumentId?: string;
}

export interface OperationScenarioStatement {
    id: string;
    operatorId: string;
    createdAt: Date;
    operatorNumber: string;
    payment: {
        status: OperationScenarioStatementStatus;
        info: PaymentStatusInfoCode;
    };
    signature: {
        status: OperationScenarioStatementStatus;
        info: string;
    };
    signer?: {
        dateOfBirth: Date;
        lastName: string;
        firstName: string;
        signedAt: Date;
    };
    attorneyPowerAdministrativeFee: AttorneyPowerAdministrativeFee | undefined;
    operationScenarios: string[];
}

export interface OperationScenarioStatementsError {
    type: OperationScenarioStatementErrorType;
}

export enum OperationScenarioStatementStatus {
    Waiting = "WAITING",
    Completed = "COMPLETED",
    Error = "ERROR",
}

export interface OperationScenarioStatementsFilterParams {
    [PAGE_NUMBER_QUERY_PARAM]?: number;
    [PAGE_SIZE_QUERY_PARAM]?: number;
    operatorNumber?: string;
    paymentStatus?: OperationScenarioStatementStatus | null;
    signStatus?: OperationScenarioStatementStatus | null;
}

export interface OperationScenarioStatementsFilters {
    operatorNumber: string;
    paymentStatus: OperationScenarioStatementStatus[] | null;
    signStatus: OperationScenarioStatementStatus[] | null;
}

export interface OperationScenarioStatementsListWithPages {
    content: OperationScenarioStatement[];
    pages: Page;
}

export enum PaymentStatusInfoCode {
    ServiceUnavailable = "ServiceUnavailable",
    TransactionRejected = "TransactionRejected",
    TransactionTimeout = "TransactionTimeout",
    Unknown = "Unknown",
}

export interface StatementConfirmationParams {
    operatorId: string;
    statementId: string;
}
