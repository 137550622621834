<dtm-ui-wizard-step-wrapper
    (next)="validateFormAndSave()"
    [title]="'dtmAdminLibPermits.associationPermitBasicDetails.basicDataStepHeader' | transloco"
    [stepsAmount]="3"
    [stepNumber]="1"
    [isBackButtonVisible]="!!(existingAssociationPermit$ | ngrxPush)"
    (back)="goBack()"
    dtmUiInvalidFormScrollable
>
    <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
        <ng-container [formGroup]="basicDataForm">
            <div class="contact-person-preview">
                <dtm-ui-select-field [compareWith]="caaUserCompare" formControlName="caaContact" [isClearable]="false">
                    <label>{{ "dtmAdminLibPermits.basicData.caaContactPersonSearchLabel" | transloco }}</label>
                    <dtm-ui-select-option *ngFor="let caaUser of caaContactUsers$ | ngrxPush" [value]="caaUser">
                        {{ caaUser.firstName }} {{ caaUser.lastName }}
                    </dtm-ui-select-option>
                    <div class="field-error" *dtmUiFieldHasError="basicDataForm.controls.caaContact; name: 'required'">
                        {{ "dtmAdminLibPermits.container.requiredField" | transloco }}
                    </div>
                </dtm-ui-select-field>
                <div class="caa-contact-user-info" @slideIn *ngIf="basicDataForm.controls.caaContact.value as caaContactUser">
                    <h2 class="contact-person-header">{{ "dtmAdminLibPermits.basicData.caaUserContactHeader" | transloco }}</h2>
                    <dtm-ui-permit-contact-person-preview [contactPerson]="caaContactUser"></dtm-ui-permit-contact-person-preview>
                </div>
            </div>

            <dtm-admin-lib-operator-search-autocomplete-control
                formControlName="associationInfo"
                (searchTextChange)="searchTextChange.emit($event)"
                [options]="associationOperators$ | ngrxPush"
            >
                <label>{{ "dtmAdminLibPermits.associationPermitBasicDetails.findOperatorLabel" | transloco }}</label>

                <div class="field-error" *dtmUiFieldHasError="basicDataForm.controls.associationInfo; name: 'required'">
                    {{ "dtmAdminLibPermits.associationPermitBasicDetails.requiredField" | transloco }}
                </div>
            </dtm-admin-lib-operator-search-autocomplete-control>

            <ng-container *ngIf="basicDataForm.controls.associationInfo.value as operator">
                <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="true">
                    <div class="section-title" headerSlot>
                        {{ "dtmAdminLibPermits.associationPermitBasicDetails.associationInfoHeader" | transloco }}
                    </div>
                    <div class="operator-info">
                        <dtm-ui-label-value
                            *ngIf="operator.associationRegistrationNumber"
                            [label]="'dtmAdminLibPermits.associationPermitBasicDetails.associationRegistrationNumberLabel' | transloco"
                            [value]="operator.associationRegistrationNumber"
                        ></dtm-ui-label-value>
                        <dtm-ui-label-value
                            *ngIf="operator.associationNationalCourtRegister"
                            [label]="'dtmAdminLibPermits.associationPermitBasicDetails.associationNationalCourtRegisterLabel' | transloco"
                            [value]="operator.associationNationalCourtRegister"
                        ></dtm-ui-label-value>
                        <dtm-ui-label-value
                            [label]="'dtmAdminLibPermits.associationPermitBasicDetails.associationNameLabel' | transloco"
                            [value]="operator.name"
                        ></dtm-ui-label-value>
                        <dtm-ui-label-value
                            [label]="'dtmAdminLibPermits.associationPermitBasicDetails.associationNumberLabel' | transloco"
                            [value]="operator.number"
                        ></dtm-ui-label-value>
                        <dtm-ui-address-label-value
                            [label]="'dtmAdminLibPermits.associationPermitBasicDetails.associationHeadquartersAddressLabel' | transloco"
                            [address]="operator.address"
                        >
                        </dtm-ui-address-label-value>
                    </div>
                </dtm-ui-expandable-panel>
                <dtm-admin-lib-operator-contact-person-control
                    dtmUiMarkValueAccessorControlsAsTouched
                    formControlName="operatorContact"
                    [isPositionFieldAvailable]="true"
                ></dtm-admin-lib-operator-contact-person-control>
            </ng-container>
        </ng-container>
    </dtm-ui-loader-container>
</dtm-ui-wizard-step-wrapper>
