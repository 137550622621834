<dtm-ui-card-header [isEditButtonVisible]="false">
    {{ "dtmAdminLibExamTrainingResults.header.title" | transloco }}

    <ng-container additionalActions>
        <button [matMenuTriggerFor]="actionsMenu" type="button" class="button-primary">
            {{ "dtmAdminLibExamTrainingResults.header.provideResults" | transloco }}
            <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
            <button (click)="resultsAdd.next(ExamTrainingResultsReportType.ExamCenterReport)" mat-menu-item type="button">
                <span>{{ "dtmAdminLibExamTrainingResults.header.menu.examReportsButtonLabel" | transloco }}</span>
            </button>
            <button (click)="resultsAdd.next(ExamTrainingResultsReportType.TrainingOperatorReport)" mat-menu-item type="button">
                <span>{{ "dtmAdminLibExamTrainingResults.header.menu.trainingReportsButtonLabel" | transloco }}</span>
            </button>
        </mat-menu>
    </ng-container>
</dtm-ui-card-header>

<mat-tab-group
    [selectedIndex]="activeTabIndex$ | ngrxPush"
    (selectedIndexChange)="onUpdateTabIndex($event)"
    class="dtm-tabs"
    color="accent"
    dynamicHeight
>
    <mat-tab [label]="'dtmAdminLibExamTrainingResults.tabs.exams' | transloco">
        <ng-content select="[examList]"></ng-content>
    </mat-tab>
    <mat-tab [label]="'dtmAdminLibExamTrainingResults.tabs.trainings' | transloco">
        <ng-content select="[trainingList]"></ng-content>
    </mat-tab>
</mat-tab-group>
