import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { LegacyDateAdapter as DateAdapter } from "@angular/material/legacy-core";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CustomDateAdapter, IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedWebAppModule } from "@dtm-frontend/web-app-lib/shared";
import { TRANSLOCO_SCOPE, TranslocoTestingModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedModule } from "../shared/shared.module";
import { CENTERS_ENDPOINTS } from "./centers.tokens";
import { CentersFiltersComponent } from "./components/centers-filters/centers-filters.component";
import { CentersHeaderComponent } from "./components/centers-header/centers-header.component";
import { CentersListComponent } from "./components/centers-list/centers-list.component";
import { CentersComponent } from "./components/centers/centers.component";
import { ExaminationCentersAddFormDialogComponent } from "./components/examination-centers-add-form-dialog/examination-centers-add-form-dialog.component";
import { ExaminationCentersPreviewDialogComponent } from "./components/examination-centers-preview-dialog/examination-centers-preview-dialog.component";
import { AuthorizationScopeChipsListComponent } from "./components/shared/authorization-scope-chips-list/authorization-scope-chips-list.component";
import { OperatorDetailsComponent } from "./components/shared/operator-details/operator-details.component";
import { OperatorQualificationsBadgesComponent } from "./components/shared/operator-qualifications-badges/operator-qualifications-badges.component";
import { TrainingCentersAddFormDialogComponent } from "./components/training-centers-add-form-dialog/training-centers-add-form-dialog.component";
import { TrainingCentersPreviewDialogComponent } from "./components/training-centers-preview-dialog/training-centers-preview-dialog.component";
import { DisplayExamCodePipe } from "./pipes/display-exam-code.pipe";
import { CentersApiService } from "./services/centers-api.service";
import { CentersState } from "./state/centers.state";

@NgModule({
    declarations: [
        CentersComponent,
        CentersHeaderComponent,
        CentersListComponent,
        CentersFiltersComponent,
        ExaminationCentersAddFormDialogComponent,
        ExaminationCentersPreviewDialogComponent,
        TrainingCentersAddFormDialogComponent,
        OperatorQualificationsBadgesComponent,
        AuthorizationScopeChipsListComponent,
        OperatorDetailsComponent,
        TrainingCentersPreviewDialogComponent,
        DisplayExamCodePipe,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        NgxsModule.forFeature([CentersState]),
        TranslocoTestingModule,
        MatTabsModule,
        MatCardModule,
        IconDirective,
        MatMenuModule,
        SharedUiModule,
        SharedI18nModule,
        LetModule,
        PushModule,
        MatTableModule,
        MatTooltipModule,
        ClipboardModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatInputModule,
        SharedWebAppModule,
        MatDatepickerModule,
        MatChipsModule,
        SharedModule,
    ],
    exports: [
        CentersHeaderComponent,
        CentersListComponent,
        CentersFiltersComponent,
        ExaminationCentersAddFormDialogComponent,
        ExaminationCentersPreviewDialogComponent,
        TrainingCentersAddFormDialogComponent,
        OperatorQualificationsBadgesComponent,
        AuthorizationScopeChipsListComponent,
        OperatorDetailsComponent,
        TrainingCentersPreviewDialogComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibCenters",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: CentersApiService,
            useValue: undefined,
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
    ],
})
export class CentersModule {
    public static forRoot(): ModuleWithProviders<CentersModule> {
        return {
            ngModule: CentersModule,
            providers: [CentersApiService],
        };
    }

    public static forTest(): ModuleWithProviders<CentersModule> {
        return {
            ngModule: CentersModule,
            providers: [
                {
                    provide: CentersApiService,
                    useValue: null,
                },
                {
                    provide: CENTERS_ENDPOINTS,
                    useValue: {},
                },
            ],
        };
    }
}
