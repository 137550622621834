<dtm-ui-radio-tile-group [formControl]="isConfirmedControl">
    <ng-content select="label" ngProjectAs="label"></ng-content>
    <dtm-ui-radio-tile [value]="true">
        <div class="tile">
            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
            <p class="selectable-tile-label">{{ "dtmAdminLibPermits.confirmTile.yesLabel" | transloco }}</p>
        </div>
    </dtm-ui-radio-tile>
    <dtm-ui-radio-tile [value]="false">
        <div class="tile">
            <dtm-ui-icon name="close-circle"></dtm-ui-icon>
            <p class="selectable-tile-label">{{ "dtmAdminLibPermits.confirmTile.noLabel" | transloco }}</p>
        </div>
    </dtm-ui-radio-tile>
</dtm-ui-radio-tile-group>
<dtm-ui-form-errors class="preserve-error-space">
    <ng-content select=".field-error"></ng-content>
</dtm-ui-form-errors>
