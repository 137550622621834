import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { AuditApiService } from "../services/audit-api.service";
import { AuditChangeLog, AuditChangeLogError } from "../services/models";
import { AuditActions } from "./audit.actions";

export interface AuditStateModel {
    isProcessing: boolean;
    changeLog: AuditChangeLog[] | undefined;
    changeLogError: AuditChangeLogError | undefined;
    pagination: Page | undefined;
}

const defaultState: AuditStateModel = {
    isProcessing: false,
    changeLog: undefined,
    changeLogError: undefined,
    pagination: undefined,
};

@State<AuditStateModel>({
    name: "audit",
    defaults: defaultState,
})
@Injectable()
export class AuditState {
    @Selector()
    public static changeLog(state: AuditStateModel): AuditChangeLog[] | undefined {
        return state.changeLog;
    }

    @Selector()
    public static changeLogError(state: AuditStateModel): AuditChangeLogError | undefined {
        return state.changeLogError;
    }

    @Selector()
    public static isProcessing(state: AuditStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static pagination(state: AuditStateModel): Page | undefined {
        return state.pagination;
    }

    constructor(private readonly auditApiService: AuditApiService) {}

    @Action(AuditActions.GetChangeLog)
    public getChangelog(context: StateContext<AuditStateModel>, action: AuditActions.GetChangeLog) {
        context.patchState({
            isProcessing: true,
            changeLogError: undefined,
        });

        return this.auditApiService.getChangeLog(action.params).pipe(
            tap((response) => {
                context.patchState({
                    changeLog: response.content,
                    pagination: {
                        pageNumber: response.pageNumber,
                        pageSize: response.pageSize,
                        totalElements: response.totalElements,
                    },
                });
            }),
            catchError((changeLogError) => {
                context.patchState({ changeLogError, pagination: undefined });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
