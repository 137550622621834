import { HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import {
    Alpha3CountryCode,
    IdentityDocument,
    IdentityDocumentStatus,
    IdentityDocumentType,
    PageResponseBody,
    PhoneNumber,
} from "@dtm-frontend/shared/ui";
import { ObjectUtils } from "@dtm-frontend/shared/utils";
import { LegalGuardianConfirmation } from "../../shared/models/operator.models";
import { SINGLE_SELECT_VALUES } from "../../shared/models/shared.models";
import { LegalGuardianResponseBody, convertLegalGuardianResponseBodyToLegalGuardian } from "../../shared/services/shared-api.converters";
import {
    UtmUserDetails,
    UtmUserError,
    UtmUserErrorType,
    UtmUserListWithPages,
    UtmUserStatus,
    UtmUsersFiltersParams,
} from "./utm-users.models";

export interface UtmUserListResponseBody {
    id: string;
    status: UtmUserStatus;
    firstName: string;
    lastName: string;
    nationality: Alpha3CountryCode;
    legalAge: boolean;
    identityDocumentCompletionRequested: boolean;
    identityDocumentCompletionRequestDate?: string;
    legalGuardianCompletionRequested: boolean;
    legalGuardianCompletionRequestDate?: string;
}

interface UtmUserFiltersParamsRequest {
    page?: number;
    size?: number;
    searchText?: string;
    statuses?: string;
    legalAge?: string;
    foreigner?: string;
    waitingIdentityDocument?: string;
    waitingCompletionRequest?: string;
}

export interface UtmUserProfileResponseBody {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    nationality: Alpha3CountryCode;
    languageTag: string;
    phoneNumber: PhoneNumber;
    email: string;
    legalGuardian?: LegalGuardianResponseBody;
    legalGuardianConfirmationRequest?: LegalGuardianConfirmation;
    identityDocument: {
        id: string;
        type: IdentityDocumentType;
        imageMediaType: string;
        filename: string;
        approved: boolean;
        expirationDate: string;
        status: IdentityDocumentStatus;
        statusReason: string;
    };
    easaMember: boolean;
    avatarId: string;
    status: UtmUserStatus;
    legalAge: boolean;
    identityDocumentCompletionRequestDate?: string;
    legalGuardianCompletionRequestDate?: string;
}

function convertIdentityDocumentResponseBodyToIdentityDocument({ identityDocument }: UtmUserProfileResponseBody): IdentityDocument {
    return (
        identityDocument && {
            id: identityDocument.id,
            type: identityDocument.type,
            filename: identityDocument.filename,
            isApproved: identityDocument.approved,
            expirationDate: identityDocument.expirationDate,
            imageMediaType: identityDocument.imageMediaType,
            status: identityDocument.status,
            statusReason: identityDocument.statusReason,
        }
    );
}

export function convertUtmUserListPageResponseBodyToUtmUserListWithPages(
    response: PageResponseBody<UtmUserListResponseBody>
): UtmUserListWithPages {
    return {
        page: {
            pageSize: response.size,
            pageNumber: response.number,
            totalElements: response.totalElements,
        },
        content: response.content.map((user) => ({
            id: user.id,
            status: user.status,
            firstName: user.firstName,
            lastName: user.lastName,
            nationality: user.nationality,
            isLegalAge: user.legalAge,
            isIdentityDocumentCompletionRequested: user.identityDocumentCompletionRequested,
            identityDocumentCompletionRequestDate: user.identityDocumentCompletionRequestDate
                ? new Date(user.identityDocumentCompletionRequestDate)
                : undefined,
            isLegalGuardianCompletionRequested: user.legalGuardianCompletionRequested,
            legalGuardianCompletionRequestDate: user.legalGuardianCompletionRequestDate
                ? new Date(user.legalGuardianCompletionRequestDate)
                : undefined,
        })),
    };
}

export function convertUtmUserFiltersParamsToHttpParams(filters: UtmUsersFiltersParams): HttpParams {
    const queryParams: UtmUserFiltersParamsRequest = {
        page: filters.page,
        size: filters.size,
        searchText: filters.searchText,
        statuses: filters.statuses,
        legalAge: filters.isLegalAge,
        waitingIdentityDocument: filters.isWaitingIdentityDocument,
        waitingCompletionRequest: filters.isWaitingCompletionRequest,
    };

    if (filters.isDefaultCountry === SINGLE_SELECT_VALUES.True) {
        queryParams.foreigner = SINGLE_SELECT_VALUES.False;
    } else if (filters.isDefaultCountry === SINGLE_SELECT_VALUES.False) {
        queryParams.foreigner = SINGLE_SELECT_VALUES.True;
    }

    return new HttpParams({ fromObject: ObjectUtils.removeNullishProperties(queryParams) });
}

export function convertUtmUserProfileResponseBodyToUtmUserDetails(response: UtmUserProfileResponseBody): UtmUserDetails {
    return {
        id: response.id,
        basicData: {
            firstName: response.firstName,
            lastName: response.lastName,
            nationality: response.nationality,
            phoneNumber: response.phoneNumber,
            email: response.email,
            dateOfBirth: response.dateOfBirth,
        },
        identityDocument: convertIdentityDocumentResponseBodyToIdentityDocument(response),
        legalGuardian: response.legalGuardian ? convertLegalGuardianResponseBodyToLegalGuardian(response.legalGuardian) : undefined,
        legalGuardianConfirmationRequest: response.legalGuardianConfirmationRequest ?? undefined,
        isEasaMember: response.easaMember,
        avatarId: response.avatarId,
        status: response.status,
        isLegalAge: response.legalAge,
        identityDocumentCompletionRequestDate: response.identityDocumentCompletionRequestDate
            ? new Date(response.identityDocumentCompletionRequestDate)
            : undefined,
        legalGuardianCompletionRequestDate: response.legalGuardianCompletionRequestDate
            ? new Date(response.legalGuardianCompletionRequestDate)
            : undefined,
    };
}

export function convertHttpErrorResponseToUtmUserError(errorResponse: HttpErrorResponse): UtmUserError {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return { type: UtmUserErrorType.NotAuthorized };
        case HttpStatusCode.NotFound:
            return { type: UtmUserErrorType.NotFound };
        default: {
            return { type: UtmUserErrorType.Unknown };
        }
    }
}
