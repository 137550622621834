<dtm-ui-loader-container *ngrxLet="{ isProcessing: isProcessing$, expandedElement: expandedElement$ } as vm" [isShown]="vm.isProcessing">
    <table *ngrxLet="statementsList$ as statementsList" mat-table [dataSource]="statementsList" multiTemplateDataRows class="dtm-table">
        <ng-container matColumnDef="expandHandle">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button type="button" class="button-icon" (click)="$event.stopPropagation(); toggleExpandableRow(element)">
                    <dtm-ui-icon name="arrow-right" class="expand-handle" [class.expanded]="element === vm.expandedElement"></dtm-ui-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibOperationScenario.statementsList.statusColumnLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let operationScenarioStatement">
                <div class="statuses">
                    <ng-container [ngSwitch]="operationScenarioStatement.payment.status">
                        <span *ngSwitchCase="OperationScenarioStatementStatus.Completed" class="chip success">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{
                                "dtmAdminLibOperationScenario.statementsList.paymentStatus"
                                    | transloco : { value: operationScenarioStatement.payment.status }
                            }}
                        </span>
                        <span *ngSwitchCase="OperationScenarioStatementStatus.Error" class="chip error">
                            <dtm-ui-icon name="information"></dtm-ui-icon>

                            {{
                                "dtmAdminLibOperationScenario.statementsList.paymentStatus"
                                    | transloco : { value: operationScenarioStatement.payment.status }
                            }}
                        </span>
                        <span *ngSwitchDefault class="chip secondary">
                            <dtm-ui-icon name="coins"></dtm-ui-icon>
                            {{
                                "dtmAdminLibOperationScenario.statementsList.paymentStatus"
                                    | transloco : { value: operationScenarioStatement.payment.status }
                            }}
                        </span>
                    </ng-container>
                    <ng-container [ngSwitch]="operationScenarioStatement.signature.status">
                        <span *ngSwitchCase="OperationScenarioStatementStatus.Completed" class="chip success">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{
                                "dtmAdminLibOperationScenario.statementsList.signatureStatus"
                                    | transloco : { value: operationScenarioStatement.signature.status }
                            }}
                        </span>
                        <span *ngSwitchCase="OperationScenarioStatementStatus.Error" class="chip error">
                            <dtm-ui-icon name="information"></dtm-ui-icon>

                            {{
                                "dtmAdminLibOperationScenario.statementsList.signatureStatus"
                                    | transloco : { value: operationScenarioStatement.signature.status }
                            }}
                        </span>
                        <span *ngSwitchDefault class="chip secondary">
                            <dtm-ui-icon name="ball-pen"></dtm-ui-icon>
                            {{
                                "dtmAdminLibOperationScenario.statementsList.signatureStatus"
                                    | transloco : { value: operationScenarioStatement.signature.status }
                            }}
                        </span>
                    </ng-container>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="statementDate">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibOperationScenario.statementsList.statementDateColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let operationScenarioStatement">{{ operationScenarioStatement.createdAt | localizeDate }}</td>
        </ng-container>
        <ng-container matColumnDef="operatorNumber">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibOperationScenario.statementsList.operatorNumberColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let operationScenarioStatement">{{ operationScenarioStatement.operatorNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="attorneyPower">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibOperationScenario.statementsList.attorneyPowerColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let operationScenarioStatement">
                {{
                    (operationScenarioStatement.attorneyPowerAdministrativeFee
                        ? "dtmAdminLibOperationScenario.statementsList.attorneyPowerYesStatus"
                        : "dtmAdminLibOperationScenario.statementsList.attorneyPowerNoStatus"
                    ) | transloco
                }}
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibOperationScenario.statementsList.actionsColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let operationScenarioStatement">
                <button
                    *ngIf="
                        operationScenarioStatement.attorneyPowerAdministrativeFee?.isDeclarationOfExemption === false ||
                        operationScenarioStatement.signature.status === OperationScenarioStatementStatus.Completed
                    "
                    [matMenuTriggerFor]="actions"
                    class="button-icon"
                    type="button"
                    (click)="$event.stopPropagation()"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
                <mat-menu #actions="matMenu">
                    <button
                        *ngIf="operationScenarioStatement.attorneyPowerAdministrativeFee?.isDeclarationOfExemption === false"
                        mat-menu-item
                        type="button"
                        (click)="
                            administrativeFeeDocumentDownload.emit(operationScenarioStatement.attorneyPowerAdministrativeFee.feeDocumentId)
                        "
                    >
                        <dtm-ui-icon name="download"></dtm-ui-icon>
                        <span>{{ "dtmAdminLibOperationScenario.statementsList.downloadAdministrativeFeeDocumentLabel" | transloco }}</span>
                    </button>
                    <button
                        *ngIf="operationScenarioStatement.signature.status === OperationScenarioStatementStatus.Completed"
                        mat-menu-item
                        type="button"
                        (click)="
                            signatureConfirmationFileGet.emit({
                                statementId: operationScenarioStatement.id,
                                operatorId: operationScenarioStatement.operatorId
                            })
                        "
                    >
                        <dtm-ui-icon name="download"></dtm-ui-icon>
                        <span>{{ "dtmAdminLibOperationScenario.statementsList.signatureConfirmationFileLabel" | transloco }}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-error
                    *ngIf="statementsListError$ | ngrxPush; else emptyListTemplate"
                    [mode]="ErrorMode.Secondary"
                    (reload)="statementsListRefresh.emit()"
                >
                </dtm-ui-error>
                <ng-template #emptyListTemplate>
                    <dtm-ui-no-results></dtm-ui-no-results>
                </ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let operationScenarioStatement" [attr.colspan]="displayedColumns.length">
                <div class="expanded-row-content" *ngIf="operationScenarioStatement === vm.expandedElement" @slideIn>
                    <div class="sub-cell">
                        <dtm-ui-label-value [label]="'dtmAdminLibOperationScenario.statementsList.permissionsListLabel' | transloco">
                            <div class="operation-scenario-values-container">
                                <span
                                    class="operation-scenario-value"
                                    *ngFor="let operationScenario of operationScenarioStatement.operationScenarios"
                                >
                                    {{ operationScenario }}
                                </span>
                            </div>
                        </dtm-ui-label-value>
                    </div>
                    <div class="sub-cell" *ngIf="operationScenarioStatement.attorneyPowerAdministrativeFee?.isDeclarationOfExemption">
                        <dtm-ui-label-value
                            [label]="'dtmAdminLibOperationScenario.statementsList.attorneyPowerCommentsLabel' | transloco"
                            [value]="'dtmAdminLibOperationScenario.statementsList.attorneyPowerCommentsValue' | transloco"
                        >
                        </dtm-ui-label-value>
                    </div>
                    <ng-container *ngIf="operationScenarioStatement.signer">
                        <dtm-ui-label-value
                            class="sub-cell"
                            [label]="'dtmAdminLibOperationScenario.statementsList.signerLabel' | transloco"
                            [value]="operationScenarioStatement.signer.firstName + ' ' + operationScenarioStatement.signer.lastName"
                        >
                        </dtm-ui-label-value>
                        <dtm-ui-label-value
                            class="sub-cell"
                            [label]="'dtmAdminLibOperationScenario.statementsList.signatureDateLabel' | transloco"
                            [value]="operationScenarioStatement.signer.signedAt | localizeDate"
                        >
                        </dtm-ui-label-value>
                    </ng-container>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
                </dtm-ui-pagination>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-footer-row
            *matFooterRowDef="['noResults']"
            [class.hide-footer]="statementsList.length || vm.isProcessing"
            class="no-results-row"
        >
            <dtm-ui-no-results></dtm-ui-no-results>
        </tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [class.expanded]="vm.expandedElement === row"
            (click)="toggleExpandableRow(row)"
        ></tr>
        <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!statementsList.length" class="pagination-row"></tr>
    </table>
</dtm-ui-loader-container>
