import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
    Validators,
} from "@angular/forms";
import { AssociationOperationType } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-association-permit-operation-type-control",
    templateUrl: "./association-permit-operation-type-control.component.html",
    styleUrls: ["./association-permit-operation-type-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AssociationPermitOperationTypeControlComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AssociationPermitOperationTypeControlComponent),
            multi: true,
        },
    ],
})
export class AssociationPermitOperationTypeControlComponent implements ControlValueAccessor, Validator {
    @Input() public set operationType(value: AssociationOperationType | null) {
        this.operationTypeControl.setValue(value, { emitEvent: false });
        if (!this.validate(this.operationTypeControl)) {
            this.propagateChange(this.operationTypeControl.value);
        }

        this.onValidationChange();
    }
    protected readonly AssociationOperationType = AssociationOperationType;
    public readonly operationTypeControl = new FormControl<AssociationOperationType | null>(null, {
        validators: Validators.required,
    });

    protected propagateChange: (missionType: AssociationOperationType | null) => void = FunctionUtils.noop;
    protected propagateTouch: () => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor() {
        this.operationTypeControl.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
            this.propagateChange(val);
        });
    }

    public registerOnChange(onChange: (value: AssociationOperationType | null) => void) {
        this.propagateChange = onChange;
    }

    public registerOnTouched(onTouched: () => void) {
        this.propagateTouch = onTouched;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(operationType: AssociationOperationType | null) {
        this.operationType = operationType;
    }

    public validate(control: AbstractControl) {
        if (!control.value) {
            return {
                required: true,
            };
        }

        return null;
    }
}
