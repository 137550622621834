import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DashboardDetailsError } from "../../../services/dashboard.model";

interface StatsTileComponentState {
    statsError: DashboardDetailsError | undefined;
    header: string | undefined;
}

@Component({
    selector: "dtm-admin-lib-stats-tile[header]",
    templateUrl: "./stats-tile.component.html",
    styleUrls: ["./stats-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StatsTileComponent {
    @Input() public set header(value: string | undefined) {
        this.localStore.patchState({ header: value });
    }

    public readonly header$ = this.localStore.selectByKey("header");

    constructor(private readonly localStore: LocalComponentStore<StatsTileComponentState>) {
        localStore.setState({ statsError: undefined, header: undefined });
    }
}
