import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ChangeLogContainerComponent } from "./components/change-log-container/change-log-container.component";
import { ChangeLogFiltersComponent } from "./components/change-log-filters/change-log-filters.component";
import { ChangeLogListComponent } from "./components/change-log-list/change-log-list.component";
import { AuditApiService } from "./services/audit-api.service";
import { AuditState } from "./state/audit.state";

@NgModule({
    declarations: [ChangeLogContainerComponent, ChangeLogListComponent, ChangeLogFiltersComponent],
    imports: [
        CommonModule,
        PushModule,
        NgxsModule.forFeature([AuditState]),
        MatTableModule,
        MatPaginatorModule,
        TranslocoModule,
        SharedUiModule,
        SharedI18nModule,
        LetModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDatepickerModule,
        FormsModule,
    ],
    exports: [ChangeLogListComponent, ChangeLogFiltersComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibAudit",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: AuditApiService,
            useValue: undefined,
        },
    ],
})
export class AuditModule {
    public static forRoot(): ModuleWithProviders<AuditModule> {
        return {
            ngModule: AuditModule,
            providers: [
                {
                    provide: AuditApiService,
                    useClass: AuditApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<AuditModule> {
        return {
            ngModule: AuditModule,
            providers: [
                {
                    provide: AuditApiService,
                    useValue: null,
                },
            ],
        };
    }
}
