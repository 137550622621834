<ng-container *ngrxLet="{ competencies: competencies$, areActionsAvailable: areActionsAvailable$ } as vm">
    <h2 class="header">{{ "dtmAdminLibShared.operatorProfile.foreignCompetencies.header" | transloco }}</h2>
    <div class="competency" *ngFor="let competency of vm.competencies">
        <div class="competency-header">
            <div class="type-with-status">
                <p class="competency-type">
                    {{
                        (competency.type === ForeignCompetencyType.A1_A3
                            ? "dtmAdminLibShared.operatorProfile.foreignCompetencies.a1a3confirmatoryDocument"
                            : "dtmAdminLibShared.operatorProfile.foreignCompetencies.a2confirmatoryDocument"
                        ) | transloco
                    }}
                </p>
                <div class="competency-status-with-action" [ngSwitch]="competency.status">
                    <span *ngSwitchCase="ForeignCompetencyStatus.Accepted" class="chip success">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{
                            "dtmAdminLibShared.operatorProfile.foreignCompetencies.competencyStatusLabel"
                                | transloco : { status: competency.status }
                        }}
                    </span>
                    <dtm-ui-content-popover *ngSwitchCase="ForeignCompetencyStatus.Rejected">
                        <span class="chip error">
                            <dtm-ui-icon name="error-warning"></dtm-ui-icon>
                            <span>
                                {{
                                    "dtmAdminLibShared.operatorProfile.foreignCompetencies.competencyStatusLabel"
                                        | transloco : { status: competency.status }
                                }}
                            </span>
                        </span>
                        <div popoverContent *ngIf="competency.statusReason">
                            <strong>{{ "dtmAdminLibShared.operatorProfile.foreignCompetencies.rejectionReasonLabel" | transloco }}:</strong>
                            <p>{{ competency.statusReason }}</p>
                        </div>
                    </dtm-ui-content-popover>
                    <span *ngSwitchCase="ForeignCompetencyStatus.Waiting" class="chip warning">
                        <dtm-ui-icon name="information"></dtm-ui-icon>
                        {{
                            "dtmAdminLibShared.operatorProfile.foreignCompetencies.competencyStatusLabel"
                                | transloco : { status: competency.status }
                        }}
                    </span>
                </div>
            </div>
            <button
                type="button"
                class="button-icon"
                [matMenuTriggerFor]="actions"
                *ngIf="competency.status === ForeignCompetencyStatus.Waiting"
            >
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>

            <mat-menu #actions="matMenu">
                <button type="button" mat-menu-item (click)="competencyAccept.next(competency.id)">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.foreignCompetencies.acceptLabel" | transloco }}
                </button>
                <button type="button" mat-menu-item (click)="competencyReject.next(competency.id)">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.foreignCompetencies.rejectLabel" | transloco }}
                </button>
            </mat-menu>
        </div>
        <div class="competency-details">
            <dtm-ui-label-value
                [label]="'dtmAdminLibShared.operatorProfile.foreignCompetencies.confirmatoryDocumentExpirationDateLabel' | transloco"
                [value]="competency.expirationDate | localizeDate"
            ></dtm-ui-label-value>
            <dtm-ui-label-value [label]="'dtmAdminLibShared.operatorProfile.foreignCompetencies.attachmentLabel' | transloco">
                <div class="attachment">
                    <dtm-ui-icon name="attachment"></dtm-ui-icon>
                    <span class="document-file-name">{{ competency.file.name | ellipsifyText : 50 }}</span>
                    <button *ngIf="vm.areActionsAvailable" (click)="download.emit(competency)" type="button" class="button-icon">
                        <dtm-ui-icon name="download"></dtm-ui-icon>
                    </button>
                </div>
            </dtm-ui-label-value>
        </div>
    </div>
</ng-container>
