<table *ngIf="operators$ | ngrxPush as operators" multiTemplateDataRows mat-table [dataSource]="operators" class="dtm-table">
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibOperator.operatorsList.operatorStatusTableHeader" | transloco }}</th>
        <td mat-cell *matCellDef="let operator">
            <dtm-ui-operator-status-badge [status]="operator.status"></dtm-ui-operator-status-badge>
        </td>
    </ng-container>
    <ng-container matColumnDef="operatorNumber">
        <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibOperator.operatorsList.operatorNumberTableHeader" | transloco }}</th>
        <td mat-cell *matCellDef="let operator">{{ operator.operatorNumber }}</td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibOperator.operatorsList.operatorNameTableHeader" | transloco }}</th>
        <td mat-cell *matCellDef="let operator">{{ operator.name }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibOperator.operatorsList.operatorTypeTableHeader" | transloco }}</th>
        <td mat-cell *matCellDef="let operator">
            <div [ngSwitch]="operator.type" class="operator-type-cell">
                <ng-container *ngSwitchCase="OperatorType.Personal">
                    <dtm-ui-icon name="user" class="operator-type-icon"></dtm-ui-icon>
                    <span>{{ "dtmAdminLibShared.operatorProfile.operatorType.personalLabel" | transloco }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="OperatorType.Enterprise">
                    <dtm-ui-icon name="building" class="operator-type-icon"></dtm-ui-icon>
                    <span>{{ "dtmAdminLibShared.operatorProfile.operatorType.enterpriseLabel" | transloco }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="OperatorType.Association">
                    <dtm-ui-icon name="group" class="operator-type-icon"></dtm-ui-icon>
                    <span>{{ "dtmAdminLibShared.operatorProfile.operatorType.associationLabel" | transloco }}</span>
                </ng-container>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
            <dtm-ui-error
                *ngIf="operatorListError$ | ngrxPush; else emptyListTemplate"
                [mode]="ErrorMode.Secondary"
                [errorMessage]="'dtmAdminLibOperator.operatorsList.errorMessage' | transloco"
                (reload)="operatorsListRefresh.emit()"
            >
            </dtm-ui-error>
            <ng-template #emptyListTemplate>
                <dtm-ui-no-results></dtm-ui-no-results>
            </ng-template>
        </td>
    </ng-container>
    <ng-container matColumnDef="pagination">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
            <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
            </dtm-ui-pagination>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-footer-row *matFooterRowDef="['noResults']" [class.hide-footer]="operators.length" class="no-results-row">
        <dtm-ui-no-results></dtm-ui-no-results>
    </tr>
    <tr
        (click)="selectOperator(operator)"
        mat-row
        *matRowDef="let operator; columns: displayedColumns"
        [class.expanded]="(selectedOperator$ | ngrxPush) === operator.id"
        class="operator-row"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!operators.length" class="pagination-row"></tr>
</table>
