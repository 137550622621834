<div class="grid grid-maxsize-8 grid-maxsize-md-12" *ngIf="!(areErrors$ | ngrxPush); else errorTemplate">
    <dtm-ui-lib-wizard-content [wizardId]="ASSOCIATION_PERMIT_WIZARD_ID">
        <ng-container *ngrxLet="{ isProcessing: isProcessing$, existingAssociationPermit: existingAssociationPermit$ } as vm">
            <dtm-ui-lib-wizard-step-content [stepId]="AssociationPermitWizardSteps.BasicInfo">
                <dtm-admin-lib-association-permit-request-basic-details-step
                    [existingAssociationPermit]="vm.existingAssociationPermit"
                    [isProcessing]="vm.isProcessing"
                    [caaContactUsers]="caaContactUsers$ | ngrxPush"
                    [associationOperators]="associationOperators$ | ngrxPush"
                    (searchTextChange)="searchOperator($event)"
                    (basicDetailsSave)="saveBasicDetailsAndGoToNextStep($event)"
                    (back)="goBackToList()"
                ></dtm-admin-lib-association-permit-request-basic-details-step>
            </dtm-ui-lib-wizard-step-content>
            <dtm-ui-lib-wizard-step-content [stepId]="AssociationPermitWizardSteps.PermitDetails">
                <dtm-admin-lib-association-permit-request-permit-details
                    [existingAssociationPermit]="vm.existingAssociationPermit"
                    [isProcessing]="vm.isProcessing"
                    (permitDetailsSave)="savePermitDetailsAndGoToNextStep($event)"
                    (back)="goToBasicDetailsStep()"
                ></dtm-admin-lib-association-permit-request-permit-details>
            </dtm-ui-lib-wizard-step-content>
            <dtm-ui-lib-wizard-step-content [stepId]="AssociationPermitWizardSteps.Summary">
                <dtm-admin-lib-association-permit-request-summary
                    [isEditMode]="!!vm.existingAssociationPermit"
                    [permit]="permit$ | ngrxPush"
                    (back)="goToPermitDetailsStep()"
                    (save)="savePermit()"
                    (stepEdit)="goToStep($event)"
                ></dtm-admin-lib-association-permit-request-summary>
            </dtm-ui-lib-wizard-step-content>
        </ng-container>
    </dtm-ui-lib-wizard-content>
</div>

<ng-template #errorTemplate>
    <dtm-ui-error
        (reload)="retryFetchData()"
        [mode]="ErrorMode.Secondary"
        [errorMessage]="'dtmAdminLibPermits.container.cannotGetCapabilitiesOrOperatorError' | transloco"
    ></dtm-ui-error>
</ng-template>
