import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import {
    AvoidingFlightsOverPeopleType,
    GroundRiskMitigationModel,
    GroundRiskMitigationType,
    RiskAssessmentReference,
} from "@dtm-frontend/shared/ui";
import { AnimationUtils, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface GroundRiskMitigationFormComponentState {
    riskMethodology: RiskAssessmentReference | undefined;
}

interface GroundRiskMitigationForm {
    strategicMitigationMeasure: FormControl<GroundRiskMitigationType | null>;
    emergencyResponsePlan: FormControl<GroundRiskMitigationType | null>;
    avoidingFlightsOverPeople: FormControl<AvoidingFlightsOverPeopleType | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-ground-risk-mitigation-form[riskMethodology]",
    templateUrl: "./ground-risk-mitigation-form.component.html",
    styleUrls: ["../../styles/shared-permit-styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GroundRiskMitigationFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => GroundRiskMitigationFormComponent),
            multi: true,
        },
    ],
    animations: [AnimationUtils.slideInAnimation()],
})
export class GroundRiskMitigationFormComponent implements ControlValueAccessor, Validator {
    @Input() public set riskMethodology(value: RiskAssessmentReference | undefined) {
        this.localStore.patchState({ riskMethodology: value });
        this.assignOptionsByRiskMethodology(value);
    }
    protected readonly riskMethodology$ = this.localStore.selectByKey("riskMethodology");
    protected readonly GroundRiskMitigationType = GroundRiskMitigationType;
    protected readonly AvoidingFlightsOverPeopleType = AvoidingFlightsOverPeopleType;
    protected readonly groundRiskMitigationForm = new FormGroup<GroundRiskMitigationForm>({
        strategicMitigationMeasure: new FormControl(null, Validators.required),
        emergencyResponsePlan: new FormControl(null, Validators.required),
        avoidingFlightsOverPeople: new FormControl(null, Validators.required),
    });

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: GroundRiskMitigationModel) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<GroundRiskMitigationFormComponentState>) {
        localStore.setState({ riskMethodology: undefined });
        this.groundRiskMitigationForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.groundRiskMitigationForm.getRawValue() as GroundRiskMitigationModel);
        });
    }

    public registerOnChange(fn: (value: GroundRiskMitigationModel) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: GroundRiskMitigationModel | undefined): void {
        if (value) {
            this.groundRiskMitigationForm.reset(
                {
                    strategicMitigationMeasure: value.strategicMitigationMeasure,
                    emergencyResponsePlan: value.emergencyResponsePlan,
                    avoidingFlightsOverPeople: value.avoidingFlightsOverPeople,
                },
                { emitEvent: false }
            );
        } else {
            this.groundRiskMitigationForm.reset();
        }
    }

    public validate(): ValidationErrors | null {
        return this.groundRiskMitigationForm.invalid ? { groundRiskMitigationForm: true } : null;
    }

    protected keepOrder(): number {
        return 0;
    }

    private assignOptionsByRiskMethodology(value: RiskAssessmentReference | undefined) {
        switch (value) {
            case RiskAssessmentReference.SORA2_0_ALTMOC:
                this.groundRiskMitigationForm.controls.emergencyResponsePlan.disable();
                this.groundRiskMitigationForm.controls.emergencyResponsePlan.reset();
                this.groundRiskMitigationForm.controls.avoidingFlightsOverPeople.enable({ emitEvent: false });
                this.groundRiskMitigationForm.controls.strategicMitigationMeasure.enable({ emitEvent: false });
                break;
            case RiskAssessmentReference.SORA2_0:
            case RiskAssessmentReference.PDRA:
                this.groundRiskMitigationForm.controls.avoidingFlightsOverPeople.disable();
                this.groundRiskMitigationForm.controls.avoidingFlightsOverPeople.reset();
                this.groundRiskMitigationForm.controls.emergencyResponsePlan.enable({ emitEvent: false });
                this.groundRiskMitigationForm.controls.strategicMitigationMeasure.enable({ emitEvent: false });
                break;
            default:
                this.groundRiskMitigationForm.disable();
        }
    }
}
