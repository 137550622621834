import { ChangeDetectionStrategy, Component } from "@angular/core";
import { CrossBorderPermitWizardSteps } from "../../../models/cross-border-permit.models";
import { CROSS_BORDER_PERMIT_WIZARD_ID } from "../cross-border-permit-wizard.models";

@Component({
    selector: "dtm-admin-lib-cross-border-permit-request-header",
    templateUrl: "./cross-border-permit-request-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrossBorderPermitRequestHeaderComponent {
    protected readonly CROSS_BORDER_PERMIT_WIZARD_ID = CROSS_BORDER_PERMIT_WIZARD_ID;
    protected readonly CrossBorderPermitWizardSteps = CrossBorderPermitWizardSteps;
}
