import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ContentObserver } from "@angular/cdk/observers";
import { ChangeDetectionStrategy, Component, ElementRef, Input } from "@angular/core";
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from "@angular/material/legacy-tabs";
import { EmptyStateMode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map, startWith } from "rxjs/operators";
import { GuideChapterItem, SAIL_REQUIREMENTS, Sail } from "../../models/operations-manual.models";

enum GuideTab {
    CHAPTERS = 0,
    REQUIREMENTS = 1,
}

interface OperationsManualGuideComponentState {
    selectedTab: GuideTab;
    guideChapterItems: GuideChapterItem[];
    highlightedChapterId: string | null;
    selectedSail: Sail | undefined;
    isGuidePanelVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-opsman-guide[guideChapterItems]",
    templateUrl: "./operations-manual-guide.component.html",
    styleUrls: ["./operations-manual-guide.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperationsManualGuideComponent {
    @Input() public set guideChapterItems(value: GuideChapterItem[] | undefined) {
        this.localStore.patchState({ guideChapterItems: value ?? [] });
    }

    @Input() public set highlightedChapterId(value: string | undefined) {
        this.localStore.patchState({ highlightedChapterId: value ?? null });
    }

    @Input() public set selectedSail(value: Sail | undefined) {
        this.localStore.patchState({ selectedSail: value });
    }

    @Input() public set isGuidePanelVisible(value: BooleanInput) {
        this.localStore.patchState({ isGuidePanelVisible: coerceBooleanProperty(value) });
    }

    protected readonly sailLevels = Object.keys(SAIL_REQUIREMENTS).map((sailLevel) => +sailLevel);
    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly guideChapterItems$ = this.localStore.selectByKey("guideChapterItems");
    protected readonly highlightedChapterId$ = this.localStore.selectByKey("highlightedChapterId");
    protected readonly selectedTab$ = this.localStore.selectByKey("selectedTab");
    protected readonly isGuidePanelVisible$ = this.localStore.selectByKey("isGuidePanelVisible");
    protected readonly selectedSail$ = this.localStore.selectByKey("selectedSail");
    protected readonly sailRequirementsItems$ = this.selectedSail$.pipe(
        map((selectedSail) => {
            if (selectedSail) {
                return SAIL_REQUIREMENTS[selectedSail] ?? [];
            }

            return null;
        }),
        startWith(null)
    );

    constructor(
        private readonly element: ElementRef,
        private readonly localStore: LocalComponentStore<OperationsManualGuideComponentState>,
        private readonly contentObserver: ContentObserver
    ) {
        this.localStore.setState({
            selectedTab: GuideTab.CHAPTERS,
            guideChapterItems: [],
            highlightedChapterId: null,
            selectedSail: this.sailLevels[0],
            isGuidePanelVisible: false,
        });

        this.watchOnItemHighlight();
    }

    public selectSail(sail: Sail) {
        this.localStore.patchState({
            selectedTab: GuideTab.REQUIREMENTS,
            selectedSail: sail,
        });
    }

    private watchOnItemHighlight() {
        const container: HTMLElement = this.element.nativeElement;

        this.contentObserver
            .observe(container)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                let yPosition = 0;

                const highlightedItemElement: HTMLElement = this.element.nativeElement.querySelector(".highlighted");
                if (highlightedItemElement) {
                    yPosition =
                        highlightedItemElement.getBoundingClientRect().y +
                        container.scrollTop -
                        container.getBoundingClientRect().height / 2;
                }

                container.scrollTo({
                    top: yPosition,
                    behavior: "smooth",
                });
            });
    }

    protected changeTab({ index }: MatTabChangeEvent): void {
        this.localStore.patchState({
            selectedTab: index,
        });
    }

    protected filterRequirementsBySail(sail: Sail): void {
        this.localStore.patchState({
            selectedSail: sail,
        });
    }
}
