import { ComponentPortal } from "@angular/cdk/portal";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { CaaNotificationsService, GlobalCapabilitiesState } from "@dtm-frontend/caa-admin-lib/shared";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { Notification } from "@dtm-frontend/shared/notifications";
import { DeviceSize, DeviceSizeService, RouteDataService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { distinctUntilChanged, map } from "rxjs/operators";

interface HeaderComponentState {
    notificationsList: Notification[] | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-header",
    templateUrl: "./header.component.html",
    styles: ["dtm-ui-header-user-button { margin-right: -12px; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeaderComponent {
    protected readonly routeTitle$ = this.routeDataService.routeTitle$;
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    protected readonly userName$ = this.store.select(GlobalCapabilitiesState.globalCapabilitiesUser).pipe(
        RxjsUtils.filterFalsy(),
        map((user) => `${user.firstName} ${user.lastName}`)
    );
    protected readonly notificationsList$ = this.localStore.selectByKey("notificationsList");
    protected readonly allowedNotificationTypes = this.caaNotificationsService.getAllowedNotifications();
    protected readonly avatarUrl$ = this.store.select(GlobalCapabilitiesState.globalCapabilitiesUser).pipe(
        RxjsUtils.filterFalsy(),
        map((data) => data.avatarUrl)
    );
    protected readonly isHeaderUserButtonShortMode$ = this.deviceSizeService.getSizeObservable(
        DeviceSize.Smartphone,
        DeviceSize.SmartphoneWide
    );

    protected readonly userId$ = this.store.select(AuthState.userId).pipe(RxjsUtils.filterFalsy());

    protected readonly componentPortalItem$ = this.routeDataService.routeData$.pipe(
        map((data) => data?.headerComponent),
        distinctUntilChanged(),
        map((headerComponent) => {
            if (!headerComponent) {
                return;
            }

            return new ComponentPortal(headerComponent);
        })
    );

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<HeaderComponentState>,
        private readonly caaNotificationsService: CaaNotificationsService,
        private readonly routeDataService: RouteDataService,
        private readonly deviceSizeService: DeviceSizeService
    ) {
        localStore.setState({
            notificationsList: undefined,
        });
    }

    protected logout() {
        this.store.dispatch(new AuthActions.Logout());
    }

    protected goToUserProfilePage() {
        this.router.navigate(["/user"]);
    }

    protected changeNotificationsList(notificationsList: Notification[] | undefined) {
        this.localStore.patchState({ notificationsList });
    }
}
