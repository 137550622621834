import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DateUtils, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { BaseOperator } from "../../shared/models/operator.models";
import { CENTERS_ENDPOINTS, CentersEndpoints } from "../centers.tokens";
import { Capabilities, CapabilitiesErrorType } from "../models/capabilities.model";
import { CentersFilterParams } from "../models/centers.models";
import {
    ExaminationCenter,
    ExaminationCenterListWithPages,
    ExaminationCentersError,
    ExaminationCentersErrorType,
} from "../models/examination-centers.models";
import { OperatorDetails, OperatorsErrorType } from "../models/operators.model";
import {
    TrainingCenter,
    TrainingCenterListWithPages,
    TrainingCentersError,
    TrainingCentersErrorType,
} from "../models/training-centers.models";
import {
    GetExaminationCentersListResponseBody,
    GetTrainingCentersListResponseBody,
    convertGetExaminationCenterListResponseBodyToCenterListWithPages,
    convertGetTrainingCenterListResponseBodyToCenterListWithPages,
    getCentersParams,
} from "./centers-api.converters";

@Injectable({
    providedIn: "root",
})
export class CentersApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(CENTERS_ENDPOINTS) private readonly endpoints: CentersEndpoints) {}

    public getExaminationCentersList(filters: CentersFilterParams): Observable<ExaminationCenterListWithPages> {
        const params: HttpParams = getCentersParams(filters);

        return this.httpClient.get<GetExaminationCentersListResponseBody>(this.endpoints.addAndGetExaminationCenter, { params }).pipe(
            map((response) => convertGetExaminationCenterListResponseBodyToCenterListWithPages(response)),
            catchError(() => throwError(() => ({ type: ExaminationCentersErrorType.CannotGetExaminationCentersList })))
        );
    }

    public addExaminationCenter(payload: ExaminationCenter): Observable<ExaminationCenter> {
        payload.validUntil = this.getFormattedDate(payload.validUntil);

        return this.httpClient
            .post<ExaminationCenter>(this.endpoints.addAndGetExaminationCenter, payload)
            .pipe(catchError((error) => throwError(this.transformAddExaminationCenterErrorResponse(error))));
    }

    public editExaminationCenter(payload: ExaminationCenter): Observable<ExaminationCenter> {
        delete payload?.identificationNumber;
        payload.validUntil = this.getFormattedDate(payload.validUntil);

        return this.httpClient
            .put<ExaminationCenter>(
                StringUtils.replaceInTemplate(this.endpoints.editAndDeleteExaminationCenter, { id: payload.id as string }),
                payload
            )
            .pipe(catchError(() => throwError(() => ({ type: ExaminationCentersErrorType.CannotEditExaminationCenter }))));
    }

    public deleteExaminationCenter(id: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.editAndDeleteExaminationCenter, { id }))
            .pipe(catchError(() => throwError(() => ({ type: ExaminationCentersErrorType.CannotDeleteExaminationCenter }))));
    }

    public getCapabilitiesList(): Observable<Capabilities> {
        return this.httpClient
            .get<Capabilities>(this.endpoints.getCapabilities)
            .pipe(catchError(() => throwError(() => ({ type: CapabilitiesErrorType.CannotGetCapabilitiesList }))));
    }

    public getOperatorsList(searchPhrase: string | undefined): Observable<BaseOperator[]> {
        const params = new HttpParams().set("searchPhrase", searchPhrase ?? "");

        return this.httpClient
            .get<BaseOperator[]>(this.endpoints.getOperatorsList, { params })
            .pipe(catchError(() => throwError(() => ({ type: OperatorsErrorType.CannotGetOperatorsList }))));
    }

    public getOperatorDetails(id: string): Observable<OperatorDetails> {
        return this.httpClient
            .get<OperatorDetails>(StringUtils.replaceInTemplate(this.endpoints.getOperatorDetails, { id }))
            .pipe(catchError(() => throwError(() => ({ type: OperatorsErrorType.CannotGetOperatorDetails }))));
    }

    public addTrainingCenter(payload: TrainingCenter): Observable<TrainingCenter> {
        payload.validUntil = this.getFormattedDate(payload.validUntil);

        return this.httpClient
            .post<TrainingCenter>(this.endpoints.manageTrainingCenters, payload)
            .pipe(catchError((error) => throwError(this.transformAddTrainingCenterErrorResponse(error))));
    }

    public editTrainingCenter(payload: TrainingCenter): Observable<TrainingCenter> {
        delete payload?.identificationNumber;
        payload.validUntil = this.getFormattedDate(payload.validUntil);

        return this.httpClient
            .put<TrainingCenter>(StringUtils.replaceInTemplate(this.endpoints.manageTrainingCenter, { id: payload.id as string }), payload)
            .pipe(catchError(() => throwError(() => ({ type: TrainingCentersErrorType.CannotEditTrainingCenter }))));
    }

    public deleteTrainingCenter(id: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.manageTrainingCenter, { id }))
            .pipe(catchError(() => throwError(() => ({ type: TrainingCentersErrorType.CannotDeleteExaminationCenter }))));
    }

    public getTrainingCentersList(filters: CentersFilterParams): Observable<TrainingCenterListWithPages> {
        const params: HttpParams = getCentersParams(filters);

        return this.httpClient.get<GetTrainingCentersListResponseBody>(this.endpoints.manageTrainingCenters, { params }).pipe(
            map((response) => convertGetTrainingCenterListResponseBodyToCenterListWithPages(response)),
            catchError(() => throwError(() => ({ type: ExaminationCentersErrorType.CannotGetExaminationCentersList })))
        );
    }

    private getFormattedDate(date: Date): Date {
        return new Date(DateUtils.getISOStringDate(date.toISOString()));
    }

    private transformAddTrainingCenterErrorResponse(errorResponse: HttpErrorResponse): TrainingCentersError {
        switch (errorResponse.status) {
            case HttpStatusCode.Conflict:
                return { type: TrainingCentersErrorType.TrainingCenterConflict };
            default:
                return { type: TrainingCentersErrorType.CannotAddTrainingCenter };
        }
    }

    private transformAddExaminationCenterErrorResponse(errorResponse: HttpErrorResponse): ExaminationCentersError {
        switch (errorResponse.status) {
            case HttpStatusCode.Conflict:
                return { type: ExaminationCentersErrorType.ExaminationCenterConflict };
            default:
                return { type: ExaminationCentersErrorType.CannotAddExaminationCenter };
        }
    }
}
