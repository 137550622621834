import { HttpClient, HttpContext, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { LegalGuardianDetailsUpdate } from "../../shared/models/operator.models";
import {
    LegalGuardianUpdateRequest,
    convertLegalGuardianDetailsUpdateToLegalGuardianUpdateRequest,
} from "../../shared/services/shared-api.converters";
import { UTM_USERS_ENDPOINTS, UtmUsersEndpoints } from "../utm-users.tokens";
import {
    UtmUserListResponseBody,
    UtmUserProfileResponseBody,
    convertHttpErrorResponseToUtmUserError,
    convertUtmUserFiltersParamsToHttpParams,
    convertUtmUserListPageResponseBodyToUtmUserListWithPages,
    convertUtmUserProfileResponseBodyToUtmUserDetails,
} from "./utm-users-api.converters";
import {
    UtmUserBasicData,
    UtmUserDetails,
    UtmUserErrorType,
    UtmUserIdentityCardStatusRequest,
    UtmUserListWithPages,
    UtmUsersFiltersParams,
} from "./utm-users.models";

@Injectable({
    providedIn: "root",
})
export class UtmUsersApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(UTM_USERS_ENDPOINTS) private readonly endpoints: UtmUsersEndpoints) {}

    public getUsers(filters: UtmUsersFiltersParams): Observable<UtmUserListWithPages> {
        const params = convertUtmUserFiltersParamsToHttpParams(filters);

        return this.httpClient.get<PageResponseBody<UtmUserListResponseBody>>(this.endpoints.getUserList, { params }).pipe(
            map((response) => convertUtmUserListPageResponseBodyToUtmUserListWithPages(response)),
            catchError(() => throwError(() => ({ type: UtmUserErrorType.Unknown })))
        );
    }

    public getUserDetails(userId: string): Observable<UtmUserDetails> {
        return this.httpClient.get<UtmUserProfileResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getUser, { userId })).pipe(
            map((response) => convertUtmUserProfileResponseBodyToUtmUserDetails(response)),
            catchError((errorResponse: HttpErrorResponse) => throwError(() => convertHttpErrorResponseToUtmUserError(errorResponse)))
        );
    }

    public updateUser(userId: string, userValues: UtmUserBasicData): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateUserDetails, { userId }), userValues)
            .pipe(catchError(() => throwError(() => ({ type: UtmUserErrorType.CannotUpdateUser }))));
    }

    public updateIdentityDocumentStatus(
        userId: string,
        identityDocumentId: string,
        statusRequest: UtmUserIdentityCardStatusRequest
    ): Observable<void> {
        return this.httpClient
            .put<void>(
                StringUtils.replaceInTemplate(this.endpoints.changeUserIdentityDocumentStatus, { userId, identityDocumentId }),
                statusRequest
            )
            .pipe(catchError(() => throwError(() => ({ type: UtmUserErrorType.CannotUpdateDocumentStatus }))));
    }

    public downloadIdentityDocument(userId: string, identityDocumentId: string, fileName: string): Observable<Blob> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.downloadIdentityDocument, { userId, identityDocumentId }), {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                tap((blob: Blob) => saveAs(blob, fileName)),
                catchError(() => throwError(() => ({ type: UtmUserErrorType.CannotDownloadDocument })))
            );
    }

    public updateLegalGuardian(userId: string, legalGuardianValues: LegalGuardianDetailsUpdate) {
        const updateLegalGuardianRequest: LegalGuardianUpdateRequest =
            convertLegalGuardianDetailsUpdateToLegalGuardianUpdateRequest(legalGuardianValues);

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateUserLegalGuardianDetails, { userId }), updateLegalGuardianRequest)
            .pipe(catchError(() => throwError(() => ({ type: UtmUserErrorType.CannotUpdateLegalGuardian }))));
    }

    public deleteLegalGuardian(userId: string) {
        return this.httpClient
            .delete(StringUtils.replaceInTemplate(this.endpoints.deleteUserLegalGuardian, { userId }))
            .pipe(catchError(() => throwError(() => ({ type: UtmUserErrorType.CannotDeleteLegalGuardian }))));
    }

    public getLegalGuardianSignedDocument(userId: string, fileName: string): Observable<Blob> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.getLegalGuardianSignedDocument, { userId }), {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                tap((response) => saveAs(response, fileName)),
                catchError(() => throwError(() => ({ type: UtmUserErrorType.CannotGetLegalGuardianSignedDocument })))
            );
    }

    public requestForDocumentUpdate(userId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.requestForDocumentUpdate, { userId }), {})
            .pipe(catchError(() => throwError(() => ({ type: UtmUserErrorType.Unknown }))));
    }

    public requestForLegalGuardianUpdate(userId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.requestForLegalGuardianUpdate, { userId }), {})
            .pipe(catchError(() => throwError(() => ({ type: UtmUserErrorType.Unknown }))));
    }
}
