import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DateUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

export interface MissionDates {
    startDate: Date;
    endDate: Date;
}

interface MissionDateRangeComponentState {
    missionDates: MissionDates | undefined;
}

@Component({
    selector: "dtm-web-app-lib-mission-date-range[missionDates]",
    templateUrl: "./mission-date-range.component.html",
    styleUrls: ["./mission-date-range.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDateRangeComponent {
    @Input() public set missionDates(value: MissionDates | undefined) {
        this.localStore.patchState({ missionDates: value });
    }

    protected readonly missionDates$ = this.localStore.selectByKey("missionDates").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<MissionDateRangeComponentState>) {
        this.localStore.setState({
            missionDates: undefined,
        });
    }

    protected getDaysBetween(startDate: Date, endDate: Date): number {
        const daysBetween = DateUtils.daysBetween(startDate, endDate);

        return Math.max(daysBetween + 1, 1);
    }
}
