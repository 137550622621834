import { HttpClient, HttpContext, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ImageConverterService, PhoneNumber } from "@dtm-frontend/shared/ui";
import { LOCALE_MAPPING, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { USER_PROFILE_ENDPOINTS, UserProfileEndpoints } from "../user-profile.tokens";
import {
    convertEmailChangeErrorResponse,
    convertGetProfileErrorResponse,
    convertPhoneNumberErrorResponse,
} from "./user-profile-api.converters";
import { AvailableAndAssignedConversationCategories, PersonalData, User, UserProfileErrorType } from "./user-profile.models";

@Injectable({
    providedIn: "root",
})
export class UserProfileApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly imageConverter: ImageConverterService,
        @Inject(USER_PROFILE_ENDPOINTS) private readonly endpoints: UserProfileEndpoints
    ) {}

    public getUserProfile(userId: string): Observable<User> {
        return this.httpClient
            .get<User>(StringUtils.replaceInTemplate(this.endpoints.getUserProfile, { userId }))
            .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(() => convertGetProfileErrorResponse(errorResponse))));
    }

    public getProfileCapabilities(userId: string): Observable<AvailableAndAssignedConversationCategories> {
        return this.httpClient
            .get<AvailableAndAssignedConversationCategories>(
                StringUtils.replaceInTemplate(this.endpoints.getProfileCapabilities, { userId })
            )
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotGetProfileCapabilities }))));
    }

    public getProfileAvatar(userId: string): Observable<string> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.getProfileAvatar, { userId }), {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true),
            })
            .pipe(switchMap((avatarFile: Blob) => this.imageConverter.convertBlobToBase64(avatarFile)));
    }

    public saveNewEmailAddress(userId: string, email: string): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.saveEmailAddress, { userId }), { email })
            .pipe(catchError((error) => throwError(() => convertEmailChangeErrorResponse(error))));
    }

    public saveNewPhoneNumber(userId: string, phoneNumber: PhoneNumber): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.savePhoneNumber, { userId }), { phoneNumber })
            .pipe(catchError((error) => throwError(() => convertPhoneNumberErrorResponse(error))));
    }

    public saveNewConversationCategories(userId: string, categoryIds: string[]): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.saveConversationCategories, { userId }), { categoryIds, userId })
            .pipe(
                catchError(() =>
                    throwError(() => ({
                        type: UserProfileErrorType.CannotSaveConversationCategories,
                    }))
                )
            );
    }

    public saveNewPersonalData(userId: string, personalData: PersonalData): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.savePersonalData, { userId }), { ...personalData })
            .pipe(
                catchError(() =>
                    throwError(() => ({
                        type: UserProfileErrorType.CannotSavePersonalData,
                    }))
                )
            );
    }

    public updateProfileLanguage(userId: string, language: LanguageCode): Observable<void> {
        const payload = {
            languageTag: LOCALE_MAPPING[language],
        };

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateProfileLanguage, { userId }), payload)
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotUpdateProfileLanguage }))));
    }

    public resetUserPassword(userId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.resetUserPassword, { userId }), {})
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotResetUserPassword }))));
    }

    public requestAvatarChange(userId: string, base64Image: string): Observable<void> {
        return this.httpClient
            .get(base64Image, { responseType: "blob" })
            .pipe(switchMap((response: Blob) => this.saveUserAvatar(userId, response)));
    }

    public deleteUserAvatar(userId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.manageProfileAvatar, { userId }))
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotDeleteUserAvatar }))));
    }

    private saveUserAvatar(userId: string, blob: Blob): Observable<void> {
        const formData: FormData = new FormData();
        formData.append("file", blob);

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.manageProfileAvatar, { userId }), formData)
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotSaveUserAvatar }))));
    }
}
