import { InjectionToken } from "@angular/core";

export interface CentersEndpoints {
    addAndGetExaminationCenter: string;
    editAndDeleteExaminationCenter: string;
    getCapabilities: string;
    getOperatorsList: string;
    getOperatorDetails: string;
    manageTrainingCenters: string;
    manageTrainingCenter: string;
}

export const CENTERS_ENDPOINTS = new InjectionToken<CentersEndpoints>("Centers endpoints");
