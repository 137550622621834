<div class="dialog-header">
    <h4 mat-dialog-title>
        {{ "dtmAdminLibShared.operatorProfile.qualifications.addNewQualificationHeader" | transloco }}
    </h4>
    <button type="button" class="button-icon" [matDialogClose]="null"><dtm-ui-icon name="close"></dtm-ui-icon></button>
</div>
<form [formGroup]="addQualificationForm" (submit)="validateAndSubmit()" dtmUiInvalidFormScrollable>
    <div mat-dialog-content>
        <dtm-ui-select-field
            [formControl]="operationScenarioFormControl"
            [isClearable]="false"
            [multiple]="true"
            (valueChange)="assignMaxDateFromSelectedValues($event)"
        >
            <label>{{ "dtmAdminLibShared.operatorProfile.qualifications.nameLabel" | transloco }}</label>
            <dtm-ui-select-option
                *ngFor="let availableOperationScenario of availableOperationScenarios"
                [value]="availableOperationScenario"
                >{{ availableOperationScenario.name }}
            </dtm-ui-select-option>
            <div class="field-error" *dtmUiFieldHasError="operationScenarioFormControl; name: 'required'">
                {{ "dtmAdminLibShared.operatorProfile.qualifications.requiredFieldError" | transloco }}
            </div>
        </dtm-ui-select-field>

        <dtm-ui-date-field>
            <label>{{ "dtmAdminLibShared.operatorProfile.qualifications.expirationDateLabel" | transloco }}</label>
            <input
                matInput
                [formControl]="expirationDateFormControl"
                [matDatepicker]="picker"
                *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                [placeholder]="datePickerPlaceholder"
            />
            <mat-datepicker #picker></mat-datepicker>
            <div class="field-error" *dtmUiFieldHasError="expirationDateFormControl; name: 'required'">
                {{ "dtmAdminLibShared.operatorProfile.qualifications.requiredFieldError" | transloco }}
            </div>
        </dtm-ui-date-field>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="null">
            {{ "dtmAdminLibShared.operatorProfile.qualifications.cancelLabel" | transloco }}
        </button>
        <button type="submit" class="button-primary">
            {{ "dtmAdminLibShared.operatorProfile.qualifications.addLabel" | transloco }}
        </button>
    </div>
</form>
