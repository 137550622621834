import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ConversationCategoryCode, ImageConverterService, OperatorsThread, ReceivedMessage } from "@dtm-frontend/shared/ui";
import { SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, switchMap, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { OperatorErrorType } from "../../../shared";
import { CONVERSATION_ENDPOINTS, ConversationEndpoints } from "../../conversation.tokens";
import {
    AddNewMessage,
    ConversationCapabilities,
    ConversationCapabilitiesErrorType,
    ConversationThreadsFilterParams,
    NewThread,
    NewThreadAssignment,
    OperatorCapabilities,
    ThreadAssignmentErrorType,
    ThreadsErrorType,
    ThreadsWithPages,
} from "../conversation.models";
import {
    AddNewMessageToThreadRequestPayload,
    GetConversationCapabilitiesResponseBody,
    GetMessagesByThreadResponseBody,
    GetOperatorCapabilitiesResponseBody,
    GetThreadsResponseBody,
    NewThreadRequestPayload,
    convertConversationCapabilitiesResponseBodyToConversationCapabilities,
    convertGetMessagesByThreadResponseBodyToReceivedMessages,
    convertGetMessagesErrorResponseToMessagesError,
    convertGetOperatorCapabilitiesResponseBodyToOperatorCapabilities,
    convertGetThreadsResponseBodyToOperatorThreads,
    convertGetThreadsResponseBodyToThreads,
    convertNewMessageToAddNewMessageToThreadRequestPayload,
    convertNewThreadToNewThreadRequestPayload,
    getOperatorCapabilitiesParams,
    getThreadsParams,
} from "./conversation-api.converters";

@Injectable({
    providedIn: "root",
})
export class ConversationApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly imageConverterService: ImageConverterService,
        @Inject(CONVERSATION_ENDPOINTS) private readonly endpoints: ConversationEndpoints
    ) {}

    public createNewThread(message: NewThread) {
        const body: NewThreadRequestPayload = convertNewThreadToNewThreadRequestPayload(message);

        return this.httpClient.post<NewThreadRequestPayload>(this.endpoints.getOrAddThreads, body);
    }

    public addNewMessageToThread(message: AddNewMessage) {
        const body: AddNewMessageToThreadRequestPayload = convertNewMessageToAddNewMessageToThreadRequestPayload(message);

        return this.httpClient.post<AddNewMessageToThreadRequestPayload>(
            StringUtils.replaceInTemplate(this.endpoints.getOrAddMessages, { threadId: message.threadId }),
            body
        );
    }

    public changeThread(threadId: string, payload: { isClosed: boolean; isRead: boolean }) {
        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.manageThread, { threadId }), {
                closed: payload.isClosed,
                read: payload.isRead,
            })
            .pipe(catchError(() => throwError({ type: ThreadsErrorType.Unknown })));
    }

    public assignThread(assignment: NewThreadAssignment) {
        if (assignment.assignee) {
            return this.httpClient
                .put(
                    StringUtils.replaceInTemplate(this.endpoints.changeThreadAssignment, { threadId: assignment.thread }),
                    assignment.assignee
                )
                .pipe(catchError(() => throwError({ type: ThreadAssignmentErrorType.Unknown })));
        }

        return this.httpClient
            .delete(StringUtils.replaceInTemplate(this.endpoints.changeThreadAssignment, { threadId: assignment.thread }))
            .pipe(catchError(() => throwError({ type: ThreadAssignmentErrorType.Unknown })));
    }

    public getCapabilities(): Observable<ConversationCapabilities> {
        return this.httpClient.get<GetConversationCapabilitiesResponseBody>(this.endpoints.getCapabilities).pipe(
            map((response) => convertConversationCapabilitiesResponseBodyToConversationCapabilities(response)),
            catchError(() => throwError({ type: ConversationCapabilitiesErrorType.Unknown }))
        );
    }

    public getThreads(passedParams: ConversationThreadsFilterParams): Observable<ThreadsWithPages> {
        const params: HttpParams = getThreadsParams(passedParams);

        return this.httpClient.get<GetThreadsResponseBody>(this.endpoints.getOrAddThreads, { params }).pipe(
            map((result) => convertGetThreadsResponseBodyToThreads(result)),
            catchError(() => throwError(() => ({ type: ThreadsErrorType.Unknown })))
        );
    }

    public getOperatorThreads(passedParams: ConversationThreadsFilterParams): Observable<OperatorsThread[]> {
        // NOTE workaround for pagination. Remove after be task (REJ-503) is implemented
        const maxThreadSize = 2000;
        passedParams = { ...passedParams, pageSize: maxThreadSize, page: 0 };
        const params: HttpParams = getThreadsParams(passedParams);

        return this.httpClient.get<GetThreadsResponseBody>(this.endpoints.getOrAddThreads, { params }).pipe(
            map((result) => convertGetThreadsResponseBodyToOperatorThreads(result)),
            catchError(() => throwError(() => ({ type: ThreadsErrorType.Unknown })))
        );
    }

    public getOperatorCapabilities(operatorId: string, searchPhrase?: string): Observable<OperatorCapabilities> {
        const params: HttpParams | null = getOperatorCapabilitiesParams(operatorId, searchPhrase);

        return this.httpClient
            .get<GetOperatorCapabilitiesResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getOperatorCapabilities, { operatorId }),
                { params: params ?? {} }
            )
            .pipe(
                map((response) => convertGetOperatorCapabilitiesResponseBodyToOperatorCapabilities(response)),
                catchError(() => throwError({ type: OperatorErrorType.Unknown }))
            );
    }

    public getMessagesByThread(threadId: string): Observable<ReceivedMessage[]> {
        return this.httpClient
            .get<GetMessagesByThreadResponseBody[]>(StringUtils.replaceInTemplate(this.endpoints.getOrAddMessages, { threadId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((messages) => convertGetMessagesByThreadResponseBodyToReceivedMessages(messages)),
                catchError((error) => throwError(() => convertGetMessagesErrorResponseToMessagesError(error)))
            );
    }

    public getAvatarPicture(sourceUrl: string): Observable<string> {
        return this.httpClient
            .get(sourceUrl, {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true),
            })
            .pipe(switchMap((response) => this.imageConverterService.convertBlobToBase64(response)));
    }

    public changeCategory(category: ConversationCategoryCode, threadId: string) {
        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.changeThreadCategory, { threadId, categoryCode: category }), {})
            .pipe(catchError(() => throwError(() => ({ type: ThreadsErrorType.Unknown }))));
    }
}
