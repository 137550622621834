import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { NULL_VALUE, SINGLE_SELECT_VALUES } from "../../../shared";

interface SingleSelectFilterComponentState {
    label: string | undefined;
    trueOptionLabel: string | undefined;
    falseOptionLabel: string | undefined;
    allOptionLabel: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-single-select-filter[label][trueOptionLabel][falseOptionLabel][singleSelectFormControl]",
    templateUrl: "./single-select-filter.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SingleSelectFilterComponent {
    @Input() public set label(value: string) {
        this.localStore.patchState({ label: value });
    }

    @Input() public set trueOptionLabel(value: string) {
        this.localStore.patchState({ trueOptionLabel: value });
    }

    @Input() public set falseOptionLabel(value: string) {
        this.localStore.patchState({ falseOptionLabel: value });
    }

    @Input() public singleSelectFormControl!: UntypedFormControl;

    @Input() public set allOptionLabel(value: string) {
        this.localStore.patchState({ allOptionLabel: value });
    }

    public readonly label$ = this.localStore.selectByKey("label").pipe(RxjsUtils.filterFalsy());
    public readonly trueOptionLabel$ = this.localStore.selectByKey("trueOptionLabel").pipe(RxjsUtils.filterFalsy());
    public readonly falseOptionLabel$ = this.localStore.selectByKey("falseOptionLabel").pipe(RxjsUtils.filterFalsy());
    public readonly allOptionLabel$ = this.localStore.selectByKey("allOptionLabel").pipe(RxjsUtils.filterFalsy());
    public readonly NULL_VALUE = NULL_VALUE;
    public readonly SINGLE_SELECT_VALUES = SINGLE_SELECT_VALUES;

    constructor(private readonly localStore: LocalComponentStore<SingleSelectFilterComponentState>) {
        this.localStore.setState({
            label: undefined,
            trueOptionLabel: undefined,
            falseOptionLabel: undefined,
            allOptionLabel: undefined,
        });
    }
}
