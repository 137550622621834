import { ClipboardModule } from "@angular/cdk/clipboard";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { SharedNotificationsModule } from "@dtm-frontend/shared/notifications";
import { FILES_UPLOAD_API_PROVIDER, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NgxMaskModule } from "ngx-mask";
import { AddOrEditOtherInformationDialogComponent } from "./components/add-or-edit-other-information-dialog/add-or-edit-other-information-dialog.component";
import { DialogWithReasonComponent } from "./components/dialog-with-reason/dialog-with-reason.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { AddOperationalAuthorizationComponent } from "./components/operator-profile/add-operational-authorization/add-operational-authorization.component";
import { AddQualificationComponent } from "./components/operator-profile/add-qualification/add-qualification.component";
import { AdministrativeFeeTileComponent } from "./components/operator-profile/administrative-fee-tile/administrative-fee-tile.component";
import { AdministratorListComponent } from "./components/operator-profile/administrator-list/administrator-list.component";
import { AttorneyPowerUpdateDialogComponent } from "./components/operator-profile/attorney-power-update-dialog/attorney-power-update-dialog.component";
import { AttorneyPowersTileComponent } from "./components/operator-profile/attorney-powers-tile/attorney-powers-tile.component";
import { ChangeLogTileComponent } from "./components/operator-profile/change-log-tile/change-log-tile.component";
import { ForeignCompetenciesComponent } from "./components/operator-profile/foreign-competencies/foreign-competencies.component";
import { InsurancePolicyTileComponent } from "./components/operator-profile/insurance-policy-tile/insurance-policy-tile.component";
import { LegalGuardianDetailsEditionComponent } from "./components/operator-profile/legal-guardian-details-edition/legal-guardian-details-edition.component";
import { LegalGuardianTileComponent } from "./components/operator-profile/legal-guardian-tile/legal-guardian-tile.component";
import { MessagesTileComponent } from "./components/operator-profile/messages-tile/messages-tile.component";
import { OperatorDetailsTileComponent } from "./components/operator-profile/operator-details-tile/operator-details-tile.component";
import { OperatorStatusComponent } from "./components/operator-profile/operator-status/operator-status.component";
import { OtherInformationTileComponent } from "./components/operator-profile/other-information-tile/other-information-tile.component";
import { OwnerTileComponent } from "./components/operator-profile/owner-tile/owner-tile.component";
import { ProfileDetailsComponent } from "./components/operator-profile/profile-details/profile-details.component";
import { ProfileTopComponent } from "./components/operator-profile/profile-top/profile-top.component";
import { QualificationsComponent } from "./components/operator-profile/qualifications/qualifications.component";
import { OperatorSearchAutocompleteControlComponent } from "./components/operator-search-autocomplete-control/operator-search-autocomplete-control.component";
import { SelectOperatorHintComponent } from "./components/select-operator-hint/select-operator-hint.component";
import { SingleSelectFilterComponent } from "./components/single-select-filter/single-select-filter.component";
import { GlobalCapabilitiesApiService } from "./global-capabilities/services/global-capabilities-api.service";
import { GlobalCapabilitiesState } from "./global-capabilities/store/global-capabilities.store";

const declarations = [
    SelectOperatorHintComponent,
    NotificationsComponent,
    DialogWithReasonComponent,
    SingleSelectFilterComponent,
    MessagesTileComponent,
    ProfileTopComponent,
    OperatorStatusComponent,
    ProfileDetailsComponent,
    QualificationsComponent,
    OwnerTileComponent,
    OperatorDetailsTileComponent,
    AddQualificationComponent,
    LegalGuardianTileComponent,
    LegalGuardianDetailsEditionComponent,
    OperatorSearchAutocompleteControlComponent,
    AddOperationalAuthorizationComponent,
    InsurancePolicyTileComponent,
    AttorneyPowersTileComponent,
    AttorneyPowerUpdateDialogComponent,
    AdministrativeFeeTileComponent,
    ChangeLogTileComponent,
    OtherInformationTileComponent,
    AddOrEditOtherInformationDialogComponent,
    ForeignCompetenciesComponent,
    AdministratorListComponent,
];

@NgModule({
    declarations,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedI18nModule,
        SharedUiModule,
        MatTableModule,
        MatSidenavModule,
        MatExpansionModule,
        MatTooltipModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        RouterModule,
        SharedNotificationsModule,
        MatProgressSpinnerModule,
        ScrollingModule,
        ClipboardModule,
        MatMenuModule,
        NgxsModule.forFeature([GlobalCapabilitiesState]),
        NgxMaskModule,
        MatAutocompleteModule,
        MatChipsModule,
    ],
    exports: declarations,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: GlobalCapabilitiesApiService,
            useValue: undefined,
        },
    ],
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: GlobalCapabilitiesApiService,
                    useClass: GlobalCapabilitiesApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: GlobalCapabilitiesApiService,
                    useValue: null,
                },
                {
                    provide: FILES_UPLOAD_API_PROVIDER,
                    useValue: null,
                },
            ],
        };
    }
}
