import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { ContactPerson, PhoneNumber, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import { FunctionUtils, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged } from "rxjs/operators";

interface ContactPersonForm {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    position: FormControl<string | null>;
    email: FormControl<string>;
    phoneNumber: FormControl<PhoneNumber | null>;
}

const MAX_CONTACT_FIELD_LENGTH = 100;

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operator-contact-person-control",
    templateUrl: "./operator-contact-person-control.component.html",
    styleUrls: ["./operator-contact-person-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OperatorContactPersonControlComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OperatorContactPersonControlComponent),
            multi: true,
        },
    ],
})
export class OperatorContactPersonControlComponent implements ControlValueAccessor, Validators {
    @Input() public set isPositionFieldAvailable(value: BooleanInput) {
        if (coerceBooleanProperty(value)) {
            this.operatorContactPersonForm.controls.position?.enable();

            return;
        }
        this.operatorContactPersonForm.controls.position?.disable();
    }

    protected readonly operatorContactPersonForm = new FormGroup<ContactPersonForm>({
        firstName: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_CONTACT_FIELD_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        lastName: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_CONTACT_FIELD_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        position: new FormControl(
            { value: null, disabled: true },
            {
                validators: [
                    Validators.required,
                    Validators.maxLength(MAX_CONTACT_FIELD_LENGTH),
                    Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                ],
            }
        ),
        email: new FormControl("", {
            validators: [Validators.email, Validators.required, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
            nonNullable: true,
        }),
        phoneNumber: new FormControl(null, {
            validators: requiredValidForSmsPhoneNumberValidator,
        }),
    });

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: ContactPerson) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor() {
        this.operatorContactPersonForm.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.operatorContactPersonForm.getRawValue() as ContactPerson);
            this.propagateTouch();
        });
    }

    public registerOnChange(fn: (value: ContactPerson) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        return this.operatorContactPersonForm.invalid ? { invalidContactPerson: true } : null;
    }

    public writeValue(value: ContactPerson | undefined): void {
        if (value) {
            this.operatorContactPersonForm.reset(value, { emitEvent: false });
        } else {
            this.operatorContactPersonForm.reset();
        }
    }
}
