import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";
import { CentersListData, CentersTabType } from "../../models/centers.models";
import { ExaminationCentersError } from "../../models/examination-centers.models";
import { TrainingCentersError } from "../../models/training-centers.models";

interface CentersListComponentState {
    centersList: CentersListData[];
    isCentersProcessing: boolean;
    centersListError: ExaminationCentersError | TrainingCentersError | undefined;
    pagination: Page | undefined;
    expandedElement: CentersListData | undefined;
    activeTabIndex: CentersTabType | undefined;
    displayedColumns: string[];
}

@Component({
    selector: "dtm-admin-lib-centers-list[centersList][pagination]",
    templateUrl: "./centers-list.component.html",
    styleUrls: ["./centers-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class CentersListComponent implements OnInit {
    @Input() public set centersList(value: CentersListData[] | undefined) {
        this.localStore.patchState({ centersList: value ?? [] });
    }
    @Input() public set isCentersProcessing(value: BooleanInput) {
        this.localStore.patchState({ isCentersProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set centersListError(value: ExaminationCentersError | TrainingCentersError | undefined) {
        this.localStore.patchState({ centersListError: value });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }
    @Input() public set activeTabIndex(value: CentersTabType | undefined) {
        this.localStore.patchState({ activeTabIndex: value });
        this.localStore.patchState({ displayedColumns: this.getDisplayedColumns(value) });
    }

    @Output() public readonly centersListRefresh = new EventEmitter<void>();
    @Output() public readonly pageChange = new EventEmitter<PageEvent>();
    @Output() public readonly edit = new EventEmitter<CentersListData>();
    @Output() public readonly preview = new EventEmitter<CentersListData>();
    @Output() public readonly delete = new EventEmitter<string>();

    protected readonly centersList$ = this.localStore.selectByKey("centersList");
    protected readonly isCentersProcessing$ = this.localStore.selectByKey("isCentersProcessing");
    protected readonly centersListError$ = this.localStore.selectByKey("centersListError");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly activeTabIndex$ = this.localStore.selectByKey("activeTabIndex");
    protected readonly displayedColumns$ = this.localStore.selectByKey("displayedColumns");

    protected readonly CentersTabType = CentersTabType;
    protected readonly ErrorMode = ErrorMode;

    constructor(
        private readonly localStore: LocalComponentStore<CentersListComponentState>,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            centersList: [],
            isCentersProcessing: false,
            centersListError: undefined,
            pagination: undefined,
            expandedElement: undefined,
            activeTabIndex: undefined,
            displayedColumns: [],
        });
    }

    public ngOnInit(): void {
        const activeTabIndex = this.localStore.selectSnapshotByKey("activeTabIndex");
        this.localStore.patchState({ displayedColumns: this.getDisplayedColumns(activeTabIndex) });
    }

    protected toggleExpandableRow(row: CentersListData) {
        this.localStore.patchState(({ expandedElement }) => ({
            expandedElement: expandedElement === row ? undefined : row,
        }));
    }

    protected handleValueCopy(isSuccess: boolean) {
        if (isSuccess) {
            this.toastService.success(this.transloco.translate("dtmUi.copyToClipboard.successMessage"));
        } else {
            this.toastService.error(this.transloco.translate("dtmUi.copyToClipboard.errorMessage"));
        }
    }

    private getDisplayedColumns(activeTabIndex: CentersTabType | undefined): string[] {
        return activeTabIndex === CentersTabType.TrainingOperators
            ? ["expandHandle", "name", "identificationNumber", "actions"]
            : ["expandHandle", "name", "identificationNumber", "email", "phoneNumber", "actions"];
    }
}
