<ng-container *ngrxLet="badges$ | ngrxPush as badges">
    <div *ngIf="badges?.length; else noCodesDataTemplate" class="trainings-container">
        <div *ngFor="let authorization of badges" class="training">
            <ng-container [ngSwitch]="authorization.category">
                <dtm-ui-icon *ngSwitchCase="OperationScenarioCategory.Open" name="shield" class="shield"></dtm-ui-icon>
                <dtm-ui-icon *ngSwitchDefault name="shield-cross" class="shield"></dtm-ui-icon>
            </ng-container>
            <span>{{ authorization.name }}</span>
        </div>
    </div>
</ng-container>

<ng-template #noCodesDataTemplate>-</ng-template>
