import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AdminUsersApiService } from "../services/admin-users-api.service";
import { AdminUser, AdminUsersError } from "../services/models";
import { AdminUsersActions } from "./admin-users.actions";

interface AdminUsersStateModel {
    usersList: AdminUser[] | undefined;
    pages: Page | undefined;
    isUsersListProcessing: boolean;
    usersListError: AdminUsersError | undefined;
    isUserProcessing: boolean;
    userError: AdminUsersError | undefined;
}

const defaultState: AdminUsersStateModel = {
    usersList: undefined,
    pages: undefined,
    isUsersListProcessing: false,
    usersListError: undefined,
    isUserProcessing: false,
    userError: undefined,
};

@State<AdminUsersStateModel>({
    name: "adminUsers",
    defaults: defaultState,
})
@Injectable()
export class AdminUsersState {
    @Selector()
    public static usersList(state: AdminUsersStateModel): AdminUser[] | undefined {
        return state.usersList;
    }

    @Selector()
    public static isUsersListProcessing(state: AdminUsersStateModel): boolean {
        return state.isUsersListProcessing;
    }

    @Selector()
    public static isUserProcessing(state: AdminUsersStateModel): boolean {
        return state.isUserProcessing;
    }

    @Selector()
    public static userError(state: AdminUsersStateModel): AdminUsersError | undefined {
        return state.userError;
    }

    @Selector()
    public static usersListError(state: AdminUsersStateModel): AdminUsersError | undefined {
        return state.usersListError;
    }

    @Selector()
    public static pages(state: AdminUsersStateModel): Page | undefined {
        return state.pages;
    }

    constructor(private readonly adminUsersApiService: AdminUsersApiService) {
        if (adminUsersApiService === undefined) {
            throw new Error("Initialize UsersModule with .forRoot()");
        }
    }

    @Action(AdminUsersActions.GetUsers)
    public getUsers(context: StateContext<AdminUsersStateModel>, action: AdminUsersActions.GetUsers) {
        context.patchState({ isUsersListProcessing: true });

        return this.adminUsersApiService.getUsers(action.payload).pipe(
            tap((result) =>
                context.patchState({
                    usersList: result.content,
                    pages: {
                        pageSize: result.size,
                        pageNumber: result.number,
                        totalElements: result.totalElements,
                    },
                    usersListError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({
                    usersListError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUsersListProcessing: false }))
        );
    }

    @Action(AdminUsersActions.AddUser)
    public addUser(context: StateContext<AdminUsersStateModel>, action: AdminUsersActions.AddUser) {
        context.patchState({ isUserProcessing: true, userError: undefined });

        return this.adminUsersApiService.addUser(action.payload).pipe(
            catchError((error) => {
                context.patchState({
                    userError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUserProcessing: false }))
        );
    }

    @Action(AdminUsersActions.EditUser)
    public editUser(context: StateContext<AdminUsersStateModel>, action: AdminUsersActions.EditUser) {
        context.patchState({ isUserProcessing: true, userError: undefined });

        return this.adminUsersApiService.editUser(action.payload, action.adminUserId).pipe(
            catchError((error) => {
                context.patchState({
                    userError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUserProcessing: false }))
        );
    }

    @Action(AdminUsersActions.DeleteUser)
    public deleteUser(context: StateContext<AdminUsersStateModel>, action: AdminUsersActions.DeleteUser) {
        context.patchState({ isUserProcessing: true, userError: undefined });

        return this.adminUsersApiService.deleteUser(action.adminUserId).pipe(
            catchError((error) => {
                context.patchState({
                    userError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUserProcessing: false }))
        );
    }

    @Action(AdminUsersActions.ResetUsersPassword)
    public resetUsersPassword(context: StateContext<AdminUsersStateModel>, action: AdminUsersActions.ResetUsersPassword) {
        context.patchState({ isUserProcessing: true, userError: undefined });

        return this.adminUsersApiService.resetUsersPassword(action.adminUserId).pipe(
            catchError((error) => {
                context.patchState({
                    userError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUserProcessing: false }))
        );
    }
}
