import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "tin",
})
export class TinPipe implements PipeTransform {
    public transform(value: string | undefined | null): string | undefined {
        if (!value) {
            return undefined;
        }
        const onlyDigits = value.replace(/\D/g, "");
        const match = onlyDigits.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (!match) {
            return;
        }

        // eslint-disable-next-line no-magic-numbers
        return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
    }
}
