<div class="form-wrapper" [formGroup]="finalRiskAreaForm">
    <dtm-ui-select-field formControlName="operationArea" [placeholder]="'dtmAdminLibPermits.finalRiskClass.chooseLabel' | transloco">
        <label>{{ "dtmAdminLibPermits.finalRiskClass.groundAreaCharacteristicLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="arcType" *ngFor="let arcType of operationArcTypes">
            {{ arcType | arcLabel }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="finalRiskAreaForm.controls.operationArea; name: 'required'"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>

    <dtm-ui-input-field [isClearable]="false">
        <label>{{ "dtmAdminLibPermits.finalRiskClass.operationAreaHeightLabel" | transloco }}</label>
        <input matInput type="number" formControlName="operationalVolumeHeightLimit" />
        <div class="field-suffix">{{ "dtmAdminLibPermits.finalRiskClass.operationAreaInputSuffix" | transloco }}</div>
        <div
            class="field-error"
            *dtmUiFieldHasError="finalRiskAreaForm.controls.operationalVolumeHeightLimit; name: 'required'"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
        <div class="field-error" *dtmUiFieldHasError="finalRiskAreaForm.controls.operationalVolumeHeightLimit; name: 'max'">
            {{
                "dtmAdminLibPermits.finalRiskClass.maxValueErrorHint"
                    | transloco
                        : {
                              max: MAX_AREA_HEIGHT_VALUE,
                              unit: "dtmAdminLibPermits.finalRiskClass.operationAreaInputSuffix" | transloco
                          }
            }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="finalRiskAreaForm.controls.operationalVolumeHeightLimit; name: 'min'">
            {{
                "dtmAdminLibPermits.finalRiskClass.minValueErrorHint"
                    | transloco
                        : {
                              min: MIN_AREA_HEIGHT_VALUE,
                              unit: "dtmAdminLibPermits.finalRiskClass.operationAreaInputSuffix" | transloco
                          }
            }}
        </div>
    </dtm-ui-input-field>

    <dtm-ui-select-field
        formControlName="operationalAirSpaces"
        [placeholder]="'dtmAdminLibPermits.finalRiskClass.chooseLabel' | transloco"
        [multiple]="true"
    >
        <label>{{ "dtmAdminLibPermits.finalRiskClass.operationalAirSpaceClassLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="space.value" *ngFor="let space of OperationalAirspaceClassType | keyvalue">
            {{ "dtmAdminLibPermits.finalRiskClass.operationalAirSpaceClassSelectValue" | transloco : { value: space.value } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="finalRiskAreaForm.controls.operationalAirSpaces; name: 'required'"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-input-field *ngIf="finalRiskAreaForm.controls.otherAirspace.enabled" @slideIn>
        <label>{{ "dtmAdminLibPermits.finalRiskClass.otherSpaceNameLabel" | transloco }}</label>
        <input formControlName="otherAirspace" matInput type="text" />
        <div
            class="field-error"
            *dtmUiFieldHasError="finalRiskAreaForm.controls.otherAirspace; name: ['required', 'pattern']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
        <div class="field-error" *dtmUiFieldHasError="finalRiskAreaForm.controls.otherAirspace; name: 'maxlength'; error as error">
            {{ "dtmAdminLibPermits.finalRiskClass.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-select-field formControlName="adjacentArea" [placeholder]="'dtmAdminLibPermits.finalRiskClass.chooseLabel' | transloco">
        <label>{{ "dtmAdminLibPermits.finalRiskClass.adjacentGroundAreaLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="arcType" *ngFor="let arcType of operationArcTypes">
            {{ arcType | arcLabel }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="finalRiskAreaForm.controls.adjacentArea; name: 'required'"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
</div>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibPermits.container.requiredField" | transloco }}
</ng-template>
