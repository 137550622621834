import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Subject } from "rxjs";
import { PermissionsToGrant } from "../../../models/operator.models";

interface AttorneyPowerUpdateDialogComponentState {
    areInitialPermissionsSelected: boolean;
}

@Component({
    selector: "dtm-admin-lib-attorney-power-update-dialog",
    templateUrl: "./attorney-power-update-dialog.component.html",
    styleUrls: ["./attorney-power-update-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AttorneyPowerUpdateDialogComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;
    protected readonly permissionsControl = new FormControl<PermissionsToGrant[]>([], {
        validators: Validators.required,
        nonNullable: true,
    });
    protected readonly form = new FormGroup({
        permissions: this.permissionsControl,
    });
    protected readonly areInitialPermissionsSelected$ = this.localStore.selectByKey("areInitialPermissionsSelected");
    private readonly newValueSubject = new Subject<PermissionsToGrant[]>();
    public readonly newValue$ = this.newValueSubject.asObservable();

    constructor(
        private readonly localStore: LocalComponentStore<AttorneyPowerUpdateDialogComponentState>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            availablePermissions: PermissionsToGrant[];
            permissions: PermissionsToGrant[];
        }
    ) {
        if (this.data.permissions?.length) {
            this.permissionsControl.patchValue(this.data.permissions);
            this.localStore.setState({ areInitialPermissionsSelected: true });
        } else {
            this.localStore.setState({ areInitialPermissionsSelected: false });
        }
    }

    protected removePermission(permission: PermissionsToGrant): void {
        this.permissionsControl.setValue(this.permissionsControl.value.filter((value) => value !== permission));
    }

    protected validateAndSubmit() {
        this.form.markAllAsTouched();

        if (this.form.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.newValueSubject.next(this.permissionsControl.value);
    }
}
