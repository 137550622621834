import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { PermitType } from "@dtm-frontend/shared/ui";
import { AssociationPermitDetailsComponent } from "./components/association-permit-details/association-permit-details.component";
import { AssociationPermitRequestHeaderComponent } from "./components/association-permit-request-wizard/association-permit-request-header/association-permit-request-header.component";
import { AssociationPermitRequestWizardContentComponent } from "./components/association-permit-request-wizard/association-permit-request-wizard-content/association-permit-request-wizard-content.component";
import { CrossBorderPermitRequestHeaderComponent } from "./components/cross-border-permit-request-wizard/cross-border-permit-request-header/cross-border-permit-request-header.component";
import { CrossBorderPermitRequestWizardComponent } from "./components/cross-border-permit-request-wizard/cross-border-permit-request-wizard.component";
import { PermitsContainerComponent } from "./components/permits-container/permits-container.component";
import { SpecificPermitDetailsComponent } from "./components/specific-permit-details/specific-permit-details.component";
import { SpecificPermitFlightRequestContentComponent } from "./components/specific-permit-flight-wizard-request/specific-permit-flight-request-content/specific-permit-flight-request-content.component";
import { SpecificPermitFlightRequestHeaderComponent } from "./components/specific-permit-flight-wizard-request/specific-permit-flight-request-header/specific-permit-flight-request-header.component";
import {
    AssociationPermitDetailsResolver,
    AssociationPermitWizardResolver,
    CrossBorderPermitDetailsResolver,
    CrossBorderPermitWizardResolver,
    SpecificPermitDetailsResolver,
    SpecificPermitWizardResolver,
} from "./services/permits.resolver";

const routes: Routes = [
    {
        path: "permits",
        component: PermitsContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.permits",
        },
    },
    {
        path: "specific-permit-flight-request",
        component: SpecificPermitFlightRequestContentComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: SpecificPermitWizardResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.specificPermitFlight",
            headerComponent: SpecificPermitFlightRequestHeaderComponent,
        },
    },
    {
        path: "specific-permit-flight-edit/:id",
        component: SpecificPermitFlightRequestContentComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: SpecificPermitDetailsResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.specificPermitFlight",
            headerComponent: SpecificPermitFlightRequestHeaderComponent,
        },
    },
    {
        path: "association-permit-flight-request",
        component: AssociationPermitRequestWizardContentComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: AssociationPermitWizardResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.associationPermitRequest",
            headerComponent: AssociationPermitRequestHeaderComponent,
        },
    },
    {
        path: "cross-border-permit-flight-request",
        component: CrossBorderPermitRequestWizardComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: CrossBorderPermitWizardResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.crossBorderPermitRequest",
            headerComponent: CrossBorderPermitRequestHeaderComponent,
        },
    },
    {
        path: "association-permit-flight-edit/:id",
        component: AssociationPermitRequestWizardContentComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: AssociationPermitDetailsResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.associationPermitRequest",
            headerComponent: AssociationPermitRequestHeaderComponent,
        },
    },
    {
        path: "cross-border-permit-flight-edit/:id",
        component: CrossBorderPermitRequestWizardComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: CrossBorderPermitDetailsResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.crossBorderPermitRequest",
            headerComponent: CrossBorderPermitRequestHeaderComponent,
        },
    },
    {
        path: "specific-permit-details/:id",
        component: SpecificPermitDetailsComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: SpecificPermitDetailsResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.specificPermitFlight",
            permitType: PermitType.OperationPermission,
        },
    },
    {
        path: "association-permit-details/:id",
        component: AssociationPermitDetailsComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: AssociationPermitDetailsResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.specificPermitFlight",
        },
    },
    {
        path: "cross-border-permit-details/:id",
        component: SpecificPermitDetailsComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: CrossBorderPermitDetailsResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibPermits.routeTitles.specificPermitFlight",
            permitType: PermitType.CrossBorder,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        SpecificPermitWizardResolver,
        SpecificPermitDetailsResolver,
        AssociationPermitWizardResolver,
        AssociationPermitDetailsResolver,
        CrossBorderPermitDetailsResolver,
        CrossBorderPermitWizardResolver,
    ],
})
export class PermitsRoutingModule {}
