import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { UtmUsersApiService } from "../services/utm-users.api.service";
import { UtmUser, UtmUserDetails, UtmUserError, UtmUserListWithPages } from "../services/utm-users.models";
import { UtmUsersActions } from "./utm-users.actions";

interface UtmUsersStateModel {
    isUserListProcessing: boolean;
    page: Page | undefined;
    userList: UtmUser[];
    selectedUser: UtmUserDetails | undefined;
    isUserProfileProcessing: boolean;
    userListError: UtmUserError | undefined;
    userError: UtmUserError | undefined;
    updateUserBasicDataError: UtmUserError | undefined;
    downloadDocumentError: UtmUserError | undefined;
    updateIdentityDocumentStatusError: UtmUserError | undefined;
    updateLegalGuardianError: UtmUserError | undefined;
    deleteLegalGuardianError: UtmUserError | undefined;
    legalGuardianSignedDocumentError: UtmUserError | undefined;
    requestForDocumentUpdateError: UtmUserError | undefined;
    requestForLegalGuardianUpdateError: UtmUserError | undefined;
}

const defaultState: UtmUsersStateModel = {
    isUserListProcessing: false,
    page: undefined,
    userList: [],
    selectedUser: undefined,
    isUserProfileProcessing: false,
    userListError: undefined,
    userError: undefined,
    updateUserBasicDataError: undefined,
    downloadDocumentError: undefined,
    updateIdentityDocumentStatusError: undefined,
    updateLegalGuardianError: undefined,
    deleteLegalGuardianError: undefined,
    legalGuardianSignedDocumentError: undefined,
    requestForDocumentUpdateError: undefined,
    requestForLegalGuardianUpdateError: undefined,
};

@State<UtmUsersStateModel>({
    name: "utmUsers",
    defaults: defaultState,
})
@Injectable()
export class UtmUsersState {
    @Selector()
    public static isUserListProcessing(state: UtmUsersStateModel): boolean {
        return state.isUserListProcessing;
    }

    @Selector()
    public static isUserProfileProcessing(state: UtmUsersStateModel): boolean {
        return state.isUserProfileProcessing;
    }

    @Selector()
    public static page(state: UtmUsersStateModel): Page | undefined {
        return state.page;
    }

    @Selector()
    public static userList(state: UtmUsersStateModel): UtmUser[] {
        return state.userList;
    }

    @Selector()
    public static selectedUser(state: UtmUsersStateModel): UtmUserDetails | undefined {
        return state.selectedUser;
    }

    @Selector()
    public static userListError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.userListError;
    }

    @Selector()
    public static userError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.userError;
    }

    @Selector()
    public static updateUserBasicDataError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.updateUserBasicDataError;
    }

    @Selector()
    public static downloadDocumentError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.downloadDocumentError;
    }

    @Selector()
    public static updateIdentityDocumentStatusError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.updateIdentityDocumentStatusError;
    }

    @Selector()
    public static updateLegalGuardianError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.updateLegalGuardianError;
    }

    @Selector()
    public static deleteLegalGuardianError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.deleteLegalGuardianError;
    }

    @Selector()
    public static legalGuardianSignedDocumentError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.legalGuardianSignedDocumentError;
    }

    @Selector()
    public static requestForDocumentUpdateError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.requestForDocumentUpdateError;
    }

    @Selector()
    public static requestForLegalGuardianUpdateError(state: UtmUsersStateModel): UtmUserError | undefined {
        return state.requestForLegalGuardianUpdateError;
    }

    constructor(private readonly utmUsersApi: UtmUsersApiService) {}

    @Action(UtmUsersActions.GetUsers)
    public getUsers(context: StateContext<UtmUsersStateModel>, action: UtmUsersActions.GetUsers) {
        context.patchState({
            isUserListProcessing: true,
            userListError: undefined,
        });

        return this.utmUsersApi.getUsers(action.queryParams).pipe(
            tap((result: UtmUserListWithPages) => {
                const selectedUser = this.getSelectedUserFromList(result.content, context.getState().selectedUser);

                context.patchState({
                    page: result.page,
                    userList: result.content,
                    isUserListProcessing: false,
                    selectedUser,
                });
            }),
            catchError((error) => {
                context.patchState({
                    userListError: error,
                    userList: [],
                    selectedUser: undefined,
                    isUserListProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(UtmUsersActions.SelectUser)
    public selectUser(context: StateContext<UtmUsersStateModel>, action: UtmUsersActions.SelectUser) {
        context.patchState({
            isUserProfileProcessing: true,
            userError: undefined,
        });

        return this.utmUsersApi.getUserDetails(action.userId).pipe(
            tap((result: UtmUserDetails) => {
                context.patchState({
                    selectedUser: result,
                    isUserProfileProcessing: false,
                });
            }),
            catchError((userError) => {
                context.patchState({
                    userError,
                    selectedUser: undefined,
                    isUserProfileProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(UtmUsersActions.UpdateUser)
    public updateUser(context: StateContext<UtmUsersStateModel>, action: UtmUsersActions.UpdateUser) {
        context.patchState({
            isUserProfileProcessing: true,
            updateUserBasicDataError: undefined,
        });

        return this.utmUsersApi.updateUser(action.userId, action.userValues).pipe(
            tap(() => {
                context.patchState({
                    isUserProfileProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    updateUserBasicDataError: error,
                    isUserProfileProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(UtmUsersActions.DownloadIdentityDocument)
    public downloadIdentityDocument(context: StateContext<UtmUsersStateModel>, action: UtmUsersActions.DownloadIdentityDocument) {
        context.patchState({ downloadDocumentError: undefined });

        return this.utmUsersApi.downloadIdentityDocument(action.userId, action.documentId, action.fileName);
    }

    @Action(UtmUsersActions.UpdateIdentityDocumentStatus)
    public updateIdentityDocumentStatus(context: StateContext<UtmUsersStateModel>, action: UtmUsersActions.UpdateIdentityDocumentStatus) {
        context.patchState({
            isUserProfileProcessing: true,
            updateIdentityDocumentStatusError: undefined,
        });

        return this.utmUsersApi.updateIdentityDocumentStatus(action.userId, action.documentId, action.statusRequest).pipe(
            tap(() => {
                context.patchState({
                    isUserProfileProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isUserProfileProcessing: false,
                    updateIdentityDocumentStatusError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(UtmUsersActions.UpdateLegalGuardian)
    public updateLegalGuardian(context: StateContext<UtmUsersStateModel>, action: UtmUsersActions.UpdateLegalGuardian) {
        context.patchState({
            isUserProfileProcessing: true,
            updateLegalGuardianError: undefined,
        });

        return this.utmUsersApi.updateLegalGuardian(action.userId, action.legalGuardianDetails).pipe(
            tap(() =>
                context.patchState({
                    isUserProfileProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({
                    isUserProfileProcessing: false,
                    updateLegalGuardianError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(UtmUsersActions.DeleteLegalGuardian)
    public deleteLegalGuardian(context: StateContext<UtmUsersStateModel>, action: UtmUsersActions.DeleteLegalGuardian) {
        context.patchState({
            isUserProfileProcessing: true,
            deleteLegalGuardianError: undefined,
        });

        return this.utmUsersApi.deleteLegalGuardian(action.userId).pipe(
            tap(() =>
                context.patchState({
                    isUserProfileProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({
                    isUserProfileProcessing: false,
                    deleteLegalGuardianError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(UtmUsersActions.GetLegalGuardianSignedDocument)
    public getLegalGuardianSignedDocument(
        context: StateContext<UtmUsersStateModel>,
        action: UtmUsersActions.GetLegalGuardianSignedDocument
    ) {
        context.patchState({
            legalGuardianSignedDocumentError: undefined,
        });

        return this.utmUsersApi.getLegalGuardianSignedDocument(action.userId, action.fileName).pipe(
            catchError((error) => {
                context.patchState({
                    legalGuardianSignedDocumentError: error,
                });

                return EMPTY;
            })
        );
    }
    @Action(UtmUsersActions.RequestForDocumentUpdate)
    public requestForDocumentUpdate(context: StateContext<UtmUsersStateModel>, action: UtmUsersActions.RequestForDocumentUpdate) {
        context.patchState({
            isUserProfileProcessing: true,
            requestForDocumentUpdateError: undefined,
        });

        return this.utmUsersApi.requestForDocumentUpdate(action.userId).pipe(
            catchError((error) => {
                context.patchState({
                    requestForDocumentUpdateError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUserProfileProcessing: false }))
        );
    }

    @Action(UtmUsersActions.RequestLegalGuardianUpdate)
    public requestLegalGuardianUpdate(context: StateContext<UtmUsersStateModel>, action: UtmUsersActions.RequestLegalGuardianUpdate) {
        context.patchState({
            isUserProfileProcessing: true,
            requestForLegalGuardianUpdateError: undefined,
        });

        return this.utmUsersApi.requestForLegalGuardianUpdate(action.userId).pipe(
            catchError((error) => {
                context.patchState({
                    requestForLegalGuardianUpdateError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isUserProfileProcessing: false }))
        );
    }

    private getSelectedUserFromList(userList: UtmUser[], selectedUser: UtmUserDetails | undefined): UtmUserDetails | undefined {
        const isSelectedUserInList: boolean = userList.some((user) => user.id === selectedUser?.id);

        return isSelectedUserInList ? selectedUser : undefined;
    }
}
