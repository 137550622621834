import { Injectable } from "@angular/core";
import {
    ConversationCategoryCode,
    MessagesError,
    MessagesErrorType,
    OperatorsThread,
    ReceivedMessage,
    StoredAvatarPictures,
} from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, lastValueFrom } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Page } from "../../operator/services/operator.models";
import { OperatorDetails, OperatorError } from "../../shared";
import { ConversationApiService } from "../services/api/conversation-api.service";
import {
    ConversationAssignee,
    ConversationCapabilities,
    ConversationCapabilitiesError,
    OperatorCapabilities,
    Thread,
    ThreadAssignmentError,
    ThreadsError,
} from "../services/conversation.models";
import { ConversationActions } from "./conversation.actions";
import ClearConversationData = ConversationActions.ClearConversationData;

export interface ConversationStateModel {
    userAssignedCategories: ConversationCategoryCode[] | undefined;
    conversationCategories: ConversationCategoryCode[] | undefined;
    conversationAssignees: Map<string, ConversationAssignee> | undefined;
    conversationCapabilitiesError: ConversationCapabilitiesError | undefined;
    interlocutor: OperatorDetails | undefined;
    operatorCapabilitiesError: OperatorError | undefined;
    threads: Thread[] | undefined;
    isThreadsListLoading: boolean | undefined;
    isMessagesProcessing: boolean | undefined;
    threadsError: ThreadsError | undefined;
    threadsPages: Page | undefined;
    operatorsThreadsError: ThreadsError | undefined;
    operatorsThreads: OperatorsThread[] | undefined;
    messages: ReceivedMessage[] | undefined;
    messagesError: MessagesError | undefined;
    processingThreadsAssignments: string[] | undefined;
    processingThreadsCategory: string[] | undefined;
    changeThreadsCategoryError: ThreadsError | undefined;
    changeThreadError: ThreadsError | undefined;
    sendMessageStatusSuccess: boolean | undefined;
    changeThreadAssignmentError: ThreadAssignmentError | undefined;
    storedAvatarPictures: StoredAvatarPictures;
}

const defaultState: ConversationStateModel = {
    userAssignedCategories: undefined,
    conversationCategories: undefined,
    conversationAssignees: undefined,
    conversationCapabilitiesError: undefined,
    interlocutor: undefined,
    operatorCapabilitiesError: undefined,
    threads: undefined,
    isThreadsListLoading: undefined,
    isMessagesProcessing: undefined,
    threadsError: undefined,
    threadsPages: undefined,
    operatorsThreads: undefined,
    operatorsThreadsError: undefined,
    processingThreadsAssignments: undefined,
    processingThreadsCategory: undefined,
    changeThreadsCategoryError: undefined,
    messages: undefined,
    messagesError: undefined,
    changeThreadError: undefined,
    sendMessageStatusSuccess: undefined,
    changeThreadAssignmentError: undefined,
    storedAvatarPictures: {},
};

@State<ConversationStateModel>({
    name: "conversation",
    defaults: defaultState,
})
@Injectable()
export class ConversationState {
    @Selector()
    public static userAssignedCategories(state: ConversationStateModel): ConversationCategoryCode[] | undefined {
        return state.userAssignedCategories;
    }

    @Selector()
    public static conversationCategories(state: ConversationStateModel): ConversationCategoryCode[] | undefined {
        return state.conversationCategories;
    }

    @Selector()
    public static conversationAssignees(state: ConversationStateModel): Map<string, ConversationAssignee> | undefined {
        return state.conversationAssignees;
    }

    @Selector()
    public static capabilitiesError(state: ConversationStateModel): ConversationCapabilitiesError | undefined {
        return state.conversationCapabilitiesError;
    }

    @Selector()
    public static changeThreadAssignmentError(state: ConversationStateModel): ThreadAssignmentError | undefined {
        return state.changeThreadAssignmentError;
    }

    @Selector()
    public static sendMessageStatusSuccess(state: ConversationStateModel): boolean | undefined {
        return state.sendMessageStatusSuccess;
    }

    @Selector()
    public static interlocutor(state: ConversationStateModel): OperatorDetails | undefined {
        return state.interlocutor;
    }

    @Selector()
    public static threads(state: ConversationStateModel): Thread[] | undefined {
        return state.threads;
    }

    @Selector()
    public static isThreadsListLoading(state: ConversationStateModel): boolean | undefined {
        return state.isThreadsListLoading;
    }

    @Selector()
    public static operatorsThreads(state: ConversationStateModel): OperatorsThread[] | undefined {
        return state.operatorsThreads;
    }

    @Selector()
    public static messages(state: ConversationStateModel): ReceivedMessage[] | undefined {
        return state.messages;
    }

    @Selector()
    public static messagesError(state: ConversationStateModel): MessagesError | undefined {
        return state.messagesError;
    }

    @Selector()
    public static isMessagesProcessing(state: ConversationStateModel): boolean | undefined {
        return state.isMessagesProcessing;
    }

    @Selector()
    public static threadsError(state: ConversationStateModel): ThreadsError | undefined {
        return state.threadsError;
    }

    @Selector()
    public static threadsPages(state: ConversationStateModel): Page | undefined {
        return state.threadsPages;
    }

    @Selector()
    public static operatorCapabilitiesError(state: ConversationStateModel): OperatorError | undefined {
        return state.operatorCapabilitiesError;
    }

    @Selector()
    public static processingThreadsAssignments(state: ConversationStateModel): string[] | undefined {
        return state.processingThreadsAssignments;
    }

    @Selector()
    public static changeThreadError(state: ConversationStateModel): ThreadsError | undefined {
        return state.changeThreadError;
    }

    @Selector()
    public static processingThreadsCategory(state: ConversationStateModel): string[] | undefined {
        return state.processingThreadsCategory;
    }

    @Selector()
    public static changeThreadsCategoryError(state: ConversationStateModel): ThreadsError | undefined {
        return state.changeThreadsCategoryError;
    }

    @Selector()
    public static storedAvatarPictures(state: ConversationStateModel): StoredAvatarPictures | undefined {
        return state.storedAvatarPictures;
    }

    constructor(private readonly conversationAPIService: ConversationApiService) {
        if (conversationAPIService === undefined) {
            throw new Error("Initialize ConversationModule with .forRoot()");
        }
    }

    @Action(ConversationActions.GetThreads)
    public getThreads(context: StateContext<ConversationStateModel>, action: ConversationActions.GetThreads) {
        context.patchState({ isThreadsListLoading: true });

        return this.conversationAPIService.getThreads(action.params).pipe(
            tap((result) =>
                context.patchState({
                    threads: result.content,
                    threadsPages: {
                        totalElements: result.pages.totalElements,
                        pageNumber: result.pages.number,
                        pageSize: result.pages.size,
                    },
                    threadsError: undefined,
                    isThreadsListLoading: false,
                })
            ),
            catchError((error) => {
                context.patchState({
                    threadsError: error,
                    isThreadsListLoading: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(ConversationActions.GetConversationsCapabilities)
    public getConversationCapabilities(context: StateContext<ConversationStateModel>) {
        return this.conversationAPIService.getCapabilities().pipe(
            tap((result: ConversationCapabilities) =>
                context.patchState({
                    userAssignedCategories: result.assignedCategories,
                    conversationCategories: result.categories,
                    conversationAssignees: result.assignees,
                    conversationCapabilitiesError: undefined,
                })
            ),
            tap((capabilities: ConversationCapabilities) => {
                const avatarUrls = Array.from(capabilities.assignees.entries()).map(
                    ([userId, assigneeDetails]) => assigneeDetails.avatarUrl
                );
                context.dispatch(new ConversationActions.StoreAvatarPictures(avatarUrls));
            }),
            catchError((error) => {
                context.patchState({
                    conversationCapabilitiesError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(ConversationActions.ClearInterlocutorData)
    public clearInterlocutorData(context: StateContext<ConversationStateModel>) {
        context.patchState({
            interlocutor: undefined,
            sendMessageStatusSuccess: undefined,
        });
    }

    @Action(ConversationActions.ClearMessagesData)
    public clearMessagesData(context: StateContext<ConversationStateModel>) {
        context.patchState({
            interlocutor: undefined,
            operatorsThreads: undefined,
            operatorsThreadsError: undefined,
            messages: undefined,
            messagesError: undefined,
        });
    }

    @Action(ConversationActions.ClearStoredAvatarPictures)
    public clearStoredAvatarPictures(context: StateContext<ConversationStateModel>) {
        context.patchState({
            storedAvatarPictures: {},
        });
    }

    @Action(ConversationActions.ClearConversationData)
    public clearConversationData(context: StateContext<ClearConversationData>) {
        context.patchState({
            threads: undefined,
            threadsError: undefined,
            threadsPages: undefined,
        });
    }

    @Action(ConversationActions.CreateNewThread)
    public createNewThread(context: StateContext<ConversationStateModel>, action: ConversationActions.CreateNewThread) {
        context.patchState({ sendMessageStatusSuccess: undefined });

        return this.conversationAPIService.createNewThread(action.thread).pipe(
            tap(() => {
                context.patchState({ sendMessageStatusSuccess: true });
                const selectedOperatorId = context.getState().interlocutor?.id;

                if (selectedOperatorId) {
                    context.dispatch([new ConversationActions.GetOperatorThreads({ stakeholderId: selectedOperatorId })]);
                }
            }),
            catchError(() => {
                context.patchState({
                    sendMessageStatusSuccess: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(ConversationActions.SetInterlocutor)
    public setInterlocutor(context: StateContext<ConversationStateModel>, action: ConversationActions.SetInterlocutor) {
        context.patchState({
            interlocutor: action.interlocutor,
        });
    }

    @Action(ConversationActions.AddNewMessageToThread)
    public addNewMessageToThread(context: StateContext<ConversationStateModel>, action: ConversationActions.AddNewMessageToThread) {
        context.patchState({ sendMessageStatusSuccess: undefined });

        return this.conversationAPIService.addNewMessageToThread(action.value).pipe(
            tap(() => {
                context.patchState({ sendMessageStatusSuccess: true });
            }),
            catchError(() => {
                context.patchState({
                    sendMessageStatusSuccess: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(ConversationActions.GetMessagesByThread)
    public getMessagesByThread(context: StateContext<ConversationStateModel>, action: ConversationActions.GetMessagesByThread) {
        const threadId: string | undefined = action.threadId;
        context.patchState({ messages: undefined, isMessagesProcessing: true });

        if (threadId) {
            return this.conversationAPIService.getMessagesByThread(threadId).pipe(
                tap((result) => {
                    context.patchState({
                        messages: result,
                        messagesError: undefined,
                    });

                    context.dispatch(new ConversationActions.MarkAsRead(threadId));
                    context.patchState({ isMessagesProcessing: false });
                }),
                tap((messages: ReceivedMessage[]) => {
                    const uniqueAvatarUrls = [...new Set(messages.map((item) => item.sender.avatarUrl))];
                    context.dispatch(new ConversationActions.StoreAvatarPictures(uniqueAvatarUrls));
                }),
                catchError((error) => {
                    context.patchState({ messages: undefined, messagesError: error, isMessagesProcessing: false });

                    return EMPTY;
                })
            );
        }

        return context.patchState({
            messages: undefined,
            isMessagesProcessing: false,
            messagesError: { type: MessagesErrorType.NotFound },
        });
    }

    @Action(ConversationActions.GetOperatorThreads)
    public getOperatorThreads(context: StateContext<ConversationStateModel>, action: ConversationActions.GetOperatorThreads) {
        return this.conversationAPIService.getOperatorThreads(action.params).pipe(
            tap((result) =>
                context.patchState({
                    operatorsThreads: result,
                    operatorsThreadsError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({ operatorsThreads: undefined, operatorsThreadsError: error });

                return EMPTY;
            })
        );
    }

    @Action(ConversationActions.GetOperatorCapabilities)
    public getOperatorCapabilities(context: StateContext<ConversationStateModel>, action: ConversationActions.GetOperatorCapabilities) {
        return this.conversationAPIService.getOperatorCapabilities(action.operatorId, action.searchPhrase).pipe(
            tap((result) =>
                context.patchState({
                    interlocutor: result.operator,
                    operatorCapabilitiesError: undefined,
                    conversationAssignees: result.assignees,
                    conversationCategories: result.categories,
                })
            ),
            tap((capabilities: OperatorCapabilities) => {
                const avatarUrls = Array.from(capabilities.assignees.entries()).map(
                    ([userId, assigneeDetails]) => assigneeDetails.avatarUrl
                );
                context.dispatch(new ConversationActions.StoreAvatarPictures(avatarUrls));
            }),
            catchError((error) => {
                context.patchState({ interlocutor: undefined, operatorCapabilitiesError: error, operatorsThreads: undefined });

                return EMPTY;
            })
        );
    }

    @Action(ConversationActions.MarkAsRead)
    public markAsRead(context: StateContext<ConversationStateModel>, action: ConversationActions.MarkAsRead) {
        const selectedThread = context.getState().operatorsThreads?.find((thread: OperatorsThread) => thread.id === action.threadId);

        if (!selectedThread || selectedThread.isRead) {
            return;
        }

        context.dispatch(new ConversationActions.ChangeThread({ isClosed: selectedThread.isClosed, isRead: true }, action.threadId));
    }

    @Action(ConversationActions.ChangeThread)
    public changeThread(context: StateContext<ConversationStateModel>, action: ConversationActions.ChangeThread) {
        context.patchState({ changeThreadError: undefined });

        return this.conversationAPIService.changeThread(action.threadId, action.payload).pipe(
            tap(() => {
                const operatorId = context.getState().interlocutor?.id;

                if (!operatorId) {
                    return;
                }

                context.dispatch(new ConversationActions.GetOperatorThreads({ stakeholderId: operatorId }));
            }),
            catchError((error) => {
                context.patchState({ changeThreadError: error });

                return EMPTY;
            })
        );
    }

    @Action(ConversationActions.AssignThread)
    public assignThread(context: StateContext<ConversationStateModel>, action: ConversationActions.AssignThread) {
        const processingAssignments = [...(context.getState().processingThreadsAssignments || [])].concat([action.assignment.thread]);
        context.patchState({
            changeThreadAssignmentError: undefined,
            processingThreadsAssignments: processingAssignments,
        });

        return this.conversationAPIService.assignThread(action.assignment).pipe(
            tap(() =>
                context.patchState({
                    changeThreadAssignmentError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({
                    changeThreadAssignmentError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                const processingThreadsAssignments = [...(context.getState().processingThreadsAssignments ?? [])];
                const currentlyProcessingThreadsAssignments = processingThreadsAssignments.filter(
                    (processingAssignmentId) => processingAssignmentId !== action.assignment.thread
                );

                context.patchState({
                    processingThreadsAssignments: currentlyProcessingThreadsAssignments,
                });
            })
        );
    }

    @Action(ConversationActions.ChangeThreadsCategory)
    public changeThreadsCategory(context: StateContext<ConversationStateModel>, action: ConversationActions.ChangeThreadsCategory) {
        const processingCategoryChanges = [...(context.getState().processingThreadsCategory || [])].concat([action.threadId]);
        context.patchState({
            changeThreadsCategoryError: undefined,
            processingThreadsCategory: processingCategoryChanges,
        });

        return this.conversationAPIService.changeCategory(action.category, action.threadId).pipe(
            tap(() =>
                context.patchState({
                    changeThreadsCategoryError: undefined,
                })
            ),
            catchError((error) => {
                context.patchState({
                    changeThreadsCategoryError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                const processingThreadsCategory = [...(context.getState().processingThreadsCategory ?? [])];
                const currentlyProcessingThreadsCategory = processingThreadsCategory.filter(
                    (processingAssignmentId) => processingAssignmentId !== action.threadId
                );

                context.patchState({
                    processingThreadsCategory: currentlyProcessingThreadsCategory,
                });
            })
        );
    }

    @Action(ConversationActions.StoreAvatarPictures)
    public storeAvatarPictures(context: StateContext<ConversationStateModel>, action: ConversationActions.StoreAvatarPictures) {
        const storedAvatarPictures = context.getState().storedAvatarPictures;
        action.avatarUrls.forEach(async (avatarUrl) => {
            if (storedAvatarPictures[avatarUrl]) {
                return;
            }

            return await lastValueFrom(
                this.conversationAPIService.getAvatarPicture(avatarUrl).pipe(
                    tap((avatar) => {
                        const currenStoredAvatarPictures = context.getState().storedAvatarPictures;

                        context.patchState({
                            storedAvatarPictures: { ...currenStoredAvatarPictures, [avatarUrl]: avatar },
                        });
                    })
                )
            );
        });
    }
}
