import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { CentersComponent } from "./components/centers/centers.component";

const routes: Routes = [
    {
        path: "centers",
        component: CentersComponent,
        data: { titleTranslationKey: "dtmAdminLibCenters.routeTitles.centers" },
        canActivate: [DtmRoleGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CentersRoutingModule {}
