import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DialogService, MIN_PAGE_SIZE_VALUE, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs/operators";
import { DialogWithReasonComponent } from "../../../shared/components/dialog-with-reason/dialog-with-reason.component";
import {
    FiltersTabKeys,
    PermitActionType,
    PermitFilterParams,
    PermitFilterQueryParams,
    PermitStatus,
    PermitStatusChange,
    PermitsTab,
} from "../../models/permits.models";
import { PermitsActions } from "../../state/permits.actions";
import { PermitsState } from "../../state/permits.state";

const DEFAULT_ACTIVE_TAB = PermitsTab.SpecificPermit;
const DEFAULT_FILTERS_VALUE = {
    [PAGE_NUMBER_QUERY_PARAM]: 0,
    [PAGE_SIZE_QUERY_PARAM]: MIN_PAGE_SIZE_VALUE,
    searchByText: "",
    status: null,
    dateFrom: null,
    dateTo: null,
};

interface PermitsContainerState {
    activeTabIndex: PermitsTab;
    [FiltersTabKeys.SpecificPermitsFilters]: PermitFilterParams;
    [FiltersTabKeys.AssociationFilters]: PermitFilterParams;
    [FiltersTabKeys.CrossBorderFilters]: PermitFilterParams;
}

const actionMap = {
    [PermitActionType.Withdraw]: {
        translationKey: "dtmAdminLibPermits.container.withdrawReasonTitle",
        status: PermitStatus.Withdrawn,
    },
    [PermitActionType.Restore]: {
        translationKey: "dtmAdminLibPermits.container.restoreReasonTitle",
        status: PermitStatus.Active,
    },
    [PermitActionType.Suspend]: {
        translationKey: "dtmAdminLibPermits.container.suspendReasonTitle",
        status: PermitStatus.Suspended,
    },
};

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-permits-lists-container",
    templateUrl: "./permits-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PermitsContainerComponent implements OnInit {
    protected readonly PermitsTab = PermitsTab;
    protected readonly specificPermits$ = this.store.select(PermitsState.specificPermits);
    protected readonly isSpecificPermitsProcessing$ = this.store.select(PermitsState.isSpecificPermitsProcessing);
    protected readonly specificPermitsError$ = this.store.select(PermitsState.specificPermitsError);
    protected readonly specificPermitsPages$ = this.store.select(PermitsState.specificPermitsPages);
    protected readonly associationFilters$ = this.localStore.selectByKey(FiltersTabKeys.AssociationFilters);
    protected readonly crossBorderFilters$ = this.localStore.selectByKey(FiltersTabKeys.CrossBorderFilters);
    protected readonly specificPermitsFilters$ = this.localStore.selectByKey(FiltersTabKeys.SpecificPermitsFilters);
    protected readonly activeTabIndex$ = this.localStore.selectByKey("activeTabIndex");

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<PermitsContainerState>,
        private readonly store: Store,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService,
        @Inject(LOCALE_ID) private readonly locale: string
    ) {
        const snapshotParams = this.route.snapshot.queryParams;
        const snapShotData = {
            [PAGE_NUMBER_QUERY_PARAM]: snapshotParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: snapshotParams[PAGE_SIZE_QUERY_PARAM] ?? MIN_PAGE_SIZE_VALUE,
            searchByText: snapshotParams.searchByText,
            status: snapshotParams.status?.split(","),
            dateFrom: snapshotParams.dateFrom ? new Date(snapshotParams.dateFrom) : null,
            dateTo: snapshotParams.dateTo ? new Date(snapshotParams.dateTo) : null,
        };

        const snapshotTab = +snapshotParams.activeTab ?? DEFAULT_ACTIVE_TAB;

        this.localStore.setState({
            activeTabIndex: snapshotTab,
            [FiltersTabKeys.SpecificPermitsFilters]: snapshotTab === PermitsTab.SpecificPermit ? snapShotData : DEFAULT_FILTERS_VALUE,
            [FiltersTabKeys.AssociationFilters]: snapshotTab === PermitsTab.Association ? snapShotData : DEFAULT_FILTERS_VALUE,
            [FiltersTabKeys.CrossBorderFilters]: snapshotTab === PermitsTab.CrossBorder ? snapShotData : DEFAULT_FILTERS_VALUE,
        });
    }

    public ngOnInit(): void {
        this.listenOnQueryParamsChanged();
    }

    protected navigateByParams(filters: Partial<PermitFilterParams>, index: PermitsTab): void {
        const activeTab = this.localStore.selectSnapshotByKey("activeTabIndex");
        const key = this.getFilterKeyByIndex(index);
        const previousFilterState = this.localStore.selectSnapshotByKey(key);
        this.localStore.patchState({ [key]: { ...previousFilterState, ...filters } });
        this.applyFilters(activeTab);
    }

    protected applyFilters(activeTab: PermitsTab = DEFAULT_ACTIVE_TAB): void {
        const filters = this.localStore.selectSnapshotByKey(this.getFilterKeyByIndex(+activeTab));
        let params: PermitFilterQueryParams = {
            activeTab,
            [PAGE_NUMBER_QUERY_PARAM]: filters[PAGE_NUMBER_QUERY_PARAM],
            [PAGE_SIZE_QUERY_PARAM]: filters[PAGE_SIZE_QUERY_PARAM],
        };

        if (filters.searchByText) {
            params = { ...params, searchByText: filters.searchByText };
        }

        if (filters.status) {
            params = { ...params, status: filters.status.join(",") };
        }

        if (filters.dateFrom) {
            params = { ...params, dateFrom: filters.dateFrom };
        }

        if (filters.dateTo) {
            params = { ...params, dateTo: filters.dateTo };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    protected updateTabIndex(tabIndex: PermitsTab): void {
        this.localStore.patchState({ activeTabIndex: tabIndex });
        this.applyFilters(tabIndex);
    }

    protected listenOnQueryParamsChanged(): void {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: Params) => {
            const activeTab = !queryParams.activeTab ? DEFAULT_ACTIVE_TAB : +queryParams.activeTab;
            this.localStore.patchState({ activeTabIndex: activeTab });
            this.getSpecificPermitsList();
        });
    }

    protected specificPermitsPageChange(pageEvent: PageEvent): void {
        const activeTab = this.localStore.selectSnapshotByKey("activeTabIndex");
        const previousFilters = this.localStore.selectSnapshotByKey(FiltersTabKeys.SpecificPermitsFilters);
        this.localStore.patchState({
            [FiltersTabKeys.SpecificPermitsFilters]: {
                ...previousFilters,
                [PAGE_NUMBER_QUERY_PARAM]: pageEvent.pageIndex,
                [PAGE_SIZE_QUERY_PARAM]: pageEvent.pageSize,
            },
        });

        this.applyFilters(activeTab);
    }

    protected specificPermitsListRefresh(): void {
        this.getSpecificPermitsList();
    }

    private getSpecificPermitsList(): void {
        const activeTab = this.localStore.selectSnapshotByKey("activeTabIndex");
        const filters = this.localStore.selectSnapshotByKey(
            activeTab === PermitsTab.SpecificPermit ? FiltersTabKeys.SpecificPermitsFilters : FiltersTabKeys.AssociationFilters
        );
        this.store.dispatch(new PermitsActions.GetSpecificPermits(filters, activeTab));
    }

    private getFilterKeyByIndex(tabIndex: PermitsTab) {
        switch (tabIndex) {
            case PermitsTab.SpecificPermit:
                return FiltersTabKeys.SpecificPermitsFilters;
            case PermitsTab.Association:
                return FiltersTabKeys.AssociationFilters;
            case PermitsTab.CrossBorder:
                return FiltersTabKeys.CrossBorderFilters;
        }
    }

    protected openActionDialog({ action, permitId }: PermitStatusChange) {
        const dialogRef = this.dialogService.open(DialogWithReasonComponent, {
            data: {
                titleText: this.transloco.translate(actionMap[action].translationKey),
                isReasonProcessing$: this.isSpecificPermitsProcessing$,
            },
        });

        dialogRef.componentInstance.setReason$
            .pipe(
                switchMap((reason: string) =>
                    this.store.dispatch(new PermitsActions.UpdatePermitStatus(actionMap[action].status, reason, permitId))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(PermitsState.updatePermitStatusError);

                if (error) {
                    this.toastService.error(this.transloco.translate("dtmAdminLibPermits.container.updatePermitErrorMessage"));

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmAdminLibPermits.container.updatePermitSuccessMessage"));
                this.getSpecificPermitsList();
                dialogRef.close();
            });
    }
}
