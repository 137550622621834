import { Page } from "@dtm-frontend/shared/ui";
import { ExamTrainingResultsMode } from "./exam-training-results.models";

export enum ExamResultsErrorType {
    CannotGetExamResultsList = "CannotGetExamResultsList",
}

export interface ExamResultListWithPages extends Page {
    content: ExamResult[];
}

export interface ExamResultsError {
    type: ExamResultsErrorType;
}

export interface ExamResult {
    id: string;
    name: string;
    operatorNumber: string;
    examNativeName: string;
    examEnglishName: string;
    passDate: Date;
    examMode: ExamTrainingResultsMode;
    examiningCenterName: string;
}
