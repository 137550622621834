import { Page, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";

export interface AuditChangeLogWithPages extends Page {
    content: AuditChangeLog[];
}

export interface AuditChangeLogError {
    type: AuditChangeLogErrorType;
}

export enum AuditChangeLogErrorType {
    Unknown = "Unknown",
}

export interface AuditChangeLog {
    subjectType: string;
    subjectIdentifier: string;
    actionName: string;
    actionDetails: any;
    id: string;
    createdAt: Date;
    userName: string;
}

export interface ChangeLogFiltersParams {
    [PAGE_NUMBER_QUERY_PARAM]?: number;
    [PAGE_SIZE_QUERY_PARAM]?: number;
    type?: string;
    identifier?: string;
    actionName?: string;
    dateFrom?: string | null;
    dateTo?: string | null;
}

export enum ChangeLogFilterFormKeys {
    Type = "type",
    Identifier = "identifier",
    ActionName = "actionName",
    DateFrom = "dateFrom",
    DateTo = "dateTo",
}

export interface ChangeLogFilters {
    [ChangeLogFilterFormKeys.Type]?: string;
    [ChangeLogFilterFormKeys.Identifier]?: string;
    [ChangeLogFilterFormKeys.ActionName]?: string;
    [ChangeLogFilterFormKeys.DateFrom]?: Date | null;
    [ChangeLogFilterFormKeys.DateTo]?: Date | null;
}
