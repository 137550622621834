import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Assignee, StoredAvatarPictures } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map, switchMap, withLatestFrom } from "rxjs/operators";
import { ConversationAssignee, Thread } from "../../../services/conversation.models";

const NULL_ASSIGNEE = 0;

interface ThreadsListAssigneeChangeComponentState {
    assignees: Map<string, ConversationAssignee> | undefined;
    processingThreadIds: string[];
    thread: Thread | undefined;
    storedAvatarPictures: StoredAvatarPictures;
}

@Component({
    selector: "dtm-admin-lib-threads-list-assignee-change[assignees][processingThreadIds]",
    templateUrl: "./threads-list-assignee-change.component.html",
    styleUrls: ["./threads-list-assignee-change.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ThreadsListAssigneeChangeComponent {
    @Input() public set assignees(value: Map<string, ConversationAssignee> | undefined) {
        this.localStore.patchState({ assignees: value });
    }

    @Input() public set processingThreadIds(value: string[] | undefined) {
        this.localStore.patchState({ processingThreadIds: value ?? [] });
    }

    @Input() public set thread(value: Thread | undefined) {
        this.localStore.patchState({ thread: value });
    }

    @Input() public set storedAvatarPictures(value: StoredAvatarPictures | undefined) {
        this.localStore.patchState({ storedAvatarPictures: value ?? {} });
    }

    @Output() public assigneeChange: EventEmitter<Assignee | null> = new EventEmitter<Assignee | null>();

    public readonly assignees$ = this.localStore.selectByKey("assignees");
    public readonly storedAvatarPictures$ = this.localStore.selectByKey("storedAvatarPictures");
    public readonly thread$ = this.localStore.selectByKey("thread");
    public readonly isProcessing$ = this.localStore.selectByKey("processingThreadIds").pipe(
        withLatestFrom(this.localStore.selectByKey("thread").pipe(map((thread) => thread?.id))),
        map(([list, threadId]) => list?.some((processingId) => processingId === threadId))
    );

    public selectedAssignee$ = this.thread$.pipe(
        RxjsUtils.filterFalsy(),
        map((thread) => thread.assignee?.userId),
        switchMap((assigneeId) =>
            this.assignees$.pipe(
                RxjsUtils.filterFalsy(),
                map((assignees) => (assigneeId ? assignees.get(assigneeId) : NULL_ASSIGNEE))
            )
        )
    );
    public readonly NULL_ASSIGNEE = NULL_ASSIGNEE;

    constructor(private readonly localStore: LocalComponentStore<ThreadsListAssigneeChangeComponentState>) {
        this.localStore.setState({
            assignees: undefined,
            thread: undefined,
            processingThreadIds: [],
            storedAvatarPictures: {},
        });
    }

    public changeAssignee(assigneeId: string | typeof NULL_ASSIGNEE) {
        if (!assigneeId) {
            this.assigneeChange.emit(null);

            return;
        }

        const newAssignee = this.localStore.selectSnapshotByKey("assignees")?.get(assigneeId);
        if (newAssignee) {
            this.assigneeChange.emit({ userId: assigneeId, firstName: newAssignee.firstName, lastName: newAssignee.lastName });

            return;
        }

        this.assigneeChange.emit(null);
    }
}
