import { AbstractControl, ValidationErrors } from "@angular/forms";

export function isConfirmationMailSameAsMail(control: AbstractControl): ValidationErrors | null {
    const email = control.parent?.value.email;
    const repeatedEmail = control.value;

    if (!control.errors?.required && !control.errors?.email && email !== repeatedEmail) {
        return { differentEmails: true };
    }

    return null;
}
