<div class="filters-wrapper">
    <dtm-ui-filters-container>
        <div [formGroup]="examTrainingResultsFiltersForm">
            <dtm-ui-input-field class="control-search-text">
                <input
                    matInput
                    [formControlName]="ExamTrainingResultsFilterFormKeys.SearchPhrase"
                    type="text"
                    [placeholder]="'dtmAdminLibExamTrainingResults.filters.searchPhrasePlaceholder' | transloco"
                />
                <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
            </dtm-ui-input-field>
        </div>
    </dtm-ui-filters-container>
    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [formGroup]="examTrainingResultsFiltersForm"
        [filtersValue]="examTrainingResultsFiltersForm.value"
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="appliedFiltersLength$ | ngrxPush"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</div>
