import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import {
    Address,
    BasicAddress,
    InvalidFormScrollableDirective,
    PhoneNumber,
    requiredValidForSmsPhoneNumberValidator,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_COUNTRY_CODE, DEFAULT_PHONE_COUNTRY_CODE, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { Subject } from "rxjs";
import { CentersTabType } from "../../models/centers.models";
import { ExaminationCenter, ExaminationCenterDialogData } from "../../models/examination-centers.models";
import { MAX_IDENTIFICATION_NUMBER_LENGTH, MAX_TEXT_LENGTH } from "../../utils/form-validators-settings";

@Component({
    selector: "dtm-admin-lib-centers-examination-centers-add-form-dialog",
    templateUrl: "./examination-centers-add-form-dialog.component.html",
    styleUrls: ["./examination-centers-add-form-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExaminationCentersAddFormDialogComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    private readonly newValueSubject = new Subject<ExaminationCenter>();
    public readonly newValue$ = this.newValueSubject.asObservable();

    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly CentersTabType = CentersTabType;
    protected readonly nameControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(MAX_TEXT_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly identificationNumberControl = new FormControl<string>("", {
        validators: [
            Validators.required,
            Validators.maxLength(MAX_IDENTIFICATION_NUMBER_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ],
        nonNullable: true,
    });
    protected readonly administrativeDecisionNumberControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(MAX_TEXT_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly emailControl = new FormControl<string>("", {
        validators: [Validators.email, Validators.required],
        nonNullable: true,
    });
    protected readonly phoneNumberControl = new FormControl<PhoneNumber>(
        {
            number: "",
            countryCode: DEFAULT_PHONE_COUNTRY_CODE,
        },
        { validators: requiredValidForSmsPhoneNumberValidator, nonNullable: true }
    );
    protected readonly countryControl = new FormControl(DEFAULT_COUNTRY_CODE, { nonNullable: true, validators: Validators.required });
    protected readonly basicAddressControl = new FormControl<BasicAddress>({
        streetName: "",
        houseNumber: "",
        apartmentNumber: "",
        postCode: "",
        city: "",
    });
    protected readonly examCodesControl = new FormControl<string[]>([], { nonNullable: true, validators: Validators.required });
    protected readonly validUntilControl = new FormControl<Date | null>(null, {
        validators: [Validators.required],
    });
    protected readonly examinationCenterForm = new FormGroup({
        name: this.nameControl,
        identificationNumber: this.identificationNumberControl,
        administrativeDecisionNumber: this.administrativeDecisionNumberControl,
        email: this.emailControl,
        phoneNumber: this.phoneNumberControl,
        address: this.basicAddressControl,
        country: this.countryControl,
        examCodes: this.examCodesControl,
        validUntil: this.validUntilControl,
    });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ExaminationCenterDialogData,
        private readonly translocoHelper: TranslationHelperService
    ) {
        if (!data.center) {
            return;
        }

        const { name, identificationNumber, administrativeDecisionNumber, email, phoneNumber, address, examCodes, validUntil } =
            data.center;
        this.examinationCenterForm.setValue({
            name,
            identificationNumber: identificationNumber as string,
            administrativeDecisionNumber,
            email,
            phoneNumber,
            address,
            country: address.country,
            examCodes,
            validUntil,
        });
    }

    protected save() {
        this.examinationCenterForm.markAllAsTouched();
        if (this.examinationCenterForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        const { name, identificationNumber, administrativeDecisionNumber, email, phoneNumber, address, country, examCodes, validUntil } =
            this.examinationCenterForm.getRawValue();
        const totalAddress = { ...address, country };

        this.newValueSubject.next({
            id: this.data.center?.id,
            name,
            identificationNumber,
            administrativeDecisionNumber,
            email,
            phoneNumber,
            address: totalAddress as Address,
            examCodes,
            validUntil: validUntil as Date,
        });
    }

    protected removeExamCode(examCode: string) {
        this.examCodesControl.setValue(this.examCodesControl.value.filter((value) => value !== examCode));
    }
}
