<dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
    <dtm-ui-wizard-step-wrapper
        [title]="'dtmAdminLibPermits.crossBorderPermitRequest.summaryTitle' | transloco"
        [stepsAmount]="3"
        [stepNumber]="3"
        [nextButtonLabel]="
            ((isUpdateMode$ | ngrxPush)
                ? 'dtmAdminLibPermits.crossBorderPermitRequest.updatePermitButtonLabel'
                : 'dtmAdminLibPermits.crossBorderPermitRequest.addPermitButtonLabel'
            ) | transloco
        "
        [isBackButtonVisible]="true"
        (back)="back.emit()"
        (next)="addPermit.emit()"
    >
        <div class="summary-wrapper" *ngrxLet="permit$ as permit">
            <dtm-ui-permit-details-preview
                [areActionButtonsVisible]="true"
                [permit]="permit"
                (basicDataEdit)="stepEdit.emit(CrossBorderPermitWizardSteps.BasicInfo)"
                (uavDetailsEdit)="stepEdit.emit(CrossBorderPermitWizardSteps.UavDetails)"
                (kmlFilePreview)="kmlFilePreview.emit($event)"
                isOperatorDetailsTileExpanded
                isContactPersonTileExpanded
                isRestrictionsTileExpanded
                isPermitDetailsExpanded
                isOperatorContactPersonVisible
            ></dtm-ui-permit-details-preview>
            <div class="operation-description">
                <h4 class="section-title">{{ "dtmAdminLibPermits.crossBorderPermitRequest.operationPermit" | transloco }}</h4>
                <span class="operator-name">{{ permit.basicData.operator.name }}</span>
                {{ "dtmAdminLibPermits.crossBorderPermitRequest.performOperationDescription" | transloco }}
            </div>
        </div>
    </dtm-ui-wizard-step-wrapper>
</dtm-ui-loader-container>
