<ng-container *ngrxLet="areInitialPermissionsSelected$ as areInitialPermissionsSelected">
    <div class="dialog-header">
        <div mat-dialog-title>
            {{
                (areInitialPermissionsSelected
                    ? "dtmAdminLibShared.operatorProfile.attorneyPowers.updatePermissionsDialogTitleText"
                    : "dtmAdminLibShared.operatorProfile.attorneyPowers.grantPermissionsDialogTitleText"
                ) | transloco
            }}
        </div>
        <button type="button" class="button-icon" [matDialogClose]="null"><dtm-ui-icon name="close"></dtm-ui-icon></button>
    </div>
    <div class="dialog-content" mat-dialog-content>
        <p>
            {{
                (areInitialPermissionsSelected
                    ? "dtmAdminLibShared.operatorProfile.attorneyPowers.updatePermissionsDialogConfirmationText"
                    : "dtmAdminLibShared.operatorProfile.attorneyPowers.grantPermissionsDialogConfirmationText"
                ) | transloco
            }}
        </p>
        <form class="form" [formGroup]="form" dtmUiInvalidFormScrollable>
            <dtm-ui-select-field [multiple]="true" formControlName="permissions">
                <label>{{ "dtmAdminLibShared.operatorProfile.attorneyPowers.permissionsLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let permission of data.availablePermissions" [value]="permission">
                    {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.permissionsValue" | transloco : { permission } }}
                </dtm-ui-select-option>
                <div class="field-error" *dtmUiFieldHasError="permissionsControl; name: 'required'">
                    {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-select-field>
            <mat-chip-list *ngIf="permissionsControl.value?.length" class="permission-chip-list">
                <mat-chip class="chip removable" *ngFor="let permission of permissionsControl.value">
                    {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.permissionsValue" | transloco : { permission } }}
                    <dtm-ui-icon matChipRemove name="close-circle-fill" (click)="removePermission(permission)"></dtm-ui-icon>
                </mat-chip>
            </mat-chip-list>
        </form>
    </div>
    <div class="action-buttons-container" mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="null">
            {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.cancelLabel" | transloco }}
        </button>
        <button (click)="validateAndSubmit()" type="submit" class="button-primary">
            {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.saveLabel" | transloco }}
        </button>
    </div>
</ng-container>
