<ng-container
    *ngrxLet="{
        aircraftEntity: aircraftEntity$,
        isUserAircraft: isUserAircraft$,
        isInVerificationMode: isInVerificationMode$,
        violationClass: violationClass$,
        name: name$,
        emergency: emergency$,
        isHeightVisible: isHeightVisible$,
        isInfoVisible: isInfoVisible$
    } as vm"
>
    <ng-container *ngIf="vm.aircraftEntity">
        <div
            class="aircraft-pin user-aircraft"
            [class.verification]="vm.isInVerificationMode"
            [ngClass]="vm.violationClass"
            [class.emergency]="vm.emergency"
            *ngIf="vm.isUserAircraft"
        >
            <div class="no-signal" *ngIf="vm.aircraftEntity.isConnectionLost">
                <dtm-ui-icon name="pin-off"></dtm-ui-icon>
            </div>
            <div class="aircraft-icon" [ngSwitch]="vm.emergency" (click)="toggleInfoVisibility()">
                <dtm-ui-icon name="drone" class="drone"></dtm-ui-icon>
                <dtm-ui-icon class="emergency-icon drone" name="tools" *ngSwitchCase="EmergencyType.TechnicalIssue"></dtm-ui-icon>
                <dtm-ui-icon class="emergency-icon drone" name="wifi-off" *ngSwitchCase="EmergencyType.LostControl"></dtm-ui-icon>
                <dtm-ui-icon class="emergency-icon drone" name="hems-emr" *ngSwitchCase="EmergencyType.EmergencyLanding"></dtm-ui-icon>
            </div>

            <ng-container
                [ngTemplateOutlet]="entityInfoTemplate"
                [ngTemplateOutletContext]="{ $implicit: vm.aircraftEntity, name: vm.name }"
            ></ng-container>
        </div>
        <div
            class="aircraft-pin"
            [class.farada]="vm.aircraftEntity.isFaradaSource"
            *ngIf="!vm.isUserAircraft"
            [class.active]="vm.isInfoVisible"
        >
            <div class="aircraft-icon" (click)="toggleInfoVisibility()">
                <div class="no-signal" *ngIf="vm.aircraftEntity?.isConnectionLost">
                    <dtm-ui-icon name="pin-off"></dtm-ui-icon>
                </div>
                <dtm-ui-icon name="drone" class="drone" *ngIf="vm.aircraftEntity.aircraftType === AircraftType.Uav"></dtm-ui-icon>
                <dtm-ui-icon
                    name="plane"
                    class="drone"
                    [ngStyle]="{ transform: 'rotate(' + vm.aircraftEntity.orientation + 'deg)' }"
                    *ngIf="vm.aircraftEntity.aircraftType === AircraftType.Airplane"
                ></dtm-ui-icon>
            </div>
            <ng-container
                [ngTemplateOutlet]="entityInfoTemplate"
                [ngTemplateOutletContext]="{ $implicit: vm.aircraftEntity }"
            ></ng-container>
        </div>
        <ng-template #entityInfoTemplate>
            <div class="drone-info" *ngIf="vm.isInfoVisible">
                <dtm-ui-triangle-pointer-svg class="pointer"></dtm-ui-triangle-pointer-svg>
                <div class="content">
                    <span class="name" *ngIf="(vm.isUserAircraft ? vm.name : undefined) ?? vm.aircraftEntity.name as name" [title]="name">
                        {{ name }}
                    </span>
                    <button type="button" class="button-tertiary" (click)="toggleHeightVisibility()">
                        {{ "dtmMapCesium.flightPin.toggleAglHeightVisibilityLabel" | transloco : { isVisible: vm.isHeightVisible } }}
                    </button>
                </div>
                <div class="flight-info">
                    <div class="main">
                        {{ "dtmMapCesium.flightPin.heightLabel" | transloco }}:
                        <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: vm.aircraftEntity.altitude }"></ng-container>
                        {{ "dtmMapCesium.flightPin.amslLabel" | transloco }}
                        <span *ngIf="vm.isHeightVisible">
                            (
                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: vm.aircraftEntity.height }">
                            </ng-container>
                            )
                        </span>
                    </div>
                    <div class="additional-info" #additionalInfoTemplate>
                        <p>
                            {{ "dtmMapCesium.flightPin.baroLabel" | transloco }}:
                            <ng-container
                                *ngTemplateOutlet="valueTemplate; context: { $implicit: vm.aircraftEntity.originalAltitudeBaro }"
                            ></ng-container>
                            {{ "dtmMapCesium.flightPin.amslLabel" | transloco }}
                        </p>
                        <p>
                            {{ "dtmMapCesium.flightPin.geoLabel" | transloco }}:
                            <ng-container
                                *ngTemplateOutlet="valueTemplate; context: { $implicit: vm.aircraftEntity.originalAltitudeGeo }"
                            ></ng-container>
                            {{ "dtmMapCesium.flightPin.amslLabel" | transloco }}
                        </p>
                    </div>
                    <button type="button" (click)="additionalInfoTemplate.classList.toggle('expanded')" class="button-icon">
                        <dtm-ui-icon
                            [name]="additionalInfoTemplate.classList.contains('expanded') ? 'arrow-up' : 'arrow-down'"
                        ></dtm-ui-icon>
                    </button>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
<ng-template #valueTemplate let-value>
    {{ value ? ("dtmMapCesium.flightPin.valueInMeters" | transloco : { value }) : "---" }}
</ng-template>
