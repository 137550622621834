import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ConversationCategoryCode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UserConversationsStats } from "../../services/dashboard.model";

interface ConversationsWidgetComponentState {
    userConversationsStats: UserConversationsStats | undefined;
    assignedCategories: string | undefined;
}

@Component({
    selector: "dtm-admin-lib-conversations-tile[userConversationsStats]",
    templateUrl: "./conversations-tile.component.html",
    styles: [".button-tertiary { width: 100%; height: 48px }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ConversationsTileComponent {
    @Input() public set userConversationsStats(value: UserConversationsStats | undefined) {
        this.localStore.patchState({ userConversationsStats: value });
    }

    @Input() public set assignedCategories(value: ConversationCategoryCode[] | undefined) {
        this.localStore.patchState({ assignedCategories: value?.join(",") });
    }

    public readonly userConversationsStats$ = this.localStore.selectByKey("userConversationsStats");
    public readonly assignedCategories$ = this.localStore.selectByKey("assignedCategories");

    constructor(private readonly localStore: LocalComponentStore<ConversationsWidgetComponentState>) {
        localStore.setState({ userConversationsStats: undefined, assignedCategories: undefined });
    }
}
