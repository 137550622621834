import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils, UploadedFile } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { FILES_MANAGEMENT_ENDPOINTS, FilesManagementEndpoints } from "./files-management.tokens";

@Injectable({
    providedIn: "root",
})
export class FilesManagementApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(FILES_MANAGEMENT_ENDPOINTS) private readonly endpoints: FilesManagementEndpoints
    ) {}

    public uploadFile(file: File): Observable<HttpEvent<UploadedFile>> {
        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .post<UploadedFile>(this.endpoints.uploadFile, formData, {
                reportProgress: true,
                responseType: "json",
                observe: "events",
            })
            .pipe(catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error))));
    }

    public getFile(fileId: string): Observable<Blob> {
        return this.httpClient.get(StringUtils.replaceInTemplate(this.endpoints.getFile, { fileId }), {
            responseType: "blob",
            context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
        });
    }

    public getFilesCompressed(fileIds: string[], fileName: string): Observable<Blob> {
        return this.httpClient.get(this.endpoints.getFilesCompressed, {
            params: new HttpParams().set("fileIds", fileIds.join(",")).set("fileName", fileName),
            responseType: "blob",
        });
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }
}
