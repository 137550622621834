import { InjectionToken } from "@angular/core";

export interface MissionSearchEndpoints {
    getPlans: string;
    getPlanData: string;
    getPlanVerification: string;
    getPlanCaaPermitDetails: string;
    getRoute: string;
}

export const MISSION_SEARCH_ENDPOINTS = new InjectionToken<MissionSearchEndpoints>("Mission Search endpoints");
export const UAV_APP_URL = new InjectionToken<string>("Uav App URL");
export const IS_EXTERNAL_APP_BUTTON_VISIBLE = new InjectionToken<boolean>("Is External App Button Visible");
