import { ConversationCategoryCode } from "@dtm-frontend/shared/ui";
import { OperatorDetails } from "../../shared";
import { AddNewMessage, ConversationThreadsFilterParams, NewThread, NewThreadAssignment } from "../services/conversation.models";

export namespace ConversationActions {
    export class ClearConversationData {
        public static readonly type = "[Conversation] Clear Conversation Data";
    }

    export class GetThreads {
        public static readonly type = "[Conversation] Get threads";

        constructor(public params: ConversationThreadsFilterParams) {}
    }

    export class GetConversationsCapabilities {
        public static readonly type = "[Conversation] Get conversation capabilities";
    }

    export class CreateNewThread {
        public static readonly type = "[Conversation] Create new Thread";

        constructor(public thread: NewThread) {}
    }

    export class AddNewMessageToThread {
        public static readonly type = "[Conversation] Add new message to thread";

        constructor(public value: AddNewMessage) {}
    }

    export class ClearInterlocutorData {
        public static readonly type = "[Conversation] Clear Data";
    }

    export class ClearMessagesData {
        public static readonly type = "[Conversation] Clear Messages Data";
    }

    export class ClearStoredAvatarPictures {
        public static readonly type = "[Conversation] Clear stored avatar pictures";
    }

    export class SetInterlocutor {
        public static readonly type = "[Conversation] Set Interlocutor";

        constructor(public interlocutor: OperatorDetails | undefined) {}
    }

    export class GetMessagesByThread {
        public static readonly type = "[Conversation] Get messages by thread";

        constructor(public threadId: string | undefined) {}
    }

    export class GetOperatorCapabilities {
        public static readonly type = "[Conversation] Get operator capabilities";

        constructor(public operatorId: string, public searchPhrase?: string) {}
    }

    export class GetOperatorThreads {
        public static readonly type = "[Conversation] Get operator threads";

        constructor(public params: ConversationThreadsFilterParams) {}
    }

    export class AssignThread {
        public static readonly type = "[Conversation] Assign thread";

        constructor(public assignment: NewThreadAssignment) {}
    }

    export class MarkAsRead {
        public static readonly type = "[Conversation] Mark as read";

        constructor(public threadId: string) {}
    }

    export class ChangeThread {
        public static readonly type = "[Conversation] Change thread";

        constructor(public payload: { isClosed: boolean; isRead: boolean }, public threadId: string) {}
    }

    export class StoreAvatarPictures {
        public static readonly type = "[Conversation] Store avatar pictures";

        constructor(public avatarUrls: string[]) {}
    }

    export class ChangeThreadsCategory {
        public static readonly type = "[Conversation] Change threads category";

        constructor(public category: ConversationCategoryCode, public threadId: string) {}
    }
}
