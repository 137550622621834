<div class="dialog-header">
    <div mat-dialog-title>{{ "dtmAdminLibCenters.centerFormDialog.trainingOperatorsDataHeader" | transloco }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>

<div mat-dialog-content>
    <div class="preview-container">
        <div class="card">
            <div class="card-title">{{ "dtmAdminLibCenters.centerFormDialog.operator.operatorInfoTitle" | transloco }}</div>
            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.operator.operatorNameLabel' | transloco"
                [value]="data.operatorDetails?.name"
            ></dtm-ui-label-value>

            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.operator.operatorNumberLabel' | transloco"
                [value]="data.operatorDetails?.number"
            ></dtm-ui-label-value>

            <dtm-ui-address-label-value [address]="data.operatorDetails?.address"></dtm-ui-address-label-value>

            <dtm-ui-label-value [label]="'dtmAdminLibCenters.centerFormDialog.operator.operatorQualificationsLabel' | transloco">
                <dtm-admin-lib-operator-qualifications-badges
                    [badges]="data.operatorDetails?.operationalAuthorizations"
                ></dtm-admin-lib-operator-qualifications-badges>
            </dtm-ui-label-value>
        </div>

        <div class="card">
            <div class="card-title">{{ "dtmAdminLibCenters.centerFormDialog.basicDataTitle" | transloco }}</div>
            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.nameLabel' | transloco"
                [value]="data.center?.name"
            ></dtm-ui-label-value>

            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.identificationNumberLabel' | transloco"
                [value]="data.center?.identificationNumber"
            ></dtm-ui-label-value>

            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.administrativeDecisionLabel' | transloco"
                [value]="data.center?.administrativeDecisionNumber"
            ></dtm-ui-label-value>
        </div>

        <div class="card">
            <div class="card-title">{{ "dtmAdminLibCenters.centerFormDialog.permitsTitle" | transloco }}</div>
            <dtm-ui-label-value
                class="conducting-trainings"
                [label]="'dtmAdminLibCenters.centerFormDialog.conductingTrainingsLabel' | transloco"
            >
                <div *ngIf="data.center?.trainingCodes?.length; else noCodesDataTemplate" class="exams-container">
                    <span *ngFor="let trainingCode of data?.center?.trainingCodes || []">
                        {{ trainingCode }}
                    </span>
                </div>

                <ng-template #noCodesDataTemplate>-</ng-template>
            </dtm-ui-label-value>

            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.validUntilLabel' | transloco"
                [value]="data.center?.validUntil | localizeDate"
            ></dtm-ui-label-value>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button type="button" class="button-primary action-button" (click)="dialog.close()">
        {{ "dtmAdminLibCenters.centerFormDialog.closeLabel" | transloco }}
    </button>
</div>
