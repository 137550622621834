<ng-container *ngrxLet="{ mission: mission$ } as vm">
    <div class="chip-list">
        <dtm-mission-mission-status-badge [status]="vm.mission.status"></dtm-mission-mission-status-badge>
        <span
            class="chip default"
            *ngIf="vm.mission.dtmNames.length"
            [matTooltip]="vm.mission.dtmNames | join"
            [matTooltipDisabled]="vm.mission.dtmNames.length < 2"
        >
            <ng-container *ngIf="vm.mission.dtmNames.length == 1">
                {{ vm.mission.dtmNames[0] }}
            </ng-container>
            <ng-container *ngIf="vm.mission.dtmNames.length > 1">
                {{ vm.mission.dtmNames[0] }},
                <span class="more-items">+{{ vm.mission.dtmNames.length - 1 }}</span>
            </ng-container>
        </span>
        <span class="chip disabled">
            <dtm-ui-icon [name]="vm.mission.flightType === MissionType.BVLOS ? 'eye-off' : 'eye'"></dtm-ui-icon>
            {{ "dtmSharedMissionSearch.missionTypeLabel" | transloco : { value: vm.mission.flightType } }}
        </span>
    </div>
    <div class="row" [ngSwitch]="isSoraApplication$ | ngrxPush">
        <ng-container *ngSwitchCase="true">
            <div class="column">
                <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                <p class="time">
                    {{ vm.mission.flightStartAtMin | localizeDate : { dateStyle: "short" } }}
                    -
                    {{ vm.mission.flightFinishAtMax | localizeDate : { dateStyle: "short" } }}
                </p>
                <p class="additional-info">
                    {{
                        "dtmSharedMissionSearch.missionPreview.missionDateRangeDays"
                            | transloco : { days: vm.mission.flightStartAtMin | invoke : getDaysBetween : vm.mission.flightFinishAtMax }
                    }}
                </p>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="column">
                <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                <p class="time">
                    {{ vm.mission.flightStartAtMin | localizeDate : { day: "numeric", month: "numeric" } }}
                </p>
                <p class="additional-info">{{ vm.mission.flightStartAtMin | localizeDate : { weekday: "long" } }}</p>
            </div>
            <div class="column">
                <dtm-ui-icon name="time"></dtm-ui-icon>
                <p class="time">
                    {{ vm.mission.flightStartAtMin | localizeDate : { timeStyle: "short" } }}
                    -
                    {{ vm.mission.flightFinishAtMax | localizeDate : { timeStyle: "short" } }}
                </p>
                <p class="additional-info">
                    <dtm-ui-time-diff-display
                        [firstDate]="vm.mission.flightStartAtMin"
                        [secondDate]="vm.mission.flightFinishAtMax"
                    ></dtm-ui-time-diff-display>
                </p>
            </div>
        </ng-container>
    </div>
</ng-container>
