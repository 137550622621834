import { Page } from "@dtm-frontend/shared/ui";
import { Observable } from "rxjs";
import { OperatorDetails } from "./operators.model";

export interface TrainingCenterDialogData {
    header?: string;
    confirmLabel?: string;
    isCenterProcessing$?: Observable<boolean>;
    center?: TrainingCenter;
    operatorDetails?: OperatorDetails;
    capabilities: string[];
}

export enum TrainingCentersErrorType {
    CannotAddTrainingCenter = "CannotAddTrainingCenter",
    CannotEditTrainingCenter = "CannotEditTrainingCenter",
    CannotGetTrainingCentersList = "CannotGetTrainingCentersList",
    CannotDeleteExaminationCenter = "CannotDeleteExaminationCenter",
    TrainingCenterConflict = "TrainingCenterConflict",
}

export interface TrainingCenterListWithPages extends Page {
    content: TrainingCenter[];
}

export interface TrainingCentersError {
    type: TrainingCentersErrorType;
}

export interface TrainingCenter {
    id?: string;
    operatorId?: string;
    operatorNumber?: string;
    name: string;
    identificationNumber?: string;
    administrativeDecisionNumber: string;
    trainingCodes: string[];
    validUntil: Date;
}
