<dtm-admin-lib-stats-tile [header]="'dtmAdminLibDashboard.conversationStats.header' | transloco">
    <mat-list listSlot *ngIf="userConversationsStats$ | ngrxPush as conversationStats">
        <dtm-admin-lib-stats-list-item
            redirectLink="/conversation"
            [redirectQueryParams]="{ read: false, assignment: true }"
            iconsClassName="mail-unread"
            [statsName]="'dtmAdminLibDashboard.conversationStats.unreadAssignedCountLabel' | transloco"
            [statsCount]="conversationStats.unreadAssignedCount"
        ></dtm-admin-lib-stats-list-item>
        <dtm-admin-lib-stats-list-item
            redirectLink="/conversation"
            [redirectQueryParams]="{ assignment: true, closed: false }"
            iconsClassName="mail-open"
            [statsName]="'dtmAdminLibDashboard.conversationStats.openedAssignedCountLabel' | transloco"
            [statsCount]="conversationStats.openedAssignedCount"
        ></dtm-admin-lib-stats-list-item>
        <ng-container *ngrxLet="assignedCategories$; let assignedCategories">
            <dtm-admin-lib-stats-list-item
                *ngIf="assignedCategories?.length; else assignedCategoriesDisplayTemplate"
                redirectLink="/conversation"
                [redirectQueryParams]="{ assignment: false, categories: assignedCategories }"
                iconsClassName="mail-close"
                [statsName]="'dtmAdminLibDashboard.conversationStats.unassignedCountLabel' | transloco"
                [statsCount]="conversationStats.notAssignedInOwnCategoriesCount"
            ></dtm-admin-lib-stats-list-item>
            <ng-template #assignedCategoriesDisplayTemplate>
                <dtm-admin-lib-stats-list-item
                    redirectLink="/conversation"
                    [redirectQueryParams]="{ assignment: false }"
                    iconsClassName="mail-close"
                    [statsName]="'dtmAdminLibDashboard.conversationStats.unassignedCountLabel' | transloco"
                    [statsCount]="conversationStats.notAssignedInOwnCategoriesCount"
                ></dtm-admin-lib-stats-list-item>
            </ng-template>
        </ng-container>
    </mat-list>

    <ng-container bottomRedirectSlot>
        <button class="button-tertiary redirect-button" type="button" [routerLink]="['/conversation']">
            {{ "dtmAdminLibDashboard.conversationStats.showAllConversationsLabel" | transloco }}
        </button>
    </ng-container>
</dtm-admin-lib-stats-tile>
