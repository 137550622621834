<div class="grid">
    <mat-card>
        <dtm-ui-card-header [isEditButtonVisible]="false">
            {{ "dtmAdminLibOperationScenario.statementsList.statementsListHeader" | transloco }}
        </dtm-ui-card-header>
        <dtm-admin-lib-operation-scenario-statements-filters
            [initialFilters]="statementsFilters$ | ngrxPush"
            (filtersChange)="updateFiltersState($event)"
        ></dtm-admin-lib-operation-scenario-statements-filters>
        <dtm-admin-lib-operation-scenario-statements-list
            [operationScenarioStatementsList]="statementsList$ | ngrxPush"
            [pagination]="statementsPages$ | ngrxPush"
            [operationScenarioStatementsListError]="statementsError$ | ngrxPush"
            [isOperationScenarioProcessing]="areStatementsProcessing$ | ngrxPush"
            (pageChange)="statementsPageChange($event)"
            (statementsListRefresh)="refreshStatementsList()"
            (administrativeFeeDocumentDownload)="getStatementAdministrativeFeeDocument($event)"
            (signatureConfirmationFileGet)="getSignatureConfirmationFile($event)"
        ></dtm-admin-lib-operation-scenario-statements-list>
    </mat-card>
</div>
