import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { IdentityDocument, IdentityDocumentStatus } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface UtmUserIdentityDocumentComponentState {
    identityDocument: IdentityDocument | undefined;
    lastRequestForUpdate: Date | undefined;
}

@Component({
    selector: "dtm-admin-lib-utm-user-identity-document-tile",
    templateUrl: "./utm-user-identity-document-tile.component.html",
    styleUrls: ["./utm-user-identity-document-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UtmUserIdentityDocumentTileComponent {
    @Input() public set identityDocument(value: IdentityDocument | undefined) {
        this.localStore.patchState({
            identityDocument: value,
        });
    }

    @Input() public set lastRequestForUpdate(value: Date | undefined) {
        this.localStore.patchState({
            lastRequestForUpdate: value,
        });
    }

    @Output() public readonly accept = new EventEmitter<void>();
    @Output() public readonly reject = new EventEmitter<void>();
    @Output() public readonly download = new EventEmitter<void>();
    @Output() public readonly updateRequest = new EventEmitter<void>();

    protected readonly identityDocument$ = this.localStore.selectByKey("identityDocument");
    protected readonly lastRequestForUpdate$ = this.localStore.selectByKey("lastRequestForUpdate");
    protected readonly IdentityDocumentStatus = IdentityDocumentStatus;

    constructor(private readonly localStore: LocalComponentStore<UtmUserIdentityDocumentComponentState>) {
        localStore.setState({
            identityDocument: undefined,
            lastRequestForUpdate: undefined,
        });
    }
}
