import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Operator } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface OperatorInfoPanelComponentState {
    operator: Operator | undefined;
}

@Component({
    selector: "dtm-admin-lib-operator-info-panel[operator]",
    templateUrl: "./operator-info-panel.component.html",
    styleUrls: ["./operator-info-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorInfoPanelComponent {
    @Input() public set operator(value: Operator | undefined) {
        this.localStore.patchState({ operator: value });
    }

    protected readonly operator$ = this.localStore.selectByKey("operator");

    constructor(private readonly localStore: LocalComponentStore<OperatorInfoPanelComponentState>) {
        this.localStore.setState({
            operator: undefined,
        });
    }
}
