import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { UserProfileContainerComponent } from "./components/user-profile-container/user-profile.container.component";
import { UserProfileResolver } from "./services/user-profile.resolvers";

const routes: Routes = [
    {
        path: "user",
        component: UserProfileContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibUserProfile.routeTitles.userProfile",
        },
        resolve: {
            initData: UserProfileResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserProfileRoutingModule {}
