import { InjectionToken } from "@angular/core";

export interface ExamTrainingResultsEndpoints {
    getExamResultsList: string;
    getTrainingResultsList: string;
    manageExamTrainingResultsReportDetails: string;
    uploadExamTrainingResultsReportFile: string;
    downloadExamTrainingResultsReportFile: string;
    manageExamConfirmation: string;
    manageTrainingConfirmation: string;
}

export const EXAM_TRAINING_RESULTS_ENDPOINTS = new InjectionToken<ExamTrainingResultsEndpoints>("Exam training results endpoints");
