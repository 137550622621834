import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { GlobalCapabilities, GlobalCapabilitiesErrorType } from "../models/global-capabilities.models";
import { GLOBAL_CAPABILITIES_ENDPOINTS, GlobalCapabilitiesEndpoints } from "./global-capabilities.tokens";

@Injectable({
    providedIn: "root",
})
export class GlobalCapabilitiesApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(GLOBAL_CAPABILITIES_ENDPOINTS) private readonly endpoints: GlobalCapabilitiesEndpoints
    ) {}

    public getUserCapabilities(): Observable<GlobalCapabilities> {
        return this.httpClient
            .get<GlobalCapabilities>(this.endpoints.getGlobalCapabilities)
            .pipe(catchError(() => throwError(() => ({ type: GlobalCapabilitiesErrorType.CannotGetUserGlobalCapabilities }))));
    }
}
