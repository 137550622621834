import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { MessagesInThreadComponent } from "@dtm-frontend/shared/ui";
import { ConversationContainerComponent } from "./components/conversation-container/conversation-container.component";
import { MessagesComponent } from "./components/messages-container/messages.component";
import { ConversationResolver } from "./services/conversation.resolver";
import { MessagesListResolver } from "./services/messages-list.resolver";
import { MessagesResolver } from "./services/messages.resolver";

const routes: Routes = [
    {
        path: "conversation",
        component: ConversationContainerComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: ConversationResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibConversation.routeTitles.conversation",
        },
    },
    { path: "messages", pathMatch: "full", redirectTo: "conversation" },
    {
        path: "messages/:operatorId",
        component: MessagesComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: MessagesResolver,
        },
        data: {
            titleTranslationKey: "dtmAdminLibConversation.routeTitles.conversation",
        },
        children: [
            {
                path: ":threadId",
                component: MessagesInThreadComponent,
                resolve: { messages: MessagesListResolver },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ConversationResolver, MessagesResolver],
})
export class ConversationRoutingModule {}
