import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { PermitStatus } from "../../../models/permits.models";

interface PermitStatusComponentState {
    status: PermitStatus | undefined;
}

@Component({
    selector: "dtm-admin-lib-permit-status-badge[status]",
    templateUrl: "./permit-status-badge.component.html",
    styles: [".chip { white-space: nowrap }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PermitStatusBadgeComponent {
    @Input() public set status(value: PermitStatus | undefined) {
        this.localStore.patchState({ status: value });
    }

    protected readonly PermitStatus = PermitStatus;
    protected readonly status$ = this.localStore.selectByKey("status");

    constructor(private readonly localStore: LocalComponentStore<PermitStatusComponentState>) {
        localStore.setState({ status: undefined });
    }
}
