import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Params } from "@angular/router";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

interface StatsListItemComponentState {
    statsCount: number;
    statsName: string | undefined;
    iconsClassName: IconName;
    redirectLink: string | undefined;
    redirectQueryParams: Params | undefined;
}

const DEFAULT_ICON_CLASS: IconName = "information";

@Component({
    selector: "dtm-admin-lib-stats-list-item[statsCount][statsName][iconsClassName][redirectLink]",
    templateUrl: "./stats-list-item.component.html",
    styleUrls: ["./stats-list-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StatsListItemComponent {
    @Input() public set statsCount(value: number | undefined) {
        this.localStore.patchState({ statsCount: value ?? 0 });
    }

    @Input() public set statsName(value: string | undefined) {
        this.localStore.patchState({ statsName: value });
    }

    @Input() public set iconsClassName(value: IconName | undefined) {
        this.localStore.patchState({ iconsClassName: value ?? DEFAULT_ICON_CLASS });
    }

    @Input() public set redirectLink(value: string | undefined) {
        this.localStore.patchState({ redirectLink: value ?? DEFAULT_ICON_CLASS });
    }

    @Input() public set redirectQueryParams(value: Params | undefined) {
        this.localStore.patchState({ redirectQueryParams: value });
    }

    public readonly statsName$ = this.localStore.selectByKey("statsName");
    public readonly statsCount$ = this.localStore.selectByKey("statsCount");
    public readonly iconsClassName$ = this.localStore.selectByKey("iconsClassName");
    public readonly redirectLink$ = this.localStore.selectByKey("redirectLink").pipe(RxjsUtils.filterFalsy());
    public readonly redirectQueryParams$ = this.localStore.selectByKey("redirectQueryParams");

    constructor(private readonly localStore: LocalComponentStore<StatsListItemComponentState>) {
        localStore.setState({
            statsCount: 0,
            redirectQueryParams: undefined,
            statsName: undefined,
            iconsClassName: DEFAULT_ICON_CLASS,
            redirectLink: undefined,
        });
    }
}
