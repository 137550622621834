import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ConfirmationDialogComponent, DialogService, FILES_UPLOAD_API_PROVIDER, getAttachmentIdsList } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { first, switchMap } from "rxjs/operators";
import { OperatorDetails } from "../../../../shared";
import { ConversationsFilesApiService } from "../../../services/api/conversations-files-api.service";
import { ConversationActions } from "../../../state/conversation.actions";
import { ConversationState } from "../../../state/conversation.state";

interface NewThreadComponentState {
    isAttachmentsControlVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-new-thread",
    templateUrl: "./new-thread.component.html",
    styleUrls: ["./new-thread.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: ConversationsFilesApiService }],
})
export class NewThreadComponent implements OnInit {
    public readonly editorControl = new UntypedFormControl(null);
    public readonly categoryAndSubjectControl = new UntypedFormControl(null);

    public readonly conversationForm = new UntypedFormGroup({
        categoryAndSubject: this.categoryAndSubjectControl,
        editor: this.editorControl,
    });

    public readonly conversationCategories$ = this.store.select(ConversationState.conversationCategories);
    public readonly capabilitiesError$ = this.store.select(ConversationState.capabilitiesError);
    public readonly interlocutor$ = this.store.select(ConversationState.interlocutor).pipe(first());
    public readonly isAttachmentsControlVisible$ = this.localStore.selectByKey("isAttachmentsControlVisible");

    constructor(
        private readonly dialogService: DialogService,
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly localStore: LocalComponentStore<NewThreadComponentState>
    ) {
        this.localStore.setState({ isAttachmentsControlVisible: false });
    }

    public ngOnInit() {
        this.store.dispatch(new ConversationActions.GetConversationsCapabilities());
    }

    public refreshCapabilities() {
        this.store.dispatch(new ConversationActions.GetConversationsCapabilities());
    }

    public close() {
        if (this.conversationForm.touched) {
            this.confirmAndClose();

            return;
        }

        this.dialogService.closeAll();
    }

    public createThread(interlocutor: OperatorDetails) {
        this.conversationForm.markAllAsTouched();

        if (!this.conversationForm.valid) {
            return;
        }

        const { subject, category } = this.categoryAndSubjectControl.value;

        this.store
            .dispatch(
                new ConversationActions.CreateNewThread({
                    content: this.conversationForm.controls.editor.value.content,
                    attachmentIdsList: getAttachmentIdsList(this.conversationForm.controls.editor.value.attachments),
                    subject,
                    category,
                    recipient: {
                        owner: {
                            userId: interlocutor.owner.userId,
                            firstName: interlocutor.owner.firstName,
                            lastName: interlocutor.owner.lastName,
                        },
                        id: interlocutor.id,
                        name: interlocutor.name,
                    },
                })
            )
            .pipe(
                switchMap(() => this.store.select(ConversationState.sendMessageStatusSuccess)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((sendMessageStatusSuccess) =>
                sendMessageStatusSuccess ? this.handleSendMessageSuccess() : this.handleSendMessageError()
            );
    }

    protected changeAttachmentControlVisibility() {
        this.localStore.patchState({ isAttachmentsControlVisible: true });
    }

    private confirmAndClose() {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate("dtmAdminLibConversation.newMessage.declineMessageTitleText"),
                confirmationText: this.translocoService.translate("dtmAdminLibConversation.newMessage.declineMessageConfirmText"),
                declineButtonLabel: this.translocoService.translate("dtmAdminLibConversation.newMessage.declineMessageCancelLabel"),
                confirmButtonLabel: this.translocoService.translate("dtmAdminLibConversation.newMessage.declineMessageConfirmLabel"),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((isConfirmed) => {
                if (!isConfirmed) {
                    return;
                }
                this.dialogService.closeAll();
            });
    }

    private handleSendMessageSuccess() {
        this.toastrService.success(this.translocoService.translate("dtmAdminLibConversation.newMessage.messageSendSuccessMessage"));
        this.dialogService.closeAll();
    }

    private handleSendMessageError() {
        this.toastrService.error(this.translocoService.translate("dtmAdminLibConversation.newMessage.messageSendErrorMessage"));
    }
}
