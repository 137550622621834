<ng-container
    *ngrxLet="{
        waitingZones: waitingZones$,
        activeZonesMain: activeZonesMain$,
        activeZonesRest: activeZonesRest$,
        additionalZones: additionalZones$,
        doesAupExist: doesAupExist$,
        selectedZoneId: selectedZoneId$,
        authorityAcceptation: authorityAcceptation$,
        isProcessing: isProcessing$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$,
        formalJustification: formalJustification$,
        isExpanded: isExpanded$,
        isEmpty: isEmpty$,
        activeLanguageCode: activeLanguageCode$,
        areRulesExpanded: areRulesExpanded$,
        areMessagesVisible: areMessagesVisible$,
        missionContext: missionContext$,
        shouldDisplayMessageConfirmation: shouldDisplayMessageConfirmation$,
        missionPhase: missionPhase$,
        areZonesEmpty: areZonesEmpty$,
        airacEndTime: airacEndTime$,
        rejectionJustification: rejectionJustification$,
        rejectionDate: rejectionDate$
    } as vm"
>
    <dtm-ui-expandable-panel
        [hasHeaderSeparator]="false"
        [isExpanded]="vm.isExpanded && !vm.isEmpty"
        [class.no-data]="vm.isEmpty"
        [isDisabled]="vm.isEmpty"
        #mainPanel
    >
        <h2 class="main-title" headerSlot>
            <ng-container *ngIf="!vm.isEmpty; else noDataHeaderTemplate">
                <span class="spinner" *ngIf="vm.isProcessing && !mainPanel.expanded">
                    <mat-spinner diameter="20" color="accent"></mat-spinner>
                </span>
                <dtm-ui-icon
                    name="information"
                    *ngIf="
                        (vm.authorityAcceptation?.dtm?.comment || vm.authorityAcceptation?.utm?.comment) &&
                        !(vm.authorityAcceptation?.dtm?.isConfirmedByUser || vm.authorityAcceptation?.utm?.isConfirmedByUser)
                    "
                >
                </dtm-ui-icon>
                {{ "dtmSharedMission.flightRequirements.headerLabel" | transloco }}
            </ng-container>
            <ng-template #noDataHeaderTemplate>
                <dtm-ui-icon name="close-circle" class="empty-icon" />
                {{ "dtmSharedMission.flightRequirements.noDataLabel" | transloco }}
            </ng-template>
        </h2>
        <div class="flight-requirements-container" *ngIf="mainPanel.expanded">
            <dtm-ui-loader [isShown]="vm.isProcessing"></dtm-ui-loader>

            <h2
                class="section-title"
                headerSlot
                [innerHTML]="'dtmSharedMission.analysis.zone.activeZonesLabel' | transloco"
                *ngIf="vm.activeZonesMain.length || vm.activeZonesRest.length"
            ></h2>
            <div class="main-section">
                <dtm-mission-requirements-panel
                    *ngIf="vm.areZonesEmpty"
                    [flightRules]="vm.currentPlanAnalysisStatus?.flightRules"
                    [missionPhase]="vm.missionPhase"
                ></dtm-mission-requirements-panel>

                <ng-container
                    *ngTemplateOutlet="zonesInfoTemplate; context: { $implicit: vm.activeZonesMain, shouldHideRequirements: true }"
                ></ng-container>

                <dtm-mission-requirements-panel
                    [additionalRequirements]="vm.authorityAcceptation | invoke : getAdditionalRequirements : vm.missionPhase"
                    [analysisIssues]="vm.activeZonesMain | invoke : getAirspaceWarningsForZones : vm.currentPlanAnalysisStatus?.airspace"
                    [evaluationIssues]="
                        vm.activeZonesMain | invoke : getEvaluationIssuesForZones : vm.currentPlanAnalysisStatus?.evaluation
                    "
                    [flightConditions]="vm.activeZonesMain | invoke : getZonesFlightConditions : vm.activeLanguageCode"
                    [flightRules]="vm.currentPlanAnalysisStatus?.flightRules | invoke : getFlightRulesForZones : vm.activeZonesMain"
                    [missionPhase]="vm.missionPhase"
                    *ngIf="vm.activeZonesMain.length"
                >
                </dtm-mission-requirements-panel>
                <dtm-ui-expandable-panel
                    isExpanded
                    [hasHeaderSeparator]="false"
                    class="messages message-to-manager"
                    *ngIf="vm.rejectionJustification"
                >
                    <h2 class="sub-header" headerSlot>
                        <dtm-ui-icon name="mail-send" />
                        {{ "dtmSharedMission.flightRequirements.rejectionReasonLabel" | transloco }}
                    </h2>
                    <div class="message">
                        <p class="message-date">
                            {{ vm.rejectionDate | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
                        </p>
                        <p class="message-text">{{ vm.rejectionJustification }}</p>
                    </div>
                </dtm-ui-expandable-panel>
                <ng-container
                    *ngIf="
                        (vm.authorityAcceptation?.dtm || vm.authorityAcceptation?.utm) && vm.authorityAcceptation as authorityAcceptation
                    "
                >
                    <dtm-ui-expandable-panel
                        isExpanded
                        [hasHeaderSeparator]="false"
                        class="messages message-to-manager"
                        [class.unread]="!isMessageFromManagerReadingConfirmedFormControl.value"
                        *ngIf="authorityAcceptation.dtm?.comment || authorityAcceptation.utm?.comment"
                    >
                        <h2 class="sub-header" headerSlot>
                            <dtm-ui-icon name="mail-send" />
                            {{ "dtmSharedMission.flightRequirements.messageFromManagerTitle" | transloco }}
                        </h2>
                        <div class="message" *ngIf="authorityAcceptation.dtm?.comment">
                            <p class="message-date">
                                {{ authorityAcceptation.dtm?.updatedAt | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
                            </p>
                            <p class="message-text">{{ authorityAcceptation.dtm?.comment }}</p>
                        </div>
                        <div class="message" *ngIf="authorityAcceptation.utm?.comment">
                            <p class="message-date">
                                {{ authorityAcceptation.utm?.updatedAt | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
                            </p>
                            <p class="message-text">{{ authorityAcceptation.utm?.comment }}</p>
                        </div>
                        <dtm-ui-checkbox-field
                            *ngIf="vm.shouldDisplayMessageConfirmation"
                            [formControl]="isMessageFromManagerReadingConfirmedFormControl"
                            required
                        >
                            {{ "dtmSharedMission.flightRequirements.readingConfirmationLabel" | transloco }}
                        </dtm-ui-checkbox-field>
                    </dtm-ui-expandable-panel>
                </ng-container>

                <ng-container *ngTemplateOutlet="messageToManagerTemplate"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="zonesInfoTemplate; context: { $implicit: vm.activeZonesRest }"></ng-container>
            <ng-container *ngIf="vm.waitingZones.length">
                <h2 class="section-title" headerSlot [innerHTML]="'dtmSharedMission.analysis.zone.waitingZonesLabel' | transloco"></h2>
                <div class="info-container" *ngIf="!vm.doesAupExist">
                    <dtm-ui-icon name="information-fill"></dtm-ui-icon>
                    <p [innerHTML]="'dtmSharedMission.analysis.zone.waitingZonesInfoLabel' | transloco"></p>
                </div>
                <ng-container *ngTemplateOutlet="zonesInfoTemplate; context: { $implicit: vm.waitingZones }"></ng-container>
            </ng-container>

            <dtm-mission-additional-analysis-info
                [additionalZones]="vm.additionalZones"
                [selectedZoneId]="vm.selectedZoneId"
                [evaluation]="vm.currentPlanAnalysisStatus?.evaluation"
                [flightRules]="vm.currentPlanAnalysisStatus?.flightRules | invoke : getFlightRulesForZones : vm.additionalZones"
                [missionPhase]="vm.missionPhase"
                (zoneSelect)="zoneSelect.emit($event)"
            ></dtm-mission-additional-analysis-info>

            <ng-template #zonesInfoTemplate let-zones let-shouldHideRequirements="shouldHideRequirements">
                <dtm-mission-zone-info
                    *ngFor="let zone of zones"
                    [zone]="zone"
                    [analysisIssues]="zone | invoke : getAirspaceWarningsForZone : vm.currentPlanAnalysisStatus?.airspace"
                    [evaluationIssues]="zone | invoke : getEvaluationIssuesForZone : vm.currentPlanAnalysisStatus?.evaluation"
                    [isZoneSelected]="vm.selectedZoneId === zone.id"
                    [doesAupExist]="vm.doesAupExist"
                    [shouldHideRequirements]="shouldHideRequirements"
                    [additionalRequirements]="zone | invoke : getDssRequirements : vm.authorityAcceptation?.dss"
                    (zoneSelectionChange)="zoneSelect.emit($event)"
                    [flightRules]="vm.currentPlanAnalysisStatus?.flightRules | invoke : getFlightRulesForZones : [zone]"
                    [missionPhase]="vm.missionPhase"
                    [airacEndTime]="vm.airacEndTime"
                >
                    <dtm-ui-expandable-panel
                        isExpanded
                        [hasHeaderSeparator]="false"
                        class="messages message-to-manager"
                        *ngIf="
                            zone.supervisor === DYNAMIC_SAFETY_AND_SECURITY_SUPERVISOR_NAME &&
                            vm.authorityAcceptation?.dss?.comment &&
                            vm.authorityAcceptation as authorityAcceptation
                        "
                    >
                        <h2 class="sub-header" headerSlot>
                            <dtm-ui-icon name="mail-send" />
                            {{ "dtmSharedMission.flightRequirements.messageFromManagerTitle" | transloco }}
                        </h2>
                        <div class="message">
                            <p class="message-date">
                                {{ authorityAcceptation.dss?.updatedAt | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
                            </p>
                            <p class="message-text">{{ authorityAcceptation.dss?.comment }}</p>
                        </div>
                    </dtm-ui-expandable-panel>
                </dtm-mission-zone-info>
            </ng-template>
        </div>
    </dtm-ui-expandable-panel>
    <ng-container *ngIf="vm.isEmpty">
        <ng-container *ngTemplateOutlet="messageToManagerTemplate; context: { $implicit: true }"></ng-container>
    </ng-container>

    <ng-template #messageToManagerTemplate let-standalone>
        <dtm-ui-expandable-panel
            [isExpanded]="false"
            [hasHeaderSeparator]="false"
            [class.standalone]="standalone"
            class="messages"
            *ngIf="vm.areMessagesVisible && vm.formalJustification as formalJustification"
        >
            <h2 class="sub-header" headerSlot>
                <dtm-ui-icon name="mail-send" />
                {{ "dtmSharedMission.flightRequirements.messageToManagerTitle" | transloco : { value: vm.missionContext } }}
            </h2>
            <div class="message" *ngIf="formalJustification.reason as reason">
                <p class="message-text">{{ reason }}</p>
            </div>
            <dtm-ui-uploaded-files-display
                *ngIf="formalJustification.attachments?.length"
                [uploadedFiles]="formalJustification.attachments"
                [additionalPathParams]="{ planId: vm.currentPlanAnalysisStatus?.planId ?? '' }"
            >
                <label>{{ "dtmSharedMission.flightRequirements.attachmentsLabel" | transloco }}</label>
            </dtm-ui-uploaded-files-display>
        </dtm-ui-expandable-panel>
    </ng-template>
</ng-container>
