import {
    AssociationOperator,
    CaaContactPerson,
    Competency,
    DtmLocations,
    OperationalArc,
    Operator,
    PAGE_NUMBER_QUERY_PARAM,
    PAGE_SIZE_QUERY_PARAM,
    PermitType,
    RiskAssessmentReference,
    Sail,
} from "@dtm-frontend/shared/ui";
import { Page } from "../../operator/services/operator.models";

const filterNumericValues = <T extends string, TEnumValue extends number>(type: { [key in T]: TEnumValue }): number[] =>
    Object.values(type).filter((value) => !isNaN(Number(value))) as number[];

export enum PermitsFilterFormKeys {
    SearchByText = "searchByText",
    Status = "status",
    DateFrom = "dateFrom",
    DateTo = "dateTo",
}

export enum FiltersTabKeys {
    SpecificPermitsFilters = "specificPermitsFilters",
    AssociationFilters = "associationFilters",
    CrossBorderFilters = "crossBorderFilters",
}

export enum PermitStatus {
    Suspended = "SUSPENDED",
    Withdrawn = "WITHDRAWN",
    Active = "ACTIVE",
    Waiting = "WAITING",
}

export enum PermitsErrorType {
    Unknown = "Unknown",
    CannotGetCapabilities = "CannotGetCapabilities",
    CannotGetOperatorList = "CannotGetOperatorList",
    CannotSavePermit = "CannotSavePermit",
    CannotGetPermitDetails = "CannotGetPermitDetails",
    CannotUpdatePermit = "CannotUpdatePermit",
    CannotGetKmlFile = "CannotGetKmlFile",
    ForbiddenAssuranceAndIntegrityLevel = "ForbiddenAssuranceAndIntegrityLevel",
}

export const sailLevels = filterNumericValues(Sail);
export const operationArcTypes = filterNumericValues(OperationalArc);

export interface PermitsError {
    type: PermitsErrorType;
}

export interface PermitFilterParams {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    activeTab?: PermitsTab;
    searchByText?: string;
    status?: PermitStatus[] | null;
    dateFrom?: Date | null;
    dateTo?: Date | null;
    activeTabIndex?: number;
}
export interface PermitFilterQueryParams extends Omit<PermitFilterParams, "status"> {
    status?: string | null;
}

export interface SpecificPermit {
    id: string;
    operatorId: string;
    status: PermitStatus;
    createdAt: Date;
    consentNumber: string;
    operatorName: string;
    validityPeriod: string;
    permitName: string;
    flightPurposes: string;
    statusReason: string;
    riskAssessmentReference: RiskAssessmentReference;
    pdraNumber?: string;
    type: PermitType;
}

export type CrossBorderPermit = SpecificPermit;

export interface CrossBorderPermitListWithPages extends Page {
    content: CrossBorderPermit[];
}

export interface SpecificPermitListWithPages extends Page {
    content: SpecificPermit[];
}

export interface SpecificPermitsCapabilities {
    dtmLocations: DtmLocations[];
    competencies: Competency[];
    caaUsers: CaaContactPerson[];
}

export type PermitActionMap = { [k in string]: PermitActionType[] };
export enum PermitActionType {
    Withdraw = "Withdraw",
    Suspend = "Suspend",
    Restore = "Restore",
}

export interface PermitStatusChange {
    action: PermitActionType;
    permitId: string;
}

export type OperatorAssociatedOrEnterprise = Operator | AssociationOperator;

export enum PermitsTab {
    SpecificPermit = 0,
    Association = 1,
    CrossBorder = 2,
}
