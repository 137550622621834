import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DisplayCountryService, FilterType, FiltersMap, OperatorStatus } from "@dtm-frontend/shared/ui";
import { DEFAULT_COUNTRY_CODE, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { firstValueFrom } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { AttorneyPowerStatus, NULL_VALUE, OperatorType, SINGLE_SELECT_VALUES } from "../../../shared";
import { OperatorFilterFormKeys, OperatorFilters } from "../../services/operator.models";

export const MAX_SEARCH_TEXT_LENGTH = 50;
export const MIN_SEARCH_TEXT_LENGTH = 3;

interface FiltersComponentState {
    initialFilters: OperatorFilters | undefined;
    appliedFiltersLength: number | undefined;
    filtersMap: FiltersMap[];
}

interface FiltersForm {
    [OperatorFilterFormKeys.SearchByText]: FormControl<string>;
    [OperatorFilterFormKeys.Type]: FormControl<OperatorType | typeof NULL_VALUE>;
    [OperatorFilterFormKeys.IsDefaultCountryRegistration]: FormControl<string | typeof NULL_VALUE>;
    [OperatorFilterFormKeys.IsWaitingCompetencyConfirmation]: FormControl<boolean | typeof NULL_VALUE>;
    [OperatorFilterFormKeys.Status]: FormControl<OperatorStatus[]>;
    [OperatorFilterFormKeys.PowerOfAttorneyStatus]: FormControl<AttorneyPowerStatus | typeof NULL_VALUE>;
}

const INITIAL_FILTERS_MAP: FiltersMap[] = [
    {
        key: OperatorFilterFormKeys.SearchByText,
        filterLabel: "dtmAdminLibOperator.operatorFilters.searchByTextLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: OperatorFilterFormKeys.Type,
        filterLabel: "dtmAdminLibOperator.operatorFilters.typeLabel",
        filterValueLabel: "dtmAdminLibOperator.operatorFilters.typeValueLabel",
    },
    {
        key: OperatorFilterFormKeys.IsDefaultCountryRegistration,
        filterLabel: "dtmAdminLibOperator.operatorFilters.isDefaultCountryRegistrationLabel",
        filterValueLabel: "dtmAdminLibOperator.operatorFilters.isDefaultCountryRegistrationValueLabel",
    },
    {
        key: OperatorFilterFormKeys.Status,
        filterLabel: "dtmAdminLibOperator.operatorFilters.statusLabel",
        filterValueLabel: "dtmAdminLibOperator.operatorFilters.statusValueLabel",
    },
    {
        key: OperatorFilterFormKeys.PowerOfAttorneyStatus,
        filterLabel: "dtmAdminLibOperator.operatorFilters.powerOfAttorneyStatusLabel",
        filterValueLabel: "dtmAdminLibOperator.operatorFilters.powerOfAttorneyStatusValueLabel",
    },
    {
        key: OperatorFilterFormKeys.IsWaitingCompetencyConfirmation,
        filterLabel: "dtmAdminLibOperator.operatorFilters.isWaitingCompetencyConfirmationLabel",
        filterValueLabel: "dtmAdminLibOperator.operatorFilters.isWaitingCompetencyConfirmationValueLabel",
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-filters",
    templateUrl: "./filters.component.html",
    styleUrls: ["./filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FiltersComponent implements OnInit {
    @Input()
    public set initialFilters(value: OperatorFilters) {
        this.localStore.patchState({ initialFilters: value });
    }

    @Output() public filtersChange: EventEmitter<OperatorFilters> = new EventEmitter();

    protected readonly MIN_SEARCH_TEXT_LENGTH = MIN_SEARCH_TEXT_LENGTH;
    protected readonly MAX_SEARCH_TEXT_LENGTH = MAX_SEARCH_TEXT_LENGTH;
    protected readonly filtersForm = new FormGroup<FiltersForm>({
        [OperatorFilterFormKeys.SearchByText]: new FormControl<string>("", {
            validators: [Validators.minLength(MIN_SEARCH_TEXT_LENGTH), Validators.maxLength(MAX_SEARCH_TEXT_LENGTH)],
            nonNullable: true,
        }),
        [OperatorFilterFormKeys.Type]: new FormControl<OperatorType | typeof NULL_VALUE>(NULL_VALUE, {
            nonNullable: true,
        }),
        [OperatorFilterFormKeys.IsDefaultCountryRegistration]: new FormControl<string | typeof NULL_VALUE>(NULL_VALUE, {
            nonNullable: true,
        }),
        [OperatorFilterFormKeys.Status]: new FormControl<OperatorStatus[]>([], {
            nonNullable: true,
        }),
        [OperatorFilterFormKeys.PowerOfAttorneyStatus]: new FormControl<AttorneyPowerStatus | typeof NULL_VALUE>(NULL_VALUE, {
            nonNullable: true,
        }),
        [OperatorFilterFormKeys.IsWaitingCompetencyConfirmation]: new FormControl<boolean | typeof NULL_VALUE>(NULL_VALUE, {
            nonNullable: true,
        }),
    });
    protected readonly appliedFiltersLength$ = this.localStore.selectByKey("appliedFiltersLength");
    protected readonly filtersMap$ = this.localStore.selectByKey("filtersMap");
    protected readonly availableStatuses = Object.values(OperatorStatus);
    protected readonly availableTypes = Object.values(OperatorType);
    protected readonly availablePowerOfAttorneyStatuses = Object.values(AttorneyPowerStatus);
    protected readonly INITIAL_FILTERS_MAP = INITIAL_FILTERS_MAP;
    protected readonly SINGLE_SELECT_VALUES = SINGLE_SELECT_VALUES;
    protected readonly NULL_VALUE = NULL_VALUE;
    protected readonly defaultCountry = this.displayCountryService.getCountryByCountryCode(DEFAULT_COUNTRY_CODE)?.displayName;

    constructor(
        private readonly localStore: LocalComponentStore<FiltersComponentState>,
        private readonly displayCountryService: DisplayCountryService
    ) {
        this.localStore.setState({
            appliedFiltersLength: undefined,
            initialFilters: undefined,
            filtersMap: this.getUpdatedFiltersMap(this.INITIAL_FILTERS_MAP, this.defaultCountry),
        });
    }

    public ngOnInit() {
        this.assignInitialFiltersAndWatchForm();
    }

    public applyFilters() {
        this.filtersChange.emit(this.filtersForm.getRawValue());
    }

    public clearFilters() {
        this.filtersForm.reset();
    }

    private async assignInitialFiltersAndWatchForm() {
        const initialFilters = await firstValueFrom(
            this.localStore.selectByKey("initialFilters").pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
        );

        this.filtersForm.setValue({
            [OperatorFilterFormKeys.SearchByText]: initialFilters.searchByText ?? "",
            [OperatorFilterFormKeys.Type]: initialFilters.type ?? NULL_VALUE,
            [OperatorFilterFormKeys.IsDefaultCountryRegistration]: initialFilters.isDefaultCountryRegistration ?? NULL_VALUE,
            [OperatorFilterFormKeys.Status]: initialFilters.status ?? [],
            [OperatorFilterFormKeys.PowerOfAttorneyStatus]: initialFilters.powerOfAttorneyStatus ?? NULL_VALUE,
            [OperatorFilterFormKeys.IsWaitingCompetencyConfirmation]: initialFilters.isWaitingCompetencyConfirmation ?? NULL_VALUE,
        });
        this.localStore.patchState({ appliedFiltersLength: Object.values(initialFilters).flat().filter(Boolean).length });

        this.filtersForm.valueChanges.pipe(untilDestroyed(this)).subscribe((filters) => {
            if (this.filtersForm.invalid) {
                return;
            }

            this.filtersForm.controls.isDefaultCountryRegistration.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    filter((value) => value === null),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    this.filtersForm.controls.isDefaultCountryRegistration.setValue(NULL_VALUE);
                });

            this.filtersForm.controls.type.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    filter((value) => value === null),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    this.filtersForm.controls.type.setValue(NULL_VALUE);
                });

            this.filtersForm.controls.isWaitingCompetencyConfirmation.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    filter((value) => value === null),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    this.filtersForm.controls.isWaitingCompetencyConfirmation.setValue(NULL_VALUE);
                });

            this.localStore.patchState({ appliedFiltersLength: Object.values(filters).flat().filter(Boolean).length });
            this.applyFilters();
        });
    }

    private getUpdatedFiltersMap(initialFiltersMap: FiltersMap[], defaultCountry: string | undefined): FiltersMap[] {
        const filtersMap = [...initialFiltersMap];
        const isDefaultCountryFilter = filtersMap.find(
            (filterItem) => filterItem.key === OperatorFilterFormKeys.IsDefaultCountryRegistration
        );
        if (isDefaultCountryFilter) {
            isDefaultCountryFilter.filterValueLabelParam = defaultCountry;
        }

        return filtersMap;
    }
}
