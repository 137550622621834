import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { LegalGuardianConfirmation } from "../../../shared/models/operator.models";

interface UtmUserLegalGuardianDetailsRequiredTileComponentState {
    legalGuardianConfirmation: LegalGuardianConfirmation | undefined;
    lastRequestForUpdate: Date | undefined;
}

@Component({
    selector: "dtm-admin-lib-utm-user-legal-guardian-details-required-tile",
    templateUrl: "./utm-user-legal-guardian-details-required-tile.component.html",
    styleUrls: ["./utm-user-legal-guardian-details-required-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UtmUserLegalGuardianDetailsRequiredTileComponent {
    @Input() public set legalGuardianConfirmation(value: LegalGuardianConfirmation | undefined) {
        this.localStore.patchState({ legalGuardianConfirmation: value });
    }
    @Input() public set lastRequestForUpdate(value: Date | undefined) {
        this.localStore.patchState({
            lastRequestForUpdate: value,
        });
    }

    @Output() public readonly detailsFill = new EventEmitter<void>();
    @Output() public readonly updateRequest = new EventEmitter<void>();

    public readonly legalGuardianConfirmation$ = this.localStore.selectByKey("legalGuardianConfirmation");
    public readonly lastRequestForUpdate$ = this.localStore.selectByKey("lastRequestForUpdate");

    constructor(private readonly localStore: LocalComponentStore<UtmUserLegalGuardianDetailsRequiredTileComponentState>) {
        localStore.setState({ legalGuardianConfirmation: undefined, lastRequestForUpdate: undefined });
    }
}
