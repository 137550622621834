import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OperatorStatus } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { AttorneyPowerStatus } from "../../../shared";
import { OperatorsStats } from "../../services/dashboard.model";

interface OperatorStatusTileComponentState {
    operatorStats: OperatorsStats | undefined;
}

@Component({
    selector: "dtm-admin-lib-operator-status-tile",
    templateUrl: "./operator-status-tile.component.html",
    styleUrls: ["./operator-status-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorStatusTileComponent {
    @Input() public set operatorStats(value: OperatorsStats | undefined) {
        this.localStore.patchState({ operatorStats: value });
    }

    protected readonly operatorStats$ = this.localStore.selectByKey("operatorStats").pipe(RxjsUtils.filterFalsy());
    protected readonly OperatorStatus = OperatorStatus;
    protected readonly AttorneyPowerStatus = AttorneyPowerStatus;

    constructor(private readonly localStore: LocalComponentStore<OperatorStatusTileComponentState>) {
        localStore.setState({ operatorStats: undefined });
    }
}
