<div class="filters-wrapper">
    <dtm-ui-filters-container>
        <div class="filters-container" [formGroup]="centersFiltersForm">
            <dtm-ui-input-field class="control-search-text">
                <input
                    matInput
                    [formControlName]="CentersFilterFormKeys.SearchPhrase"
                    type="text"
                    [placeholder]="'dtmAdminLibCenters.filters.searchPhrasePlaceholder' | transloco"
                />
                <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
            </dtm-ui-input-field>
        </div>
    </dtm-ui-filters-container>
    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [formGroup]="centersFiltersForm"
        [filtersValue]="centersFiltersForm.value"
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="appliedFiltersLength$ | ngrxPush"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</div>
