import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { switchMap } from "rxjs/operators";
import { PermitsActions } from "../state/permits.actions";

@Injectable()
export class SpecificPermitWizardResolver {
    constructor(private readonly store: Store) {}

    public async resolve(): Promise<void> {
        await lastValueFrom(this.store.dispatch(PermitsActions.GetSpecificPermitsCapabilities));
    }
}

@Injectable()
export class SpecificPermitDetailsResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        await lastValueFrom(
            this.store
                .dispatch(new PermitsActions.GetSpecificPermitsCapabilities())
                .pipe(switchMap(() => this.store.dispatch(new PermitsActions.GetSpecificPermitDetails(route.params.id))))
        );
    }
}

@Injectable()
export class CrossBorderPermitWizardResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        await lastValueFrom(this.store.dispatch(new PermitsActions.GetSpecificPermitsCapabilities()));
    }
}

@Injectable()
export class CrossBorderPermitDetailsResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        await lastValueFrom(
            this.store
                .dispatch(new PermitsActions.GetSpecificPermitsCapabilities())
                .pipe(switchMap(() => this.store.dispatch(new PermitsActions.GetCrossBorderSpecificPermitDetails(route.params.id))))
        );
    }
}

@Injectable()
export class AssociationPermitDetailsResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        await lastValueFrom(
            this.store
                .dispatch(new PermitsActions.GetSpecificPermitsCapabilities())
                .pipe(switchMap(() => this.store.dispatch(new PermitsActions.GetAssociationPermitDetails(route.params.id))))
        );
    }
}

@Injectable()
export class AssociationPermitWizardResolver {
    constructor(private readonly store: Store) {}

    public async resolve(): Promise<void> {
        await lastValueFrom(this.store.dispatch(PermitsActions.GetSpecificPermitsCapabilities));
    }
}
