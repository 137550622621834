import { Address, Alpha3CountryCode, ConversationCategoryCode, PhoneNumber, UserContactErrorType } from "@dtm-frontend/shared/ui";
import { LegalGuardianConfirmation } from "../../shared/models/operator.models";

export enum UserProfileErrorType {
    Unknown = "UserProfileErrorErrorUnknown",
    NotFound = "UserProfileErrorErrorNotFound",
    NotAuthorized = "UserProfileErrorNotAuthorized",
    CannotGetProfileCapabilities = "CannotGetProfileCapabilities",
    CannotSaveConversationCategories = "CannotSaveConversationCategories",
    CannotSavePersonalData = "CannotSavePersonalData",
    CannotUpdateProfileLanguage = "CannotUpdateProfileLanguage",
    CannotResetUserPassword = "CannotResetUserPassword",
    CannotSaveUserAvatar = "CannotSaveUserAvatar",
    CannotDeleteUserAvatar = "CannotDeleteUserAvatar",
}

export interface AvailableAndAssignedConversationCategories {
    categories: ConversationCategory[];
    assignedCategories: ConversationCategory[];
}

export interface UserProfileError {
    type: UserProfileErrorType | UserContactErrorType;
}

export interface LegalGuardian extends LegalGuardianConfirmation {
    phoneNumber: PhoneNumber;
    address: Address;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    nationality: Alpha3CountryCode;
    phoneNumber: PhoneNumber;
    languageTag: string;
    email: string;
    isEasaMember: boolean;
    legalGuardian: LegalGuardian;
    legalGuardianConfirmation: LegalGuardianConfirmation;
    avatarId?: string;
}

export interface ConversationCategory {
    id: string;
    name: string;
    code: ConversationCategoryCode;
}

export interface PersonalData {
    firstName: string;
    lastName: string;
}
