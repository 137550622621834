import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { UtmUsersContainerComponent } from "./components/utm-users-container/utm-users-container.component";

const routes: Routes = [
    {
        path: "utm-users",
        component: UtmUsersContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibUtmUsers.routeTitles.userList",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UtmUsersRoutingModule {}
