<div *ngIf="user$ | ngrxPush as user" class="main-container">
    <div class="user-header">
        <div class="header-name">
            <p class="header-label">{{ "dtmAdminLibUtmUsers.userProfile.userLabel" | transloco }}</p>
            <h5>{{ user.basicData.firstName }} {{ user.basicData.lastName }}</h5>
        </div>
        <dtm-admin-lib-utm-user-status-badge [status]="user.status"></dtm-admin-lib-utm-user-status-badge>
    </div>
    <div class="user-content">
        <dtm-admin-lib-utm-user-basic-data-tile
            [basicData]="user.basicData"
            (basicDataEdit)="basicDataEdit.emit()"
        ></dtm-admin-lib-utm-user-basic-data-tile>
        <dtm-admin-lib-utm-user-identity-document-tile
            *ngIf="!user.isEasaMember"
            [identityDocument]="user.identityDocument"
            [lastRequestForUpdate]="user.identityDocumentCompletionRequestDate"
            (download)="documentDownload.emit()"
            (accept)="documentAccept.emit()"
            (reject)="documentReject.emit()"
            (updateRequest)="documentUpdateRequest.emit()"
        ></dtm-admin-lib-utm-user-identity-document-tile>
        <ng-container *ngIf="!user.isLegalAge">
            <dtm-admin-lib-legal-guardian-tile
                *ngIf="user.legalGuardian; else legalGuardianRequiredTemplate"
                [legalGuardian]="user.legalGuardian"
                [isActionMenuVisible]="true"
                (legalGuardianDetailsEdit)="legalGuardianDetailsEdit.emit()"
                (legalGuardianDelete)="legalGuardianDelete.emit()"
                (legalGuardianSignedDocumentDownload)="legalGuardianSignedDocumentDownload.emit()"
            >
            </dtm-admin-lib-legal-guardian-tile>
            <ng-template #legalGuardianRequiredTemplate>
                <dtm-admin-lib-utm-user-legal-guardian-details-required-tile
                    [legalGuardianConfirmation]="user.legalGuardianConfirmationRequest"
                    [lastRequestForUpdate]="user.legalGuardianCompletionRequestDate"
                    (detailsFill)="legalGuardianDetailsFill.emit()"
                    (updateRequest)="legalGuardianUpdateRequest.emit()"
                >
                </dtm-admin-lib-utm-user-legal-guardian-details-required-tile>
            </ng-template>
        </ng-container>
        <dtm-admin-lib-change-log-tile [type]="ChangeLogType.User" [identifier]="user.id"></dtm-admin-lib-change-log-tile>
    </div>
</div>
