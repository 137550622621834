import { InjectionToken } from "@angular/core";

export interface OperatorEndpoints {
    getCapabilities: string;
    getOperatorList: string;
    getAvailableOperationScenarios: string;
    addOperationalAuthorization: string;
    manageOperationalAuthorization: string;
    changeOperatorStatus: string;
    getPersonalOperatorDetails: string;
    getEnterpriseOperatorDetails: string;
    getAssociationOperatorDetails: string;
    getAttorneyPowerDocument: string;
    getAdministrativeFeeDocument: string;
    updateAttorneyPower: string;
    updateAttorneyPowerPermissions: string;
    addOtherInformation: string;
    manageOtherInformation: string;
    manageOperator: string;
    getForeignCompetencyDocument: string;
    updateForeignCompetencyStatus: string;
}

export const OPERATOR_ENDPOINTS = new InjectionToken<OperatorEndpoints>("Operator endpoints");
