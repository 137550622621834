<dtm-ui-filters-container>
    <form class="filters-container" [formGroup]="filtersForm" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
        <dtm-ui-input-field class="control-type">
            <label>{{ "dtmAdminLibAudit.changeLogFilters.typeLabel" | transloco }}</label>
            <input matInput formControlName="type" type="text" />
            <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
        </dtm-ui-input-field>
        <dtm-ui-input-field class="control-identifier">
            <label>{{ "dtmAdminLibAudit.changeLogFilters.identifierLabel" | transloco }}</label>
            <input matInput formControlName="identifier" type="text" />
            <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
        </dtm-ui-input-field>
        <dtm-ui-input-field class="control-action-name">
            <label>{{ "dtmAdminLibAudit.changeLogFilters.actionNameLabel" | transloco }}</label>
            <input matInput formControlName="actionName" type="text" />
            <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
        </dtm-ui-input-field>
        <dtm-ui-date-field class="control-date-from">
            <label for="dateFrom">
                {{ "dtmAdminLibAudit.changeLogFilters.dateFromLabel" | transloco }}
            </label>
            <input
                id="dateFrom"
                formControlName="dateFrom"
                matInput
                [placeholder]="datePickerPlaceholder"
                [matDatepicker]="dateFromDatepicker"
            />
            <mat-datepicker #dateFromDatepicker></mat-datepicker>
        </dtm-ui-date-field>
        <dtm-ui-date-field class="control-date-to">
            <label for="dateTo">
                {{ "dtmAdminLibAudit.changeLogFilters.dateToLabel" | transloco }}
            </label>
            <input id="dateTo" formControlName="dateTo" matInput [placeholder]="datePickerPlaceholder" [matDatepicker]="dateToDatepicker" />
            <mat-datepicker #dateToDatepicker></mat-datepicker>
        </dtm-ui-date-field>
    </form>
    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="appliedFiltersLength$ | ngrxPush"
        [formGroup]="filtersForm"
        [filtersValue]="filtersForm.value"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</dtm-ui-filters-container>
