<ng-container *ngrxLet="isCollapsed$; let isCollapsed">
    <ng-template #menuItemTemplate let-label="label" let-icon="icon" let-link="link">
        <a
            [routerLink]="link"
            routerLinkActive="active-route"
            [matTooltip]="label"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
            (click)="collapseMenu()"
        >
            <dtm-ui-icon [name]="icon"></dtm-ui-icon>
            <span class="label">{{ label }}</span>
        </a>
    </ng-template>

    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.dashboardMenuItemLabel' | transloco,
                    icon: 'dashboard',
                    link: '/dashboard'
                }"
            ></ng-template>
        </li>
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.operatorsMenuItemLabel' | transloco,
                    icon: 'building',
                    link: '/operators'
                }"
            ></ng-template>
        </li>
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.conversationMenuItemLabel' | transloco,
                    icon: 'mail',
                    link: '/conversation'
                }"
            ></ng-template>
        </li>
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.userProfileMenuItemLabel' | transloco,
                    icon: 'profile',
                    link: '/user'
                }"
            ></ng-template>
        </li>
    </ul>

    <h4
        class="menu-section-header"
        [matTooltip]="'caaAdmin.menu.usersHeader' | transloco"
        matTooltipPosition="right"
        [matTooltipDisabled]="!isCollapsed"
    >
        {{ "caaAdmin.menu.usersHeader" | transloco }}
    </h4>
    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.utmUsersProfileMenuItemLabel' | transloco,
                    icon: 'group',
                    link: '/utm-users'
                }"
            ></ng-template>
        </li>
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.adminUsersListMenuItemLabel' | transloco,
                    icon: 'user-settings',
                    link: '/admin-users'
                }"
            ></ng-template>
        </li>
    </ul>
    <h4
        class="menu-section-header"
        [matTooltip]="'caaAdmin.menu.authorizationsHeader' | transloco"
        matTooltipPosition="right"
        [matTooltipDisabled]="!isCollapsed"
    >
        {{ "caaAdmin.menu.authorizationsHeader" | transloco }}
    </h4>
    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.operationScenarioMenuItemLabel' | transloco,
                    icon: 'draft',
                    link: '/operation-scenario'
                }"
            ></ng-template>
        </li>
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.permitsMenuItemLabel' | transloco,
                    icon: 'draft',
                    link: '/permits'
                }"
            ></ng-template>
        </li>
    </ul>

    <h4
        class="menu-section-header"
        [matTooltip]="'caaAdmin.menu.trainingsAndExamsHeader' | transloco"
        matTooltipPosition="right"
        [matTooltipDisabled]="!isCollapsed"
    >
        {{ "caaAdmin.menu.trainingsAndExamsHeader" | transloco }}
    </h4>
    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.centersItemLabel' | transloco,
                    icon: 'school',
                    link: '/centers'
                }"
            ></ng-template>
        </li>
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.examTrainingResultsItemLabel' | transloco,
                    icon: 'student',
                    link: '/exam-training-results'
                }"
            ></ng-template>
        </li>
    </ul>
    <ng-container *ngIf="isMissionSearchFeatureAvailable$ | ngrxPush">
        <h4
            class="menu-section-header"
            [matTooltip]="'caaAdmin.menu.missionsHeader' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
        >
            {{ "caaAdmin.menu.missionsHeader" | transloco }}
        </h4>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'caaAdmin.menu.missionSearchItemLabel' | transloco,
                        icon: 'search',
                        link: '/mission-search'
                    }"
                ></ng-template>
            </li>
        </ul>
    </ng-container>
    <h4
        class="menu-section-header"
        [matTooltip]="'caaAdmin.menu.mapsHeader' | transloco"
        matTooltipPosition="right"
        [matTooltipDisabled]="!isCollapsed"
    >
        {{ "caaAdmin.menu.mapsHeader" | transloco }}
    </h4>
    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.areaMapItemLabel' | transloco,
                    icon: 'treasure-map',
                    link: '/area-map'
                }"
            ></ng-template>
        </li>
    </ul>
    <h4
        class="menu-section-header"
        [matTooltip]="'caaAdmin.menu.auditHeader' | transloco"
        matTooltipPosition="right"
        [matTooltipDisabled]="!isCollapsed"
    >
        {{ "caaAdmin.menu.auditHeader" | transloco }}
    </h4>
    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'caaAdmin.menu.changeLogItemLabel' | transloco,
                    icon: 'file-edit',
                    link: '/change-log'
                }"
            ></ng-template>
        </li>
    </ul>
</ng-container>
