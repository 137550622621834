import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { RouterLink } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { OperationScenarioStatementsContainerComponent } from "./components/operation-scenario-statements-container/operation-scenario-statements-container.component";
import { OperationScenarioStatementsFiltersComponent } from "./components/operation-scenario-statements-filters/operation-scenario-statements-filters.component";
import { OperationScenarioStatementsListComponent } from "./components/operation-scenario-statements-list/operation-scenario-statements-list.component";
import { OPERATION_SCENARIO_STATEMENTS_ENDPOINTS } from "./operation-scenario-statements.tokens";
import { OperationScenarioStatementConfirmationDocumentsApiService } from "./services/operation-scenario-statement-confirmation-documents-api.service";
import { OperationScenarioStatementsApiService } from "./services/operation-scenario-statements-api.service";
import { OperationScenarioStatementsState } from "./state/operation-scenario-statements.state";

@NgModule({
    declarations: [
        OperationScenarioStatementsContainerComponent,
        OperationScenarioStatementsListComponent,
        OperationScenarioStatementsFiltersComponent,
    ],
    imports: [
        NgxsModule.forFeature([OperationScenarioStatementsState]),
        CommonModule,
        MatCardModule,
        MatMenuModule,
        SharedUiModule,
        MatInputModule,
        ReactiveFormsModule,
        PushModule,
        LetModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        SharedI18nModule,
        RouterLink,
    ],
    exports: [
        OperationScenarioStatementsContainerComponent,
        OperationScenarioStatementsListComponent,
        OperationScenarioStatementsFiltersComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibOperationScenario",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: OperationScenarioStatementsApiService,
            useValue: undefined,
        },
        OperationScenarioStatementConfirmationDocumentsApiService,
    ],
})
export class OperationScenarioStatementsModule {
    public static forRoot(): ModuleWithProviders<OperationScenarioStatementsModule> {
        return {
            ngModule: OperationScenarioStatementsModule,
            providers: [
                {
                    provide: OperationScenarioStatementsApiService,
                    useClass: OperationScenarioStatementsApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<OperationScenarioStatementsModule> {
        return {
            ngModule: OperationScenarioStatementsModule,
            providers: [
                {
                    provide: OperationScenarioStatementsApiService,
                    useValue: null,
                },
                { provide: OperationScenarioStatementConfirmationDocumentsApiService, useValue: null },
                {
                    provide: OPERATION_SCENARIO_STATEMENTS_ENDPOINTS,
                    useValue: {},
                },
            ],
        };
    }
}
