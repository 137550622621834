import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import {
    ErrorMode,
    FILES_UPLOAD_API_PROVIDER,
    PhoneNumber,
    UIState,
    UserContactError,
    UserContactErrorType,
} from "@dtm-frontend/shared/ui";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { EMPTY, Observable } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";
import { UserAvatarUploadApiService } from "../../services/user-avatar-upload-api.service";
import { PersonalData, UserProfileError } from "../../services/user-profile.models";
import { UserProfileActions } from "../../state/user-profile.actions";
import { UserProfileState } from "../../state/user-profile.state";
import { getI18nUpdateProfileError } from "../../utils/update-error.translations";

enum NotificationTypes {
    SavePhoneSuccess = "SavePhoneSuccess",
    SaveEmailSuccess = "SaveEmailSuccess",
    SaveConversationsSuccess = "SaveConversationsSuccess",
    SaveNewPersonalDataSuccess = "SaveNewPersonalDataSuccess",
    UpdateProfileLanguageSuccess = "UpdateProfileLanguageSuccess",
    SaveProfileAvatarSuccess = "SaveProfileAvatarSuccess",
    DeleteProfileAvatarSuccess = "DeleteProfileAvatarSuccess",
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-user-profile-container",
    templateUrl: "./user-profile-container.component.html",
    styleUrls: ["./user-profile-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: FILES_UPLOAD_API_PROVIDER, useClass: UserAvatarUploadApiService }],
})
export class UserProfileContainerComponent {
    protected readonly userProfile$ = this.store.select(UserProfileState.user);
    protected readonly error$ = this.store.select(UserProfileState.userProfileError);
    protected readonly isProcessing$ = this.store.select(UserProfileState.isProcessing);
    protected readonly isPhoneNumberEditMode$ = this.store.select(UserProfileState.isPhoneNumberEditMode);
    protected readonly isEmailAddressEditMode$ = this.store.select(UserProfileState.isEmailAddressEditMode);
    protected readonly conversationCategories$ = this.store.select(UserProfileState.conversationCategories);
    protected readonly assignedConversationCategories$ = this.store.select(UserProfileState.assignedConversationCategories);
    protected readonly currentLanguage$ = this.store.select(UIState.activeLanguage).pipe(RxjsUtils.filterFalsy());
    protected readonly profileAvatar$ = this.store.select(UserProfileState.profileAvatar);
    protected readonly fullName$ = this.userProfile$.pipe(
        RxjsUtils.filterFalsy(),
        map((user) => `${user.firstName} ${user.lastName}`)
    );

    protected readonly hasEmailAddressConflictError$ = this.store
        .select(UserProfileState.emailAddressSaveError)
        .pipe(map((error) => error?.type === UserContactErrorType.EmailConflict));
    protected readonly hasPhoneNumberConflictError$ = this.store
        .select(UserProfileState.phoneNumberSaveError)
        .pipe(map((error) => error?.type === UserContactErrorType.PhoneConflict));
    protected readonly ErrorMode = ErrorMode;

    constructor(
        private readonly store: Store,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {}

    protected saveNewEmailAddress(payload: string): void {
        this.store
            .dispatch(new UserProfileActions.SaveNewEmailAddress(payload))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.displayNotification(
                    this.store.selectSnapshot(UserProfileState.emailAddressSaveError),
                    NotificationTypes.SaveEmailSuccess
                );
            });
    }

    protected saveNewPhoneNumber(payload: PhoneNumber): void {
        this.store
            .dispatch(new UserProfileActions.SaveNewPhoneNumber(payload))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.displayNotification(
                    this.store.selectSnapshot(UserProfileState.phoneNumberSaveError),
                    NotificationTypes.SavePhoneSuccess
                );
            });
    }

    protected clearEmailAddressChange(): void {
        this.store.dispatch(new UserProfileActions.ClearEmailAddressRequestChange());
    }

    protected clearPhoneNumberChange(): void {
        this.store.dispatch(new UserProfileActions.ClearPhoneRequestChange());
    }

    protected reloadUserProfile() {
        this.store
            .select(AuthState.userId)
            .pipe(
                RxjsUtils.filterFalsy(),
                first(),
                switchMap((userId) =>
                    this.store.dispatch([
                        new UserProfileActions.GetUserProfile(userId),
                        new UserProfileActions.GetProfileCapabilities(userId),
                        new UserProfileActions.GetProfileAvatar(userId),
                    ])
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected conversationCategoriesChanged(newAssignedConversationCategoriesIds: string[]): void {
        this.store
            .dispatch(new UserProfileActions.SaveNewConversationsCategories(newAssignedConversationCategoriesIds))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.displayNotification(
                    this.store.selectSnapshot(UserProfileState.newConversationCategoriesSaveError),
                    NotificationTypes.SaveConversationsSuccess
                );
            });
    }

    protected personalDataChange(personalData: PersonalData): void {
        this.store
            .dispatch(new UserProfileActions.SaveNewPersonalData(personalData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.displayNotification(
                    this.store.selectSnapshot(UserProfileState.personalDataSaveError),
                    NotificationTypes.SaveNewPersonalDataSuccess
                );
            });
    }

    protected updateProfileLanguage(lang: LanguageCode): void {
        this.store
            .dispatch(new UserProfileActions.UpdateProfileLanguage(lang))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.displayNotification(
                    this.store.selectSnapshot(UserProfileState.updateProfileLanguageError),
                    NotificationTypes.UpdateProfileLanguageSuccess
                );

                /* TODO: [REJ-714] Need find solution to fire markForCheck() after translations files be fetched.
                   Randomly app doesn't refresh translations after profile-language change
                */
            });
    }

    protected resetPassword(): void {
        this.store
            .dispatch(new UserProfileActions.ResetUserPassword())
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.resetUserPasswordError);

                if (error) {
                    this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));
                }
            });
    }

    protected saveProfileAvatar(croppedBase64Image: string): void {
        this.store
            .dispatch(new UserProfileActions.SaveProfileAvatar(croppedBase64Image))
            .pipe(
                map(() => {
                    const error = this.store.selectSnapshot(UserProfileState.saveProfileAvatarError);
                    if (error) {
                        this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));
                    } else {
                        this.displayNotification(
                            this.store.selectSnapshot(UserProfileState.saveProfileAvatarError),
                            NotificationTypes.SaveProfileAvatarSuccess
                        );
                    }

                    return !!error;
                }),
                switchMap((isError) => {
                    if (isError) {
                        return EMPTY;
                    }

                    return this.refreshProfile();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected deleteUserAvatar(): void {
        this.store
            .dispatch(new UserProfileActions.DeleteAvatar())
            .pipe(
                map(() => {
                    const error = this.store.selectSnapshot(UserProfileState.deleteProfileAvatarError);
                    this.displayNotification(error, NotificationTypes.DeleteProfileAvatarSuccess);

                    return !!error;
                }),
                switchMap((isError) => {
                    if (isError) {
                        return EMPTY;
                    }

                    return this.refreshProfile();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private refreshProfile(): Observable<void> {
        return this.store.select(AuthState.userId).pipe(
            RxjsUtils.filterFalsy(),
            first(),
            switchMap((userId) => this.store.dispatch(new UserProfileActions.GetUserProfile(userId)))
        );
    }

    private displayNotification(error: UserProfileError | UserContactError | undefined, notificationType: NotificationTypes): void {
        const errorType = error?.type;

        if (errorType === UserContactErrorType.EmailConflict || errorType === UserContactErrorType.PhoneConflict) {
            return;
        }

        if (errorType) {
            this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(errorType)));

            return;
        }

        switch (notificationType) {
            case NotificationTypes.SavePhoneSuccess: {
                this.toastService.success(this.transloco.translate("dtmAdminLibUserProfile.notifications.saveNewPhoneNumberSuccess"));
                break;
            }
            case NotificationTypes.SaveEmailSuccess: {
                this.toastService.success(this.transloco.translate("dtmAdminLibUserProfile.notifications.saveNewEmailAddressSuccess"));
                break;
            }
            case NotificationTypes.SaveConversationsSuccess: {
                this.toastService.success(
                    this.transloco.translate("dtmAdminLibUserProfile.notifications.saveNewConversationCategoriesSuccess")
                );
                break;
            }
            case NotificationTypes.SaveNewPersonalDataSuccess: {
                this.toastService.success(this.transloco.translate("dtmAdminLibUserProfile.notifications.saveNewPersonalDataSuccess"));
                break;
            }
            case NotificationTypes.UpdateProfileLanguageSuccess: {
                this.toastService.success(this.transloco.translate("dtmAdminLibUserProfile.notifications.updateProfileLanguageSuccess"));
                break;
            }
            case NotificationTypes.SaveProfileAvatarSuccess: {
                this.toastService.success(this.transloco.translate("dtmAdminLibUserProfile.notifications.saveProfileAvatarSuccess"));
                break;
            }
            case NotificationTypes.DeleteProfileAvatarSuccess: {
                this.toastService.success(this.transloco.translate("dtmAdminLibUserProfile.notifications.deleteProfileAvatarSuccess"));
                break;
            }
        }
    }
}
