import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ChangeLogType } from "../../../models/shared.models";

interface ChangeLogTileComponentState {
    type: ChangeLogType | undefined;
    identifier: string | undefined;
}

@Component({
    selector: "dtm-admin-lib-change-log-tile[type][identifier]",
    templateUrl: "./change-log-tile.component.html",
    styleUrls: ["./change-log-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ChangeLogTileComponent {
    @Input() public set type(value: ChangeLogType | undefined) {
        this.localStore.patchState({ type: value });
    }

    @Input() public set identifier(value: string | undefined) {
        this.localStore.patchState({ identifier: value });
    }

    protected readonly type$ = this.localStore.selectByKey("type");
    protected readonly identifier$ = this.localStore.selectByKey("identifier");

    constructor(private readonly localStore: LocalComponentStore<ChangeLogTileComponentState>, private readonly router: Router) {
        localStore.setState({ type: undefined, identifier: undefined });
    }

    protected redirectToChangeLog(type: ChangeLogType, identifier: string) {
        this.router.navigate(["change-log"], { queryParams: { type, identifier } });
    }
}
