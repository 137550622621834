import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import {
    AdjacentGroundAreaCharacteristicType,
    ControlledGroundAreaCharacteristicType,
    GroundAreaCharacteristicType,
    GroundRiskCharacteristicModel,
    RiskAssessmentReference,
} from "@dtm-frontend/shared/ui";
import { AnimationUtils, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

const MAX_ADJACENT_GROUND_AREA_EXTENT = 35;
const MIN_ADJACENT_GROUND_AREA_EXTENT = 0.1;

interface GroundRiskCharacteristicFormComponentState {
    groundAreaCharacteristicOptions: GroundAreaCharacteristicType[];
    adjacentGroundAreaCharacteristicOptions: AdjacentGroundAreaCharacteristicType[];
    controlledGroundAreaCharacteristicOptions: ControlledGroundAreaCharacteristicType[];
}

interface GroundRiskCharacteristicForm {
    groundAreaCharacteristic: FormControl<GroundAreaCharacteristicType | null>;
    adjacentGroundAreaCharacteristic: FormControl<AdjacentGroundAreaCharacteristicType | null>;
    controlledGroundAreaCharacteristic: FormControl<ControlledGroundAreaCharacteristicType | null>;
    adjacentGroundAreaExtent: FormControl<number | null>;
}

const GROUND_RISK_FORM_OPTIONS_FOR_SORA2_0_ALTMOC = {
    groundAreaCharacteristicOptions: [
        GroundAreaCharacteristicType.Controlled,
        GroundAreaCharacteristicType.Rural,
        GroundAreaCharacteristicType.SparselyPopulated,
        GroundAreaCharacteristicType.Suburban,
        GroundAreaCharacteristicType.Urban,
        GroundAreaCharacteristicType.DenseUrban,
        GroundAreaCharacteristicType.PeopleAssembly,
    ],
    controlledGroundAreaCharacteristicOptions: [
        ControlledGroundAreaCharacteristicType.Rural,
        ControlledGroundAreaCharacteristicType.SparselyPopulated,
        ControlledGroundAreaCharacteristicType.Suburban,
        ControlledGroundAreaCharacteristicType.Urban,
        ControlledGroundAreaCharacteristicType.DenseUrban,
    ],
    adjacentGroundAreaCharacteristicOptions: [
        AdjacentGroundAreaCharacteristicType.Rural,
        AdjacentGroundAreaCharacteristicType.SparselyPopulated,
        AdjacentGroundAreaCharacteristicType.Suburban,
        AdjacentGroundAreaCharacteristicType.Urban,
        AdjacentGroundAreaCharacteristicType.DenseUrban,
        AdjacentGroundAreaCharacteristicType.PeopleAssembly,
    ],
};
const GROUND_RISK_FORM_OPTIONS_DEFAULT = {
    groundAreaCharacteristicOptions: [
        GroundAreaCharacteristicType.Controlled,
        GroundAreaCharacteristicType.SparselyPopulated,
        GroundAreaCharacteristicType.Populated,
        GroundAreaCharacteristicType.PeopleAssembly,
    ],
    controlledGroundAreaCharacteristicOptions: [
        ControlledGroundAreaCharacteristicType.SparselyPopulated,
        ControlledGroundAreaCharacteristicType.Populated,
    ],
    adjacentGroundAreaCharacteristicOptions: [
        AdjacentGroundAreaCharacteristicType.Controlled,
        AdjacentGroundAreaCharacteristicType.SparselyPopulated,
        AdjacentGroundAreaCharacteristicType.Populated,
        AdjacentGroundAreaCharacteristicType.PeopleAssembly,
    ],
};

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-ground-risk-characteristic-form[riskMethodology]",
    templateUrl: "./ground-risk-characteristic-form.component.html",
    styleUrls: ["../../styles/shared-permit-styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GroundRiskCharacteristicFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => GroundRiskCharacteristicFormComponent),
            multi: true,
        },
    ],
    animations: [AnimationUtils.slideInAnimation()],
})
export class GroundRiskCharacteristicFormComponent implements ControlValueAccessor, Validators {
    @Input() public set riskMethodology(value: RiskAssessmentReference | undefined) {
        if (!value) {
            this.groundRiskForm.controls.groundAreaCharacteristic.disable();
            this.groundRiskForm.controls.adjacentGroundAreaCharacteristic.disable();

            return;
        }
        this.assignOptionsByRiskMethodology(value);
        this.groundRiskForm.controls.groundAreaCharacteristic.enable({ emitEvent: false });
        this.groundRiskForm.controls.adjacentGroundAreaCharacteristic.enable({ emitEvent: false });
    }

    protected readonly MAX_ADJACENT_GROUND_AREA_EXTENT = MAX_ADJACENT_GROUND_AREA_EXTENT;
    protected readonly MIN_ADJACENT_GROUND_AREA_EXTENT = MIN_ADJACENT_GROUND_AREA_EXTENT;
    protected readonly groundAreaCharacteristicOptions$ = this.localStore.selectByKey("groundAreaCharacteristicOptions");
    protected readonly adjacentGroundAreaCharacteristicOptions$ = this.localStore.selectByKey("adjacentGroundAreaCharacteristicOptions");
    protected readonly controlledGroundAreaCharacteristicOptions$ = this.localStore.selectByKey(
        "controlledGroundAreaCharacteristicOptions"
    );

    protected readonly groundRiskForm = new FormGroup<GroundRiskCharacteristicForm>({
        groundAreaCharacteristic: new FormControl(null, Validators.required),
        adjacentGroundAreaCharacteristic: new FormControl(null, Validators.required),
        adjacentGroundAreaExtent: new FormControl(null, {
            validators: [
                Validators.required,
                Validators.min(MIN_ADJACENT_GROUND_AREA_EXTENT),
                Validators.max(MAX_ADJACENT_GROUND_AREA_EXTENT),
            ],
        }),
        controlledGroundAreaCharacteristic: new FormControl({ value: null, disabled: true }),
    });

    constructor(private readonly localStore: LocalComponentStore<GroundRiskCharacteristicFormComponentState>) {
        localStore.setState({
            adjacentGroundAreaCharacteristicOptions: [],
            groundAreaCharacteristicOptions: [],
            controlledGroundAreaCharacteristicOptions: [],
        });

        this.groundRiskForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.groundRiskForm.getRawValue() as GroundRiskCharacteristicModel);
        });

        this.groundRiskForm.controls.groundAreaCharacteristic.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.updateFormAfterGroundAreaCharacteristicTypeChanges(value);
        });
    }

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: GroundRiskCharacteristicModel) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public registerOnChange(fn: (value: GroundRiskCharacteristicModel) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: GroundRiskCharacteristicModel): void {
        if (value) {
            this.groundRiskForm.reset(
                {
                    groundAreaCharacteristic: value.groundAreaCharacteristic,
                    adjacentGroundAreaCharacteristic: value.adjacentGroundAreaCharacteristic,
                    adjacentGroundAreaExtent: value.adjacentGroundAreaExtent,
                },
                { emitEvent: false }
            );
            if (value.groundAreaCharacteristic === GroundAreaCharacteristicType.Controlled && value.controlledGroundAreaCharacteristic) {
                this.groundRiskForm.controls.controlledGroundAreaCharacteristic.setValue(value.controlledGroundAreaCharacteristic);
                this.groundRiskForm.controls.controlledGroundAreaCharacteristic.enable();
            }
        } else {
            this.groundRiskForm.reset();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.groundRiskForm.valid) {
            return null;
        }

        return this.groundRiskForm.invalid ? { invalidGroundRiskCharacteristic: true } : null;
    }

    protected updateFormAfterGroundAreaCharacteristicTypeChanges(groundAreaCharacteristicValue: GroundAreaCharacteristicType | null) {
        if (groundAreaCharacteristicValue === GroundAreaCharacteristicType.Controlled) {
            this.groundRiskForm.controls.controlledGroundAreaCharacteristic.enable();

            return;
        }
        this.groundRiskForm.controls.controlledGroundAreaCharacteristic.disable();
    }

    private assignOptionsByRiskMethodology(value: RiskAssessmentReference) {
        switch (value) {
            case RiskAssessmentReference.SORA2_0_ALTMOC:
                this.localStore.patchState(GROUND_RISK_FORM_OPTIONS_FOR_SORA2_0_ALTMOC);
                break;
            default:
                this.localStore.patchState(GROUND_RISK_FORM_OPTIONS_DEFAULT);
        }
        if (
            this.groundRiskForm.controls.groundAreaCharacteristic.value &&
            this.localStore
                .selectSnapshotByKey("groundAreaCharacteristicOptions")
                .includes(this.groundRiskForm.controls.groundAreaCharacteristic.value)
        ) {
            this.groundRiskForm.controls.groundAreaCharacteristic.reset();
        }
        if (
            this.groundRiskForm.controls.controlledGroundAreaCharacteristic.value &&
            this.localStore
                .selectSnapshotByKey("controlledGroundAreaCharacteristicOptions")
                .includes(this.groundRiskForm.controls.controlledGroundAreaCharacteristic.value)
        ) {
            this.groundRiskForm.controls.controlledGroundAreaCharacteristic.reset();
        }
        if (
            this.groundRiskForm.controls.adjacentGroundAreaCharacteristic.value &&
            this.localStore
                .selectSnapshotByKey("adjacentGroundAreaCharacteristicOptions")
                .includes(this.groundRiskForm.controls.adjacentGroundAreaCharacteristic.value)
        ) {
            this.groundRiskForm.controls.adjacentGroundAreaCharacteristic.reset();
        }
    }
}
