import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { RouterModule } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoTestingModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ConversationsTileComponent } from "./components/conversations-tile/conversations-tile.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { OperatorStatusTileComponent } from "./components/operator-status-tile/operator-status-tile.component";
import { StatsListItemComponent } from "./components/shared/stats-list-item/stats-list-item.component";
import { StatsTileComponent } from "./components/shared/stats-tile/stats-tile.component";
import { UsersTileComponent } from "./components/users-tile/users-tile.component";
import { DashboardApiService } from "./services/api/dashboard-api.service";
import { DashboardState } from "./state/dashboard.state";

@NgModule({
    declarations: [
        DashboardComponent,
        ConversationsTileComponent,
        OperatorStatusTileComponent,
        StatsTileComponent,
        StatsListItemComponent,
        UsersTileComponent,
    ],
    imports: [
        CommonModule,
        LetModule,
        PushModule,
        NgxsModule.forFeature([DashboardState]),
        MatCardModule,
        RouterModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        MatBadgeModule,
        TranslocoTestingModule,
        SharedUiModule,
    ],
    exports: [DashboardComponent, UsersTileComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibDashboard",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: DashboardApiService,
            useValue: undefined,
        },
    ],
})
export class DashboardModule {
    public static forRoot(): ModuleWithProviders<DashboardModule> {
        return {
            ngModule: DashboardModule,
            providers: [
                {
                    provide: DashboardApiService,
                    useClass: DashboardApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<DashboardModule> {
        return {
            ngModule: DashboardModule,
            providers: [
                {
                    provide: DashboardApiService,
                    useValue: null,
                },
            ],
        };
    }
}
