<dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
    <dtm-ui-wizard-step-wrapper
        [title]="'dtmAdminLibPermits.summary.summaryStepTitle' | transloco"
        [stepsAmount]="3"
        [stepNumber]="3"
        [nextButtonLabel]="
            ((isUpdateMode$ | ngrxPush) ? 'dtmAdminLibPermits.summary.updatePermitLabel' : 'dtmAdminLibPermits.summary.addPermitLabel')
                | transloco
        "
        [isBackButtonVisible]="true"
        (back)="back.emit()"
        (next)="addPermit.emit()"
    >
        <div class="summary-wrapper" *ngrxLet="permit$ as permit">
            <dtm-ui-permit-details-preview
                areActionButtonsVisible
                [permit]="permit"
                (basicDataEdit)="editStep.emit(SpecificPermitFlightWizardSteps.BasicInfo)"
                (uavDetailsEdit)="editStep.emit(SpecificPermitFlightWizardSteps.UavDetails)"
                (kmlFilePreview)="kmlFilePreview.emit($event)"
                isOperatorDetailsTileExpanded
                isContactPersonTileExpanded
                isRestrictionsTileExpanded
                isPermitDetailsExpanded
                isOperatorContactPersonVisible
            ></dtm-ui-permit-details-preview>
            <div class="operation-description">
                <h4 class="section-title">{{ "dtmAdminLibPermits.uavDetails.operationPermit" | transloco }}</h4>
                <span class="operator-name">{{ permit.basicData.operator.name }}</span>
                {{ "dtmAdminLibPermits.uavDetails.performOperationDescription" | transloco }}
            </div>
        </div>
    </dtm-ui-wizard-step-wrapper>
</dtm-ui-loader-container>
