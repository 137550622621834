import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { LocalComponentStore, TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";

interface TermsOfServiceAcceptanceComponentState {
    isProcessing: boolean;
}

@Component({
    selector: "dtm-web-app-lib-terms-of-service-acceptance",
    templateUrl: "./terms-of-service-acceptance.component.html",
    styleUrls: ["./terms-of-service-acceptance.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TermsOfServiceAcceptanceComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Output() public termsAccept = new EventEmitter<void>();
    @Output() public logout = new EventEmitter<void>();

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly acceptTermsOfUseControl = new FormControl<boolean>(false, {
        validators: Validators.requiredTrue,
        nonNullable: true,
    });

    constructor(
        @Inject(TERMS_OF_USE_URL) protected readonly termsOfUseUrl: string,
        private localStore: LocalComponentStore<TermsOfServiceAcceptanceComponentState>
    ) {
        localStore.setState({ isProcessing: false });
    }

    protected acceptTermsOfUse() {
        if (this.acceptTermsOfUseControl.valid) {
            this.termsAccept.emit();

            return;
        }
        this.acceptTermsOfUseControl.markAsTouched();
    }
}
