import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { SINGLE_SELECT_VALUES } from "../../../shared/models/shared.models";
import { UtmUserStatus } from "../../../utm-users/services/utm-users.models";
import { UsersStats } from "../../services/dashboard.model";

export interface UsersTileComponentState {
    usersStats: UsersStats | undefined;
}

@Component({
    selector: "dtm-admin-lib-users-tile",
    templateUrl: "./users-tile.component.html",
    styleUrls: ["./users-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UsersTileComponent {
    @Input() public set usersStats(value: UsersStats | undefined) {
        this.localStore.patchState({ usersStats: value });
    }

    protected readonly usersStats$ = this.localStore.selectByKey("usersStats").pipe(RxjsUtils.filterFalsy());
    protected readonly SINGLE_SELECT_VALUES = SINGLE_SELECT_VALUES;
    protected readonly UtmUserStatus = UtmUserStatus;

    constructor(private readonly localStore: LocalComponentStore<UsersTileComponentState>) {
        localStore.setState({ usersStats: undefined });
    }
}
