<ng-container *ngIf="interlocutorDetailsError$ | ngrxPush as interlocutorDetailsError; else messagesContainerTemplate">
    <ng-container [ngSwitch]="interlocutorDetailsError.type">
        <ng-container
            *ngSwitchCase="OperatorErrorType.NotAuthorized"
            [ngTemplateOutlet]="downloadCapabilitiesErrorTemplate"
            [ngTemplateOutletContext]="{
                errorMessage: 'dtmAdminLibConversation.messagesContainer.operatorDetailsUnauthorizedError' | transloco
            }"
        >
        </ng-container>
        <ng-container
            *ngSwitchCase="OperatorErrorType.NotFound"
            [ngTemplateOutlet]="downloadCapabilitiesErrorTemplate"
            [ngTemplateOutletContext]="{
                errorMessage: 'dtmAdminLibConversation.messagesContainer.operatorDetailsNotFoundError' | transloco
            }"
        >
        </ng-container>
        <ng-container *ngSwitchDefault [ngTemplateOutlet]="downloadCapabilitiesErrorTemplate"></ng-container>
    </ng-container>
</ng-container>

<ng-template #messagesContainerTemplate>
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="true" *ngrxLet="interlocutorDetails$; let interlocutorDetails">
        <mat-sidenav [fixedInViewport]="true" position="end" mode="over" #drawer class="operator-details-container">
            <dtm-admin-lib-messages-interlocutor-profile [profile]="interlocutorDetails"> </dtm-admin-lib-messages-interlocutor-profile>
        </mat-sidenav>

        <mat-sidenav-content>
            <div class="grid content">
                <dtm-ui-filters-container [filtersCount]="filtersCount$ | ngrxPush" class="save-bottom-margin">
                    <dtm-ui-search-thread [formControl]="textSearchControl" [isLabelVisible]="false"></dtm-ui-search-thread>
                </dtm-ui-filters-container>

                <mat-card class="main-container" *ngrxLet="selectedThread$; let selectedThread">
                    <ng-container *ngrxLet="threadsList$; let threadsList">
                        <div class="threads-container">
                            <div class="operator">
                                <div>
                                    <span>{{ interlocutorDetails.operatorNumber }}</span>
                                    <button class="button-secondary" type="button" (click)="drawer.toggle()">
                                        {{ "dtmAdminLibConversation.messagesContainer.showOperatorProfileButtonLabel" | transloco }}
                                    </button>
                                </div>
                                <div>
                                    <ng-container [ngSwitch]="interlocutorDetails.type">
                                        <dtm-ui-icon name="building" *ngSwitchCase="OperatorType.Enterprise"></dtm-ui-icon>
                                        <dtm-ui-icon name="user" *ngSwitchCase="OperatorType.Personal"></dtm-ui-icon>
                                    </ng-container>
                                    <div class="operator-name">{{ interlocutorDetails.name }}</div>
                                </div>
                            </div>
                            <div class="threads">
                                <dtm-ui-operators-thread-list
                                    [selectedThread]="selectedThread?.id"
                                    [threadsList]="threadsList"
                                    [canDisplayAssignment]="true"
                                    (threadChange)="changeThread($event)"
                                ></dtm-ui-operators-thread-list>
                            </div>
                        </div>

                        <div class="messages-container" *ngrxLet="storedAvatarPictures$ as storedAvatarPictures">
                            <div class="new-thread-button">
                                <button type="button" class="button-primary" (click)="openNewThreadContainer()">
                                    <dtm-ui-icon name="add"></dtm-ui-icon>
                                    {{ "dtmAdminLibConversation.messagesContainer.createNewThreadButtonLabel" | transloco }}
                                </button>
                            </div>
                            <ng-container *ngIf="threadsList?.length; else emptyThreadsListTemplate">
                                <ng-container *ngIf="selectedThread">
                                    <div class="messages-header">
                                        <p class="messages-subject">{{ selectedThread.name }}</p>
                                        <div *ngIf="!selectedThread.isClosed; else closedThreadInfoTemplate" class="thread-buttons">
                                            <button
                                                *ngIf="selectedThread && !selectedThread.isClosed"
                                                type="button"
                                                class="button-icon"
                                                (click)="markAsUnread(selectedThread)"
                                                [matTooltip]="'dtmAdminLibConversation.messagesContainer.markAsUnreadHint' | transloco"
                                            >
                                                <dtm-ui-icon name="mail"></dtm-ui-icon>
                                            </button>
                                            <button
                                                *ngIf="!selectedThread?.isClosed"
                                                type="button"
                                                class="button-icon"
                                                (click)="changeEditorVisibility(true)"
                                                [matTooltip]="
                                                    'dtmAdminLibConversation.messagesContainer.createNewMessageInThisThread' | transloco
                                                "
                                            >
                                                <dtm-ui-icon name="reply"></dtm-ui-icon>
                                            </button>
                                            <button
                                                *ngIf="selectedThread && !selectedThread?.isClosed"
                                                type="button"
                                                class="button-icon"
                                                (click)="closeThread(selectedThread)"
                                                [matTooltip]="'dtmAdminLibConversation.messagesContainer.closeThreadLabel' | transloco"
                                            >
                                                <dtm-ui-icon name="mail-lock"></dtm-ui-icon>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="thread-management">
                                        <dtm-admin-lib-thread-category-change
                                            [categories]="categories$ | ngrxPush"
                                            [processingThreadsIds]="processingThreadsCategory$ | ngrxPush"
                                            [thread]="selectedThread"
                                            [selectedCategory]="selectedThread.category"
                                            (categoryChange)="changeThreadsCategory($event, selectedThread.id)"
                                        ></dtm-admin-lib-thread-category-change>

                                        <dtm-admin-lib-messages-assignee-select
                                            [storedAvatarPictures]="storedAvatarPictures"
                                            [assignees]="assignees$ | ngrxPush"
                                            [selectedAssignee]="selectedThreadAssignee$ | ngrxPush"
                                            [isClosedThread]="selectedThread.isClosed"
                                            [processingThreadIds]="processingThreadsAssignments$ | ngrxPush"
                                            [selectedThreadId]="selectedThread.id"
                                            (assigneeChange)="changeAssignee({ assignee: $event, thread: selectedThread.id })"
                                        ></dtm-admin-lib-messages-assignee-select>
                                    </div>
                                </ng-container>

                                <div *ngIf="messages$ | ngrxPush as messages; else noMessagesTemplate" class="messages-list">
                                    <form *ngIf="showMessageEditor$ | ngrxPush" [formGroup]="conversationForm">
                                        <dtm-ui-new-message-editor
                                            *ngrxLet="isAttachmentsControlVisible$ as isAttachmentsControlVisible"
                                            class="editor-container"
                                            formControlName="editor"
                                            isJoinedView
                                            [isAttachmentsControlVisible]="isAttachmentsControlVisible"
                                        >
                                            <dtm-ui-new-message-actions
                                                [isAttachmentsButtonVisible]="!isAttachmentsControlVisible"
                                                (attachmentsButtonVisibilityChange)="changeAttachmentControlVisibility()"
                                                (messageDecline)="changeEditorVisibility(false)"
                                                (messageSend)="sendMessage(messages[0].subject, messages[0].threadId)"
                                            ></dtm-ui-new-message-actions>
                                        </dtm-ui-new-message-editor>
                                    </form>
                                    <dtm-ui-messages-in-thread
                                        [messages]="messages"
                                        [storedAvatarPictures]="storedAvatarPictures"
                                    ></dtm-ui-messages-in-thread>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </mat-card>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-template>

<ng-template #noMessagesTemplate>
    <ng-container *ngrxLet="messagesError$ as messagesError">
        <dtm-ui-error
            *ngIf="messagesError && messagesError.type !== MessagesErrorType.NotFound; else messagesLoadingOrNotSelectedTemplate"
            [mode]="ErrorMode.Secondary"
            (reload)="reloadMessages()"
            [errorMessage]="'dtmAdminLibConversation.messagesContainer.threadDoesNotExistError' | transloco"
        ></dtm-ui-error>
    </ng-container>
</ng-template>

<ng-template #emptyThreadsListTemplate>
    <dtm-ui-no-results [areFiltersApplied]="textSearchControl.value"></dtm-ui-no-results>
</ng-template>

<ng-template #loaderTemplate>
    <div class="loader">
        <mat-spinner diameter="60" color="accent"></mat-spinner>
    </div>
</ng-template>

<ng-template #messagesLoadingOrNotSelectedTemplate>
    <ng-container *ngIf="!(isMessagesProcessing$ | ngrxPush); else loaderTemplate">
        <dtm-ui-error
            class="no-message-selected"
            [errorMessage]="'dtmUi.conversations.selectThreadToDisplayMessagesHint' | transloco"
            imageSrc="assets/images/select-thread-desktop.svg"
        >
        </dtm-ui-error>
    </ng-container>
</ng-template>

<ng-template #closedThreadInfoTemplate>
    <dtm-ui-icon
        name="lock"
        class="closed-thread-info-icon"
        [matTooltip]="'dtmAdminLibConversation.closedThreadHint' | transloco"
    ></dtm-ui-icon>
</ng-template>

<ng-template #downloadCapabilitiesErrorTemplate let-errorMessage="errorMessage">
    <dtm-ui-error (reload)="refreshCapabilities()" [errorMessage]="errorMessage"></dtm-ui-error>
</ng-template>
