<dtm-ui-presentation-tile-with-action *ngIf="identityDocument$ | ngrxPush as identityDocument; else emptyStateTemplate">
    <ng-container headerSlot>
        {{ "dtmAdminLibUtmUsers.userProfile.identityDocument.title" | transloco }}
        <dtm-ui-popover *ngIf="identityDocument.statusReason" class="popover">
            <div class="popover-header">{{ "dtmAdminLibUtmUsers.userProfile.identityDocument.statusReasonLabel" | transloco }}</div>
            <p class="status-reason">{{ identityDocument.statusReason }}</p>
        </dtm-ui-popover>
    </ng-container>
    <button actionButtonSlot class="button-icon" type="button" [matMenuTriggerFor]="menu">
        <dtm-ui-icon name="more"></dtm-ui-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button *ngIf="identityDocument.status !== IdentityDocumentStatus.Active" type="button" mat-menu-item (click)="accept.emit()">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{ "dtmAdminLibUtmUsers.userProfile.identityDocument.acceptLabel" | transloco }}
        </button>
        <button *ngIf="identityDocument.status !== IdentityDocumentStatus.Rejected" type="button" mat-menu-item (click)="reject.emit()">
            <dtm-ui-icon name="forbid"></dtm-ui-icon>
            {{ "dtmAdminLibUtmUsers.userProfile.identityDocument.rejectLabel" | transloco }}
        </button>
    </mat-menu>
    <div class="content">
        <dtm-ui-identity-document-status-badge [status]="identityDocument.status"></dtm-ui-identity-document-status-badge>
        <div class="document-info">
            <dtm-ui-label-value
                [label]="'dtmAdminLibUtmUsers.userProfile.identityDocument.documentTypeLabel' | transloco"
                [value]="identityDocument.type | identityDocumentType"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmAdminLibUtmUsers.userProfile.identityDocument.expiryDateLabel' | transloco"
                [value]="identityDocument.expirationDate | localizeDate"
            >
            </dtm-ui-label-value>
        </div>
        <dtm-ui-label-value [label]="'dtmAdminLibUtmUsers.userProfile.identityDocument.attachmentLabel' | transloco">
            <div class="attachment">
                <dtm-ui-icon name="attachment"></dtm-ui-icon>
                <span>{{ identityDocument.filename }}</span>
                <button type="button" class="button-icon" (click)="download.emit()">
                    <dtm-ui-icon name="download"></dtm-ui-icon>
                </button>
            </div>
        </dtm-ui-label-value>
    </div>
    <ng-container *ngIf="identityDocument.status === IdentityDocumentStatus.Rejected">
        <div class="rejected-request request-for-update" *ngrxLet="lastRequestForUpdate$ as lastRequestForUpdate">
            <p>{{ "dtmAdminLibUtmUsers.userProfile.identityDocument.rejectedDocumentRequestText" | transloco }}</p>
            <p *ngIf="lastRequestForUpdate" class="last-update">
                {{
                    "dtmAdminLibUtmUsers.userProfile.identityDocument.lastRequestForUpdateText"
                        | transloco : { date: lastRequestForUpdate | localizeDate }
                }}
            </p>
            <button type="button" class="button-link" (click)="updateRequest.emit()">
                {{ "dtmAdminLibUtmUsers.userProfile.identityDocument.requestForUpdateLabel" | transloco }}
            </button>
        </div>
    </ng-container>
</dtm-ui-presentation-tile-with-action>

<ng-template #emptyStateTemplate>
    <div class="card" *ngrxLet="lastRequestForUpdate$ as lastRequestForUpdate">
        <div class="card-title">
            {{ "dtmAdminLibUtmUsers.userProfile.identityDocument.title" | transloco }}
        </div>
        <div class="chip secondary">
            <dtm-ui-icon name="draft"></dtm-ui-icon>
            <p>{{ "dtmAdminLibUtmUsers.userProfile.identityDocument.toUpdateChipsLabel" | transloco }}</p>
        </div>
        <div class="request-for-update">
            <p>{{ "dtmAdminLibUtmUsers.userProfile.identityDocument.emptyStateText" | transloco }}</p>
            <p *ngIf="lastRequestForUpdate" class="last-update">
                {{
                    "dtmAdminLibUtmUsers.userProfile.identityDocument.lastRequestForUpdateText"
                        | transloco : { date: lastRequestForUpdate | localizeDate }
                }}
            </p>
            <button type="button" class="button-link" (click)="updateRequest.emit()">
                {{ "dtmAdminLibUtmUsers.userProfile.identityDocument.requestForUpdateLabel" | transloco }}
            </button>
        </div>
    </div>
</ng-template>
