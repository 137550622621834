<dtm-ui-radio-tile-group [formControl]="operationTypeControl">
    <label>{{ "dtmAdminLibPermits.associationPermitDetails.operationTypeLabel" | transloco }} </label>
    <dtm-ui-radio-tile [value]="AssociationOperationType.VLOS">
        <div class="tile" data-testid="mission-type-vlos-tile">
            <dtm-ui-icon name="eye"></dtm-ui-icon>
            <p class="selectable-tile-label">
                {{
                    "dtmAdminLibPermits.associationPermitDetails.operationTypeValueLabel"
                        | transloco : { value: AssociationOperationType.VLOS }
                }}
            </p>
        </div>
    </dtm-ui-radio-tile>
    <dtm-ui-radio-tile [value]="AssociationOperationType.FPV">
        <div class="tile">
            <dtm-ui-icon name="google"></dtm-ui-icon>
            <p class="selectable-tile-label">
                {{
                    "dtmAdminLibPermits.associationPermitDetails.operationTypeValueLabel"
                        | transloco : { value: AssociationOperationType.FPV }
                }}
            </p>
        </div>
    </dtm-ui-radio-tile>
</dtm-ui-radio-tile-group>

<dtm-ui-form-errors class="preserve-error-space">
    <ng-content select=".field-error"></ng-content>
</dtm-ui-form-errors>
