<dtm-ui-loader-container *ngrxLet="isSpecificPermitsProcessing$ as isSpecificPermitsProcessing" [isShown]="isSpecificPermitsProcessing">
    <ng-container *ngrxLet="expandedElement$ as expandedElement">
        <table
            *ngrxLet="specificPermitsList$ as specificPermits"
            mat-table
            [dataSource]="specificPermits"
            multiTemplateDataRows
            class="dtm-table"
        >
            <ng-container matColumnDef="expandHandle">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" class="button-icon" (click)="$event.stopPropagation(); toggleExpandableRow(element)">
                        <dtm-ui-icon name="arrow-right" class="expand-handle" [class.expanded]="element === expandedElement"></dtm-ui-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibPermits.specificPermitsList.statusLabel" | transloco }}</th>
                <td mat-cell *matCellDef="let permit">
                    <dtm-admin-lib-permit-status-badge [status]="permit.status"></dtm-admin-lib-permit-status-badge>
                </td>
            </ng-container>
            <ng-container matColumnDef="consentNumber">
                <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibPermits.specificPermitsList.numberLabel" | transloco }}</th>
                <td mat-cell *matCellDef="let permit">{{ permit.consentNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="operatorName">
                <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibPermits.specificPermitsList.operatorLabel" | transloco }}</th>
                <td mat-cell *matCellDef="let permit">{{ permit.operatorName }}</td>
            </ng-container>
            <ng-container matColumnDef="validityPeriod">
                <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibPermits.specificPermitsList.validityPeriodLabel" | transloco }}</th>
                <td mat-cell *matCellDef="let permit">{{ permit.validityPeriod }}</td>
            </ng-container>
            <ng-container matColumnDef="permitName">
                <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibPermits.specificPermitsList.nameLabel" | transloco }}</th>
                <td mat-cell *matCellDef="let permit">{{ permit.permitName }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibPermits.specificPermitsList.actionLabel" | transloco }}</th>
                <td mat-cell *matCellDef="let permit">
                    <button [matMenuTriggerFor]="actions" class="button-icon" type="button" (click)="$event.stopPropagation()">
                        <dtm-ui-icon name="more"></dtm-ui-icon>
                    </button>
                    <mat-menu #actions="matMenu">
                        <button [routerLink]="[permit.type | invoke : getDetailsRoute, permit.id]" type="button" mat-menu-item>
                            <dtm-ui-icon name="information"></dtm-ui-icon>
                            {{ "dtmAdminLibPermits.specificPermitsList.permitDetailsLabel" | transloco }}
                        </button>
                        <button
                            *ngIf="permit.status !== PermitStatus.Withdrawn"
                            [routerLink]="[permit.type | invoke : getEditionRoute, permit.id]"
                            type="button"
                            mat-menu-item
                        >
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{ "dtmAdminLibPermits.specificPermitsList.editPermitLabel" | transloco }}
                        </button>
                        <button
                            type="button"
                            (click)="updatePermitStatus(PermitActionType.Withdraw, permit.id)"
                            *ngIf="permit.status | invoke : shouldDisplayActionButton : PermitActionType.Withdraw"
                            mat-menu-item
                        >
                            <dtm-ui-icon name="information"></dtm-ui-icon>
                            {{ "dtmAdminLibPermits.specificPermitsList.withdrawButtonLabel" | transloco }}
                        </button>
                        <button
                            type="button"
                            (click)="updatePermitStatus(PermitActionType.Suspend, permit.id)"
                            *ngIf="permit.status | invoke : shouldDisplayActionButton : PermitActionType.Suspend"
                            mat-menu-item
                        >
                            <dtm-ui-icon name="information"></dtm-ui-icon>
                            {{ "dtmAdminLibPermits.specificPermitsList.suspendButtonLabel" | transloco }}
                        </button>
                        <button
                            type="button"
                            (click)="updatePermitStatus(PermitActionType.Restore, permit.id)"
                            *ngIf="permit.status | invoke : shouldDisplayActionButton : PermitActionType.Restore"
                            mat-menu-item
                        >
                            <dtm-ui-icon name="information"></dtm-ui-icon>
                            {{ "dtmAdminLibPermits.specificPermitsList.restoreButtonLabel" | transloco }}
                        </button>
                    </mat-menu>
                </td>
            </ng-container>
            <ng-container matColumnDef="noResults">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                    <dtm-ui-error
                        *ngIf="specificPermitsListError$ | ngrxPush; else emptyListTemplate"
                        [mode]="ErrorMode.Secondary"
                        (reload)="specificPermitsListRefresh.emit()"
                    >
                    </dtm-ui-error>
                    <ng-template #emptyListTemplate>
                        <dtm-ui-no-results></dtm-ui-no-results>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="expanded-row-content" *ngIf="element === expandedElement" @slideIn>
                        <dtm-ui-label-value
                            class="sub-cell"
                            [label]="'dtmAdminLibPermits.specificPermitsList.operationTargetLabel' | transloco"
                            [value]="element.flightPurposes"
                        ></dtm-ui-label-value>
                        <dtm-ui-label-value
                            class="sub-cell"
                            *ngIf="element.statusReason"
                            [label]="'dtmAdminLibPermits.specificPermitsList.withdrawReasonLabel' | transloco"
                            [value]="element.statusReason"
                        ></dtm-ui-label-value>
                        <dtm-ui-label-value
                            class="sub-cell"
                            [label]="'dtmAdminLibPermits.specificPermitsList.riskMethodologyLabel' | transloco"
                        >
                            <span class="value">
                                {{
                                    "dtmAdminLibPermits.specificPermitsList.riskMethodologyValue"
                                        | transloco : { value: element.riskAssessmentReference }
                                }}
                                <ng-container *ngIf="element.pdraNumber">{{ element.pdraNumber }}</ng-container>
                            </span>
                        </dtm-ui-label-value>
                        <dtm-ui-label-value
                            class="sub-cell"
                            [label]="'dtmAdminLibPermits.specificPermitsList.createdAtLabel' | transloco"
                            [value]="element.createdAt | localizeDate"
                        >
                        </dtm-ui-label-value>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="pagination">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                    <dtm-ui-pagination
                        *ngIf="pagination$ | ngrxPush as pagination"
                        [pagination]="pagination"
                        (page)="pageChange.emit($event)"
                    >
                    </dtm-ui-pagination>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-footer-row
                *matFooterRowDef="['noResults']"
                [class.hide-footer]="specificPermits.length || isSpecificPermitsProcessing"
                class="no-results-row"
            >
                <dtm-ui-no-results></dtm-ui-no-results>
            </tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [class.expanded]="expandedElement === row"
                (click)="toggleExpandableRow(row)"
            ></tr>
            <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
            <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!specificPermits.length" class="pagination-row"></tr>
        </table>
    </ng-container>
</dtm-ui-loader-container>
