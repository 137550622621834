import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { AdminUsersFilter } from "../../services/models";

const MAX_SEARCH_TEXT_LENGTH = 50;
const FILTERS_MAP: FiltersMap[] = [
    {
        key: "textSearch",
        filterLabel: "dtmAdminLibAdminUsers.usersListFilters.searchLabel",
        type: FilterType.TextEllipsis,
    },
];

interface AdminUsersFilterComponentState {
    filtersCount: number;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-admin-users-filter",
    templateUrl: "./admin-users-filter.component.html",
    styleUrls: ["./admin-users-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdminUsersFilterComponent implements OnInit {
    @Input() public set initialFilters(value: AdminUsersFilter | undefined) {
        if (!value) {
            return;
        }

        this.filtersForm.setValue({
            textSearch: value.textSearch ?? "",
        });

        this.assignFiltersCount(this.filtersForm.value);
    }

    @Output() public filtersChange = new EventEmitter<AdminUsersFilter>();

    protected readonly textSearchControl = new FormControl<string>("", {
        validators: [Validators.maxLength(MAX_SEARCH_TEXT_LENGTH)],
        nonNullable: true,
    });
    protected readonly filtersForm = new FormGroup({
        textSearch: this.textSearchControl,
    });

    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly filtersCount$ = this.localStore.selectByKey("filtersCount");

    constructor(private readonly localStore: LocalComponentStore<AdminUsersFilterComponentState>) {
        localStore.setState({ filtersCount: 0 });
    }

    public ngOnInit(): void {
        this.filtersForm.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((filters) => {
                if (this.filtersForm.valid) {
                    this.assignFiltersCount(filters);
                    this.filtersChange.emit(filters);
                }
            });
    }

    protected clearFilters() {
        this.filtersForm.reset();
    }

    private assignFiltersCount(filters: AdminUsersFilter) {
        this.localStore.patchState({ filtersCount: Object.values(filters).flat().filter(FunctionUtils.isTruthy).length });
    }
}
