import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DateUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import {
    MissionCategory,
    MissionPlanSpecificPermitType,
    MissionProcessingPhase,
    MissionType,
} from "../../../../shared/models/mission.models";
import { MissionDetails } from "../../../models/mission-search.models";

interface MissionBasicDetailsComponentState {
    mission: MissionDetails | undefined;
}

@Component({
    selector: "dtm-mission-mission-search-preview-basic-details[mission]",
    templateUrl: "./mission-basic-details.component.html",
    styleUrls: ["./mission-basic-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionBasicDetailsComponent {
    @Input() public set mission(value: MissionDetails | undefined) {
        this.localStore.patchState({ mission: value });
    }

    protected readonly MissionType = MissionType;
    protected readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());
    protected readonly isSoraApplication$ = this.mission$.pipe(map((mission) => this.isSoraApplication(mission)));

    constructor(private readonly localStore: LocalComponentStore<MissionBasicDetailsComponentState>) {
        this.localStore.setState({
            mission: undefined,
        });
    }

    protected getDaysBetween(startDate: Date, endDate: Date): number {
        const daysBetween = DateUtils.daysBetween(startDate, endDate);

        return Math.max(daysBetween + 1, 1);
    }

    private isSoraApplication(mission: MissionDetails): boolean {
        return (
            (mission.category.type === MissionCategory.Specific &&
                mission.category.specificPermitType === MissionPlanSpecificPermitType.Individual) ||
            mission.phase === MissionProcessingPhase.CaaPermitApplication
        );
    }
}
