import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { UserContactErrorType } from "@dtm-frontend/shared/ui";
import { UserProfileError, UserProfileErrorType } from "./user-profile.models";

export const convertGetProfileErrorResponse = (errorResponse: HttpErrorResponse): UserProfileError => {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return { type: UserProfileErrorType.NotAuthorized };
        case HttpStatusCode.NotFound:
            return { type: UserProfileErrorType.NotFound };
        default:
            return { type: UserProfileErrorType.Unknown };
    }
};

export const convertEmailChangeErrorResponse = (errorResponse: HttpErrorResponse): UserProfileError => {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return { type: UserProfileErrorType.NotAuthorized };
        case HttpStatusCode.NotFound:
            return { type: UserContactErrorType.CannotSaveEmailAddress };
        case HttpStatusCode.Conflict:
            return { type: UserContactErrorType.EmailConflict };
        default:
            return { type: UserProfileErrorType.Unknown };
    }
};

export const convertPhoneNumberErrorResponse = (errorResponse: HttpErrorResponse): UserProfileError => {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return { type: UserProfileErrorType.NotAuthorized };
        case HttpStatusCode.NotFound:
            return { type: UserContactErrorType.CannotSavePhoneNumber };
        case HttpStatusCode.Conflict:
            return { type: UserContactErrorType.PhoneConflict };
        default:
            return { type: UserProfileErrorType.Unknown };
    }
};
