import { Pipe, PipeTransform } from "@angular/core";
import { Address } from "@dtm-frontend/shared/ui";

@Pipe({
    name: "displayStreet",
})
export class DisplayStreetPipe implements PipeTransform {
    public transform(address: Address | undefined): string | undefined {
        if (!address) {
            return;
        }

        return !address.apartmentNumber
            ? `${address.streetName} ${address.houseNumber}`
            : `${address.streetName} ${address.houseNumber}/${address.apartmentNumber}`;
    }
}
