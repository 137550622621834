<dtm-ui-expandable-panel
    *ngIf="operatorDetails$ | ngrxPush as operatorDetails"
    [hasHeaderSeparator]="false"
    [isExpanded]="true"
    class="operator-container"
>
    <div class="section-title" headerSlot>
        {{ "dtmAdminLibCenters.centerFormDialog.operator.operatorInfoHeader" | transloco : { value: operatorDetails.name } }}
    </div>

    <div class="operator-info">
        <dtm-ui-label-value
            [label]="'dtmAdminLibCenters.centerFormDialog.operator.operatorNameLabel' | transloco"
            [value]="operatorDetails.name"
        >
        </dtm-ui-label-value>

        <dtm-ui-label-value
            [label]="'dtmAdminLibCenters.centerFormDialog.operator.operatorNumberLabel' | transloco"
            [value]="operatorDetails.number"
        ></dtm-ui-label-value>

        <dtm-ui-address-label-value [address]="operatorDetails.address"></dtm-ui-address-label-value>

        <dtm-ui-label-value [label]="'dtmAdminLibCenters.centerFormDialog.operator.operatorQualificationsLabel' | transloco">
            <dtm-admin-lib-operator-qualifications-badges
                [badges]="operatorDetails.operationalAuthorizations"
            ></dtm-admin-lib-operator-qualifications-badges>
        </dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>
