export interface GlobalCapabilitiesError {
    type: GlobalCapabilitiesErrorType;
}

export enum GlobalCapabilitiesErrorType {
    CannotGetUserGlobalCapabilities = "CannotGetUserGlobalCapabilities",
}

export enum CaaFeatures {
    MissionList = "MISSION_LIST",
}

export interface GlobalCapabilitiesUser {
    firstName: string;
    lastName: string;
    avatarUrl?: string;
}

export interface GlobalCapabilities {
    user: GlobalCapabilitiesUser;
    features: CaaFeatures[];
}
