import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { OPERATION_SCENARIO_STATEMENTS_ENDPOINTS, OperationScenarioStatementsEndpoints } from "../operation-scenario-statements.tokens";
import {
    OperationScenarioStatementsListResponseBody,
    convertOperationScenarioStatementsListResponseBodyToOperationScenarioStatementsListWithPages,
} from "./operation-scenario-statements-api.converters";
import {
    OperationScenarioStatementErrorType,
    OperationScenarioStatementsFilterParams,
    OperationScenarioStatementsListWithPages,
} from "./operation-scenario-statements.models";

@Injectable({
    providedIn: "root",
})
export class OperationScenarioStatementsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPERATION_SCENARIO_STATEMENTS_ENDPOINTS) private readonly endpoints: OperationScenarioStatementsEndpoints
    ) {}

    public getStatementsList(params: OperationScenarioStatementsFilterParams): Observable<OperationScenarioStatementsListWithPages> {
        let httpParams = new HttpParams()
            .set("page", `${params[PAGE_NUMBER_QUERY_PARAM] ?? 0}`)
            .set("size", `${params[PAGE_SIZE_QUERY_PARAM]}`);

        if (params.operatorNumber) {
            httpParams = httpParams.append("operatorNumber", params.operatorNumber);
        }

        if (params.signStatus?.length) {
            httpParams = httpParams.append("signatureStatuses", params.signStatus);
        }

        if (params.paymentStatus?.length) {
            httpParams = httpParams.append("paymentStatuses", params.paymentStatus);
        }

        return this.httpClient
            .get<OperationScenarioStatementsListResponseBody>(this.endpoints.operationScenarioStatementsList, {
                params: httpParams,
            })
            .pipe(
                map((response: OperationScenarioStatementsListResponseBody) =>
                    convertOperationScenarioStatementsListResponseBodyToOperationScenarioStatementsListWithPages(response)
                ),
                catchError(() => throwError(() => ({ type: OperationScenarioStatementErrorType.CannotGetOperationScenarioStatements })))
            );
    }
}
