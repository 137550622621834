import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface PilotOperatorEmptyCardComponentState {
    headerText: string;
    headerButtonText: string;
    actionButtonText: string;
    isButtonVisible: boolean;
}

@Component({
    selector: "dtm-web-app-lib-pilot-operator-empty-card[headerText][headerButtonText][actionButtonText]",
    templateUrl: "./pilot-operator-empty-card.component.html",
    styleUrls: ["./pilot-operator-empty-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotOperatorEmptyCardComponent {
    @Input() public set headerText(value: string) {
        this.localStore.patchState({ headerText: value });
    }

    @Input() public set headerButtonText(value: string) {
        this.localStore.patchState({ headerButtonText: value });
    }

    @Input() public set actionButtonText(value: string) {
        this.localStore.patchState({ actionButtonText: value });
    }
    @Input() public set isButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isButtonVisible: coerceBooleanProperty(value) });
    }

    @Output() public headerButtonClicked = new EventEmitter<void>();
    @Output() public actionButtonClicked = new EventEmitter<void>();

    protected readonly headerText$ = this.localStore.selectByKey("headerText");
    protected readonly headerButtonText$ = this.localStore.selectByKey("headerButtonText");
    protected readonly isButtonVisible$ = this.localStore.selectByKey("isButtonVisible");

    protected readonly actionButtonText$ = this.localStore.selectByKey("actionButtonText");

    constructor(private readonly localStore: LocalComponentStore<PilotOperatorEmptyCardComponentState>) {
        this.localStore.setState({
            headerText: "",
            headerButtonText: "",
            actionButtonText: "",
            isButtonVisible: false,
        });
    }
}
