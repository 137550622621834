<dtm-ui-lib-wizard-header [wizardId]="ASSOCIATION_PERMIT_WIZARD_ID">
    <dtm-ui-lib-wizard-step-header [stepId]="AssociationPermitWizardSteps.BasicInfo">
        {{ "dtmAdminLibPermits.associationPermitWizardHeader.basicInfoStepTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="AssociationPermitWizardSteps.PermitDetails">
        {{ "dtmAdminLibPermits.associationPermitWizardHeader.permitDetailsStepTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="AssociationPermitWizardSteps.Summary">
        {{ "dtmAdminLibPermits.associationPermitWizardHeader.summaryStepTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
</dtm-ui-lib-wizard-header>
