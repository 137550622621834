<div *ngIf="profile$ | ngrxPush as profile" class="card">
    <div class="card-header">
        <div class="card-title">
            {{
                (profile.type === OperatorType.Personal
                    ? "dtmAdminLibShared.operatorProfile.ownerDetails.titleOwner"
                    : "dtmAdminLibShared.operatorProfile.ownerDetails.titleRegistrator"
                ) | transloco
            }}
        </div>
        <ng-container [ngSwitch]="profile.owner.status">
            <span *ngSwitchCase="OwnerStatus.Active" class="chip success">
                <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                {{ "dtmAdminLibShared.operatorProfile.ownerDetails.statusActiveLabel" | transloco }}
            </span>
            <span *ngSwitchCase="OwnerStatus.Waiting" class="chip secondary">
                <dtm-ui-icon name="markup"></dtm-ui-icon>
                {{ "dtmAdminLibShared.operatorProfile.ownerDetails.statusWaitingLabel" | transloco }}
            </span>
        </ng-container>
    </div>
    <dtm-ui-label-value
        [label]="'dtmAdminLibShared.operatorProfile.ownerDetails.nameLabel' | transloco"
        [value]="profile.owner.firstName + ' ' + profile.owner.lastName"
    >
    </dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmAdminLibShared.operatorProfile.ownerDetails.emailLabel' | transloco"
        [value]="profile.owner.email"
        [canCopy]="true"
    >
    </dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmAdminLibShared.operatorProfile.ownerDetails.phoneNumberLabel' | transloco"
        [value]="profile.owner.phoneNumber | formatPhoneNumber"
        [canCopy]="true"
    >
    </dtm-ui-label-value>
    <dtm-ui-label-value [label]="'dtmAdminLibShared.operatorProfile.ownerDetails.nationalityLabel' | transloco">
        <dtm-ui-country-display [country]="profile.owner.nationality"> </dtm-ui-country-display>
    </dtm-ui-label-value>
    <div class="button-container">
        <button type="button" routerLink="/utm-users" [queryParams]="{ searchText: profile.owner.email }" class="button-tertiary">
            {{ "dtmAdminLibShared.operatorProfile.ownerDetails.utmUsersLinkLabel" | transloco }}
        </button>
    </div>
</div>
