import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { PersonalData } from "../../services/user-profile.models";

interface PersonalDataForm {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
}

interface PersonalDataComponentState {
    firstName: string;
    lastName: string;
}

const MAX_NAME_LENGTH = 100;

@Component({
    selector: "dtm-admin-lib-personal-data[firstName][lastName]",
    templateUrl: "./personal-data.component.html",
    styleUrls: ["./personal-data.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalDataComponent {
    @Input() public set firstName(value: string | undefined) {
        this.localStore.patchState({ firstName: value ?? "" });
    }

    @Input() public set lastName(value: string | undefined) {
        this.localStore.patchState({ lastName: value ?? "" });
    }

    @Output() public personalDataChange: EventEmitter<PersonalData> = new EventEmitter<PersonalData>();

    protected readonly firstName$ = this.localStore.selectByKey("firstName");
    protected readonly lastName$ = this.localStore.selectByKey("lastName");

    protected readonly personalDataFormGroup = new FormGroup<PersonalDataForm>({
        firstName: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        lastName: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
    });

    constructor(private readonly localStore: LocalComponentStore<PersonalDataComponentState>) {
        this.localStore.setState({
            firstName: "",
            lastName: "",
        });
    }

    protected save(): void {
        if (this.personalDataFormGroup.valid) {
            this.personalDataChange.emit(this.personalDataFormGroup.getRawValue());
        } else {
            this.personalDataFormGroup.markAllAsTouched();
        }
    }

    protected changeEditMode(isEditMode: boolean) {
        if (isEditMode) {
            this.personalDataFormGroup.setValue({
                lastName: this.localStore.selectSnapshotByKey("lastName"),
                firstName: this.localStore.selectSnapshotByKey("firstName"),
            });
        } else {
            this.personalDataFormGroup.reset();
        }
    }
}
