import { HttpClient, HttpContext } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable } from "rxjs";
import { OPERATION_SCENARIO_STATEMENTS_ENDPOINTS, OperationScenarioStatementsEndpoints } from "../operation-scenario-statements.tokens";
import { StatementConfirmationParams } from "./operation-scenario-statements.models";

@Injectable()
export class OperationScenarioStatementConfirmationDocumentsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPERATION_SCENARIO_STATEMENTS_ENDPOINTS) private readonly endpoints: OperationScenarioStatementsEndpoints
    ) {}

    public getAdministrativeFeeFile(feeDocumentId: string): Observable<Blob> {
        return this.httpClient.get(
            StringUtils.replaceInTemplate(this.endpoints.downloadAdministrativeFeeFile, {
                feeDocumentId,
            }),
            {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            }
        );
    }

    public getSignatureConfirmation(params: StatementConfirmationParams): Observable<Blob> {
        return this.httpClient.get(
            StringUtils.replaceInTemplate(this.endpoints.downloadStatementConfirmationFile, {
                operatorId: params.operatorId,
                statementId: params.statementId,
            }),
            {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            }
        );
    }
}
