import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { PersonalData } from "../services/user-profile.models";

export namespace UserProfileActions {
    export class GetUserProfile {
        public static readonly type = "[User Profile] Get user profile";
        constructor(public userId: string) {}
    }

    export class GetProfileCapabilities {
        public static readonly type = "[User Profile] Get profile capabilities";
        constructor(public userId: string) {}
    }

    export class GetProfileAvatar {
        public static readonly type = "[User Profile] Get profile avatar";
        constructor(public userId: string) {}
    }

    export class SaveNewEmailAddress {
        public static readonly type = "[User Profile] Save new address email";
        constructor(public newEmailAddress: string) {}
    }

    export class SaveNewPhoneNumber {
        public static readonly type = "[User Profile] Save new phone number";
        constructor(public newPhoneNumber: PhoneNumber) {}
    }

    export class UpdateUserEmail {
        public static readonly type = "[User Profile] Update user email";
        constructor(public newEmailAddress: string) {}
    }

    export class UpdateUserPhoneNumber {
        public static readonly type = "[User Profile] Update user phone number";
        constructor(public newPhoneNumber: PhoneNumber) {}
    }

    export class ClearEmailAddressRequestChange {
        public static readonly type = "[User Profile] Clear address request change";
    }

    export class ClearPhoneRequestChange {
        public static readonly type = "[User Profile] Clear phone number request change";
    }

    export class SaveNewConversationsCategories {
        public static readonly type = "[User Profile] Save conversations categories";
        constructor(public newAssignedConversationCategoriesIds: string[]) {}
    }

    export class SaveNewPersonalData {
        public static readonly type = "[User Profile] Save personal data";
        constructor(public newPersonalData: PersonalData) {}
    }

    export class UpdateProfileLanguage {
        public static readonly type = "[User Profile] Update profile language";
        constructor(public language: LanguageCode) {}
    }

    export class ResetUserPassword {
        public static readonly type = "[User Profile] Reset user password";
        constructor() {}
    }

    export class DeleteAvatar {
        public static readonly type = "[User Profile] Delete user avatar";
    }

    export class SaveProfileAvatar {
        public static readonly type = "[User Profile] Save user avatar";
        constructor(public base64Image: string) {}
    }
}
