import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { OperationScenarioCategory, QualificationStatus } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import jsonToCsvExport from "json-to-csv-export";
import { Subject } from "rxjs";
import { ExamTrainingResultsAddDialogData, ExamTrainingResultsReportDetailsError } from "../../models/exam-training-results.models";

interface ExamTrainingResultsAddDialogComponentState {
    errors: ExamTrainingResultsReportDetailsError[];
}

@Component({
    selector: "dtm-admin-lib-exam-training-results-add-dialog[resultsList][pagination]",
    templateUrl: "./exam-training-results-add-dialog.component.html",
    styleUrls: ["./exam-training-results-add-dialog.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExamTrainingResultsAddDialogComponent {
    private readonly newValueSubject = new Subject<void>();
    public readonly newValue$ = this.newValueSubject.asObservable();
    protected readonly errors$ = this.localStore.selectByKey("errors");

    protected readonly trainingDisplayedColumns = ["name", "trainingTime", "trainingId"];
    protected readonly examDisplayedColumns = ["name", "trainingId"];
    protected readonly errorDisplayedColumns = ["cell", "errorType"];
    protected readonly OperationScenarioCategory = OperationScenarioCategory;
    protected readonly QualificationStatus = QualificationStatus;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ExamTrainingResultsAddDialogData,
        private readonly translocoService: TranslocoService,
        private readonly translationHelperService: TranslationHelperService,
        private readonly localStore: LocalComponentStore<ExamTrainingResultsAddDialogComponentState>
    ) {
        localStore.setState({
            errors: this.data.results.errors?.map((error) => ({ ...error, errorMsg: this.getErrorMessage(error) })) ?? [],
        });
    }

    protected downloadCSV(errors: ExamTrainingResultsReportDetailsError[]) {
        const dataToConvert = {
            data: errors.map(({ cell, errorMsg }) => ({
                column: cell || "-",
                nativeMsg: errorMsg,
            })),
            filename: "data",
            delimiter: ",",
            headers: [
                this.translocoService.translate("dtmAdminLibExamTrainingResults.dialog.cellLabel"),
                this.translocoService.translate("dtmAdminLibExamTrainingResults.dialog.errorTypeLabel"),
            ],
        };

        jsonToCsvExport(dataToConvert);
    }

    protected save() {
        this.newValueSubject.next();
    }

    private getErrorMessage({ errorMsg, errorMsgArgs }: ExamTrainingResultsReportDetailsError) {
        return this.translationHelperService.selectSystemTranslation(errorMsg, errorMsgArgs);
    }
}
