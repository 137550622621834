<mat-card>
    <dtm-ui-card-header
        headerSlot
        (edit)="headerButtonClicked.next()"
        [isEditButtonVisible]="isButtonVisible$ | ngrxPush"
        [buttonLabel]="headerButtonText$ | ngrxPush"
        icon="information"
    >
        {{ headerText$ | ngrxPush }}
    </dtm-ui-card-header>

    <div class="body">
        <div class="text">
            <ng-content select="[textSlot]"></ng-content>
        </div>
        <ng-content select="[imageSlot]"></ng-content>
    </div>

    <button type="button" class="full-width-button button-primary" (click)="actionButtonClicked.next()">
        {{ actionButtonText$ | ngrxPush }}
    </button>
</mat-card>
