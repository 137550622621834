import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
    OperationScenarioStatement,
    OperationScenarioStatementStatus,
    OperationScenarioStatementsError,
    StatementConfirmationParams,
} from "../../services/operation-scenario-statements.models";

interface OperationScenarioStatementsListComponentState {
    statementsList: OperationScenarioStatement[];
    statementsListError: OperationScenarioStatementsError | undefined;
    isProcessing: boolean;
    pagination: Page | undefined;
    expandedElement: OperationScenarioStatement | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operation-scenario-statements-list[operationScenarioStatementsList]",
    templateUrl: "./operation-scenario-statements-list.component.html",
    styleUrls: ["./operation-scenario-statements-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [LocalComponentStore],
})
export class OperationScenarioStatementsListComponent {
    @Input()
    public set operationScenarioStatementsList(value: OperationScenarioStatement[] | undefined) {
        this.localStore.patchState({ statementsList: value ?? [] });
    }

    @Input()
    public set operationScenarioStatementsListError(value: OperationScenarioStatementsError | undefined) {
        this.localStore.patchState({ statementsListError: value });
    }

    @Input()
    public set isOperationScenarioProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    protected readonly OperationScenarioStatementStatus = OperationScenarioStatementStatus;
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly statementsList$ = this.localStore.selectByKey("statementsList");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly statementsListError$ = this.localStore.selectByKey("statementsListError");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly displayedColumns = ["expandHandle", "status", "statementDate", "operatorNumber", "attorneyPower", "actions"];
    protected readonly ErrorMode = ErrorMode;

    @Output() public statementsListRefresh = new EventEmitter<void>();
    @Output() public pageChange = new EventEmitter<PageEvent>();
    @Output() public administrativeFeeDocumentDownload = new EventEmitter<string>();
    @Output() public signatureConfirmationFileGet = new EventEmitter<StatementConfirmationParams>();

    constructor(private readonly localStore: LocalComponentStore<OperationScenarioStatementsListComponentState>) {
        this.localStore.setState({
            statementsList: [],
            statementsListError: undefined,
            isProcessing: false,
            pagination: undefined,
            expandedElement: undefined,
        });
    }

    protected toggleExpandableRow(row: OperationScenarioStatement): void {
        this.localStore.patchState((state) => ({
            expandedElement: state.expandedElement === row ? undefined : row,
        }));
    }
}
