<ng-container *ngIf="interlocutor$ | ngrxPush as interlocutor">
    <div class="dialog-header">
        <h2 class="header" mat-dialog-title>
            {{ "dtmAdminLibConversation.newThread.headerLabel" | transloco : { interlocutor: interlocutor?.name } }} ({{
                interlocutor?.operatorNumber
            }})
        </h2>
        <button class="button-icon" type="button" (click)="close()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <ng-container *ngrxLet="isAttachmentsControlVisible$ as isAttachmentsControlVisible">
        <form [formGroup]="conversationForm" *ngIf="!(capabilitiesError$ | ngrxPush); else capabilitiesErrorTemplate" mat-dialog-content>
            <dtm-ui-new-thread-category-subject-form
                dtmUiMarkValueAccessorControlsAsTouched
                [categories]="conversationCategories$ | ngrxPush"
                formControlName="categoryAndSubject"
            >
            </dtm-ui-new-thread-category-subject-form>
            <dtm-ui-new-message-editor
                formControlName="editor"
                dtmUiMarkValueAccessorControlsAsTouched
                [isAttachmentsControlVisible]="isAttachmentsControlVisible"
            >
            </dtm-ui-new-message-editor>
        </form>
        <div mat-dialog-actions>
            <dtm-ui-new-message-actions
                [isAttachmentsButtonVisible]="!isAttachmentsControlVisible"
                (attachmentsButtonVisibilityChange)="changeAttachmentControlVisibility()"
                (messageDecline)="close()"
                (messageSend)="createThread(interlocutor)"
            ></dtm-ui-new-message-actions>
        </div>
    </ng-container>
</ng-container>

<ng-template #capabilitiesErrorTemplate>
    <dtm-ui-error
        (reload)="refreshCapabilities()"
        [errorMessage]="'dtmAdminLibConversation.newThread.capabilitiesDownloadError' | transloco"
    ></dtm-ui-error>
</ng-template>
