import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DialogService, FILES_UPLOAD_API_PROVIDER, PermitType, UploadedFile } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { PermitsTab } from "../../models/permits.models";
import { PERMITS_ENDPOINTS } from "../../permits.tokens";
import { operationalRestrictionsUploadApiFactory } from "../../services/operational-restrictions-upload-api.factory";
import { OperationalRestrictionsUploadApiService } from "../../services/operational-restrictions-upload-api.service";
import { OPERATIONAL_RESTRICTIONS_API_ENDPOINTS } from "../../services/operational-restrictions-upload-api.tokens";
import { PermitsActions } from "../../state/permits.actions";
import { PermitsState } from "../../state/permits.state";
import { KmlPreviewDialogComponent } from "../shared-form-section-components/kml-preview-dialog/kml-preview-dialog.component";

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-specific-permit-details",
    templateUrl: "./specific-permit-details.component.html",
    styleUrls: ["./specific-permit-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: OperationalRestrictionsUploadApiService },
        {
            provide: OPERATIONAL_RESTRICTIONS_API_ENDPOINTS,
            useFactory: operationalRestrictionsUploadApiFactory,
            deps: [PERMITS_ENDPOINTS],
        },
    ],
})
export class SpecificPermitDetailsComponent implements OnDestroy {
    protected readonly permit$ = this.store.select(PermitsState.permit).pipe(RxjsUtils.filterFalsy());
    protected readonly applicationPermitError$ = this.store.select(PermitsState.permitError);
    protected readonly operatorPermitType = this.route.snapshot.data.permitType;
    protected readonly PermitType = PermitType;
    protected readonly PermitsTab = PermitsTab;

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly transloco: TranslocoService,
        private readonly toastrService: ToastrService,
        private readonly dialogService: DialogService
    ) {}

    public ngOnDestroy() {
        this.store.dispatch(PermitsActions.ClearSpecificPermitDetails);
    }

    protected getSpecificPermit(): void {
        if (this.operatorPermitType === PermitType.CrossBorder) {
            this.store.dispatch(new PermitsActions.GetCrossBorderSpecificPermitDetails(this.route.snapshot.params.id));

            return;
        }
        this.store.dispatch(new PermitsActions.GetSpecificPermitDetails(this.route.snapshot.params.id));
    }
    protected previewKmlFile(file: UploadedFile): void {
        this.store
            .dispatch(new PermitsActions.GetKmlPreview(file))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.store.selectSnapshot(PermitsState.kmlFileForPreviewError)) {
                    this.toastrService.error(
                        this.transloco.translate("dtmAdminLibPermits.specificPermitDetails.cannotGetKmlForPreviewErrorMessage")
                    );

                    return;
                }

                this.dialogService.open(KmlPreviewDialogComponent, {
                    data: this.store.selectSnapshot(PermitsState.kmlFileForPreview),
                });
            });
    }
}
