<div class="layers-control" *ngrxLet="{ isOpen: isOpen$, layers: layers$ } as vm">
    <div class="container">
        <button type="button" class="toggle-button" [class.opened]="vm.isOpen" (click)="toggleControlVisibility()">
            {{ "dtmAdminLibAreaMap.layersControl.header" | transloco }}
            <dtm-ui-icon [name]="vm.isOpen ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
        </button>
        <div class="attachments-container" [class.visible]="vm.isOpen">
            <dtm-ui-files-upload
                (processedFilesAdd)="addLayers($event)"
                [processedFiles]="processedFiles$ | ngrxPush"
                (processedFileRemove)="removeProcessedFile($event)"
                [allowedTypes]="[ALLOWED_EXTENSION]"
            >
            </dtm-ui-files-upload>
            <div *ngIf="vm.layers.length" @slideIn>
                <dtm-ui-label-value [label]="'dtmAdminLibAreaMap.layersControl.attachmentsLabel' | transloco">
                    <div *ngFor="let layer of vm.layers" class="attachment">
                        <dtm-ui-icon name="attachment" class="attachment-icon"></dtm-ui-icon>
                        <span class="attachment-name">{{ layer.file.name }}</span>
                    </div>
                </dtm-ui-label-value>
                <button type="button" (click)="removeLayers()" class="button-secondary clear-button">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    {{ "dtmAdminLibAreaMap.layersControl.clearAttachmentsLabel" | transloco }}
                </button>
            </div>
        </div>
    </div>
</div>
