import { Alpha3CountryCode, IdentityDocument, IdentityDocumentStatus, Page, PhoneNumber } from "@dtm-frontend/shared/ui";
import { LegalGuardian, LegalGuardianConfirmation, NULL_VALUE } from "../../shared";

export interface UtmUser extends UtmUserBasic {
    isLegalAge: boolean;
}

export interface UtmUserBasic {
    id: string;
    status: UtmUserStatus;
    firstName: string;
    lastName: string;
    nationality: Alpha3CountryCode;
    isIdentityDocumentCompletionRequested: boolean;
    identityDocumentCompletionRequestDate?: Date;
    isLegalGuardianCompletionRequested: boolean;
    legalGuardianCompletionRequestDate?: Date;
}

export enum UtmUserStatus {
    Active = "ACTIVE",
    Waiting = "WAITING",
}

export interface UtmUserError {
    type: UtmUserErrorType;
}

export enum UtmUserErrorType {
    Unknown = "UtmUserErrorUnknown",
    NotFound = "UtmUserErrorNotFound",
    NotAuthorized = "UtmUserErrorNotAuthorized",
    CannotUpdateUser = "CannotUpdateUser",
    CannotDownloadDocument = "CannotDownloadDocument",
    CannotUpdateDocumentStatus = "CannotUpdateDocumentStatus",
    CannotUpdateLegalGuardian = "CannotUpdateLegalGuardian",
    CannotDeleteLegalGuardian = "CannotDeleteLegalGuardian",
    CannotGetLegalGuardianSignedDocument = "CannotGetLegalGuardianSignedDocument",
}

export interface UtmUserListWithPages {
    content: UtmUser[];
    page: Page;
}

export interface UtmUsersFiltersParams {
    page?: number;
    size?: number;
    searchText?: string;
    statuses?: string;
    isLegalAge?: string;
    isDefaultCountry?: string;
    isWaitingIdentityDocument?: string;
    isWaitingCompletionRequest?: string;
}

export enum UtmUsersFilterFormKeys {
    SearchText = "searchText",
    Statuses = "statuses",
    IsLegalAge = "isLegalAge",
    IsWaitingIdentityDocument = "isWaitingIdentityDocument",
    IsWaitingCompletionRequest = "isWaitingCompletionRequest",
    IsDefaultCountry = "isDefaultCountry",
}

export interface UtmUserFilters {
    [UtmUsersFilterFormKeys.SearchText]: string;
    [UtmUsersFilterFormKeys.Statuses]: UtmUserStatus[];
    [UtmUsersFilterFormKeys.IsDefaultCountry]: string | typeof NULL_VALUE;
    [UtmUsersFilterFormKeys.IsLegalAge]: string | typeof NULL_VALUE;
    [UtmUsersFilterFormKeys.IsWaitingIdentityDocument]: string | typeof NULL_VALUE;
    [UtmUsersFilterFormKeys.IsWaitingCompletionRequest]: string | typeof NULL_VALUE;
}

export interface UtmUserDetails {
    id: string;
    basicData: UtmUserBasicData;
    identityDocument: IdentityDocument;
    legalGuardian?: LegalGuardian;
    legalGuardianConfirmationRequest?: LegalGuardianConfirmation;
    isEasaMember: boolean;
    avatarId: string;
    status: UtmUserStatus;
    isLegalAge: boolean;
    identityDocumentCompletionRequestDate?: Date;
    legalGuardianCompletionRequestDate?: Date;
}

export interface UtmUserBasicData {
    firstName: string;
    lastName: string;
    nationality: Alpha3CountryCode;
    phoneNumber: PhoneNumber;
    email: string;
    dateOfBirth: string;
}

export interface UtmUserIdentityCardStatusRequest {
    status: IdentityDocumentStatus;
    statusReason?: string;
}
