import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { ConversationActions } from "../state/conversation.actions";

@Injectable({
    providedIn: "root",
})
export class MessagesResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        const operatorId = route.params.operatorId;
        const searchByText = route.queryParams.searchByText;
        this.store.dispatch(ConversationActions.ClearStoredAvatarPictures);

        return await lastValueFrom(
            this.store.dispatch([
                new ConversationActions.GetOperatorCapabilities(operatorId, searchByText),
                new ConversationActions.GetOperatorThreads({ stakeholderId: operatorId }),
            ])
        );
    }
}
