import { CentersFilterParams } from "../models/centers.models";
import { ExaminationCenter } from "../models/examination-centers.models";
import { TrainingCenter } from "../models/training-centers.models";

export namespace CentersActions {
    export class GetExaminationCenters {
        public static readonly type = "[Centers] Get examination centers list";
        constructor(public filtersQuery: CentersFilterParams) {}
    }

    export class AddExaminationCenter {
        public static readonly type = "[Centers] Add examination center";
        constructor(public center: ExaminationCenter) {}
    }

    export class EditExaminationCenter {
        public static readonly type = "[Centers] Edit examination center";
        constructor(public center: ExaminationCenter) {}
    }

    export class DeleteExaminationCenter {
        public static readonly type = "[Centers] Delete examination center";
        constructor(public centerId: string) {}
    }

    export class GetCapabilities {
        public static readonly type = "[Centers] Get capabilities";
    }

    export class GetOperatorsList {
        public static readonly type = "[Centers] Get operators list";
        constructor(public searchPhrase: string) {}
    }

    export class GetOperatorDetails {
        public static readonly type = "[Centers] Get operator details";
        constructor(public operatorId: string) {}
    }

    export class AddTrainingCenter {
        public static readonly type = "[Centers] Add training center";
        constructor(public center: TrainingCenter) {}
    }

    export class EditTrainingCenter {
        public static readonly type = "[Centers] Edit training center";
        constructor(public center: TrainingCenter) {}
    }

    export class DeleteTrainingCenter {
        public static readonly type = "[Centers] Delete training center";
        constructor(public centerId: string) {}
    }

    export class GetTrainingCentersList {
        public static readonly type = "[Centers] Get training centers list";
        constructor(public filtersQuery: CentersFilterParams) {}
    }
}
