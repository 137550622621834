<dtm-ui-loader-container [isShown]="isUserProfileProcessing$ | ngrxPush">
    <div class="grid main-container">
        <div class="grid-col-8 side-container">
            <dtm-admin-lib-utm-users-filters
                (filtersChange)="applyFilters($event)"
                *ngIf="filtersQuery$ | ngrxPush as filters"
                [initialFilters]="filters"
            ></dtm-admin-lib-utm-users-filters>
            <dtm-admin-lib-utm-user-list
                *ngIf="userList$ | ngrxPush as userList"
                [isListProcessing]="isUserListProcessing$ | ngrxPush"
                [selectedUserId]="(selectedUser$ | ngrxPush)?.id"
                [users]="userList"
                (userSelect)="userSelected($event)"
                [pagination]="page$ | ngrxPush"
                [userListError]="userListError$ | ngrxPush"
                (userListRefresh)="refreshList()"
                (pageChange)="changePage($event)"
            ></dtm-admin-lib-utm-user-list>
        </div>
        <div class="grid-col-4 profile-container">
            <ng-container *ngIf="userError$ | ngrxPush as error; else userProfileTemplate">
                <dtm-ui-error (reload)="refreshList()"></dtm-ui-error>
            </ng-container>
            <ng-template #userProfileTemplate>
                <dtm-admin-lib-utm-user-profile
                    *ngIf="selectedUser$ | ngrxPush as selectedUser; else selectUserTemplate"
                    [user]="selectedUser"
                    (basicDataEdit)="editBasicData(selectedUser)"
                    (documentDownload)="downloadDocument(selectedUser)"
                    (documentAccept)="acceptDocument(selectedUser)"
                    (documentReject)="rejectDocument(selectedUser)"
                    (documentUpdateRequest)="requestForDocumentUpdate(selectedUser)"
                    (legalGuardianDetailsEdit)="editLegalGuardianDetails(selectedUser)"
                    (legalGuardianDelete)="deleteLegalGuardian(selectedUser)"
                    (legalGuardianDetailsFill)="fillLegalGuardianDetails(selectedUser)"
                    (legalGuardianUpdateRequest)="requestLegalGuardianUpdate(selectedUser)"
                    (legalGuardianSignedDocumentDownload)="downloadLegalGuardianSignedDocument(selectedUser.id)"
                >
                </dtm-admin-lib-utm-user-profile>
            </ng-template>
        </div>
    </div>
</dtm-ui-loader-container>
<ng-template #selectUserTemplate>
    <div class="select-user">
        <dtm-ui-icon name="error-warning-fill"></dtm-ui-icon>
        <p>{{ "dtmAdminLibUtmUsers.userProfile.selectUserHint" | transloco }}</p>
    </div>
</ng-template>
