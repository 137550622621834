import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { DashboardActions } from "../state/dashboard.actions";

@Injectable({
    providedIn: "root",
})
export class DashboardResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
        return lastValueFrom(this.store.dispatch(new DashboardActions.GetDashboardDetails()));
    }
}
