<dtm-ui-presentation-tile-with-action
    [titleTile]="'dtmAdminLibShared.operatorProfile.otherInformationTile.header' | transloco"
    *ngrxLet="{
        otherInformationList: otherInformation$,
        areActionsAvailable: areActionsAvailable$,
    } as vm"
>
    <button *ngIf="vm.areActionsAvailable" actionButtonSlot class="button-secondary" type="button" (click)="otherInformationAdd.emit()">
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ "dtmAdminLibShared.operatorProfile.otherInformationTile.addLabel" | transloco }}
    </button>
    <div class="container" *ngIf="vm.otherInformationList.length">
        <div *ngFor="let otherInformation of vm.otherInformationList" class="row">
            <div class="info-with-icon">
                <dtm-ui-icon name="file-info"></dtm-ui-icon>
                <p>{{ otherInformation.text }}</p>
            </div>
            <button *ngIf="vm.areActionsAvailable" class="button-icon" type="button" [matMenuTriggerFor]="menu">
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button type="button" (click)="otherInformationEdit.emit(otherInformation)" mat-menu-item>
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.otherInformationTile.editLabel" | transloco }}
                </button>
                <button type="button" (click)="otherInformationDelete.emit(otherInformation.id)" mat-menu-item>
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.otherInformationTile.deleteLabel" | transloco }}
                </button>
            </mat-menu>
        </div>
    </div>
</dtm-ui-presentation-tile-with-action>
