<dtm-admin-lib-stats-tile [header]="'dtmAdminLibDashboard.operatorStats.header' | transloco">
    <mat-list listSlot *ngIf="operatorStats$ | ngrxPush as operatorStats">
        <dtm-admin-lib-stats-list-item
            [redirectLink]="'/operators'"
            [redirectQueryParams]="{ status: OperatorStatus.PendingApproval, page: 0 }"
            iconsClassName="time"
            [statsName]="'dtmAdminLibDashboard.operatorStats.pendingApprovalLabel' | transloco"
            [statsCount]="operatorStats.pendingApprovalCount"
        ></dtm-admin-lib-stats-list-item>
        <dtm-admin-lib-stats-list-item
            [redirectLink]="'/operators'"
            [redirectQueryParams]="{ status: OperatorStatus.ApprovalInProgress, page: 0 }"
            iconsClassName="user-settings"
            [statsName]="'dtmAdminLibDashboard.operatorStats.approvalInProgressLabel' | transloco"
            [statsCount]="operatorStats.approvalInProgressCount"
        ></dtm-admin-lib-stats-list-item>
        <dtm-admin-lib-stats-list-item
            [redirectLink]="'/operators'"
            [redirectQueryParams]="{ status: OperatorStatus.Suspended, page: 0 }"
            iconsClassName="forbid"
            [statsName]="'dtmAdminLibDashboard.operatorStats.suspendedLabel' | transloco"
            [statsCount]="operatorStats.suspendedCount"
        ></dtm-admin-lib-stats-list-item>
        <dtm-admin-lib-stats-list-item
            [redirectLink]="'/operators'"
            [redirectQueryParams]="{ powerOfAttorneyStatus: AttorneyPowerStatus.PendingApproval, page: 0 }"
            iconsClassName="file-list"
            [statsName]="'dtmAdminLibDashboard.operatorStats.attorneyPowersPendingApprovalLabel' | transloco"
            [statsCount]="operatorStats.attorneyPowersPendingApprovalCount"
        ></dtm-admin-lib-stats-list-item>
        <dtm-admin-lib-stats-list-item
            [redirectLink]="'/operators'"
            [redirectQueryParams]="{ isWaitingCompetencyConfirmation: true, page: 0 }"
            iconsClassName="shield-star"
            [statsName]="'dtmAdminLibDashboard.operatorStats.waitingCompetencyConfirmationsCount' | transloco"
            [statsCount]="operatorStats.waitingCompetencyConfirmationsCount"
        ></dtm-admin-lib-stats-list-item>
    </mat-list>

    <ng-container bottomRedirectSlot>
        <button class="button-tertiary redirect-button" type="button" [routerLink]="['/operators']">
            {{ "dtmAdminLibDashboard.operatorStats.redirectToOperatorsList" | transloco }}
        </button>
    </ng-container>
</dtm-admin-lib-stats-tile>
