import { Page } from "@dtm-frontend/shared/ui";
import { ExamTrainingResultsMode } from "./exam-training-results.models";

export enum TrainingResultsErrorType {
    CannotGetTrainingResultsList = "CannotGetTrainingResultsList",
}

export interface TrainingResultListWithPages extends Page {
    content: TrainingResult[];
}

export interface TrainingResultsError {
    type: TrainingResultsErrorType;
}

export interface TrainingResult {
    id: string;
    name: string;
    operatorNumber: string;
    trainingNativeName: string;
    trainingEnglishName: string;
    startDate: Date;
    endDate: Date;
    trainingMode: ExamTrainingResultsMode;
    trainingOperatorName: string;
}
