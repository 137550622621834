import { AirRiskMitigationsCategory } from "@dtm-frontend/shared/mission";

export interface FlightPurpose {
    id: string;
    name: string;
    description: string;
}

export enum GroundRiskBufferType {
    Standard = "STANDARD",
    Individual = "INDIVIDUAL",
}

export enum PilotCompetencies {
    A1 = "A1",
    A2 = "A2",
    A3 = "A3",
    STS01 = "STS-01",
    STS02 = "STS-02",
    PDRAG01 = "PDRA-G01",
    PDRAG02 = "PDRA-G02",
    NSTS01 = "NSTS-01",
    NSTS02 = "NSTS-02",
    NSTS03 = "NSTS-03",
    NSTS04 = "NSTS-04",
    NSTS05 = "NSTS-05",
    NSTS06 = "NSTS-06",
    NSTS07 = "NSTS-07",
    NSTS08 = "NSTS-08",
}

export type PilotOperatorQualifications = Exclude<PilotCompetencies, PilotCompetencies.PDRAG02 | PilotCompetencies.PDRAG01>;

export const OPERATIONAL_AUTHORIZATIONS: PilotOperatorQualifications[] = [
    PilotCompetencies.STS01,
    PilotCompetencies.STS02,
    PilotCompetencies.NSTS01,
    PilotCompetencies.NSTS02,
    PilotCompetencies.NSTS03,
    PilotCompetencies.NSTS04,
    PilotCompetencies.NSTS05,
    PilotCompetencies.NSTS06,
    PilotCompetencies.NSTS07,
    PilotCompetencies.NSTS08,
];

export const PILOT_COMPETENCIES: PilotOperatorQualifications[] = [
    PilotCompetencies.A1,
    PilotCompetencies.A2,
    PilotCompetencies.A3,
    PilotCompetencies.STS01,
    PilotCompetencies.STS02,
    PilotCompetencies.NSTS01,
    PilotCompetencies.NSTS02,
    PilotCompetencies.NSTS03,
    PilotCompetencies.NSTS04,
    PilotCompetencies.NSTS05,
    PilotCompetencies.NSTS06,
    PilotCompetencies.NSTS07,
    PilotCompetencies.NSTS08,
];

export enum RobustnessLevel {
    None = "NONE",
    Low = "LOW",
    Medium = "MEDIUM",
    High = "HIGH",
    HighPlus = "HIGH_PLUS",
}

export enum M1AStatementsKeys {
    lowerPeopleDensity = "dtmWebAppLibShared.groundRiskMitigationsStatements.m1A.lowerPeopleDensity",
    bystandersShielded = "dtmWebAppLibShared.groundRiskMitigationsStatements.m1A.bystandersShielded",
}

export enum M1BStatementsKeys {
    ableToIdentifyRiskOnTheGround = "dtmWebAppLibShared.groundRiskMitigationsStatements.m1B.ableToIdentifyRiskOnTheGround",
    ableToReduceNumberOfBystandersAtRisk = "dtmWebAppLibShared.groundRiskMitigationsStatements.m1B.ableToReduceNumberOfBystandersAtRisk",
    abnormalSituationAlert = "dtmWebAppLibShared.groundRiskMitigationsStatements.m1B.abnormalSituationAlert",
    abnormalSituationCommand = "dtmWebAppLibShared.groundRiskMitigationsStatements.m1B.abnormalSituationCommand",
    bystandersSafeDistance = "dtmWebAppLibShared.groundRiskMitigationsStatements.m1B.bystandersSafeDistance",
    operationalProceduresDocumented = "dtmWebAppLibShared.groundRiskMitigationsStatements.m1B.operationalProceduresDocumented",
}

export enum M2StatementsKeys {
    parachute = "dtmWebAppLibShared.groundRiskMitigationsStatements.m2.parachute",
    criticalAreaShielded = "dtmWebAppLibShared.groundRiskMitigationsStatements.m2.criticalAreaShielded",
    other = "dtmWebAppLibShared.groundRiskMitigationsStatements.m2.other",
    operatorHasDevelopedProcedures = "dtmWebAppLibShared.groundRiskMitigationsStatements.m2.operatorHasDevelopedProcedures",
    operatorProvidesPracticalAndTheoreticalStaffTraining = "dtmWebAppLibShared.groundRiskMitigationsStatements.m2.operatorProvidesPracticalAndTheoreticalStaffTraining",
    parachuteOperatingInstructionsIsAttached = "dtmWebAppLibShared.groundRiskMitigationsStatements.m2.parachuteOperatingInstructionsIsAttached",
}

export enum AirRiskMitigationSectionKeys {
    commonFlightRules = "dtmWebAppLibShared.airRiskMitigationSectionKey.commonFlightRules",
    commonAirspaceStructures = "dtmWebAppLibShared.airRiskMitigationSectionKey.commonAirspaceStructures",
    timeSlotScheduledFlight = "dtmWebAppLibShared.airRiskMitigationSectionKey.timeSlotScheduledFlight",
    geographicAreaLimitation = "dtmWebAppLibShared.airRiskMitigationSectionKey.geographicAreaLimitation",
    limitationOfFlightTime = "dtmWebAppLibShared.airRiskMitigationSectionKey.limitationOfFlightTime",
}

export enum TacticalRiskMitigationMeasuresCategory {
    DETECT = "detect",
    DECIDE = "decide",
    COMMAND = "command",
    EXECUTE = "execute",
    FEEDBACK_LOOP = "feedbackLoop",
}

export const AIR_RISK_MITIGATIONS_CATEGORIES = [
    AirRiskMitigationsCategory.CtrCore,
    AirRiskMitigationsCategory.Ctr,
    AirRiskMitigationsCategory.MctrCore,
    AirRiskMitigationsCategory.Mctr,
    AirRiskMitigationsCategory.Rmz,
    AirRiskMitigationsCategory.AtzCore,
    AirRiskMitigationsCategory.Atz,
    AirRiskMitigationsCategory.Hlz,
    AirRiskMitigationsCategory.EmptyAirspace,
    AirRiskMitigationsCategory.Mtma,
    AirRiskMitigationsCategory.Other,
    AirRiskMitigationsCategory.Tma,
];

export interface OperationalGeometryData {
    safetyAreaWidth: number;
    safetyAreaHeight: number;
    groundRiskBuffer?: number;
    groundRiskBufferType: GroundRiskBufferType | null;
    adjacentAreaBufferHorizontal: number;
    adjacentAreaHeight: number;
    flightAreaMaxRadius: number;
    flightAreaMaxDeclaredHeight: number;
    operationAreaMaxRadius: number;
    flightAreaMaxHeight: number;
    operationAreaMaxHeight: number;
    flightAreaEstimatedDistance: number;
    flightAreaMaxWidth: number;
    operationAreaMaxWidth: number;
    operationAreaMaxDeclaredHeight: number;
    flightAreaSize: number;
    operationAreaSize: number;
}
