<dtm-ui-presentation-tile-with-action
    *ngrxLet="{ type: type$, identifier: identifier$ } as vm"
    [titleTile]="'dtmAdminLibShared.operatorProfile.changeLog.title' | transloco"
>
    <button
        *ngIf="vm.type && vm.identifier"
        actionButtonSlot
        class="button-secondary"
        type="button"
        (click)="redirectToChangeLog(vm.type, vm.identifier)"
    >
        <dtm-ui-icon name="eye"></dtm-ui-icon>
        {{ "dtmAdminLibShared.operatorProfile.changeLog.actionButtonLabel" | transloco }}
    </button>
</dtm-ui-presentation-tile-with-action>
