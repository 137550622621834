import { PageCriteria } from "../services/operator.models";

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_NUMBER = 0;

export const DEFAULT_OPERATOR_PAGE: PageCriteria = {
    sort: {
        sorted: false,
        unsorted: true,
        empty: false,
    },
    pageNumber: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE,
    paged: true,
    unpaged: false,
    offset: 0,
};
