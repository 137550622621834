<div class="grid grid-maxsize-8 grid-maxsize-md-12" *ngIf="!(areErrors$ | ngrxPush); else errorTemplate">
    <dtm-ui-lib-wizard-content [wizardId]="CROSS_BORDER_PERMIT_WIZARD_ID">
        <ng-container
            *ngrxLet="{ isProcessing: isProcessing$, existingPermitDetails: existingPermitDetails$, uavModels: uavModels$ } as vm"
        >
            <dtm-ui-lib-wizard-step-content [stepId]="CrossBorderPermitWizardSteps.BasicInfo">
                <dtm-admin-lib-cross-border-permit-basic-data
                    [caaUsers]="caaUsers$ | ngrxPush"
                    [existingPermitDetails]="vm.existingPermitDetails"
                    [dtmLocations]="dtmLocations$ | ngrxPush"
                    [competencies]="competencies$ | ngrxPush"
                    [operators]="operators$ | ngrxPush"
                    [areUavsAvailable]="!!vm.uavModels?.length"
                    [isProcessing]="vm.isProcessing"
                    (searchTextChanged)="findOperatorByText($event)"
                    (operatorSelect)="assignOperator($event)"
                    (basicDataFormSave)="storeBasicData($event)"
                    (kmlFilePreview)="previewKmlFile($event)"
                    (back)="goToPermitsList()"
                ></dtm-admin-lib-cross-border-permit-basic-data>
            </dtm-ui-lib-wizard-step-content>
            <dtm-ui-lib-wizard-step-content [stepId]="CrossBorderPermitWizardSteps.UavDetails">
                <dtm-admin-lib-permit-uav-details-step
                    [existingPermitDetails]="vm.existingPermitDetails"
                    [uavModels]="vm.uavModels"
                    [selectedOperator]="selectedOperator$ | ngrxPush"
                    (uavDetailsFormSave)="storeUavDetails($event)"
                    (back)="setActiveStep(CrossBorderPermitWizardSteps.BasicInfo)"
                ></dtm-admin-lib-permit-uav-details-step>
            </dtm-ui-lib-wizard-step-content>
            <dtm-ui-lib-wizard-step-content [stepId]="CrossBorderPermitWizardSteps.Summary">
                <dtm-admin-lib-cross-border-permit-summary
                    [isUpdateMode]="!!vm.existingPermitDetails"
                    [permit]="permit$ | ngrxPush"
                    [isProcessing]="vm.isProcessing"
                    (stepEdit)="setActiveStep($event)"
                    (back)="setActiveStep(CrossBorderPermitWizardSteps.UavDetails)"
                    (addPermit)="addOrUpdatePermit()"
                    (kmlFilePreview)="previewKmlFile($event)"
                ></dtm-admin-lib-cross-border-permit-summary>
            </dtm-ui-lib-wizard-step-content>
        </ng-container>
    </dtm-ui-lib-wizard-content>
</div>
<ng-template #errorTemplate>
    <dtm-ui-error
        (reload)="retryFetchData()"
        [mode]="ErrorMode.Secondary"
        [errorMessage]="'dtmAdminLibPermits.container.cannotGetCapabilitiesOrOperatorError' | transloco"
    ></dtm-ui-error>
</ng-template>
