<dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
    <div class="grid profile-cards-container" *ngIf="userProfile$ | ngrxPush as userProfile; else errorTemplate">
        <div class="grid-col-6 column">
            <dtm-admin-lib-personal-data
                [firstName]="userProfile.firstName"
                [lastName]="userProfile.lastName"
                (personalDataChange)="personalDataChange($event)"
            ></dtm-admin-lib-personal-data>
            <dtm-ui-user-contact
                [email]="userProfile.email"
                [phoneNumber]="userProfile.phoneNumber"
                [isVerificationRequired]="false"
                [hasRequestPhoneChangeConflictError]="hasPhoneNumberConflictError$ | ngrxPush"
                [hasRequestEmailChangeConflictError]="hasEmailAddressConflictError$ | ngrxPush"
                [isPhoneNumberEditMode]="isPhoneNumberEditMode$ | ngrxPush"
                [isEmailAddressEditMode]="isEmailAddressEditMode$ | ngrxPush"
                [saveButtonLabel]="'dtmAdminLibUserProfile.profileContainer.saveButtonLabel' | transloco"
                (requestEmailAddressChange)="saveNewEmailAddress($event)"
                (requestPhoneNumberChange)="saveNewPhoneNumber($event)"
                (clearEmailAddressChange)="clearEmailAddressChange()"
                (clearPhoneNumberChange)="clearPhoneNumberChange()"
            ></dtm-ui-user-contact>
            <dtm-admin-lib-conversation-categories
                [conversationCategories]="conversationCategories$ | ngrxPush"
                [assignedConversationCategories]="assignedConversationCategories$ | ngrxPush"
                (conversationCategoriesChange)="conversationCategoriesChanged($event)"
            ></dtm-admin-lib-conversation-categories>
        </div>
        <div class="grid-col-6 column">
            <dtm-ui-profile-avatar
                [canRemove]="userProfile.avatarId"
                [fullName]="fullName$ | ngrxPush"
                [avatar]="profileAvatar$ | ngrxPush"
                (saveAvatar)="saveProfileAvatar($event)"
                (delete)="deleteUserAvatar()"
            ></dtm-ui-profile-avatar>
            <dtm-ui-profile-language
                [currentLanguage]="currentLanguage$ | ngrxPush"
                (updateLanguage)="updateProfileLanguage($event)"
            ></dtm-ui-profile-language>
            <dtm-ui-reset-password (resetPassword)="resetPassword()"></dtm-ui-reset-password>
        </div>
    </div>
    <ng-template #errorTemplate>
        <ng-container *ngrxLet="error$ as error">
            <dtm-ui-error
                [mode]="ErrorMode.Secondary"
                [errorMessage]="'dtmAdminLibUserProfile.profileContainer.profileUnknownErrorMessage' | transloco"
                (reload)="reloadUserProfile()"
            ></dtm-ui-error>
        </ng-container>
    </ng-template>
</dtm-ui-loader-container>
