import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Notification, NotificationDisplay } from "@dtm-frontend/shared/notifications";
import { LocalComponentStore, StringUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import {
    CaaConversationNewMessageNotificationRequestPayload,
    CaaConversationNotification,
    CaaConversationNotificationType,
    CaaConversationThreadGeneralNotificationRequestPayload,
} from "../../models/notification.models";
import { CaaNotificationsService } from "../../services/caa-notifications.service";

interface NotificationsComponentState {
    notificationList: NotificationDisplay[] | undefined;
}

const MAX_THREAD_NAME_LENGTH = 50;

@Component({
    selector: "dtm-admin-lib-notifications[notificationsList]",
    templateUrl: "./notifications.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NotificationsComponent {
    @Input() public set notificationsList(value: Notification[] | undefined) {
        if (value) {
            this.localStore.patchState({ notificationList: this.formatNotificationList(value) });
        } else {
            this.localStore.patchState({ notificationList: [] });
        }
    }

    public readonly notificationsList$ = this.localStore.selectByKey("notificationList");

    constructor(
        private readonly localStore: LocalComponentStore<NotificationsComponentState>,
        private readonly translocoService: TranslocoService,
        private readonly caaNotificationsService: CaaNotificationsService
    ) {
        localStore.setState({ notificationList: undefined });
    }

    private formatNotificationList(notificationsToFormat: Notification[]): NotificationDisplay[] {
        const conversationEvents: CaaConversationNotification[] = notificationsToFormat.filter((notification: Notification) =>
            this.caaNotificationsService.getConversationNotificationTypes().some((type) => notification.type === type)
        ) as CaaConversationNotification[];

        return this.getConversationEventsList(conversationEvents);
    }

    private getConversationRedirectUrl(operatorId: string | undefined, threadId: string): string | undefined {
        if (!operatorId) {
            return;
        }

        if (threadId) {
            return `messages/${operatorId}?threadId=${threadId}`;
        }

        return `messages/${operatorId}`;
    }

    private getConversationEventsList(conversationEvents: CaaConversationNotification[]): NotificationDisplay[] {
        return conversationEvents
            .map<NotificationDisplay>((notification: CaaConversationNotification) => {
                const threadName = StringUtils.ellipsifyText(notification.payload.threadName, MAX_THREAD_NAME_LENGTH);

                switch (notification.type) {
                    case CaaConversationNotificationType.NewMessageToAssigneeEvent:
                        return this.getNotificationDisplayForNewMessageReceivedEvent(notification);
                    case CaaConversationNotificationType.ThreadAssignedEvent:
                        return this.getNotificationDisplayForThreadAssignedEvent(notification, threadName);
                    case CaaConversationNotificationType.ThreadUnassignedEvent:
                        return this.getNotificationDisplayForThreadUnassignedEvent(notification, threadName);
                    case CaaConversationNotificationType.ThreadClosedEvent:
                        return this.getNotificationDisplayForThreadClosedEvent(notification, threadName);
                }
            })
            .filter(Boolean);
    }

    private getNotificationDisplayForNewMessageReceivedEvent(notification: Notification): NotificationDisplay {
        // eslint-disable-next-line no-case-declarations
        const newMessageReceivedPayload = notification.payload as CaaConversationNewMessageNotificationRequestPayload;

        return {
            icon: "mail",
            title: this.translocoService.translate("dtmAdminLibShared.notifications.newMessageLabel"),
            text: this.translocoService.translate("dtmAdminLibShared.notifications.newMessageText", {
                sender: newMessageReceivedPayload.sender.operatorName,
            }),
            date: notification.createdAt,
            id: notification.id,
            isRead: notification.read,
            redirectUrl: this.getConversationRedirectUrl(newMessageReceivedPayload.sender.operatorId, newMessageReceivedPayload.threadId),
        };
    }
    private getNotificationDisplayForThreadAssignedEvent(notification: Notification, threadName: string): NotificationDisplay {
        // eslint-disable-next-line no-case-declarations
        const threadAssignedPayload = notification.payload as CaaConversationThreadGeneralNotificationRequestPayload;

        return {
            icon: "mail",
            title: this.translocoService.translate("dtmAdminLibShared.notifications.newAssignmentLabel"),
            text: this.translocoService.translate("dtmAdminLibShared.notifications.newAssignmentText", {
                threadName,
            }),
            date: notification.createdAt,
            id: notification.id,
            isRead: notification.read,
            redirectUrl: this.getConversationRedirectUrl(threadAssignedPayload.stakeholderId, threadAssignedPayload.threadId),
        };
    }
    private getNotificationDisplayForThreadUnassignedEvent(notification: Notification, threadName: string): NotificationDisplay {
        // eslint-disable-next-line no-case-declarations
        const threadUnassignedPayload = notification.payload as CaaConversationThreadGeneralNotificationRequestPayload;

        return {
            icon: "mail",
            title: this.translocoService.translate("dtmAdminLibShared.notifications.unassignedLabel"),
            text: this.translocoService.translate("dtmAdminLibShared.notifications.unassignedText", {
                threadName,
            }),
            date: notification.createdAt,
            id: notification.id,
            isRead: notification.read,
            redirectUrl: this.getConversationRedirectUrl(threadUnassignedPayload.stakeholderId, threadUnassignedPayload.threadId),
        };
    }
    private getNotificationDisplayForThreadClosedEvent(notification: Notification, threadName: string): NotificationDisplay {
        // eslint-disable-next-line no-case-declarations
        const threadClosedPayload = notification.payload as CaaConversationThreadGeneralNotificationRequestPayload;

        return {
            icon: "mail",
            title: this.translocoService.translate("dtmAdminLibShared.notifications.closedThreadLabel"),
            text: this.translocoService.translate("dtmAdminLibShared.notifications.closedThreadText", {
                threadName,
            }),
            date: notification.createdAt,
            id: notification.id,
            isRead: notification.read,
            redirectUrl: this.getConversationRedirectUrl(threadClosedPayload.stakeholderId, threadClosedPayload.threadId),
        };
    }
}
