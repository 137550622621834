import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { ExaminationCenter } from "./examination-centers.models";
import { TrainingCenter } from "./training-centers.models";

export enum CentersFilterFormKeys {
    SearchPhrase = "searchPhrase",
    ActiveTabIndex = "activeTabIndex",
}

export enum CentersTabType {
    ExaminationEntities = 0,
    TrainingOperators = 1,
}

export enum CentersActionType {
    Add = "Add",
    Edit = "Edit",
    Delete = "Delete",
}

export interface CentersFilterParams {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    [CentersFilterFormKeys.ActiveTabIndex]?: number;
    [CentersFilterFormKeys.SearchPhrase]?: string;
}

export enum CentersFiltersTabKeys {
    ExaminationCentersFilters = "examinationCentersFilters",
    TrainingCentersFilters = "trainingCentersFilters",
}

export type CentersListData = ExaminationCenter | TrainingCenter;
