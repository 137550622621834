import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils, SQUARE_METERS_IN_SQUARE_KILOMETER } from "@dtm-frontend/shared/utils";
import { OperationalGeometryData } from "../../../mission/models/mission.model";

interface OperationalAreaGeometryComponentState {
    operationalGeometryData: OperationalGeometryData | undefined;
}

@Component({
    selector: "dtm-web-app-lib-operational-area-geometry[operationalGeometryData]",
    templateUrl: "./operational-area-geometry.component.html",
    styleUrls: ["./operational-area-geometry.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperationalAreaGeometryComponent {
    @Input() public set operationalGeometryData(value: OperationalGeometryData | undefined) {
        this.localStore.patchState({ operationalGeometryData: value });
    }

    protected readonly operationalGeometryData$ = this.localStore.selectByKey("operationalGeometryData").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<OperationalAreaGeometryComponentState>) {
        this.localStore.setState({
            operationalGeometryData: undefined,
        });
    }

    protected convertSquareMetersToSquareKilometers(value: number | undefined): number | undefined {
        if (!value) {
            return;
        }

        return value / SQUARE_METERS_IN_SQUARE_KILOMETER;
    }
}
