<dtm-ui-presentation-tile-with-action
    *ngIf="basicData$ | ngrxPush as basicData"
    [titleTile]="'dtmAdminLibUtmUsers.userProfile.basicDataTile.title' | transloco"
>
    <button actionButtonSlot class="button-secondary" type="button" (click)="basicDataEdit.emit()">
        <dtm-ui-icon name="edit"></dtm-ui-icon>
        {{ "dtmAdminLibUtmUsers.userProfile.editLabel" | transloco }}
    </button>
    <div class="content">
        <dtm-ui-label-value
            [label]="'dtmAdminLibUtmUsers.userProfile.basicDataTile.emailAddressLabel' | transloco"
            [value]="basicData.email"
        >
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmAdminLibUtmUsers.userProfile.basicDataTile.phoneNumberLabel' | transloco"
            [value]="basicData.phoneNumber | formatPhoneNumber"
        >
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmAdminLibUtmUsers.userProfile.basicDataTile.dateOfBirthLabel' | transloco"
            [value]="basicData.dateOfBirth | localizeDate"
        >
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmAdminLibUtmUsers.userProfile.basicDataTile.nationalityLabel' | transloco">
            <dtm-ui-country-display [country]="basicData.nationality"> </dtm-ui-country-display>
        </dtm-ui-label-value>
    </div>
</dtm-ui-presentation-tile-with-action>
