import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OperationScenarioCategory } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperatorDetails } from "../../../models/operators.model";

interface OperatorDetailsComponentState {
    operatorDetails: OperatorDetails | undefined;
}

@Component({
    selector: "dtm-admin-lib-operator-details[operatorDetails]",
    templateUrl: "./operator-details.component.html",
    styleUrls: ["./operator-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorDetailsComponent {
    @Input() public set operatorDetails(value: OperatorDetails | undefined) {
        this.localStore.patchState({ operatorDetails: value });
    }

    protected readonly operatorDetails$ = this.localStore.selectByKey("operatorDetails");

    constructor(private readonly localStore: LocalComponentStore<OperatorDetailsComponentState>) {
        this.localStore.setState({
            operatorDetails: undefined,
        });
    }

    protected readonly OperationScenarioCategory = OperationScenarioCategory;
}
