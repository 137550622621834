import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, MILLISECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { NewOperationalAuthorization, OperationScenario } from "../../../models/operator.models";

interface AddOperationalAuthorizationComponentState {
    maxDates: Date[];
    minDate: Date;
}

interface OperationalAuthorizationForm {
    operationScenario: FormControl<OperationScenario | null>;
    expirationDate: FormControl<Date | null>;
    uavSerialNumbers: FormControl<string[] | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-add-operational-authorization",
    templateUrl: "./add-operational-authorization.component.html",
    styleUrls: ["./add-operational-authorization.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AddOperationalAuthorizationComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;
    protected readonly operationalAuthorizationsArray = new FormArray<FormGroup<OperationalAuthorizationForm>>([]);
    protected readonly form = new FormGroup<{ operationalAuthorizations: FormArray }>({
        operationalAuthorizations: this.operationalAuthorizationsArray,
    });
    protected readonly availableOperationScenarios = this.data.availableOperationScenarios;
    private readonly newValueSubject = new Subject<NewOperationalAuthorization[]>();
    public readonly newValue$ = this.newValueSubject.asObservable();
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private readonly data: {
            availableOperationScenarios: OperationScenario[];
        },
        private readonly localStore: LocalComponentStore<AddOperationalAuthorizationComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localStore.setState({
            maxDates: [],
            minDate: new Date(),
        });
        this.addOperationalAuthorizationFormGroup();
    }

    protected addOperationalAuthorizationFormGroup() {
        const newOperationalAuthorization = this.getNewOperationalAuthorizationFormGroup();
        this.operationalAuthorizationsArray.push(newOperationalAuthorization);
        this.initControlsValidityCheckAfterChange(newOperationalAuthorization.controls.operationScenario);
        this.localStore.patchState({
            maxDates: [...this.localStore.selectSnapshotByKey("maxDates"), new Date()],
        });
    }

    protected handleOperationScenarioValueChange(value: OperationScenario, formGroup: FormGroup, operationalAuthorizationIndex: number) {
        this.assignMaxDateFromSelectedValue(value, formGroup, operationalAuthorizationIndex);
        this.manageUavSerialNumbersControl(!!value.areUavsRequired, formGroup.controls.uavSerialNumbers);
    }

    private manageUavSerialNumbersControl(areUavsRequired: boolean, uavSerialNumbersControl: AbstractControl) {
        if (areUavsRequired && !uavSerialNumbersControl.enabled) {
            uavSerialNumbersControl.enable();
        } else if (!areUavsRequired && uavSerialNumbersControl.enabled) {
            uavSerialNumbersControl.reset();
            uavSerialNumbersControl.disable();
        }
    }

    private assignMaxDateFromSelectedValue(value: OperationScenario, formGroup: FormGroup, operationalAuthorizationIndex: number) {
        formGroup.controls.expirationDate.enable();
        const valueGrantValidityMaxDate = new Date(new Date().setMonth(new Date().getMonth() + value.grantValidityInMonths));
        const maxDates = this.localStore.selectSnapshotByKey("maxDates");

        if (value.expirationDate) {
            const valueExpirationDate = new Date(value.expirationDate);
            maxDates[operationalAuthorizationIndex] =
                valueGrantValidityMaxDate > valueExpirationDate ? valueExpirationDate : valueGrantValidityMaxDate;
        } else {
            maxDates[operationalAuthorizationIndex] = valueGrantValidityMaxDate;
        }

        this.localStore.patchState({ maxDates });
        formGroup.controls.expirationDate.setValue(maxDates[operationalAuthorizationIndex]);
    }

    protected validateAndSubmit() {
        this.operationalAuthorizationsArray.markAllAsTouched();

        if (this.operationalAuthorizationsArray.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        const newOperationalAuthorizations: NewOperationalAuthorization[] = this.operationalAuthorizationsArray.value.map(
            (operationalAuthorization) => {
                if (operationalAuthorization.expirationDate) {
                    // TODO: REJ-2772 - Remove after fixing the issue with the date picker and codebase-wide date interpretation
                    operationalAuthorization.expirationDate = new Date(
                        operationalAuthorization.expirationDate.getTime() -
                            operationalAuthorization.expirationDate.getTimezoneOffset() * MILLISECONDS_IN_MINUTE
                    );
                }

                return {
                    id: (operationalAuthorization.operationScenario as OperationScenario).id,
                    expirationDate: operationalAuthorization.expirationDate as Date,
                    uavSerialNumbers: operationalAuthorization.uavSerialNumbers as string[],
                };
            }
        );
        this.newValueSubject.next(newOperationalAuthorizations);
    }

    private getNewOperationalAuthorizationFormGroup(): FormGroup<OperationalAuthorizationForm> {
        return new FormGroup<OperationalAuthorizationForm>({
            operationScenario: new FormControl(null, {
                validators: [Validators.required, (control: AbstractControl) => this.getOperationScenarioUniquenessValidator(control)],
            }),
            expirationDate: new FormControl({ value: null, disabled: true }, Validators.required),
            uavSerialNumbers: new FormControl({ value: null, disabled: true }),
        });
    }

    private getOperationScenarioUniquenessValidator(operationScenarioControl: AbstractControl) {
        if (operationScenarioControl.value) {
            const controlsIncludingSelectedValue = this.operationalAuthorizationsArray.controls.filter(
                (operationalAuthorization) =>
                    operationalAuthorization.controls.operationScenario !== operationScenarioControl &&
                    operationalAuthorization.controls.operationScenario.value?.id === operationScenarioControl.value.id
            );

            if (controlsIncludingSelectedValue.length) {
                return { notUnique: true };
            }
        }

        return null;
    }

    private initControlsValidityCheckAfterChange(control: AbstractControl) {
        control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            const operationScenarioControls = this.operationalAuthorizationsArray.controls.map(
                (operationalAuthorization) => operationalAuthorization.controls.operationScenario
            );
            operationScenarioControls.forEach((operationScenario) => {
                if (operationScenario.value && operationScenario.hasError("notUnique")) {
                    operationScenario.updateValueAndValidity({ emitEvent: false });
                }
            });
        });
    }
}
