import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Address, BasicAddress } from "@dtm-frontend/shared/ui";
import { DEFAULT_COUNTRY_CODE, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

interface AddressDetailsComponentState {
    address: Address | undefined;
    isEditModeOn: boolean;
    label: string | undefined;
}

@Component({
    selector: "dtm-web-app-lib-address-details[address]",
    templateUrl: "./address-details.component.html",
    styleUrls: ["./address-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AddressDetailsComponent {
    @Input() public set address(value: Address | undefined) {
        this.localStore.patchState({ address: value, isEditModeOn: false });
    }
    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    @Output() public save = new EventEmitter<Address>();

    protected readonly address$ = this.localStore.selectByKey("address").pipe(RxjsUtils.filterFalsy());
    protected readonly label$ = this.localStore.selectByKey("label");
    protected readonly isEditModeOn$ = this.localStore.selectByKey("isEditModeOn");
    protected readonly countryControl = new FormControl(DEFAULT_COUNTRY_CODE, { nonNullable: true, validators: Validators.required });
    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;

    protected readonly basicAddressControl = new FormControl<BasicAddress>({
        streetName: "",
        houseNumber: "",
        apartmentNumber: "",
        postCode: "",
        city: "",
    });

    protected readonly addressFormGroup = new FormGroup({
        basicAddress: this.basicAddressControl,
        country: this.countryControl,
    });

    constructor(private readonly localStore: LocalComponentStore<AddressDetailsComponentState>) {
        this.localStore.setState({
            address: undefined,
            isEditModeOn: false,
            label: undefined,
        });
    }

    protected saveForm(): void {
        this.addressFormGroup.markAllAsTouched();

        if (this.addressFormGroup.invalid) {
            return;
        }

        this.save.emit({ ...(this.basicAddressControl.getRawValue() as BasicAddress), country: this.countryControl.getRawValue() });
    }

    protected manageFormState(isEditModeOn: boolean): void {
        const addressState = this.localStore.selectSnapshotByKey("address");

        if (!isEditModeOn) {
            this.basicAddressControl.reset();
            this.localStore.patchState({ isEditModeOn });

            return;
        }

        if (!addressState) {
            return;
        }

        this.localStore.patchState({ isEditModeOn });

        this.basicAddressControl.patchValue({
            streetName: addressState.streetName,
            houseNumber: addressState.houseNumber,
            apartmentNumber: addressState.apartmentNumber,
            city: addressState.city,
            postCode: addressState.postCode,
        });
        this.countryControl.patchValue(addressState.country);
    }
}
