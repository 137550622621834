<form [formGroup]="associationIdForm">
    <dtm-ui-radio-group formControlName="associationRegistrationIdType" (change)="changeAssociationRegistrationIdType($event.value)">
        <label class="label">{{ "dtmWebAppLibShared.associationOperatorIdDetails.associationRegistrationIdTypeLabel" | transloco }}</label>

        <dtm-ui-radio-field [value]="AssociationRegistrationIdType.NationalCourtRegister">
            {{
                "dtmWebAppLibShared.associationOperatorIdDetails.associationRegistrationIdTypeValue"
                    | transloco : { type: AssociationRegistrationIdType.NationalCourtRegister }
            }}
        </dtm-ui-radio-field>
        <dtm-ui-radio-field [value]="AssociationRegistrationIdType.RegistrationNumber">
            {{
                "dtmWebAppLibShared.associationOperatorIdDetails.associationRegistrationIdTypeValue"
                    | transloco : { type: AssociationRegistrationIdType.RegistrationNumber }
            }}
        </dtm-ui-radio-field>
        <dtm-ui-form-errors class="preserve-error-space">
            <div class="field-error" *dtmUiFieldHasError="associationIdForm.controls.associationRegistrationIdType; name: 'required'">
                {{ "dtmWebAppLibShared.associationOperatorIdDetails.requiredFieldError" | transloco }}
            </div>
        </dtm-ui-form-errors>
    </dtm-ui-radio-group>

    <dtm-ui-input-field *ngIf="associationIdForm.controls.associationRegistrationId.enabled" @slideIn>
        <label>{{
            "dtmWebAppLibShared.associationOperatorIdDetails.associationRegistrationIdTypeValue"
                | transloco : { type: associationIdForm.controls.associationRegistrationIdType.value }
        }}</label>
        <input
            matInput
            type="text"
            formControlName="associationRegistrationId"
            [mask]="
                associationIdForm.controls.associationRegistrationIdType.value === AssociationRegistrationIdType.NationalCourtRegister
                    ? NATIONAL_COURT_REGISTRATION_MASK
                    : null
            "
            [showMaskTyped]="
                associationIdForm.controls.associationRegistrationIdType.value === AssociationRegistrationIdType.NationalCourtRegister
            "
        />
        <div class="field-error" *dtmUiFieldHasError="associationIdForm.controls.associationRegistrationId; name: ['required', 'pattern']">
            {{ "dtmWebAppLibShared.associationOperatorIdDetails.requiredFieldError" | transloco }}
        </div>
        <div
            class="field-error"
            *dtmUiFieldHasError="associationIdForm.controls.associationRegistrationId; name: 'maxlength'; error as error"
        >
            {{
                "dtmWebAppLibShared.associationOperatorIdDetails.associationRegistrationIdMaxLengthError"
                    | transloco : { max: error.requiredLength }
            }}
        </div>
        <div
            class="field-error"
            *dtmUiFieldHasError="associationIdForm.controls.associationRegistrationId; name: 'minlength'; error as error"
        >
            {{
                "dtmWebAppLibShared.associationOperatorIdDetails.associationRegistrationIdMinLengthError"
                    | transloco : { min: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>
</form>
