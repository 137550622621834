import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ButtonTheme, ConfirmationDialogComponent, DialogService, ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ExamResultsError } from "../../models/exam-results.models";
import { ExamTrainingResultsMode, ExamTrainingResultsTabType, ResultsListData } from "../../models/exam-training-results.models";
import { TrainingResultsError } from "../../models/training-results.models";

interface ExamTrainingResultsListComponentState {
    resultsList: ResultsListData[];
    resultsListError: ExamResultsError | TrainingResultsError | undefined;
    isResultsListProcessing: boolean;
    pagination: Page | undefined;
    activeTabIndex: ExamTrainingResultsTabType | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-exam-training-results-list[resultsList][pagination]",
    templateUrl: "./exam-training-results-list.component.html",
    styleUrls: ["./exam-training-results-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class ExamTrainingResultsListComponent {
    @Input() public set resultsList(value: ResultsListData[] | undefined) {
        this.localStore.patchState({ resultsList: value ?? [] });
    }
    @Input() public set isResultsListProcessing(value: BooleanInput) {
        this.localStore.patchState({ isResultsListProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set resultsListError(value: ExamResultsError | TrainingResultsError | undefined) {
        this.localStore.patchState({ resultsListError: value });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }
    @Input() public set activeTabIndex(value: ExamTrainingResultsTabType | undefined) {
        this.localStore.patchState({ activeTabIndex: value });
    }

    @Output() public readonly resultsListRefresh = new EventEmitter<void>();
    @Output() public readonly pageChange = new EventEmitter<PageEvent>();
    @Output() public readonly deleteResults = new EventEmitter<string>();

    protected readonly resultsList$ = this.localStore.selectByKey("resultsList");
    protected readonly isResultsListProcessing$ = this.localStore.selectByKey("isResultsListProcessing");
    protected readonly resultsListError$ = this.localStore.selectByKey("resultsListError");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly activeTabIndex$ = this.localStore.selectByKey("activeTabIndex");

    protected readonly ExamTrainingResultsTabType = ExamTrainingResultsTabType;
    protected readonly ExamTrainingResultsMode = ExamTrainingResultsMode;
    protected readonly displayedColumns = ["name", "operatorNumber", "englishName", "date", "mode", "operatorName", "actions"];
    protected readonly ErrorMode = ErrorMode;

    constructor(
        private readonly localStore: LocalComponentStore<ExamTrainingResultsListComponentState>,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            resultsList: [],
            isResultsListProcessing: false,
            resultsListError: undefined,
            pagination: undefined,
            activeTabIndex: ExamTrainingResultsTabType.Exams,
        });
    }

    protected delete(resultsId: string): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("dtmAdminLibExamTrainingResults.dialog.deleteDialogConfirmationLabel"),
                    shouldHideBody: true,
                    confirmButtonLabel: this.translocoService.translate(
                        "dtmAdminLibExamTrainingResults.dialog.deleteDialogConfirmationButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.deleteResults.emit(resultsId));
    }

    protected areResultsStationary(results: ResultsListData): boolean {
        const mode = "examMode" in results ? results.examMode : results.trainingMode;

        return mode === ExamTrainingResultsMode.Stationary;
    }
}
