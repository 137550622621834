import { DOCUMENT } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, Inject, NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CaaAdminLibModule } from "@dtm-frontend/caa-admin-lib";
import { ADMIN_USERS_ENDPOINTS, AdminUsersModule } from "@dtm-frontend/caa-admin-lib/admin-users";
import { AreaMapModule } from "@dtm-frontend/caa-admin-lib/area-map";
import { AUDIT_ENDPOINTS, AuditModule } from "@dtm-frontend/caa-admin-lib/audit";
import { CENTERS_ENDPOINTS, CentersModule } from "@dtm-frontend/caa-admin-lib/centers";
import { CONVERSATION_ENDPOINTS, ConversationModule } from "@dtm-frontend/caa-admin-lib/conversation";
import { DASHBOARD_ENDPOINTS, DashboardModule } from "@dtm-frontend/caa-admin-lib/dashboard";
import { EXAM_TRAINING_RESULTS_ENDPOINTS, ExamTrainingResultsModule } from "@dtm-frontend/caa-admin-lib/exam-training-results";
import {
    OPERATION_SCENARIO_STATEMENTS_ENDPOINTS,
    OperationScenarioStatementsModule,
} from "@dtm-frontend/caa-admin-lib/operation-scenario-statements";
import { OPERATOR_ENDPOINTS, OperatorModule, PILOT_ENDPOINTS } from "@dtm-frontend/caa-admin-lib/operator";
import { PERMITS_ENDPOINTS, PermitsModule } from "@dtm-frontend/caa-admin-lib/permits";
import {
    CaaFeatures,
    FilesManagementApiService,
    GLOBAL_CAPABILITIES_ENDPOINTS,
    GlobalCapabilitiesActions,
    GlobalCapabilitiesState,
    SharedModule,
} from "@dtm-frontend/caa-admin-lib/shared";
import { USER_PROFILE_ENDPOINTS, UserProfileModule } from "@dtm-frontend/caa-admin-lib/user-profile";
import { UTM_USERS_ENDPOINTS, UtmUsersModule } from "@dtm-frontend/caa-admin-lib/utm-users";
import { AuthState, KEYCLOAK_CONFIG, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { WEATHER_ENDPOINTS, WeatherModule } from "@dtm-frontend/shared/map/geo-weather";
import { GEO_ZONES_ENDPOINTS, GeoZonesModule } from "@dtm-frontend/shared/map/geo-zones";
import { IS_EXTERNAL_APP_BUTTON_VISIBLE, MISSION_SEARCH_ENDPOINTS, MissionSearchModule, UAV_APP_URL } from "@dtm-frontend/shared/mission";
import { NOTIFICATIONS_ENDPOINTS, SharedNotificationsModule } from "@dtm-frontend/shared/notifications";
import { FILES_UPLOAD_API_PROVIDER, SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TRANSLATION_ENDPOINTS,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { VERSION_DATA_ENDPOINTS, VersionModule } from "@dtm-frontend/shared/ui/version";
import { FunctionUtils, Logger, LoggerModule, RxjsUtils } from "@dtm-frontend/shared/utils";
import { SharedWebsocketModuleConfig, WEBSOCKET_CONFIGURATION } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { forkJoin, lastValueFrom } from "rxjs";
import { filter, first, switchMap, tap } from "rxjs/operators";
import { CaaAdminEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { LazyRouterOutletComponent } from "./components/lazy-router-outlet/lazy-router-outlet.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ActionsHandler } from "./services/actions.handler";

Logger.initialize("https://f3face57356501c13013b2baf9b940de@sentry.pansa.cloud/3");

export function createAppModule(environment: CaaAdminEnvironment) {
    @NgModule({
        declarations: [AppComponent, LazyRouterOutletComponent, HeaderComponent, MenuComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            HttpClientModule,
            CaaAdminLibModule,
            MatButtonModule,
            MatIconModule,
            MatMenuModule,
            MatProgressSpinnerModule,
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "CaaAdmin",
            }),
            OperatorModule.forRoot(),
            DashboardModule.forRoot(),
            ConversationModule.forRoot(),
            PermitsModule.forRoot(),
            AdminUsersModule.forRoot(),
            OperationScenarioStatementsModule.forRoot(),
            CentersModule.forRoot(),
            ExamTrainingResultsModule.forRoot(),
            LetModule,
            PushModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            SharedAuthModule.forRoot({
                bearerExcludedUrls: [...environment.bearerExcludedUrls],
            }),
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            SharedUiModule,
            VersionModule.forRoot(),
            MatBadgeModule,
            SharedNotificationsModule.forRoot(),
            SharedModule,
            MatTooltipModule,
            UserProfileModule.forRoot(),
            UtmUsersModule.forRoot(),
            CesiumMapModule,
            AreaMapModule,
            AuditModule.forRoot(),
            MissionSearchModule.forRoot(),
            GeoZonesModule.forRoot(),
            WeatherModule.forRoot(),
        ],
        providers: [
            {
                provide: DASHBOARD_ENDPOINTS,
                useValue: environment.dashboardEndpoints,
            },
            {
                provide: OPERATOR_ENDPOINTS,
                useValue: environment.operatorEndpoints,
            },
            {
                provide: PERMITS_ENDPOINTS,
                useValue: environment.permitsEndpoints,
            },
            {
                provide: CENTERS_ENDPOINTS,
                useValue: environment.centersEndpoints,
            },
            {
                provide: EXAM_TRAINING_RESULTS_ENDPOINTS,
                useValue: environment.examTrainingResultsEndpoints,
            },
            {
                provide: PILOT_ENDPOINTS,
                useValue: environment.pilotEndpoints,
            },
            {
                provide: OPERATION_SCENARIO_STATEMENTS_ENDPOINTS,
                useValue: environment.operationScenarioStatementsEndpoints,
            },
            {
                provide: VERSION_DATA_ENDPOINTS,
                useValue: environment.versionDataEndpoints,
            },
            {
                provide: CONVERSATION_ENDPOINTS,
                useValue: environment.conversationEndpoints,
            },
            {
                provide: NOTIFICATIONS_ENDPOINTS,
                useValue: environment.notificationEndpoints,
            },
            {
                provide: ADMIN_USERS_ENDPOINTS,
                useValue: environment.adminUsersEndpoints,
            },
            {
                provide: USER_PROFILE_ENDPOINTS,
                useValue: environment.userProfileEndpoints,
            },
            {
                provide: GLOBAL_CAPABILITIES_ENDPOINTS,
                useValue: environment.globalCapabilitiesEndpoints,
            },
            {
                provide: UTM_USERS_ENDPOINTS,
                useValue: environment.utmUsersEndpoints,
            },
            {
                provide: FILES_UPLOAD_API_PROVIDER,
                useClass: FilesManagementApiService,
            },
            {
                provide: SHARED_MAP_ENDPOINTS,
                useValue: environment.sharedMapEndpoints,
            },
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler],
                multi: true,
            },
            {
                provide: TRANSLATION_ENDPOINTS,
                useValue: environment.translationEndpoints,
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: AUDIT_ENDPOINTS,
                useValue: environment.auditEndpoints,
            },
            {
                provide: MISSION_SEARCH_ENDPOINTS,
                useValue: environment.missionSearchEndpoints,
            },
            {
                provide: UAV_APP_URL,
                useValue: environment.uavAppUrl,
            },
            {
                provide: IS_EXTERNAL_APP_BUTTON_VISIBLE,
                useFactory: (store: Store) => store.selectSnapshot(GlobalCapabilitiesState.isFeatureAvailable(CaaFeatures.MissionList)),
                deps: [Store],
            },
            {
                provide: WEBSOCKET_CONFIGURATION,
                useFactory: (store: Store): SharedWebsocketModuleConfig => ({
                    endpoint: environment.webSocketEndpoint,
                    authorizationTokenProvider: async () =>
                        await lastValueFrom(store.select(AuthState.token).pipe(RxjsUtils.filterFalsy(), first())),
                }),
                deps: [Store],
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "caaAdmin",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: GEO_ZONES_ENDPOINTS,
                useValue: environment.geoZonesEndpoints,
            },
            {
                provide: WEATHER_ENDPOINTS,
                useValue: environment.weatherEndpoints,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(
            keycloakService: KeycloakService,
            store: Store,
            translocoHelper: TranslationHelperService,
            titleService: Title,
            @Inject(DOCUMENT) document: Document
        ) {
            // TODO: is this a good place for some initializing tasks? Maybe it should be extracted somewhere, use APP_INITIALIZER?
            const gotUserData$ = keycloakService.keycloakEvents$.pipe(
                filter((event) => event?.type === KeycloakEventType.OnReady),
                first(),
                switchMap(() => store.dispatch([new GlobalCapabilitiesActions.GetGlobalCapabilities()]))
            );

            const gotTitle$ = translocoHelper
                .waitForTranslation("caaAdmin.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)));

            forkJoin([gotUserData$, gotTitle$])
                .pipe(
                    first(),
                    tap(() => {
                        document.getElementById("loader")?.remove();
                    })
                )
                .subscribe();
        }
    }

    return AppModule;
}
