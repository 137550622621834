<ng-container *ngrxLet="filtersCount$ as filtersCount">
    <dtm-ui-filters-container [filtersCount]="filtersCount">
        <div class="filters-content">
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibOperationScenario.operationsScenarioFilters.operatorNumberLabel" | transloco }}</label>
                <input class="control-search" matInput type="text" [formControl]="operatorNumberControl" />
                <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
            </dtm-ui-input-field>
            <dtm-ui-select-field [multiple]="true" [formControl]="paymentStatusControl">
                <label>{{ "dtmAdminLibOperationScenario.operationsScenarioFilters.paymentStatusLabel" | transloco }}</label>
                <dtm-ui-select-option [value]="OperationScenarioStatementStatus.Error">
                    {{
                        "dtmAdminLibOperationScenario.statementsList.paymentStatus"
                            | transloco : { value: OperationScenarioStatementStatus.Error }
                    }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="OperationScenarioStatementStatus.Completed">
                    {{
                        "dtmAdminLibOperationScenario.statementsList.paymentStatus"
                            | transloco : { value: OperationScenarioStatementStatus.Completed }
                    }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="OperationScenarioStatementStatus.Waiting">
                    {{
                        "dtmAdminLibOperationScenario.statementsList.paymentStatus"
                            | transloco : { value: OperationScenarioStatementStatus.Waiting }
                    }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <dtm-ui-select-field [multiple]="true" [formControl]="signStatusControl">
                <label>{{ "dtmAdminLibOperationScenario.operationsScenarioFilters.signatureStatusLabel" | transloco }}</label>
                <dtm-ui-select-option [value]="OperationScenarioStatementStatus.Error">
                    {{
                        "dtmAdminLibOperationScenario.statementsList.signatureStatus"
                            | transloco : { value: OperationScenarioStatementStatus.Error }
                    }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="OperationScenarioStatementStatus.Completed">
                    {{
                        "dtmAdminLibOperationScenario.statementsList.signatureStatus"
                            | transloco : { value: OperationScenarioStatementStatus.Completed }
                    }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="OperationScenarioStatementStatus.Waiting">
                    {{
                        "dtmAdminLibOperationScenario.statementsList.signatureStatus"
                            | transloco : { value: OperationScenarioStatementStatus.Waiting }
                    }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
        </div>
    </dtm-ui-filters-container>
    <dtm-ui-filter-chips-display
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="filtersCount"
        [formGroup]="filtersFormGroup"
        [filtersValue]="filtersFormGroup.value"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</ng-container>
