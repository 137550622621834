import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ErrorMode, Page, PermitType } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import {
    PermitActionMap,
    PermitActionType,
    PermitStatus,
    PermitStatusChange,
    PermitsError,
    SpecificPermit,
} from "../../models/permits.models";

interface SpecificPermitsListComponentState {
    specificPermitsList: SpecificPermit[];
    specificPermitsListError: PermitsError | undefined;
    isSpecificPermitsProcessing: boolean;
    pagination: Page | undefined;
    expandedElement: SpecificPermit | undefined;
}

const actionButtonMap: PermitActionMap = {
    [PermitStatus.Active]: [PermitActionType.Withdraw, PermitActionType.Suspend],
    [PermitStatus.Suspended]: [PermitActionType.Withdraw, PermitActionType.Restore],
};

@Component({
    selector: "dtm-admin-lib-specific-permits-list[specificPermitsList][pagination]",
    templateUrl: "./specific-permits-list.component.html",
    styleUrls: ["./specific-permits-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class SpecificPermitsListComponent {
    @Input() public set specificPermitsList(value: SpecificPermit[] | undefined) {
        this.localStore.patchState({ specificPermitsList: value ?? [] });
    }
    @Input() public set isSpecificPermitsProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isSpecificPermitsProcessing: !!value });
    }
    @Input() public set specificPermitsListError(value: PermitsError | undefined) {
        this.localStore.patchState({ specificPermitsListError: value });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Output() public specificPermitsListRefresh = new EventEmitter<void>();
    @Output() public pageChange = new EventEmitter<PageEvent>();
    @Output() public openDialog = new EventEmitter<PermitStatusChange>();

    protected readonly PermitActionType = PermitActionType;
    protected readonly PermitType = PermitType;
    protected readonly PermitStatus = PermitStatus;
    protected readonly ErrorMode = ErrorMode;
    protected readonly specificPermitsList$ = this.localStore.selectByKey("specificPermitsList");
    protected readonly isSpecificPermitsProcessing$ = this.localStore.selectByKey("isSpecificPermitsProcessing");
    protected readonly specificPermitsListError$ = this.localStore.selectByKey("specificPermitsListError");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly displayedColumns = [
        "expandHandle",
        "status",
        "consentNumber",
        "operatorName",
        "validityPeriod",
        "permitName",
        "actions",
    ];

    constructor(private readonly localStore: LocalComponentStore<SpecificPermitsListComponentState>) {
        this.localStore.setState({
            specificPermitsList: [],
            specificPermitsListError: undefined,
            isSpecificPermitsProcessing: false,
            pagination: undefined,
            expandedElement: undefined,
        });
    }

    protected shouldDisplayActionButton(status: PermitStatus, action: PermitActionType): boolean {
        return Array.isArray(actionButtonMap[status]) ? actionButtonMap[status].includes(action) : false;
    }

    protected updatePermitStatus(action: PermitActionType, permitId: string): void {
        this.openDialog.emit({
            action,
            permitId,
        });
    }

    protected toggleExpandableRow(row: SpecificPermit): void {
        this.localStore.patchState((state) => ({
            expandedElement: state.expandedElement === row ? undefined : row,
        }));
    }

    protected getEditionRoute(permitType: PermitType): string {
        switch (permitType) {
            case PermitType.Association:
                return "/association-permit-flight-edit";
            case PermitType.OperationPermission:
                return "/specific-permit-flight-edit";
            case PermitType.CrossBorder:
                return "/cross-border-permit-flight-edit";
        }
    }

    protected getDetailsRoute(permitType: PermitType): string {
        switch (permitType) {
            case PermitType.Association:
                return "/association-permit-details";
            case PermitType.CrossBorder:
                return "/cross-border-permit-details";
            default:
                return "/specific-permit-details";
        }
    }
}
