import { OperatorStatus } from "@dtm-frontend/shared/ui";
import { AttorneyPowerStatus, NULL_VALUE, OperationScenario, Operator, OperatorType, PermissionsToGrant } from "../../shared";

export interface QualificationsError {
    type: QualificationsErrorType;
}

export enum QualificationsErrorType {
    CannotAdd = "CannotAdd",
    CannotRemove = "CannotRemove",
    CannotSuspend = "CannotSuspend",
    CannotWithdraw = "CannotWithdraw",
    CannotActivate = "CannotActivate",
    CannotGetAvailableOperationScenarios = "CannotGetAvailableOperationScenarios",
    CannotSuspendAll = "CannotSuspendAll",
}

export interface OperatorListWithPages extends Page {
    content: Operator[];
}

export interface Sort {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
}

export interface Page {
    totalElements: number;
    pageNumber: number;
    pageSize: number;
}

export interface PageCriteria {
    sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
    offset: number;
}

export enum OperatorFilterFormKeys {
    SearchByText = "searchByText",
    Type = "type",
    IsDefaultCountryRegistration = "isDefaultCountryRegistration",
    Status = "status",
    PowerOfAttorneyStatus = "powerOfAttorneyStatus",
    IsWaitingCompetencyConfirmation = "isWaitingCompetencyConfirmation",
}

export interface OperatorFilters {
    [OperatorFilterFormKeys.SearchByText]: string;
    [OperatorFilterFormKeys.Type]: OperatorType | typeof NULL_VALUE;
    [OperatorFilterFormKeys.IsDefaultCountryRegistration]: string | typeof NULL_VALUE;
    [OperatorFilterFormKeys.Status]: OperatorStatus[];
    [OperatorFilterFormKeys.PowerOfAttorneyStatus]: AttorneyPowerStatus | typeof NULL_VALUE;
    [OperatorFilterFormKeys.IsWaitingCompetencyConfirmation]: boolean | typeof NULL_VALUE;
}

export interface OperatorsFilterParams {
    page?: number;
    size?: number;
    searchByText?: string;
    type?: string;
    status?: string;
    isDefaultCountryRegistration?: string;
    powerOfAttorneyStatus?: string;
    isWaitingCompetencyConfirmation?: boolean;
}

export interface OperatorsCapabilities {
    permissionsToGrant: PermissionsToGrant[];
    operationalAuthorizations: OperationScenario[];
    competencies: OperationScenario[];
}

export interface CapabilitiesError {
    type: CapabilitiesErrorType;
}

export enum CapabilitiesErrorType {
    CannotGetCapabilities = "CannotGetCapabilities",
}
