import { InjectionToken } from "@angular/core";

export interface OperationScenarioStatementsEndpoints {
    operationScenarioStatementsList: string;
    downloadAdministrativeFeeFile: string;
    downloadStatementConfirmationFile: string;
}

export const OPERATION_SCENARIO_STATEMENTS_ENDPOINTS = new InjectionToken<OperationScenarioStatementsEndpoints>(
    "Operation Scenario Statements endpoints"
);
