import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ContextOperator } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { MissionDataSimple, MissionPlanDataAndCapabilities, MissionPlanRoute } from "../../models";

interface MissionDataComponentState {
    missionPlanDataAndCapabilities: MissionPlanDataAndCapabilities | undefined;
    operators: { [key: string]: ContextOperator } | undefined;
    route: MissionPlanRoute | undefined;
    operatorName: string | undefined;
}

@Component({
    selector: "dtm-mission-mission-data[missionPlanDataAndCapabilities]",
    templateUrl: "./mission-data.component.html",
    styleUrls: ["./mission-data.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDataComponent {
    @Input() public set operators(value: { [key: string]: ContextOperator } | undefined) {
        this.localStore.patchState({ operators: value });
    }
    @Input() public set operatorName(value: string | undefined) {
        this.localStore.patchState({ operatorName: value });
    }
    @Input() public set missionPlanDataAndCapabilities(value: MissionPlanDataAndCapabilities | undefined) {
        this.localStore.patchState({ missionPlanDataAndCapabilities: value });
    }
    @Input() public set route(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ route: value });
    }

    protected readonly mission$ = combineLatest([
        this.localStore.selectByKey("missionPlanDataAndCapabilities"),
        this.localStore.selectByKey("operatorName"),
        this.localStore.selectByKey("operators"),
        this.localStore.selectByKey("route"),
    ]).pipe(
        map(([missionPlanDataAndCapabilities, operatorName, operators, route]) =>
            this.prepareMissionData(missionPlanDataAndCapabilities, operatorName, operators, route)
        )
    );

    protected readonly missionPlanDataAndCapabilities$ = this.localStore.selectByKey("missionPlanDataAndCapabilities");

    constructor(private readonly localStore: LocalComponentStore<MissionDataComponentState>) {
        this.localStore.setState({
            operators: undefined,
            missionPlanDataAndCapabilities: undefined,
            route: undefined,
            operatorName: undefined,
        });
    }

    private prepareMissionData(
        missionPlanDataAndCapabilities: MissionPlanDataAndCapabilities | undefined,
        operatorName: string | undefined,
        operators: { [key: string]: ContextOperator } | undefined,
        route: MissionPlanRoute | undefined
    ): MissionDataSimple | undefined {
        if (!missionPlanDataAndCapabilities?.plan) {
            return undefined;
        }

        const missionPlan = missionPlanDataAndCapabilities.plan;
        const missionCapabilities = missionPlan.capabilities;

        return {
            isRoutePathBased: !!missionPlan.route?.isPathBased,
            flightStartAtMin: missionPlan.flightStartAtMin,
            flightStartAtMax: missionPlan.flightStartAtMax,
            flightFinishAtMin: missionPlan.flightFinishAtMin,
            flightFinishAtMax: missionPlan.flightFinishAtMax,
            phase: missionPlan.phase,
            distance: route?.estimatedDistance ?? 0,
            operatorName: operatorName ? operatorName : operators?.[missionCapabilities.operator]?.name,
            pilotName: missionCapabilities.pilotName,
            uavName:
                missionCapabilities.uavName && missionCapabilities.setupName
                    ? `${missionCapabilities.uavName} (${missionCapabilities.setupName})`
                    : undefined,
            uavSerialNumbers: missionCapabilities.uavSerialNumbers ?? [],
            trackersIdentifiers: missionCapabilities.trackersIdentifiers ?? [],
            category: missionPlan.category,
            flightPurpose: {
                nameTranslationKey:
                    missionPlanDataAndCapabilities.flightPurposes?.find((purpose) => purpose.id === missionPlan.flightPurpose?.id)?.name ??
                    "",
                comment: missionPlan.flightPurpose?.comment ?? undefined,
            },
            additionalCrew: [],
        };
    }
}
