import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { BaseOperator } from "../../shared/models/operator.models";
import { Capabilities, CapabilitiesError } from "../models/capabilities.model";
import { ExaminationCenter, ExaminationCenterListWithPages, ExaminationCentersError } from "../models/examination-centers.models";
import { OperatorDetails, OperatorsError } from "../models/operators.model";
import { TrainingCenter, TrainingCenterListWithPages, TrainingCentersError } from "../models/training-centers.models";
import { CentersApiService } from "../services/centers-api.service";
import { CentersActions } from "./centers.actions";

interface CentersModel {
    examinationCentersList: ExaminationCenter[] | undefined;
    examinationCentersListError: ExaminationCentersError | undefined;
    isExaminationCentersListProcessing: boolean;
    examinationCentersPages: Page | undefined;
    examinationCenterError: ExaminationCentersError | undefined;
    isExaminationCenterProcessing: boolean;
    trainingCentersList: TrainingCenter[] | undefined;
    trainingCentersListError: TrainingCentersError | undefined;
    isTrainingCentersListProcessing: boolean;
    trainingCentersPages: Page | undefined;
    trainingCenterError: TrainingCentersError | undefined;
    isTrainingCenterProcessing: boolean;
    capabilities: Capabilities | undefined;
    areCapabilitiesProcessing: boolean;
    capabilitiesError: CapabilitiesError | undefined;
    operatorsList: BaseOperator[] | undefined;
    operatorsListError: OperatorsError | undefined;
    isOperatorsListProcessing: boolean;
    operatorDetails: OperatorDetails | undefined;
    isOperatorDetailsProcessing: boolean;
    operatorDetailsError: OperatorsError | undefined;
}

const defaultState: CentersModel = {
    examinationCentersList: undefined,
    examinationCentersListError: undefined,
    isExaminationCentersListProcessing: false,
    examinationCentersPages: undefined,
    examinationCenterError: undefined,
    isExaminationCenterProcessing: false,
    isTrainingCenterProcessing: false,
    trainingCentersList: undefined,
    trainingCentersListError: undefined,
    isTrainingCentersListProcessing: false,
    trainingCentersPages: undefined,
    trainingCenterError: undefined,
    capabilities: undefined,
    areCapabilitiesProcessing: false,
    capabilitiesError: undefined,
    operatorsList: undefined,
    operatorsListError: undefined,
    isOperatorsListProcessing: false,
    operatorDetails: undefined,
    isOperatorDetailsProcessing: false,
    operatorDetailsError: undefined,
};

@State<CentersModel>({
    name: "centers",
    defaults: defaultState,
})
@Injectable()
export class CentersState {
    constructor(private readonly centersApiService: CentersApiService) {}

    @Selector()
    public static examinationCentersList(state: CentersModel): ExaminationCenter[] | undefined {
        return state.examinationCentersList;
    }

    @Selector()
    public static examinationCentersListError(state: CentersModel): ExaminationCentersError | undefined {
        return state.examinationCentersListError;
    }

    @Selector()
    public static examinationCentersPages(state: CentersModel): Page | undefined {
        return state.examinationCentersPages;
    }

    @Selector()
    public static isExaminationCentersListProcessing(state: CentersModel): boolean {
        return state.isExaminationCentersListProcessing;
    }

    @Selector()
    public static isExaminationCenterProcessing(state: CentersModel): boolean {
        return state.isExaminationCenterProcessing;
    }

    @Selector()
    public static trainingCentersListError(state: CentersModel): TrainingCentersError | undefined {
        return state.trainingCentersListError;
    }

    @Selector()
    public static trainingCentersPages(state: CentersModel): Page | undefined {
        return state.trainingCentersPages;
    }

    @Selector()
    public static trainingCentersList(state: CentersModel): TrainingCenter[] | undefined {
        return state.trainingCentersList;
    }

    @Selector()
    public static trainingCenterError(state: CentersModel): TrainingCentersError | undefined {
        return state.trainingCenterError;
    }

    @Selector()
    public static isTrainingCenterProcessing(state: CentersModel): boolean {
        return state.isExaminationCenterProcessing;
    }

    @Selector()
    public static examinationCenterError(state: CentersModel): ExaminationCentersError | undefined {
        return state.examinationCenterError;
    }

    @Selector()
    public static capabilities(state: CentersModel): Capabilities | undefined {
        return state.capabilities;
    }

    @Selector()
    public static areCapabilitiesProcessing(state: CentersModel): boolean {
        return state.areCapabilitiesProcessing;
    }

    @Selector()
    public static capabilitiesError(state: CentersModel): CapabilitiesError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static operatorsList(state: CentersModel): BaseOperator[] | undefined {
        return state.operatorsList;
    }

    @Selector()
    public static isOperatorsListProcessing(state: CentersModel): boolean {
        return state.isOperatorsListProcessing;
    }

    @Selector()
    public static operatorsListError(state: CentersModel): OperatorsError | undefined {
        return state.operatorsListError;
    }

    @Selector()
    public static isOperatorDetailsProcessing(state: CentersModel): boolean {
        return state.isOperatorDetailsProcessing;
    }

    @Selector()
    public static operatorDetailsError(state: CentersModel): OperatorsError | undefined {
        return state.operatorDetailsError;
    }

    @Selector()
    public static operatorDetails(state: CentersModel): OperatorDetails | undefined {
        return state.operatorDetails;
    }

    @Action(CentersActions.GetExaminationCenters)
    public getExaminationCentersList(context: StateContext<CentersModel>, action: CentersActions.GetExaminationCenters) {
        context.patchState({ isExaminationCentersListProcessing: true, examinationCentersListError: undefined });

        return this.centersApiService.getExaminationCentersList(action.filtersQuery).pipe(
            tap(({ content, pageSize, pageNumber, totalElements }: ExaminationCenterListWithPages) =>
                context.patchState({
                    examinationCentersList: content,
                    examinationCentersPages: {
                        pageSize,
                        pageNumber,
                        totalElements,
                    },
                })
            ),
            catchError((centersError) => {
                context.patchState({ examinationCentersListError: centersError, examinationCentersList: [] });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isExaminationCentersListProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.GetCapabilities)
    public getCapabilitiesList(context: StateContext<CentersModel>) {
        context.patchState({ areCapabilitiesProcessing: true, capabilitiesError: undefined });

        return this.centersApiService.getCapabilitiesList().pipe(
            tap((capabilities) => context.patchState({ capabilities })),
            catchError((capabilitiesError) => {
                context.patchState({ capabilitiesError, capabilities: undefined });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    areCapabilitiesProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.AddExaminationCenter)
    public addExaminationCenter(context: StateContext<CentersModel>, action: CentersActions.AddExaminationCenter) {
        context.patchState({ isExaminationCenterProcessing: true, examinationCenterError: undefined });

        return this.centersApiService.addExaminationCenter(action.center).pipe(
            catchError((examinationCenterError) => {
                context.patchState({ examinationCenterError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isExaminationCenterProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.EditExaminationCenter)
    public editExaminationCenter(context: StateContext<CentersModel>, action: CentersActions.EditExaminationCenter) {
        context.patchState({ isExaminationCenterProcessing: true, examinationCenterError: undefined });

        return this.centersApiService.editExaminationCenter(action.center).pipe(
            catchError((examinationCenterError) => {
                context.patchState({ examinationCenterError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isExaminationCenterProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.GetOperatorsList)
    public getOperatorsList(context: StateContext<CentersModel>, action: CentersActions.GetOperatorsList) {
        context.patchState({ isOperatorsListProcessing: true, operatorsListError: undefined });

        return this.centersApiService.getOperatorsList(action.searchPhrase).pipe(
            tap((operatorsList) => context.patchState({ operatorsList })),
            catchError((operatorsListError) => {
                context.patchState({ operatorsListError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isOperatorsListProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.GetOperatorDetails)
    public getOperatorDetails(context: StateContext<CentersModel>, action: CentersActions.GetOperatorDetails) {
        context.patchState({ isOperatorDetailsProcessing: true, operatorDetailsError: undefined });

        return this.centersApiService.getOperatorDetails(action.operatorId).pipe(
            tap((operatorDetails) => context.patchState({ operatorDetails })),
            catchError((operatorDetailsError) => {
                context.patchState({ operatorDetailsError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isOperatorDetailsProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.AddTrainingCenter)
    public addTrainingCenter(context: StateContext<CentersModel>, action: CentersActions.AddTrainingCenter) {
        context.patchState({ isTrainingCenterProcessing: true, trainingCenterError: undefined });

        return this.centersApiService.addTrainingCenter(action.center).pipe(
            catchError((trainingCenterError) => {
                context.patchState({ trainingCenterError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isTrainingCenterProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.DeleteExaminationCenter)
    public deleteExaminationCenter(context: StateContext<CentersModel>, action: CentersActions.DeleteExaminationCenter) {
        context.patchState({ isExaminationCenterProcessing: true, examinationCenterError: undefined });

        return this.centersApiService.deleteExaminationCenter(action.centerId).pipe(
            catchError((examinationCenterError) => {
                context.patchState({ examinationCenterError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isExaminationCenterProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.EditTrainingCenter)
    public editTrainingCenter(context: StateContext<CentersModel>, action: CentersActions.EditTrainingCenter) {
        context.patchState({ isTrainingCenterProcessing: true, trainingCenterError: undefined });

        return this.centersApiService.editTrainingCenter(action.center).pipe(
            catchError((trainingCenterError) => {
                context.patchState({ trainingCenterError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isTrainingCenterProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.DeleteTrainingCenter)
    public deleteTrainingCenter(context: StateContext<CentersModel>, action: CentersActions.DeleteTrainingCenter) {
        context.patchState({ isTrainingCenterProcessing: true, trainingCenterError: undefined });

        return this.centersApiService.deleteTrainingCenter(action.centerId).pipe(
            catchError((trainingCenterError) => {
                context.patchState({ trainingCenterError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isTrainingCenterProcessing: false,
                })
            )
        );
    }

    @Action(CentersActions.GetTrainingCentersList)
    public getTrainingCentersList(context: StateContext<CentersModel>, action: CentersActions.GetTrainingCentersList) {
        context.patchState({ isTrainingCentersListProcessing: true, trainingCentersListError: undefined });

        return this.centersApiService.getTrainingCentersList(action.filtersQuery).pipe(
            tap(({ content: trainingCentersList, pageSize, pageNumber, totalElements }: TrainingCenterListWithPages) =>
                context.patchState({
                    trainingCentersList,
                    trainingCentersPages: {
                        pageSize,
                        pageNumber,
                        totalElements,
                    },
                })
            ),
            catchError((trainingCentersListError) => {
                context.patchState({ trainingCentersListError, trainingCentersList: [] });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isTrainingCentersListProcessing: false,
                })
            )
        );
    }
}
