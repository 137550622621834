<div class="card">
    <div class="card-title">
        {{ "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.titleTile" | transloco }}
    </div>
    <div class="content">
        <ng-container *ngIf="legalGuardianConfirmation$ | ngrxPush as legalGuardianConfirmation; else emptyStateTemplate">
            <div class="chip secondary">
                <dtm-ui-icon name="draft"></dtm-ui-icon>
                <p>{{ "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.toUpdateChipsLabel" | transloco }}</p>
            </div>
            <dtm-ui-label-value
                [label]="'dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.nameLabel' | transloco"
                [value]="legalGuardianConfirmation.firstName + ' ' + legalGuardianConfirmation.lastName"
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.emailAddressLabel' | transloco"
                [value]="legalGuardianConfirmation.email"
            >
            </dtm-ui-label-value>
            <ng-container
                [ngTemplateOutlet]="requestForUpdateTemplate"
                [ngTemplateOutletContext]="{ isPartiallyFilled: true }"
            ></ng-container>
            <button type="button" class="button-primary" (click)="detailsFill.emit()">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.fillDetailsButtonLabel" | transloco }}
            </button>
        </ng-container>
        <ng-template #emptyStateTemplate>
            <div class="chip secondary">
                <dtm-ui-icon name="draft"></dtm-ui-icon>
                <p>{{ "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.toUpdateChipsLabel" | transloco }}</p>
            </div>
            <ng-container [ngTemplateOutlet]="requestForUpdateTemplate"></ng-container>
            <button type="button" class="button-primary" (click)="detailsFill.emit()">
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.addDetailsButtonLabel" | transloco }}
            </button>
        </ng-template>
    </div>
</div>

<ng-template #requestForUpdateTemplate let-isPartiallyFilled="isPartiallyFilled">
    <div class="request-for-update">
        <p>
            {{
                (isPartiallyFilled
                    ? "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.partiallyFilledText"
                    : "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.emptyStateText"
                ) | transloco
            }}
        </p>
        <p *ngIf="lastRequestForUpdate$ | ngrxPush as lastRequestForUpdate" class="last-update">
            {{
                "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.lastRequestForUpdateText"
                    | transloco : { date: lastRequestForUpdate | localizeDate }
            }}
        </p>
        <button type="button" class="button-link" (click)="updateRequest.emit()">
            {{ "dtmAdminLibUtmUsers.userProfile.legalGuardianDetailsRequired.requestForUpdateLabel" | transloco }}
        </button>
    </div>
</ng-template>
