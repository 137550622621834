import { Notification } from "@dtm-frontend/shared/notifications";

export interface CaaConversationNotification extends Notification {
    type: CaaConversationNotificationType;
    payload: CaaConversationNewMessageNotificationRequestPayload | CaaConversationThreadGeneralNotificationRequestPayload;
}

export enum CaaConversationNotificationType {
    NewMessageToAssigneeEvent = "NewMessageToAssigneeEvent",
    ThreadClosedEvent = "ThreadClosedEvent",
    ThreadAssignedEvent = "ThreadAssignedEvent",
    ThreadUnassignedEvent = "ThreadUnassignedEvent",
}

export interface CaaConversationNewMessageNotificationRequestPayload {
    sender: {
        operatorId: string;
        operatorName: string;
    };
    threadId: string;
    threadName: string;
}

export interface CaaConversationThreadGeneralNotificationRequestPayload {
    threadId: string;
    threadName: string;
    stakeholderId: string;
}
