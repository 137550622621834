import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Assignee, StoredAvatarPictures } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { ConversationAssignee } from "../../../services/conversation.models";

const NULL_ASSIGNEE = 0;

interface AssigneeSelectComponentState {
    assignees: Map<string, ConversationAssignee> | undefined;
    selectedAssignee: Assignee | null;
    processingThreadIds: string[];
    selectedThreadId: string | undefined;
    isClosedThread: boolean;
    storedAvatarPictures: StoredAvatarPictures;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-messages-assignee-select[assignees][isClosedThread][processingThreadIds][selectedThreadId]",
    templateUrl: "./messages-assignee-select.component.html",
    styleUrls: ["./messages-assignee-select.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MessagesAssigneeSelectComponent implements OnInit {
    @Input() public set assignees(value: Map<string, ConversationAssignee> | undefined) {
        this.localStore.patchState({ assignees: value });
    }

    @Input() public set processingThreadIds(value: string[] | undefined) {
        this.localStore.patchState({ processingThreadIds: value ?? [] });
    }

    @Input() public set isClosedThread(value: boolean | undefined) {
        this.localStore.patchState({ isClosedThread: !!value });
    }

    @Input() public set selectedThreadId(value: string | undefined) {
        this.localStore.patchState({ selectedThreadId: value });
    }

    @Input() public set storedAvatarPictures(value: StoredAvatarPictures | undefined) {
        this.localStore.patchState({ storedAvatarPictures: value ?? {} });
    }

    @Input() public set selectedAssignee(value: Assignee | null | undefined) {
        this.localStore.patchState({ selectedAssignee: value ?? null });
        if (!value) {
            this.assigneeControl.setValue(NULL_ASSIGNEE);
        } else {
            this.assigneeControl.setValue(value.userId);
        }
    }

    @Output() public assigneeChange: EventEmitter<Assignee | null> = new EventEmitter<Assignee | null>();

    public readonly assigneeControl = new UntypedFormControl();
    public readonly assignees$ = this.localStore.selectByKey("assignees");
    public readonly storedAvatarPictures$ = this.localStore.selectByKey("storedAvatarPictures");
    public readonly isProcessing$ = this.localStore.selectByKey("processingThreadIds").pipe(
        withLatestFrom(this.localStore.selectByKey("selectedThreadId")),
        map(([list, threadId]) => list?.some((processingId) => processingId === threadId))
    );
    public readonly isClosedThread$ = this.localStore.selectByKey("isClosedThread");
    public readonly NULL_ASSIGNEE = NULL_ASSIGNEE;

    public readonly selectedAssignee$ = this.localStore.selectByKey("selectedAssignee");

    constructor(private readonly localStore: LocalComponentStore<AssigneeSelectComponentState>) {
        this.localStore.setState({
            assignees: undefined,
            selectedAssignee: null,
            processingThreadIds: [],
            isClosedThread: false,
            selectedThreadId: undefined,
            storedAvatarPictures: {},
        });
    }

    public ngOnInit() {
        combineLatest([this.isClosedThread$, this.isProcessing$])
            .pipe(untilDestroyed(this))
            .subscribe(([isClosed, isProcessing]) => {
                if (isClosed || isProcessing) {
                    this.assigneeControl.disable();

                    return;
                }

                this.assigneeControl.enable();
            });
    }

    public changeAssignee(assigneeId: string | typeof NULL_ASSIGNEE) {
        if (!assigneeId) {
            this.assigneeChange.emit(null);

            return;
        }

        const newAssignee = this.localStore.selectSnapshotByKey("assignees")?.get(assigneeId);
        if (newAssignee) {
            this.assigneeChange.emit({ userId: assigneeId, firstName: newAssignee.firstName, lastName: newAssignee.lastName });

            return;
        }

        this.assigneeChange.emit(null);
    }
}
