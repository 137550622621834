import { OperationScenarioCategory, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM, QualificationStatus } from "@dtm-frontend/shared/ui";
import { Observable } from "rxjs";
import { ExamResult } from "./exam-results.models";
import { TrainingResult } from "./training-results.models";

export enum ExamTrainingResultsFilterFormKeys {
    SearchPhrase = "searchPhrase",
    ActiveTabIndex = "activeTabIndex",
}

export enum ExamTrainingResultsTabType {
    Exams = 0,
    Trainings = 1,
}

export interface ExamTrainingResultsFilterParams {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    [ExamTrainingResultsFilterFormKeys.ActiveTabIndex]?: number;
    [ExamTrainingResultsFilterFormKeys.SearchPhrase]?: string;
}

export enum ExamTrainingResultsErrorType {
    CannotGetExamTrainingResultsReportDetails = "CannotGetExamTrainingResultsReportDetails",
    CannotAddExamTrainingResults = "CannotAddExamTrainingResults",
    CannotDeleteResults = "CannotDeleteResults",
}

export enum ExamTrainingResultsFiltersTabKeys {
    ExamResultsFilters = "examResultsFilters",
    TrainingResultsFilters = "trainingResultsFilters",
}

export enum ExamTrainingResultsMode {
    Online = "ONLINE",
    Stationary = "STATIONARY",
}

export enum ExamTrainingResultsReportType {
    ExamCenterReport = "EXAMINING_CENTER_REPORT",
    TrainingOperatorReport = "TRAINING_OPERATOR_REPORT",
}

export interface ExamTrainingResultsReportDetails {
    status: string;
    institution: {
        name: string;
        identificationNumber: string;
    };
    operators: ExamTrainingResultsReportOperatorDetails[];
    errors: ExamTrainingResultsReportDetailsError[] | undefined;
}

export interface ExamTrainingResultsReportDetailsError {
    cell: string;
    errorMsgArgs: Record<string, string> | undefined;
    errorMsg: string;
}

export interface ExamTrainingResultsAddDialogData {
    results: ExamTrainingResultsReportDetails;
    isExamTrainingResultProcessing$: Observable<boolean>;
}

export type ResultsListData = ExamResult | TrainingResult;

export interface OperatorCompetencies {
    name: string;
    category: OperationScenarioCategory;
    expirationDate: Date;
    status: QualificationStatus;
    statusReason?: string;
}

export interface OperatorEvaluatedCompetencies {
    name: string;
    category: OperationScenarioCategory;
    expirationDate: Date;
}

export interface ExamTrainingResultsReportOperatorDetails {
    name: string;
    email: string;
    operatorNumber: string;
    trainings?: TrainingDetails[];
    exams?: ExamsDetails[];
    currentCompetencies: OperatorCompetencies[];
    evaluatedCompetencies: OperatorEvaluatedCompetencies[];
}

export interface TrainingDetails {
    trainingNativeName: string;
    trainingEnglishName: string;
    startDate: Date;
    endDate: Date;
    trainingOperatorName: string;
    identificationNumber: string;
}

export interface ExamsDetails {
    examNativeName: string;
    examEnglishName: string;
    passDate: Date;
}
