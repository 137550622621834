import { InjectionToken } from "@angular/core";

export interface UtmUsersEndpoints {
    getUserList: string;
    getUser: string;
    updateUserDetails: string;
    updateUserLegalGuardianDetails: string;
    deleteUserLegalGuardian: string;
    changeUserIdentityDocumentStatus: string;
    downloadIdentityDocument: string;
    getLegalGuardianSignedDocument: string;
    requestForDocumentUpdate: string;
    requestForLegalGuardianUpdate: string;
}
export const UTM_USERS_ENDPOINTS = new InjectionToken<UtmUsersEndpoints>("Utm Users endpoints");
