import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { AngularCesiumModule } from "@pansa/ngx-cesium";
import { AreaMapContainerComponent } from "./area-map-container/area-map-container.component";
import { AreaMapLayersControlComponent } from "./area-map-layers-control/area-map-layers-control.component";

@NgModule({
    declarations: [AreaMapContainerComponent, AreaMapLayersControlComponent],
    imports: [CommonModule, AngularCesiumModule, CesiumMapModule, TranslocoModule, LetModule, SharedUiModule, PushModule],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibAreaMap",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class AreaMapModule {}
