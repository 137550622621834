import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Observable } from "rxjs";
import { Operator, OperatorError, OperatorType } from "../../../shared";

interface OperatorListComponentState {
    selectedOperatorId: string | undefined;
    operators: Operator[] | undefined;
    pagination: Page | undefined;
    operatorListError: OperatorError | undefined;
}

@Component({
    selector: "dtm-admin-lib-operator-list[selectedOperatorId][operators][pagination]",
    templateUrl: "./operator-list.component.html",
    styleUrls: ["./operator-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorListComponent {
    @Input()
    public set selectedOperatorId(value: string | undefined) {
        this.localStore.patchState({ selectedOperatorId: value });
    }
    @Input()
    public set operators(value: Operator[] | undefined) {
        this.localStore.patchState({ operators: value });
    }
    @Input()
    public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Input()
    public set operatorListError(value: OperatorError | undefined) {
        this.localStore.patchState({ operatorListError: value });
    }

    @Output() public operatorSelect: EventEmitter<Operator> = new EventEmitter<Operator>();
    @Output() public pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() public operatorsListRefresh: EventEmitter<void> = new EventEmitter<void>();

    protected readonly OperatorType = OperatorType;
    protected readonly displayedColumns = ["status", "operatorNumber", "name", "type"];
    protected readonly selectedOperator$: Observable<string | undefined> = this.localStore.selectByKey("selectedOperatorId");
    protected readonly operators$ = this.localStore.selectByKey("operators");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly operatorListError$ = this.localStore.selectByKey("operatorListError");
    protected readonly ErrorMode = ErrorMode;

    protected selectOperator(operator: Operator) {
        this.operatorSelect.emit(operator);
        this.localStore.patchState({
            selectedOperatorId: operator.id,
        });
    }

    constructor(private readonly localStore: LocalComponentStore<OperatorListComponentState>) {
        this.localStore.setState({
            selectedOperatorId: undefined,
            operators: undefined,
            pagination: undefined,
            operatorListError: undefined,
        });
    }
}
