import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ExamTrainingResultsState } from "../../state/exam-training-results.state";
import { REPORT_API_ENDPOINTS, ReportApiEndpoints } from "./reports-upload-api.token";

@Injectable()
export class ReportsUploadApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly store: Store,
        @Inject(REPORT_API_ENDPOINTS)
        private readonly endpoints: ReportApiEndpoints
    ) {}

    public uploadFile(file: File): Observable<HttpEvent<UploadedFileBody>> {
        const params = new HttpParams().set(
            "reportType",
            this.store.selectSnapshot(ExamTrainingResultsState.selectedExamTrainingResultsReportType) as string
        );
        const formData: FormData = new FormData();
        formData.append("file", file);

        return this.httpClient
            .post<UploadedFileBody>(this.endpoints.uploadFile, formData, {
                params,
                reportProgress: true,
                responseType: "json",
                observe: "events",
            })
            .pipe(catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error))));
    }

    public getFile(fileId: string) {
        return this.httpClient.get(StringUtils.replaceInTemplate(this.endpoints.downloadFile, { fileId }), {
            responseType: "blob",
            context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
        });
    }

    public getFilesCompressed() {
        console.error("Not implemented");

        return EMPTY;
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }
}
