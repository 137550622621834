<ng-container
    *ngrxLet="{
        selectedAssignee: selectedAssignee$,
        assignees: assignees$,
        isProcessing: isProcessing$,
        storedAvatarPictures: storedAvatarPictures$
    } as vm"
>
    <dtm-ui-select-field [isClearable]="false" *ngIf="vm.assignees" [formControl]="assigneeControl" (valueChange)="changeAssignee($event)">
        <label>{{ "dtmAdminLibConversation.messagesContainer.assigneeLabel" | transloco }}</label>
        <mat-select-trigger>
            <div *ngIf="vm.isProcessing; else selectedValueTemplate" class="loader">
                <mat-spinner color="accent" diameter="24"></mat-spinner>
                <span
                    *ngIf="vm.assignees.get(assigneeControl.value) as proposedAssigneeValue; else noAssigneeTemplate"
                    class="loading-value"
                >
                    {{ proposedAssigneeValue.firstName }} {{ proposedAssigneeValue.lastName }}
                </span>
            </div>

            <ng-template #selectedValueTemplate>
                <ng-container *ngIf="vm.selectedAssignee; else noAssigneeTemplate">
                    <div
                        *ngIf="vm.assignees.get(vm.selectedAssignee.userId) as selectedAssigneeValue; else noAssigneeTemplate"
                        class="selected-assignee"
                    >
                        <img
                            alt=""
                            *ngIf="selectedAssigneeValue?.avatarUrl"
                            [src]="vm.storedAvatarPictures[selectedAssigneeValue.avatarUrl]"
                        />
                        <span> {{ selectedAssigneeValue.firstName }} {{ selectedAssigneeValue.lastName }} </span>
                    </div>
                </ng-container>
            </ng-template>
        </mat-select-trigger>

        <dtm-ui-select-option [value]="NULL_ASSIGNEE">
            <ng-template [ngTemplateOutlet]="noAssigneeTemplate"></ng-template>
        </dtm-ui-select-option>
        <dtm-ui-select-option *ngFor="let availableAssignee of vm.assignees | keyvalue" [value]="availableAssignee?.key">
            <span class="selected-assignee">
                <img *ngIf="availableAssignee.value.avatarUrl" alt="" [src]="vm.storedAvatarPictures[availableAssignee.value.avatarUrl]" />
                <span>{{ availableAssignee.value.firstName }} {{ availableAssignee.value.lastName }}</span>
            </span>
        </dtm-ui-select-option>
    </dtm-ui-select-field>
</ng-container>

<ng-template #noAssigneeTemplate>
    <div class="selected-assignee">
        <span>{{ "dtmAdminLibConversation.messagesContainer.noAssignmentLabel" | transloco }}</span>
    </div>
</ng-template>
