<div class="form-wrapper" [formGroup]="additionalAccidentForm">
    <dtm-admin-lib-confirm-tile formControlName="hasInsurance">
        <label>{{ "dtmAdminLibPermits.additionalAccidentType.insuranceLabel" | transloco }}</label>
        <div
            class="field-error"
            *dtmUiFieldHasError="additionalAccidentForm.controls.hasInsurance; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-admin-lib-confirm-tile>
    <dtm-ui-input-field>
        <label>{{ "dtmAdminLibPermits.additionalAccidentType.operationsManualReferenceLabel" | transloco }}</label>
        <input matInput formControlName="operationsManualReference" type="text" />
        <div
            class="field-error"
            *dtmUiFieldHasError="additionalAccidentForm.controls.operationsManualReference; name: ['required', 'pattern']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
        <div
            class="field-error"
            *dtmUiFieldHasError="additionalAccidentForm.controls.operationsManualReference; name: 'maxlength'; error as error"
        >
            {{ "dtmAdminLibPermits.additionalAccidentType.maxLengthErrorLabel" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-textarea-field [maxLength]="MAX_ADDITIONAL_LIMITATIONS_LENGTH">
        <label>{{ "dtmAdminLibPermits.additionalAccidentType.additionalLimitationsLabel" | transloco }}</label>
        <textarea formControlName="additionalLimitations" [rows]="TEXTAREA_ROWS" matInput></textarea>
        <div
            class="field-error"
            *dtmUiFieldHasError="additionalAccidentForm.controls.additionalLimitations; name: ['pattern']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
        <div
            class="field-error"
            *dtmUiFieldHasError="additionalAccidentForm.controls.additionalLimitations; name: 'maxlength'; error as error"
        >
            {{ "dtmAdminLibPermits.additionalAccidentType.maxLengthErrorLabel" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-textarea-field>
</div>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibPermits.additionalAccidentType.requiredField" | transloco }}
</ng-template>
