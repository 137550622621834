<div *ngIf="profile$ | ngrxPush as profile" class="main-container">
    <div class="header-container">
        <div class="header-name">
            <p class="name-label">{{ "dtmAdminLibShared.operatorProfile.operatorLabel" | transloco }}</p>
            <div class="name-value">{{ profile.name }}</div>
        </div>
        <dtm-ui-operator-status-badge [status]="profile.status"></dtm-ui-operator-status-badge>
    </div>
    <div class="content">
        <dtm-admin-lib-profile-details [profile]="profile"></dtm-admin-lib-profile-details>
        <dtm-admin-lib-insurance-policy-tile [profile]="profile"></dtm-admin-lib-insurance-policy-tile>
        <dtm-admin-lib-owner-tile [profile]="profile"></dtm-admin-lib-owner-tile>
        <dtm-admin-lib-operator-details-tile [profile]="profile"></dtm-admin-lib-operator-details-tile>
        <dtm-admin-lib-qualifications
            *ngIf="profile.operationalAuthorizations?.length"
            [areActionsAvailable]="false"
            [qualificationType]="QualificationType.OperationalAuthorizations"
            [titleTile]="'dtmAdminLibShared.operatorProfile.qualifications.operationalAuthorizationsTitleTile' | transloco"
            [qualifications]="profile.operationalAuthorizations"
        ></dtm-admin-lib-qualifications>
        <dtm-admin-lib-qualifications
            *ngIf="profile.pilotCompetencies?.length"
            [areActionsAvailable]="false"
            [qualificationType]="QualificationType.PilotCompetencies"
            [titleTile]="'dtmAdminLibShared.operatorProfile.qualifications.pilotCompetenciesTitleTile' | transloco"
            [qualifications]="profile.pilotCompetencies"
        ></dtm-admin-lib-qualifications>
        <dtm-admin-lib-attorney-powers-tile
            *ngIf="profile.attorneyPowers && profile.attorneyPowers.length"
            [areActionsAvailable]="false"
            [attorneyPowers]="profile.attorneyPowers"
        ></dtm-admin-lib-attorney-powers-tile>
        <dtm-admin-lib-administrative-fee-tile
            *ngIf="profile.administrativeFee"
            [administrativeFee]="profile.administrativeFee"
        ></dtm-admin-lib-administrative-fee-tile>
        <dtm-admin-lib-other-information-tile
            *ngIf="profile.otherInformation.length"
            [areActionsAvailable]="false"
            [otherInformation]="profile.otherInformation"
        ></dtm-admin-lib-other-information-tile>
        <dtm-admin-lib-change-log-tile
            [type]="ChangeLogType.Operator"
            [identifier]="profile.operatorNumber"
        ></dtm-admin-lib-change-log-tile>
    </div>
</div>
