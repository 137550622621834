import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperatorDetails } from "../../../models/operator.models";

interface InsurancePolicyTileComponentState {
    profile: OperatorDetails | undefined;
}

@Component({
    selector: "dtm-admin-lib-insurance-policy-tile",
    templateUrl: "./insurance-policy-tile.component.html",
    styleUrls: ["./insurance-policy-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InsurancePolicyTileComponent {
    @Input() public set profile(value: OperatorDetails | undefined) {
        this.localStore.patchState({ profile: value });
    }

    protected readonly profile$ = this.localStore.selectByKey("profile");

    constructor(private readonly localStore: LocalComponentStore<InsurancePolicyTileComponentState>) {
        localStore.setState({ profile: undefined });
    }
}
