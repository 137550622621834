<ng-container *ngrxLet="data.isProcessing$ as isProcessing">
    <div class="dialog-header">
        <h4 mat-dialog-title>{{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.header" | transloco }}</h4>
        <button type="button" class="button-icon" [matDialogClose]="null" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <form [formGroup]="legalGuardianDetailsEditionForm" (ngSubmit)="confirm()" dtmUiInvalidFormScrollable>
        <div mat-dialog-content>
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.firstNameLabel" | transloco }} </label>
                <input matInput type="text" formControlName="firstName" />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.firstName; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.firstName; name: 'maxlength'; error as error"
                >
                    {{
                        "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.firstNameLengthError"
                            | transloco : { max: error.requiredLength }
                    }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.lastNameLabel" | transloco }} </label>
                <input matInput type="text" formControlName="lastName" />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.lastName; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.lastName; name: 'maxlength'; error as error"
                >
                    {{
                        "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.lastNameLengthError"
                            | transloco : { max: error.requiredLength }
                    }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-date-field>
                <label>
                    {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.dateOfBirthLabel" | transloco }}
                </label>
                <input
                    *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                    formControlName="dateOfBirth"
                    matInput
                    [max]="MAX_LEGAL_AGE_YEARS"
                    [min]="MIN_DATE_OF_BIRTH"
                    [placeholder]="datePickerPlaceholder"
                    [matDatepicker]="dateOfBirthDatepicker"
                />
                <mat-datepicker #dateOfBirthDatepicker></mat-datepicker>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.dateOfBirth; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.dateOfBirth; name: 'matDatepickerMax'"
                >
                    {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.minAgeErrorHint" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.dateOfBirth; name: 'matDatepickerMin'; error as error"
                >
                    {{
                        "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.minDateOfBirthErrorHint"
                            | transloco : { minDate: error.min | localizeDate }
                    }}
                </div>
            </dtm-ui-date-field>
            <dtm-ui-country-select-field formControlName="nationality">
                <label> {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.nationalityLabel" | transloco }} </label>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.nationality; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-country-select-field>
            <dtm-ui-phone-number-field formControlName="phoneNumber">
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.phoneNumber; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.phoneNumber; name: 'invalidNumber'">
                    {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.invalidPhoneNumberError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.phoneNumber; name: 'invalidNumberType'"
                >
                    {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.invalidNumberTypeError" | transloco }}
                </div>
            </dtm-ui-phone-number-field>
            <dtm-ui-input-field>
                <label> {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.emailLabel" | transloco }} </label>
                <input matInput type="email" formControlName="email" />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.email; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.email; name: 'email'">
                    {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.emailIncorrectError" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-country-select-field formControlName="country">
                <label> {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.countryLabel" | transloco }} </label>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianDetailsEditionForm.controls.country; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-country-select-field>
            <dtm-ui-address-form
                [formControl]="addressControl"
                dtmUiMarkValueAccessorControlsAsTouched
                [isSystemDefaultCountrySelected]="legalGuardianDetailsEditionForm.controls.country.value === DEFAULT_COUNTRY_CODE"
            >
            </dtm-ui-address-form>
        </div>
        <div mat-dialog-actions>
            <button type="submit" class="button-primary save-button" (click)="confirm()" [disabled]="isProcessing">
                {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.confirmLabel" | transloco }}
            </button>
        </div>
    </form>

    <ng-template #requiredErrorTemplate>
        {{ "dtmAdminLibShared.operatorProfile.legalGuardianDetailsEdition.requiredFieldError" | transloco }}
    </ng-template>
</ng-container>
