import { Address, OperationScenarioCategory } from "@dtm-frontend/shared/ui";

export enum OperatorsErrorType {
    CannotGetOperatorsList = "CannotGetOperatorsList",
    CannotGetOperatorDetails = "CannotGetOperatorDetails",
}

export interface OperatorsError {
    type: OperatorsErrorType;
}

export interface OperatorDetails {
    id: string;
    number: string;
    name: string;
    address: Address;
    operationalAuthorizations: { name: string; category: OperationScenarioCategory }[];
}
