import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { DashboardDetails, DashboardDetailsErrorType } from "../dashboard.model";
import { DASHBOARD_ENDPOINTS, DashboardEndpoints } from "../dashboard.tokens";
import { GetDashboardDetailsResponseBody } from "./dashboard-api.converters";

@Injectable({
    providedIn: "root",
})
export class DashboardApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(DASHBOARD_ENDPOINTS) private readonly endpoints: DashboardEndpoints) {}

    public getDashboardDetails(): Observable<DashboardDetails> {
        return this.httpClient
            .get<GetDashboardDetailsResponseBody>(this.endpoints.getDashboardDetails)
            .pipe(catchError(() => throwError({ type: DashboardDetailsErrorType.Unknown })));
    }
}
