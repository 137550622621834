<dtm-ui-expandable-panel
    [isExpanded]="isExpanded$ | ngrxPush"
    [hasHeaderSeparator]="false"
    *ngrxLet="{ operationalGeometryData: operationalGeometryData$ } as vm"
>
    <h2 class="section-title" headerSlot>
        {{ "dtmSharedMission.operationalAreaGeometry.operationalAreaGeometryHeader" | transloco }}
    </h2>
    <div class="container">
        <section>
            <div class="section-header">{{ "dtmSharedMission.operationalAreaGeometry.operationAreaHeader" | transloco }}</div>
            <div class="values">
                <dtm-ui-label-value
                    *ngIf="vm.operationalGeometryData.flightAreaMaxWidth !== 0"
                    [label]="'dtmSharedMission.operationalAreaGeometry.widthLabel' | transloco"
                    [value]="
                        'dtmSharedMission.operationalAreaGeometry.metersUnitValue'
                            | transloco
                                : { value: vm.operationalGeometryData.flightAreaMaxWidth | localizeNumber : { maximumFractionDigits: 2 } }
                    "
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmSharedMission.operationalAreaGeometry.maxFlightHeightLabel' | transloco"
                    [value]="
                        'dtmSharedMission.operationalAreaGeometry.heightUnitValue'
                            | transloco
                                : { value: vm.operationalGeometryData.flightAreaMaxHeight | localizeNumber : { maximumFractionDigits: 2 } }
                    "
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmSharedMission.operationalAreaGeometry.radiusLabel' | transloco"
                    [labelHint]="'dtmSharedMission.operationalAreaGeometry.radiusLabelTooltip' | transloco"
                    [value]="
                        'dtmSharedMission.operationalAreaGeometry.metersUnitValue'
                            | transloco
                                : { value: vm.operationalGeometryData.flightAreaMaxRadius | localizeNumber : { maximumFractionDigits: 0 } }
                    "
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value [label]="'dtmSharedMission.operationalAreaGeometry.areaLabel' | transloco">
                    <div
                        [innerHTML]="
                            'dtmSharedMission.operationalAreaGeometry.squareKilometersValue'
                                | transloco
                                    : {
                                          value:
                                              vm.operationalGeometryData.flightAreaSize
                                              | invoke : convertSquareMetersToSquareKilometers
                                              | localizeNumber : { maximumFractionDigits: 2 }
                                      }
                        "
                    ></div>
                </dtm-ui-label-value>
            </div>
        </section>
        <section>
            <div class="section-header">{{ "dtmSharedMission.operationalAreaGeometry.safetyAreaHeader" | transloco }}</div>
            <div class="values">
                <dtm-ui-label-value
                    [label]="'dtmSharedMission.operationalAreaGeometry.widthLabel' | transloco"
                    [value]="
                        'dtmSharedMission.operationalAreaGeometry.metersUnitValue'
                            | transloco
                                : { value: vm.operationalGeometryData.safetyAreaWidth | localizeNumber : { maximumFractionDigits: 2 } }
                    "
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmSharedMission.operationalAreaGeometry.heightLabel' | transloco"
                    [value]="
                        'dtmSharedMission.operationalAreaGeometry.metersUnitValue'
                            | transloco
                                : {
                                      value:
                                          vm.operationalGeometryData.operationAreaMaxDeclaredHeight -
                                              vm.operationalGeometryData.flightAreaMaxDeclaredHeight
                                          | localizeNumber : { maximumFractionDigits: 0 }
                                  }
                    "
                >
                </dtm-ui-label-value>
            </div>
        </section>
        <section>
            <div class="section-header">{{ "dtmSharedMission.operationalAreaGeometry.operationSpaceHeader" | transloco }}</div>
            <div class="values">
                <dtm-ui-label-value
                    *ngIf="vm.operationalGeometryData.flightAreaMaxWidth !== 0"
                    [label]="'dtmSharedMission.operationalAreaGeometry.widthLabel' | transloco"
                    [value]="
                        'dtmSharedMission.operationalAreaGeometry.metersUnitValue'
                            | transloco
                                : {
                                      value:
                                          vm.operationalGeometryData.operationAreaMaxWidth | localizeNumber : { maximumFractionDigits: 2 }
                                  }
                    "
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmSharedMission.operationalAreaGeometry.heightLabel' | transloco"
                    [value]="
                        'dtmSharedMission.operationalAreaGeometry.heightUnitValue'
                            | transloco
                                : {
                                      value:
                                          vm.operationalGeometryData.operationAreaMaxDeclaredHeight
                                          | localizeNumber : { maximumFractionDigits: 2 }
                                  }
                    "
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmSharedMission.operationalAreaGeometry.radiusLabel' | transloco"
                    [labelHint]="'dtmSharedMission.operationalAreaGeometry.radiusLabelTooltip' | transloco"
                    [value]="
                        'dtmSharedMission.operationalAreaGeometry.metersUnitValue'
                            | transloco
                                : {
                                      value:
                                          vm.operationalGeometryData.operationAreaMaxRadius | localizeNumber : { maximumFractionDigits: 0 }
                                  }
                    "
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value [label]="'dtmSharedMission.operationalAreaGeometry.areaLabel' | transloco">
                    <div
                        [innerHTML]="
                            'dtmSharedMission.operationalAreaGeometry.squareKilometersValue'
                                | transloco
                                    : {
                                          value:
                                              vm.operationalGeometryData.operationAreaSize
                                              | invoke : convertSquareMetersToSquareKilometers
                                              | localizeNumber : { maximumFractionDigits: 2 }
                                      }
                        "
                    ></div>
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmSharedMission.operationalAreaGeometry.groundRiskBufferLabel' | transloco"
                    [value]="
                        vm.operationalGeometryData.groundRiskBuffer
                            ? ('dtmSharedMission.operationalAreaGeometry.metersUnitValue'
                              | transloco
                                  : {
                                        value: vm.operationalGeometryData.groundRiskBuffer | localizeNumber : { maximumFractionDigits: 0 }
                                    })
                            : '-'
                    "
                >
                </dtm-ui-label-value>
            </div>
        </section>
        <section>
            <div class="section-header">{{ "dtmSharedMission.operationalAreaGeometry.areaAndAdjacentSpaceHeader" | transloco }}</div>
            <div class="values">
                <dtm-ui-label-value
                    [label]="'dtmSharedMission.operationalAreaGeometry.widthLabel' | transloco"
                    [value]="
                        vm.operationalGeometryData.adjacentAreaBufferHorizontal
                            ? ('dtmSharedMission.operationalAreaGeometry.metersUnitValue'
                              | transloco
                                  : {
                                        value:
                                            vm.operationalGeometryData.adjacentAreaBufferHorizontal
                                            | localizeNumber : { maximumFractionDigits: 2 }
                                    })
                            : '-'
                    "
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmSharedMission.operationalAreaGeometry.heightLabel' | transloco"
                    [value]="
                        'dtmSharedMission.operationalAreaGeometry.heightUnitValue'
                            | transloco
                                : {
                                      value: vm.operationalGeometryData.adjacentAreaHeight | localizeNumber : { maximumFractionDigits: 2 }
                                  }
                    "
                >
                </dtm-ui-label-value>
            </div>
        </section>
    </div>
</dtm-ui-expandable-panel>
