<div *ngrxLet="status$ as status" [ngSwitch]="status">
    <span *ngSwitchCase="PermitStatus.Active" class="chip success">
        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
        <ng-container *ngTemplateOutlet="statusTranslationTemplate"></ng-container>
    </span>
    <span *ngSwitchCase="PermitStatus.Withdrawn" class="chip error">
        <dtm-ui-icon name="error-warning"></dtm-ui-icon>
        <ng-container *ngTemplateOutlet="statusTranslationTemplate"></ng-container>
    </span>
    <span *ngSwitchCase="PermitStatus.Suspended" class="chip warning">
        <dtm-ui-icon name="pause-circle"></dtm-ui-icon>
        <ng-container *ngTemplateOutlet="statusTranslationTemplate"></ng-container>
    </span>
    <span *ngSwitchCase="PermitStatus.Waiting" class="chip secondary">
        <dtm-ui-icon name="pause-circle"></dtm-ui-icon>
        <ng-container *ngTemplateOutlet="statusTranslationTemplate"></ng-container>
    </span>
    <ng-template #statusTranslationTemplate>
        {{ "dtmAdminLibPermits.permitsFilters.statusValueLabel" | transloco : { value: status } }}
    </ng-template>
</div>
