import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { QualificationStatus } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { NewQualification, QualificationStatusChange } from "../../shared";
import { PILOT_ENDPOINTS, PilotEndpoints } from "../pilot.tokens";
import { QualificationsErrorType } from "./operator.models";

@Injectable({
    providedIn: "root",
})
export class PilotAPIService {
    constructor(private readonly httpClient: HttpClient, @Inject(PILOT_ENDPOINTS) private readonly endpoints: PilotEndpoints) {}

    public addPilotCompetency(newCompetency: NewQualification, pilotId: string) {
        return this.httpClient
            .post(StringUtils.replaceInTemplate(this.endpoints.addPilotCompetency, { pilotId }), newCompetency)
            .pipe(catchError(() => throwError(() => ({ type: QualificationsErrorType.CannotAdd }))));
    }

    public changePilotCompetencyStatus(pilotId: string, update: QualificationStatusChange) {
        return this.httpClient
            .put(
                StringUtils.replaceInTemplate(this.endpoints.managePilotCompetency, {
                    pilotId,
                    competencyId: update.qualificationId as string,
                }),
                {
                    status: update.status,
                    statusReason: update.reason ?? null,
                }
            )
            .pipe(catchError(() => this.getPilotCompetencyUpdateError(update.status)));
    }

    public suspendAllPilotCompetencies(pilotId: string, update: QualificationStatusChange) {
        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.manageAllCompetencies, { pilotId }), {
                status: update.status,
                statusReason: update.reason,
            })
            .pipe(catchError(() => throwError(() => ({ type: QualificationsErrorType.CannotSuspendAll }))));
    }

    public removeCompetency(pilotId: string, competencyId: string) {
        return this.httpClient
            .delete(StringUtils.replaceInTemplate(this.endpoints.managePilotCompetency, { pilotId, competencyId }))
            .pipe(catchError(() => throwError(() => ({ type: QualificationsErrorType.CannotRemove }))));
    }

    private getPilotCompetencyUpdateError(status: QualificationStatus) {
        let errorType: QualificationsErrorType;
        switch (status) {
            case QualificationStatus.Active:
                errorType = QualificationsErrorType.CannotActivate;
                break;
            case QualificationStatus.Suspended:
                errorType = QualificationsErrorType.CannotSuspend;
                break;

            case QualificationStatus.Withdrawn:
                errorType = QualificationsErrorType.CannotSuspend;
                break;
        }

        return throwError(() => ({ type: errorType }));
    }
}
