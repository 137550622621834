import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { AttorneyPower, AttorneyPowerStatus, AttorneyPowerType } from "../../../models/operator.models";

interface AttorneyPowersTileComponentState {
    attorneyPowers: AttorneyPower[] | [];
    areActionsAvailable: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-attorney-powers-tile[attorneyPowers]",
    templateUrl: "./attorney-powers-tile.component.html",
    styleUrls: ["./attorney-powers-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AttorneyPowersTileComponent {
    @Input() public set attorneyPowers(value: AttorneyPower[] | undefined) {
        this.localStore.patchState({
            attorneyPowers: value ?? [],
        });
    }

    @Input() public set areActionsAvailable(value: BooleanInput) {
        this.localStore.patchState({
            areActionsAvailable: coerceBooleanProperty(value),
        });
    }

    @Output() public update = new EventEmitter<AttorneyPower>();
    @Output() public download = new EventEmitter<AttorneyPower>();

    protected readonly attorneyPowers$ = this.localStore.selectByKey("attorneyPowers");
    protected readonly areActionsAvailable$ = this.localStore.selectByKey("areActionsAvailable");
    protected readonly AttorneyPowerStatus = AttorneyPowerStatus;
    protected readonly AttorneyPowerType = AttorneyPowerType;
    protected readonly elements$ = this.attorneyPowers$.pipe(map((items) => ArrayUtils.groupByCount(items, 1).map((cards) => ({ cards }))));

    constructor(private readonly localStore: LocalComponentStore<AttorneyPowersTileComponentState>) {
        this.localStore.setState({
            attorneyPowers: [],
            areActionsAvailable: true,
        });
    }

    public trackBy(index: number) {
        // NOTE it is needed to force change detection after attorneyPower permissions list changes
        return index;
    }
}
