import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface MessagesTileComponentState {
    profileId: string | undefined;
}

@Component({
    selector: "dtm-admin-lib-messages-tile[profileId]",
    templateUrl: "./messages-tile.component.html",
    styleUrls: ["./messages-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MessagesTileComponent {
    @Input() public set profileId(value: string | undefined) {
        this.localStore.patchState({ profileId: value });
    }

    @Output() public writeNewMessageOpen = new EventEmitter<void>();

    public readonly profileId$ = this.localStore.selectByKey("profileId");

    constructor(private readonly localStore: LocalComponentStore<MessagesTileComponentState>) {
        localStore.setState({ profileId: undefined });
    }
}
