import { ContextOperator } from "@dtm-frontend/shared/ui";

export namespace OperatorContextActions {
    export class GetGlobalCapabilities {
        public static readonly type = "[Operator context] Get global capabilities";
    }

    export class TrySelectContext {
        public static readonly type = "[Operator context] Try to select context";

        constructor(public operator: ContextOperator) {}
    }

    export class SelectContext {
        public static readonly type = "[Operator context] Select context";

        constructor(public operator: ContextOperator) {}
    }

    export class AcceptTermsOfService {
        public static readonly type = "[Operator context] Accept terms of service";

        constructor(public userId: string) {}
    }
}
