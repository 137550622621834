import { OperationScenarioStatementsFilterParams, StatementConfirmationParams } from "../services/operation-scenario-statements.models";

export namespace OperationScenarioStatementsActions {
    export class GetStatementsList {
        public static readonly type = "[Operator scenario statements] Get statements list";
        constructor(public params: OperationScenarioStatementsFilterParams) {}
    }

    export class GetOperationScenarioStatementAdministrativeFeeDocument {
        public static readonly type = "[Operation scenario statement] Get operation scenario statement administrative fee document";
        constructor(public feeDocumentId: string, public fileName: string) {}
    }

    export class GetSignatureConfirmationFile {
        public static readonly type = "[Operation scenario statement] Get signature confirmation file";
        constructor(public params: StatementConfirmationParams) {}
    }
}
