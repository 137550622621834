<ng-container *ngrxLet="data.isReasonProcessing$ as isProcessing">
    <div class="dialog-header">
        <h4 mat-dialog-title>{{ data.titleText }}</h4>
        <button type="button" class="button-icon" [matDialogClose]="false" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <dtm-ui-textarea-field [maxLength]="MAX_REASON_LENGTH">
            <label>{{ "dtmAdminLibShared.operatorProfile.dialogWithReason.reasonFormLabel" | transloco }}</label>
            <textarea matInput [formControl]="reasonControl"></textarea>
            <div class="field-error" *dtmUiFieldHasError="reasonControl; name: 'minlength'; error as error">
                {{
                    "dtmAdminLibShared.operatorProfile.dialogWithReason.reasonMinLengthValueError"
                        | transloco : { minLength: error.requiredLength }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="reasonControl; name: 'maxlength'; error as error">
                {{
                    "dtmAdminLibShared.operatorProfile.dialogWithReason.reasonMaxLengthValueError"
                        | transloco : { maxLength: error.requiredLength }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="reasonControl; name: 'required'">
                {{ "dtmAdminLibShared.operatorProfile.dialogWithReason.reasonRequiredError" | transloco }}
            </div>
        </dtm-ui-textarea-field>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [mat-dialog-close]="false" [disabled]="isProcessing">
            {{ "dtmAdminLibShared.operatorProfile.dialogWithReason.reasonRejectLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="confirm()" [disabled]="isProcessing">
            {{ "dtmAdminLibShared.operatorProfile.dialogWithReason.reasonConfirmLabel" | transloco }}
        </button>
    </div>
</ng-container>
