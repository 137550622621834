import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged, first } from "rxjs/operators";
import { CentersFilterFormKeys, CentersFilterParams } from "../../models/centers.models";

interface CentersFiltersForm {
    [CentersFilterFormKeys.SearchPhrase]: FormControl<string>;
}

interface FiltersComponentState {
    initialFilters: CentersFilterParams | undefined;
    appliedFiltersLength: number | undefined;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: CentersFilterFormKeys.SearchPhrase,
        filterLabel: "dtmAdminLibCenters.filters.searchPhrase",
        type: FilterType.TextEllipsis,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-centers-filters",
    templateUrl: "./centers-filters.component.html",
    styleUrls: ["./centers-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CentersFiltersComponent {
    @Input()
    public set initialFilters(value: CentersFilterParams | undefined) {
        this.localStore.patchState({ initialFilters: value });
    }

    @Output() public readonly filtersChange: EventEmitter<Partial<CentersFilterParams>> = new EventEmitter();

    protected readonly appliedFiltersLength$ = this.localStore.selectByKey("appliedFiltersLength");
    protected readonly CentersFilterFormKeys = CentersFilterFormKeys;
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly centersFiltersForm = new FormGroup<CentersFiltersForm>({
        searchPhrase: new FormControl("", { nonNullable: true }),
    });

    constructor(private readonly localStore: LocalComponentStore<FiltersComponentState>) {
        this.localStore.setState({
            appliedFiltersLength: 0,
            initialFilters: undefined,
        });

        this.assignInitialFilters();
        this.watchFormAndEmitFiltersOnChange();
    }

    protected clearFilters() {
        this.centersFiltersForm.reset();
    }

    private watchFormAndEmitFiltersOnChange() {
        this.centersFiltersForm.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(() => {
                this.setActiveFiltersCount();
                this.filtersChange.emit(this.centersFiltersForm.getRawValue());
            });
    }

    private assignInitialFilters() {
        this.localStore
            .selectByKey("initialFilters")
            .pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
            .subscribe((filters: CentersFilterParams) => {
                this.centersFiltersForm.patchValue({ ...filters });
                this.setActiveFiltersCount();
            });
    }

    private setActiveFiltersCount() {
        this.localStore.patchState({
            appliedFiltersLength: Object.values(this.centersFiltersForm.getRawValue()).flat().filter(FunctionUtils.isTruthy).length,
        });
    }
}
