import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContextOperator } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Actions, Store, ofActionDispatched } from "@ngxs/store";
import { Observable, combineLatest, of } from "rxjs";
import { combineLatestWith, filter, map, switchMap } from "rxjs/operators";
import { OPERATOR_CONTEXT_PARAM } from "../models/operator-context.models";
import { OperatorContextActions } from "../state/operator-context.actions";
import { OperatorContextState } from "../state/operator-context.state";

@Injectable({
    providedIn: "root",
})
export class UserContextService {
    private readonly tries: Set<Observable<boolean>> = new Set([of(true)]);

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {}

    public init() {
        this.observeContextSwitchTry();
        this.observeSelectedContext();
        this.observeQueryRouteParams();
    }

    public registerContextSwitchTry(try$: Observable<boolean>): void {
        this.tries.add(try$);
    }

    public unregisterContextSwitchTry(try$: Observable<boolean>): void {
        this.tries.delete(try$);
    }

    private observeContextSwitchTry(): void {
        this.actions$
            .pipe(
                ofActionDispatched(OperatorContextActions.TrySelectContext),
                map(({ operator }) => operator),
                RxjsUtils.filterFalsy(),
                combineLatestWith(this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy())),
                filter(([operator, currentOperator]) => operator.id !== currentOperator.id),
                map(([operator]) => operator),
                switchMap((operator) =>
                    combineLatest(Array.from(this.tries)).pipe(
                        map((tries) => tries.every(Boolean)),
                        RxjsUtils.filterFalsy(),
                        map(() => operator)
                    )
                )
            )
            .subscribe((operator) => this.store.dispatch(new OperatorContextActions.SelectContext(operator)));
    }

    private observeSelectedContext(): void {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(RxjsUtils.filterFalsy())
            .subscribe((operator) => {
                this.persistSelectedContext(operator);
                this.changeQueryParam(operator);
            });
    }

    private persistSelectedContext(operator: ContextOperator): void {
        localStorage.setItem(OPERATOR_CONTEXT_PARAM, operator.id);
    }

    private changeQueryParam(operator: ContextOperator): void {
        if ((this.route.snapshot.queryParams[OPERATOR_CONTEXT_PARAM] ?? operator.id) === operator.id) {
            return;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [OPERATOR_CONTEXT_PARAM]: operator.id },
            queryParamsHandling: "merge",
        });
    }

    private observeQueryRouteParams(): void {
        this.route.queryParamMap
            .pipe(
                map((params) => params.get(OPERATOR_CONTEXT_PARAM)),
                RxjsUtils.filterFalsy(),
                filter((operatorContextId) => operatorContextId !== this.store.selectSnapshot(OperatorContextState.selectedContext)?.id),
                switchMap((operatorContextId) =>
                    this.store.select(OperatorContextState.operator(operatorContextId)).pipe(RxjsUtils.filterFalsy())
                )
            )
            .subscribe((operator) => {
                this.store.dispatch(new OperatorContextActions.TrySelectContext(operator));
            });
    }
}
