import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ChangeLogType } from "../../../shared";
import { UtmUserDetails } from "../../services/utm-users.models";

interface UtmUserProfileComponentState {
    user: UtmUserDetails | undefined;
}

@Component({
    selector: "dtm-admin-lib-utm-user-profile[user]",
    templateUrl: "./utm-user-profile.component.html",
    styleUrls: ["./utm-user-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UtmUserProfileComponent {
    @Input() public set user(value: UtmUserDetails | undefined) {
        this.localStore.patchState({ user: value });
    }

    @Output() public readonly basicDataEdit = new EventEmitter<void>();
    @Output() public readonly documentDownload = new EventEmitter<void>();
    @Output() public readonly documentAccept = new EventEmitter<void>();
    @Output() public readonly documentReject = new EventEmitter<void>();
    @Output() public readonly documentUpdateRequest = new EventEmitter<void>();
    @Output() public readonly legalGuardianDetailsEdit = new EventEmitter<void>();
    @Output() public readonly legalGuardianDelete = new EventEmitter<void>();
    @Output() public readonly legalGuardianDetailsFill = new EventEmitter<void>();
    @Output() public readonly legalGuardianUpdateRequest = new EventEmitter<void>();
    @Output() public readonly legalGuardianSignedDocumentDownload = new EventEmitter<void>();

    protected readonly user$ = this.localStore.selectByKey("user");
    protected readonly ChangeLogType = ChangeLogType;

    constructor(private readonly localStore: LocalComponentStore<UtmUserProfileComponentState>) {
        localStore.setState({
            user: undefined,
        });
    }
}
