<ng-container *ngrxLet="filtersCount$ as filtersCount">
    <dtm-ui-filters-container [filtersCount]="filtersCount" class="save-bottom-margin">
        <form [formGroup]="filtersForm">
            <div class="filters-container">
                <dtm-ui-input-field>
                    <input
                        matInput
                        type="text"
                        [formControl]="textSearchControl"
                        [placeholder]="'dtmAdminLibAdminUsers.usersListFilters.searchPlaceholder' | transloco"
                    />
                    <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
                    <div class="field-error" *dtmUiFieldHasError="textSearchControl; name: 'maxlength'; error as error">
                        {{
                            "dtmAdminLibAdminUsers.usersListFilters.textSearchValueToLongError" | transloco : { max: error.requiredLength }
                        }}
                    </div>
                </dtm-ui-input-field>
            </div>
        </form>

        <dtm-ui-filter-chips-display
            *ngIf="filtersCount && filtersForm.valid"
            selectedFiltersDisplaySlot
            [filtersMap]="FILTERS_MAP"
            [filtersCount]="filtersCount"
            [formGroup]="filtersForm"
            [filtersValue]="filtersForm.value"
            (allFiltersReset)="clearFilters()"
        >
        </dtm-ui-filter-chips-display>
    </dtm-ui-filters-container>
</ng-container>
