import { MissionType } from "@dtm-frontend/shared/mission";
import {
    Address,
    AdjacentGroundAreaCharacteristicType,
    AirRiskMitigationType,
    AssociationOperationType,
    AssociationPermit,
    AvoidingFlightsOverPeopleType,
    CaaContactPerson,
    Competency,
    ContactPerson,
    ContainmentLevelReachedType,
    ControlledGroundAreaCharacteristicType,
    GroundAreaCharacteristicType,
    GroundRiskMitigationType,
    LocalizationType,
    OperationalAirspaceClassType,
    OperationalArc,
    PageResponseBody,
    Permit,
    PermitType,
    PermitUavModel,
    RiskAssessmentReference,
    Sail,
    TacticalAirRiskMitigationType,
    UavDetailsModel,
} from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import { PhoneNumber } from "libphonenumber-js";
import { PermitStatus, SpecificPermit, SpecificPermitListWithPages } from "../models/permits.models";
import { RawPermitDetails } from "./permit.utils";

export type GetPermitsListResponseBody = PageResponseBody<PermitResponseBody>;
export interface PermitResponseBody {
    id: string;
    type: PermitType;
    permitNumber: string;
    status: PermitStatus;
    statusReason: string;
    name: string;
    operatorId: string;
    operatorName: string;
    createdAt: string;
    validityPeriodStart: string;
    validityPeriodFinish: string;
    flightPurposes: string;
    riskAssessmentReference: RiskAssessmentReference;
    pdraNumber?: string;
}

interface Uav {
    modelId: string;
    operatedNumber: number;
    serialNumbers: string;
    maxWidth: number;
    takeOffMass: number;
    maxFlightSpeed: number;
    dvrOrTc: string;
    cofA: string;
    noiseCertificate: string;
    groundRiskMitigation: GroundRiskMitigationType;
    swarm: boolean;
}

interface CaaContactPersonResponseBody extends Omit<CaaContactPerson, "id"> {
    userId: string;
}

export interface SpecificPermitResponseBody extends Omit<SpecificPermitApplicationRequestPayload, "caaContactUserId"> {
    id: string;
    operatorName: string;
    operatorNumber: string;
    createdAt: string;
    caaContact: CaaContactPersonResponseBody;
}

export interface SpecificPermitWithUavResponseBody {
    specificPermit: SpecificPermitResponseBody;
    uavModel: PermitUavModel;
}

export interface CrossBorderSpecificPermitResponseBody {
    confirmationNumber: string;
    foreignSpecificPermit: SpecificPermitResponseBody;
    uavModel: PermitUavModel;
}

export interface GetKmlLocationResponseBody {
    originLocationView: string;
}

export interface SpecificPermitApplicationRequestPayload {
    caaContactUserId: string;
    name?: string; // NOTE: it exists on backend model but is not send from fronted for now
    operatorId: string;
    operatorContact: ContactPerson;
    permitNumber: string;
    validityPeriodStart: Date;
    validityPeriodFinish: Date;
    flightPurposes: string;
    location: {
        kmlFileId?: string;
        kmlFileName?: string;
        dtmId?: string;
        type?: LocalizationType;
        description?: string; // NOTE: it exists on backend model but is not send from frontend;
    };
    riskAssessmentReference: RiskAssessmentReference;
    pdraNumber?: string;
    assuranceAndIntegrityLevel: Sail;
    operationType: MissionType;
    dangerousGoodsTransport: boolean;
    groundAreaCharacteristic: GroundAreaCharacteristicType;
    controlledGroundAreaCharacteristic?: ControlledGroundAreaCharacteristicType;
    adjacentGroundAreaCharacteristic: AdjacentGroundAreaCharacteristicType;
    adjacentGroundAreaExtent: number;
    operationalVolumeHeightLimit: number;
    operationalAirspaces: OperationalAirspaceClassType[];
    otherAirspace?: string;
    operationalArc: OperationalArc;
    adjacentArc: OperationalArc;
    strategicGroundRiskMitigation: GroundRiskMitigationType;
    emergencyResponsePlan?: GroundRiskMitigationType;
    avoidingFlightsOverPeople?: AvoidingFlightsOverPeopleType;
    strategicAirRiskMitigations: string[];
    tacticalAirRiskMitigation: TacticalAirRiskMitigationType;
    achievedContainmentLevel: ContainmentLevelReachedType;
    pilotCompetencies: string[];
    pilotAdditionalCompetencies?: string[];
    personnelCompetencies?: string[];
    insurance: boolean;
    operationsManualReference: string;
    additionalLimitations: string;
    uav: Uav;
    remarks?: string;
}

export interface CrossBorderPermitApplicationRequestPayload {
    confirmationNumber: string;
    foreignSpecificPermit: SpecificPermitApplicationRequestPayload;
}

export interface AssociationSpecificPermitRequestBody {
    caaContactUserId: string;
    name?: string;
    operatorId: string;
    operatorContact: ContactPerson;
    permitNumber: string;
    validityPeriodStart: string;
    validityPeriodFinish: string;
    flightPurposes: string;
    locations: string;
    operationType: AssociationOperationType;
    insurance: boolean;
}

const convertUavFormToUavRequestBody = (uavDetails: UavDetailsModel) => ({
    modelId: uavDetails.uavModel.id,
    operatedNumber: uavDetails.operatedNumber,
    serialNumbers: uavDetails.serialNumbers,
    maxWidth: uavDetails.maxUavWidth,
    takeOffMass: uavDetails.takeOffWeight,
    maxFlightSpeed: uavDetails.maxFlightSpeed,
    dvrOrTc: uavDetails.designVerificationReport,
    cofA: uavDetails.certificateOfAirworthiness,
    noiseCertificate: uavDetails.noiseCertificate,
    groundRiskMitigation: uavDetails.riskMitigation,
    swarm: uavDetails.isDroneSwarm,
});

function getStrategicAirRiskMitigations(value: AirRiskMitigationType): string[] {
    switch (value) {
        case AirRiskMitigationType.OperationalMitigationAndCommonRulesAndAirspace: {
            return ["OPERATIONAL_MITIGATION", "COMMON_RULES_AND_AIRSPACE"];
        }
        case AirRiskMitigationType.Absence:
            return ["ABSENCE"];
        case AirRiskMitigationType.OperationalMitigation:
            return ["OPERATIONAL_MITIGATION"];
        default:
            return ["COMMON_RULES_AND_AIRSPACE"];
    }
}

export function convertPermitFormDataToSpecificPermitRequestPayload(data: Permit): SpecificPermitApplicationRequestPayload {
    return {
        caaContactUserId: data.basicData.caaContactUser.id,
        operatorContact: data.basicData.operatorContact,
        operatorId: data.basicData.operator.id,
        permitNumber: data.basicData.operationRestrictions.permitNumber,
        validityPeriodStart: data.basicData.operationRestrictions.expirationDate.dateFrom,
        validityPeriodFinish: data.basicData.operationRestrictions.expirationDate.dateTo,
        flightPurposes: data.basicData.operationRestrictions.operationTarget,
        insurance: data.basicData.additionalAccidentType.hasInsurance,
        location: {
            type: data.basicData.operationRestrictions.localization,
            kmlFileId: data.basicData.operationRestrictions?.kmlFile ? data.basicData.operationRestrictions?.kmlFile[0]?.id : undefined,
            dtmId: data.basicData.operationRestrictions.dtm?.id,
        },
        riskAssessmentReference: data.basicData.operationRestrictions.riskMethodology,
        ...(data.basicData.operationRestrictions.pdraNumber && { pdraNumber: data.basicData.operationRestrictions.pdraNumber }),
        assuranceAndIntegrityLevel: data.basicData.operationRestrictions.sail,
        operationType: data.basicData.operationRestrictions.operationType,
        dangerousGoodsTransport: data.basicData.operationRestrictions.isDangerousGoodsTransport,
        groundAreaCharacteristic: data.basicData.groundRiskCharacteristic.groundAreaCharacteristic,
        controlledGroundAreaCharacteristic: data.basicData.groundRiskCharacteristic.controlledGroundAreaCharacteristic,
        adjacentGroundAreaCharacteristic: data.basicData.groundRiskCharacteristic.adjacentGroundAreaCharacteristic,
        adjacentGroundAreaExtent: data.basicData.groundRiskCharacteristic.adjacentGroundAreaExtent,
        emergencyResponsePlan: data.basicData.groundRiskMitigation.emergencyResponsePlan,
        operationalVolumeHeightLimit: data.basicData.finalRiskArea.operationalVolumeHeightLimit,
        pilotAdditionalCompetencies: data.basicData.airRiskMitigation.additionalCompetencies,
        pilotCompetencies: data.basicData.airRiskMitigation.competencies.map((competency: Competency) => competency.id),
        personnelCompetencies: data.basicData.airRiskMitigation.staffCompetencies,
        operationalAirspaces: data.basicData.finalRiskArea.operationalAirSpaces,
        otherAirspace: data.basicData.finalRiskArea.otherAirspace,
        operationalArc: data.basicData.finalRiskArea.operationArea,
        adjacentArc: data.basicData.finalRiskArea.adjacentArea,
        strategicGroundRiskMitigation: data.basicData.groundRiskMitigation.strategicMitigationMeasure,
        avoidingFlightsOverPeople: data.basicData.groundRiskMitigation.avoidingFlightsOverPeople,
        strategicAirRiskMitigations: getStrategicAirRiskMitigations(data.basicData.airRiskMitigation.strategicMitigationMeasure),
        tacticalAirRiskMitigation: data.basicData.airRiskMitigation.tacticalAirRiskMitigationMeasure,
        achievedContainmentLevel: data.basicData.airRiskMitigation.containmentReachedLevel,
        operationsManualReference: data.basicData.additionalAccidentType.operationsManualReference,
        additionalLimitations: data.basicData.additionalAccidentType.additionalLimitations,
        uav: convertUavFormToUavRequestBody(data.uavDetails),
        remarks: data.uavDetails.comments,
    };
}

export function convertCrossBorderPermitFormDataToCrossBorderPermitRequestPayload(
    data: Permit
): CrossBorderPermitApplicationRequestPayload {
    return {
        confirmationNumber: data.basicData.operationRestrictions.permitConfirmationNumber as string,
        foreignSpecificPermit: convertPermitFormDataToSpecificPermitRequestPayload(data),
    };
}

export interface AssociationPermitResponseBody {
    caaContact: CaaContactPersonResponseBody;
    associationInfo: {
        operatorId: string;
        name: string;
        operatorNumber: string;
        nationalCourtRegister?: string;
        registrationNumber?: string;
        companyNumber: string;
        phoneNumber: PhoneNumber;
        email: string;
        address: Address;
    };
    operatorContact: ContactPerson;
    permitNumber: string;
    validityPeriodStart: string;
    validityPeriodFinish: string;
    flightPurposes: string;
    locations: string;
    operationType: AssociationOperationType;
    insurance: boolean;
    createdAt: string;
    status: PermitStatus;
    statusReason: PermitStatus;
    id: string;
    type: PermitType;
    name: string;
}

export function convertAssociationPermitFormDataToAssociationSpecificPermitRequestBody(
    data: AssociationPermit
): AssociationSpecificPermitRequestBody {
    return {
        caaContactUserId: data.caaContact.id,
        operatorId: data.associationInfo.id,
        operatorContact: data.operatorContact,
        permitNumber: data.permitNumber,
        validityPeriodStart: DateUtils.getISOStringDate(data.expirationDate.dateFrom.toISOString()),
        validityPeriodFinish: DateUtils.getISOStringDate(data.expirationDate.dateTo.toISOString()),
        flightPurposes: data.flightPurpose,
        locations: data.locations,
        operationType: data.operationType,
        insurance: data.isInsurance,
    };
}

export function getFormattedStringDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const day = date.getDate();

    return `${day}.${month}.${year}`;
}

export function getPermitFromPermitResponseBody(permitResponse: PermitResponseBody): SpecificPermit {
    return {
        id: permitResponse.id,
        operatorId: permitResponse.operatorId,
        status: permitResponse.status,
        consentNumber: permitResponse.permitNumber,
        operatorName: permitResponse.operatorName,
        validityPeriod: `${getFormattedStringDate(permitResponse.validityPeriodStart)} - ${getFormattedStringDate(
            permitResponse.validityPeriodFinish
        )}`,
        permitName: permitResponse.name,
        flightPurposes: permitResponse.flightPurposes,
        statusReason: permitResponse.statusReason,
        type: permitResponse.type,
        riskAssessmentReference: permitResponse.riskAssessmentReference,
        pdraNumber: permitResponse.riskAssessmentReference === RiskAssessmentReference.PDRA ? permitResponse.pdraNumber : undefined,
        createdAt: new Date(permitResponse.createdAt),
    };
}

export function convertGetPermitListResponseBodyToPermitListWithPages(response: GetPermitsListResponseBody): SpecificPermitListWithPages {
    return {
        content: response.content.map((permit) => getPermitFromPermitResponseBody(permit)),
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export function convertSpecificPermitResponseBodyToRawPermitDetails(
    response: SpecificPermitWithUavResponseBody,
    confirmationNumber?: string
): RawPermitDetails {
    return {
        id: response.specificPermit.id,
        operatorName: response.specificPermit.operatorName,
        operatorNumber: response.specificPermit.operatorNumber,
        createdAt: new Date(response.specificPermit.createdAt),
        caaContact: {
            id: response.specificPermit.caaContact.userId,
            firstName: response.specificPermit.caaContact.firstName,
            lastName: response.specificPermit.caaContact.lastName,
            email: response.specificPermit.caaContact.email,
            phoneNumber: response.specificPermit.caaContact.phoneNumber,
        },
        operatorId: response.specificPermit.operatorId,
        operatorContact: response.specificPermit.operatorContact,
        permitNumber: response.specificPermit.permitNumber,
        permitConfirmationNumber: confirmationNumber,
        validityPeriodStart: new Date(response.specificPermit.validityPeriodStart),
        validityPeriodFinish: new Date(response.specificPermit.validityPeriodFinish),
        flightPurposes: response.specificPermit.flightPurposes,
        location: {
            kmlFileId: response.specificPermit.location?.kmlFileId,
            kmlFileName: response.specificPermit.location?.kmlFileName,
            dtmId: response.specificPermit.location?.dtmId,
            type: response.specificPermit.location?.type,
        },
        riskAssessmentReference: response.specificPermit.riskAssessmentReference,
        pdraNumber: response.specificPermit.pdraNumber,
        assuranceAndIntegrityLevel: response.specificPermit.assuranceAndIntegrityLevel,
        operationType: response.specificPermit.operationType,
        isDangerousGoodsTransport: response.specificPermit.dangerousGoodsTransport,
        groundAreaCharacteristic: response.specificPermit.groundAreaCharacteristic,
        controlledGroundAreaCharacteristic: response.specificPermit.controlledGroundAreaCharacteristic,
        adjacentGroundAreaCharacteristic: response.specificPermit.adjacentGroundAreaCharacteristic,
        adjacentGroundAreaExtent: response.specificPermit.adjacentGroundAreaExtent,
        operationalVolumeHeightLimit: response.specificPermit.operationalVolumeHeightLimit,
        operationalAirSpaces: response.specificPermit.operationalAirspaces,
        otherAirspace: response.specificPermit.otherAirspace,
        operationalArc: response.specificPermit.operationalArc,
        adjacentArc: response.specificPermit.adjacentArc,
        strategicGroundRiskMitigation: response.specificPermit.strategicGroundRiskMitigation,
        emergencyResponsePlan: response.specificPermit.emergencyResponsePlan,
        avoidingFlightsOverPeople: response.specificPermit.avoidingFlightsOverPeople,
        strategicAirRiskMitigations: response.specificPermit.strategicAirRiskMitigations,
        tacticalAirRiskMitigation: response.specificPermit.tacticalAirRiskMitigation,
        achievedContainmentLevel: response.specificPermit.achievedContainmentLevel,
        pilotCompetencies: response.specificPermit.pilotCompetencies,
        pilotAdditionalCompetencies: response.specificPermit.pilotAdditionalCompetencies,
        personnelCompetencies: response.specificPermit.personnelCompetencies,
        isInsurance: response.specificPermit.insurance,
        operationsManualReference: response.specificPermit.operationsManualReference,
        additionalLimitations: response.specificPermit.additionalLimitations,
        uav: {
            modelId: response.specificPermit.uav.modelId,
            operatedNumber: response.specificPermit.uav.operatedNumber,
            serialNumbers: response.specificPermit.uav.serialNumbers,
            maxWidth: response.specificPermit.uav.maxWidth,
            takeOffMass: response.specificPermit.uav.takeOffMass,
            maxFlightSpeed: response.specificPermit.uav.maxFlightSpeed,
            designVerificationReport: response.specificPermit.uav.dvrOrTc,
            certificateOfAirworthiness: response.specificPermit.uav.cofA,
            noiseCertificate: response.specificPermit.uav.noiseCertificate,
            groundRiskMitigation: response.specificPermit.uav.groundRiskMitigation,
            isSwarm: response.specificPermit.uav.swarm,
        },
        remarks: response.specificPermit.remarks,
        uavModel: response.uavModel,
    };
}

export function convertCrossBorderSpecificPermitResponseBodyToRawPermitDetails(
    response: CrossBorderSpecificPermitResponseBody
): RawPermitDetails {
    const permitWithUav = { specificPermit: response.foreignSpecificPermit, uavModel: response.uavModel };

    return convertSpecificPermitResponseBodyToRawPermitDetails(permitWithUav, response.confirmationNumber);
}

export function convertAssociationPermitResponseBodyToAssociationPermit(response: AssociationPermitResponseBody): AssociationPermit {
    return {
        caaContact: {
            id: response.caaContact.userId,
            firstName: response.caaContact.firstName,
            lastName: response.caaContact.lastName,
            email: response.caaContact.email,
            phoneNumber: response.caaContact.phoneNumber,
        },
        associationInfo: {
            id: response.associationInfo.operatorId,
            name: response.associationInfo.name,
            number: response.associationInfo.operatorNumber,
            associationNationalCourtRegister: response.associationInfo.nationalCourtRegister,
            associationRegistrationNumber: response.associationInfo.registrationNumber,
            address: response.associationInfo.address,
        },
        flightPurpose: response.flightPurposes,
        id: response.id,
        isInsurance: response.insurance,
        locations: response.locations,
        operationType: response.operationType,
        operatorContact: response.operatorContact,
        permitNumber: response.permitNumber,
        type: response.type,
        expirationDate: {
            dateTo: new Date(response.validityPeriodFinish),
            dateFrom: new Date(response.validityPeriodStart),
        },
    };
}
