import { Address, Page, PhoneNumber } from "@dtm-frontend/shared/ui";
import { Observable } from "rxjs";

export interface ExaminationCenterDialogData {
    header?: string;
    confirmLabel?: string;
    isCenterProcessing$?: Observable<boolean>;
    center?: ExaminationCenter;
    capabilities: string[];
}

export enum ExaminationCentersErrorType {
    CannotAddExaminationCenter = "CannotAddExaminationCenter",
    CannotEditExaminationCenter = "CannotEditExaminationCenter",
    CannotGetExaminationCentersList = "CannotGetExaminationCentersList",
    CannotDeleteExaminationCenter = "CannotDeleteExaminationCenter",
    ExaminationCenterConflict = "ExaminationCenterConflict",
}

export interface ExaminationCenterListWithPages extends Page {
    content: ExaminationCenter[];
}

export interface ExaminationCentersError {
    type: ExaminationCentersErrorType;
}

export interface ExaminationCenter {
    id?: string;
    examCodes: string[];
    name: string;
    identificationNumber?: string;
    administrativeDecisionNumber: string;
    email: string;
    phoneNumber: PhoneNumber;
    address: Address;
    validUntil: Date;
}
