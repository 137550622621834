import { HttpParams } from "@angular/common/http";
import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { AdminUser, AdminUsersQueryParams } from "./models";

export type AdminUserWithPages = PageResponseBody<AdminUser>;

export function transformGetAdminUsersPayloadToGetAdminUsersParams(payload: AdminUsersQueryParams): HttpParams {
    let params = new HttpParams().set("page", payload.page).set("size", payload.size);

    if (payload.textSearch) {
        params = params.set("name", payload.textSearch);
    }

    return params;
}
