import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DialogService, MIN_PAGE_SIZE_VALUE, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { filter } from "rxjs/operators";
import {
    ExamTrainingResultsAddDialogData,
    ExamTrainingResultsFilterFormKeys,
    ExamTrainingResultsFilterParams,
    ExamTrainingResultsFiltersTabKeys,
    ExamTrainingResultsReportDetails,
    ExamTrainingResultsReportType,
    ExamTrainingResultsTabType,
} from "../../models/exam-training-results.models";
import { ExamTrainingResultsActions } from "../../state/exam-training-results.actions";
import { ExamTrainingResultsState } from "../../state/exam-training-results.state";
import { ExamTrainingResultsAddDialogComponent } from "../exam-training-results-add-dialog/exam-training-results-add-dialog.component";
import { ExamTrainingResultsUploadFileDialogComponent } from "../exam-training-results-upload-file-dialog/exam-training-results-upload-file-dialog.component";

interface ExamTrainingResultsContainerComponentState {
    [ExamTrainingResultsFilterFormKeys.ActiveTabIndex]: ExamTrainingResultsTabType;
    [ExamTrainingResultsFiltersTabKeys.ExamResultsFilters]: ExamTrainingResultsFilterParams;
    [ExamTrainingResultsFiltersTabKeys.TrainingResultsFilters]: ExamTrainingResultsFilterParams;
}

const DEFAULT_ACTIVE_TAB = ExamTrainingResultsTabType.Exams;
const DEFAULT_FILTERS_VALUE = {
    [ExamTrainingResultsFilterFormKeys.SearchPhrase]: "",
    [PAGE_NUMBER_QUERY_PARAM]: 0,
    [PAGE_SIZE_QUERY_PARAM]: MIN_PAGE_SIZE_VALUE,
};

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-exam-training-results-container",
    templateUrl: "./exam-training-results-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ExamTrainingResultsContainerComponent implements OnInit {
    protected readonly activeTabIndex$ = this.localStore.selectByKey(ExamTrainingResultsFilterFormKeys.ActiveTabIndex);
    protected readonly examResultsFilters$ = this.localStore.selectByKey(ExamTrainingResultsFiltersTabKeys.ExamResultsFilters);
    protected readonly trainingResultsFilters$ = this.localStore.selectByKey(ExamTrainingResultsFiltersTabKeys.TrainingResultsFilters);
    protected readonly examResultsList$ = this.store.select(ExamTrainingResultsState.examResultsList);
    protected readonly examResultsPages$ = this.store.select(ExamTrainingResultsState.examResultsPages);
    protected readonly examResultsListError$ = this.store.select(ExamTrainingResultsState.examResultsListError);
    protected readonly isExamResultsListProcessing$ = this.store.select(ExamTrainingResultsState.isExamResultsListProcessing);
    protected readonly trainingResultsList$ = this.store.select(ExamTrainingResultsState.trainingResultsList);
    protected readonly trainingResultsPages$ = this.store.select(ExamTrainingResultsState.trainingResultsPages);
    protected readonly trainingResultsListError$ = this.store.select(ExamTrainingResultsState.trainingResultsListError);
    protected readonly isTrainingResultsListProcessing$ = this.store.select(ExamTrainingResultsState.isTrainingResultsListProcessing);
    protected readonly isExamTrainingResultsReportDetailsProcessing$ = this.store.select(
        ExamTrainingResultsState.isExamTrainingResultsReportDetailsProcessing
    );
    protected readonly isExamTrainingResultProcessing$ = this.store.select(ExamTrainingResultsState.isExamTrainingResultProcessing);

    protected readonly ExamTrainingResultsTabType = ExamTrainingResultsTabType;
    protected readonly ExamTrainingResultsFiltersTabKeys = ExamTrainingResultsFiltersTabKeys;

    constructor(
        private readonly localStore: LocalComponentStore<ExamTrainingResultsContainerComponentState>,
        private readonly store: Store,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        const snapshotParams = this.route.snapshot.queryParams;
        const snapShotData = {
            [PAGE_NUMBER_QUERY_PARAM]: snapshotParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: snapshotParams[PAGE_SIZE_QUERY_PARAM] ?? MIN_PAGE_SIZE_VALUE,
            searchPhrase: snapshotParams.searchPhrase,
        };
        const snapshotTabIndex = +snapshotParams[ExamTrainingResultsFilterFormKeys.ActiveTabIndex];

        this.localStore.setState({
            [ExamTrainingResultsFilterFormKeys.ActiveTabIndex]: DEFAULT_ACTIVE_TAB,
            [ExamTrainingResultsFiltersTabKeys.ExamResultsFilters]:
                snapshotTabIndex === ExamTrainingResultsTabType.Exams ? snapShotData : DEFAULT_FILTERS_VALUE,
            [ExamTrainingResultsFiltersTabKeys.TrainingResultsFilters]:
                snapshotTabIndex === ExamTrainingResultsTabType.Trainings ? snapShotData : DEFAULT_FILTERS_VALUE,
        });
    }

    public ngOnInit(): void {
        this.listenOnQueryParamsChanged();
    }

    protected updateTabIndex(activeTabIndex: ExamTrainingResultsTabType): void {
        this.localStore.patchState({ activeTabIndex });
        this.applyFilters(activeTabIndex);
    }

    private listenOnQueryParamsChanged(): void {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: Params) => {
            const index = queryParams?.[ExamTrainingResultsFilterFormKeys.ActiveTabIndex];
            const activeTabIndex = !index ? DEFAULT_ACTIVE_TAB : +index;
            this.updateTabIndex(activeTabIndex);

            this.refreshData(activeTabIndex);
        });
    }

    protected getExamResultsList(): void {
        const examResultsFilters = this.localStore.selectSnapshotByKey(ExamTrainingResultsFiltersTabKeys.ExamResultsFilters);
        this.store.dispatch(new ExamTrainingResultsActions.GetExamResultsList(examResultsFilters));
    }

    protected getTrainingResultsList(): void {
        const trainingResultsFilters = this.localStore.selectSnapshotByKey(ExamTrainingResultsFiltersTabKeys.TrainingResultsFilters);
        this.store.dispatch(new ExamTrainingResultsActions.GetTrainingResultsList(trainingResultsFilters));
    }

    protected resultsPageChange({ pageIndex, pageSize }: PageEvent, filterTabKey: ExamTrainingResultsFiltersTabKeys): void {
        const activeTabIndex = this.localStore.selectSnapshotByKey(ExamTrainingResultsFilterFormKeys.ActiveTabIndex);
        const previousFilters = this.localStore.selectSnapshotByKey(filterTabKey);
        this.localStore.patchState({
            [filterTabKey]: {
                ...previousFilters,
                [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
                [PAGE_SIZE_QUERY_PARAM]: pageSize,
            },
        });

        this.applyFilters(activeTabIndex);
    }

    protected navigateByParams(filters: Partial<ExamTrainingResultsFilterParams>, index: ExamTrainingResultsTabType): void {
        const activeTabIndex = this.localStore.selectSnapshotByKey(ExamTrainingResultsFilterFormKeys.ActiveTabIndex) ?? DEFAULT_ACTIVE_TAB;

        if (activeTabIndex !== index) {
            return;
        }

        const key = this.getFilterKeyByIndex(index);
        const previousFilterState = this.localStore.selectSnapshotByKey(key);
        this.localStore.patchState({ [key]: { ...previousFilterState, ...filters } });
        this.applyFilters(activeTabIndex);
    }

    private applyFilters(activeTabIndex: ExamTrainingResultsTabType = DEFAULT_ACTIVE_TAB): void {
        const filters = this.localStore.selectSnapshotByKey(this.getFilterKeyByIndex(+activeTabIndex));
        let params: ExamTrainingResultsFilterParams = {
            [ExamTrainingResultsFilterFormKeys.ActiveTabIndex]: activeTabIndex,
            [PAGE_NUMBER_QUERY_PARAM]: filters[PAGE_NUMBER_QUERY_PARAM],
            [PAGE_SIZE_QUERY_PARAM]: filters[PAGE_SIZE_QUERY_PARAM],
        };

        if (filters[ExamTrainingResultsFilterFormKeys.SearchPhrase]) {
            params = {
                ...params,
                [ExamTrainingResultsFilterFormKeys.SearchPhrase]: filters[ExamTrainingResultsFilterFormKeys.SearchPhrase],
            };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    protected openFileUploadDialog(type: ExamTrainingResultsReportType) {
        this.store.dispatch(new ExamTrainingResultsActions.SetExamTrainingResultsReportType(type));
        const dialogRef = this.dialogService.open(ExamTrainingResultsUploadFileDialogComponent, {
            disableClose: true,
        });
        dialogRef.componentInstance.newValue$.pipe(untilDestroyed(this)).subscribe(async (fileId) => {
            if (fileId) {
                this.store.dispatch(new ExamTrainingResultsActions.GetExamTrainingResultsReportDetails(fileId));

                await firstValueFrom(
                    this.isExamTrainingResultsReportDetailsProcessing$.pipe(
                        filter((processing) => !processing),
                        untilDestroyed(this)
                    )
                );

                if (this.store.selectSnapshot(ExamTrainingResultsState.examTrainingResultsReportDetailsError)) {
                    this.toastrService.error(
                        this.translocoService.translate("dtmAdminLibExamTrainingResults.messages.reportsDetailsErrorMessage")
                    );

                    return;
                }

                const results: ExamTrainingResultsReportDetails = this.store.selectSnapshot(
                    ExamTrainingResultsState.examTrainingResultsReportDetails
                ) as ExamTrainingResultsReportDetails;

                dialogRef.close();
                this.openAddDialog(results, fileId);
            } else if (fileId === null) {
                this.toastrService.info(
                    this.translocoService.translate("dtmAdminLibExamTrainingResults.messages.resultsNotProvidedMessage")
                );
            }

            dialogRef.close();
        });
    }

    protected deleteResults(id: string, type: ExamTrainingResultsTabType): void {
        this.store
            .dispatch(
                new ExamTrainingResultsActions[type === ExamTrainingResultsTabType.Exams ? "DeleteExamResults" : "DeleteTrainingResults"](
                    id
                )
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.store.selectSnapshot(ExamTrainingResultsState.examTrainingResultsReportDetailsError)) {
                    this.toastrService.error(
                        this.translocoService.translate("dtmAdminLibExamTrainingResults.messages.deleteExamTrainingResultsErrorMessage")
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("dtmAdminLibExamTrainingResults.messages.deleteExamTrainingResultsSuccessMessage")
                );

                this.refreshData(type);
            });
    }

    private openAddDialog(results: ExamTrainingResultsReportDetails, fileId: string) {
        const data: ExamTrainingResultsAddDialogData = { results, isExamTrainingResultProcessing$: this.isExamTrainingResultProcessing$ };
        const dialogRef = this.dialogService.open(ExamTrainingResultsAddDialogComponent, {
            data,
            disableClose: true,
        });
        dialogRef.componentInstance.newValue$.pipe(untilDestroyed(this)).subscribe(async () => {
            this.store.dispatch(new ExamTrainingResultsActions.AddExamTrainingResults(fileId));

            await firstValueFrom(
                this.isExamTrainingResultProcessing$.pipe(
                    filter((processing) => !processing),
                    untilDestroyed(this)
                )
            );

            if (this.store.selectSnapshot(ExamTrainingResultsState.examTrainingResultError)) {
                this.toastrService.error(
                    this.translocoService.translate("dtmAdminLibExamTrainingResults.messages.addExamTrainingResultsErrorMessage")
                );

                return;
            }

            dialogRef.close();
            this.handleAddResults();
        });
    }

    private getFilterKeyByIndex(tabIndex: ExamTrainingResultsTabType): ExamTrainingResultsFiltersTabKeys {
        switch (tabIndex) {
            case ExamTrainingResultsTabType.Exams:
                return ExamTrainingResultsFiltersTabKeys.ExamResultsFilters;
            case ExamTrainingResultsTabType.Trainings:
                return ExamTrainingResultsFiltersTabKeys.TrainingResultsFilters;
        }
    }

    private refreshData(activeTabIndex: ExamTrainingResultsTabType) {
        if (activeTabIndex === ExamTrainingResultsTabType.Exams) {
            this.getExamResultsList();

            return;
        }
        this.getTrainingResultsList();
    }

    private handleAddResults(): void {
        this.toastrService.success(
            this.translocoService.translate("dtmAdminLibExamTrainingResults.messages.addExamTrainingResultsSuccessMessage")
        );

        const currentIndex = this.localStore.selectSnapshotByKey(ExamTrainingResultsFilterFormKeys.ActiveTabIndex);
        const selectedReportType = this.store.selectSnapshot(ExamTrainingResultsState.selectedExamTrainingResultsReportType);
        const nextIndex =
            selectedReportType === ExamTrainingResultsReportType.TrainingOperatorReport
                ? ExamTrainingResultsTabType.Trainings
                : ExamTrainingResultsTabType.Exams;

        if (currentIndex === nextIndex) {
            this.refreshData(currentIndex);
        } else {
            this.applyFilters(nextIndex);
        }
    }
}
