<div class="grid">
    <mat-card>
        <dtm-ui-card-header [isEditButtonVisible]="false">
            {{ "dtmAdminLibPermits.container.header" | transloco }}
            <ng-container additionalActions>
                <button [matMenuTriggerFor]="actionsMenu" type="button" class="button-primary">
                    {{ "dtmAdminLibPermits.container.launchPermitButtonLabel" | transloco }}
                    <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
                </button>
                <mat-menu #actionsMenu="matMenu" class="wide-panel">
                    <button mat-menu-item type="button" [routerLink]="['/specific-permit-flight-request']">
                        <span>{{ "dtmAdminLibPermits.container.specificPermitFlightButtonLabel" | transloco }}</span>
                    </button>
                    <button mat-menu-item type="button" [routerLink]="['/association-permit-flight-request']">
                        <span>{{ "dtmAdminLibPermits.container.associationPermitFlightButtonLabel" | transloco }}</span>
                    </button>
                    <button mat-menu-item type="button" [routerLink]="['/cross-border-permit-flight-request']">
                        <span>{{ "dtmAdminLibPermits.container.crossBorderPermitFlightButtonLabel" | transloco }}</span>
                    </button>
                </mat-menu>
            </ng-container>
        </dtm-ui-card-header>
        <div class="dtm-tabs">
            <mat-tab-group
                color="accent"
                *ngrxLet="activeTabIndex$ as activeTab"
                [selectedIndex]="activeTab"
                (selectedIndexChange)="updateTabIndex($event)"
            >
                <mat-tab [label]="'dtmAdminLibPermits.container.specificPermitLabel' | transloco">
                    <dtm-admin-lib-permits-filters
                        [initialFilters]="specificPermitsFilters$ | ngrxPush"
                        (filtersChange)="navigateByParams($event, PermitsTab.SpecificPermit)"
                    ></dtm-admin-lib-permits-filters>
                    <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
                </mat-tab>
                <mat-tab [label]="'dtmAdminLibPermits.container.associationPermitLabel' | transloco">
                    <dtm-admin-lib-permits-filters
                        [initialFilters]="associationFilters$ | ngrxPush"
                        (filtersChange)="navigateByParams($event, PermitsTab.Association)"
                    ></dtm-admin-lib-permits-filters>
                    <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
                </mat-tab>
                <mat-tab [label]="'dtmAdminLibPermits.container.crossBorderPermitLabel' | transloco">
                    <dtm-admin-lib-permits-filters
                        [initialFilters]="crossBorderFilters$ | ngrxPush"
                        (filtersChange)="navigateByParams($event, PermitsTab.CrossBorder)"
                    ></dtm-admin-lib-permits-filters>
                    <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-card>
</div>

<ng-template #listTemplate>
    <dtm-admin-lib-specific-permits-list
        [specificPermitsList]="specificPermits$ | ngrxPush"
        [pagination]="specificPermitsPages$ | ngrxPush"
        [specificPermitsListError]="specificPermitsError$ | ngrxPush"
        [isSpecificPermitsProcessing]="isSpecificPermitsProcessing$ | ngrxPush"
        (pageChange)="specificPermitsPageChange($event)"
        (specificPermitsListRefresh)="specificPermitsListRefresh()"
        (openDialog)="openActionDialog($event)"
    ></dtm-admin-lib-specific-permits-list>
</ng-template>
