import { Injectable } from "@angular/core";

import { AuthState } from "@dtm-frontend/shared/auth";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { UserProfileActions } from "../state/user-profile.actions";

@Injectable({
    providedIn: "root",
})
export class UserProfileResolver {
    constructor(private readonly store: Store) {}

    public resolve(): Observable<void> {
        return this.store.select(AuthState.userId).pipe(
            RxjsUtils.filterFalsy(),
            first(),
            switchMap((userId) =>
                this.store.dispatch([
                    new UserProfileActions.GetUserProfile(userId),
                    new UserProfileActions.GetProfileCapabilities(userId),
                    new UserProfileActions.GetProfileAvatar(userId),
                ])
            )
        );
    }
}
