import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { PilotCompetencies, PilotOperatorQualifications } from "../../models/mission.models";

interface PilotOperatorQualificationsComponentState {
    title: string;
    qualifications: PilotOperatorQualifications[];
}

@Component({
    selector: "dtm-web-app-lib-pilot-operator-qualifications[title][qualifications]",
    templateUrl: "./pilot-operator-qualifications.component.html",
    styleUrls: ["./pilot-operator-qualifications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotOperatorQualificationsComponent {
    @Input() public set title(value: string) {
        this.localStore.patchState({ title: value ?? "" });
    }
    @Input() public set qualifications(value: PilotOperatorQualifications[]) {
        this.localStore.patchState({ qualifications: value ?? [] });
    }

    @Output() public drawerClose = new EventEmitter<void>();

    protected readonly qualifications$ = this.localStore.selectByKey("qualifications");
    protected readonly title$ = this.localStore.selectByKey("title");
    protected readonly PilotCompetencies = PilotCompetencies;

    constructor(private readonly localStore: LocalComponentStore<PilotOperatorQualificationsComponentState>) {
        this.localStore.setState({
            title: "",
            qualifications: [],
        });
    }

    protected isOpenIcon(permit: PilotOperatorQualifications): boolean {
        return [PilotCompetencies.A1, PilotCompetencies.A2, PilotCompetencies.A3].includes(permit as PilotCompetencies);
    }
}
