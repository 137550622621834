<dtm-ui-editable-card
    *ngrxLet="address$ as address"
    [label]="'dtmWebAppLibShared.addressDetails.addressDetailsHeader' | transloco"
    [canLeaveEditModeOnSave]="false"
    [isEditModeOn]="isEditModeOn$ | ngrxPush"
    (editModeChange)="manageFormState($event)"
    (actionSave)="saveForm()"
>
    <ng-container previewContent>
        <dtm-ui-address-label-value [address]="address" [isCountryFlagVisible]="true" [label]="label$ | ngrxPush">
        </dtm-ui-address-label-value>
    </ng-container>
    <div editableContent class="address-form">
        <dtm-ui-country-select-field [formControl]="countryControl">
            <label>
                {{ "dtmWebAppLibShared.addressDetails.countryLabel" | transloco }}
            </label>
            <div class="field-error" *dtmUiFieldHasError="countryControl; name: ['required', 'requiredTouched']">
                {{ "dtmWebAppLibShared.addressDetails.requiredFieldError" | transloco }}
            </div>
        </dtm-ui-country-select-field>

        <dtm-ui-address-form
            [formControl]="basicAddressControl"
            dtmUiMarkValueAccessorControlsAsTouched
            [isSystemDefaultCountrySelected]="countryControl.value === DEFAULT_COUNTRY_CODE"
        >
        </dtm-ui-address-form>
    </div>
</dtm-ui-editable-card>
