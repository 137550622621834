<dtm-ui-expandable-panel *ngIf="operator$ | ngrxPush as operator" [hasHeaderSeparator]="false" [isExpanded]="true">
    <div class="info-header" headerSlot>{{ "dtmAdminLibPermits.operatorInfoPanel.header" | transloco }}</div>
    <div class="info-block">
        <dtm-ui-label-value
            [label]="'dtmAdminLibPermits.operatorInfoPanel.operatorNumberLabel' | transloco"
            [value]="operator.number"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmAdminLibPermits.operatorInfoPanel.operatorNameLabel' | transloco"
            [value]="operator.name"
        ></dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>
