import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { AdminUsersComponent } from "./components/admin-users/admin-users.component";

const routes: Routes = [
    {
        path: "admin-users",
        component: AdminUsersComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibAdminUsers.routeTitles.users",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminUsersRoutingModule {}
