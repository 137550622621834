<button
    type="button"
    [routerLink]="['/permits']"
    [queryParams]="{
        activeTab: operatorPermitType === PermitType.OperationPermission ? PermitsTab.SpecificPermit : PermitsTab.CrossBorder
    }"
    class="button-secondary redirect-button"
>
    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
    {{ "dtmAdminLibPermits.specificPermitDetails.goBackToListButtonLabel" | transloco }}
</button>

<div class="grid grid-maxsize-8 grid-maxsize-md-12" *ngIf="!(applicationPermitError$ | ngrxPush); else errorTemplate">
    <mat-card>
        <h2>
            {{ "dtmAdminLibPermits.specificPermitDetails.title" | transloco : { operatorPermitType: operatorPermitType } }}
        </h2>
        <dtm-ui-permit-details-preview
            [permit]="permit$ | ngrxPush"
            (kmlFilePreview)="previewKmlFile($event)"
            isOperatorDetailsTileExpanded
            isContactPersonTileExpanded
            isRestrictionsTileExpanded
            isPermitDetailsExpanded
            isOperatorContactPersonVisible
        ></dtm-ui-permit-details-preview>
    </mat-card>
</div>

<ng-template #errorTemplate>
    <dtm-ui-error
        [errorMessage]="'dtmAdminLibPermits.specificPermitDetails.cannotGetPermitDetailsError' | transloco"
        (reload)="getSpecificPermit()"
    ></dtm-ui-error>
</ng-template>
