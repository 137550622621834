import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { LegacyDateAdapter as DateAdapter } from "@angular/material/legacy-core";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { CustomDateAdapter, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ConversationModule } from "../conversation/conversation.module";
import { SharedModule } from "../shared";
import { FiltersComponent } from "./components/filters/filters.component";
import { OperatorListComponent } from "./components/operator-list/operator-list.component";
import { OperatorProfileContainerComponent } from "./components/operator-profile-container/operator-profile-container.component";
import { OperatorsContainerComponent } from "./components/operators-container/operators-container.component";
import { OperatorAPIService } from "./services/operator-api.service";
import { PilotAPIService } from "./services/pilot-api.service";
import { OperatorState } from "./state/operator.state";

@NgModule({
    declarations: [OperatorListComponent, OperatorsContainerComponent, FiltersComponent, OperatorProfileContainerComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        NgxsModule.forFeature([OperatorState]),
        LetModule,
        PushModule,
        ReactiveFormsModule,
        RouterModule,
        SharedToastModule.forRoot(),
        SharedI18nModule,
        MatPaginatorModule,
        MatTableModule,
        MatToolbarModule,
        MatDividerModule,
        MatButtonToggleModule,
        SharedModule.forRoot(),
        ConversationModule.forRoot(),
        MatChipsModule,
        SharedUiModule,
        MatExpansionModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibOperator",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: OperatorAPIService,
            useValue: undefined,
        },
        {
            provide: PilotAPIService,
            useValue: undefined,
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
    ],
    exports: [OperatorsContainerComponent, OperatorListComponent, FiltersComponent],
})
export class OperatorModule {
    public static forRoot(): ModuleWithProviders<OperatorModule> {
        return {
            ngModule: OperatorModule,
            providers: [
                {
                    provide: OperatorAPIService,
                    useClass: OperatorAPIService,
                },
                {
                    provide: PilotAPIService,
                    useClass: PilotAPIService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<OperatorModule> {
        return {
            ngModule: OperatorModule,
            providers: [
                {
                    provide: OperatorAPIService,
                    useValue: null,
                },
                {
                    provide: PilotAPIService,
                    useValue: PilotAPIService,
                },
            ],
        };
    }
}
