import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { OperatorInsurancePolicy } from "../../models/pilot-and-operator.models";

interface InsurancePolicyEditComponentState {
    insuranceNumber: string | undefined;
    expirationDate: string | undefined;
    isEditModeOn: boolean;
}

interface PolicyNumberForm {
    number: FormControl<string>;
    expirationDate: FormControl<string>;
}

const MAX_INSURANCE_POLICY_LENGTH = 50;
const TOMORROW_DATE = new Date();
TOMORROW_DATE.setDate(TOMORROW_DATE.getDate() + 1);

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-insurance-policy-edit",
    templateUrl: "./insurance-policy-edit.component.html",
    styleUrls: ["./insurance-policy-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InsurancePolicyEditComponent {
    @Input() public set insuranceNumber(value: string | undefined) {
        this.localStore.patchState({ insuranceNumber: value });
    }

    @Input() public set expirationDate(value: string | undefined) {
        this.localStore.patchState({ expirationDate: value });
    }

    @Output() public save = new EventEmitter<OperatorInsurancePolicy>();
    @Output() public delete = new EventEmitter<void>();

    protected readonly insuranceNumber$ = this.localStore.selectByKey("insuranceNumber");
    protected readonly expirationDate$ = this.localStore.selectByKey("expirationDate");

    protected readonly isEditModeOn$ = this.localStore.selectByKey("isEditModeOn");
    protected readonly policyFormGroup = new FormGroup<PolicyNumberForm>({
        number: new FormControl("", {
            validators: [Validators.required, Validators.maxLength(MAX_INSURANCE_POLICY_LENGTH)],
            nonNullable: true,
        }),
        expirationDate: new FormControl("", { validators: Validators.required, nonNullable: true }),
    });

    protected readonly TOMORROW_DATE = TOMORROW_DATE;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    constructor(
        private readonly localStore: LocalComponentStore<InsurancePolicyEditComponentState>,
        private readonly translocoHelper: TranslationHelperService,
        private readonly transloco: TranslocoService,
        private readonly dialog: MatDialog
    ) {
        this.localStore.setState({
            insuranceNumber: undefined,
            expirationDate: undefined,
            isEditModeOn: false,
        });
    }

    protected tryDeleteInsurancePolicy(): void {
        this.dialog
            .open(ConfirmationDialogComponent, this.getDeleteConfirmationDialogConfig())
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.delete.emit());
    }

    protected savePolicy(): void {
        this.save.emit(this.policyFormGroup.getRawValue());
        this.localStore.patchState({ isEditModeOn: false });
        this.policyFormGroup.markAsPristine();
    }

    protected setFormState(isEditModeOn: boolean): void {
        const number = this.localStore.selectSnapshotByKey("insuranceNumber");
        const expirationDate = this.localStore.selectSnapshotByKey("expirationDate");

        if (!isEditModeOn) {
            this.policyFormGroup.reset();

            return;
        }

        this.policyFormGroup.patchValue({
            number: number,
            expirationDate: expirationDate,
        });
    }

    protected setEditMode(editMode: boolean): void {
        this.localStore.patchState({ isEditModeOn: editMode });
        this.setFormState(editMode);
    }

    private getDeleteConfirmationDialogConfig(): MatDialogConfig {
        return {
            data: {
                titleText: this.transloco.translate("dtmWebAppLibShared.insurancePolicyEdit.dialogTitleText"),
                confirmationText: this.transloco.translate("dtmWebAppLibShared.insurancePolicyEdit.dialogConfirmationText"),
                confirmButtonLabel: this.transloco.translate("dtmWebAppLibShared.insurancePolicyEdit.dialogConfirmationButtonLabel"),
                theme: ButtonTheme.Warn,
            },
        };
    }
}
