import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    CameraHelperService,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
} from "@dtm-frontend/shared/map/cesium";
import { AirspaceElement, AirspaceElementsInfo, ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import { GeoJSON, ItineraryEditorType, MissionPlanRoute, RouteAreaTypeId, RouteData, TimeRange } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import {
    Polygon,
    Properties,
    feature as createFeature,
    featureCollection as createFeatureCollection,
    feature,
    featureCollection,
} from "@turf/helpers";
import { Feature as GeoJSONFeature } from "@turf/helpers/dist/js/lib/geojson";
import { Observable, combineLatest, first } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import {
    MissionPlanAnalysisStatus,
    OperationalGeometryData,
    TacticalMitigationPerformanceRequirementProperty,
    TrafficMissionData,
} from "../../models/mission-plan-verification.models";
import {
    AuthorityAcceptationItem,
    MissionCategory,
    MissionContextType,
    MissionDataSimple,
    MissionPlanRemarks,
    MissionPlanSpecificPermitType,
    MissionPreviewData,
    MissionProcessingPhase,
    MissionType,
    SoraSettings,
} from "../../models/mission.models";
import { MissionUtils } from "../../utils/index";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface MissionPreviewComponentState {
    isProcessing: boolean;
    missionData: MissionPreviewData | undefined;
    route: MissionPlanRoute | undefined;
    flightPurposes: Record<string, string>;
    analysisStatus: MissionPlanAnalysisStatus | undefined;
    operationalGeometry: OperationalGeometryData | undefined;
    nearbyMissionsRouteData: RouteData<TrafficMissionData>[];
    collisionZones: AirspaceElementsInfo | undefined;
    isFlightRequirementsProcessing: boolean;
    selectedZoneId: string | undefined;
    soraSettings: SoraSettings | undefined;
    selectedOtherMissionId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-preview[missionData]",
    templateUrl: "./mission-preview.component.html",
    styleUrls: ["./mission-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionPreviewComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input({ required: true }) public set missionData(value: MissionPreviewData | undefined) {
        this.localStore.patchState({ missionData: value });
    }

    @Input({ required: true }) public set route(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ route: value });
    }

    @Input({ required: true }) public set flightPurposes(value: Record<string, string> | undefined) {
        this.localStore.patchState({ flightPurposes: value ?? {} });
    }

    @Input() public set analysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ analysisStatus: value });
    }

    @Input() public set operationalGeometry(value: OperationalGeometryData | undefined) {
        this.localStore.patchState({ operationalGeometry: value });
    }

    @Input() public set nearbyMissionsRouteData(value: RouteData<TrafficMissionData>[] | undefined) {
        this.localStore.patchState({ nearbyMissionsRouteData: value });
    }

    @Input() public set collisionZones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ collisionZones: value });
    }

    @Input() public set isFlightRequirementsProcessing(value: BooleanInput) {
        this.localStore.patchState({ isFlightRequirementsProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set soraSettings(value: SoraSettings | undefined) {
        this.localStore.patchState({ soraSettings: value });
    }

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly missionData$ = this.localStore.selectByKey("missionData").pipe(RxjsUtils.filterFalsy());
    protected readonly route$ = this.localStore.selectByKey("route");
    protected readonly isAuthorityAcceptationConfirmed$ = this.missionData$.pipe(
        map(({ remarks }) => this.getAuthorityAcceptationItemWithMessage(remarks)?.isConfirmedByUser)
    );
    protected readonly isSoraApplication$ = this.missionData$.pipe(
        map(
            ({ category, phase }) =>
                (category?.type === MissionCategory.Specific &&
                    category?.specificPermitType === MissionPlanSpecificPermitType.Individual) ||
                phase === MissionProcessingPhase.CaaPermitApplication
        )
    );
    protected readonly missionDataSimple$ = combineLatest([
        this.missionData$,
        this.localStore.selectByKey("route"),
        this.localStore.selectByKey("flightPurposes"),
    ]).pipe(map(([missionData, route, flightPurposes]) => this.prepareMissionDataSimple(missionData, route, flightPurposes)));
    protected readonly analysisStatus$ = this.localStore.selectByKey("analysisStatus");
    protected readonly isAnalysisAvailable$ = this.analysisStatus$.pipe(map((analysisStatus) => !!(analysisStatus && analysisStatus.sora)));
    protected readonly operationalGeometry$ = this.localStore.selectByKey("operationalGeometry");
    private readonly nearbyMissionsRouteData$ = this.localStore
        .selectByKey("nearbyMissionsRouteData")
        .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    protected readonly nearbyMissions$ = this.nearbyMissionsRouteData$.pipe(
        map((nearbyMissionsRouteData) => this.convertNearbyMissionsRouteDataToTrafficMissionsData(nearbyMissionsRouteData, false))
    );
    protected readonly collisionMission$ = this.nearbyMissionsRouteData$.pipe(
        map((nearbyMissionsRouteData) => this.convertNearbyMissionsRouteDataToTrafficMissionsData(nearbyMissionsRouteData, true))
    );
    protected readonly collisionZones$ = this.localStore.selectByKey("collisionZones");
    protected readonly isFlightRequirementsProcessing$ = this.localStore.selectByKey("isFlightRequirementsProcessing");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly soraSettings$ = this.localStore.selectByKey("soraSettings");
    protected readonly isTacticalMitigationPerformanceRequirementsPanelVisible$ = combineLatest([
        this.missionData$,
        this.analysisStatus$,
    ]).pipe(
        map(([missionData, analysisStatus]) => {
            const tacticalMitigationPerformanceRequirementProperty =
                analysisStatus?.sora?.result?.tacticalMitigationPerformanceRequirementProperty;

            return (
                missionData?.flightType === MissionType.BVLOS &&
                tacticalMitigationPerformanceRequirementProperty &&
                tacticalMitigationPerformanceRequirementProperty !== TacticalMitigationPerformanceRequirementProperty.VLOS
            );
        })
    );
    protected readonly initialViewBox$ = this.getInitialViewBoxObservable();
    protected readonly routeData$ = this.getRouteDataObservable();
    protected readonly missionTimeRange$ = this.getMissionTimeRangeObservable();

    protected readonly routeDrawableFeature: RouteAreaTypeId[] = ["flightArea", "waypoint"];
    protected readonly nearbyMissionsRouteDrawableFeature: RouteAreaTypeId[] = ["flightArea", "waypoint"];
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly MissionContextType = MissionContextType;
    protected readonly ItineraryEditorType = ItineraryEditorType;
    protected readonly MissionType = MissionType;
    protected readonly SceneMode = SceneMode;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;
    protected readonly MissionCategory = MissionCategory;

    constructor(
        private readonly localStore: LocalComponentStore<MissionPreviewComponentState>,
        private readonly cameraHelperService: CameraHelperService,
        viewerConfiguration: ViewerConfiguration
    ) {
        this.localStore.setState({
            isProcessing: false,
            missionData: undefined,
            route: undefined,
            flightPurposes: {},
            analysisStatus: undefined,
            operationalGeometry: undefined,
            nearbyMissionsRouteData: [],
            collisionZones: undefined,
            isFlightRequirementsProcessing: false,
            selectedZoneId: undefined,
            soraSettings: undefined,
            selectedOtherMissionId: undefined,
        });

        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE3D,
        };
    }

    protected flyToMissionRoute(): void {
        this.flyToRoute(this.localStore.selectSnapshotByKey("route"));
    }

    protected flyToOtherMissionRoute(routeId: string): void {
        const route = this.localStore
            .selectSnapshotByKey("nearbyMissionsRouteData")
            ?.find((routeData) => routeData.data?.routeId === routeId)?.route;

        this.flyToRoute(route);
    }

    protected selectOtherMission(missionId: string): void {
        this.localStore.patchState({ selectedOtherMissionId: missionId });
    }

    protected selectZone(zone: AirspaceElement | undefined): void {
        const currentSelectedZoneId = this.localStore.selectSnapshotByKey("selectedZoneId");

        if (currentSelectedZoneId === zone?.id) {
            this.flyToMissionRoute();
            this.localStore.patchState({ selectedZoneId: undefined });

            return;
        }

        this.flyToGeometry(zone?.geometry);
        this.localStore.patchState({ selectedZoneId: zone?.id });
    }

    protected flyToGeometry(geometry: GeoJSON | undefined) {
        if (!geometry) {
            return;
        }

        this.cameraHelperService.flyToGeoJSON(geometry);
    }

    protected getRoutesFromRouteData(data?: RouteData<TrafficMissionData>): MissionPlanRoute[] {
        return [data?.route, data?.nearbyMissionsData?.map(({ route }) => route)].flat().filter(FunctionUtils.isTruthy);
    }

    private getAuthorityAcceptationItemWithMessage(remarks?: MissionPlanRemarks): AuthorityAcceptationItem | undefined {
        const authorityAcceptation = remarks?.authorityAcceptation;

        if (!authorityAcceptation) {
            return;
        }

        return Object.values(authorityAcceptation).find((acceptation: AuthorityAcceptationItem | undefined) => acceptation?.comment);
    }

    private prepareMissionDataSimple(
        missionData: MissionPreviewData,
        route: MissionPlanRoute | undefined,
        flightPurposes: Record<string, string>
    ): MissionDataSimple {
        return {
            isRoutePathBased: !!route?.isPathBased,
            flightStartAtMin: missionData.flightStartAtMin,
            flightStartAtMax: missionData.flightStartAtMax,
            flightFinishAtMin: missionData.flightFinishAtMin,
            flightFinishAtMax: missionData.flightFinishAtMax,
            phase: missionData.phase,
            distance: route?.estimatedDistance,
            operatorName: missionData.operatorName,
            pilotName: missionData.pilotName,
            uavName: missionData.uavName && missionData.setupName ? `${missionData.uavName} (${missionData.setupName})` : undefined,
            uavSerialNumbers: missionData.uavSerialNumbers ?? [],
            trackersIdentifiers: missionData.trackersIdentifiers ?? [],
            category: missionData.category,
            flightPurpose: {
                nameTranslationKey: missionData.flightPurpose?.id ? flightPurposes[missionData.flightPurpose?.id] ?? "" : "",
                comment: missionData.flightPurpose?.comment ?? undefined,
            },
            additionalCrew: missionData.additionalCrew,
        };
    }

    private convertNearbyMissionsRouteDataToTrafficMissionsData(
        nearbyMissionsRouteData: RouteData<TrafficMissionData>[],
        isCollision: boolean
    ) {
        return nearbyMissionsRouteData.reduce<TrafficMissionData[]>(
            (result, item) => (isCollision === item.isCollision && item.data ? [...result, item.data] : result),
            []
        );
    }

    private getInitialViewBoxObservable() {
        return this.route$.pipe(
            RxjsUtils.filterFalsy(),
            first(),
            map((routeData) => {
                const bbox = turfBbox(this.getZoomArea(routeData));
                Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
                Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);

                // NOTE: setTimeout is needed to make sure that the camera is set after the map is initialized
                setTimeout(() => this.flyToRoute(routeData));

                return true;
            })
        );
    }

    private getRouteDataObservable() {
        return combineLatest([
            this.route$.pipe(map((route, uniqueRouteId) => ({ route, uniqueRouteId }))),
            this.nearbyMissionsRouteData$.pipe(map((nearbyMissions, uniqueNearbyRoutesId) => ({ nearbyMissions, uniqueNearbyRoutesId }))),
            this.localStore.selectByKey("selectedOtherMissionId"),
        ]).pipe(
            map(([{ route, uniqueRouteId }, { nearbyMissions, uniqueNearbyRoutesId }, selectedOtherMissionId]) =>
                route
                    ? this.transformMissionRouteToRouteData(
                          route,
                          nearbyMissions,
                          uniqueRouteId,
                          uniqueNearbyRoutesId,
                          selectedOtherMissionId
                      )
                    : undefined
            )
        );
    }

    private transformMissionRouteToRouteData(
        missionRoute: MissionPlanRoute,
        nearbyMissionsData: RouteData<TrafficMissionData>[] | undefined,
        uniqueRouteId: number,
        uniqueNearbyRoutesId: number,
        selectedOtherMissionId: string | undefined
    ): RouteData<TrafficMissionData> {
        return {
            isMain: true,
            isPathBased: missionRoute.isPathBased,
            route: missionRoute,
            nearbyMissionsData: nearbyMissionsData?.map((mission) => ({
                ...mission,
                isSelected: mission.data?.id === selectedOtherMissionId,
            })),
            uniqueNearbyRoutesId,
            uniqueRouteId,
            isOutsideDtm: missionRoute.stats?.flight.dtmNames && missionRoute.stats.flight.dtmNames.length === 0,
        };
    }

    private getMissionTimeRangeObservable(): Observable<TimeRange> {
        return combineLatest([this.isProcessing$, this.route$]).pipe(
            map(([isProcessing, route]) => {
                if (isProcessing || !route) {
                    return undefined;
                }

                return MissionUtils.getTimeRangeFromWaypointsWithSection(MissionUtils.convertRouteToWaypoints(route));
            }),
            RxjsUtils.filterFalsy()
        );
    }

    private getZoomArea(route: MissionPlanRoute) {
        return featureCollection<Polygon, Properties>(
            route.sections
                .map((section) => section.segment?.safetyArea.volume.area ?? section.flightZone?.safetyArea.volume.area)
                .filter(FunctionUtils.isTruthy)
                .map((polygon) => feature(polygon))
        );
    }

    private flyToRoute(route?: MissionPlanRoute) {
        if (!route) {
            return;
        }

        const zoomArea = createFeatureCollection(
            route.sections.reduce<GeoJSONFeature[]>((features, section) => {
                const area = section.flightZone?.safetyArea.volume.area ?? section.segment?.safetyArea.volume.area;

                if (area) {
                    features.push(createFeature(area));
                }

                return features;
            }, [])
        );

        this.cameraHelperService.flyToGeoJSON(zoomArea);
    }
}
