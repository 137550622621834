<dtm-ui-presentation-tile-with-action
    [titleTile]="'dtmAdminLibShared.operatorProfile.messages.tileTitle' | transloco"
    *ngIf="profileId$ | ngrxPush as profileId"
>
    <button actionButtonSlot class="button-secondary" type="button" (click)="writeNewMessageOpen.emit()">
        <dtm-ui-icon name="edit"></dtm-ui-icon>
        {{ "dtmAdminLibShared.operatorProfile.messages.actionButtonLabel" | transloco }}
    </button>
    <div class="main-container">
        <button class="button-tertiary" type="button" [routerLink]="['/messages', profileId]">
            {{ "dtmAdminLibShared.operatorProfile.messages.goToMessagesLink" | transloco }}
        </button>
    </div>
</dtm-ui-presentation-tile-with-action>
