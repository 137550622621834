import { Injectable } from "@angular/core";
import { ConversationCategoryCode } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { DashboardApiService } from "../services/api/dashboard-api.service";
import { DashboardDetailsError, OperatorsStats, UserConversationsStats, UsersStats } from "../services/dashboard.model";
import { DashboardActions } from "./dashboard.actions";

export interface DashboardStateModel {
    userConversationsStats: UserConversationsStats | undefined;
    operatorsStats: OperatorsStats | undefined;
    assignedCategories: ConversationCategoryCode[] | undefined;
    dashboardDetailsError: DashboardDetailsError | undefined;
    usersStats: UsersStats | undefined;
}

const defaultState: DashboardStateModel = {
    userConversationsStats: undefined,
    operatorsStats: undefined,
    dashboardDetailsError: undefined,
    assignedCategories: undefined,
    usersStats: undefined,
};

@State<DashboardStateModel>({
    name: "dashboard",
    defaults: defaultState,
})
@Injectable()
export class DashboardState {
    @Selector()
    public static userConversationsStats(state: DashboardStateModel): UserConversationsStats | undefined {
        return state.userConversationsStats;
    }

    @Selector()
    public static dashboardDetailsError(state: DashboardStateModel): DashboardDetailsError | undefined {
        return state.dashboardDetailsError;
    }

    @Selector()
    public static operatorsStats(state: DashboardStateModel): OperatorsStats | undefined {
        return state.operatorsStats;
    }

    @Selector()
    public static usersStats(state: DashboardStateModel): UsersStats | undefined {
        return state.usersStats;
    }

    @Selector()
    public static assignedCategories(state: DashboardStateModel): ConversationCategoryCode[] | undefined {
        return state.assignedCategories;
    }

    constructor(private readonly dashboardApiService: DashboardApiService) {}

    @Action(DashboardActions.GetDashboardDetails)
    public getDashboardDetails(context: StateContext<DashboardStateModel>) {
        context.patchState({ dashboardDetailsError: undefined });

        return this.dashboardApiService.getDashboardDetails().pipe(
            tap((result) => {
                context.patchState({
                    userConversationsStats: result.userConversationsStats,
                    operatorsStats: result.operatorsStats,
                    usersStats: result.usersStats,
                    assignedCategories: result.assignedCategories,
                });
            }),
            catchError((error) => {
                context.patchState({
                    dashboardDetailsError: error,
                    userConversationsStats: undefined,
                    operatorsStats: undefined,
                    usersStats: undefined,
                    assignedCategories: undefined,
                });

                return EMPTY;
            })
        );
    }
}
