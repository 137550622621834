import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AssociationOperationType, AssociationPermit } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { AssociationPermitWizardSteps } from "../../../../models/association-permit.models";

interface AssociationPermitRequestSummaryComponentState {
    isProcessing: boolean;
    isEditMode: boolean;
    permit: AssociationPermit | undefined;
}

@Component({
    selector: "dtm-admin-lib-association-permit-request-summary[permit]",
    templateUrl: "./association-permit-request-summary.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AssociationPermitRequestSummaryComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set permit(value: AssociationPermit | undefined) {
        this.localStore.patchState({ permit: value });
    }
    @Input() public set isEditMode(value: BooleanInput) {
        this.localStore.patchState({ isEditMode: coerceBooleanProperty(value) });
    }

    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly save = new EventEmitter<void>();
    @Output() public readonly stepEdit = new EventEmitter<AssociationPermitWizardSteps>();

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly permit$ = this.localStore.selectByKey("permit").pipe(RxjsUtils.filterFalsy());
    protected readonly AssociationPermitWizardSteps = AssociationPermitWizardSteps;
    protected readonly OperationType = AssociationOperationType;

    constructor(private readonly localStore: LocalComponentStore<AssociationPermitRequestSummaryComponentState>) {
        localStore.setState({ isProcessing: false, isEditMode: false, permit: undefined });
    }
}
