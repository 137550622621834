<ng-container *ngrxLet="threadList$; let threadList">
    <ng-container *ngIf="threadList?.length; else emptyListTemplate">
        <mat-card class="no-box-shadow">
            <div class="table-header">
                <p>{{ "dtmAdminLibConversation.threadsContainer.operatorsListMainHeader" | transloco }}</p>
            </div>
            <mat-list>
                <mat-list-item>
                    <span class="list-headers">
                        <span><!-- NOTE it is left empty to represent space for icon--></span>
                        <span>{{ "dtmAdminLibConversation.threadsContainer.operatorsListOwnerHeader" | transloco }}</span>
                        <span>{{ "dtmAdminLibConversation.threadsContainer.operatorsListOperatorHeader" | transloco }}</span>
                        <span>{{ "dtmAdminLibConversation.threadsContainer.operatorsListSubjectHeader" | transloco }}</span>
                        <span>{{ "dtmAdminLibConversation.threadsContainer.operatorsListDeliveryDateHeader" | transloco }}</span>
                    </span>
                    <span class="assignment-header">
                        <span>{{ "dtmAdminLibConversation.threadsContainer.operatorsListDeliveryAssignmentHeader" | transloco }}</span>
                    </span>
                </mat-list-item>
                <mat-list-item
                    *ngFor="let thread of threadList"
                    [class.unread]="!thread.isRead && !thread.isClosed"
                    [class.unassigned]="!thread.assignee && !thread.isClosed"
                >
                    <a [routerLink]="['/messages', thread.stakeholder.id]" [queryParams]="{ threadId: thread.id }" class="title-container">
                        <dtm-ui-thread-list-item-icon
                            [isAssigned]="!!thread.assignee"
                            [isRead]="thread.isRead"
                            [isClosed]="thread.isClosed"
                        ></dtm-ui-thread-list-item-icon>
                        <span>{{ thread.stakeholder.owner.firstName }} {{ thread.stakeholder.owner.lastName }}</span>
                        <span>{{ thread.stakeholder.name }}</span>
                        <span>{{ thread.name }}</span>
                        <span>{{ thread.modifiedAt | messageDateDisplay }}</span>
                    </a>
                    <ng-container *ngrxLet="processingThreadsAssignments$; let processingThreadsAssignments">
                        <dtm-admin-lib-threads-list-assignee-change
                            [storedAvatarPictures]="storedAvatarPictures$ | ngrxPush"
                            [assignees]="assignees$ | ngrxPush"
                            [thread]="thread"
                            [processingThreadIds]="processingThreadsAssignments"
                            (assigneeChange)="assigneeAssign.emit({ assignee: $event, thread: thread.id })"
                        >
                        </dtm-admin-lib-threads-list-assignee-change>
                    </ng-container>
                </mat-list-item>
            </mat-list>
        </mat-card>
    </ng-container>
</ng-container>

<ng-template #emptyListTemplate><dtm-ui-no-results [areFiltersApplied]="areFiltersApplied$ | ngrxPush"></dtm-ui-no-results></ng-template>
