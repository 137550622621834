import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, TemplateRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from "@angular/material/legacy-autocomplete";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, Observable, pipe, UnaryFunction } from "rxjs";
import { tap } from "rxjs/operators";
import { BaseOperator } from "../../models/operator.models";

interface OperatorSearchAutocompleteControlComponentState {
    options: BaseOperator[] | undefined;
    optionTemplate: TemplateRef<HTMLElement> | undefined;
}

const displayAutocompleteValueFn = (option: BaseOperator): string => option.name;

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operator-search-autocomplete-control[options]",
    templateUrl: "./operator-search-autocomplete-control.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OperatorSearchAutocompleteControlComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OperatorSearchAutocompleteControlComponent),
            multi: true,
        },
    ],
})
export class OperatorSearchAutocompleteControlComponent implements ControlValueAccessor, Validator {
    @Input() public set options(value: BaseOperator[] | undefined) {
        this.localStore.patchState({ options: value });
    }
    @Input() public set optionTemplate(value: TemplateRef<HTMLElement> | undefined) {
        this.localStore.patchState({ optionTemplate: value });
    }

    @Output() public readonly searchTextChange = new EventEmitter<string>();

    protected readonly displayAutocompleteValueFn = displayAutocompleteValueFn;
    protected readonly options$ = this.localStore.selectByKey("options");
    protected readonly optionTemplate$ = this.localStore.selectByKey("optionTemplate");

    protected readonly searchControl = new FormControl<BaseOperator | string>("", {
        nonNullable: true,
        validators: Validators.required,
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: BaseOperator | null) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<OperatorSearchAutocompleteControlComponentState>) {
        this.localStore.setState({
            options: undefined,
            optionTemplate: undefined,
        });

        this.searchControl.valueChanges
            .pipe(
                tap((value) => {
                    if (!value || typeof value === "string") {
                        this.propagateChange(null);
                    }
                }),
                this.emitDebouncedSearchText$(),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected optionSelected({ option: { value } }: MatAutocompleteSelectedEvent): void {
        const option: BaseOperator = value;

        this.propagateChange(option);
        this.searchTextChange.next(option.id);
    }

    public registerOnChange(fn: (value: BaseOperator | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: BaseOperator): void {
        if (value) {
            this.searchControl.reset(value, { emitEvent: false });
        } else {
            this.searchControl.reset();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.searchControl.valid) {
            return null;
        }

        return { invalidFind: true };
    }

    private emitDebouncedSearchText$(): UnaryFunction<Observable<string | BaseOperator>, Observable<string | BaseOperator>> {
        return pipe(
            debounceTime(DEFAULT_DEBOUNCE_TIME),
            tap((value) => {
                if (typeof value === "string") {
                    this.searchTextChange.next(value);
                }
            })
        );
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.searchControl.disable();
        } else {
            this.searchControl.enable();
        }
    }
}
