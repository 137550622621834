<dtm-ui-wizard-step-wrapper
    (next)="save.emit()"
    (back)="back.emit()"
    [title]="'dtmAdminLibPermits.associationPermitSummary.header' | transloco"
    [stepsAmount]="3"
    [stepNumber]="3"
    [isBackButtonVisible]="true"
    [nextButtonLabel]="
        ((isEditMode$ | ngrxPush)
            ? 'dtmAdminLibPermits.associationPermitSummary.updatePermitLabel'
            : 'dtmAdminLibPermits.associationPermitSummary.addPermitLabel'
        ) | transloco
    "
    dtmUiInvalidFormScrollable
    *ngrxLet="permit$ as permit"
>
    <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
        <dtm-ui-association-permit-basic-info-preview
            [permit]="permit"
            (edit)="stepEdit.emit(AssociationPermitWizardSteps.BasicInfo)"
            [canEdit]="true"
            [isCaaContactTileExpanded]="true"
            [isOperatorInfoTileExpanded]="true"
        ></dtm-ui-association-permit-basic-info-preview>
        <dtm-ui-association-permit-details-preview
            [permit]="permit"
            (edit)="stepEdit.emit(AssociationPermitWizardSteps.PermitDetails)"
            [canEdit]="true"
            [isPermitDetailsTileExpanded]="true"
        ></dtm-ui-association-permit-details-preview>
    </dtm-ui-loader-container>
</dtm-ui-wizard-step-wrapper>
