import { AbstractControl, ValidationErrors } from "@angular/forms";
import { DEFAULT_COMPANY_NUMBER_LENGTH } from "@dtm-frontend/shared/utils";

export function optionalDefaultCountryCompanyNumberValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.length !== DEFAULT_COMPANY_NUMBER_LENGTH) {
        return { minlength: true };
    }

    return null;
}
