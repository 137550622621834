<div class="dialog-header">
    <div mat-dialog-title>
        {{ "dtmAdminLibShared.operatorProfile.qualifications.addNewOperationalAuthorizationHeader" | transloco }}
    </div>
    <button type="button" class="button-icon" [matDialogClose]="null"><dtm-ui-icon name="close"></dtm-ui-icon></button>
</div>
<form [formGroup]="form" dtmUiInvalidFormScrollable (submit)="validateAndSubmit()">
    <div formArrayName="operationalAuthorizations" matDialogContent>
        <div
            *ngFor="let operationalAuthorization of operationalAuthorizationsArray.controls; index as operationalAuthorizationIndex"
            [formGroupName]="operationalAuthorizationIndex"
            class="form-group"
        >
            <dtm-ui-select-field
                [formControl]="operationalAuthorization.controls.operationScenario"
                [isClearable]="false"
                (valueChange)="handleOperationScenarioValueChange($event, operationalAuthorization, operationalAuthorizationIndex)"
            >
                <label>{{ "dtmAdminLibShared.operatorProfile.qualifications.nameLabel" | transloco }}</label>
                <dtm-ui-select-option
                    *ngFor="let availableOperationScenario of availableOperationScenarios"
                    [value]="availableOperationScenario"
                >
                    {{ availableOperationScenario.name }}
                </dtm-ui-select-option>
                <div class="field-error" *dtmUiFieldHasError="operationalAuthorization.controls.operationScenario; name: 'required'">
                    {{ "dtmAdminLibShared.operatorProfile.qualifications.requiredFieldError" | transloco }}
                </div>
                <!--NOTE: cannot use dtmUiFieldHasError as it does not refresh after changes in other control-->
                <div class="field-error" *ngIf="operationalAuthorization.controls.operationScenario.errors?.notUnique">
                    {{ "dtmAdminLibShared.operatorProfile.qualifications.operationalAuthorizationAlreadySelectedError" | transloco }}
                </div>
            </dtm-ui-select-field>
            <dtm-ui-date-field>
                <label>{{ "dtmAdminLibShared.operatorProfile.qualifications.expirationDateLabel" | transloco }}</label>
                <input
                    matInput
                    [formControl]="operationalAuthorization.controls.expirationDate"
                    [matDatepicker]="picker"
                    *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                    [placeholder]="datePickerPlaceholder"
                />
                <mat-datepicker #picker></mat-datepicker>
                <div class="field-error" *dtmUiFieldHasError="operationalAuthorization.controls.expirationDate; name: 'required'">
                    {{ "dtmAdminLibShared.operatorProfile.qualifications.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-date-field>
            <dtm-ui-serial-numbers-control
                *ngIf="operationalAuthorization.controls.uavSerialNumbers.enabled"
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="operationalAuthorization.controls.uavSerialNumbers"
                [isSingle]="false"
            ></dtm-ui-serial-numbers-control>
        </div>
        <button type="button" class="button-secondary add-button" (click)="addOperationalAuthorizationFormGroup()">
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmAdminLibShared.operatorProfile.qualifications.addAnotherOperationalAuthorizationLabel" | transloco }}
        </button>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="null">
            {{ "dtmAdminLibShared.operatorProfile.qualifications.cancelLabel" | transloco }}
        </button>
        <button type="submit" class="button-primary">
            {{ "dtmAdminLibShared.operatorProfile.qualifications.addLabel" | transloco }}
        </button>
    </div>
</form>
