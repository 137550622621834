<dtm-admin-lib-stats-tile [header]="'dtmAdminLibDashboard.usersStats.header' | transloco">
    <mat-list listSlot *ngIf="usersStats$ | ngrxPush as usersStats">
        <dtm-admin-lib-stats-list-item
            redirectLink="/utm-users"
            [redirectQueryParams]="{ statuses: UtmUserStatus.Waiting, page: 0 }"
            iconsClassName="user-follow"
            [statsName]="'dtmAdminLibDashboard.usersStats.approvalInProgressCountLabel' | transloco"
            [statsCount]="usersStats.approvalInProgressCount"
        ></dtm-admin-lib-stats-list-item>
        <dtm-admin-lib-stats-list-item
            redirectLink="/utm-users"
            [redirectQueryParams]="{ isWaitingCompletionRequest: SINGLE_SELECT_VALUES.False, page: 0 }"
            iconsClassName="time"
            [statsName]="'dtmAdminLibDashboard.usersStats.waitingWithoutCompletionRequestsLabel' | transloco"
            [statsCount]="usersStats.waitingWithoutCompletionRequestsCount"
        ></dtm-admin-lib-stats-list-item>
        <dtm-admin-lib-stats-list-item
            redirectLink="/utm-users"
            [redirectQueryParams]="{ isWaitingCompletionRequest: SINGLE_SELECT_VALUES.True, page: 0 }"
            iconsClassName="user-settings"
            [statsName]="'dtmAdminLibDashboard.usersStats.waitingCompletionRequestsCountLabel' | transloco"
            [statsCount]="usersStats.waitingCompletionRequestsCount"
        ></dtm-admin-lib-stats-list-item>
        <dtm-admin-lib-stats-list-item
            redirectLink="/utm-users"
            [redirectQueryParams]="{ isWaitingIdentityDocument: SINGLE_SELECT_VALUES.True, page: 0 }"
            iconsClassName="file-list"
            [statsName]="'dtmAdminLibDashboard.usersStats.waitingIdentityDocumentsCountLabel' | transloco"
            [statsCount]="usersStats.waitingIdentityDocumentsCount"
        ></dtm-admin-lib-stats-list-item>
    </mat-list>

    <ng-container bottomRedirectSlot>
        <button class="button-tertiary redirect-button" type="button" [routerLink]="['/utm-users']">
            {{ "dtmAdminLibDashboard.usersStats.redirectToUserList" | transloco }}
        </button>
    </ng-container>
</dtm-admin-lib-stats-tile>
