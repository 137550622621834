import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { OperationScenarioStatementStatus, OperationScenarioStatementsFilters } from "../../services/operation-scenario-statements.models";

interface OperationScenarioStatementFiltersComponentState {
    filtersCount: number;
}

interface OperationsScenarioFiltersFormGroup {
    operatorNumber: FormControl<string>;
    paymentStatus: FormControl<OperationScenarioStatementStatus[] | null>;
    signStatus: FormControl<OperationScenarioStatementStatus[] | null>;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "paymentStatus",
        filterLabel: "dtmAdminLibOperationScenario.operationsScenarioFilters.paymentStatusLabel",
        filterValueLabel: "dtmAdminLibOperationScenario.statementsList.paymentStatus",
    },
    {
        key: "signStatus",
        filterLabel: "dtmAdminLibOperationScenario.operationsScenarioFilters.signatureStatusLabel",
        filterValueLabel: "dtmAdminLibOperationScenario.statementsList.signatureStatus",
    },
    {
        key: "operatorNumber",
        filterLabel: "dtmAdminLibOperationScenario.operationsScenarioFilters.operatorNumberLabel",
        type: FilterType.TextEllipsis,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operation-scenario-statements-filters[initialFilters]",
    templateUrl: "./operation-scenario-statements-filters.component.html",
    styleUrls: ["./operation-scenario-statements-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperationScenarioStatementsFiltersComponent {
    @Input() public set initialFilters(value: OperationScenarioStatementsFilters | undefined) {
        this.operatorNumberControl.setValue(value?.operatorNumber ?? "");
        this.signStatusControl.setValue(value?.signStatus ?? []);
        this.paymentStatusControl.setValue(value?.paymentStatus ?? []);
    }

    @Output() public filtersChange = new EventEmitter<OperationScenarioStatementsFilters>();

    protected readonly OperationScenarioStatementStatus = OperationScenarioStatementStatus;
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    protected readonly operatorNumberControl = new FormControl<string>("", { nonNullable: true });
    protected readonly paymentStatusControl = new FormControl<OperationScenarioStatementStatus[] | null>(null);
    protected readonly signStatusControl = new FormControl<OperationScenarioStatementStatus[] | null>(null);
    protected readonly filtersFormGroup = new FormGroup<OperationsScenarioFiltersFormGroup>({
        operatorNumber: this.operatorNumberControl,
        paymentStatus: this.paymentStatusControl,
        signStatus: this.signStatusControl,
    });

    constructor(private readonly localStore: LocalComponentStore<OperationScenarioStatementFiltersComponentState>) {
        localStore.setState({ filtersCount: 0 });
        this.watchFormChanges();
    }

    private watchFormChanges() {
        this.filtersFormGroup.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((value) => {
                this.updateFiltersCount();
                this.filtersChange.emit(value);
            });
    }

    private updateFiltersCount() {
        if (this.filtersFormGroup.valid) {
            const filtersCount = Object.values(this.filtersFormGroup.value).flat().filter(Boolean).length;

            this.localStore.patchState({ filtersCount });
        }
    }

    protected clearFilters() {
        this.filtersFormGroup.reset();
    }
}
