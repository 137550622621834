<div
    *ngrxLet="{
        areActionsAvailable: areActionsAvailable$,
    } as vm"
    class="card"
>
    <div class="card-title">
        {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.titleTile" | transloco }}
    </div>
    <div class="main-container">
        <dtm-ui-carousel>
            <ng-container *ngFor="let element of elements$ | ngrxPush; trackBy: trackBy">
                <div class="slide" *dtmUiCarouselSlides>
                    <div *ngFor="let attorneyPower of element.cards" class="attorney-power-container">
                        <div class="row">
                            <div [ngSwitch]="attorneyPower.status">
                                <span *ngSwitchCase="AttorneyPowerStatus.Approved" class="chip success">
                                    <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                                    {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.statusApprovedLabel" | transloco }}
                                </span>
                                <span *ngSwitchCase="AttorneyPowerStatus.PendingApproval" class="chip warning">
                                    <dtm-ui-icon name="rest-time"></dtm-ui-icon>
                                    {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.statusPendingApprovalLabel" | transloco }}
                                </span>
                            </div>
                            <button *ngIf="vm.areActionsAvailable" class="button-icon" type="button" [matMenuTriggerFor]="menu">
                                <dtm-ui-icon name="more"></dtm-ui-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button
                                    *ngIf="attorneyPower.status === AttorneyPowerStatus.Approved; else approveTemplate"
                                    (click)="update.emit(attorneyPower)"
                                    type="button"
                                    mat-menu-item
                                >
                                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                                    {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.editPermissionsButtonLabel" | transloco }}
                                </button>
                                <ng-template #approveTemplate>
                                    <button type="button" mat-menu-item (click)="update.emit(attorneyPower)">
                                        <dtm-ui-icon name="check"></dtm-ui-icon>
                                        {{ "dtmAdminLibShared.operatorProfile.attorneyPowers.approveButtonLabel" | transloco }}
                                    </button>
                                </ng-template>
                            </mat-menu>
                        </div>
                        <dtm-ui-label-value
                            [label]="'dtmAdminLibShared.operatorProfile.attorneyPowers.nameLabel' | transloco"
                            [value]="attorneyPower.grantee.firstName + ' ' + attorneyPower.grantee.lastName"
                        >
                        </dtm-ui-label-value>
                        <ng-container [ngSwitch]="attorneyPower.type">
                            <dtm-ui-label-value
                                *ngSwitchCase="AttorneyPowerType.ConfirmatoryDocument"
                                [label]="'dtmAdminLibShared.operatorProfile.attorneyPowers.attachmentLabel' | transloco"
                            >
                                <div class="attachment">
                                    <dtm-ui-icon name="attachment"></dtm-ui-icon>
                                    <span class="document-file-name">{{ attorneyPower.documentFileName | ellipsifyText : 50 }}</span>
                                    <button
                                        *ngIf="vm.areActionsAvailable"
                                        (click)="download.emit(attorneyPower)"
                                        type="button"
                                        class="button-icon"
                                    >
                                        <dtm-ui-icon name="download"></dtm-ui-icon>
                                    </button>
                                </div>
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                *ngSwitchCase="AttorneyPowerType.NationalCourtNumber"
                                [label]="'dtmAdminLibShared.operatorProfile.attorneyPowers.nationalCourtNumberLabel' | transloco"
                                [value]="attorneyPower.nationalCourtRegister"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                *ngSwitchCase="AttorneyPowerType.Owner"
                                [label]="'dtmAdminLibShared.operatorProfile.attorneyPowers.ownerLabel' | transloco"
                                [value]="'dtmAdminLibShared.operatorProfile.attorneyPowers.ownerValue' | transloco"
                            >
                            </dtm-ui-label-value>
                        </ng-container>
                        <dtm-ui-label-value
                            *ngIf="attorneyPower.permissions && attorneyPower.permissions.length"
                            [label]="'dtmAdminLibShared.operatorProfile.attorneyPowers.permissionsLabel' | transloco"
                        >
                            <ul class="value-container">
                                <li *ngFor="let permission of attorneyPower.permissions">
                                    {{
                                        "dtmAdminLibShared.operatorProfile.attorneyPowers.permissionsValue"
                                            | transloco : { permission: permission }
                                    }}
                                </li>
                            </ul>
                        </dtm-ui-label-value>
                    </div>
                </div>
            </ng-container>
        </dtm-ui-carousel>
    </div>
</div>
