<dtm-ui-wizard-step-wrapper
    [title]="'dtmAdminLibPermits.crossBorderAndSpecificPermitPermitFlightWizardHeader.uavDetailsStepTitle' | transloco"
    [stepsAmount]="3"
    [stepNumber]="2"
    [nextButtonLabel]="'dtmAdminLibPermits.uavDetails.nextButtonLabel' | transloco"
    [isBackButtonVisible]="true"
    (next)="validateFormAndGoToNextStep()"
    (back)="back.emit()"
    dtmUiInvalidFormScrollable
>
    <div class="form-wrapper" [formGroup]="uavDetailsForm">
        <dtm-ui-select-field
            formControlName="manufacturer"
            [placeholder]="'dtmAdminLibPermits.uavDetails.manufacturerPlaceholder' | transloco"
        >
            <label>{{ "dtmAdminLibPermits.uavDetails.manufacturerLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let manufacturer of manufacturerList$ | ngrxPush" [value]="manufacturer">{{
                manufacturer
            }}</dtm-ui-select-option>
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.manufacturer; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
        </dtm-ui-select-field>
        <dtm-ui-select-field formControlName="uavModel" [placeholder]="'dtmAdminLibPermits.uavDetails.modelPlaceholder' | transloco">
            <label>{{ "dtmAdminLibPermits.uavDetails.modelLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let uavModel of filteredUavModels$ | ngrxPush" [value]="uavModel">
                {{ uavModel.name }}
            </dtm-ui-select-option>
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.uavModel; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
        </dtm-ui-select-field>
        <dtm-ui-input-field [isClearable]="false">
            <label>{{ "dtmAdminLibPermits.uavDetails.uavTypeLabel" | transloco }}</label>
            <input matInput formControlName="uavType" type="text" />
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>{{ "dtmAdminLibPermits.uavDetails.operatedNumberLabel" | transloco }}</label>
            <div class="field-suffix">{{ "dtmAdminLibPermits.uavDetails.operatedNumberSuffix" | transloco }}</div>
            <input matInput formControlName="operatedNumber" type="number" />
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.operatedNumber; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.operatedNumber; name: 'min'">
                {{
                    "dtmAdminLibPermits.uavDetails.minValueErrorHint"
                        | transloco
                            : {
                                  min: MIN_FIELD_NUMBER,
                                  unit: "dtmAdminLibPermits.uavDetails.operatedNumberSuffix" | transloco
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.operatedNumber; name: 'max'">
                {{
                    "dtmAdminLibPermits.uavDetails.maxValueErrorHint"
                        | transloco
                            : {
                                  max: MAX_OPERATED_NUMBER,
                                  unit: "dtmAdminLibPermits.uavDetails.operatedNumberSuffix" | transloco
                              }
                }}
            </div>
        </dtm-ui-input-field>
        <dtm-admin-lib-confirm-tile formControlName="isDroneSwarm">
            <label>{{ "dtmAdminLibPermits.uavDetails.isDroneSwarmLabel" | transloco }}</label>
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.isDroneSwarm; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
        </dtm-admin-lib-confirm-tile>
        <dtm-ui-textarea-field [maxLength]="MAX_SERIAL_NUMBER_LENGTH">
            <label>{{ "dtmAdminLibPermits.uavDetails.serialNumbersLabel" | transloco }}</label>
            <textarea matInput formControlName="serialNumbers"></textarea>
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.serialNumbers; name: ['required', 'pattern']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.serialNumbers; name: 'maxlength'; error as error">
                {{
                    "dtmAdminLibPermits.operationRestrictionsForm.valueLongerThanMaxLengthErrorHint"
                        | transloco : { max: error.requiredLength }
                }}
            </div>
        </dtm-ui-textarea-field>
        <dtm-ui-input-field>
            <label>{{ "dtmAdminLibPermits.uavDetails.maxUavWidthLabel" | transloco }}</label>
            <div class="field-suffix">{{ "dtmAdminLibPermits.uavDetails.maxUavWidthSuffix" | transloco }}</div>
            <input matInput formControlName="maxUavWidth" type="number" />
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.maxUavWidth; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.maxUavWidth; name: 'min'">
                {{
                    "dtmAdminLibPermits.uavDetails.minValueErrorHint"
                        | transloco
                            : {
                                  min: MIN_FIELD_NUMBER,
                                  unit: "dtmAdminLibPermits.uavDetails.maxUavWidthSuffix" | transloco
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.maxUavWidth; name: 'max'">
                {{
                    "dtmAdminLibPermits.uavDetails.maxValueErrorHint"
                        | transloco
                            : {
                                  max: MAX_UAV_WIDTH,
                                  unit: "dtmAdminLibPermits.uavDetails.maxUavWidthSuffix" | transloco
                              }
                }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>{{ "dtmAdminLibPermits.uavDetails.takeOffWeightLabel" | transloco }}</label>
            <div class="field-suffix">{{ "dtmAdminLibPermits.uavDetails.takeOffWeightSuffix" | transloco }}</div>
            <input matInput formControlName="takeOffWeight" type="number" />
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.takeOffWeight; name: ['required']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.takeOffWeight; name: 'min'">
                {{
                    "dtmAdminLibPermits.uavDetails.minValueErrorHint"
                        | transloco
                            : {
                                  min: MIN_TAKE_OFF_WEIGHT,
                                  unit: "dtmAdminLibPermits.uavDetails.takeOffWeightSuffix" | transloco
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.takeOffWeight; name: 'max'">
                {{
                    "dtmAdminLibPermits.uavDetails.maxValueErrorHint"
                        | transloco
                            : {
                                  max: MAX_TAKE_OFF_WEIGHT,
                                  unit: "dtmAdminLibPermits.uavDetails.takeOffWeightSuffix" | transloco
                              }
                }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>{{ "dtmAdminLibPermits.uavDetails.maxFlightSpeedLabel" | transloco }}</label>
            <div class="field-suffix">{{ "dtmAdminLibPermits.uavDetails.maxFlightSpeedSuffix" | transloco }}</div>
            <input matInput formControlName="maxFlightSpeed" type="number" />
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.maxFlightSpeed; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.maxFlightSpeed; name: 'min'">
                {{
                    "dtmAdminLibPermits.uavDetails.minValueErrorHint"
                        | transloco
                            : {
                                  min: MIN_FLIGHT_SPEED,
                                  unit: "dtmAdminLibPermits.uavDetails.maxFlightSpeedSuffix" | transloco
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.maxFlightSpeed; name: 'max'">
                {{
                    "dtmAdminLibPermits.uavDetails.maxValueErrorHint"
                        | transloco
                            : {
                                  max: MAX_FLIGHT_SPEED,
                                  unit: "dtmAdminLibPermits.uavDetails.maxFlightSpeedSuffix" | transloco
                              }
                }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-textarea-field [maxLength]="MAX_DVR_REPORT_LENGTH">
            <label>{{ "dtmAdminLibPermits.uavDetails.dvrReportLabel" | transloco }}</label>
            <textarea formControlName="designVerificationReport" matInput></textarea>
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.designVerificationReport; name: ['pattern']"
                [ngTemplateOutlet]="onlyWhitespacesTemplate"
            ></div>
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.designVerificationReport; name: 'maxlength'; error as error"
            >
                {{ "dtmAdminLibPermits.uavDetails.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-textarea-field>
        <dtm-ui-input-field>
            <label>{{ "dtmAdminLibPermits.uavDetails.certificateOfAirworthinessLabel" | transloco }}</label>
            <input formControlName="certificateOfAirworthiness" matInput type="text" />
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.certificateOfAirworthiness; name: 'pattern'"
                [ngTemplateOutlet]="onlyWhitespacesTemplate"
            ></div>
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.certificateOfAirworthiness; name: 'maxlength'; error as error"
            >
                {{
                    "dtmAdminLibPermits.operationRestrictionsForm.valueLongerThanMaxLengthErrorHint"
                        | transloco : { max: error.requiredLength }
                }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>{{ "dtmAdminLibPermits.uavDetails.noiseCertificate" | transloco }}</label>
            <input formControlName="noiseCertificate" matInput type="text" />
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.noiseCertificate; name: 'pattern'"
                [ngTemplateOutlet]="onlyWhitespacesTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.noiseCertificate; name: 'maxlength'; error as error">
                {{
                    "dtmAdminLibPermits.operationRestrictionsForm.valueLongerThanMaxLengthErrorHint"
                        | transloco : { max: error.requiredLength }
                }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-select-field
            formControlName="riskMitigation"
            [placeholder]="'dtmAdminLibPermits.uavDetails.chooseRiskMitigationMeasurePlaceholder' | transloco"
        >
            <label>{{ "dtmAdminLibPermits.uavDetails.riskMitigationMeasureLabel" | transloco }}</label>
            <dtm-ui-select-option [value]="risk.value" *ngFor="let risk of GroundRiskMitigationType | keyvalue : keepOrder">
                {{ "dtmAdminLibPermits.uavDetails.groundRiskMitigationMeasureValueLabel" | transloco : { value: risk.value } }}
            </dtm-ui-select-option>
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.riskMitigation; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
        </dtm-ui-select-field>

        <h4 class="section-title comments">{{ "dtmAdminLibPermits.uavDetails.commentsSection" | transloco }}</h4>

        <dtm-ui-textarea-field [maxLength]="MAX_COMMENTS_LENGTH">
            <label>{{ "dtmAdminLibPermits.uavDetails.commentsLabel" | transloco }}</label>
            <textarea formControlName="comments" matInput></textarea>
            <div
                class="field-error"
                *dtmUiFieldHasError="uavDetailsForm.controls.comments; name: 'pattern'"
                [ngTemplateOutlet]="onlyWhitespacesTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="uavDetailsForm.controls.comments; name: 'maxlength'; error as error">
                {{ "dtmAdminLibPermits.uavDetails.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-textarea-field>

        <div class="operation-description">
            <h4 class="section-title">{{ "dtmAdminLibPermits.uavDetails.operationPermit" | transloco }}</h4>
            <span class="operator-name">{{ selectedOperator$ | ngrxPush }}</span>
            {{ "dtmAdminLibPermits.uavDetails.performOperationDescription" | transloco }}
        </div>
    </div>
</dtm-ui-wizard-step-wrapper>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibPermits.container.requiredField" | transloco }}
</ng-template>
<ng-template #onlyWhitespacesTemplate>
    {{ "dtmAdminLibPermits.uavDetails.whitespacesErrorHint" | transloco }}
</ng-template>
