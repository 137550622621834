import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UtmUserBasicData } from "../../services/utm-users.models";

interface UtmUserBasicDataTileComponentState {
    basicData: UtmUserBasicData | undefined;
}

@Component({
    selector: "dtm-admin-lib-utm-user-basic-data-tile[basicData]",
    templateUrl: "./utm-user-basic-data-tile.component.html",
    styleUrls: ["./utm-user-basic-data-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UtmUserBasicDataTileComponent {
    @Input() public set basicData(value: UtmUserBasicData | undefined) {
        this.localStore.patchState({ basicData: value });
    }

    @Output() public readonly basicDataEdit = new EventEmitter<void>();

    protected readonly basicData$ = this.localStore.selectByKey("basicData");

    constructor(private readonly localStore: LocalComponentStore<UtmUserBasicDataTileComponentState>) {
        localStore.setState({
            basicData: undefined,
        });
    }
}
