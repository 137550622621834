import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Notification, NotificationDisplay, SharedNotificationDisplayProps } from "@dtm-frontend/shared/notifications";
import { IconName } from "@dtm-frontend/shared/ui";
import { SYSTEM_TRANSLATION_SCOPE } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, LocalComponentStore, StringUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { OPERATOR_CONTEXT_PARAM } from "../../operator-context/models/operator-context.models";
import {
    CompetenciesManagementNotificationType,
    MissionPlanningNotificationTargetType,
    WebAppCompetenciesManagementNotification,
    WebAppCompetenciesManagementNotificationRequestPayload,
    WebAppConversationNewMessageNotificationRequestPayload,
    WebAppConversationNotification,
    WebAppConversationNotificationType,
    WebAppMembershipInvitationNotification,
    WebAppMembershipInvitationNotificationType,
    WebAppMembershipNotification,
    WebAppMembershipNotificationRequestPayload,
    WebAppMembershipNotificationType,
    WebAppMissionPlanningNotification,
    WebAppPermissionActivatedNotificationRequestPayload,
    WebAppPermissionExpiredNotificationRequestPayload,
    WebAppPermissionGrantedNotificationRequestPayload,
    WebAppPermissionNotification,
    WebAppPermissionNotificationType,
    WebAppPermissionRemovedNotificationRequestPayload,
    WebAppPermissionSoonToExpiredNotificationRequestPayload,
    WebAppPermissionSuspendedNotificationRequestPayload,
    WebAppPermissionWithdrawnNotificationRequestPayload,
    WebAppStatementPaymentStatusChangedNotificationPayload,
    WebAppUserNotification,
    WebAppUserNotificationType,
} from "../services/web-app-notifications.models";
import { WebAppNotificationsService } from "../services/web-app-notifications.service";

interface NotificationsComponentState {
    notificationList: NotificationDisplay[];
}

const MAX_THREAD_NAME_LENGTH = 50;
const PILOT_PROFILE_URL = "pilot-profile";
const USER_PROFILE_URL = "user";

@Component({
    selector: "dtm-web-app-lib-notifications[notificationsList]",
    templateUrl: "./notifications.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
    @Input()
    public set notificationsList(value: Notification[] | undefined) {
        if (value) {
            this.localStore.patchState({ notificationList: this.formatNotificationList(value) });
        } else {
            this.localStore.patchState({ notificationList: [] });
        }
    }

    protected readonly notificationsList$ = this.localStore.selectByKey("notificationList");

    constructor(
        private readonly localStore: LocalComponentStore<NotificationsComponentState>,
        private readonly translocoService: TranslocoService,
        private readonly webAppNotificationsService: WebAppNotificationsService
    ) {
        localStore.setState({ notificationList: [] });
    }

    private formatNotificationList(notificationsToFormat: Notification[]): NotificationDisplay[] {
        const conversationEvents: WebAppConversationNotification[] = notificationsToFormat.filter((notification: Notification) =>
            this.webAppNotificationsService.getConversationNotificationTypes().some((type) => notification.type === type)
        ) as WebAppConversationNotification[];

        const membershipInvitationEvents: WebAppMembershipInvitationNotification[] = notificationsToFormat.filter(
            (notification: Notification) =>
                this.webAppNotificationsService.getMembershipInvitationNotificationTypes().some((type) => notification.type === type)
        ) as WebAppMembershipInvitationNotification[];

        const membershipEvents: WebAppMembershipNotification[] = notificationsToFormat.filter((notification: Notification) =>
            this.webAppNotificationsService.getMembershipNotificationTypes().some((type) => notification.type === type)
        ) as WebAppMembershipNotification[];

        const permissionsEvents: WebAppPermissionNotification[] = notificationsToFormat.filter((notification: Notification) =>
            this.webAppNotificationsService.getPermissionsNotificationTypes().some((type) => notification.type === type)
        ) as WebAppPermissionNotification[];

        const competenciesManagementEvents: WebAppCompetenciesManagementNotification[] = notificationsToFormat.filter(
            (notification: Notification) =>
                this.webAppNotificationsService.getCompetenciesManagementNotificationTypes().some((type) => notification.type === type)
        ) as WebAppCompetenciesManagementNotification[];

        const userEvents: WebAppUserNotification[] = notificationsToFormat.filter((notification: Notification) =>
            this.webAppNotificationsService.getUserNotificationTypes().some((type) => notification.type === type)
        ) as WebAppUserNotification[];

        const missionPlanningEvents: WebAppMissionPlanningNotification[] = notificationsToFormat.filter((notification: Notification) =>
            this.webAppNotificationsService.getMissionPlanningNotificationTypes().some((type) => notification.type === type)
        ) as WebAppMissionPlanningNotification[];

        return [
            ...this.getConversationEventsList(conversationEvents),
            ...this.getMembershipInvitationEventsList(membershipInvitationEvents),
            ...this.getMembershipEventsList(membershipEvents),
            ...this.getPermissionsEventsList(permissionsEvents),
            ...this.getCompetenciesManagementEventsList(competenciesManagementEvents),
            ...this.getUserEventsList(userEvents),
            ...this.getMissionPlanningEventsList(missionPlanningEvents),
        ].sort((left, right) => new Date(right.date).getTime() - new Date(left.date).getTime());
    }

    private getConversationEventsList(conversationEvents: WebAppConversationNotification[]): NotificationDisplay[] {
        return conversationEvents
            .map<NotificationDisplay>((notification: WebAppConversationNotification) => {
                const threadName = StringUtils.ellipsifyText(notification.payload.threadName, MAX_THREAD_NAME_LENGTH);
                const sharedProps = this.getSharedProps(notification, "mail");

                switch (notification.type) {
                    case WebAppConversationNotificationType.NewMessageToStakeholderEvent:
                        return this.getNotificationDisplayForNewMessageReceivedEvent(sharedProps, notification, threadName);
                    case WebAppConversationNotificationType.ThreadClosedEvent:
                        return this.getNotificationDisplayForThreadClosedEvent(sharedProps, notification, threadName);
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getNotificationDisplayForNewMessageReceivedEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppConversationNotification,
        threadName: string
    ): NotificationDisplay {
        const newMessageReceivedPayload = notification.payload as WebAppConversationNewMessageNotificationRequestPayload;

        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.newMessageLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.newMessageText", {
                userName: `${newMessageReceivedPayload.sender.firstName} ${newMessageReceivedPayload.sender.lastName}`,
                threadName,
            }),
            redirectUrl: this.getConversationRedirectUrl(notification.payload.threadId, notification.payload.stakeholderId),
        };
    }

    private getNotificationDisplayForThreadClosedEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppConversationNotification,
        threadName: string
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.closedThreadLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.closedThreadText", {
                threadName,
            }),
            redirectUrl: this.getConversationRedirectUrl(notification.payload.threadId, notification.payload.stakeholderId),
        };
    }

    private getConversationRedirectUrl(threadId: string, operatorId: string): string | undefined {
        if (threadId && operatorId) {
            return `operator-conversations?${OPERATOR_CONTEXT_PARAM}=${operatorId}&threadId=${threadId}`;
        }

        return "operator-conversations";
    }

    private getMembershipInvitationEventsList(
        membershipNotificationEvents: WebAppMembershipInvitationNotification[]
    ): NotificationDisplay[] {
        return membershipNotificationEvents
            .map<NotificationDisplay>((notification: WebAppMembershipInvitationNotification) => {
                const sharedProps = this.getSharedProps(notification, "mail");

                switch (notification.type) {
                    case WebAppMembershipInvitationNotificationType.UserInvitedEvent:
                        return this.getUserInvitedEvent(sharedProps, notification);
                    case WebAppMembershipInvitationNotificationType.UserAcceptedInvitationEvent:
                        return this.getUserAcceptedInvitationEvent(sharedProps, notification);
                    case WebAppMembershipInvitationNotificationType.UserRejectedInvitationEvent:
                        return this.getUserRejectedInvitationEvent(sharedProps, notification);
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getMembershipEventsList(membershipNotificationEvents: WebAppMembershipNotification[]): NotificationDisplay[] {
        return membershipNotificationEvents
            .map<NotificationDisplay>((notification: WebAppMembershipNotification) => {
                const sharedProps = this.getSharedProps(notification, "mail");

                switch (notification.type) {
                    case WebAppMembershipNotificationType.MembershipDeactivatedEvent:
                        return this.getMembershipDeactivatedEvent(sharedProps, notification.payload);
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getPermissionsEventsList(permissionNotificationEvents: WebAppPermissionNotification[]): NotificationDisplay[] {
        return permissionNotificationEvents
            .map<NotificationDisplay>((notification: WebAppPermissionNotification) => {
                const sharedProps = this.getSharedProps(notification, "mail");

                switch (notification.type) {
                    case WebAppPermissionNotificationType.OperationalAuthorizationsGrantedEvent:
                        return this.getGrantedPermissionEvent(
                            sharedProps,
                            notification.payload as WebAppPermissionGrantedNotificationRequestPayload
                        );
                    case WebAppPermissionNotificationType.OperationalAuthorizationWithdrawnEvent:
                        return this.getWithdrawnPermissionEvent(
                            sharedProps,
                            notification.payload as WebAppPermissionWithdrawnNotificationRequestPayload
                        );
                    case WebAppPermissionNotificationType.OperationalAuthorizationRemovedEvent:
                        return this.getRemovedPermissionEvent(
                            sharedProps,
                            notification.payload as WebAppPermissionRemovedNotificationRequestPayload
                        );
                    case WebAppPermissionNotificationType.OperationalAuthorizationSuspendedEvent:
                        return this.getSuspendedPermissionEvent(
                            sharedProps,
                            notification.payload as WebAppPermissionSuspendedNotificationRequestPayload
                        );
                    case WebAppPermissionNotificationType.OperationalAuthorizationActivatedEvent:
                        return this.getActivatedPermissionEvent(
                            sharedProps,
                            notification.payload as WebAppPermissionActivatedNotificationRequestPayload
                        );
                    case WebAppPermissionNotificationType.OperationalAuthorizationExpiredEvent:
                        return this.getExpiredPermissionEvent(
                            sharedProps,
                            notification.payload as WebAppPermissionExpiredNotificationRequestPayload
                        );
                    case WebAppPermissionNotificationType.OperationalAuthorizationSoonToExpireEvent:
                        return this.getSoonToExpirePermissionEvent(
                            sharedProps,
                            notification.payload as WebAppPermissionSoonToExpiredNotificationRequestPayload
                        );
                    case WebAppPermissionNotificationType.OperationScenariosStatementPaymentStatusChangedEvent:
                        return this.getStatementPaymentStatusChangedEvent(
                            sharedProps,
                            notification.payload as WebAppStatementPaymentStatusChangedNotificationPayload
                        );
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getGrantedPermissionEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppPermissionGrantedNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionGrantedEventLabel"),
            text: StringUtils.ellipsifyText(
                this.translocoService.translate("dtmWebAppLibShared.notifications.permissionGrantedEventText", {
                    grantedScenarios: notification.grantedScenarios,
                    operatorName: notification.operator.name,
                })
            ),
            redirectUrl: `operator-permissions?${OPERATOR_CONTEXT_PARAM}=${notification.operator.id}`,
        };
    }

    private getWithdrawnPermissionEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppPermissionWithdrawnNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionWithdrawnEventLabel"),
            text: StringUtils.ellipsifyText(
                this.translocoService.translate("dtmWebAppLibShared.notifications.permissionWithdrawnEventText", {
                    operatorName: notification.operator.name,
                })
            ),
            redirectUrl: `operator-permissions?${OPERATOR_CONTEXT_PARAM}=${notification.operator.id}`,
        };
    }

    private getRemovedPermissionEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppPermissionRemovedNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionRemovedEventLabel"),
            text: StringUtils.ellipsifyText(
                this.translocoService.translate("dtmWebAppLibShared.notifications.permissionRemovedEventText", {
                    operatorName: notification.operator.name,
                })
            ),
            redirectUrl: `operator-permissions?${OPERATOR_CONTEXT_PARAM}=${notification.operator.id}`,
        };
    }

    private getSuspendedPermissionEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppPermissionSuspendedNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionSuspendedEventLabel"),
            text: StringUtils.ellipsifyText(
                this.translocoService.translate("dtmWebAppLibShared.notifications.permissionSuspendedEventText", {
                    operatorName: notification.operator.name,
                })
            ),
            redirectUrl: `operator-permissions?${OPERATOR_CONTEXT_PARAM}=${notification.operator.id}`,
        };
    }

    private getExpiredPermissionEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppPermissionExpiredNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionExpiredEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionExpiredEventText", {
                operationScenarioName: notification.operationScenarioName,
                operatorName: notification.operatorName,
            }),
            redirectUrl: `operator-permissions?${OPERATOR_CONTEXT_PARAM}=${notification.operatorId}`,
        };
    }

    private getActivatedPermissionEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppPermissionActivatedNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionActivatedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionActivatedEventText", {
                grantedScenarios: notification.grantedScenarios,
                operatorName: notification.operator.name,
            }),
            redirectUrl: `operator-permissions?${OPERATOR_CONTEXT_PARAM}=${notification.operator.id}`,
        };
    }

    private getSoonToExpirePermissionEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppPermissionSoonToExpiredNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionSoonToExpireEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.permissionSoonToExpireEventText", {
                operationScenarioName: notification.operationScenarioName,
                operatorName: notification.operatorName,
            }),
            redirectUrl: `operator-permissions?${OPERATOR_CONTEXT_PARAM}=${notification.operatorId}`,
        };
    }

    private getStatementPaymentStatusChangedEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppStatementPaymentStatusChangedNotificationPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.statementPaymentStatusChangedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.statementPaymentStatusChangedEventText", {
                paymentStatus: notification.paymentStatus,
            }),
            redirectUrl: `statements?${OPERATOR_CONTEXT_PARAM}=${notification.operatorId}&statementId=${notification.operationScenariosStatementId}`,
        };
    }

    private getUserInvitedEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppMembershipInvitationNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.membershipInvitationLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.membershipInvitationText", {
                operatorName: `${notification.payload.sender.operatorName}`,
            }),
            redirectUrl: `membership/invitations/${notification.payload.invitationId}`,
        };
    }

    private getUserAcceptedInvitationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppMembershipInvitationNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,

            title: this.translocoService.translate("dtmWebAppLibShared.notifications.membershipInvitationAcceptanceLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.membershipInvitationAcceptanceText", {
                userName: `${notification.payload.recipient.firstName} ${notification.payload.recipient.lastName}`,
            }),
            redirectUrl: `membership/users-list?page=0&${OPERATOR_CONTEXT_PARAM}=${notification.payload.sender.operatorId}`,
        };
    }

    private getUserRejectedInvitationEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppMembershipInvitationNotification
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.membershipInvitationRejectionLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.membershipInvitationRejectionText", {
                userName: `${notification.payload.recipient.firstName} ${notification.payload.recipient.lastName}`,
            }),
            redirectUrl: `membership/users-list?page=0&${OPERATOR_CONTEXT_PARAM}=${notification.payload.sender.operatorId}`,
        };
    }

    private getMembershipDeactivatedEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppMembershipNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.membershipDeactivatedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.membershipDeactivatedEventText", {
                operatorName: notification.membershipOperatorName,
            }),
        };
    }

    private getMissionPlanningEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppMissionPlanningNotification
    ): NotificationDisplay {
        const isPlanTarget = notification.payload.target.type === MissionPlanningNotificationTargetType.Plan;
        const redirectUrl = (isPlanTarget ? "plan/list/" : "tactical/mission/") + notification.payload.target.id;

        const translationKey = isPlanTarget
            ? "dtmWebAppLibShared.notifications.missionPlanningEventLabel"
            : "dtmWebAppLibShared.notifications.tacticalMissionEventLabel";

        return {
            ...sharedProps,
            title: this.translocoService.translate(translationKey),
            text: this.translocoService.translate(
                `${SYSTEM_TRANSLATION_SCOPE}.${notification.payload.translationId}`,
                notification.payload.args
            ),
            redirectUrl,
        };
    }

    private getCompetenciesManagementEventsList(
        competenciesManagementNotificationEvents: WebAppCompetenciesManagementNotification[]
    ): NotificationDisplay[] {
        return competenciesManagementNotificationEvents
            .map<NotificationDisplay>((notification: WebAppCompetenciesManagementNotification) => {
                const sharedProps = this.getSharedProps(notification, "mail");

                switch (notification.type) {
                    case CompetenciesManagementNotificationType.CompetencyGrantedEvent:
                        return this.getGrantedCompetencyEvent(
                            sharedProps,
                            notification.payload as WebAppCompetenciesManagementNotificationRequestPayload
                        );
                    case CompetenciesManagementNotificationType.CompetencyActivatedEvent:
                        return this.getActivatedCompetencyEvent(
                            sharedProps,
                            notification.payload as WebAppCompetenciesManagementNotificationRequestPayload
                        );
                    case CompetenciesManagementNotificationType.CompetencyRemovedEvent:
                        return this.getRemovedCompetencyEvent(
                            sharedProps,
                            notification.payload as WebAppCompetenciesManagementNotificationRequestPayload
                        );
                    case CompetenciesManagementNotificationType.CompetencyWithdrawnEvent:
                        return this.getWithdrawnCompetencyEvent(
                            sharedProps,
                            notification.payload as WebAppCompetenciesManagementNotificationRequestPayload
                        );
                    case CompetenciesManagementNotificationType.CompetencySuspendedEvent:
                        return this.getSuspendedCompetencyEvent(
                            sharedProps,
                            notification.payload as WebAppCompetenciesManagementNotificationRequestPayload
                        );
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getMissionPlanningEventsList(missionPlanningNotificationEvents: WebAppMissionPlanningNotification[]): NotificationDisplay[] {
        return missionPlanningNotificationEvents
            .map<NotificationDisplay>((notification: WebAppMissionPlanningNotification) => {
                const sharedProps = this.getSharedProps(notification, "error-warning");

                return this.getMissionPlanningEvent(sharedProps, notification);
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getGrantedCompetencyEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppCompetenciesManagementNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.competencyGrantedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.competencyGrantedEventText", {
                pilotName: `${notification.pilot.firstName} ${notification.pilot.lastName}`,
            }),
            redirectUrl: PILOT_PROFILE_URL,
        };
    }

    private getActivatedCompetencyEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppCompetenciesManagementNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.competencyActivatedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.competencyActivatedEventText", {
                pilotName: `${notification.pilot.firstName} ${notification.pilot.lastName}`,
            }),
            redirectUrl: PILOT_PROFILE_URL,
        };
    }

    private getRemovedCompetencyEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppCompetenciesManagementNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.competencyRemovedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.competencyRemovedEventText", {
                pilotName: `${notification.pilot.firstName} ${notification.pilot.lastName}`,
            }),
            redirectUrl: PILOT_PROFILE_URL,
        };
    }

    private getWithdrawnCompetencyEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppCompetenciesManagementNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.competencyWithdrawnEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.competencyWithdrawnEventText", {
                pilotName: `${notification.pilot.firstName} ${notification.pilot.lastName}`,
            }),
            redirectUrl: PILOT_PROFILE_URL,
        };
    }

    private getSuspendedCompetencyEvent(
        sharedProps: SharedNotificationDisplayProps,
        notification: WebAppCompetenciesManagementNotificationRequestPayload
    ): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.competencySuspendedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.competencySuspendedEventText", {
                pilotName: `${notification.pilot.firstName} ${notification.pilot.lastName}`,
            }),
            redirectUrl: PILOT_PROFILE_URL,
        };
    }

    private getUserEventsList(userEvents: WebAppUserNotification[]): NotificationDisplay[] {
        return userEvents
            .map<NotificationDisplay>((notification: WebAppUserNotification) => {
                const sharedProps = this.getSharedProps(notification, "mail");

                switch (notification.type) {
                    case WebAppUserNotificationType.LegalGuardianUpdatedEvent:
                        return this.getLegalGuardianUpdatedEvent(sharedProps);
                    case WebAppUserNotificationType.UtmUserDataChangedEvent:
                        return this.getUtmUserDataChangedEvent(sharedProps);
                    case WebAppUserNotificationType.IdentityDocumentAcceptedEvent:
                        return this.getIdentityDocumentAcceptedEvent(sharedProps);
                    case WebAppUserNotificationType.IdentityDocumentRejectedEvent:
                        return this.getIdentityDocumentRejectedEvent(sharedProps);
                }
            })
            .filter(FunctionUtils.isTruthy);
    }

    private getLegalGuardianUpdatedEvent(sharedProps: SharedNotificationDisplayProps): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.legalGuardianUpdatedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.legalGuardianUpdatedEventText"),
            redirectUrl: USER_PROFILE_URL,
        };
    }
    private getUtmUserDataChangedEvent(sharedProps: SharedNotificationDisplayProps): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.utmUserDataChangedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.utmUserDataChangedEventText"),
            redirectUrl: USER_PROFILE_URL,
        };
    }
    private getIdentityDocumentAcceptedEvent(sharedProps: SharedNotificationDisplayProps): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.identityDocumentAcceptedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.identityDocumentAcceptedEventText"),
            redirectUrl: USER_PROFILE_URL,
        };
    }
    private getIdentityDocumentRejectedEvent(sharedProps: SharedNotificationDisplayProps): NotificationDisplay {
        return {
            ...sharedProps,
            title: this.translocoService.translate("dtmWebAppLibShared.notifications.identityDocumentRejectedEventLabel"),
            text: this.translocoService.translate("dtmWebAppLibShared.notifications.identityDocumentRejectedEventText"),
            redirectUrl: USER_PROFILE_URL,
        };
    }

    private getSharedProps(
        notification:
            | WebAppConversationNotification
            | WebAppMembershipInvitationNotification
            | WebAppMembershipNotification
            | WebAppPermissionNotification
            | WebAppCompetenciesManagementNotification
            | WebAppUserNotification
            | WebAppMissionPlanningNotification,
        icon: IconName
    ): SharedNotificationDisplayProps {
        return { icon, date: notification.createdAt, id: notification.id, isRead: notification.read };
    }
}
