import { Assignee, ConversationCategoryCode, MessageRecipient } from "@dtm-frontend/shared/ui";
import { OperatorDetails } from "../../shared";
import { NULL_VALUE } from "../../shared/models/shared.models";

export enum ConversationErrorType {
    NotAuthorized = "NotAuthorized",
    Unknown = "Unknown",
}

export interface AddNewMessage {
    content: string;
    subject: string;
    recipient: MessageRecipient;
    attachmentIds: string[] | undefined;
    threadId: string;
}

export interface ConversationAssignee {
    firstName: string;
    lastName: string;
    avatarUrl: string;
}

export interface ConversationCapabilities {
    categories: ConversationCategoryCode[];
    assignedCategories: ConversationCategoryCode[];
    assignees: Map<string, ConversationAssignee>;
}

export interface ConversationCapabilitiesError {
    type: ConversationCapabilitiesErrorType;
}

export enum ConversationCapabilitiesErrorType {
    Unknown = "Unknown",
}

export interface ThreadAssignmentError {
    type: ThreadAssignmentErrorType;
}

export enum ThreadAssignmentErrorType {
    Unknown = "Unknown",
}

export interface ThreadsError {
    type: ThreadsErrorType;
}

export enum ThreadsErrorType {
    Unknown = "Unknown",
}

export interface Thread {
    id: string;
    createdAt: string;
    modifiedAt: string;
    isRead: boolean;
    isClosed: boolean;
    name: string;
    hasAttachments: boolean;
    assignee: { userId: string; firstName: string; lastName: string } | null;
    category: ConversationCategoryCode;
    stakeholder: MessageRecipient;
}

export interface ThreadsWithPages {
    content: Thread[];
    pages: {
        totalElements: number;
        size: number;
        number: number;
        empty: boolean;
    };
}

export interface NewThreadAssignment {
    assignee: Assignee | null;
    thread: string;
}

export interface OperatorCapabilities {
    categories: ConversationCategoryCode[];
    operator: OperatorDetails;
    assignees: Map<string, ConversationAssignee>;
}

export interface ConversationThreadsFilterParams {
    page?: number;
    pageSize?: number;
    categories?: string | undefined;
    searchByText?: string | undefined;
    assignment?: string | undefined;
    read?: string | undefined;
    closed?: string | undefined;
    stakeholderId?: string | undefined;
}

export enum ConversationFilterFormKeys {
    Categories = "categories",
    SearchByText = "searchByText",
    Assignment = "assignment",
    Read = "read",
    Closed = "closed",
}

export interface ConversationThreadFilterValue {
    [ConversationFilterFormKeys.Categories]?: string[];
    [ConversationFilterFormKeys.SearchByText]?: string;
    [ConversationFilterFormKeys.Assignment]?: string | typeof NULL_VALUE;
    [ConversationFilterFormKeys.Read]?: string | typeof NULL_VALUE;
    [ConversationFilterFormKeys.Closed]?: string | typeof NULL_VALUE;
}

export interface NewThread {
    content: string;
    attachmentIdsList?: string[];
    category: ConversationCategoryCode;
    subject: string;
    recipient: MessageRecipient;
}
