<div class="grid">
    <mat-card *ngrxLet="activeTabIndex$ as activeTabIndex">
        <dtm-admin-lib-centers-header
            [activeTabIndex]="activeTabIndex"
            (tabIndexUpdate)="updateTabIndex($event)"
            (addCenterOpen)="openAddCenter(activeTabIndex)"
        >
            <ng-container examinationList>
                <dtm-admin-lib-centers-filters
                    [initialFilters]="examinationCentersFilters$ | ngrxPush"
                    (filtersChange)="navigateByParams($event, CentersTabType.ExaminationEntities)"
                ></dtm-admin-lib-centers-filters>

                <dtm-admin-lib-centers-list
                    [centersList]="examinationCentersList$ | ngrxPush"
                    [pagination]="examinationCentersPages$ | ngrxPush"
                    [centersListError]="examinationCentersListError$ | ngrxPush"
                    [isCentersProcessing]="(isExaminationCenterProcessing$ | ngrxPush) || (areCapabilitiesProcessing$ | ngrxPush)"
                    [activeTabIndex]="activeTabIndex"
                    (pageChange)="centersPageChange($event, CentersFiltersTabKeys.ExaminationCentersFilters)"
                    (centersListRefresh)="examinationCentersListRefresh()"
                    (preview)="openPreviewExaminationCenter($event)"
                    (edit)="openAddCenter(activeTabIndex, $event)"
                    (delete)="openDeleteExaminationCenter($event)"
                ></dtm-admin-lib-centers-list>
            </ng-container>

            <ng-container trainingList>
                <dtm-admin-lib-centers-filters
                    [initialFilters]="trainingCentersFilters$ | ngrxPush"
                    (filtersChange)="navigateByParams($event, CentersTabType.TrainingOperators)"
                ></dtm-admin-lib-centers-filters>

                <dtm-admin-lib-centers-list
                    [centersList]="trainingCentersList$ | ngrxPush"
                    [pagination]="trainingCentersPages$ | ngrxPush"
                    [centersListError]="trainingCentersListError$ | ngrxPush"
                    [isCentersProcessing]="(isTrainingCenterProcessing$ | ngrxPush) || (areCapabilitiesProcessing$ | ngrxPush)"
                    [activeTabIndex]="activeTabIndex"
                    (pageChange)="centersPageChange($event, CentersFiltersTabKeys.TrainingCentersFilters)"
                    (centersListRefresh)="trainingCentersListRefresh()"
                    (preview)="openPreviewTrainingCenter($event)"
                    (edit)="openAddCenter(activeTabIndex, $event)"
                    (delete)="openDeleteTrainingCenter($event)"
                ></dtm-admin-lib-centers-list>
            </ng-container>
        </dtm-admin-lib-centers-header>
    </mat-card>
</div>
