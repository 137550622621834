import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { OperatorStatus } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { OperatorDetails, OperatorStatusChange } from "../../../models/operator.models";

interface OperatorStatusComponentState {
    profile: OperatorDetails | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operator-status[profile]",
    templateUrl: "./operator-status.component.html",
    styleUrls: ["./operator-status.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorStatusComponent {
    @Input() public set profile(value: OperatorDetails | undefined) {
        this.localStore.patchState({ profile: value });
    }

    @Output() public statusChange = new EventEmitter<OperatorStatusChange>();

    public readonly profile$ = this.localStore.selectByKey("profile").pipe(RxjsUtils.filterFalsy());
    public readonly OperatorStatus = OperatorStatus;

    constructor(@Inject(LocalComponentStore) private readonly localStore: LocalComponentStore<OperatorStatusComponentState>) {}

    public changeOperatorStatus(profile: OperatorDetails, newStatus: OperatorStatus) {
        this.statusChange.emit({ newStatus: newStatus, operator: profile });
    }

    public keepOperatorStatusOrder(): number {
        return 0;
    }
}
