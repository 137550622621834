import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LegalGuardian } from "../../../../shared";

interface LegalGuardianTileComponentState {
    legalGuardian: LegalGuardian | undefined;
    isEditButtonVisible: boolean;
    isActionMenuVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-legal-guardian-tile[legalGuardian]",
    templateUrl: "./legal-guardian-tile.component.html",
    styleUrls: ["./legal-guardian-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LegalGuardianTileComponent {
    @Input() public set legalGuardian(value: LegalGuardian | undefined) {
        this.localStore.patchState({ legalGuardian: value ?? undefined });
    }
    @Input() public set isEditButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isEditButtonVisible: coerceBooleanProperty(value) });
    }

    @Input() public set isActionMenuVisible(value: BooleanInput) {
        this.localStore.patchState({ isActionMenuVisible: coerceBooleanProperty(value) });
    }

    @Output() public readonly legalGuardianDetailsEdit = new EventEmitter<void>();
    @Output() public readonly legalGuardianDelete = new EventEmitter<void>();
    @Output() public readonly legalGuardianSignedDocumentDownload = new EventEmitter<void>();

    public readonly legalGuardian$ = this.localStore.selectByKey("legalGuardian");
    public readonly isEditButtonVisible$ = this.localStore.selectByKey("isEditButtonVisible");
    public readonly isActionMenuVisible$ = this.localStore.selectByKey("isActionMenuVisible");

    constructor(
        private readonly localStore: LocalComponentStore<LegalGuardianTileComponentState>,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService
    ) {
        localStore.setState({ legalGuardian: undefined, isEditButtonVisible: true, isActionMenuVisible: false });
    }

    protected openDocumentRejectDialog() {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate("dtmAdminLibShared.operatorProfile.legalGuardian.deleteLegalGuardianDialogTitleText"),
                confirmButtonLabel: this.transloco.translate("dtmAdminLibShared.operatorProfile.legalGuardian.deleteConfirmLabel"),
                theme: ButtonTheme.Warn,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.legalGuardianDelete.emit());
    }
}
