<div class="grid grid-maxsize-8 grid-maxsize-md-12" *ngIf="!(areErrors$ | ngrxPush); else errorTemplate">
    <dtm-ui-lib-wizard-content
        [wizardId]="SPECIFIC_PERMIT_FLIGHT_WIZARD_ID"
        *ngrxLet="{ isProcessing: isProcessing$, existingPermitDetails: existingPermitDetails$, uavModels: uavModels$ } as vm"
    >
        <dtm-ui-lib-wizard-step-content [stepId]="SpecificPermitFlightWizardSteps.BasicInfo">
            <dtm-admin-lib-specific-permit-basic-data-step
                [caaUsers]="caaUsers$ | ngrxPush"
                [existingPermitDetails]="vm.existingPermitDetails"
                [dtmLocations]="dtmLocations$ | ngrxPush"
                [competencies]="competencies$ | ngrxPush"
                [operators]="operators$ | ngrxPush"
                [isProcessing]="vm.isProcessing"
                [areUavsAvailable]="!!vm.uavModels?.length"
                (searchTextChanged)="findOperatorByText($event)"
                (operatorSelect)="assignOperator($event)"
                (basicDataFormSave)="storeBasicData($event)"
                (kmlFilePreview)="previewKmlFile($event)"
                (back)="goBackToList()"
            ></dtm-admin-lib-specific-permit-basic-data-step>
        </dtm-ui-lib-wizard-step-content>
        <dtm-ui-lib-wizard-step-content [stepId]="SpecificPermitFlightWizardSteps.UavDetails">
            <dtm-admin-lib-permit-uav-details-step
                [existingPermitDetails]="vm.existingPermitDetails"
                [uavModels]="vm.uavModels"
                [selectedOperator]="selectedOperator$ | ngrxPush"
                (uavDetailsFormSave)="storeUavDetails($event)"
                (back)="setActiveStep(SpecificPermitFlightWizardSteps.BasicInfo)"
            ></dtm-admin-lib-permit-uav-details-step>
        </dtm-ui-lib-wizard-step-content>
        <dtm-ui-lib-wizard-step-content [stepId]="SpecificPermitFlightWizardSteps.Summary">
            <dtm-admin-lib-specific-permit-summary-step
                [isUpdateMode]="!!vm.existingPermitDetails"
                [permit]="permit$ | ngrxPush"
                [isProcessing]="vm.isProcessing"
                (editStep)="setActiveStep($event)"
                (back)="setActiveStep(SpecificPermitFlightWizardSteps.UavDetails)"
                (addPermit)="addOrUpdatePermit()"
                (kmlFilePreview)="previewKmlFile($event)"
            ></dtm-admin-lib-specific-permit-summary-step>
        </dtm-ui-lib-wizard-step-content>
    </dtm-ui-lib-wizard-content>
</div>

<ng-template #errorTemplate>
    <dtm-ui-error
        (reload)="retryFetchData()"
        [mode]="ErrorMode.Secondary"
        [errorMessage]="'dtmAdminLibPermits.container.cannotGetCapabilitiesOrOperatorError' | transloco"
    ></dtm-ui-error>
</ng-template>
