<div class="dialog-header">
    <div mat-dialog-title>{{ data.header }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>

<dtm-ui-loader-container [isShown]="data.isCenterProcessing$ | ngrxPush">
    <form [formGroup]="examinationCenterForm" mat-dialog-content dtmUiInvalidFormScrollable>
        <div class="section">
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibCenters.centerFormDialog.centerNameLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="nameControl" />
                <div class="field-error" *dtmUiFieldHasError="nameControl; name: 'maxlength'; error as error">
                    {{ "dtmAdminLibCenters.centerFormDialog.nameTooLongError" | transloco : { max: error.requiredLength } }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="nameControl; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-input-field>

            <dtm-ui-input-field *ngIf="!data.center">
                <label>{{ "dtmAdminLibCenters.centerFormDialog.identificationNumberLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="identificationNumberControl" />
                <div class="field-error" *dtmUiFieldHasError="identificationNumberControl; name: 'maxlength'; error as error">
                    {{ "dtmAdminLibCenters.centerFormDialog.identificationNumberTooLongError" | transloco : { max: error.requiredLength } }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="identificationNumberControl; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-input-field>

            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibCenters.centerFormDialog.administrativeDecisionLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="administrativeDecisionNumberControl" />
                <div class="field-error" *dtmUiFieldHasError="administrativeDecisionNumberControl; name: 'maxlength'; error as error">
                    {{
                        "dtmAdminLibCenters.centerFormDialog.administrativeDecisionTooLongError" | transloco : { max: error.requiredLength }
                    }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="administrativeDecisionNumberControl; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-input-field>

            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibCenters.centerFormDialog.emailLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="emailControl" />
                <div class="field-error" *dtmUiFieldHasError="emailControl; name: 'email'">
                    {{ "dtmAdminLibCenters.centerFormDialog.emailError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="emailControl; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-input-field>

            <dtm-ui-phone-number-field [formControl]="phoneNumberControl">
                <div
                    class="field-error"
                    *dtmUiFieldHasError="phoneNumberControl; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumber'">
                    {{ "dtmAdminLibCenters.centerFormDialog.invalidPhoneNumberError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumberType'">
                    {{ "dtmAdminLibCenters.centerFormDialog.invalidNumberTypeError" | transloco }}
                </div>
            </dtm-ui-phone-number-field>

            <div class="address-form">
                <dtm-ui-country-select-field [formControl]="countryControl">
                    <label>
                        {{ "dtmAdminLibCenters.centerFormDialog.countryLabel" | transloco }}
                    </label>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="countryControl; name: ['required', 'requiredTouched']"
                        [ngTemplateOutlet]="requiredErrorTemplate"
                    ></div>
                </dtm-ui-country-select-field>

                <dtm-ui-address-form
                    class="basic-address"
                    [formControl]="basicAddressControl"
                    dtmUiMarkValueAccessorControlsAsTouched
                    [isSystemDefaultCountrySelected]="countryControl.value === DEFAULT_COUNTRY_CODE"
                >
                </dtm-ui-address-form>
            </div>

            <dtm-ui-select-field [multiple]="true" [formControl]="examCodesControl">
                <label>{{
                    "dtmAdminLibCenters.centerFormDialog.authorizationScopeLabel"
                        | transloco : { value: CentersTabType.ExaminationEntities }
                }}</label>
                <dtm-ui-select-option *ngFor="let competency of data.capabilities" [value]="competency">
                    {{ competency | displayExamCode }}
                </dtm-ui-select-option>

                <div
                    class="field-error"
                    *dtmUiFieldHasError="examCodesControl; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-select-field>

            <dtm-admin-lib-authorization-scope-chips-list
                *ngIf="examCodesControl.value?.length"
                [chipsList]="examCodesControl.value"
                (chipRemove)="removeExamCode($event)"
            >
            </dtm-admin-lib-authorization-scope-chips-list>

            <dtm-ui-date-field>
                <label>{{ "dtmAdminLibCenters.centerFormDialog.validUntilLabel" | transloco }}</label>
                <input
                    matInput
                    *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                    [formControl]="validUntilControl"
                    [matDatepicker]="picker"
                    [placeholder]="datePickerPlaceholder"
                />
                <mat-datepicker #picker></mat-datepicker>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="validUntilControl; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-date-field>
        </div>
    </form>

    <div mat-dialog-actions>
        <button type="button" class="button-primary action-button" (click)="save()">{{ data.confirmLabel }}</button>
    </div>
</dtm-ui-loader-container>

<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibCenters.centerFormDialog.requiredFieldError" | transloco }}
</ng-template>
