<div class="dialog-header">
    <div mat-dialog-title>{{ "dtmAdminLibExamTrainingResults.header.provideResults" | transloco }}</div>
    <button type="button" class="button-icon" (click)="cancel()">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>

<div mat-dialog-content>
    <dtm-ui-files-upload-field
        [allowedTypes]="ALLOWED_FILE_EXTENSION"
        [maxFileSize]="MAX_FILE_SIZE_BYTES"
        [formControl]="fileControl"
        [isMultiple]="false"
        [isDownloadAllButtonVisible]="false"
    >
        <label>{{ "dtmAdminLibExamTrainingResults.dialog.uploadFieldLabel" | transloco }}</label>
        <div class="field-hint">
            {{ "dtmAdminLibExamTrainingResults.dialog.attachFileHint" | transloco : { value: MAX_FILE_SIZE_BYTES | formatFileSize } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="fileControl; name: ['required', 'requiredTouched']">
            {{ "dtmAdminLibExamTrainingResults.dialog.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="fileControl; name: 'maxlength'">
            {{ "dtmAdminLibExamTrainingResults.dialog.uploadMultipleFilesError" | transloco }}
        </div>
    </dtm-ui-files-upload-field>

    <mat-dialog-actions align="end">
        <button type="button" class="button-secondary" (click)="cancel()">
            {{ "dtmAdminLibExamTrainingResults.dialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="save()">
            {{ "dtmAdminLibExamTrainingResults.dialog.saveButtonLabel" | transloco }}
        </button>
    </mat-dialog-actions>
</div>
