import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { saveAs } from "file-saver";
import { EMPTY } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { OperationScenarioStatementConfirmationDocumentsApiService } from "../services/operation-scenario-statement-confirmation-documents-api.service";
import { OperationScenarioStatementsApiService } from "../services/operation-scenario-statements-api.service";
import {
    OperationScenarioStatement,
    OperationScenarioStatementErrorType,
    OperationScenarioStatementsError,
} from "../services/operation-scenario-statements.models";
import { OperationScenarioStatementsActions } from "./operation-scenario-statements.actions";

export interface OperationScenarioStatementsStateModel {
    operationScenarioStatementsList: OperationScenarioStatement[] | undefined;
    areOperationScenarioStatementsBeingProcessed: boolean | undefined;
    operationScenarioStatementsListError: OperationScenarioStatementsError | undefined;
    operationScenarioStatementAdministrativeFeeDocumentError: OperationScenarioStatementsError | undefined;
    signatureConfirmationDownloadError: OperationScenarioStatementsError | undefined;
    signatureConfirmationFile: Blob | undefined;
    operationScenarioStatementsListPagination: Page | undefined;
}

const defaultState: OperationScenarioStatementsStateModel = {
    operationScenarioStatementsList: undefined,
    areOperationScenarioStatementsBeingProcessed: false,
    operationScenarioStatementsListError: undefined,
    operationScenarioStatementAdministrativeFeeDocumentError: undefined,
    signatureConfirmationDownloadError: undefined,
    signatureConfirmationFile: undefined,
    operationScenarioStatementsListPagination: undefined,
};

@State<OperationScenarioStatementsStateModel>({
    name: "operationScenarioStatements",
    defaults: defaultState,
})
@Injectable()
export class OperationScenarioStatementsState {
    constructor(
        private readonly operationScenarioStatementsApiService: OperationScenarioStatementsApiService,
        private readonly administrativeFeeInOperationScenarioStatementApiService: OperationScenarioStatementConfirmationDocumentsApiService
    ) {
        if (operationScenarioStatementsApiService === undefined || administrativeFeeInOperationScenarioStatementApiService === undefined) {
            throw new Error("Initialize OperationScenarioStatementsModule with .forRoot()");
        }
    }

    @Selector()
    public static operationScenarioStatementsPages(state: OperationScenarioStatementsStateModel): Page | undefined {
        return state.operationScenarioStatementsListPagination;
    }
    @Selector()
    public static operationScenarioStatementsList(state: OperationScenarioStatementsStateModel): OperationScenarioStatement[] | undefined {
        return state.operationScenarioStatementsList;
    }

    @Selector()
    public static areOperationScenarioStatementsProcessing(state: OperationScenarioStatementsStateModel): boolean | undefined {
        return state.areOperationScenarioStatementsBeingProcessed;
    }

    @Selector()
    public static operationScenarioStatementsError(
        state: OperationScenarioStatementsStateModel
    ): OperationScenarioStatementsError | undefined {
        return state.operationScenarioStatementsListError;
    }

    @Selector()
    public static signatureConfirmationDownloadError(
        state: OperationScenarioStatementsStateModel
    ): OperationScenarioStatementsError | undefined {
        return state.signatureConfirmationDownloadError;
    }

    @Selector()
    public static signatureConfirmationFile(state: OperationScenarioStatementsStateModel): Blob | undefined {
        return state.signatureConfirmationFile;
    }

    @Selector()
    public static operationScenarioStatementAdministrativeFeeDocumentError(
        state: OperationScenarioStatementsStateModel
    ): OperationScenarioStatementsError | undefined {
        return state.operationScenarioStatementAdministrativeFeeDocumentError;
    }

    @Action(OperationScenarioStatementsActions.GetStatementsList, { cancelUncompleted: true })
    public getStatementsList(
        context: StateContext<OperationScenarioStatementsStateModel>,
        action: OperationScenarioStatementsActions.GetStatementsList
    ) {
        context.patchState({
            areOperationScenarioStatementsBeingProcessed: true,
        });

        return this.operationScenarioStatementsApiService.getStatementsList(action.params).pipe(
            tap((result) =>
                context.patchState({
                    operationScenarioStatementsList: result.content,
                    operationScenarioStatementsListError: undefined,
                    operationScenarioStatementsListPagination: result.pages,
                })
            ),
            catchError((error) => {
                context.patchState({ operationScenarioStatementsListError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ areOperationScenarioStatementsBeingProcessed: false }))
        );
    }

    @Action(OperationScenarioStatementsActions.GetOperationScenarioStatementAdministrativeFeeDocument)
    public getOperationScenarioStatementAdministrativeFeeDocument(
        context: StateContext<OperationScenarioStatementsStateModel>,
        action: OperationScenarioStatementsActions.GetOperationScenarioStatementAdministrativeFeeDocument
    ) {
        context.patchState({
            operationScenarioStatementAdministrativeFeeDocumentError: undefined,
            areOperationScenarioStatementsBeingProcessed: true,
        });

        return this.administrativeFeeInOperationScenarioStatementApiService.getAdministrativeFeeFile(action.feeDocumentId).pipe(
            tap((administrativeFeeDocument) => saveAs(administrativeFeeDocument, action.fileName)),
            catchError(() => {
                context.patchState({
                    operationScenarioStatementAdministrativeFeeDocumentError: {
                        type: OperationScenarioStatementErrorType.CannotGetOperationScenarioStatementAdministrativeFeeDocument,
                    },
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ areOperationScenarioStatementsBeingProcessed: false }))
        );
    }

    @Action(OperationScenarioStatementsActions.GetSignatureConfirmationFile)
    public getSignatureConfirmationFile(
        context: StateContext<OperationScenarioStatementsStateModel>,
        action: OperationScenarioStatementsActions.GetSignatureConfirmationFile
    ) {
        context.patchState({
            signatureConfirmationDownloadError: undefined,
            signatureConfirmationFile: undefined,
        });

        return this.administrativeFeeInOperationScenarioStatementApiService.getSignatureConfirmation(action.params).pipe(
            tap((confirmationFile) => context.patchState({ signatureConfirmationFile: confirmationFile })),
            catchError(() => {
                context.patchState({
                    signatureConfirmationDownloadError: {
                        type: OperationScenarioStatementErrorType.Unknown,
                    },
                });

                return EMPTY;
            })
        );
    }
}
