<h5>
    {{ "dtmAdminLibPermits.operatorContactPerson.header" | transloco }}
</h5>

<form [formGroup]="operatorContactPersonForm">
    <div class="form-wrapper">
        <div class="row">
            <dtm-ui-input-field>
                <label>
                    {{ "dtmAdminLibPermits.operatorContactPerson.firstNameLabel" | transloco }}
                </label>
                <input matInput formControlName="firstName" />
                <div class="field-error" *dtmUiFieldHasError="operatorContactPersonForm.controls.firstName; name: ['required', 'pattern']">
                    {{ "dtmAdminLibPermits.operatorContactPerson.requiredFieldError" | transloco }}
                </div>

                <div
                    class="field-error"
                    *dtmUiFieldHasError="operatorContactPersonForm.controls.firstName; name: 'maxlength'; error as error"
                >
                    {{ "dtmAdminLibPermits.operatorContactPerson.maxLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>
                    {{ "dtmAdminLibPermits.operatorContactPerson.lastNameLabel" | transloco }}
                </label>
                <input matInput formControlName="lastName" />
                <div class="field-error" *dtmUiFieldHasError="operatorContactPersonForm.controls.lastName; name: ['required', 'pattern']">
                    {{ "dtmAdminLibPermits.operatorContactPerson.requiredFieldError" | transloco }}
                </div>

                <div
                    class="field-error"
                    *dtmUiFieldHasError="operatorContactPersonForm.controls.lastName; name: 'maxlength'; error as error"
                >
                    {{ "dtmAdminLibPermits.operatorContactPerson.maxLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
        </div>
        <div [class.row]="operatorContactPersonForm.controls.position.enabled">
            <dtm-ui-input-field *ngIf="operatorContactPersonForm.controls.position.enabled">
                <label>
                    {{ "dtmAdminLibPermits.operatorContactPerson.positionLabel" | transloco }}
                </label>
                <input matInput formControlName="position" />
                <div class="field-error" *dtmUiFieldHasError="operatorContactPersonForm.controls.position; name: ['required', 'pattern']">
                    {{ "dtmAdminLibPermits.operatorContactPerson.requiredFieldError" | transloco }}
                </div>

                <div
                    class="field-error"
                    *dtmUiFieldHasError="operatorContactPersonForm.controls.position; name: 'maxlength'; error as error"
                >
                    {{ "dtmAdminLibPermits.operatorContactPerson.maxLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-phone-number-field formControlName="phoneNumber">
                <div
                    class="field-error"
                    *dtmUiFieldHasError="operatorContactPersonForm.controls.phoneNumber; name: ['required', 'pattern']"
                >
                    {{ "dtmAdminLibPermits.operatorContactPerson.requiredFieldError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operatorContactPersonForm.controls.phoneNumber; name: 'invalidNumber'">
                    {{ "dtmAdminLibPermits.operatorContactPerson.invalidPhoneNumberErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operatorContactPersonForm.controls.phoneNumber; name: 'invalidNumberType'">
                    {{ "dtmAdminLibPermits.operatorContactPerson.invalidNumberTypeErrorHint" | transloco }}
                </div>
            </dtm-ui-phone-number-field>
        </div>
        <dtm-ui-input-field>
            <label>
                {{ "dtmAdminLibPermits.operatorContactPerson.emailLabel" | transloco }}
            </label>
            <input matInput formControlName="email" />
            <div class="field-error" *dtmUiFieldHasError="operatorContactPersonForm.controls.email; name: ['required', 'pattern']">
                {{ "dtmAdminLibPermits.operatorContactPerson.requiredFieldError" | transloco }}
            </div>

            <div class="field-error" *dtmUiFieldHasError="operatorContactPersonForm.controls.email; name: 'email'">
                {{ "dtmAdminLibPermits.operatorContactPerson.invalidEmailError" | transloco }}
            </div>
        </dtm-ui-input-field>
    </div>
</form>
