import { Injectable } from "@angular/core";
import { AuthActions } from "@dtm-frontend/shared/auth";
import { PhoneNumber, UIActions, UserContactError } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { GlobalCapabilitiesActions } from "../../shared/global-capabilities/store/global-capabilities.actions";
import { UserProfileApiService } from "../services/user-profile.api.service";
import { AvailableAndAssignedConversationCategories, ConversationCategory, User, UserProfileError } from "../services/user-profile.models";
import { UserProfileActions } from "./user-profile.actions";

export interface UserProfileStateModel {
    user: User | undefined;
    userId: string | undefined;
    userProfileError: UserProfileError | undefined;
    profileCapabilitiesError: UserProfileError | undefined;
    isProcessing: boolean;
    profileAvatar: string | undefined;
    conversationCategories: ConversationCategory[] | undefined;
    assignedConversationCategories: ConversationCategory[] | undefined;
    emailAddressSaveError: UserContactError | undefined;
    phoneNumberSaveError: UserContactError | undefined;
    newConversationCategoriesSaveError: UserProfileError | undefined;
    newEmailAddress: string | undefined;
    newPhoneNumber: PhoneNumber | undefined;
    isPhoneNumberEditMode: boolean;
    isEmailAddressEditMode: boolean;
    personalDataSaveError: UserProfileError | undefined;
    updateProfileLanguageError: UserProfileError | undefined;
    resetUserPasswordError: UserProfileError | undefined;
    saveProfileAvatarError: UserProfileError | undefined;
    deleteProfileAvatarError: UserProfileError | undefined;
}

const defaultState: UserProfileStateModel = {
    user: undefined,
    userId: undefined,
    userProfileError: undefined,
    isProcessing: false,
    profileAvatar: undefined,
    conversationCategories: undefined,
    assignedConversationCategories: undefined,
    newConversationCategoriesSaveError: undefined,
    profileCapabilitiesError: undefined,
    emailAddressSaveError: undefined,
    phoneNumberSaveError: undefined,
    newEmailAddress: undefined,
    newPhoneNumber: undefined,
    isPhoneNumberEditMode: false,
    isEmailAddressEditMode: false,
    personalDataSaveError: undefined,
    updateProfileLanguageError: undefined,
    resetUserPasswordError: undefined,
    saveProfileAvatarError: undefined,
    deleteProfileAvatarError: undefined,
};

@State<UserProfileStateModel>({
    name: "userProfile",
    defaults: defaultState,
})
@Injectable()
export class UserProfileState {
    @Selector()
    public static user(state: UserProfileStateModel): User | undefined {
        return state.user;
    }

    @Selector()
    public static userId(state: UserProfileStateModel): string | undefined {
        return state.userId;
    }

    @Selector()
    public static userProfileError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.userProfileError;
    }

    @Selector()
    public static emailAddressSaveError(state: UserProfileStateModel): UserContactError | undefined {
        return state.emailAddressSaveError;
    }

    @Selector()
    public static phoneNumberSaveError(state: UserProfileStateModel): UserContactError | undefined {
        return state.phoneNumberSaveError;
    }

    @Selector()
    public static isProcessing(state: UserProfileStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static isPhoneNumberEditMode(state: UserProfileStateModel): boolean {
        return state.isPhoneNumberEditMode;
    }

    @Selector()
    public static isEmailAddressEditMode(state: UserProfileStateModel): boolean {
        return state.isEmailAddressEditMode;
    }

    @Selector()
    public static conversationCategories(state: UserProfileStateModel): ConversationCategory[] | undefined {
        return state.conversationCategories;
    }

    @Selector()
    public static assignedConversationCategories(state: UserProfileStateModel): ConversationCategory[] | undefined {
        return state.assignedConversationCategories;
    }

    @Selector()
    public static newConversationCategoriesSaveError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.newConversationCategoriesSaveError;
    }

    @Selector()
    public static personalDataSaveError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.personalDataSaveError;
    }

    @Selector()
    public static updateProfileLanguageError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.updateProfileLanguageError;
    }

    @Selector()
    public static resetUserPasswordError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.resetUserPasswordError;
    }

    @Selector()
    public static deleteProfileAvatarError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.deleteProfileAvatarError;
    }

    @Selector()
    public static saveProfileAvatarError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.saveProfileAvatarError;
    }

    @Selector()
    public static profileAvatar(state: UserProfileStateModel): string | undefined {
        return state.profileAvatar;
    }

    constructor(private readonly userProfileApi: UserProfileApiService) {}

    @Action(UserProfileActions.GetUserProfile)
    public getUserProfile(context: StateContext<UserProfileStateModel>, action: UserProfileActions.GetUserProfile) {
        context.patchState({
            userProfileError: undefined,
            isProcessing: true,
        });

        return this.userProfileApi.getUserProfile(action.userId).pipe(
            map((result: User) =>
                context.patchState({
                    user: result,
                    userProfileError: undefined,
                    isProcessing: false,
                    userId: action.userId,
                })
            ),
            catchError((userProfileError) => {
                context.patchState({ userProfileError, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.GetProfileAvatar)
    public getProfileAvatar(context: StateContext<UserProfileStateModel>, action: UserProfileActions.GetProfileAvatar) {
        return this.userProfileApi.getProfileAvatar(action.userId).pipe(
            tap((profileAvatar: string) => context.patchState({ profileAvatar })),
            catchError(() => EMPTY)
        );
    }

    @Action(UserProfileActions.GetProfileCapabilities)
    public getProfileCapabilities(context: StateContext<UserProfileStateModel>, action: UserProfileActions.GetProfileCapabilities) {
        context.patchState({
            profileCapabilitiesError: undefined,
            isProcessing: true,
            conversationCategories: undefined,
            assignedConversationCategories: undefined,
        });

        return this.userProfileApi.getProfileCapabilities(action.userId).pipe(
            map((result: AvailableAndAssignedConversationCategories) =>
                context.patchState({
                    conversationCategories: result.categories,
                    assignedConversationCategories: result.assignedCategories,
                    isProcessing: false,
                })
            ),
            catchError((profileCapabilitiesError) => {
                context.patchState({ profileCapabilitiesError, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.SaveNewEmailAddress)
    public saveNewEmailAddress(context: StateContext<UserProfileStateModel>, action: UserProfileActions.SaveNewEmailAddress) {
        const { userId } = context.getState();

        if (!userId) {
            return;
        }

        context.patchState({ emailAddressSaveError: undefined, isProcessing: true, isEmailAddressEditMode: true });

        return this.userProfileApi.saveNewEmailAddress(userId, action.newEmailAddress).pipe(
            tap(() => {
                context.dispatch(new UserProfileActions.UpdateUserEmail(action.newEmailAddress));
            }),
            catchError((error: UserContactError) => {
                context.patchState({ emailAddressSaveError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.SaveNewPhoneNumber)
    public saveNewPhoneNumber(context: StateContext<UserProfileStateModel>, action: UserProfileActions.SaveNewPhoneNumber) {
        const { userId } = context.getState();

        if (!userId) {
            return;
        }

        context.patchState({ phoneNumberSaveError: undefined, isProcessing: true, isPhoneNumberEditMode: true });

        return this.userProfileApi.saveNewPhoneNumber(userId, action.newPhoneNumber).pipe(
            tap(() => {
                context.dispatch(new UserProfileActions.UpdateUserPhoneNumber(action.newPhoneNumber));
            }),
            catchError((error: UserContactError) => {
                context.patchState({ phoneNumberSaveError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.UpdateUserEmail)
    public updateEmail(context: StateContext<UserProfileStateModel>, action: UserProfileActions.UpdateUserEmail) {
        const user = context.getState().user;

        if (!user) {
            return;
        }

        context.patchState({ user: { ...user, email: action.newEmailAddress }, isProcessing: false, isEmailAddressEditMode: false });
        context.dispatch(new UserProfileActions.ClearEmailAddressRequestChange());
    }

    @Action(UserProfileActions.UpdateUserPhoneNumber)
    public updatePhoneNumber(context: StateContext<UserProfileStateModel>, action: UserProfileActions.UpdateUserPhoneNumber) {
        const user = context.getState().user;

        if (!user) {
            return;
        }

        context.patchState({ user: { ...user, phoneNumber: action.newPhoneNumber }, isProcessing: false, isPhoneNumberEditMode: false });
        context.dispatch(new UserProfileActions.ClearPhoneRequestChange());
    }

    @Action(UserProfileActions.ClearEmailAddressRequestChange)
    public clearEmailAddressRequestChange(context: StateContext<UserProfileStateModel>) {
        context.patchState({
            emailAddressSaveError: undefined,
            newEmailAddress: undefined,
        });
    }

    @Action(UserProfileActions.ClearPhoneRequestChange)
    public resetPhoneRequestChange(context: StateContext<UserProfileStateModel>) {
        context.patchState({
            phoneNumberSaveError: undefined,
            newPhoneNumber: undefined,
        });
    }

    @Action(UserProfileActions.SaveNewConversationsCategories)
    public saveNewConversationCategories(
        context: StateContext<UserProfileStateModel>,
        action: UserProfileActions.SaveNewConversationsCategories
    ) {
        const user = context.getState().user;

        if (!user) {
            return;
        }

        context.patchState({ newConversationCategoriesSaveError: undefined, isProcessing: true });

        return this.userProfileApi.saveNewConversationCategories(user.id, action.newAssignedConversationCategoriesIds).pipe(
            tap(() => {
                context.dispatch(new UserProfileActions.GetProfileCapabilities(user.id));
            }),
            catchError((error: UserProfileError) => {
                context.patchState({ newConversationCategoriesSaveError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.SaveNewPersonalData)
    public saveNewPersonalData(context: StateContext<UserProfileStateModel>, action: UserProfileActions.SaveNewPersonalData) {
        const user = context.getState().user;

        if (!user) {
            return;
        }

        context.patchState({ personalDataSaveError: undefined, isProcessing: true });

        return this.userProfileApi.saveNewPersonalData(user.id, action.newPersonalData).pipe(
            tap(() => {
                context.patchState({
                    user: {
                        ...user,
                        firstName: action.newPersonalData.firstName,
                        lastName: action.newPersonalData.lastName,
                    },
                    isProcessing: false,
                });
                context.dispatch([new GlobalCapabilitiesActions.GetGlobalCapabilities()]);
            }),
            catchError((error: UserContactError) => {
                context.patchState({ personalDataSaveError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.UpdateProfileLanguage)
    public updateProfileLanguage(context: StateContext<UserProfileStateModel>, action: UserProfileActions.UpdateProfileLanguage) {
        const user = context.getState().user;

        if (!user) {
            return;
        }

        context.dispatch(new UIActions.SetActiveLanguage(action.language));
        context.patchState({ updateProfileLanguageError: undefined, isProcessing: true });

        return this.userProfileApi.updateProfileLanguage(user.id, action.language).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((error: UserProfileError) => {
                context.patchState({ updateProfileLanguageError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.ResetUserPassword)
    public resetUserPassword(context: StateContext<UserProfileStateModel>) {
        context.patchState({ resetUserPasswordError: undefined, isProcessing: true });
        const { userId } = context.getState();

        if (!userId) {
            return;
        }

        return this.userProfileApi.resetUserPassword(userId).pipe(
            tap(() => context.dispatch(new AuthActions.Logout())),
            catchError((error: UserProfileError) => {
                context.patchState({ resetUserPasswordError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.SaveProfileAvatar)
    public saveProfileAvatar(context: StateContext<UserProfileStateModel>, action: UserProfileActions.SaveProfileAvatar) {
        context.patchState({ saveProfileAvatarError: undefined, isProcessing: true });
        const { userId } = context.getState();

        if (!userId) {
            return;
        }

        return this.userProfileApi.requestAvatarChange(userId, action.base64Image).pipe(
            tap(() => {
                context.patchState({ isProcessing: false });
                context.dispatch([
                    new UserProfileActions.GetProfileAvatar(userId),
                    new GlobalCapabilitiesActions.RefreshCapabilitiesAfterAvatarChange(),
                ]);
            }),
            catchError((error) => {
                context.patchState({ saveProfileAvatarError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.DeleteAvatar)
    public deleteAvatar(context: StateContext<UserProfileStateModel>) {
        context.patchState({ deleteProfileAvatarError: undefined, isProcessing: true });
        const user = context.getState().user;

        if (!user?.id) {
            return;
        }

        return this.userProfileApi.deleteUserAvatar(user.id).pipe(
            tap(() => {
                context.patchState({ profileAvatar: undefined, isProcessing: false });
                context.dispatch(new GlobalCapabilitiesActions.GetGlobalCapabilities());
            }),
            catchError((deleteProfileAvatarError) => {
                context.patchState({ deleteProfileAvatarError, isProcessing: false });

                return EMPTY;
            })
        );
    }
}
