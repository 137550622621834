import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    DialogService,
    MIDDLE_PAGE_SIZE_VALUE,
    PAGE_NUMBER_QUERY_PARAM,
    PAGE_SIZE_QUERY_PARAM,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";
import { AdminUser, AdminUsersErrorType, AdminUsersFilter, AdminUsersQueryParams, NewAdminUserValue } from "../../services/models";
import { AdminUsersState } from "../../state/admin-users-state.service";
import { AdminUsersActions } from "../../state/admin-users.actions";
import { AdminUserFormDialogComponent } from "../admin-user-form-dialog/admin-user-form-dialog.component";

interface AdminUsersComponentState {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    filtersQuery: AdminUsersFilter;
}

const enum AdminUserListActionType {
    AddUser = "AddUser",
    EditUser = "EditUser",
    DeleteUser = "DeleteUser",
    ResetPassword = "ResetPassword",
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-admin-users",
    templateUrl: "./admin-users.component.html",
    styleUrls: ["./admin-users.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdminUsersComponent implements OnInit {
    protected readonly usersList$ = this.store.select(AdminUsersState.usersList);
    protected readonly usersListError$ = this.store.select(AdminUsersState.usersListError);
    protected readonly isUsersListProcessing$ = this.store.select(AdminUsersState.isUsersListProcessing);
    protected readonly pagination$ = this.store.select(AdminUsersState.pages);
    protected readonly filtersQuery$ = this.localStore.selectByKey("filtersQuery");
    protected readonly isUserProcessing$ = this.store.select(AdminUsersState.isUserProcessing);

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<AdminUsersComponentState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService
    ) {
        localStore.setState({
            [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIDDLE_PAGE_SIZE_VALUE,
            filtersQuery: {
                textSearch: this.route.snapshot.queryParams.textSearch,
            },
        });
    }

    public ngOnInit() {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe(() => {
            this.localStore.patchState({
                [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
                [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIDDLE_PAGE_SIZE_VALUE,
                filtersQuery: {
                    textSearch: this.route.snapshot.queryParams.textSearch,
                },
            });
            this.refreshUsersList();
        });
    }

    protected refreshUsersList() {
        this.store.dispatch(
            new AdminUsersActions.GetUsers({
                size: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
                page: this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM),
                textSearch: this.localStore.selectSnapshotByKey("filtersQuery").textSearch,
            })
        );
    }

    protected changePage(event: PageEvent) {
        this.localStore.patchState({
            [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: event.pageSize,
        });

        this.navigateByParams();
    }

    protected applyFilters(filtersQuery: AdminUsersFilter) {
        this.localStore.patchState({ filtersQuery });
        this.navigateByParams(true);
    }

    protected openAddUserDialog() {
        const dialogRef = this.dialogService.open(AdminUserFormDialogComponent, {
            data: {
                header: this.translocoService.translate("dtmAdminLibAdminUsers.userFormDialog.addUserHeader"),
                isUserProcessing$: this.isUserProcessing$,
                confirmLabel: this.translocoService.translate("dtmAdminLibAdminUsers.userFormDialog.addLabel"),
            },
            disableClose: true,
        });

        dialogRef.componentInstance.newValue$
            .pipe(
                switchMap((newUserValue: NewAdminUserValue) => this.store.dispatch(new AdminUsersActions.AddUser(newUserValue))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdminUsersState.userError);
                if (!error) {
                    this.showActionSuccessMessage(AdminUserListActionType.AddUser);
                    dialogRef.close();
                    this.refreshUsersList();

                    return;
                }

                this.showActionErrorMessage(AdminUserListActionType.AddUser, error.type);
            });
    }

    protected editUser(user: AdminUser) {
        const dialogRef = this.dialogService.open(AdminUserFormDialogComponent, {
            data: {
                user: user,
                header: this.translocoService.translate("dtmAdminLibAdminUsers.userFormDialog.editUserHeader"),
                isUserProcessing$: this.isUserProcessing$,
                confirmLabel: this.translocoService.translate("dtmAdminLibAdminUsers.userFormDialog.saveLabel"),
            },
            disableClose: true,
        });

        dialogRef.componentInstance.newValue$
            .pipe(
                switchMap((newUserValue: NewAdminUserValue) => this.store.dispatch(new AdminUsersActions.EditUser(newUserValue, user.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(AdminUsersState.userError);
                if (!error) {
                    this.showActionSuccessMessage(AdminUserListActionType.EditUser);
                    dialogRef.close();
                    this.refreshUsersList();

                    return;
                }

                this.showActionErrorMessage(AdminUserListActionType.EditUser, error.type);
            });
    }

    protected deleteUser(user: AdminUser) {
        if (user.id === this.store.selectSnapshot(AuthState.userId)) {
            this.toastrService.warning(this.translocoService.translate("dtmAdminLibAdminUsers.users.selfRemovalForbiddenWarningMessage"));

            return;
        }

        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate("dtmAdminLibAdminUsers.users.deleteUserDialogTitle", {
                    userName: `${user.firstName} ${user.lastName}`,
                }),
                confirmationText: this.translocoService.translate("dtmAdminLibAdminUsers.users.deleteUserDialogConfirmationText"),
                declineButtonLabel: this.translocoService.translate("dtmAdminLibAdminUsers.users.cancelLabel"),
                confirmButtonLabel: this.translocoService.translate("dtmAdminLibAdminUsers.users.confirmUserRemovalLabel"),
                theme: ButtonTheme.Warn,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new AdminUsersActions.DeleteUser(user.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (!this.store.selectSnapshot(AdminUsersState.userError)) {
                    this.showActionSuccessMessage(AdminUserListActionType.DeleteUser);
                    this.refreshUsersList();

                    return;
                }

                this.showActionErrorMessage(AdminUserListActionType.DeleteUser);
            });
    }

    protected resetPassword(user: AdminUser) {
        if (user.id === this.store.selectSnapshot(AuthState.userId)) {
            this.toastrService.warning(this.translocoService.translate("dtmAdminLibAdminUsers.users.selfPasswordResetWarningMessage"));

            return;
        }

        const userName = `${user.firstName} ${user.lastName}`;

        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate("dtmAdminLibAdminUsers.users.resetPasswordDialogTitle", {
                    userName,
                }),
                confirmationText: this.translocoService.translate("dtmAdminLibAdminUsers.users.resetPasswordDialogConfirmationText", {
                    userName,
                }),
                declineButtonLabel: this.translocoService.translate("dtmAdminLibAdminUsers.users.cancelLabel"),
                confirmButtonLabel: this.translocoService.translate("dtmAdminLibAdminUsers.users.resetPasswordConfirmLabel"),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new AdminUsersActions.ResetUsersPassword(user.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (!this.store.selectSnapshot(AdminUsersState.userError)) {
                    this.showActionSuccessMessage(AdminUserListActionType.ResetPassword);

                    return;
                }

                this.showActionErrorMessage(AdminUserListActionType.ResetPassword);
            });
    }

    protected navigateByParams(shouldResetPage?: boolean) {
        const pageIndex = shouldResetPage ? 0 : this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM);
        const { textSearch } = this.localStore.selectSnapshotByKey("filtersQuery");

        if (shouldResetPage) {
            this.localStore.patchState({ [PAGE_NUMBER_QUERY_PARAM]: pageIndex });
        }

        let params: AdminUsersQueryParams = {
            [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
        };

        if (textSearch?.length) {
            params = { ...params, textSearch };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    private showActionSuccessMessage(actionType: AdminUserListActionType) {
        switch (actionType) {
            case AdminUserListActionType.AddUser:
                this.toastrService.success(this.translocoService.translate("dtmAdminLibAdminUsers.users.addUserSuccessMessage"));
                break;
            case AdminUserListActionType.EditUser:
                this.toastrService.success(this.translocoService.translate("dtmAdminLibAdminUsers.users.editUserSuccessMessage"));
                break;
            case AdminUserListActionType.DeleteUser:
                this.toastrService.success(this.translocoService.translate("dtmAdminLibAdminUsers.users.deleteUserSuccessMessage"));
                break;
            case AdminUserListActionType.ResetPassword:
                this.toastrService.success(this.translocoService.translate("dtmAdminLibAdminUsers.users.resetPasswordSuccessMessage"));
                break;
        }
    }

    private showActionErrorMessage(actionType: AdminUserListActionType, errorType?: AdminUsersErrorType) {
        if (!errorType) {
            switch (actionType) {
                case AdminUserListActionType.AddUser:
                    this.toastrService.error(this.translocoService.translate("dtmAdminLibAdminUsers.users.addUserErrorMessage"));
                    break;
                case AdminUserListActionType.EditUser:
                    this.toastrService.error(this.translocoService.translate("dtmAdminLibAdminUsers.users.editUserErrorMessage"));
                    break;
                case AdminUserListActionType.DeleteUser:
                    this.toastrService.error(this.translocoService.translate("dtmAdminLibAdminUsers.users.deleteUserErrorMessage"));
                    break;
                case AdminUserListActionType.ResetPassword:
                    this.toastrService.error(this.translocoService.translate("dtmAdminLibAdminUsers.users.resetPasswordErrorMessage"));
                    break;
            }

            return;
        }

        switch (errorType) {
            case AdminUsersErrorType.EmailConflict:
                this.toastrService.error(this.translocoService.translate("dtmAdminLibAdminUsers.users.emailConflictErrorMessage"));
                break;
            case AdminUsersErrorType.PhoneNumberConflict:
                this.toastrService.error(this.translocoService.translate("dtmAdminLibAdminUsers.users.phoneNumberConflictErrorMessage"));
                break;
            default:
                this.toastrService.error(this.translocoService.translate("dtmAdminLibAdminUsers.users.generalErrorMessage"));
        }
    }
}
