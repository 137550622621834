<form [formGroup]="filtersFormGroup">
    <dtm-ui-filters-container *ngrxLet="filtersCount$; let filtersCount">
        <div class="filters-container">
            <dtm-ui-search-thread class="field" formControlName="searchByText" [isLabelVisible]="true"></dtm-ui-search-thread>
            <dtm-ui-conversations-category-multi-select
                class="field"
                [formControlName]="ConversationFilterFormKeys.Categories"
                [categories]="categories$ | ngrxPush"
            ></dtm-ui-conversations-category-multi-select>
        </div>
        <div class="filters-container">
            <dtm-admin-lib-single-select-filter
                class="field"
                [singleSelectFormControl]="assignmentFilterControl"
                [label]="'dtmAdminLibConversation.threadContainerFilters.assignmentFormLabel' | transloco"
                [trueOptionLabel]="
                    'dtmAdminLibConversation.threadContainerFilters.assignedFilterValueLabel'
                        | transloco : { value: SINGLE_SELECT_VALUES.True }
                "
                [falseOptionLabel]="
                    'dtmAdminLibConversation.threadContainerFilters.assignedFilterValueLabel'
                        | transloco : { value: SINGLE_SELECT_VALUES.False }
                "
            >
            </dtm-admin-lib-single-select-filter>
            <dtm-admin-lib-single-select-filter
                class="field"
                [singleSelectFormControl]="readFilterControl"
                [label]="'dtmAdminLibConversation.threadContainerFilters.readFilterLabel' | transloco"
                [trueOptionLabel]="
                    'dtmAdminLibConversation.threadContainerFilters.readFilterValueLabel' | transloco : { value: SINGLE_SELECT_VALUES.True }
                "
                [falseOptionLabel]="
                    'dtmAdminLibConversation.threadContainerFilters.readFilterValueLabel'
                        | transloco : { value: SINGLE_SELECT_VALUES.False }
                "
            >
            </dtm-admin-lib-single-select-filter>
            <dtm-admin-lib-single-select-filter
                class="field"
                [singleSelectFormControl]="closedFilterControl"
                [label]="'dtmAdminLibConversation.threadContainerFilters.closedFilterLabel' | transloco"
                [trueOptionLabel]="
                    'dtmAdminLibConversation.threadContainerFilters.closedFilterValueLabel'
                        | transloco : { value: SINGLE_SELECT_VALUES.True }
                "
                [falseOptionLabel]="
                    'dtmAdminLibConversation.threadContainerFilters.closedFilterValueLabel'
                        | transloco : { value: SINGLE_SELECT_VALUES.False }
                "
            >
            </dtm-admin-lib-single-select-filter>
        </div>

        <dtm-ui-filter-chips-display
            *ngIf="filtersCount"
            selectedFiltersDisplaySlot
            [filtersMap]="FILTERS_MAP"
            [filtersCount]="filtersCount"
            [formGroup]="filtersFormGroup"
            [filtersValue]="filtersFormGroup.value"
            (allFiltersReset)="clearFilters()"
        ></dtm-ui-filter-chips-display>
    </dtm-ui-filters-container>
</form>
