import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ConversationCategoryCode, OperatorsThread } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

interface ThreadCategoryChangeComponentState {
    categories: ConversationCategoryCode[];
    processingThreadsIds: string[];
    thread: OperatorsThread | undefined;
    selectedCategory: ConversationCategoryCode | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-thread-category-change[categories][processingThreadsIds][thread]",
    templateUrl: "./thread-category-change.component.html",
    styleUrls: ["./thread-category-change.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ThreadCategoryChangeComponent implements OnInit {
    @Input() public set categories(value: ConversationCategoryCode[] | undefined) {
        if (value?.length) {
            this.localStore.patchState({ categories: value });
        }
    }

    @Input() public set processingThreadsIds(value: string[] | undefined) {
        this.localStore.patchState({ processingThreadsIds: value ?? [] });
    }

    @Input() public set thread(value: OperatorsThread | undefined) {
        this.localStore.patchState({ thread: value });
    }

    @Input() public set selectedCategory(value: ConversationCategoryCode | undefined) {
        this.localStore.patchState({ selectedCategory: value });
        if (value) {
            this.categoryControl.setValue(value);
        }
    }

    @Output() public categoryChange = new EventEmitter<ConversationCategoryCode>();

    public readonly categories$ = this.localStore.selectByKey("categories");
    public readonly thread$ = this.localStore.selectByKey("thread");
    public readonly isProcessing$ = this.localStore.selectByKey("processingThreadsIds").pipe(
        withLatestFrom(
            this.localStore.selectByKey("thread").pipe(
                RxjsUtils.filterFalsy(),
                map((thread) => thread.id)
            )
        ),
        map(([list, threadId]) => list?.some((processingId) => processingId === threadId))
    );

    public readonly categoryControl = new UntypedFormControl();

    constructor(private readonly localStore: LocalComponentStore<ThreadCategoryChangeComponentState>) {
        localStore.setState({ categories: [], processingThreadsIds: [], thread: undefined, selectedCategory: undefined });
    }

    public ngOnInit() {
        combineLatest([this.thread$.pipe(map((thread) => thread?.isClosed)), this.isProcessing$])
            .pipe(untilDestroyed(this))
            .subscribe(([isClosed, isProcessing]) => {
                if (isClosed || isProcessing) {
                    this.categoryControl.disable();

                    return;
                }

                this.categoryControl.enable();
                this.categoryControl.setValue(this.localStore.selectSnapshotByKey("selectedCategory"), { emitEvent: false });
            });
    }
}
