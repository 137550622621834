import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ObjectUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AUDIT_ENDPOINTS, AuditEndpoints } from "../audit.tokens";
import { GetAuditChangeLogResponseBody, convertGetAuditChangeLogResponseBodyToChangeLogWithPages } from "./audit-api.converters";
import { AuditChangeLogErrorType, AuditChangeLogWithPages, ChangeLogFiltersParams } from "./models";

@Injectable({
    providedIn: "root",
})
export class AuditApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(AUDIT_ENDPOINTS) private readonly endpoints: AuditEndpoints) {}

    public getChangeLog(filters: ChangeLogFiltersParams): Observable<AuditChangeLogWithPages> {
        const params = new HttpParams({ fromObject: ObjectUtils.removeNullishProperties(filters) });

        return this.httpClient.get<GetAuditChangeLogResponseBody>(this.endpoints.getChangeLog, { params }).pipe(
            map((response) => convertGetAuditChangeLogResponseBodyToChangeLogWithPages(response)),
            catchError(() => throwError(() => ({ type: AuditChangeLogErrorType.Unknown })))
        );
    }
}
