<div class="dialog-header">
    <h4 mat-dialog-title>{{ data.header }}</h4>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<dtm-ui-loader-container [isShown]="data.isUserProcessing$ | ngrxPush">
    <form [formGroup]="adminUserForm" mat-dialog-content dtmUiInvalidFormScrollable>
        <div class="section">
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibAdminUsers.userFormDialog.firstNameLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="firstNameControl" />
                <div class="field-error" *dtmUiFieldHasError="firstNameControl; name: 'maxlength'; error as error">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.firstNameTooLongError" | transloco : { max: error.requiredLength } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="firstNameControl; name: 'required'">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.requiredError" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibAdminUsers.userFormDialog.lastNameLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="lastNameControl" />
                <div class="field-error" *dtmUiFieldHasError="lastNameControl; name: 'maxlength'; error as error">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.lastNameTooLongError" | transloco : { max: error.requiredLength } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="lastNameControl; name: 'required'">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.requiredError" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibAdminUsers.userFormDialog.emailLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="emailControl" (blur)="validateConfirmationEmail()" />
                <div class="field-error" *dtmUiFieldHasError="emailControl; name: 'email'; error as error">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.emailError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="emailControl; name: 'required'">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.requiredError" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibAdminUsers.userFormDialog.emailConfirmationLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="emailConfirmationControl" (paste)="$event.preventDefault()" />
                <div class="field-error" *dtmUiFieldHasError="emailConfirmationControl; name: 'differentEmails'">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.emailsMustBeIdenticalError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="emailConfirmationControl; name: 'required'">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.requiredError" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-phone-number-field [formControl]="phoneNumberControl">
                <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'required'">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.requiredError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumber'">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.invalidPhoneNumberError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumberType'">
                    {{ "dtmAdminLibAdminUsers.userFormDialog.invalidNumberTypeError" | transloco }}
                </div>
            </dtm-ui-phone-number-field>
        </div>
    </form>

    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [mat-dialog-close]="false">
            {{ "dtmAdminLibAdminUsers.userFormDialog.cancelLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="save()">{{ data.confirmLabel }}</button>
    </div>
</dtm-ui-loader-container>
