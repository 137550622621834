import { AssociationPermit, Permit, UploadedFile } from "@dtm-frontend/shared/ui";
import { PermitFilterParams, PermitStatus, PermitsTab } from "../models/permits.models";

export namespace PermitsActions {
    export class GetOperatorList {
        public static readonly type = "[SpecificPermits] Get operator list";
        constructor(public searchText?: string) {}
    }

    export class GetAssociationOperatorList {
        public static readonly type = "[SpecificPermits] Get association operator list";
        constructor(public searchText?: string) {}
    }

    export class GetSpecificPermitDetails {
        public static readonly type = "[SpecificPermits] Get specific permit details";
        constructor(public permitId: string) {}
    }

    export class GetCrossBorderSpecificPermitDetails {
        public static readonly type = "[SpecificPermits] Get cross border specific permit details";
        constructor(public permitId: string) {}
    }

    export class GetAssociationPermitDetails {
        public static readonly type = "[SpecificPermits] Get association permit details";
        constructor(public permitId: string) {}
    }

    export class ClearSpecificPermitDetails {
        public static readonly type = "[SpecificPermits] Clear specific permit details";
    }

    export class ClearAssociationPermitDetails {
        public static readonly type = "[SpecificPermits] Clear association permit details";
    }

    export class AddPermit {
        public static readonly type = "[SpecificPermits] Add permit";
        constructor(public applicationPermitData: Permit) {}
    }

    export class UpdatePermit {
        public static readonly type = "[SpecificPermits] Update permit";
        constructor(public applicationPermitData: Permit, public permitId: string) {}
    }

    export class AddCrossBorderPermit {
        public static readonly type = "[SpecificPermits] Add cross border permit";
        constructor(public applicationPermitData: Permit) {}
    }

    export class UpdateCrossBorderPermit {
        public static readonly type = "[SpecificPermits] Update cross border permit";
        constructor(public applicationPermitData: Permit, public permitId: string) {}
    }

    export class AddAssociationPermit {
        public static readonly type = "[SpecificPermits] Add association permit";
        constructor(public applicationPermitData: AssociationPermit) {}
    }

    export class UpdateAssociationPermit {
        public static readonly type = "[SpecificPermits] Update association permit";
        constructor(public permit: AssociationPermit) {}
    }

    export class GetSpecificPermits {
        public static readonly type = "[SpecificPermits] Get specific permits";
        constructor(public filtersQuery: PermitFilterParams, public activeTab: PermitsTab) {}
    }

    export class UpdatePermitStatus {
        public static readonly type = "[SpecificPermits] Update specific permit status";
        constructor(public action: PermitStatus, public reason: string, public permitId: string) {}
    }

    export class GetSpecificPermitsCapabilities {
        public static readonly type = "[SpecificPermits] Get specific permits capabilities";
    }

    export class RetryFetchData {
        public static readonly type = "[SpecificPermits] Retry fetch required data";
    }

    export class RetryFetchAssociationPermitRequestData {
        public static readonly type = "[SpecificPermits] Retry fetch association permit request data";
    }

    export class GetKmlPreview {
        public static readonly type = "[SpecificPermits] Get KML preview";
        constructor(public file: UploadedFile) {}
    }
}
