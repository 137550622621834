import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { GlobalCapabilities, GlobalCapabilitiesErrorType } from "../models/operator-context.models";
import { GlobalCapabilitiesResponseBody, convertGlobalCapabilitiesResponseBodyToGlobalCapabilities } from "./user-data-api.converters";
import { GLOBAL_CAPABILITIES_ENDPOINTS, GlobalCapabilitiesEndpoints } from "./user-data.tokens";

@Injectable({
    providedIn: "root",
})
export class GlobalCapabilitiesApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(GLOBAL_CAPABILITIES_ENDPOINTS) private readonly endpoints: GlobalCapabilitiesEndpoints
    ) {}

    public getUserCapabilities(): Observable<GlobalCapabilities> {
        return this.httpClient.get<GlobalCapabilitiesResponseBody>(this.endpoints.getGlobalCapabilities).pipe(
            map((response) => convertGlobalCapabilitiesResponseBodyToGlobalCapabilities(response)),
            catchError(() => throwError(() => ({ type: GlobalCapabilitiesErrorType.CannotGetUserGlobalCapabilities })))
        );
    }

    public acceptTermsOfService(userId: string): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.acceptTermsOfService, { userId }), {})
            .pipe(catchError(() => throwError(() => ({ type: GlobalCapabilitiesErrorType.Unknown }))));
    }
}
