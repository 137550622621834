<dtm-ui-presentation-tile-with-action
    [titleTile]="titleTile$ | ngrxPush"
    *ngrxLet="{
        qualifications: qualifications$,
        suspendedQualifications: suspendedQualifications$,
        areActionsAvailable: areActionsAvailable$,
        qualificationType: qualificationType$
    } as vm"
>
    <button *ngIf="vm.areActionsAvailable" actionButtonSlot class="button-secondary" type="button" (click)="qualificationAdd.emit()">
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ "dtmAdminLibShared.operatorProfile.qualifications.addLabel" | transloco }}
    </button>
    <div class="main-container">
        <div *ngFor="let qualification of vm.qualifications" class="row">
            <dtm-ui-qualification-badge [qualification]="qualification"></dtm-ui-qualification-badge>
            <button *ngIf="vm.areActionsAvailable" class="button-icon" type="button" [matMenuTriggerFor]="menu">
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button type="button" (click)="changeQualificationStatus(qualification.id, QualificationStatus.Withdrawn)" mat-menu-item>
                    <dtm-ui-icon name="play-circle"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.qualifications.withdrawnLabel" | transloco }}
                </button>
                <button
                    *ngIf="qualification.status === QualificationStatus.Suspended; else suspendTemplate"
                    type="button"
                    (click)="changeQualificationStatus(qualification.id, QualificationStatus.Active)"
                    mat-menu-item
                >
                    <dtm-ui-icon name="play-circle"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.qualifications.activateLabel" | transloco }}
                </button>
                <ng-template #suspendTemplate>
                    <button
                        type="button"
                        (click)="changeQualificationStatus(qualification.id, QualificationStatus.Suspended)"
                        mat-menu-item
                    >
                        <dtm-ui-icon name="pause-circle"></dtm-ui-icon>
                        {{ "dtmAdminLibShared.operatorProfile.qualifications.suspendLabel" | transloco }}
                    </button>
                </ng-template>
                <button type="button" (click)="qualificationRemove.emit(qualification.id)" mat-menu-item>
                    <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.qualifications.deleteLabel" | transloco }}
                </button>
            </mat-menu>
        </div>
        <div
            *ngIf="
                vm.areActionsAvailable &&
                vm.qualifications.length !== vm.suspendedQualifications.length &&
                vm.qualificationType === QualificationType.PilotCompetencies
            "
            (click)="qualificationsSuspendAll.emit()"
            class="button-container"
        >
            <button type="button" class="button-tertiary">
                {{ "dtmAdminLibShared.operatorProfile.qualifications.suspendAllCompetenciesLabel" | transloco }}
            </button>
        </div>
    </div>
</dtm-ui-presentation-tile-with-action>
