import {
    Attachment,
    AttorneyPowerUpdate,
    ForeignCompetencyDocument,
    ForeignCompetencyStatus,
    NewOperationalAuthorization,
    NewQualification,
    OperatorDocumentType,
    OperatorStatusChange,
    OperatorType,
    PermissionsToGrant,
    QualificationStatusChange,
} from "../../shared";
import { OperatorsFilterParams } from "../services/operator.models";

export namespace OperatorActions {
    export class GetCapabilities {
        public static readonly type = "[Operator] Get capabilities";
    }

    export class GetOperators {
        public static readonly type = "[Operator] Get operators";

        constructor(public filtersQuery: OperatorsFilterParams) {}
    }

    export class SelectOperator {
        public static readonly type = "[Operator] Set selected operator";

        constructor(public operatorId: string, public operatorType: OperatorType) {}
    }

    export class ClearOperatorDetails {
        public static readonly type = "[Operator] Clear selected operator details";
    }

    export class AddOperationalAuthorization {
        public static readonly type = "[Operator] Add operational authorization";

        constructor(public operatorId: string, public newOperationalAuthorizations: NewOperationalAuthorization[]) {}
    }

    export class AddPilotsCompetency {
        public static readonly type = "[Operator] Add pilots competency";

        constructor(public operatorId: string, public newOperationalAuthorization: NewQualification, public pilotId: string) {}
    }

    export class ChangeOperationalAuthorizationStatus {
        public static readonly type = "[Operator] Change operational authorization status";
        constructor(public operatorId: string, public statusChange: QualificationStatusChange) {}
    }

    export class ChangePilotCompetencyStatus {
        public static readonly type = "[Operator] Change pilot competency status";
        constructor(public operatorId: string, public pilotId: string, public statusChange: QualificationStatusChange) {}
    }

    export class SuspendAllPilotCompetencies {
        public static readonly type = "[Operator] Suspend all pilot competencies";
        constructor(public operatorId: string, public pilotId: string, public statusChange: QualificationStatusChange) {}
    }

    export class RemoveOperationalAuthorization {
        public static readonly type = "[Operator] Remove operational authorization";

        constructor(public operatorId: string, public operationalAuthorizationId: string) {}
    }

    export class RemovePilotCompetency {
        public static readonly type = "[Operator] Remove pilot competency";

        constructor(public operatorId: string, public competencyId: string, public pilotId: string) {}
    }

    export class GetOperatorsAvailableOperationScenarios {
        public static readonly type = "[Operator] Get operators available operation scenarios";

        constructor(public operatorId: string) {}
    }

    export class ChangeOperatorStatus {
        public static readonly type = "[Operator] Change operator status";

        constructor(public statusChange: OperatorStatusChange) {}
    }

    export class GetDocument {
        public static readonly type = "[Operator] Get attorney power document";

        constructor(public operatorId: string, public attachment: Attachment, public documentType: OperatorDocumentType) {}
    }

    export class GetForeignCompetencyDocument {
        public static readonly type = "[Operator] Get foreign competency document";

        constructor(public competency: ForeignCompetencyDocument) {}
    }

    export class UpdateAttorneyPower {
        public static readonly type = "[Operator] Update attorney power";

        constructor(public operatorId: string, public attorneyPowerUpdate: AttorneyPowerUpdate) {}
    }

    export class UpdateAttorneyPowerPermissions {
        public static readonly type = "[Operator] Update attorney power permissions";

        constructor(public operatorId: string, public attorneyPowerId: string, public permissions: PermissionsToGrant[]) {}
    }

    export class AddOtherInformation {
        public static readonly type = "[Operator] Add other information";

        constructor(public operatorId: string, public text: string) {}
    }

    export class EditOtherInformation {
        public static readonly type = "[Operator] Edit other information";

        constructor(public operatorId: string, public otherInformationId: string, public text: string) {}
    }

    export class DeleteOtherInformation {
        public static readonly type = "[Operator] Delete other information";

        constructor(public operatorId: string, public otherInformationId: string) {}
    }

    export class DeleteOperator {
        public static readonly type = "[Operator] Delete operator";

        constructor(public operatorId: string) {}
    }

    export class UpdateForeignCompetencyStatus {
        public static readonly type = "[Operator] Update foreign competency status";

        constructor(
            public pilotId: string,
            public competencyId: string,
            public status: ForeignCompetencyStatus,
            public statusReason?: string
        ) {}
    }
}
