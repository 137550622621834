import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ExaminationCenterDialogData } from "../../models/examination-centers.models";

@Component({
    selector: "dtm-admin-lib-examination-centers-preview-dialog",
    templateUrl: "./examination-centers-preview-dialog.component.html",
    styleUrls: ["./examination-centers-preview-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExaminationCentersPreviewDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ExaminationCenterDialogData,
        public readonly dialog: MatDialogRef<ExaminationCentersPreviewDialogComponent>
    ) {}
}
