import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { saveAs } from "file-saver";
import { EMPTY, of } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import {
    OperationScenario,
    Operator,
    OperatorDetails,
    OperatorError,
    OperatorErrorType,
    PermissionsToGrant,
    UpdateAttorneyPowerError,
    UpdateOperatorDetailsError,
} from "../../shared";
import { OperatorAPIService } from "../services/operator-api.service";
import { CapabilitiesError, OperatorListWithPages, OperatorsCapabilities, Page, QualificationsError } from "../services/operator.models";
import { PilotAPIService } from "../services/pilot-api.service";
import { OperatorActions } from "./operator.actions";

const ALLOWED_FILE_EXTENSIONS_TO_DISPLAY: Record<string, string> = {
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    png: "image/png",
    apng: "image/apng",
    avif: "image/avif",
    gif: "image/gif",
    svg: "image/svg+xml",
    webp: "image/webp",
    pdf: "application/pdf",
};

export interface OperatorStateModel {
    capabilities: OperatorsCapabilities | undefined;
    capabilitiesError: CapabilitiesError | undefined;
    operatorListError: OperatorError | undefined;
    operatorError: OperatorError | undefined;
    operatorList: Operator[] | undefined;
    pages: Page | undefined;
    selectedOperatorDetails: OperatorDetails | undefined;
    operatorAvailableOperationScenarios: OperationScenario[] | undefined;
    operatorAvailableOperationScenariosError: QualificationsError | undefined;
    operationalAuthorizationsError: QualificationsError | undefined;
    pilotCompetencyUpdateError: QualificationsError | undefined;
    pilotCompetenciesSuspendAllError: QualificationsError | undefined;
    changeStatusError: UpdateOperatorDetailsError | undefined;
    updateOperatorDetailsError: UpdateOperatorDetailsError | undefined;
    availablePermissions: PermissionsToGrant[] | undefined;
    updateAttorneyPowerError: UpdateAttorneyPowerError | undefined;
    isProcessing: boolean;
    addOtherInformationError: UpdateOperatorDetailsError | undefined;
    editOtherInformationError: UpdateOperatorDetailsError | undefined;
    deleteOtherInformationError: UpdateOperatorDetailsError | undefined;
    deleteOperatorError: OperatorErrorType | undefined;
    updateForeignCompetencyStatusError: OperatorErrorType | undefined;
}

const defaultState: OperatorStateModel = {
    capabilities: undefined,
    capabilitiesError: undefined,
    operatorList: undefined,
    operatorListError: undefined,
    pages: undefined,
    selectedOperatorDetails: undefined,
    operatorError: undefined,
    operatorAvailableOperationScenarios: undefined,
    operatorAvailableOperationScenariosError: undefined,
    operationalAuthorizationsError: undefined,
    pilotCompetencyUpdateError: undefined,
    pilotCompetenciesSuspendAllError: undefined,
    changeStatusError: undefined,
    updateOperatorDetailsError: undefined,
    availablePermissions: undefined,
    updateAttorneyPowerError: undefined,
    isProcessing: false,
    addOtherInformationError: undefined,
    editOtherInformationError: undefined,
    deleteOtherInformationError: undefined,
    deleteOperatorError: undefined,
    updateForeignCompetencyStatusError: undefined,
};

@State<OperatorStateModel>({
    name: "operator",
    defaults: defaultState,
})
@Injectable()
export class OperatorState {
    @Selector()
    public static capabilities(state: OperatorStateModel): OperatorsCapabilities | undefined {
        return state.capabilities;
    }

    @Selector()
    public static capabilitiesError(state: OperatorStateModel): CapabilitiesError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static operatorList(state: OperatorStateModel): Operator[] | undefined {
        return state.operatorList;
    }

    @Selector()
    public static operatorListError(state: OperatorStateModel): OperatorError | undefined {
        return state.operatorListError;
    }

    @Selector()
    public static operatorError(state: OperatorStateModel): OperatorError | undefined {
        return state.operatorError;
    }

    @Selector()
    public static pages(state: OperatorStateModel): Page | undefined {
        return state.pages;
    }

    @Selector()
    public static selectedOperatorDetails(state: OperatorStateModel): OperatorDetails | undefined {
        return state.selectedOperatorDetails;
    }

    @Selector()
    public static pilotCompetencyUpdateError(state: OperatorStateModel): QualificationsError | undefined {
        return state.pilotCompetencyUpdateError;
    }

    @Selector()
    public static operatorAvailableOperationScenarios(state: OperatorStateModel): OperationScenario[] | undefined {
        return state.operatorAvailableOperationScenarios;
    }

    @Selector()
    public static operatorAvailableOperationScenariosError(state: OperatorStateModel): QualificationsError | undefined {
        return state.operatorAvailableOperationScenariosError;
    }

    @Selector()
    public static operationalAuthorizationsError(state: OperatorStateModel): QualificationsError | undefined {
        return state.operationalAuthorizationsError;
    }

    @Selector()
    public static changeStatusError(state: OperatorStateModel): UpdateOperatorDetailsError | undefined {
        return state.changeStatusError;
    }

    @Selector()
    public static addOtherInformationError(state: OperatorStateModel): UpdateOperatorDetailsError | undefined {
        return state.addOtherInformationError;
    }

    @Selector()
    public static editOtherInformationError(state: OperatorStateModel): UpdateOperatorDetailsError | undefined {
        return state.editOtherInformationError;
    }

    @Selector()
    public static deleteOtherInformationError(state: OperatorStateModel): UpdateOperatorDetailsError | undefined {
        return state.deleteOtherInformationError;
    }

    @Selector()
    public static deleteOperatorError(state: OperatorStateModel): OperatorErrorType | undefined {
        return state.deleteOperatorError;
    }

    @Selector()
    public static isProcessing(state: OperatorStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static changeOperatorDetailsError(state: OperatorStateModel): UpdateOperatorDetailsError | undefined {
        return state.updateOperatorDetailsError;
    }

    @Selector()
    public static pilotCompetenciesSuspendAllError(state: OperatorStateModel): QualificationsError | undefined {
        return state.pilotCompetenciesSuspendAllError;
    }

    @Selector()
    public static availablePermissions(state: OperatorStateModel): PermissionsToGrant[] | undefined {
        return state.availablePermissions;
    }

    @Selector()
    public static updateAttorneyPowerError(state: OperatorStateModel): UpdateAttorneyPowerError | undefined {
        return state.updateAttorneyPowerError;
    }

    @Selector()
    public static updateForeignCompetencyStatusError(state: OperatorStateModel): OperatorErrorType | undefined {
        return state.updateForeignCompetencyStatusError;
    }

    constructor(
        private readonly operatorApi: OperatorAPIService,
        private readonly pilotApi: PilotAPIService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        if (operatorApi === undefined) {
            throw new Error("Initialize OperatorModule with .forRoot()");
        }
    }

    @Action(OperatorActions.GetCapabilities)
    public getCapabilities(context: StateContext<OperatorStateModel>) {
        context.patchState({ capabilitiesError: undefined });

        return this.operatorApi.getCapabilities().pipe(
            tap((capabilities) => context.patchState({ capabilities })),
            catchError((capabilitiesError) => {
                context.patchState({ capabilitiesError, capabilities: undefined });

                return EMPTY;
            })
        );
    }

    @Action(OperatorActions.GetOperators)
    public getOperators(context: StateContext<OperatorStateModel>, action: OperatorActions.GetOperators) {
        context.patchState({ isProcessing: true });

        return this.operatorApi.getOperators(action.filtersQuery).pipe(
            tap((result: OperatorListWithPages) => {
                const operator: OperatorDetails | undefined = this.getSelectedOperatorFromList(
                    result.content,
                    context.getState().selectedOperatorDetails
                );

                context.patchState({
                    operatorList: result.content,
                    pages: {
                        totalElements: result.totalElements,
                        pageNumber: result.pageNumber,
                        pageSize: result.pageSize,
                    },
                    operatorListError: undefined,
                    selectedOperatorDetails: operator,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                const state = context.patchState({
                    operatorListError: error,
                    isProcessing: false,
                });

                return of(state);
            })
        );
    }

    @Action(OperatorActions.SelectOperator)
    public selectOperator(context: StateContext<OperatorStateModel>, action: OperatorActions.SelectOperator) {
        context.patchState({ isProcessing: true });

        return this.operatorApi.getOperatorDetails(action.operatorId, action.operatorType).pipe(
            tap((result: OperatorDetails) => {
                context.patchState({
                    selectedOperatorDetails: result,
                    operatorError: undefined,
                });
            }),
            catchError((operatorError) => {
                context.patchState({
                    operatorError,
                    selectedOperatorDetails: undefined,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorActions.ClearOperatorDetails)
    public clearOperatorDetails(context: StateContext<OperatorStateModel>) {
        context.patchState({
            selectedOperatorDetails: undefined,
            operatorError: undefined,
        });
    }

    @Action(OperatorActions.GetOperatorsAvailableOperationScenarios)
    public getOperatorsAvailableOperationScenarios(
        context: StateContext<OperatorStateModel>,
        action: OperatorActions.GetOperatorsAvailableOperationScenarios
    ) {
        return this.operatorApi.getAvailableOperationScenarios(action.operatorId).pipe(
            tap((result: OperationScenario[]) => {
                context.patchState({
                    operatorAvailableOperationScenarios: result,
                    operatorAvailableOperationScenariosError: undefined,
                });
            }),
            catchError((error) => {
                const state = context.patchState({
                    operatorAvailableOperationScenarios: undefined,
                    operatorAvailableOperationScenariosError: error,
                });

                return of(state);
            })
        );
    }

    @Action(OperatorActions.ChangeOperatorStatus)
    public changeOperatorStatus(context: StateContext<OperatorStateModel>, action: OperatorActions.ChangeOperatorStatus) {
        context.patchState({
            changeStatusError: undefined,
            isProcessing: true,
        });

        return this.operatorApi.changeOperatorStatus(action.statusChange).pipe(
            tap(() =>
                context.dispatch(new OperatorActions.SelectOperator(action.statusChange.operator.id, action.statusChange.operator.type))
            ),
            catchError((error) => {
                context.patchState({
                    changeStatusError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorActions.AddPilotsCompetency)
    public addPilotsCompetencyAndRefresh(context: StateContext<OperatorStateModel>, action: OperatorActions.AddPilotsCompetency) {
        return this.pilotApi.addPilotCompetency(action.newOperationalAuthorization, action.pilotId).pipe(
            tap(() => context.patchState({ pilotCompetencyUpdateError: undefined })),
            catchError((error) => {
                context.patchState({
                    pilotCompetencyUpdateError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorActions.AddOperationalAuthorization)
    public addOperationalAuthorizationAndRefresh(
        context: StateContext<OperatorStateModel>,
        action: OperatorActions.AddOperationalAuthorization
    ) {
        return this.operatorApi.addOperationalAuthorization(action.operatorId, action.newOperationalAuthorizations).pipe(
            tap(() => context.patchState({ operationalAuthorizationsError: undefined })),
            catchError((error) => {
                const state = context.patchState({
                    operationalAuthorizationsError: error,
                });

                return of(state);
            })
        );
    }

    @Action(OperatorActions.ChangeOperationalAuthorizationStatus)
    public changeOperationalAuthorizationStatusAndRefresh(
        context: StateContext<OperatorStateModel>,
        action: OperatorActions.ChangeOperationalAuthorizationStatus
    ) {
        return this.operatorApi.changeOperationalAuthorization(action.operatorId, action.statusChange).pipe(
            tap(() => context.patchState({ operationalAuthorizationsError: undefined })),
            catchError((error) => {
                context.patchState({
                    operationalAuthorizationsError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorActions.ChangePilotCompetencyStatus)
    public changePilotCompetencyStatusAndRefresh(
        context: StateContext<OperatorStateModel>,
        action: OperatorActions.ChangePilotCompetencyStatus
    ) {
        return this.pilotApi.changePilotCompetencyStatus(action.pilotId, action.statusChange).pipe(
            tap(() => context.patchState({ pilotCompetencyUpdateError: undefined })),
            catchError((error) => {
                context.patchState({
                    pilotCompetencyUpdateError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorActions.SuspendAllPilotCompetencies)
    public suspendAllPilotCompetenciesAndRefresh(
        context: StateContext<OperatorStateModel>,
        action: OperatorActions.SuspendAllPilotCompetencies
    ) {
        return this.pilotApi.suspendAllPilotCompetencies(action.pilotId, action.statusChange).pipe(
            tap(() => context.patchState({ pilotCompetenciesSuspendAllError: undefined })),
            catchError((error) => {
                context.patchState({
                    pilotCompetenciesSuspendAllError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorActions.RemoveOperationalAuthorization)
    public removeOperationalAuthorizationAndRefresh(
        context: StateContext<OperatorStateModel>,
        action: OperatorActions.RemoveOperationalAuthorization
    ) {
        return this.operatorApi.removeOperationalAuthorization(action.operatorId, action.operationalAuthorizationId).pipe(
            tap(() => context.patchState({ operationalAuthorizationsError: undefined })),
            catchError((error) => {
                const state = context.patchState({
                    operationalAuthorizationsError: error,
                });

                return of(state);
            })
        );
    }

    @Action(OperatorActions.RemovePilotCompetency)
    public removePilotCompetencyAndRefresh(context: StateContext<OperatorStateModel>, action: OperatorActions.RemovePilotCompetency) {
        return this.pilotApi.removeCompetency(action.pilotId, action.competencyId).pipe(
            tap(() => context.patchState({ pilotCompetencyUpdateError: undefined })),
            catchError((error) => {
                const state = context.patchState({
                    pilotCompetencyUpdateError: error,
                });

                return of(state);
            })
        );
    }

    @Action(OperatorActions.GetDocument)
    public getDocument(context: StateContext<OperatorStateModel>, action: OperatorActions.GetDocument) {
        const attachmentName = action.attachment.documentFileName.toLowerCase();
        const attachmentExtension = attachmentName.slice(attachmentName.lastIndexOf(".") + 1);

        const handledExtension = ALLOWED_FILE_EXTENSIONS_TO_DISPLAY[attachmentExtension];

        if (handledExtension) {
            return this.operatorApi.getFile(action.operatorId, action.attachment.documentId, action.documentType).pipe(
                tap((response: Blob) => {
                    const file = new Blob([response], { type: handledExtension });
                    const fileURL = URL.createObjectURL(file);
                    this.document.open(fileURL, action.attachment.documentFileName, "");
                })
            );
        } else {
            return this.operatorApi
                .getFile(action.operatorId, action.attachment.documentId, action.documentType)
                .pipe(tap((attachment: Blob) => saveAs(attachment, action.attachment.documentFileName)));
        }
    }

    @Action(OperatorActions.GetForeignCompetencyDocument)
    public getForeignCompetencyDocument(context: StateContext<OperatorStateModel>, action: OperatorActions.GetForeignCompetencyDocument) {
        return this.operatorApi.getForeignCompetencyDocument(action.competency);
    }

    @Action(OperatorActions.UpdateAttorneyPower)
    public updateAttorneyPower(context: StateContext<OperatorStateModel>, action: OperatorActions.UpdateAttorneyPower) {
        return this.operatorApi.updateAttorneyPower(action.operatorId, action.attorneyPowerUpdate).pipe(
            tap(() => context.patchState({ updateAttorneyPowerError: undefined })),
            catchError((error) => {
                context.patchState({ updateAttorneyPowerError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperatorActions.UpdateAttorneyPowerPermissions)
    public updateAttorneyPowerPermissions(
        context: StateContext<OperatorStateModel>,
        action: OperatorActions.UpdateAttorneyPowerPermissions
    ) {
        return this.operatorApi.updateAttorneyPowerPermissions(action.operatorId, action.attorneyPowerId, action.permissions).pipe(
            tap(() => context.patchState({ updateAttorneyPowerError: undefined })),
            catchError((error) => {
                context.patchState({ updateAttorneyPowerError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperatorActions.AddOtherInformation)
    public addOtherInformation(context: StateContext<OperatorStateModel>, action: OperatorActions.AddOtherInformation) {
        context.patchState({ isProcessing: true, addOtherInformationError: undefined });

        return this.operatorApi.addOtherInformation(action.operatorId, action.text).pipe(
            catchError((error) => {
                context.patchState({ addOtherInformationError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorActions.EditOtherInformation)
    public editOtherInformation(context: StateContext<OperatorStateModel>, action: OperatorActions.EditOtherInformation) {
        context.patchState({ isProcessing: true, editOtherInformationError: undefined });

        return this.operatorApi.editOtherInformation(action.operatorId, action.otherInformationId, action.text).pipe(
            catchError((error) => {
                context.patchState({ editOtherInformationError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorActions.DeleteOtherInformation)
    public deleteOtherInformation(context: StateContext<OperatorStateModel>, action: OperatorActions.DeleteOtherInformation) {
        context.patchState({ isProcessing: true, deleteOtherInformationError: undefined });

        return this.operatorApi.deleteOtherInformation(action.operatorId, action.otherInformationId).pipe(
            catchError((error) => {
                context.patchState({ deleteOtherInformationError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorActions.DeleteOperator)
    public deleteOperator(context: StateContext<OperatorStateModel>, action: OperatorActions.DeleteOperator) {
        context.patchState({ isProcessing: true, deleteOperatorError: undefined });

        return this.operatorApi.deleteOperator(action.operatorId).pipe(
            catchError((error) => {
                context.patchState({ deleteOperatorError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorActions.UpdateForeignCompetencyStatus)
    public updateForeignCompetencyStatus(context: StateContext<OperatorStateModel>, action: OperatorActions.UpdateForeignCompetencyStatus) {
        context.patchState({ isProcessing: true, updateForeignCompetencyStatusError: undefined });

        return this.operatorApi.updateForeignCompetencyStatus(action.pilotId, action.competencyId, action.status, action.statusReason).pipe(
            catchError((error) => {
                context.patchState({ updateForeignCompetencyStatusError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    private getSelectedOperatorFromList(
        operatorList: Operator[],
        selectedOperator: OperatorDetails | undefined
    ): OperatorDetails | undefined {
        return operatorList.some((operator: Operator) => operator.id === selectedOperator?.id) ? selectedOperator : undefined;
    }
}
