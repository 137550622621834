import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { OperatorAndPilotQualification, QualificationStatus } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { QualificationStatusChange, QualificationType } from "../../../models/operator.models";

interface QualificationsComponentState {
    qualifications: OperatorAndPilotQualification[];
    suspendedQualifications: OperatorAndPilotQualification[];
    titleTile: string | undefined;
    qualificationType: QualificationType | undefined;
    areActionsAvailable: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-qualifications[qualifications][titleTile][qualificationType]",
    templateUrl: "./qualifications.component.html",
    styleUrls: ["./qualifications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class QualificationsComponent {
    @Input() public set qualifications(value: OperatorAndPilotQualification[] | undefined) {
        const suspendedQualifications = value
            ? value.filter((qualification) => qualification.status === QualificationStatus.Suspended)
            : [];
        this.localStore.patchState({
            qualifications: value ?? [],
            suspendedQualifications,
        });
    }
    @Input() public set titleTile(value: string) {
        this.localStore.patchState({ titleTile: value });
    }
    @Input() public set qualificationType(value: QualificationType) {
        this.localStore.patchState({ qualificationType: value });
    }
    @Input() public set areActionsAvailable(value: boolean | undefined) {
        this.localStore.patchState({ areActionsAvailable: !!value });
    }

    @Output() public qualificationAdd = new EventEmitter<void>();
    @Output() public qualificationManage = new EventEmitter<QualificationStatusChange>();
    @Output() public qualificationRemove = new EventEmitter<string>();
    @Output() public qualificationsSuspendAll = new EventEmitter<void>();

    protected readonly qualifications$ = this.localStore.selectByKey("qualifications").pipe(RxjsUtils.filterFalsy());
    protected readonly suspendedQualifications$ = this.localStore.selectByKey("suspendedQualifications");
    protected readonly titleTile$ = this.localStore.selectByKey("titleTile").pipe(RxjsUtils.filterFalsy());
    protected readonly areActionsAvailable$ = this.localStore.selectByKey("areActionsAvailable");
    protected readonly qualificationType$ = this.localStore.selectByKey("qualificationType").pipe(RxjsUtils.filterFalsy());
    protected readonly QualificationStatus = QualificationStatus;
    protected readonly QualificationType = QualificationType;

    constructor(private readonly localStore: LocalComponentStore<QualificationsComponentState>) {
        localStore.setState({
            qualifications: [],
            suspendedQualifications: [],
            titleTile: undefined,
            qualificationType: undefined,
            areActionsAvailable: true,
        });
    }

    public async changeQualificationStatus(qualificationId: string, status: QualificationStatus) {
        this.qualificationManage.emit({
            qualificationId,
            status,
        });
    }
}
