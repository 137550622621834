import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { CentersFilterFormKeys, CentersTabType } from "../../models/centers.models";

interface CentersHeaderComponentState {
    activeTabIndex: CentersTabType | undefined;
}

@Component({
    selector: "dtm-admin-lib-centers-header",
    templateUrl: "./centers-header.component.html",
    styleUrls: ["./centers-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CentersHeaderComponent {
    @Input() public set activeTabIndex(value: CentersTabType | undefined) {
        this.localStore.patchState({ activeTabIndex: value });
    }
    @Output() public readonly tabIndexUpdate: EventEmitter<CentersTabType> = new EventEmitter<CentersTabType>();
    @Output() public readonly addCenterOpen = new EventEmitter<void>();

    protected readonly activeTabIndex$ = this.localStore.selectByKey(CentersFilterFormKeys.ActiveTabIndex);

    constructor(private readonly localStore: LocalComponentStore<CentersHeaderComponentState>) {
        this.localStore.setState({
            activeTabIndex: undefined,
        });
    }

    protected onUpdateTabIndex(activeTabIndex: CentersTabType): void {
        this.localStore.patchState({ activeTabIndex });
        this.tabIndexUpdate.next(activeTabIndex);
    }
}
