<ng-container *ngrxLet="activeTabIndex$ as activeTab">
    <dtm-ui-card-header [isEditButtonVisible]="false">
        {{ "dtmAdminLibCenters.header.title" | transloco : { value: activeTab } }}

        <ng-container additionalActions>
            <button type="button" class="button-secondary" (click)="addCenterOpen.next()">
                <dtm-ui-icon name="add"></dtm-ui-icon>

                {{ "dtmAdminLibCenters.header.addDataButtonLabel" | transloco : { value: activeTab } }}
            </button>
        </ng-container>
    </dtm-ui-card-header>

    <mat-tab-group
        [selectedIndex]="activeTab"
        (selectedIndexChange)="onUpdateTabIndex($event)"
        class="dtm-tabs"
        color="accent"
        dynamicHeight
    >
        <mat-tab [label]="'dtmAdminLibCenters.tabs.examinationEntities' | transloco">
            <ng-content select="[examinationList]"></ng-content>
        </mat-tab>
        <mat-tab [label]="'dtmAdminLibCenters.tabs.trainingOperators' | transloco">
            <ng-content select="[trainingList]"></ng-content>
        </mat-tab>
    </mat-tab-group>
</ng-container>
