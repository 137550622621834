<div
    class="dtm-tabs"
    *ngrxLet="{
        guideChapterItems: guideChapterItems$,
        highlightedChapterId: highlightedChapterId$,
        sailRequirementsItems: sailRequirementsItems$,
        selectedSail: selectedSail$,
        isGuidePanelVisible: isGuidePanelVisible$
    } as vm"
>
    <mat-tab-group
        (selectedTabChange)="changeTab($event)"
        [selectedIndex]="selectedTab$ | ngrxPush"
        dynamicHeight
        color="accent"
        *ngIf="vm.isGuidePanelVisible"
    >
        <mat-tab [label]="'dtmWebAppLibShared.operationsManualGuide.chaptersTab.tabLabel' | transloco">
            <div class="tab-container">
                <ul class="guide-list" *ngIf="vm.guideChapterItems.length; else emptyGuideChaptersListTemplate">
                    <li
                        *ngFor="let item of vm.guideChapterItems"
                        class="guide-item"
                        [class.highlighted]="item.chapterId === vm.highlightedChapterId"
                    >
                        <h2 class="title">{{ item.title }}</h2>
                        <div class="content" [innerHTML]="item.content"></div>
                    </li>
                </ul>
            </div>
            <ng-template #emptyGuideChaptersListTemplate>
                <div class="tab-container">
                    <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
                        <ng-container titleSlot>
                            {{ "dtmWebAppLibShared.operationsManualGuide.chaptersTab.emptyStateTitle" | transloco }}
                        </ng-container>
                        <ng-container messageSlot>
                            {{ "dtmWebAppLibShared.operationsManualGuide.chaptersTab.emptyStateMessage" | transloco }}
                        </ng-container>
                    </dtm-ui-empty-state>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab [label]="'dtmWebAppLibShared.operationsManualGuide.requirementsTab.tabLabel' | transloco">
            <div class="tab-container">
                <dtm-ui-select-field
                    [placeholder]="'dtmWebAppLibShared.operationsManualGuide.requirementsTab.sailSelectorPlaceholderLabel' | transloco"
                    [value]="vm.selectedSail"
                    (selectionChange)="filterRequirementsBySail($event.value)"
                    [isClearable]="false"
                >
                    <dtm-ui-select-option [value]="sail" *ngFor="let sail of sailLevels">
                        {{ sail | sailLevel }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field>
                <ul class="guide-list" *ngIf="vm.sailRequirementsItems?.length; else emptySailRequirementsListTemplate">
                    <li *ngFor="let item of vm.sailRequirementsItems" class="guide-item">
                        <h2 class="title">{{ item.titleKey | transloco }}</h2>
                        <ul class="chips-list" *ngIf="item.osoItems.length">
                            <li *ngFor="let oso of item.osoItems" class="chip">
                                {{
                                    "dtmWebAppLibShared.operationsManualGuide.requirementsTab.osoLabel"
                                        | transloco : { number: oso.number, level: oso.level }
                                }}
                            </li>
                        </ul>
                        <div class="content" [innerHTML]="item.contentKey | transloco"></div>
                    </li>
                </ul>
                <ng-template #emptySailRequirementsListTemplate>
                    <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
                        <ng-container titleSlot>
                            {{ "dtmWebAppLibShared.operationsManualGuide.requirementsTab.emptyStateTitle" | transloco }}
                        </ng-container>
                        <ng-container messageSlot *ngIf="vm.sailRequirementsItems">
                            {{ "dtmWebAppLibShared.operationsManualGuide.requirementsTab.emptyStateMessage" | transloco }}
                        </ng-container>
                        <ng-container messageSlot *ngIf="!vm.sailRequirementsItems">
                            {{ "dtmWebAppLibShared.operationsManualGuide.requirementsTab.sailNotSelectedEmptyStateMessage" | transloco }}
                        </ng-container>
                    </dtm-ui-empty-state>
                </ng-template>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
