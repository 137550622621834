import { HttpParams } from "@angular/common/http";
import { OperationScenarioCategory, PageResponseBody, QualificationStatus } from "@dtm-frontend/shared/ui";
import { ExamResult, ExamResultListWithPages } from "../models/exam-results.models";
import {
    ExamTrainingResultsFilterFormKeys,
    ExamTrainingResultsFilterParams,
    ExamTrainingResultsReportDetails,
    ExamTrainingResultsReportDetailsError,
    ExamTrainingResultsReportOperatorDetails,
} from "../models/exam-training-results.models";
import { TrainingResult, TrainingResultListWithPages } from "../models/training-results.models";

export type GetExamResultsListResponseBody = PageResponseBody<ExamResult>;

export interface ExamTrainingResultReportOperatorCompetenciesResponseBody {
    name: string;
    category: OperationScenarioCategory;
    status: QualificationStatus;
    statusReason?: string;
    expirationDate: string;
}
export interface ExamTrainingResultReportOperatorEvaluatedCompetenciesResponseBody {
    name: string;
    category: OperationScenarioCategory;
    expirationDate: string;
}

export interface ExamTrainingResultReportTrainingsResponseBody {
    trainingNativeName: string;
    trainingEnglishName: string;
    startDate: string;
    endDate: string;
    trainingOperatorName: string;
    identificationNumber: string;
}

export interface ExamTrainingResultReportExamsResponseBody {
    examNativeName: string;
    examEnglishName: string;
    passDate: string;
}

interface ExamTrainingResultReportOperatorResponseBody {
    name: string;
    email: string;
    operatorNumber: string;
    trainings?: ExamTrainingResultReportTrainingsResponseBody[];
    exams?: ExamTrainingResultReportExamsResponseBody[];
    currentCompetencies?: ExamTrainingResultReportOperatorCompetenciesResponseBody[];
    evaluatedCompetencies?: ExamTrainingResultReportOperatorEvaluatedCompetenciesResponseBody[];
}

export interface ExamTrainingResultsReportDetailsResponseBody {
    status: string;
    institution: {
        name: string;
        identificationNumber: string;
    };
    operators: ExamTrainingResultReportOperatorResponseBody[];
    errors: ExamTrainingResultsReportDetailsError[] | undefined;
}

export function convertGetExamResultListResponseBodyToResultListWithPages(
    response: GetExamResultsListResponseBody
): ExamResultListWithPages {
    return {
        content: response.content,
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export type GetTrainingResultsListResponseBody = PageResponseBody<TrainingResult>;

export function convertGetTrainingResultListResponseBodyToResultListWithPages(
    response: GetTrainingResultsListResponseBody
): TrainingResultListWithPages {
    return {
        content: response.content,
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export function getExamTrainingResultsParams(filters: ExamTrainingResultsFilterParams): HttpParams {
    let params = new HttpParams().set("page", `${filters?.page ?? 0}`);

    if (filters.size) {
        params = params.append("size", filters.size);
    }

    const searchPhrase = filters[ExamTrainingResultsFilterFormKeys.SearchPhrase];

    if (searchPhrase) {
        params = params.append(ExamTrainingResultsFilterFormKeys.SearchPhrase, searchPhrase);
    }

    return params;
}

function convertExamTrainingResultReportOperatorResponseBodyToExamTrainingResultsReportOperatorDetails(
    operators: ExamTrainingResultReportOperatorResponseBody[]
): ExamTrainingResultsReportOperatorDetails[] {
    return operators.map((operator) => ({
        name: operator.name,
        email: operator.email,
        operatorNumber: operator.operatorNumber,
        trainings: operator.trainings?.map((training) => ({
            trainingNativeName: training.trainingNativeName,
            trainingEnglishName: training.trainingEnglishName,
            startDate: new Date(training.startDate),
            endDate: new Date(training.endDate),
            trainingOperatorName: training.trainingOperatorName,
            identificationNumber: training.identificationNumber,
        })),
        exams: operator.exams?.map((exam) => ({
            examNativeName: exam.examNativeName,
            examEnglishName: exam.examEnglishName,
            passDate: exam.passDate ? new Date(exam.passDate) : new Date(),
        })),
        currentCompetencies:
            operator.currentCompetencies?.map((competency) => ({
                name: competency.name,
                category: competency.category,
                status: competency.status,
                statusReason: competency.statusReason,
                expirationDate: new Date(competency.expirationDate),
            })) ?? [],
        evaluatedCompetencies:
            operator.evaluatedCompetencies?.map((competency) => ({
                name: competency.name,
                category: competency.category,
                expirationDate: new Date(competency.expirationDate),
            })) ?? [],
    }));
}

export function convertExamTrainingResultReportDetailsResponseBodyToExamTrainingResultsReportDetails(
    result: ExamTrainingResultsReportDetailsResponseBody
): ExamTrainingResultsReportDetails {
    return {
        status: result.status,
        institution: result.institution,
        operators: convertExamTrainingResultReportOperatorResponseBodyToExamTrainingResultsReportOperatorDetails(result.operators),
        errors: result.errors,
    };
}
