<dtm-ui-loader-container
    *ngrxLet="{ isResultsListProcessing: isResultsListProcessing$, activeTabIndex: activeTabIndex$, resultsList: resultsList$ } as vm"
    [isShown]="vm.isResultsListProcessing"
>
    <table mat-table [dataSource]="vm.resultsList" multiTemplateDataRows class="dtm-table">
        <ng-container [matColumnDef]="displayedColumns[0]">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibExamTrainingResults.list.nameLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let result">{{ result.name }}</td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[1]">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibExamTrainingResults.list.operatorNumberLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let result">{{ result.operatorNumber }}</td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[2]">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibExamTrainingResults.list.examTrainingNameLabel" | transloco : { value: vm.activeTabIndex } }}
            </th>
            <td mat-cell *matCellDef="let result">
                {{ vm.activeTabIndex === ExamTrainingResultsTabType.Trainings ? result.trainingEnglishName : result.examEnglishName }}
            </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[3]">
            <th mat-header-cell *matHeaderCellDef>
                {{
                    (vm.activeTabIndex === ExamTrainingResultsTabType.Trainings
                        ? "dtmAdminLibExamTrainingResults.list.trainingDurationLabel"
                        : "dtmAdminLibExamTrainingResults.list.passDateLabel"
                    ) | transloco
                }}
            </th>
            <td mat-cell *matCellDef="let result">
                <ng-container *ngIf="vm.activeTabIndex === ExamTrainingResultsTabType.Trainings">
                    {{ result.startDate | localizeDate }} - {{ result.endDate | localizeDate }}
                </ng-container>
                <ng-container *ngIf="vm.activeTabIndex === ExamTrainingResultsTabType.Exams">{{
                    result.passDate | localizeDate
                }}</ng-container>
            </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[4]">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmAdminLibExamTrainingResults.list.modeLabel" | transloco : { value: vm.activeTabIndex } }}
            </th>
            <td mat-cell *matCellDef="let result">
                <div class="mode-container">
                    <ng-container *ngIf="vm.activeTabIndex === ExamTrainingResultsTabType.Trainings; else examsTemplate">
                        <dtm-ui-icon [name]="result.trainingMode === ExamTrainingResultsMode.Stationary ? 'home' : 'global'"> </dtm-ui-icon>
                        <span class="mode">{{
                            "dtmAdminLibExamTrainingResults.list.modeValueLabel" | transloco : { value: result.trainingMode }
                        }}</span>
                    </ng-container>

                    <ng-template #examsTemplate>
                        <dtm-ui-icon [name]="result.examMode === ExamTrainingResultsMode.Stationary ? 'home' : 'global'"> </dtm-ui-icon>
                        <span class="mode">{{
                            "dtmAdminLibExamTrainingResults.list.modeValueLabel" | transloco : { value: result.examMode }
                        }}</span>
                    </ng-template>
                </div>
            </td>
        </ng-container>

        <ng-container [matColumnDef]="displayedColumns[5]">
            <th mat-header-cell *matHeaderCellDef>
                {{
                    (vm.activeTabIndex === ExamTrainingResultsTabType.Trainings
                        ? "dtmAdminLibExamTrainingResults.list.trainingOperatorLabel"
                        : "dtmAdminLibExamTrainingResults.list.examEntityLabel"
                    ) | transloco
                }}
            </th>
            <td mat-cell *matCellDef="let result">
                {{ vm.activeTabIndex === ExamTrainingResultsTabType.Trainings ? result.trainingOperatorName : result.examiningCenterName }}
            </td>
        </ng-container>
        <ng-container [matColumnDef]="displayedColumns[6]">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibExamTrainingResults.list.actionsLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let result">
                <ng-container *ngIf="result | invoke : areResultsStationary">
                    <button [matMenuTriggerFor]="actions" class="button-icon" type="button" (click)="$event.stopPropagation()">
                        <dtm-ui-icon name="more"></dtm-ui-icon>
                    </button>
                    <mat-menu #actions="matMenu">
                        <button mat-menu-item type="button" (click)="delete(result.id)">
                            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                            <span>{{ "dtmAdminLibExamTrainingResults.list.removeButtonLabel" | transloco }}</span>
                        </button>
                    </mat-menu>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-error
                    *ngIf="resultsListError$ | ngrxPush; else emptyListTemplate"
                    [mode]="ErrorMode.Secondary"
                    (reload)="resultsListRefresh.emit()"
                >
                </dtm-ui-error>
                <ng-template #emptyListTemplate>
                    <dtm-ui-no-results></dtm-ui-no-results>
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
                </dtm-ui-pagination>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-footer-row
            *matFooterRowDef="['noResults']"
            [class.hide-footer]="vm.resultsList.length || vm.isResultsListProcessing"
            class="no-results-row"
        >
            <dtm-ui-no-results></dtm-ui-no-results>
        </tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!vm.resultsList.length" class="pagination-row"></tr>
    </table>
</dtm-ui-loader-container>
