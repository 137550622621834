import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SpecificPermitFlightWizardSteps } from "@dtm-frontend/shared/ui";
import { SPECIFIC_PERMIT_FLIGHT_WIZARD_ID } from "../specific-permit-flight-wizard.models";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "dtm-admin-lib-specific-permit-flight-request-header",
    templateUrl: "./specific-permit-flight-request-header.component.html",
    styleUrls: ["./specific-permit-flight-request-header.component.scss"],
})
export class SpecificPermitFlightRequestHeaderComponent {
    protected readonly SPECIFIC_PERMIT_FLIGHT_WIZARD_ID = SPECIFIC_PERMIT_FLIGHT_WIZARD_ID;
    protected readonly SpecificPermitFlightWizardSteps = SpecificPermitFlightWizardSteps;
}
