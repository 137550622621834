import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_COUNTRY_CODE, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { BaseOperator } from "../../../shared/models/operator.models";
import { CentersTabType } from "../../models/centers.models";
import { TrainingCenter, TrainingCenterDialogData } from "../../models/training-centers.models";
import { CentersActions } from "../../state/centers.actions";
import { CentersState } from "../../state/centers.state";
import { MAX_IDENTIFICATION_NUMBER_LENGTH, MAX_TEXT_LENGTH } from "../../utils/form-validators-settings";

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-centers-training-centers-add-form-dialog",
    templateUrl: "./training-centers-add-form-dialog.component.html",
    styleUrls: ["./training-centers-add-form-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TrainingCentersAddFormDialogComponent implements OnInit {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    private readonly newValueSubject = new Subject<TrainingCenter>();
    public readonly newValue$ = this.newValueSubject.asObservable();
    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly CentersTabType = CentersTabType;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    protected readonly operatorsList$ = this.store.select(CentersState.operatorsList);
    protected readonly operatorDetails$ = this.store.select(CentersState.operatorDetails);
    protected readonly isTrainingCenterProcessing$ = this.store.select(CentersState.isTrainingCenterProcessing);

    protected readonly operatorControl = new FormControl<BaseOperator | undefined>(undefined, {
        validators: [Validators.required],
        nonNullable: true,
    });
    protected readonly nameControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(MAX_TEXT_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly identificationNumberControl = new FormControl<string>("", {
        validators: [
            Validators.required,
            Validators.maxLength(MAX_IDENTIFICATION_NUMBER_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ],
        nonNullable: true,
    });
    protected readonly administrativeDecisionNumberControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(MAX_TEXT_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly trainingCodesControl = new FormControl<string[]>([], {
        nonNullable: true,
        validators: Validators.required,
    });
    protected readonly validUntilControl = new FormControl<Date | null>(null, {
        validators: [Validators.required],
    });
    protected readonly trainingCenterForm = new FormGroup({
        operator: this.operatorControl,
        name: this.nameControl,
        identificationNumber: this.identificationNumberControl,
        administrativeDecisionNumber: this.administrativeDecisionNumberControl,
        trainingCodes: this.trainingCodesControl,
        validUntil: this.validUntilControl,
    });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: TrainingCenterDialogData,
        private readonly store: Store,
        private readonly translocoHelper: TranslationHelperService
    ) {
        if (!data.center) {
            this.store.dispatch(new CentersActions.GetOperatorsList(""));

            return;
        }

        const { operatorId, operatorNumber, name, identificationNumber, administrativeDecisionNumber, trainingCodes, validUntil } =
            data.center;
        const operator: BaseOperator = { id: operatorId as string, name, number: operatorNumber as string };
        this.trainingCenterForm.setValue({
            operator,
            name,
            identificationNumber: identificationNumber as string,
            administrativeDecisionNumber,
            trainingCodes,
            validUntil,
        });

        this.store.dispatch(new CentersActions.GetOperatorDetails(operatorId as string));
    }

    protected save() {
        this.trainingCenterForm.markAllAsTouched();
        if (this.trainingCenterForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        const { operator, name, identificationNumber, administrativeDecisionNumber, trainingCodes, validUntil } =
            this.trainingCenterForm.getRawValue();

        this.newValueSubject.next({
            id: this.data.center?.id,
            operatorId: operator?.id,
            name,
            identificationNumber,
            administrativeDecisionNumber,
            trainingCodes,
            validUntil: validUntil as Date,
        });
    }

    protected removeTrainingCode(trainingCode: string) {
        this.trainingCodesControl.setValue(this.trainingCodesControl.value.filter((value) => value !== trainingCode));
    }

    protected searchTextChange(searchPhrase: string) {
        this.store.dispatch(new CentersActions.GetOperatorsList(searchPhrase ?? ""));
    }

    public ngOnInit(): void {
        this.operatorControl.valueChanges
            .pipe(
                filter((operator) => !!operator),
                untilDestroyed(this)
            )
            .subscribe((operator) => this.store.dispatch(new CentersActions.GetOperatorDetails(operator?.id as string)));
    }
}
