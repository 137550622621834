<div class="form-wrapper" [formGroup]="groundRiskMitigationForm">
    <dtm-ui-select-field
        formControlName="strategicMitigationMeasure"
        [placeholder]="'dtmAdminLibPermits.groundRiskMitigation.chooseLabel' | transloco"
    >
        <label>{{
            "dtmAdminLibPermits.groundRiskMitigation.strategicMitigationMeasuresLabel"
                | transloco : { riskMethodology: riskMethodology$ | ngrxPush }
        }}</label>
        <dtm-ui-select-option [value]="risk.value" *ngFor="let risk of GroundRiskMitigationType | keyvalue : keepOrder">
            {{ "dtmAdminLibPermits.groundRiskMitigation.groundRiskMitigationMeasureValueLabel" | transloco : { value: risk.value } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="groundRiskMitigationForm.controls.strategicMitigationMeasure; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        *ngIf="groundRiskMitigationForm.controls.avoidingFlightsOverPeople.enabled"
        @slideIn
        formControlName="avoidingFlightsOverPeople"
        [placeholder]="'dtmAdminLibPermits.groundRiskMitigation.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.groundRiskMitigation.avoidingFlightsOverPeopleLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="risk.value" *ngFor="let risk of AvoidingFlightsOverPeopleType | keyvalue : keepOrder">
            {{ "dtmAdminLibPermits.groundRiskMitigation.avoidingFlightsOverPeopleValueLabel" | transloco : { value: risk.value } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="groundRiskMitigationForm.controls.avoidingFlightsOverPeople; name: 'required'"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <dtm-ui-select-field
        *ngIf="groundRiskMitigationForm.controls.emergencyResponsePlan.enabled"
        @slideIn
        formControlName="emergencyResponsePlan"
        [placeholder]="'dtmAdminLibPermits.groundRiskMitigation.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.groundRiskMitigation.emergencyResponsePlanLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="risk.value" *ngFor="let risk of GroundRiskMitigationType | keyvalue : keepOrder">
            {{ "dtmAdminLibPermits.groundRiskMitigation.groundRiskMitigationMeasureValueLabel" | transloco : { value: risk.value } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="groundRiskMitigationForm.controls.emergencyResponsePlan; name: 'required'"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
</div>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibPermits.groundRiskMitigation.requiredField" | transloco }}
</ng-template>
