<ng-container *ngrxLet="{ selectedContext: selectedContext$, isPilot: isPilot$ } as vm">
    <button
        type="button"
        class="user-button selected-user"
        [matMenuTriggerFor]="operatorDropdownMenu"
        [attr.aria-label]="'dtmWebAppLibShared.userContext.operatorMenuButtonAriaLabel' | transloco"
    >
        <ng-template
            [ngTemplateOutlet]="userButtonTemplate"
            [ngTemplateOutletContext]="{ operator: vm.selectedContext, isSelectedContext: true, isShortMode: isShortMode$ | ngrxPush }"
        ></ng-template>
    </button>

    <mat-menu #operatorDropdownMenu="matMenu" xPosition="before">
        <div class="contexts-list" *ngrxLet="operatorContexts$ as operatorContexts">
            <button
                type="button"
                class="user-button"
                [class.current-user]="operator.id === vm.selectedContext.id"
                [attr.aria-label]="
                    'dtmWebAppLibShared.userContext.operatorSelectButtonAriaLabel' | transloco : { operatorName: operator.name }
                "
                mat-menu-item
                (click)="selectOperatorContext(operator)"
                *ngFor="let operator of operatorContexts"
            >
                <ng-template [ngTemplateOutlet]="userButtonTemplate" [ngTemplateOutletContext]="{ operator: operator }"></ng-template>
            </button>
        </div>
        <button mat-menu-item class="separated action" type="button" (click)="redirect.emit('/register/operator')">
            <dtm-ui-icon name="building"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibShared.userContext.addOperatorLabel" | transloco }}</span>
        </button>
        <button *ngIf="canRegisterPilot$ | ngrxPush" mat-menu-item class="action" type="button" (click)="redirect.emit('/register/pilot')">
            <dtm-ui-icon name="gamepad"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibShared.userContext.addPilotLabel" | transloco }}</span>
        </button>
        <button mat-menu-item class="action" type="button" (click)="redirect.emit('/operator-migration')">
            <dtm-ui-icon name="download"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibShared.userContext.operatorMigrationLabel" | transloco }}</span>
        </button>
        <button mat-menu-item class="action" type="button" (click)="redirect.emit('/user')">
            <dtm-ui-icon name="settings"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibShared.userContext.accountSettingsLabel" | transloco }}</span>
        </button>
        <button mat-menu-item class="action" type="button" (click)="redirect.emit('/help-center')">
            <dtm-ui-icon name="lifebuoy"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibShared.userContext.helpCenterLabel" | transloco }}</span>
        </button>
        <button mat-menu-item class="separated action" type="button" (click)="logout.emit()">
            <dtm-ui-icon name="logout-circle"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibShared.userContext.logoutLabel" | transloco }}</span>
        </button>
    </mat-menu>

    <ng-template #userButtonTemplate let-operator="operator" let-isSelectedContext="isSelectedContext" let-isShortMode="isShortMode">
        <dtm-ui-avatar
            [label]="
                isShortMode && isSelectedContext
                    ? undefined
                    : vm.isPilot && operator.type === OperatorType.Personal
                    ? ('dtmWebAppLibShared.userContext.pilotLabel' | transloco)
                    : ('dtmWebAppLibShared.userContext.operatorLabel' | transloco)
            "
            [fullName]="isShortMode && isSelectedContext ? undefined : operator.name"
            [sourceUrl]="operator.avatarUrl"
        ></dtm-ui-avatar>
        <dtm-ui-icon name="arrow-down" class="arrow-icon" *ngIf="isSelectedContext"></dtm-ui-icon>
    </ng-template>
</ng-container>
