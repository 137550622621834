import { OperatorStatus, OperatorType, PageResponseBody } from "@dtm-frontend/shared/ui";
import { OperationScenario, Operator } from "../../shared/models/operator.models";
import { OperatorListWithPages } from "./operator.models";

export type GetOperatorsListResponseBody = PageResponseBody<OperatorResponseBody>;

export interface OperatorResponseBody {
    id: string;
    status: OperatorStatus;
    operatorNumber: string;
    type: OperatorType;
    name: string;
}

export interface OperatorAvailableOperationScenarioResponseBody {
    id: string;
    name: string;
    category: string;
    description: string;
    expirationDate: string;
    grantValidityInMonths: number;
    national: boolean;
    price: number;
    uavsRequired: boolean;
}

function getOperatorFromOperatorResponseBody(operatorResponse: OperatorResponseBody): Operator {
    return {
        id: operatorResponse.id,
        status: operatorResponse.status,
        operatorNumber: operatorResponse.operatorNumber,
        type: operatorResponse.type,
        name: operatorResponse.name,
    };
}

export function convertGetOperatorsListResponseBodyToOperatorListWithPages(response: GetOperatorsListResponseBody): OperatorListWithPages {
    return {
        content: response.content.map((operator) => getOperatorFromOperatorResponseBody(operator)),
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export function convertGetOperatorAvailableOperationScenariosResponseBodyToOperationScenarios(
    response: OperatorAvailableOperationScenarioResponseBody[]
): OperationScenario[] {
    return response.map((operationScenario) => ({
        id: operationScenario.id,
        name: operationScenario.name,
        grantValidityInMonths: operationScenario.grantValidityInMonths,
        expirationDate: operationScenario.expirationDate,
        areUavsRequired: operationScenario.uavsRequired,
    }));
}
