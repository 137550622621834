import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ADMIN_USERS_ENDPOINTS, AdminUsersEndpoints } from "../admin-users.tokens";
import { AdminUserWithPages, transformGetAdminUsersPayloadToGetAdminUsersParams } from "./admin-users-api.converters";
import { AdminUsersError, AdminUsersErrorType, AdminUsersQueryParams, NewAdminUserValue } from "./models";

const CONFLICT_ERROR_CODE = "AlreadyUsed";
const PHONE_NUMBER_FIELD_NAME = "phoneNumber";
const EMAIL_FIELD_NAME = "email";

@Injectable({
    providedIn: "root",
})
export class AdminUsersApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(ADMIN_USERS_ENDPOINTS) private readonly endpoints: AdminUsersEndpoints) {}

    public getUsers(payload: AdminUsersQueryParams): Observable<AdminUserWithPages> {
        return this.httpClient
            .get<AdminUserWithPages>(this.endpoints.getOrAddUsers, { params: transformGetAdminUsersPayloadToGetAdminUsersParams(payload) })
            .pipe(
                catchError(() =>
                    throwError(() => ({
                        type: AdminUsersErrorType.Unknown,
                    }))
                )
            );
    }

    public addUser(payload: NewAdminUserValue) {
        return this.httpClient
            .post<NewAdminUserValue>(this.endpoints.getOrAddUsers, payload)
            .pipe(catchError((error) => throwError(() => this.getAddOrEditUserError(error))));
    }

    public editUser(payload: NewAdminUserValue, adminUserId: string) {
        return this.httpClient
            .put<NewAdminUserValue>(StringUtils.replaceInTemplate(this.endpoints.manageUser, { adminUserId }), payload)
            .pipe(catchError((error) => throwError(() => this.getAddOrEditUserError(error))));
    }

    public deleteUser(adminUserId: string) {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.manageUser, { adminUserId }))
            .pipe(catchError(() => throwError(() => ({ type: AdminUsersErrorType.Unknown }))));
    }

    public resetUsersPassword(adminUserId: string) {
        return this.httpClient
            .post<null>(StringUtils.replaceInTemplate(this.endpoints.resetPassword, { adminUserId }), null)
            .pipe(catchError(() => throwError(() => ({ type: AdminUsersErrorType.Unknown }))));
    }

    private getAddOrEditUserError(errorResponse: HttpErrorResponse): AdminUsersError {
        if (errorResponse.status === HttpStatusCode.Conflict) {
            const phoneConflict = errorResponse.error.fieldErrors.some(
                (errorField: any) => errorField.fieldName === PHONE_NUMBER_FIELD_NAME
            );
            const emailConflict = errorResponse.error.fieldErrors.some((errorField: any) => errorField.fieldName === EMAIL_FIELD_NAME);

            if (phoneConflict) {
                return { type: AdminUsersErrorType.PhoneNumberConflict };
            } else if (emailConflict) {
                return { type: AdminUsersErrorType.EmailConflict };
            }
        }

        return { type: AdminUsersErrorType.Unknown };
    }
}
