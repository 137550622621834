<ng-container *ngIf="profile$ | ngrxPush as profile">
    <dtm-ui-label-value
        *ngIf="profile.suspensionReason"
        [label]="'dtmAdminLibShared.operatorProfile.profileDetails.suspensionReasonLabel' | transloco"
    >
        <p class="suspension-reason">{{ profile.suspensionReason }}</p>
    </dtm-ui-label-value>

    <div class="info-container">
        <div>
            <dtm-ui-label-value
                [label]="'dtmAdminLibShared.operatorProfile.profileDetails.operatorNumberLabel' | transloco"
                [value]="profile.operatorNumber"
                [canCopy]="true"
            >
            </dtm-ui-label-value>
        </div>
        <div *ngIf="profile.secret">
            <dtm-ui-label-value
                *ngrxLet="isSecretNumberVisible$ as isSecretNumberVisible"
                [label]="'dtmAdminLibShared.operatorProfile.profileDetails.secretOperatorNumberLabel' | transloco"
            >
                <p class="value">
                    <ng-container *ngIf="!isSecretNumberVisible; else visibleSecretNumberTemplate">
                        <span>{{ secretMask$ | ngrxPush }}</span>
                        <button
                            class="button-icon value-icon"
                            type="button"
                            (click)="toggleSecretCodeVisibility()"
                            [matTooltip]="'dtmAdminLibShared.operatorProfile.profileDetails.showSecretOperatorNumberLabel' | transloco"
                        >
                            <dtm-ui-icon name="eye-off"></dtm-ui-icon>
                        </button>
                    </ng-container>

                    <ng-template #visibleSecretNumberTemplate>
                        <span>{{ profile.secret }}</span>
                        <button
                            class="button-icon value-icon"
                            type="button"
                            (click)="toggleSecretCodeVisibility()"
                            [matTooltip]="'dtmAdminLibShared.operatorProfile.profileDetails.hideSecretOperatorNumberLabel' | transloco"
                        >
                            <dtm-ui-icon name="eye"></dtm-ui-icon>
                        </button>
                    </ng-template>
                </p>
            </dtm-ui-label-value>
        </div>
    </div>
    <div class="info-container icons">
        <ng-container [ngSwitch]="profile.type">
            <div *ngSwitchCase="OperatorType.Enterprise" class="details-icon-with-info">
                <dtm-ui-icon name="building"></dtm-ui-icon>
                <p>{{ "dtmAdminLibShared.operatorProfile.operatorType.enterpriseLabel" | transloco }}</p>
            </div>
            <ng-container *ngSwitchCase="OperatorType.Personal" class="details-icon-with-info">
                <div class="details-icon-with-info">
                    <dtm-ui-icon name="user"></dtm-ui-icon>
                    <p>{{ "dtmAdminLibShared.operatorProfile.operatorType.personalLabel" | transloco }}</p>
                </div>
                <div *ngIf="!profile.owner.isLegalAge" class="details-icon-with-info">
                    <dtm-ui-icon name="child"></dtm-ui-icon>
                    <p>{{ "dtmAdminLibShared.operatorProfile.isMinorLabel" | transloco }}</p>
                </div>
            </ng-container>
            <div *ngSwitchCase="OperatorType.Association" class="details-icon-with-info">
                <dtm-ui-icon name="group"></dtm-ui-icon>
                <p>{{ "dtmAdminLibShared.operatorProfile.operatorType.associationLabel" | transloco }}</p>
            </div>
        </ng-container>
    </div>
    <dtm-ui-label-value
        *ngIf="profile.pilotNumber"
        class="pilot-number"
        [label]="'dtmAdminLibShared.operatorProfile.pilotNumberLabel' | transloco"
        [value]="profile.pilotNumber"
        canCopy
    ></dtm-ui-label-value>
</ng-container>
