import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperatorDetails, OperatorType } from "../../../models/operator.models";

interface OperatorDetailsTileComponentState {
    profile: OperatorDetails | undefined;
}

@Component({
    selector: "dtm-admin-lib-operator-details-tile[profile]",
    templateUrl: "./operator-details-tile.component.html",
    styleUrls: ["./operator-details-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorDetailsTileComponent {
    @Input() public set profile(value: OperatorDetails | undefined) {
        this.localStore.patchState({ profile: value });
    }

    @Output() public operatorDetailsEdit = new EventEmitter<void>();

    public readonly profile$ = this.localStore.selectByKey("profile");
    public readonly OperatorType = OperatorType;

    constructor(private readonly localStore: LocalComponentStore<OperatorDetailsTileComponentState>) {
        localStore.setState({ profile: undefined });
    }
}
