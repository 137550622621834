import { InjectionToken } from "@angular/core";

export interface PermitsEndpoints {
    managePermits: string;
    updateSpecificPermit: string;
    manageAssociationPermits: string;
    manageCrossBorderPermits: string;
    updateCrossBorderPermit: string;
    getOperatorList: string;
    getAssociationOperatorList: string;
    getSpecificPermitsCapabilities: string;
    getPermitDetails: string;
    getCrossBorderPermitDetails: string;
    manageAssociationPermitDetails: string;
    updateSpecificPermitStatus: string;
    uploadKmlFile: string;
    downloadKmlFile: string;
    downloadKmlLocationFile: string;
}

export const PERMITS_ENDPOINTS = new InjectionToken<PermitsEndpoints>("Permits endpoints");
