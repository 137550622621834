import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { OperationScenarioStatementsContainerComponent } from "./components/operation-scenario-statements-container/operation-scenario-statements-container.component";

const routes: Routes = [
    {
        path: "operation-scenario",
        component: OperationScenarioStatementsContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibOperationScenario.routeTitles.operationScenarioStatements",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OperationScenarioStatementsRoutingModule {}
