import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MapActionWithPayload, MapActionsPanelMode, MapEntity, MapEntityType, MapUtils } from "@dtm-frontend/shared/map/cesium";
import { GeoJSON, SearchControlOption } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { FormType, LocalComponentStore, Logger } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Feature as GeoJSONFeature } from "@turf/helpers/dist/js/lib/geojson";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import {
    MissionPhaseExtensions,
    MissionProcessingPhase,
    MissionProcessingPhaseExtended,
    MissionType,
} from "../../../../shared/models/mission.models";
import {
    AreaGeoJSON,
    FilterConditionSearchState,
    FilterConditionSearchText,
    FilterConditionSearchType,
    FilterConditions,
    MissionFilterForm,
    SearchArea,
} from "../../../models/mission-search.models";
import { FilterAreaMapService } from "./filter-area-map.service";

interface FilterConditionsComponentState {
    operatorSearchState: FilterConditionSearchState;
    pilotSearchState: FilterConditionSearchState;
    missionIdSearchState: FilterConditionSearchState;
    uavSearchState: FilterConditionSearchState;
    zoneSearchState: FilterConditionSearchState;
}

interface DatesFormValues {
    fromDate: Date | null;
    fromTime: Date | null;
    toDate: Date | null;
    toTime: Date | null;
}

// NOTE: just a fallback if backend do not send initial viewbox
const FALLBACK_INITIAL_VIEWBOX: GeoJSON = {
    type: "Polygon",
    coordinates: [
        [
            /* eslint-disable no-magic-numbers */
            [14.069638889, 49.0020432310001],
            [14.069638889, 55.849716667],
            [24.150833333, 55.849716667],
            [24.150833333, 49.0020432310001],
            [14.069638889, 49.0020432310001],
            /* eslint-enable no-magic-numbers */
        ],
    ],
};
const UID_VALIDATION_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const MIN_VALUE_LENGTH = 3;

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-search-filter-conditions",
    templateUrl: "./filter-conditions.component.html",
    styleUrls: ["./filter-conditions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, FilterAreaMapService],
})
export class FilterConditionsComponent implements AfterViewInit, OnDestroy {
    @Input() public isPartialSearchFeatureDisabled = false;

    @Input() public set initialFilterConditions(value: FilterConditions | undefined) {
        const filtersValue = this.prepareFilterFormValue(value);

        if (this.isPartialSearchFeatureDisabled) {
            this.filtersForm.setValue(
                {
                    ...filtersValue,
                    operator: (filtersValue.operator?.name as unknown as SearchControlOption) ?? null,
                    pilot: (filtersValue.pilot?.name as unknown as SearchControlOption) ?? null,
                    uav: (filtersValue.uav?.name as unknown as SearchControlOption) ?? null,
                    missionId: (filtersValue.missionId?.name as unknown as SearchControlOption) ?? null,
                },
                { emitEvent: false }
            );
        } else {
            this.filtersForm.setValue(filtersValue, { emitEvent: false });
        }
        this.setUpDateFields(filtersValue);
    }

    @Input() public set operatorSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ operatorSearchState: value });
    }

    @Input() public set pilotSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ pilotSearchState: value });
    }

    @Input() public set missionIdSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ missionIdSearchState: value });
    }

    @Input() public set uavSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ uavSearchState: value });
    }

    @Input() public set zoneSearchState(value: FilterConditionSearchState | undefined) {
        this.localStore.patchState({ zoneSearchState: value });
    }

    @Output() public readonly searchTextChange = new EventEmitter<FilterConditionSearchText>();

    protected readonly FilterConditionSearchType = FilterConditionSearchType;
    protected readonly SearchArea = SearchArea;
    protected readonly MapActionsPanelMode = MapActionsPanelMode;
    protected readonly missionTypes = Object.values(MissionType);
    protected readonly missionStatuses = [...Object.values(MissionProcessingPhase), ...Object.values(MissionPhaseExtensions)].filter(
        (status) => status !== MissionProcessingPhase.Unsubmitted
    );
    // NOTE: Temporarily search by zone is disabled
    protected readonly areas = Object.values(SearchArea).filter((area) => area !== SearchArea.Zone);
    protected readonly initialViewbox = FALLBACK_INITIAL_VIEWBOX;

    protected readonly operatorOptions$ = this.localStore
        .selectByKey("operatorSearchState")
        .pipe(map((operatorSearchState) => operatorSearchState.options));
    protected readonly pilotOptions$ = this.localStore
        .selectByKey("pilotSearchState")
        .pipe(map((pilotSearchState) => pilotSearchState.options));
    protected readonly missionIdOptions$ = this.localStore
        .selectByKey("missionIdSearchState")
        .pipe(map((missionIdSearchState) => missionIdSearchState.options));
    protected readonly uavOptions$ = this.localStore.selectByKey("uavSearchState").pipe(map((uavSearchState) => uavSearchState.options));
    protected readonly zoneOptions$ = this.localStore
        .selectByKey("zoneSearchState")
        .pipe(map((zoneSearchState) => zoneSearchState.options));
    protected readonly isProcessingOperator$ = this.localStore
        .selectByKey("operatorSearchState")
        .pipe(map((operatorSearchState) => operatorSearchState.isProcessing));
    protected readonly isProcessingPilot$ = this.localStore
        .selectByKey("pilotSearchState")
        .pipe(map((pilotSearchState) => pilotSearchState.isProcessing));
    protected readonly isProcessingMissionId$ = this.localStore
        .selectByKey("missionIdSearchState")
        .pipe(map((pilotSearchState) => pilotSearchState.isProcessing));
    protected readonly isProcessingUav$ = this.localStore
        .selectByKey("uavSearchState")
        .pipe(map((uavSearchState) => uavSearchState.isProcessing));
    protected readonly isProcessingZone$ = this.localStore
        .selectByKey("zoneSearchState")
        .pipe(map((zoneSearchState) => zoneSearchState.isProcessing));
    protected readonly drawnEntitiesCount$ = this.filterAreaMapService.editorContent$.pipe(map((content) => content?.length ?? 0));
    protected readonly activeMapAction$ = this.filterAreaMapService.activeMapAction$;
    protected readonly activeEntityStatus$ = this.filterAreaMapService.activeEntityStatus$;

    protected readonly filtersForm = new FormGroup<MissionFilterForm>(
        {
            operator: new FormControl<SearchControlOption | null>(null, Validators.minLength(MIN_VALUE_LENGTH)),
            pilot: new FormControl<SearchControlOption | null>(null, Validators.minLength(MIN_VALUE_LENGTH)),
            missionId: new FormControl<SearchControlOption | null>(null, Validators.pattern(UID_VALIDATION_PATTERN)),
            status: new FormControl<MissionProcessingPhaseExtended | null>(null),
            uav: new FormControl<SearchControlOption | null>(null, Validators.minLength(MIN_VALUE_LENGTH)),
            missionType: new FormControl<MissionType | null>(null),
            fromDate: new FormControl<Date | null>(null),
            toDate: new FormControl<Date | null>(null),
            area: new FormControl<SearchArea | null>(null),
            zone: new FormControl<SearchControlOption | null>(null),
            customArea: new FormControl<AreaGeoJSON | null>(null),
        },
        ({ value }) => {
            if (value.area === SearchArea.Zone && !value.zone) {
                return { invalidZoneArea: true };
            }
            if (value.area === SearchArea.Custom && !value.customArea) {
                return { invalidCustomArea: true };
            }

            return null;
        }
    );
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly datesForm = new FormGroup<FormType<DatesFormValues>>(
        {
            fromDate: new FormControl<Date | null>(null),
            fromTime: new FormControl<Date | null>(null),
            toDate: new FormControl<Date | null>(null),
            toTime: new FormControl<Date | null>(null),
        },
        (form): ValidationErrors | null => {
            const validationErrors = this.validateDates(form.value);

            this.filtersForm.controls.fromDate.setErrors(validationErrors ? { invalid: true } : null);
            this.filtersForm.controls.toDate.setErrors(validationErrors ? { invalid: true } : null);

            return validationErrors;
        }
    );

    public readonly isFilterFormInvalid$ = this.filtersForm.statusChanges.pipe(
        distinctUntilChanged(),
        map((status) => status === "INVALID")
    );

    constructor(
        private readonly localStore: LocalComponentStore<FilterConditionsComponentState>,
        private readonly translocoHelper: TranslationHelperService,
        private readonly filterAreaMapService: FilterAreaMapService
    ) {
        this.localStore.setState({
            operatorSearchState: this.getDefaultSearchState(),
            pilotSearchState: this.getDefaultSearchState(),
            missionIdSearchState: this.getDefaultSearchState(),
            uavSearchState: this.getDefaultSearchState(),
            zoneSearchState: this.getDefaultSearchState(),
        });

        this.manageDatesFields();

        this.filtersForm.controls.area.valueChanges
            .pipe(
                filter((area) => area !== SearchArea.Custom),
                untilDestroyed(this)
            )
            .subscribe(() => this.filterAreaMapService.clearMap());

        this.filterAreaMapService.editorContent$
            .pipe(
                map((entities) => entities[0]),
                untilDestroyed(this)
            )
            .subscribe((entity: MapEntity | undefined) => {
                const area = this.convertEntityToGeoJSONFeature(entity) as AreaGeoJSON;

                this.filtersForm.controls.customArea.setValue(area ?? null);

                if (area) {
                    this.filterAreaMapService.zoomToArea();
                }
            });
    }

    public async ngAfterViewInit() {
        // NOTE: some editor updates are not available in this cycle, so we will need to wait for next cycle
        await this.waitForNextCycle();

        this.filterAreaMapService.mapEntitiesReady$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.filtersForm.controls.customArea.value) {
                this.filterAreaMapService.createEditableArea(this.filtersForm.controls.customArea.value);
                this.filterAreaMapService.zoomToArea();
            }
        });
    }

    public ngOnDestroy(): void {
        this.filterAreaMapService.clearMap();
    }

    public getFiltersFormValue(): FilterConditions {
        const filterConditions = this.filtersForm.getRawValue();

        if (this.isPartialSearchFeatureDisabled) {
            filterConditions.operator = filterConditions.operator ? { id: "", name: filterConditions.operator as unknown as string } : null;
            filterConditions.pilot = filterConditions.pilot ? { id: "", name: filterConditions.pilot as unknown as string } : null;
            filterConditions.missionId = filterConditions.missionId
                ? { id: "", name: filterConditions.missionId as unknown as string }
                : null;
            filterConditions.uav = filterConditions.uav ? { id: "", name: filterConditions.uav as unknown as string } : null;
        }

        let area = filterConditions.area;
        if (filterConditions.area === SearchArea.Zone && !filterConditions.zone) {
            area = SearchArea.Country;
        }

        let zone = filterConditions.zone;
        if (area !== SearchArea.Zone || !filterConditions.zone) {
            zone = null;
        }

        return {
            ...filterConditions,
            area,
            zone,
        };
    }

    protected changeSearchText(type: FilterConditionSearchType, text: string): void {
        this.searchTextChange.emit({ type, text });
    }

    protected processMapActionChange(action: MapActionWithPayload) {
        try {
            this.filterAreaMapService.processMapActionChange(action);
        } catch (error) {
            Logger.captureException(error);
        }
    }

    private prepareFilterFormValue(filterConditions: FilterConditions | undefined): FilterConditions {
        return {
            operator: filterConditions?.operator ?? null,
            pilot: filterConditions?.pilot ?? null,
            missionId: filterConditions?.missionId ?? null,
            status: filterConditions?.status ?? null,
            uav: filterConditions?.uav ?? null,
            missionType: filterConditions?.missionType ?? null,
            fromDate: filterConditions?.fromDate ?? null,
            toDate: filterConditions?.toDate ?? null,
            area: filterConditions?.area ?? null,
            zone: filterConditions?.zone ?? null,
            customArea: filterConditions?.customArea ?? null,
        };
    }

    private manageDatesFields(): void {
        this.datesForm.valueChanges.pipe(untilDestroyed(this)).subscribe(({ fromDate, fromTime, toDate, toTime }) => {
            if (this.datesForm.invalid) {
                return;
            }

            if (!fromDate && !fromTime && !toDate && !toTime) {
                this.filtersForm.controls.fromDate.setValue(null);
                this.filtersForm.controls.toDate.setValue(null);
            } else {
                const fromDateTime = this.calculateDate(fromDate as Date, fromTime ?? null, false);
                const toDateTime = this.calculateDate(toDate as Date, toTime ?? null, true);

                this.filtersForm.controls.fromDate.setValue(fromDateTime);
                this.filtersForm.controls.toDate.setValue(toDateTime);
            }
        });

        this.datesForm.controls.fromDate.valueChanges.pipe(untilDestroyed(this)).subscribe((fromDate) => {
            if (fromDate && !this.datesForm.controls.toDate.value) {
                this.datesForm.controls.toDate.setValue(fromDate);
            }
        });

        this.datesForm.controls.toDate.valueChanges.pipe(untilDestroyed(this)).subscribe((toDate) => {
            if (toDate && !this.datesForm.controls.fromDate.value) {
                this.datesForm.controls.fromDate.setValue(toDate);
            }
        });
    }

    private getDefaultSearchState(): FilterConditionSearchState {
        return {
            isProcessing: false,
            options: [],
        };
    }

    private setUpDateFields({ fromDate, toDate }: FilterConditions): void {
        this.datesForm.setValue({ fromDate, fromTime: fromDate, toDate, toTime: toDate }, { emitEvent: false });
    }

    private validateDates({ fromDate, fromTime, toDate, toTime }: DatesFormValues): ValidationErrors | null {
        if (!fromDate && !toDate && !fromTime && !toTime) {
            return null;
        }

        if (!fromDate) {
            return { fromDateRequired: true };
        }

        if (!toDate) {
            return { toDateRequired: true };
        }

        if (!fromTime && toTime) {
            return { fromTimeRequired: true };
        }

        if (fromTime && !toTime) {
            return { toTimeRequired: true };
        }

        const fromDateTime = this.calculateDate(fromDate, fromTime, false);
        const toDateTime = this.calculateDate(toDate, toTime, true);

        if (fromDateTime > toDateTime) {
            return { range: true };
        }

        return null;
    }

    private calculateDate(date: Date, time: Date | null, isEnd: boolean): Date {
        const dateTime: Date = new Date(date);

        if (time) {
            // eslint-disable-next-line no-magic-numbers
            dateTime.setHours(time.getHours(), time.getMinutes(), isEnd ? 59 : 0);
        } else if (isEnd) {
            // eslint-disable-next-line no-magic-numbers
            dateTime.setHours(23, 59, 59);
        } else {
            dateTime.setHours(0, 0, 0);
        }

        return dateTime;
    }

    private async waitForNextCycle(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve);
        });
    }

    private convertEntityToGeoJSONFeature(entity: MapEntity | undefined): GeoJSONFeature | undefined {
        if (entity?.type === MapEntityType.Cylinder) {
            return MapUtils.convertCylinderEntityToGeoJSONFeature(entity);
        }
        if (entity?.type === MapEntityType.Prism) {
            const result = MapUtils.convertPrismEntityToGeoJSONFeature(entity);

            if (result.properties && entity.center) {
                const centerPoint = MapUtils.convertCartesian3ToSerializableCartographic(entity.center);
                result.properties.center = [centerPoint.longitude, centerPoint.latitude];
            }

            return result;
        }

        return undefined;
    }
}
