<dtm-mission-mission-preview
    [isProcessing]="isProcessing$ | ngrxPush"
    [missionData]="mission$ | ngrxPush"
    [route]="route$ | ngrxPush"
    [flightPurposes]="flightPurposes$ | ngrxPush"
    [analysisStatus]="analysisStatus$ | ngrxPush"
    [operationalGeometry]="operationalGeometry$ | ngrxPush"
    [nearbyMissionsRouteData]="nearbyMissionsRouteData$ | ngrxPush"
    [collisionZones]="collisionZones$ | ngrxPush"
    [isFlightRequirementsProcessing]="isFlightRequirementsProcessing$ | ngrxPush"
    [soraSettings]="soraSettings$ | ngrxPush"
>
    <div class="header" headerSlot>
        <h2>{{ "dtmSharedMission.missionPreview.header" | transloco }}</h2>
        <button *ngIf="isExternalAppButtonVisible" type="button" class="button-secondary" (click)="showDetailsInExternalApp()">
            <dtm-ui-icon name="external-link"></dtm-ui-icon>
            {{ "dtmSharedMissionSearch.missionPreview.showInExternalAppButtonLabel" | transloco }}
        </button>
    </div>
</dtm-mission-mission-preview>
