export const NULL_VALUE = 0;

export enum SINGLE_SELECT_VALUES {
    True = "true",
    False = "false",
}

export enum ChangeLogType {
    Operator = "operator",
    User = "user",
}
