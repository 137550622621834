import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { RouterModule } from "@angular/router";
import { SharedAuthModule } from "@dtm-frontend/shared/auth";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedModule } from "../shared/shared.module";
import { ConversationCategoriesComponent } from "./components/conversation-categories/conversation-categories.component";
import { PersonalDataComponent } from "./components/personal-data/personal-data.component";
import { UserProfileContainerComponent } from "./components/user-profile-container/user-profile.container.component";
import { UserProfileApiService } from "./services/user-profile.api.service";
import { UserProfileResolver } from "./services/user-profile.resolvers";
import { UserProfileState } from "./state/user-profile.state";

@NgModule({
    declarations: [UserProfileContainerComponent, ConversationCategoriesComponent, PersonalDataComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgxsModule.forFeature([UserProfileState]),
        RouterModule,
        SharedUiModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedI18nModule,
        SharedAuthModule,
        SharedModule.forRoot(),
        MatCardModule,
        MatInputModule,
    ],
    providers: [
        UserProfileResolver,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibUserProfile",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: UserProfileApiService,
            useValue: undefined,
        },
    ],
    exports: [ConversationCategoriesComponent, PersonalDataComponent],
})
export class UserProfileModule {
    public static forRoot(): ModuleWithProviders<UserProfileModule> {
        return {
            ngModule: UserProfileModule,
            providers: [
                {
                    provide: UserProfileApiService,
                    useClass: UserProfileApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<UserProfileModule> {
        return {
            ngModule: UserProfileModule,
            providers: [
                {
                    provide: UserProfileApiService,
                    useValue: null,
                },
            ],
        };
    }
}
