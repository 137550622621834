import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { AdminUser, AdminUsersError } from "../../services/models";

interface AdminUsersListComponentState {
    usersList: AdminUser[];
    usersListError: AdminUsersError | undefined;
    isUsersListProcessing: boolean;
    pagination: Page | undefined;
}

@Component({
    selector: "dtm-admin-lib-admin-users-list[usersList][pagination]",
    templateUrl: "./admin-users-list.component.html",
    styleUrls: ["./admin-users-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdminUsersListComponent {
    @Input() public set usersList(value: AdminUser[] | undefined) {
        this.localStore.patchState({ usersList: value ?? [] });
    }
    @Input() public set usersListError(value: AdminUsersError | undefined) {
        this.localStore.patchState({ usersListError: value });
    }
    @Input() public set isUsersListProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isUsersListProcessing: !!value });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    protected readonly usersList$ = this.localStore.selectByKey("usersList");
    protected readonly usersListError$ = this.localStore.selectByKey("usersListError");
    protected readonly isUsersListProcessing$ = this.localStore.selectByKey("isUsersListProcessing");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly displayedColumns = ["firstName", "lastName", "email", "phone", "actions"];
    protected readonly ErrorMode = ErrorMode;

    @Output() public usersListRefresh = new EventEmitter<void>();
    @Output() public pageChange = new EventEmitter<PageEvent>();
    @Output() public userEdit = new EventEmitter<AdminUser>();
    @Output() public userDelete = new EventEmitter<AdminUser>();
    @Output() public passwordReset = new EventEmitter<AdminUser>();

    constructor(private readonly localStore: LocalComponentStore<AdminUsersListComponentState>) {
        localStore.setState({
            usersList: [],
            usersListError: undefined,
            isUsersListProcessing: false,
            pagination: undefined,
        });
    }
}
