<ng-container
    *ngrxLet="{
        weatherList: weatherList$,
        weatherViewMode: weatherViewMode$,
        weatherByMissionTime: weatherByMissionTime$,
        isProcessing: isProcessing$,
        selectedDtm: selectedDtm$,
        forecastRefTime: forecastRefTime$
    } as vm"
>
    <dtm-ui-loader-container [isShown]="vm.isProcessing">
        <div class="weather" [class.tabs-mode]="vm.weatherViewMode === WeatherViewMode.Tabs" [class.no-data]="!vm.weatherList.length">
            <ng-container
                *ngIf="
                    (vm.weatherViewMode === WeatherViewMode.Tabs && vm.weatherByMissionTime) ||
                        vm.weatherViewMode === WeatherViewMode.Basic;
                    else noWeatherTemplate
                "
            >
                <ng-container *ngIf="vm.weatherViewMode === WeatherViewMode.Basic">
                    <h2 *ngIf="!vm.weatherList.length">
                        {{ "dtmMapCesium.weather.chooseDtmHeading" | transloco }}
                    </h2>
                    <div class="dtm-list">
                        <button
                            type="button"
                            [class.selected]="vm.selectedDtm === dtm.value"
                            class="selectable-tile"
                            *ngFor="let dtm of DtmName | keyvalue"
                            (click)="selectDtm(dtm.value)"
                        >
                            {{ dtm.value }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="vm.weatherList.length">
                    <section class="header">
                        <h2>{{ "dtmMapCesium.weather.weatherLabel" | transloco }}</h2>
                        <dtm-ui-icon name="information-fill" [matTooltip]="'dtmMapCesium.weather.tooltipText' | transloco"></dtm-ui-icon>
                        <!-- TODO: dtmMapCesium.weather.tooltipText is empty -->
                        <span class="forecast-time" *ngIf="vm.forecastRefTime">
                            {{
                                "dtmMapCesium.weather.forecastTimeLabel"
                                    | transloco
                                        : {
                                              date: vm.forecastRefTime | localizeDate : { dateStyle: "short" },
                                              time: vm.forecastRefTime | localizeDate : { timeStyle: "short" }
                                          }
                            }}
                        </span>
                    </section>
                    <ng-container *ngIf="vm.weatherViewMode === WeatherViewMode.Basic">
                        <dtm-map-time-range-slider
                            [weatherRange]="vm.weatherList"
                            (rangeSelect)="changeWeatherConditions($event)"
                        ></dtm-map-time-range-slider>
                        <dtm-map-weather-conditions-grid
                            isWithinDtm
                            [selectedRange]="selectedRange$ | ngrxPush"
                        ></dtm-map-weather-conditions-grid>
                    </ng-container>
                    <mat-tab-group class="dtm-tabs" color="accent" *ngIf="vm.weatherViewMode === WeatherViewMode.Tabs">
                        <mat-tab [label]="'dtmMapCesium.weather.selectedMissionTabLabel' | transloco">
                            <div class="tab-content">
                                <dtm-map-weather-conditions-grid
                                    [selectedRange]="vm.weatherByMissionTime"
                                    [isWithinDtm]="isWithinDtm$ | ngrxPush"
                                ></dtm-map-weather-conditions-grid>
                            </div>
                        </mat-tab>
                        <mat-tab [label]="'dtmMapCesium.weather.selectedHourMissionTabLabel' | transloco">
                            <div class="tab-content">
                                <dtm-map-time-range-slider
                                    [weatherRange]="vm.weatherList"
                                    [selectedWeatherRangeIndex]="selectedWeatherRangeIndex$ | ngrxPush"
                                    (rangeSelect)="changeWeatherConditions($event)"
                                ></dtm-map-time-range-slider>
                                <dtm-map-weather-conditions-grid
                                    [selectedRange]="selectedRange$ | ngrxPush"
                                    [isWithinDtm]="isWithinDtm$ | ngrxPush"
                                ></dtm-map-weather-conditions-grid>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </ng-container>
                <span class="agl-hint" *ngIf="vm.weatherList.length">{{ "dtmMapCesium.weather.aglHint" | transloco }}</span>
            </ng-container>
        </div>
    </dtm-ui-loader-container>
    <ng-template #noWeatherTemplate>
        <div class="no-weather-condition">
            {{ "dtmMapCesium.weather.noConditionDataLabel" | transloco : { type: vm.weatherViewMode } }}
        </div>
    </ng-template>
</ng-container>
