import { HttpErrorResponse, HttpEvent, HttpResponse } from "@angular/common/http";
import { FileUploadErrorType, FilesUploadApi } from "@dtm-frontend/shared/ui";
import { UploadedFile } from "@dtm-frontend/shared/utils";
import { Observable, of } from "rxjs";

export class FilesManagementApiMock implements FilesUploadApi {
    public uploadFile(file: File): Observable<HttpEvent<UploadedFile>> {
        return of(new HttpResponse<UploadedFile>({ body: { id: "foo", name: "fake-file.txt" } }));
    }

    public getFile(fileId: string): Observable<Blob> {
        return of(new Blob(["foo"]));
    }

    public getFilesCompressed(fileIds: string[], fileName: string): Observable<Blob> {
        return of(new Blob(["foo", "bar"]));
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        return { type: FileUploadErrorType.Unknown };
    }
}
