<div *ngrxLet="levelState$ as levelState">
    <div class="label">
        <ng-content select="label" ngProjectAs="label"></ng-content>
    </div>
    <div class="tile-container" *ngrxLet="isDisabled$ as isDisabled">
        <div class="selectable-tile" (click)="setLevelState(false)" [class.selected]="levelState === false" [class.disabled]="isDisabled">
            <h6 class="selectable-tile-label">{{ "dtmAdminLibPermits.containmentLevelReachedControl.basicTileText" | transloco }}</h6>
        </div>
        <div class="selectable-tile" (click)="setLevelState(true)" [class.selected]="levelState" [class.disabled]="isDisabled">
            <span class="selectable-tile-label">{{
                "dtmAdminLibPermits.containmentLevelReachedControl.strengthenedTileText" | transloco
            }}</span>
        </div>
    </div>
    <dtm-ui-form-errors class="preserve-error-space">
        <ng-content select=".field-error"></ng-content>
    </dtm-ui-form-errors>
</div>
