import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { EmptyStateMode, OperatorAndPilotQualification } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface OperatorQualificationsComponentState {
    qualifications: OperatorAndPilotQualification[];
    canManageOperator: boolean;
}

@Component({
    selector: "dtm-web-app-lib-operator-qualifications[qualifications]",
    templateUrl: "./operator-qualifications.component.html",
    styleUrls: ["./operator-qualifications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorQualificationsComponent {
    @Input() public set qualifications(value: OperatorAndPilotQualification[] | undefined) {
        this.localStore.patchState({ qualifications: value ?? [] });
    }

    @Input() public set canManageOperator(value: BooleanInput) {
        this.localStore.patchState({ canManageOperator: coerceBooleanProperty(value) });
    }

    @Output() public readonly operatorQualificationsShow = new EventEmitter<void>();

    protected readonly qualifications$ = this.localStore.selectByKey("qualifications");
    protected readonly canManageOperator$ = this.localStore.selectByKey("canManageOperator");
    protected readonly EmptyStateMode = EmptyStateMode;

    constructor(private router: Router, private readonly localStore: LocalComponentStore<OperatorQualificationsComponentState>) {
        this.localStore.setState({
            qualifications: [],
            canManageOperator: false,
        });
    }

    public navigateToGetAuthorizations(): void {
        this.router.navigateByUrl("/statements");
    }
}
