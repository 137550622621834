import { LegalGuardianDetailsUpdate } from "../../shared/models/operator.models";
import { UtmUserBasicData, UtmUserIdentityCardStatusRequest, UtmUsersFiltersParams } from "../services/utm-users.models";

export namespace UtmUsersActions {
    export class GetUsers {
        public static readonly type = "[UtmUsers] Get users";

        constructor(public queryParams: UtmUsersFiltersParams) {}
    }

    export class SelectUser {
        public static readonly type = "[UtmUsers] Set selected user";

        constructor(public userId: string) {}
    }

    export class UpdateUser {
        public static readonly type = "[UtmUsers] Update User";

        constructor(public userId: string, public userValues: UtmUserBasicData) {}
    }

    export class DownloadIdentityDocument {
        public static readonly type = "[UtmUsers] Download identity document";

        constructor(public userId: string, public documentId: string, public fileName: string) {}
    }

    export class UpdateIdentityDocumentStatus {
        public static readonly type = "[UtmUsers] Update identity document status";

        constructor(public userId: string, public documentId: string, public statusRequest: UtmUserIdentityCardStatusRequest) {}
    }

    export class RequestForDocumentUpdate {
        public static readonly type = "[UtmUsers] Request for document update";

        constructor(public userId: string) {}
    }

    export class RequestLegalGuardianUpdate {
        public static readonly type = "[UtmUsers] Request for legal guardian update";

        constructor(public userId: string) {}
    }

    export class UpdateLegalGuardian {
        public static readonly type = "[UtmUsers] Update legal guardian";

        constructor(public userId: string, public legalGuardianDetails: LegalGuardianDetailsUpdate) {}
    }

    export class DeleteLegalGuardian {
        public static readonly type = "[UtmUsers] Delete legal guardian";

        constructor(public userId: string) {}
    }

    export class GetLegalGuardianSignedDocument {
        public static readonly type = "[UtmUsers] Get legal guardian signed document";

        constructor(public userId: string, public fileName: string) {}
    }
}
