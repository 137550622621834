import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ForeignCompetencyDocument, ForeignCompetencyStatus, ForeignCompetencyType } from "../../../models/operator.models";

interface ForeignCompetenciesComponentState {
    competencies: ForeignCompetencyDocument[];
    areActionsAvailable: boolean;
}

@Component({
    selector: "dtm-admin-lib-foreign-competencies[competencies]",
    templateUrl: "./foreign-competencies.component.html",
    styleUrls: ["./foreign-competencies.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ForeignCompetenciesComponent {
    @Input() public set competencies(value: ForeignCompetencyDocument[] | undefined) {
        this.localStore.patchState({ competencies: value ?? [] });
    }
    @Input() public set areActionsAvailable(value: BooleanInput) {
        this.localStore.patchState({ areActionsAvailable: coerceBooleanProperty(value) });
    }

    @Output() public readonly competencyAccept = new EventEmitter<string>();
    @Output() public readonly competencyReject = new EventEmitter<string>();
    @Output() public readonly download = new EventEmitter<ForeignCompetencyDocument>();

    protected readonly ForeignCompetencyStatus = ForeignCompetencyStatus;
    protected readonly ForeignCompetencyType = ForeignCompetencyType;
    protected readonly competencies$ = this.localStore.selectByKey("competencies");
    protected readonly areActionsAvailable$ = this.localStore.selectByKey("areActionsAvailable");

    constructor(private readonly localStore: LocalComponentStore<ForeignCompetenciesComponentState>) {
        localStore.setState({ competencies: [], areActionsAvailable: true });
    }
}
