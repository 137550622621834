<dtm-ui-select-field [formControl]="singleSelectFormControl" [isClearable]="false">
    <label *ngrxLet="label$; let label">{{ label }}</label>
    <dtm-ui-select-option [value]="NULL_VALUE">
        {{ (allOptionLabel$ | ngrxPush) ?? ("dtmAdminLibShared.filters.allValueLabel" | transloco) }}
    </dtm-ui-select-option>
    <dtm-ui-select-option [value]="SINGLE_SELECT_VALUES.True" *ngrxLet="trueOptionLabel$; let trueOptionLabel">
        {{ trueOptionLabel }}
    </dtm-ui-select-option>
    <dtm-ui-select-option [value]="SINGLE_SELECT_VALUES.False" *ngrxLet="falseOptionLabel$; let falseOptionLabel">
        {{ falseOptionLabel }}
    </dtm-ui-select-option>
</dtm-ui-select-field>
