import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    ConfirmationDialogConfig,
    DialogService,
    MIN_PAGE_SIZE_VALUE,
    PAGE_NUMBER_QUERY_PARAM,
    PAGE_SIZE_QUERY_PARAM,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, switchMap } from "rxjs";
import { filter } from "rxjs/operators";
import {
    CentersActionType,
    CentersFilterFormKeys,
    CentersFilterParams,
    CentersFiltersTabKeys,
    CentersListData,
    CentersTabType,
} from "../../models/centers.models";
import {
    ExaminationCenter,
    ExaminationCenterDialogData,
    ExaminationCentersError,
    ExaminationCentersErrorType,
} from "../../models/examination-centers.models";
import {
    TrainingCenter,
    TrainingCenterDialogData,
    TrainingCentersError,
    TrainingCentersErrorType,
} from "../../models/training-centers.models";
import { CentersActions } from "../../state/centers.actions";
import { CentersState } from "../../state/centers.state";
import { ExaminationCentersAddFormDialogComponent } from "../examination-centers-add-form-dialog/examination-centers-add-form-dialog.component";
import { ExaminationCentersPreviewDialogComponent } from "../examination-centers-preview-dialog/examination-centers-preview-dialog.component";
import { TrainingCentersAddFormDialogComponent } from "../training-centers-add-form-dialog/training-centers-add-form-dialog.component";
import { TrainingCentersPreviewDialogComponent } from "../training-centers-preview-dialog/training-centers-preview-dialog.component";

interface CentersComponentState {
    [CentersFilterFormKeys.ActiveTabIndex]: CentersTabType;
    [CentersFiltersTabKeys.ExaminationCentersFilters]: CentersFilterParams;
    [CentersFiltersTabKeys.TrainingCentersFilters]: CentersFilterParams;
}

const DEFAULT_ACTIVE_TAB = CentersTabType.ExaminationEntities;
const DEFAULT_FILTERS_VALUE = {
    [CentersFilterFormKeys.SearchPhrase]: "",
    [PAGE_NUMBER_QUERY_PARAM]: 0,
    [PAGE_SIZE_QUERY_PARAM]: MIN_PAGE_SIZE_VALUE,
};

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-centers",
    templateUrl: "./centers.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CentersComponent implements OnInit {
    protected readonly activeTabIndex$ = this.localStore.selectByKey(CentersFilterFormKeys.ActiveTabIndex);
    protected readonly examinationCentersPages$ = this.store.select(CentersState.examinationCentersPages);
    protected readonly examinationCentersFilters$ = this.localStore.selectByKey(CentersFiltersTabKeys.ExaminationCentersFilters);
    protected readonly examinationCentersList$ = this.store.select(CentersState.examinationCentersList);
    protected readonly examinationCentersListError$ = this.store.select(CentersState.examinationCentersListError);
    protected readonly isExaminationCenterProcessing$ = this.store.select(CentersState.isExaminationCenterProcessing);
    protected readonly isTrainingCenterProcessing$ = this.store.select(CentersState.isTrainingCenterProcessing);
    protected readonly trainingCentersList$ = this.store.select(CentersState.trainingCentersList);
    protected readonly trainingCentersListError$ = this.store.select(CentersState.trainingCentersListError);
    protected readonly trainingCentersPages$ = this.store.select(CentersState.trainingCentersPages);
    protected readonly trainingCentersFilters$ = this.localStore.selectByKey(CentersFiltersTabKeys.TrainingCentersFilters);
    protected readonly areCapabilitiesProcessing$ = this.store.select(CentersState.areCapabilitiesProcessing);
    protected readonly capabilities$ = this.store.select(CentersState.capabilities);
    protected readonly capabilitiesError$ = this.store.select(CentersState.capabilitiesError);
    protected readonly isOperatorDetailsProcessing$ = this.store.select(CentersState.isOperatorDetailsProcessing);

    protected readonly CentersTabType = CentersTabType;
    protected readonly CentersFiltersTabKeys = CentersFiltersTabKeys;

    constructor(
        private readonly localStore: LocalComponentStore<CentersComponentState>,
        private readonly store: Store,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        const snapshotParams = this.route.snapshot.queryParams;
        const snapShotData = {
            [PAGE_NUMBER_QUERY_PARAM]: snapshotParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: snapshotParams[PAGE_SIZE_QUERY_PARAM] ?? MIN_PAGE_SIZE_VALUE,
            searchPhrase: snapshotParams.searchPhrase,
        };
        const snapshotTabIndex = +snapshotParams[CentersFilterFormKeys.ActiveTabIndex];

        this.localStore.setState({
            activeTabIndex: DEFAULT_ACTIVE_TAB,
            examinationCentersFilters: snapshotTabIndex === CentersTabType.ExaminationEntities ? snapShotData : DEFAULT_FILTERS_VALUE,
            trainingCentersFilters: snapshotTabIndex === CentersTabType.TrainingOperators ? snapShotData : DEFAULT_FILTERS_VALUE,
        });
    }

    public ngOnInit(): void {
        this.listenOnQueryParamsChanged();
        this.store.dispatch(new CentersActions.GetCapabilities());
    }

    protected updateTabIndex(activeTabIndex: CentersTabType): void {
        this.localStore.patchState({ activeTabIndex: activeTabIndex });
        this.applyFilters(activeTabIndex);
    }

    private listenOnQueryParamsChanged(): void {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: Params) => {
            const index = queryParams?.[CentersFilterFormKeys.ActiveTabIndex];
            const activeTabIndex = !index ? DEFAULT_ACTIVE_TAB : +index;
            this.updateTabIndex(activeTabIndex);
            if (activeTabIndex === CentersTabType.ExaminationEntities) {
                this.getExaminationCentersList();
            } else {
                this.getTrainingCentersList();
            }
        });
    }

    protected centersPageChange({ pageIndex, pageSize }: PageEvent, filterTabKey: CentersFiltersTabKeys): void {
        const activeTabIndex = this.localStore.selectSnapshotByKey(CentersFilterFormKeys.ActiveTabIndex);
        const previousFilters = this.localStore.selectSnapshotByKey(filterTabKey);
        this.localStore.patchState({
            [filterTabKey]: {
                ...previousFilters,
                [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
                [PAGE_SIZE_QUERY_PARAM]: pageSize,
            },
        });

        this.applyFilters(activeTabIndex);
    }

    protected examinationCentersListRefresh(): void {
        this.getExaminationCentersList();
    }

    protected trainingCentersListRefresh(): void {
        this.getTrainingCentersList();
    }

    protected navigateByParams(filters: Partial<CentersFilterParams>, index: CentersTabType): void {
        const activeTabIndex = this.localStore.selectSnapshotByKey(CentersFilterFormKeys.ActiveTabIndex) ?? DEFAULT_ACTIVE_TAB;

        if (activeTabIndex !== index) {
            return;
        }

        const key = this.getFilterKeyByIndex(index);
        const previousFilterState = this.localStore.selectSnapshotByKey(key);
        this.localStore.patchState({ [key]: { ...previousFilterState, ...filters } });
        this.applyFilters(activeTabIndex);
    }

    private getExaminationCentersList(): void {
        const examinationCentersFilters = this.localStore.selectSnapshotByKey(CentersFiltersTabKeys.ExaminationCentersFilters);
        this.store.dispatch(new CentersActions.GetExaminationCenters(examinationCentersFilters));
    }

    private getTrainingCentersList(): void {
        const trainingCentersFilters = this.localStore.selectSnapshotByKey(CentersFiltersTabKeys.TrainingCentersFilters);
        this.store.dispatch(new CentersActions.GetTrainingCentersList(trainingCentersFilters));
    }

    private applyFilters(activeTabIndex: CentersTabType = DEFAULT_ACTIVE_TAB): void {
        const filters = this.localStore.selectSnapshotByKey(this.getFilterKeyByIndex(+activeTabIndex));
        let params: CentersFilterParams = {
            [CentersFilterFormKeys.ActiveTabIndex]: activeTabIndex,
            [PAGE_NUMBER_QUERY_PARAM]: filters[PAGE_NUMBER_QUERY_PARAM],
            [PAGE_SIZE_QUERY_PARAM]: filters[PAGE_SIZE_QUERY_PARAM],
        };

        if (filters[CentersFilterFormKeys.SearchPhrase]) {
            params = { ...params, [CentersFilterFormKeys.SearchPhrase]: filters[CentersFilterFormKeys.SearchPhrase] };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    protected async openAddCenter(activeTabIndex: CentersTabType, center?: ExaminationCenter | TrainingCenter) {
        const capabilities = this.store.selectSnapshot(CentersState.capabilities);
        const codes = activeTabIndex === CentersTabType.ExaminationEntities ? capabilities?.examCodes : capabilities?.trainingCodes;

        if (!codes?.length) {
            this.store.dispatch(new CentersActions.GetCapabilities());

            await firstValueFrom(
                this.areCapabilitiesProcessing$.pipe(
                    filter((capabilitiesProcessing) => !capabilitiesProcessing),
                    untilDestroyed(this)
                )
            );

            if (this.store.selectSnapshot(CentersState.capabilitiesError)) {
                this.toastrService.error(this.translocoService.translate("dtmAdminLibCenters.toastMessages.capabilitiesErrorMessage"));

                return;
            }
        }

        if (activeTabIndex === CentersTabType.ExaminationEntities) {
            this.openAddExaminationCenter(center as ExaminationCenter);
        } else {
            this.openAddTrainingCenter(center as TrainingCenter);
        }
    }

    protected openPreviewExaminationCenter(center: CentersListData) {
        const data: ExaminationCenterDialogData = { center: center as ExaminationCenter, capabilities: [] };

        this.dialogService.open(ExaminationCentersPreviewDialogComponent, {
            data,
            disableClose: true,
        });
    }

    protected async openPreviewTrainingCenter(center: CentersListData) {
        this.store.dispatch(new CentersActions.GetOperatorDetails((center as TrainingCenter).operatorId as string));

        await firstValueFrom(
            this.isOperatorDetailsProcessing$.pipe(
                filter((processing) => !processing),
                untilDestroyed(this)
            )
        );

        if (this.store.selectSnapshot(CentersState.operatorDetailsError)) {
            this.toastrService.error(this.translocoService.translate("dtmAdminLibCenters.toastMessages.operatorDetailsErrorMessage"));

            return;
        }

        const data: TrainingCenterDialogData = {
            center: center as TrainingCenter,
            capabilities: [],
            operatorDetails: this.store.selectSnapshot(CentersState.operatorDetails),
        };

        this.dialogService.open(TrainingCentersPreviewDialogComponent, {
            data,
            disableClose: true,
        });
    }

    private async openAddTrainingCenter(center?: TrainingCenter) {
        const data: TrainingCenterDialogData = {
            header: center
                ? this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.header.editDataHeader")
                : this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.header.addDataHeader", {
                      value: CentersTabType.TrainingOperators,
                  }),
            capabilities: this.store.selectSnapshot(CentersState.capabilities)?.trainingCodes ?? [],
            confirmLabel: center
                ? this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.saveButtonLabel")
                : this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.addButtonLabel"),
            center,
        };

        const dialogRef = this.dialogService.open(TrainingCentersAddFormDialogComponent, {
            data,
            disableClose: true,
        });

        dialogRef.componentInstance.newValue$
            .pipe(
                switchMap((trainingCenter: TrainingCenter) =>
                    center
                        ? this.store.dispatch(new CentersActions.EditTrainingCenter(trainingCenter))
                        : this.store.dispatch(new CentersActions.AddTrainingCenter(trainingCenter))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const actionType = center ? CentersActionType.Edit : CentersActionType.Add;
                const error = this.store.selectSnapshot(CentersState.trainingCenterError);
                if (!error) {
                    this.showActionSuccessMessage(actionType);
                    dialogRef.close();
                    this.trainingCentersListRefresh();

                    return;
                }

                this.showActionErrorMessage(actionType, error);
            });
    }

    private openAddExaminationCenter(center?: ExaminationCenter) {
        const data: ExaminationCenterDialogData = {
            header: center
                ? this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.header.editDataHeader")
                : this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.header.addDataHeader", {
                      value: CentersTabType.ExaminationEntities,
                  }),
            capabilities: this.store.selectSnapshot(CentersState.capabilities)?.examCodes ?? [],
            confirmLabel: center
                ? this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.saveButtonLabel")
                : this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.addButtonLabel"),
            center,
            isCenterProcessing$: this.isExaminationCenterProcessing$,
        };

        const dialogRef = this.dialogService.open(ExaminationCentersAddFormDialogComponent, {
            data,
            disableClose: true,
        });

        dialogRef.componentInstance.newValue$
            .pipe(
                switchMap((examinationCenter: ExaminationCenter) =>
                    center
                        ? this.store.dispatch(new CentersActions.EditExaminationCenter(examinationCenter))
                        : this.store.dispatch(new CentersActions.AddExaminationCenter(examinationCenter))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const actionType = center ? CentersActionType.Edit : CentersActionType.Add;
                const error = this.store.selectSnapshot(CentersState.examinationCenterError);
                if (!error) {
                    this.showActionSuccessMessage(actionType);
                    dialogRef.close();
                    this.examinationCentersListRefresh();

                    return;
                }

                this.showActionErrorMessage(actionType, error);
            });
    }

    private getFilterKeyByIndex(tabIndex: CentersTabType): CentersFiltersTabKeys {
        switch (tabIndex) {
            case CentersTabType.ExaminationEntities:
                return CentersFiltersTabKeys.ExaminationCentersFilters;
            case CentersTabType.TrainingOperators:
                return CentersFiltersTabKeys.TrainingCentersFilters;
        }
    }

    private showActionSuccessMessage(actionType: CentersActionType) {
        switch (actionType) {
            case CentersActionType.Add:
                this.toastrService.success(this.translocoService.translate("dtmAdminLibCenters.toastMessages.addCenterMessage"));
                break;
            case CentersActionType.Edit:
                this.toastrService.success(this.translocoService.translate("dtmAdminLibCenters.toastMessages.editCenterMessage"));
                break;
            case CentersActionType.Delete:
                this.toastrService.success(this.translocoService.translate("dtmAdminLibCenters.toastMessages.deleteCenterMessage"));
                break;
            default:
                break;
        }
    }

    private showActionErrorMessage(actionType: CentersActionType, error?: TrainingCentersError | ExaminationCentersError) {
        switch (actionType) {
            case CentersActionType.Add:
                this.showAddTrainingCenterError(error);
                break;
            case CentersActionType.Edit:
                this.toastrService.error(this.translocoService.translate("dtmAdminLibCenters.toastMessages.editCenterErrorMessage"));
                break;
            case CentersActionType.Delete:
                this.toastrService.error(this.translocoService.translate("dtmAdminLibCenters.toastMessages.deleteCenterErrorMessage"));
                break;
            default:
                break;
        }
    }

    protected openDeleteExaminationCenter(centerId: string) {
        const dialogRef = this.getConfirmationDialogDeleteRef(CentersTabType.ExaminationEntities);

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new CentersActions.DeleteExaminationCenter(centerId))),
                switchMap(() => this.store.select(CentersState.examinationCenterError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    this.showActionSuccessMessage(CentersActionType.Delete);
                    dialogRef.close();
                    this.examinationCentersListRefresh();

                    return;
                }

                this.showActionErrorMessage(CentersActionType.Delete);
            });
    }

    protected openDeleteTrainingCenter(centerId: string) {
        const dialogRef = this.getConfirmationDialogDeleteRef(CentersTabType.TrainingOperators);

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new CentersActions.DeleteTrainingCenter(centerId))),
                switchMap(() => this.store.select(CentersState.trainingCenterError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    this.showActionSuccessMessage(CentersActionType.Delete);
                    dialogRef.close();
                    this.trainingCentersListRefresh();

                    return;
                }

                this.showActionErrorMessage(CentersActionType.Delete);
            });
    }

    private getConfirmationDialogDeleteRef(activeTabIndex: CentersTabType): MatDialogRef<ConfirmationDialogComponent> {
        const data: ConfirmationDialogConfig = {
            titleText: this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.deleteCenterTitle", {
                value: activeTabIndex,
            }),
            confirmationText: "",
            confirmButtonLabel: this.translocoService.translate("dtmAdminLibCenters.centerFormDialog.deleteCenterLabel"),
            theme: ButtonTheme.Warn,
        };

        return this.dialogService.open(ConfirmationDialogComponent, { data });
    }

    private showAddTrainingCenterError(error?: TrainingCentersError | ExaminationCentersError) {
        if (
            error?.type === TrainingCentersErrorType.TrainingCenterConflict ||
            error?.type === ExaminationCentersErrorType.ExaminationCenterConflict
        ) {
            this.toastrService.error(this.translocoService.translate("dtmAdminLibCenters.toastMessages.addCenterConflictErrorMessage"));

            return;
        }

        this.toastrService.error(this.translocoService.translate("dtmAdminLibCenters.toastMessages.addCenterErrorMessage"));
    }
}
