import { ChangeDetectionStrategy, Component } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { MIN_PAGE_SIZE_VALUE, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import {
    OperationScenarioStatementsFilterParams,
    OperationScenarioStatementsFilters,
    StatementConfirmationParams,
} from "../../services/operation-scenario-statements.models";
import { OperationScenarioStatementsActions } from "../../state/operation-scenario-statements.actions";
import { OperationScenarioStatementsState } from "../../state/operation-scenario-statements.state";

interface OperationScenarioStatementsContainerComponentState {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    currentFilters: OperationScenarioStatementsFilters | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operation-scenario-statements-container",
    templateUrl: "./operation-scenario-statements-container.component.html",
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationScenarioStatementsContainerComponent {
    protected readonly statementsList$ = this.store.select(OperationScenarioStatementsState.operationScenarioStatementsList);
    protected readonly statementsFilters$ = this.localStore.selectByKey("currentFilters");
    protected readonly areStatementsProcessing$ = this.store.select(
        OperationScenarioStatementsState.areOperationScenarioStatementsProcessing
    );
    protected readonly statementsError$ = this.store.select(OperationScenarioStatementsState.operationScenarioStatementsError);
    protected readonly statementsPages$ = this.store.select(OperationScenarioStatementsState.operationScenarioStatementsPages);

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<OperationScenarioStatementsContainerComponentState>,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastService: ToastrService
    ) {
        this.localStore.setState({
            [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIN_PAGE_SIZE_VALUE,
            currentFilters: undefined,
        });
        this.listenOnQueryParamsChanges();
        this.refreshStatementsList();
    }

    private listenOnQueryParamsChanges(): void {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: OperationScenarioStatementsFilterParams) => {
            if (!Object.keys(queryParams).length) {
                return;
            }
            this.getStatementsList(queryParams);
        });
    }

    protected refreshStatementsList(): void {
        const params = {
            [PAGE_NUMBER_QUERY_PARAM]: this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM),
            [PAGE_SIZE_QUERY_PARAM]: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
            ...this.localStore.selectSnapshotByKey("currentFilters"),
        };

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    private getStatementsList(params: OperationScenarioStatementsFilterParams): void {
        this.store.dispatch(new OperationScenarioStatementsActions.GetStatementsList(params));
    }

    protected updateFiltersState(filters: OperationScenarioStatementsFilters): void {
        this.localStore.patchState((currentState) => ({ ...currentState, currentFilters: filters, [PAGE_NUMBER_QUERY_PARAM]: 0 }));
        this.refreshStatementsList();
    }

    protected statementsPageChange(pageEvent: PageEvent): void {
        this.localStore.patchState((currentState) => ({
            ...currentState,
            [PAGE_NUMBER_QUERY_PARAM]: pageEvent.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: pageEvent.pageSize,
        }));

        this.refreshStatementsList();
    }

    protected getSignatureConfirmationFile(params: StatementConfirmationParams): void {
        this.store
            .dispatch(new OperationScenarioStatementsActions.GetSignatureConfirmationFile(params))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationScenarioStatementsState.signatureConfirmationDownloadError);
                const signatureConfirmationFile = this.store.selectSnapshot(OperationScenarioStatementsState.signatureConfirmationFile);
                if (error || !signatureConfirmationFile) {
                    this.toastService.error(
                        this.translocoService.translate(
                            "dtmAdminLibOperationScenario.statementsList.signatureConfirmationFileDownloadError"
                        )
                    );

                    return;
                }

                saveAs(
                    signatureConfirmationFile,
                    this.translocoService.translate("dtmAdminLibOperationScenario.statementsList.signatureConfirmationFileName")
                );
            });
    }

    protected getStatementAdministrativeFeeDocument(feeDocumentId: string): void {
        this.store
            .dispatch(
                new OperationScenarioStatementsActions.GetOperationScenarioStatementAdministrativeFeeDocument(
                    feeDocumentId,
                    this.translocoService.translate("dtmAdminLibOperationScenario.statementsList.administrativeFeeDocumentName")
                )
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(
                    OperationScenarioStatementsState.operationScenarioStatementAdministrativeFeeDocumentError
                );
                if (error) {
                    this.toastService.error(
                        this.translocoService.translate(
                            "dtmAdminLibOperationScenario.statementsList.downloadAdministrativeFeeDocumentErrorLabel"
                        )
                    );
                }
            });
    }
}
