import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { AdministrativeFee } from "../../../models/operator.models";

interface AdministrativeFeeTileComponentState {
    administrativeFee: AdministrativeFee | undefined;
    areActionsAvailable: boolean;
}

@Component({
    selector: "dtm-admin-lib-administrative-fee-tile[administrativeFee]",
    templateUrl: "./administrative-fee-tile.component.html",
    styleUrls: ["./administrative-fee-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdministrativeFeeTileComponent {
    @Input() public set administrativeFee(value: AdministrativeFee | undefined) {
        this.localStore.patchState({
            administrativeFee: value,
        });
    }

    @Output() public download = new EventEmitter<void>();

    protected readonly areActionsAvailable$ = this.localStore.selectByKey("areActionsAvailable");
    protected readonly administrativeFee$ = this.localStore.selectByKey("administrativeFee").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<AdministrativeFeeTileComponentState>) {
        this.localStore.setState({
            administrativeFee: undefined,
            areActionsAvailable: true,
        });
    }
}
