import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { LegacyDateAdapter as DateAdapter } from "@angular/material/legacy-core";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterLink } from "@angular/router";
import { CustomDateAdapter, IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoTestingModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedModule } from "../shared/shared.module";
import { ExamTrainingResultsAddDialogComponent } from "./components/exam-training-results-add-dialog/exam-training-results-add-dialog.component";
import { ExamTrainingResultsContainerComponent } from "./components/exam-training-results-container/exam-training-results-container.component";
import { ExamTrainingResultsFiltersComponent } from "./components/exam-training-results-filters/exam-training-results-filters.component";
import { ExamTrainingResultsHeaderComponent } from "./components/exam-training-results-header/exam-training-results-header.component";
import { ExamTrainingResultsListComponent } from "./components/exam-training-results-list/exam-training-results-list.component";
import { ExamTrainingResultsUploadFileDialogComponent } from "./components/exam-training-results-upload-file-dialog/exam-training-results-upload-file-dialog.component";
import { EXAM_TRAINING_RESULTS_ENDPOINTS } from "./exam-training-results.tokens";
import { ExamTrainingResultsApiService } from "./services/exam-training-results-api.service";
import { ExamTrainingResultsState } from "./state/exam-training-results.state";

@NgModule({
    declarations: [
        ExamTrainingResultsContainerComponent,
        ExamTrainingResultsHeaderComponent,
        ExamTrainingResultsFiltersComponent,
        ExamTrainingResultsListComponent,
        ExamTrainingResultsUploadFileDialogComponent,
        ExamTrainingResultsAddDialogComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        NgxsModule.forFeature([ExamTrainingResultsState]),
        TranslocoTestingModule,
        IconDirective,
        SharedUiModule,
        SharedI18nModule,
        LetModule,
        PushModule,
        ClipboardModule,
        ReactiveFormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatCardModule,
        MatTabsModule,
        MatInputModule,
        MatMenuModule,
        RouterLink,
        MatTableModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
    ],
    exports: [
        ExamTrainingResultsHeaderComponent,
        ExamTrainingResultsFiltersComponent,
        ExamTrainingResultsListComponent,
        ExamTrainingResultsUploadFileDialogComponent,
        ExamTrainingResultsAddDialogComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibExamTrainingResults",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: ExamTrainingResultsApiService,
            useValue: undefined,
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
    ],
})
export class ExamTrainingResultsModule {
    public static forRoot(): ModuleWithProviders<ExamTrainingResultsModule> {
        return {
            ngModule: ExamTrainingResultsModule,
            providers: [ExamTrainingResultsApiService],
        };
    }

    public static forTest(): ModuleWithProviders<ExamTrainingResultsModule> {
        return {
            ngModule: ExamTrainingResultsModule,
            providers: [
                {
                    provide: ExamTrainingResultsApiService,
                    useValue: null,
                },
                {
                    provide: EXAM_TRAINING_RESULTS_ENDPOINTS,
                    useValue: {},
                },
            ],
        };
    }
}
