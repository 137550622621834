<dtm-ui-expandable-panel
    *ngrxLet="{
        tacticalMitigationPerformanceRequirementProperty: tacticalMitigationPerformanceRequirementProperty$,
        tacticalMitigationPerformanceRequirements: tacticalMitigationPerformanceRequirements$,
        isExpanded: isExpanded$
    } as vm"
    [hasHeaderSeparator]="false"
    [isDisabled]="headerButtonsContainer.hasChildNodes()"
    [isExpanded]="vm.isExpanded"
>
    <div class="header" headerSlot>
        <h2 class="title">
            {{ "dtmSharedMission.tacticalRiskMitigationMeasures.header" | transloco }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="'dtmSharedMission.tacticalRiskMitigationMeasures.headerTooltip' | transloco"
            ></dtm-ui-popover>
        </h2>
        <div #headerButtonsContainer>
            <ng-content select="[headerButtonsSlot]"></ng-content>
        </div>
    </div>

    <ng-content></ng-content>

    <div class="content">
        <ng-container *ngIf="!vm.tacticalMitigationPerformanceRequirements; else requirementsMetTemplate">
            <div class="levels">
                {{
                    "dtmSharedMission.tacticalRiskMitigationMeasures.levelsLabel"
                        | transloco : { value: vm.tacticalMitigationPerformanceRequirementProperty }
                }}
            </div>

            <div class="level-description">
                <dtm-ui-icon name="information-fill"></dtm-ui-icon>
                {{
                    "dtmSharedMission.tacticalRiskMitigationMeasures.levelsDescription"
                        | transloco : { value: vm.tacticalMitigationPerformanceRequirementProperty }
                }}
            </div>
        </ng-container>

        <ng-template #requirementsMetTemplate>
            <p
                [innerHTML]="
                    'dtmSharedMission.tacticalRiskMitigationMeasures.requirementsDescriptionLabel'
                        | transloco : { value: vm.tacticalMitigationPerformanceRequirementProperty }
                "
            ></p>

            <ul class="statements">
                <li *ngFor="let requirement of vm.tacticalMitigationPerformanceRequirements! | keyvalue">
                    <h3>
                        {{
                            "dtmSharedMission.tacticalRiskMitigationMeasures.tacticalRiskMitigationMeasuresStatements"
                                | transloco : { value: requirement.key }
                        }}
                    </h3>
                    <p>
                        {{ requirement.value || "-" }}
                    </p>
                </li>
            </ul>
        </ng-template>
    </div>
</dtm-ui-expandable-panel>
