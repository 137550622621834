export interface GuideChapterItem {
    chapterId: string;
    title: string;
    content: string;
}

export enum Sail {
    /* eslint-disable no-magic-numbers */
    Sail1 = 1,
    Sail2 = 2,
    Sail3 = 3,
    Sail4 = 4,
    Sail5 = 5,
    Sail6 = 6,
    /* eslint-enable no-magic-numbers*/
}

export enum OsoLevel {
    Optional = "optional",
    Low = "low",
    Medium = "medium",
    High = "high",
}

export interface SailRequirements {
    titleKey: string;
    contentKey: string;
    osoItems: Array<{
        number: number;
        level: OsoLevel;
    }>;
}

export const SAIL_REQUIREMENTS: Partial<{ [sail in Sail]: SailRequirements[] }> = {
    [Sail.Sail1]: [
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.staff.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.staff.content",
            osoItems: [
                { number: 3, level: OsoLevel.Low },
                { number: 7, level: OsoLevel.Low },
                { number: 16, level: OsoLevel.Low },
                { number: 17, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.trainings.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.trainings.content",
            osoItems: [
                { number: 3, level: OsoLevel.Low },
                { number: 7, level: OsoLevel.Low },
                { number: 9, level: OsoLevel.Low },
                { number: 15, level: OsoLevel.Low },
                { number: 16, level: OsoLevel.Low },
                { number: 22, level: OsoLevel.Low },
                { number: 23, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.checklist.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.checklist.content",
            osoItems: [{ number: 7, level: OsoLevel.Low }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.logsAndRecords.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.logsAndRecords.content",
            osoItems: [{ number: 3, level: OsoLevel.Low }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.technicalService.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.technicalService.content",
            osoItems: [{ number: 3, level: OsoLevel.Low }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.procedures.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.procedures.content",
            osoItems: [
                { number: 8, level: OsoLevel.Low },
                { number: 11, level: OsoLevel.Low },
                { number: 13, level: OsoLevel.Low },
                { number: 14, level: OsoLevel.Low },
                { number: 16, level: OsoLevel.Low },
                { number: 21, level: OsoLevel.Low },
                { number: 23, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.leavingBoundariesProtection.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.leavingBoundariesProtection.content",
            osoItems: [
                { number: 10, level: OsoLevel.Low },
                { number: 12, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.environmentalConditions.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailI.environmentalConditions.content",
            osoItems: [{ number: 23, level: OsoLevel.Low }],
        },
    ],
    [Sail.Sail2]: [
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.operatorTasks.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.operatorTasks.content",
            osoItems: [{ number: 1, level: OsoLevel.Low }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.staff.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.staff.content",
            osoItems: [
                { number: 1, level: OsoLevel.Low },
                { number: 3, level: OsoLevel.Low },
                { number: 7, level: OsoLevel.Low },
                { number: 16, level: OsoLevel.Low },
                { number: 17, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.trainings.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.trainings.content",
            osoItems: [
                { number: 1, level: OsoLevel.Low },
                { number: 9, level: OsoLevel.Low },
                { number: 15, level: OsoLevel.Low },
                { number: 22, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.checklist.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.checklist.content",
            osoItems: [
                { number: 1, level: OsoLevel.Low },
                { number: 7, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.logsAndRecords.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.logsAndRecords.content",
            osoItems: [{ number: 3, level: OsoLevel.Low }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.procedures.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.procedures.content",
            osoItems: [
                { number: 8, level: OsoLevel.Medium },
                { number: 11, level: OsoLevel.Medium },
                { number: 13, level: OsoLevel.Low },
                { number: 14, level: OsoLevel.Medium },
                { number: 16, level: OsoLevel.Low },
                { number: 21, level: OsoLevel.Medium },
                { number: 23, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.c3LinkCharacteristic.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.c3LinkCharacteristic.content",
            osoItems: [{ number: 6, level: OsoLevel.Low }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.technicalService.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.technicalService.content",
            osoItems: [
                { number: 1, level: OsoLevel.Low },
                { number: 3, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.leavingBoundariesProtection.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.leavingBoundariesProtection.content",
            osoItems: [
                { number: 10, level: OsoLevel.Low },
                { number: 12, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.hmi.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.hmi.content",
            osoItems: [{ number: 20, level: OsoLevel.Low }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.environmentalConditions.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailII.environmentalConditions.content",
            osoItems: [{ number: 23, level: OsoLevel.Low }],
        },
    ],
    [Sail.Sail3]: [
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.audit.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.audit.content",
            osoItems: [{ number: 1, level: OsoLevel.Medium }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.operatorTasks.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.operatorTasks.content",
            osoItems: [{ number: 1, level: OsoLevel.Medium }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.staff.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.staff.content",
            osoItems: [
                { number: 1, level: OsoLevel.Medium },
                { number: 3, level: OsoLevel.Medium },
                { number: 7, level: OsoLevel.Medium },
                { number: 8, level: OsoLevel.High },
                { number: 11, level: OsoLevel.High },
                { number: 14, level: OsoLevel.High },
                { number: 21, level: OsoLevel.High },
                { number: 16, level: OsoLevel.Medium },
                { number: 17, level: OsoLevel.Low },
                { number: 19, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.trainings.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.trainings.content",
            osoItems: [
                { number: 1, level: OsoLevel.Medium },
                { number: 9, level: OsoLevel.Medium },
                { number: 15, level: OsoLevel.Medium },
                { number: 16, level: OsoLevel.Medium },
                { number: 19, level: OsoLevel.Low },
                { number: 22, level: OsoLevel.Medium },
                { number: 23, level: OsoLevel.Medium },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.checklist.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.checklist.content",
            osoItems: [
                { number: 1, level: OsoLevel.Medium },
                { number: 7, level: OsoLevel.Medium },
                { number: 19, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.logsAndRecords.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.logsAndRecords.content",
            osoItems: [{ number: 3, level: OsoLevel.Medium }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.procedures.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.procedures.content",
            osoItems: [
                { number: 8, level: OsoLevel.High },
                { number: 11, level: OsoLevel.High },
                { number: 13, level: OsoLevel.Medium },
                { number: 14, level: OsoLevel.High },
                { number: 16, level: OsoLevel.Medium },
                { number: 18, level: OsoLevel.Low },
                { number: 19, level: OsoLevel.Low },
                { number: 21, level: OsoLevel.High },
                { number: 23, level: OsoLevel.Medium },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.uasProduction.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.uasProduction.content",
            osoItems: [
                { number: 2, level: OsoLevel.Low },
                { number: 5, level: OsoLevel.Low },
                { number: 10, level: OsoLevel.Medium },
                { number: 12, level: OsoLevel.Medium },
                { number: 19, level: OsoLevel.Low },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.c3LinkCharacteristic.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.c3LinkCharacteristic.content",
            osoItems: [{ number: 6, level: OsoLevel.Low }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.technicalService.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.technicalService.content",
            osoItems: [
                { number: 1, level: OsoLevel.Medium },
                { number: 3, level: OsoLevel.Medium },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.leavingBoundariesProtection.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.leavingBoundariesProtection.content",
            osoItems: [
                { number: 10, level: OsoLevel.Medium },
                { number: 12, level: OsoLevel.Medium },
            ],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.communicationDevices.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.communicationDevices.content",
            osoItems: [{ number: 16, level: OsoLevel.Medium }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.hmi.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.hmi.content",
            osoItems: [{ number: 20, level: OsoLevel.Low }],
        },
        {
            titleKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.environmentalConditions.title",
            contentKey: "dtmWebAppLibShared.operationsManualGuide.sailRequirements.sailIII.environmentalConditions.content",
            osoItems: [
                { number: 23, level: OsoLevel.Medium },
                { number: 24, level: OsoLevel.Medium },
            ],
        },
    ],
};
