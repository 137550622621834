import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import {
    Alpha3CountryCode,
    BasicAddress,
    InvalidFormScrollableDirective,
    PhoneNumber,
    requiredValidForSmsPhoneNumberValidator,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_COUNTRY_CODE, LEGAL_AGE, MIN_DATE_OF_BIRTH, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { LegalGuardianDetailsEditionDialogData, LegalGuardianDetailsUpdate } from "../../../../shared";

const MAX_LEGAL_AGE_YEARS = new Date();
MAX_LEGAL_AGE_YEARS.setFullYear(MAX_LEGAL_AGE_YEARS.getFullYear() - LEGAL_AGE);
const MAX_NAME_LENGTH = 100;

interface LegalGuardianForm {
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    dateOfBirth: FormControl<Date | null>;
    nationality: FormControl<Alpha3CountryCode | null>;
    phoneNumber: FormControl<PhoneNumber | null>;
    email: FormControl<string | null>;
    address: FormControl<BasicAddress | null>;
    country: FormControl<Alpha3CountryCode | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-legal-guardian-details-edition",
    templateUrl: "./legal-guardian-details-edition.component.html",
    styleUrls: ["./legal-guardian-details-edition.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalGuardianDetailsEditionComponent implements OnInit {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    private readonly newValueSubject = new Subject<LegalGuardianDetailsUpdate>();
    public readonly newValue$ = this.newValueSubject.asObservable();
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly MAX_LEGAL_AGE_YEARS = MAX_LEGAL_AGE_YEARS;
    protected readonly MIN_DATE_OF_BIRTH = MIN_DATE_OF_BIRTH;
    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly addressControl = new FormControl<BasicAddress>({
        streetName: "",
        houseNumber: "",
        apartmentNumber: "",
        postCode: "",
        city: "",
    });
    protected readonly legalGuardianDetailsEditionForm = new FormGroup<LegalGuardianForm>({
        firstName: new FormControl<string | null>(null, {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
        }),
        lastName: new FormControl<string | null>(null, {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
        }),
        dateOfBirth: new FormControl<Date | null>(null, Validators.required),
        nationality: new FormControl<Alpha3CountryCode | null>(null, Validators.required),
        email: new FormControl<string | null>(null, {
            validators: [Validators.required, Validators.email],
        }),
        phoneNumber: new FormControl<PhoneNumber | null>(null, {
            validators: requiredValidForSmsPhoneNumberValidator,
        }),
        address: this.addressControl,
        country: new FormControl<Alpha3CountryCode | null>(null, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: LegalGuardianDetailsEditionDialogData,
        private readonly matDialog: MatDialogRef<LegalGuardianDetailsEditionComponent>,
        private readonly translocoHelper: TranslationHelperService
    ) {}

    public ngOnInit() {
        this.data.isProcessing$.pipe(untilDestroyed(this)).subscribe((isProcessing) => {
            if (isProcessing) {
                this.legalGuardianDetailsEditionForm.disable();

                return;
            }
            this.legalGuardianDetailsEditionForm.enable();
        });

        if (this.data.legalGuardianConfirmation) {
            this.legalGuardianDetailsEditionForm.patchValue(this.data.legalGuardianConfirmation);
        }

        if (this.data.legalGuardian) {
            this.legalGuardianDetailsEditionForm.patchValue({
                ...this.data.legalGuardian,
                country: this.data.legalGuardian?.address.country,
            });
        }
    }

    protected confirm() {
        this.legalGuardianDetailsEditionForm.markAllAsTouched();

        if (this.legalGuardianDetailsEditionForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        if (!this.legalGuardianDetailsEditionForm.dirty) {
            this.matDialog.close();

            return;
        }

        const formValue = this.legalGuardianDetailsEditionForm.getRawValue();
        const values = {
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            email: formValue.email,
            phoneNumber: formValue.phoneNumber,
            dateOfBirth: formValue.dateOfBirth,
            nationality: formValue.nationality,
            address: {
                ...formValue.address,
                country: formValue.country,
            },
        };

        this.legalGuardianDetailsEditionForm.disable();
        this.newValueSubject.next(values as LegalGuardianDetailsUpdate);
    }
}
