import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import {
    AnimationUtils,
    FunctionUtils,
    NATIONAL_COURT_REGISTRATION_LENGTH,
    NATIONAL_COURT_REGISTRATION_MASK,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged } from "rxjs/operators";
import { AssociationOperatorDetails, AssociationRegistrationIdType } from "../../models/pilot-and-operator.models";

const ASSOCIATION_REGISTRATION_NUMBER_MAX_LENGTH = 30;

export interface AssociationOperatorIdForm {
    associationRegistrationId: FormControl<string | null>;
    associationRegistrationIdType: FormControl<AssociationRegistrationIdType | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-association-id-details-control",
    templateUrl: "./association-id-details-control.component.html",
    styleUrls: ["./association-id-details-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AssociationIdDetailsControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AssociationIdDetailsControlComponent),
            multi: true,
        },
    ],
})
export class AssociationIdDetailsControlComponent implements OnInit, ControlValueAccessor, Validator {
    @Input()
    public set associationId(value: AssociationOperatorDetails) {
        this.associationIdTypeControl.setValue(value.associationRegistrationIdType);
        this.addAssociationIdControl(value.associationRegistrationId);

        if (!this.validate(this.associationIdForm)) {
            this.propagateChange(value);
        }

        this.onValidationChange();
    }

    protected readonly AssociationRegistrationIdType = AssociationRegistrationIdType;
    protected readonly NATIONAL_COURT_REGISTRATION_MASK = NATIONAL_COURT_REGISTRATION_MASK;
    protected readonly associationIdTypeControl = new FormControl<AssociationRegistrationIdType | null>(null, {
        validators: [Validators.required],
    });
    protected readonly associationIdControl = new FormControl<string>(
        { value: "", disabled: true },
        {
            validators: [Validators.required, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
            nonNullable: true,
        }
    );
    protected readonly associationIdForm = new FormGroup<AssociationOperatorIdForm>({
        associationRegistrationIdType: this.associationIdTypeControl,
        associationRegistrationId: this.associationIdControl,
    });

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: AssociationOperatorDetails) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private readonly registrationNumberValidator = Validators.maxLength(ASSOCIATION_REGISTRATION_NUMBER_MAX_LENGTH);
    private readonly nationalCourtRegisterValidator = Validators.minLength(NATIONAL_COURT_REGISTRATION_LENGTH);

    public ngOnInit() {
        this.associationIdForm.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.associationIdForm.getRawValue() as AssociationOperatorDetails);
            this.propagateTouch();
        });
    }

    public registerOnChange(fn: (value: AssociationOperatorDetails) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (this.associationIdForm.valid) {
            return null;
        }

        return this.associationIdForm.invalid ? { invalidAssociationIdForm: true } : null;
    }

    public writeValue(value: AssociationOperatorDetails | null): void {
        if (value) {
            this.associationId = value;

            return;
        }
    }

    protected changeAssociationRegistrationIdType(associationTypeValue: AssociationRegistrationIdType) {
        if (associationTypeValue === AssociationRegistrationIdType.RegistrationNumber) {
            this.associationIdControl.addValidators(this.registrationNumberValidator);
            this.associationIdControl.removeValidators(this.nationalCourtRegisterValidator);
        } else {
            this.associationIdControl.addValidators(this.nationalCourtRegisterValidator);
            this.associationIdControl.removeValidators(this.registrationNumberValidator);
        }

        this.associationIdControl.setValue("");
        this.associationIdForm.controls.associationRegistrationId.enable();
    }

    private addAssociationIdControl(value?: string) {
        if (value) {
            this.associationIdControl.setValue(value);
            this.associationIdControl.enable();

            return;
        }
        this.associationIdControl.disable();
    }
}
