import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ChangeLogType } from "../../../../shared";
import { OperatorDetails, QualificationType } from "../../../../shared/models/operator.models";

interface MessagesOperatorProfileComponentState {
    profile: OperatorDetails | undefined;
}

@Component({
    selector: "dtm-admin-lib-messages-interlocutor-profile[profile]",
    templateUrl: "./messages-interlocutor-profile.component.html",
    styleUrls: ["./messages-interlocutor-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MessagesInterlocutorProfileComponent {
    @Input() public set profile(value: OperatorDetails | undefined) {
        this.localStore.patchState({ profile: value });
    }

    protected readonly profile$ = this.localStore.selectByKey("profile");
    protected readonly QualificationType = QualificationType;
    protected readonly ChangeLogType = ChangeLogType;

    constructor(private readonly localStore: LocalComponentStore<MessagesOperatorProfileComponentState>) {
        localStore.setState({ profile: undefined });
    }
}
