import { InjectionToken } from "@angular/core";

export interface ConversationEndpoints {
    getCapabilities: string;
    getOrAddThreads: string;
    getOperatorCapabilities: string;
    getOrAddMessages: string;
    uploadFile: string;
    downloadFile: string;
    downloadCompressedFiles: string;
    manageThread: string;
    changeThreadAssignment: string;
    changeThreadCategory: string;
}

export const CONVERSATION_ENDPOINTS = new InjectionToken<ConversationEndpoints>("Conversation endpoints");
