import { AssociationOperationType, AssociationOperator, CaaContactPerson, ContactPerson } from "@dtm-frontend/shared/ui";

export enum AssociationPermitWizardSteps {
    BasicInfo = "BasicInfo",
    PermitDetails = "PermitDetails",
    Summary = "Summary",
}

export interface AssociationPermitWizardBasicDetails {
    associationInfo: AssociationOperator;
    operatorContact: ContactPerson;
    caaContact: CaaContactPerson;
}

export interface AssociationPermitWizardPermitDetails {
    permitNumber: string;
    expirationDate: {
        dateFrom: Date;
        dateTo: Date;
    };
    flightPurpose: string;
    locations: string;
    operationType: AssociationOperationType;
    isInsurance: boolean;
}
