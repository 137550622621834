<div class="grid">
    <dtm-admin-lib-admin-users-filter (filtersChange)="applyFilters($event)" [initialFilters]="filtersQuery$ | ngrxPush">
    </dtm-admin-lib-admin-users-filter>
    <mat-card>
        <div class="header">
            <h2>{{ "dtmAdminLibAdminUsers.users.usersListHeader" | transloco }}</h2>
            <button type="button" class="button-primary" (click)="openAddUserDialog()">
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmAdminLibAdminUsers.users.addUserLabel" | transloco }}
            </button>
        </div>
        <dtm-admin-lib-admin-users-list
            [usersList]="usersList$ | ngrxPush"
            [usersListError]="usersListError$ | ngrxPush"
            [isUsersListProcessing]="isUsersListProcessing$ | ngrxPush"
            [pagination]="pagination$ | ngrxPush"
            (userEdit)="editUser($event)"
            (userDelete)="deleteUser($event)"
            (passwordReset)="resetPassword($event)"
            (usersListRefresh)="refreshUsersList()"
            (pageChange)="changePage($event)"
        >
        </dtm-admin-lib-admin-users-list>
    </mat-card>
</div>
