import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirspaceElement } from "@dtm-frontend/shared/map/geo-zones";
import { EmptyStateMode, MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { MissionCategory, MissionContextType, MissionDataSimple, MissionType } from "../../../../shared/models/mission.models";
import { MissionDetails } from "../../../models/mission-search.models";

interface MissionSearchPreviewComponentState {
    mission: MissionDetails | undefined;
    route: MissionPlanRoute | undefined;
    isProcessing: boolean;
    selectedZoneId: string | undefined;
    flightPurposesTranslationKeys: Record<string, string>;
    isExternalAppButtonVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-mission-mission-search-preview[mission]",
    templateUrl: "./mission-search-preview.component.html",
    styleUrls: ["./mission-search-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionSearchPreviewComponent {
    @Input() public set mission(value: MissionDetails | undefined) {
        this.localStore.patchState({ mission: value });
    }

    @Input() public set route(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ route: value });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }

    @Input() public set flightPurposesTranslationKeys(value: Record<string, string> | undefined) {
        this.localStore.patchState({ flightPurposesTranslationKeys: value ?? {} });
    }

    @Input() public set isExternalAppButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isExternalAppButtonVisible: coerceBooleanProperty(value) });
    }

    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();
    @Output() public readonly externalAppShow = new EventEmitter<void>();

    protected readonly MissionContextType = MissionContextType;
    protected readonly MissionCategory = MissionCategory;
    protected readonly MissionType = MissionType;
    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly route$ = this.localStore.selectByKey("route");
    protected readonly isExternalAppButtonVisible$ = this.localStore.selectByKey("isExternalAppButtonVisible");
    protected readonly missionData$ = this.mission$.pipe(map((mission) => this.prepareMissionData(mission)));

    constructor(private readonly localStore: LocalComponentStore<MissionSearchPreviewComponentState>) {
        this.localStore.setState({
            mission: undefined,
            route: undefined,
            isProcessing: false,
            selectedZoneId: undefined,
            flightPurposesTranslationKeys: {},
            isExternalAppButtonVisible: false,
        });
    }

    private prepareMissionData(mission: MissionDetails): MissionDataSimple {
        const flightPurposes = this.localStore.selectSnapshotByKey("flightPurposesTranslationKeys");

        return {
            isRoutePathBased: mission.isRoutePathBased,
            flightStartAtMin: mission.flightStartAtMin,
            flightStartAtMax: mission.flightStartAtMax,
            flightFinishAtMin: mission.flightFinishAtMin,
            flightFinishAtMax: mission.flightFinishAtMax,
            phase: mission.phase,
            distance: mission.distance,
            operatorName: mission.operatorName,
            pilotName: mission.pilotName,
            uavName: mission.uavName,
            uavSerialNumbers: mission.uavSerialNumbers,
            trackersIdentifiers: mission.trackersIdentifiers,
            category: mission.category,
            flightPurpose: {
                nameTranslationKey: mission.flightPurposeId ? flightPurposes[mission.flightPurposeId] ?? "" : "",
                comment: mission.flightPurposeComment,
            },
            additionalCrew: [],
        };
    }
}
