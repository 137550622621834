import { PageResponseBody } from "@dtm-frontend/shared/ui";
import {
    OperationScenarioStatement,
    OperationScenarioStatementsListWithPages,
    PaymentStatusInfoCode,
} from "./operation-scenario-statements.models";

interface OperationScenarioStatementResponseBody
    extends Omit<OperationScenarioStatement, "createdAt" | "attorneyPowerAdministrativeFee" | "signer"> {
    createdAt: string;
    signer?: {
        dateOfBirth: string;
        lastName: string;
        name: string;
        signedAt: string;
    };
    attorneyPowerAdministrativeFee: {
        feeDocumentId?: string;
        declarationOfExemption: boolean;
    };
}

export type OperationScenarioStatementsListResponseBody = PageResponseBody<OperationScenarioStatementResponseBody>;

function getStatementsPaymentInfoCode(info?: string): PaymentStatusInfoCode {
    switch (info) {
        case "user_directory.payment.service_unavailable":
            return PaymentStatusInfoCode.ServiceUnavailable;
        case "user_directory.payment.transaction_rejected":
            return PaymentStatusInfoCode.TransactionRejected;
        case "user_directory.payment.transaction_timeout":
            return PaymentStatusInfoCode.TransactionTimeout;
        default:
            return PaymentStatusInfoCode.Unknown;
    }
}

export function convertOperationScenarioStatementsListResponseBodyToOperationScenarioStatementsListWithPages(
    response: OperationScenarioStatementsListResponseBody
): OperationScenarioStatementsListWithPages {
    return {
        content: response.content.map(
            (operationScenarioStatementResponse): OperationScenarioStatement => ({
                id: operationScenarioStatementResponse.id,
                operatorId: operationScenarioStatementResponse.operatorId,
                operatorNumber: operationScenarioStatementResponse.operatorNumber,
                createdAt: new Date(operationScenarioStatementResponse.createdAt),
                payment: {
                    status: operationScenarioStatementResponse.payment.status,
                    info: getStatementsPaymentInfoCode(operationScenarioStatementResponse.payment.info),
                },
                signature: operationScenarioStatementResponse.signature,
                signer: operationScenarioStatementResponse.signer
                    ? {
                          dateOfBirth: new Date(operationScenarioStatementResponse.signer.dateOfBirth),
                          lastName: operationScenarioStatementResponse.signer.lastName,
                          firstName: operationScenarioStatementResponse.signer.name,
                          signedAt: new Date(operationScenarioStatementResponse.signer.signedAt),
                      }
                    : undefined,
                operationScenarios: operationScenarioStatementResponse.operationScenarios,
                attorneyPowerAdministrativeFee: operationScenarioStatementResponse.attorneyPowerAdministrativeFee
                    ? {
                          feeDocumentId: operationScenarioStatementResponse.attorneyPowerAdministrativeFee?.feeDocumentId,
                          isDeclarationOfExemption:
                              operationScenarioStatementResponse.attorneyPowerAdministrativeFee?.declarationOfExemption,
                      }
                    : undefined,
            })
        ),
        pages: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}
