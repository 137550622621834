<dtm-ui-input-field matAutocompleteOrigin #autocompleteOrigin="matAutocompleteOrigin">
    <ng-content select="label" ngProjectAs="label"></ng-content>
    <input
        matInput
        [placeholder]="'dtmAdminLibShared.operatorSearchAutocompleteControl.placeholder' | transloco"
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="autocompleteOrigin"
        (focusout)="propagateTouch()"
    />
    <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
    <mat-autocomplete
        class="options-list"
        (optionSelected)="optionSelected($event)"
        [displayWith]="displayAutocompleteValueFn"
        #auto="matAutocomplete"
    >
        <ng-content select="mat-option"></ng-content>

        <mat-option *ngFor="let option of options$ | ngrxPush" [value]="option">
            <ng-container *ngrxLet="optionTemplate$ | ngrxPush as customTemplate">
                <ng-container
                    [ngTemplateOutlet]="customTemplate ?? defaultTemplate"
                    [ngTemplateOutletContext]="{
                        option: option
                    }"
                ></ng-container>
            </ng-container>
        </mat-option>
    </mat-autocomplete>
    <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
    <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
</dtm-ui-input-field>

<ng-template #defaultTemplate let-option="option">{{ option.name }}</ng-template>
