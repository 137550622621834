<div *ngIf="profile$ | ngrxPush as profile" class="card">
    <div class="card-title">
        {{ "dtmAdminLibShared.operatorProfile.insurancePolicy.titleTile" | transloco }}
    </div>
    <ng-container *ngIf="profile.insurancePolicyNumber; else emptyStateTemplate">
        <dtm-ui-label-value
            [label]="'dtmAdminLibShared.operatorProfile.insurancePolicy.insuranceNumberLabel' | transloco"
            [value]="profile.insurancePolicyNumber"
        >
        </dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="profile.insurancePolicyExpirationDate"
            [label]="'dtmAdminLibShared.operatorProfile.insurancePolicy.expirationDateLabel' | transloco"
            [value]="profile.insurancePolicyExpirationDate | localizeDate"
        >
        </dtm-ui-label-value>
    </ng-container>
    <ng-template #emptyStateTemplate>
        {{ "dtmAdminLibShared.operatorProfile.insurancePolicy.emptyStateLabel" | transloco }}
    </ng-template>
</div>
