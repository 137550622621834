import { AdminUsersQueryParams, NewAdminUserValue } from "../services/models";

export namespace AdminUsersActions {
    export class GetUsers {
        public static readonly type = "[Admin Users] Get users";

        constructor(public payload: AdminUsersQueryParams) {}
    }

    export class AddUser {
        public static readonly type = "[Admin Users] Add user";

        constructor(public payload: NewAdminUserValue) {}
    }

    export class EditUser {
        public static readonly type = "[Admin Users] Edit user";

        constructor(public payload: NewAdminUserValue, public adminUserId: string) {}
    }

    export class DeleteUser {
        public static readonly type = "[Admin Users] Delete user";

        constructor(public adminUserId: string) {}
    }

    export class ResetUsersPassword {
        public static readonly type = "[Admin Users] Reset user's password";

        constructor(public adminUserId: string) {}
    }
}
