import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { ConversationActions } from "../state/conversation.actions";

@Injectable({
    providedIn: "root",
})
export class MessagesListResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        const threadId = route.params.threadId;

        return lastValueFrom(this.store.dispatch(new ConversationActions.GetMessagesByThread(threadId)));
    }
}
