<div *ngrxLet="status$ as status" [ngSwitch]="status">
    <span *ngSwitchCase="UtmUserStatus.Active" class="chip success">
        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
        {{ "dtmAdminLibUtmUsers.userStatus.active" | transloco }}
    </span>
    <span *ngSwitchCase="UtmUserStatus.Waiting" class="chip secondary">
        <dtm-ui-icon name="markup"></dtm-ui-icon>
        {{ "dtmAdminLibUtmUsers.userStatus.waiting" | transloco }}
    </span>
</div>
