import { Injectable } from "@angular/core";
import { Action, createSelector, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { CaaFeatures, GlobalCapabilitiesError, GlobalCapabilitiesUser } from "../models/global-capabilities.models";
import { GlobalCapabilitiesApiService } from "../services/global-capabilities-api.service";
import { GlobalCapabilitiesActions } from "./global-capabilities.actions";

export interface GlobalCapabilitiesModel {
    isProcessing: boolean;
    globalCapabilitiesUser: GlobalCapabilitiesUser | undefined;
    globalCapabilitiesError: GlobalCapabilitiesError | undefined;
    features: CaaFeatures[] | undefined;
}

const defaultState: GlobalCapabilitiesModel = {
    isProcessing: false,
    globalCapabilitiesUser: undefined,
    globalCapabilitiesError: undefined,
    features: undefined,
};

@State<GlobalCapabilitiesModel>({
    name: "globalCapabilitiesContext",
    defaults: defaultState,
})
@Injectable()
export class GlobalCapabilitiesState {
    constructor(private readonly globalCapabilitiesApiService: GlobalCapabilitiesApiService) {}

    @Selector()
    public static globalCapabilitiesError(state: GlobalCapabilitiesModel): GlobalCapabilitiesError | undefined {
        return state.globalCapabilitiesError;
    }

    @Selector()
    public static globalCapabilitiesUser(state: GlobalCapabilitiesModel): GlobalCapabilitiesUser | undefined {
        return state.globalCapabilitiesUser;
    }

    @Selector()
    public static isProcessing(state: GlobalCapabilitiesModel): boolean {
        return state.isProcessing;
    }

    public static isFeatureAvailable(feature: CaaFeatures) {
        return createSelector([GlobalCapabilitiesState], (state: GlobalCapabilitiesModel) =>
            !state.features ? false : state.features.includes(feature)
        );
    }

    @Action(GlobalCapabilitiesActions.GetGlobalCapabilities)
    public getGlobalCapabilities(context: StateContext<GlobalCapabilitiesModel>) {
        context.patchState({ isProcessing: true, globalCapabilitiesError: undefined });

        return this.globalCapabilitiesApiService.getUserCapabilities().pipe(
            map((result) => {
                context.patchState({
                    globalCapabilitiesUser: result.user,
                    features: result.features ?? [],
                });
            }),
            catchError((error) => {
                context.patchState({ globalCapabilitiesError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(GlobalCapabilitiesActions.RefreshCapabilitiesAfterAvatarChange)
    public refreshCapabilitiesAfterAvatarChange(context: StateContext<GlobalCapabilitiesModel>) {
        const globalCapabilitiesUser = context.getState().globalCapabilitiesUser;
        if (!globalCapabilitiesUser) {
            return;
        }

        const updatedGlobalCapabilitiesUser = {
            firstName: globalCapabilitiesUser.firstName,
            lastName: globalCapabilitiesUser.lastName,
            avatarUrl: undefined,
        };

        context.patchState({ globalCapabilitiesUser: updatedGlobalCapabilitiesUser });

        return context.dispatch(GlobalCapabilitiesActions.GetGlobalCapabilities);
    }
}
