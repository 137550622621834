import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { ConversationActions } from "../state/conversation.actions";

export const PAGE_NUMBER_QUERY_PARAM = "page";
export const PAGE_SIZE_QUERY_PARAM = "pageSize";

@Injectable({
    providedIn: "root",
})
export class ConversationResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        this.store.dispatch(ConversationActions.ClearStoredAvatarPictures);

        return lastValueFrom(this.store.dispatch(ConversationActions.GetConversationsCapabilities));
    }
}
