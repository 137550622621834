<dtm-ui-lib-wizard-header [wizardId]="SPECIFIC_PERMIT_FLIGHT_WIZARD_ID">
    <dtm-ui-lib-wizard-step-header [stepId]="SpecificPermitFlightWizardSteps.BasicInfo">
        {{ "dtmAdminLibPermits.crossBorderAndSpecificPermitPermitFlightWizardHeader.restrictionsStepTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="SpecificPermitFlightWizardSteps.UavDetails">
        {{ "dtmAdminLibPermits.crossBorderAndSpecificPermitPermitFlightWizardHeader.uavDetailsStepTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="SpecificPermitFlightWizardSteps.Summary">
        {{ "dtmAdminLibPermits.crossBorderAndSpecificPermitPermitFlightWizardHeader.summaryStepTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
</dtm-ui-lib-wizard-header>
