import {
    Address,
    Alpha3CountryCode,
    OperatorAndPilotQualification,
    OperatorStatus,
    PhoneNumber,
    QualificationStatus,
    UploadedFile,
} from "@dtm-frontend/shared/ui";
import { Observable } from "rxjs";

export enum OperatorType {
    Personal = "PERSONAL",
    Enterprise = "ENTERPRISE",
    Association = "ASSOCIATION",
}

export interface Operator {
    id: string;
    status: OperatorStatus;
    operatorNumber: string;
    name: string;
    type: OperatorType;
}

export interface BaseOperator {
    id: string;
    name: string;
    number: string;
}

export interface OperatorStatusChange {
    newStatus: OperatorStatus;
    operator: OperatorDetails;
    reason?: string;
}

export interface UpdateOperatorDetailsError {
    type: UpdateOperatorDetailsErrorType;
}

export enum UpdateOperatorDetailsErrorType {
    CannotChangeStatus = "CannotChangeStatus",
    Unknown = "Unknown",
}

export interface QualificationStatusChange {
    qualificationId?: string;
    status: QualificationStatus;
    reason?: string;
}

export enum QualificationChangeType {
    Activate = "Activate",
    Suspend = "Suspend",
    Withdraw = "Withdraw",
    Delete = "Delete",
    Add = "Add",
}

export enum OperatorActionType {
    UpdatePilotCompetency = "UpdatePilotCompetency",
    SuspendAllPilotCompetencies = "SuspendAllPilotCompetencies",
    UpdateOperationalAuthorization = "UpdateOperationalAuthorization",
    ChangeStatus = "ChangeStatus",
    UpdateAttorneyPower = "UpdateAttorneyPower",
    AddOtherInformation = "AddOtherInformation",
    EditOtherInformation = "EditOtherInformation",
    DeleteOtherInformation = "DeleteOtherInformation",
    DeleteOperator = "DeleteOperator",
}

export interface UpdateAttorneyPowerError {
    type: UpdateAttorneyPowerErrorType;
}

export enum UpdateAttorneyPowerErrorType {
    CannotUpdate = "CannotUpdate",
}

export interface OperatorDetails {
    id: string;
    status: OperatorStatus;
    suspensionReason: string | null;
    pilotId: string | null;
    pilotNumber?: string;
    owner: Owner;
    operatorNumber: string;
    secret?: string;
    type: OperatorType;
    name: string;
    operatorInfo: OperatorInfo;
    insurancePolicyNumber?: string;
    insurancePolicyExpirationDate?: string;
    operationalAuthorizations: OperatorAndPilotQualification[];
    pilotCompetencies?: OperatorAndPilotQualification[];
    legalGuardian?: LegalGuardian | null;
    attorneyPowers?: AttorneyPower[];
    administrativeFee?: AdministrativeFee;
    otherInformation: OtherInformation[];
    competencyConfirmations: ForeignCompetencyDocument[];
    administrators?: Administrator[];
}

export interface LegalGuardianDetailsUpdate {
    address: Address;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    dateOfBirth: Date;
    nationality: string;
}

export interface Owner {
    userId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    nationality: Alpha3CountryCode;
    phoneNumber: PhoneNumber;
    email: string;
    status: OwnerStatus;
    isLegalAge?: boolean;
}

export enum OwnerStatus {
    Active = "ACTIVE",
    Waiting = "WAITING",
}

export interface CompanyInfo {
    name: string;
    companyNumber: string;
    phoneNumber: PhoneNumber;
    email: string;
    address: Address;
}

export interface AssociationInfo extends CompanyInfo {
    nationalCourtRegister: string;
    registrationNumber: string;
}

export interface OperatorInfo {
    name: string;
    companyNumber?: string;
    phoneNumber?: PhoneNumber;
    email?: string;
    nationalCourtRegister?: string;
    registrationNumber?: string;
    address: Address;
}

export interface AttorneyPower {
    id: string;
    grantee: AttorneyPowerGrantee;
    type: AttorneyPowerType;
    status: AttorneyPowerStatus;
    nationalCourtRegister?: string;
    documentId?: string;
    documentFileName?: string;
    permissions?: PermissionsToGrant[];
}

export interface AttorneyPowerGrantee {
    userId: string;
    firstName: string;
    lastName: string;
}

export enum AttorneyPowerType {
    Owner = "OWNER",
    NationalCourtNumber = "NATIONAL_COURT_NUMBER",
    ConfirmatoryDocument = "CONFIRMATORY_DOCUMENT",
}

export enum AttorneyPowerStatus {
    Approved = "APPROVED",
    PendingApproval = "PENDING_APPROVAL",
}

export interface AttorneyPowerUpdate {
    id: string;
    status: AttorneyPowerStatus;
    permissions: PermissionsToGrant[];
}

export enum PermissionsToGrant {
    MakingStatements = "MAKING_STATEMENTS",
    SpecificPermitApply = "SPECIFIC_PERMIT_APPLY",
}

export interface AdministrativeFee {
    feeConfirmationId?: string;
    isDeclarationOfExemption: boolean;
    filename?: string;
}

export interface OperatorNumber {
    number: string;
    secret: string;
}

export interface LegalGuardian extends LegalGuardianConfirmation {
    phoneNumber: PhoneNumber;
    address: Address;
    dateOfBirth: Date;
    nationality: string;
    signer?: {
        firstName: string;
        lastName: string;
        dateOfBirth: Date;
        personalIdentifier: string;
        signedAt: Date;
    };
}

export interface LegalGuardianConfirmation {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface NewQualification {
    operationScenarioIds: string[];
    expirationDate: string | null;
}

export interface NewOperationalAuthorization {
    id?: string;
    uavSerialNumbers: string[];
    expirationDate: string | Date;
}

export interface OperatorError {
    type: OperatorErrorType;
}

export enum OperatorErrorType {
    Unknown = "OperatorErrorUnknown",
    NotFound = "OperatorErrorNotFound",
    NotAuthorized = "OperatorErrorNotAuthorized",
    NoOperatorType = "NoOperatorType",
}

export interface Attachment {
    documentId: string;
    documentFileName: string;
}

export enum OperatorDocumentType {
    AttorneyPower = "ATTORNEY_POWER",
    AdministrativeFee = "ADMINISTRATIVE_FEE",
}

export enum QualificationType {
    PilotCompetencies = "PilotCompetencies",
    OperationalAuthorizations = "OperationalAuthorizations",
}

export interface LegalGuardianDetailsEditionDialogData {
    legalGuardian?: LegalGuardian;
    legalGuardianConfirmation?: LegalGuardianConfirmation;
    isProcessing$: Observable<boolean>;
}

export interface OperationScenario {
    id: string;
    name: string;
    grantValidityInMonths: number;
    expirationDate?: string;
    areUavsRequired?: boolean;
}

export interface OtherInformation {
    id: string;
    text: string;
}

export enum OtherInformationActionType {
    Edit = "edit",
    Add = "add",
}

export interface ForeignCompetencyDocument {
    id: string;
    status: ForeignCompetencyStatus;
    statusReason?: string;
    type: ForeignCompetencyType;
    expirationDate: Date;
    file: UploadedFile;
    pilotId: string;
}

export enum ForeignCompetencyType {
    A1_A3 = "A1A3",
    A2 = "A2",
}

export enum ForeignCompetencyStatus {
    Accepted = "ACCEPTED",
    Waiting = "WAITING",
    Rejected = "REJECTED",
}

export interface Administrator {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
}
