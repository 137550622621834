import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import {
    ExamTrainingResultsFilterFormKeys,
    ExamTrainingResultsReportType,
    ExamTrainingResultsTabType,
} from "../../models/exam-training-results.models";

interface ExamTrainingResultsHeaderComponentState {
    activeTabIndex: ExamTrainingResultsTabType | undefined;
}

@Component({
    selector: "dtm-admin-lib-exam-training-results-header[activeTabIndex]",
    templateUrl: "./exam-training-results-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ExamTrainingResultsHeaderComponent {
    @Input() public set activeTabIndex(value: ExamTrainingResultsTabType | undefined) {
        this.localStore.patchState({ activeTabIndex: value });
    }
    @Output() public readonly tabIndexUpdate = new EventEmitter<ExamTrainingResultsTabType>();
    @Output() public readonly resultsAdd = new EventEmitter<ExamTrainingResultsReportType>();

    protected readonly activeTabIndex$ = this.localStore.selectByKey(ExamTrainingResultsFilterFormKeys.ActiveTabIndex);
    protected readonly ExamTrainingResultsReportType = ExamTrainingResultsReportType;

    constructor(private readonly localStore: LocalComponentStore<ExamTrainingResultsHeaderComponentState>) {
        this.localStore.setState({
            activeTabIndex: undefined,
        });
    }

    protected onUpdateTabIndex(activeTabIndex: ExamTrainingResultsTabType): void {
        this.localStore.patchState({ activeTabIndex });
        this.tabIndexUpdate.next(activeTabIndex);
    }
}
