import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EmptyStateMode } from "@dtm-frontend/shared/ui";
import { DateUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import {
    MissionCategory,
    MissionDataSimple,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionPlanStsOperationCategoryOption,
} from "../../../models";
import { MissionProcessingPhase } from "../../../models/mission.models";

interface MissionDataSimpleComponentState {
    mission: MissionDataSimple | undefined;
}

@Component({
    selector: "dtm-mission-mission-data-simple[mission]",
    templateUrl: "./mission-data-simple.component.html",
    styleUrls: ["./mission-data-simple.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionDataSimpleComponent {
    @Input() public set mission(value: MissionDataSimple | undefined) {
        this.localStore.patchState({ mission: value });
    }

    protected readonly MissionPlanSpecificPermitType = MissionPlanSpecificPermitType;
    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly mission$ = this.localStore.selectByKey("mission");

    constructor(private readonly localStore: LocalComponentStore<MissionDataSimpleComponentState>) {
        this.localStore.setState({
            mission: undefined,
        });
    }

    protected getOpenCategory(category?: MissionPlanOperationCategoryOption) {
        if (category?.type === MissionCategory.Open) {
            return category;
        }

        return;
    }

    protected getSpecificCategory(category?: MissionPlanOperationCategoryOption) {
        if (category?.type === MissionCategory.Specific) {
            return category;
        }

        return;
    }

    protected isSoraApplication(category: MissionPlanOperationCategoryOption | undefined): boolean {
        return category?.type === MissionCategory.Specific && category.specificPermitType === MissionPlanSpecificPermitType.Individual;
    }

    protected getAsStsCategoryType(category?: MissionPlanOperationCategoryOption) {
        return category as MissionPlanStsOperationCategoryOption;
    }

    protected getDaysBetween(mission: MissionDataSimple): number {
        const daysBetween = DateUtils.daysBetween(mission.flightStartAtMin, mission.flightFinishAtMax);

        return Math.max(daysBetween + 1, 1);
    }
}
