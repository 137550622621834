import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { SHARED_MAP_ENDPOINTS, SharedMapEndpoints } from "@dtm-frontend/shared/map";
import { AZURE_MAPS_LAYER_OPTIONS, DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS } from "@dtm-frontend/shared/map/cesium";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcMapComponent, SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import equal from "fast-deep-equal";
import { distinctUntilChanged } from "rxjs/operators";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface AreaMapContainerComponentState {
    layers: Blob[];
    kmlDataSources: unknown[];
}

const DEFAULT_INITIAL_VIEWBOX_COORDINATES = [
    [
        /* eslint-disable no-magic-numbers*/
        [20.764778015913926, 52.34398333338602],
        [21.27486090157105, 52.33982648474249],
        [21.20705078322777, 52.09924302933922],
        [20.8019476103596, 52.10816433369502],
        [20.764778015913926, 52.34398333338602],
        /* eslint-enable no-magic-numbers*/
    ],
];

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-area-map-container",
    templateUrl: "./area-map-container.component.html",
    styleUrls: ["./area-map-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AreaMapContainerComponent implements AfterViewInit {
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly layers$ = this.localStore.selectByKey("layers");
    protected readonly initialViewbox$ = this.getInitialViewBox();
    @ViewChild(AcMapComponent) private acMap: AcMapComponent | undefined;

    constructor(
        viewerConfiguration: ViewerConfiguration,
        @Inject(SHARED_MAP_ENDPOINTS) public readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly localStore: LocalComponentStore<AreaMapContainerComponentState>
    ) {
        localStore.setState({ layers: [], kmlDataSources: [] });
        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE2D,
        };
    }

    public ngAfterViewInit() {
        this.watchLayersChange();
    }

    protected updateLayers(layers: Blob[]) {
        this.localStore.patchState({ layers });
    }

    private getInitialViewBox() {
        const bbox = turfBbox({
            type: "Polygon",
            coordinates: DEFAULT_INITIAL_VIEWBOX_COORDINATES,
        });

        Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);

        return Cesium.Camera.DEFAULT_VIEW_RECTANGLE;
    }

    private watchLayersChange() {
        const viewer = this.acMap?.getCesiumService().getViewer();
        if (!viewer) {
            console.error("Viewer not found");
        }
        const additionalLayer = new Cesium.ImageryLayer(
            new Cesium.WebMapServiceImageryProvider({
                url: this.sharedMapEndpoints.geoServerEndpoint,
                layers: this.sharedMapEndpoints.layersPrefix,
                enablePickFeatures: false,
                parameters: {
                    transparent: true,
                    format: "image/png",
                },
            })
        );
        viewer.imageryLayers.add(additionalLayer);

        this.layers$.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe((layers) => {
            if (layers.length) {
                layers.forEach((layer: Blob) => {
                    const kmlDataSource = new Cesium.KmlDataSource();
                    kmlDataSource.load(layer);

                    viewer.dataSources.add(kmlDataSource).then((data: unknown | string | Document | Blob) => {
                        viewer.zoomTo(data);
                        this.localStore.patchState(({ kmlDataSources }) => ({ kmlDataSources: [...kmlDataSources, kmlDataSource] }));
                    });
                });

                return;
            }

            const kmlSources = this.localStore.selectSnapshotByKey("kmlDataSources");
            if (kmlSources?.length) {
                kmlSources.forEach((dataSource) => viewer.dataSources.remove(dataSource));
                viewer.scene.requestRender();
                this.localStore.patchState({ kmlDataSources: [] });
            }
        });
    }
}
