import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { ProcessedFile } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

const ALLOWED_EXTENSION = ".kml";

interface AreaMapLayersControlComponentState {
    isOpen: boolean;
    layers: ProcessedFile[];
    processedFiles: ProcessedFile[];
}
@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-area-map-layers-control",
    templateUrl: "./area-map-layers-control.component.html",
    styleUrls: ["./area-map-layers-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class AreaMapLayersControlComponent {
    protected readonly isOpen$ = this.localStore.selectByKey("isOpen");
    protected readonly layers$ = this.localStore.selectByKey("layers");
    protected readonly processedFiles$ = this.localStore.selectByKey("processedFiles");
    protected readonly ALLOWED_EXTENSION = ALLOWED_EXTENSION;

    @Output() public readonly layersChange = new EventEmitter<Blob[]>();

    constructor(
        private readonly localStore: LocalComponentStore<AreaMapLayersControlComponentState>,
        private readonly transloco: TranslocoService
    ) {
        localStore.setState({ isOpen: false, layers: [], processedFiles: [] });

        this.layers$.pipe(untilDestroyed(this)).subscribe((layers) => this.layersChange.emit(this.getBlobsFromFiles(layers)));
    }

    protected toggleControlVisibility() {
        this.localStore.patchState(({ isOpen }) => ({ isOpen: !isOpen }));
    }

    protected addLayers(newLayers: ProcessedFile[]) {
        newLayers.forEach((layer) => {
            if (layer.file.name.includes(ALLOWED_EXTENSION)) {
                this.localStore.patchState(({ layers, processedFiles }) => ({
                    layers: [...layers, layer],
                    processedFiles: processedFiles.filter((file) => file.id !== layer.id),
                }));

                return;
            }
            layer.error = this.transloco.translate("dtmAdminLibAreaMap.layersControl.wrongExtensionError");
            this.localStore.patchState(({ processedFiles }) => ({ processedFiles: [...processedFiles, layer] }));
        });
    }

    protected removeLayers() {
        this.localStore.patchState({ layers: [] });
    }

    protected removeProcessedFile(file: ProcessedFile) {
        this.localStore.patchState(({ processedFiles }) => ({
            processedFiles: processedFiles.filter((processedFile) => processedFile.id !== file.id),
        }));
    }

    private getBlobsFromFiles(layers: ProcessedFile[]): Blob[] {
        return layers.map((layer) => layer.file);
    }
}
