<div class="form-wrapper" [formGroup]="restrictionsForm">
    <dtm-ui-input-field>
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.permitNumberLabel" | transloco }}</label>
        <input matInput formControlName="permitNumber" type="text" />
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.permitNumber; name: ['required', 'pattern']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
        <div class="field-error" *dtmUiFieldHasError="restrictionsForm.controls.permitNumber; name: 'maxlength'; error as error">
            {{
                "dtmAdminLibPermits.operationRestrictionsForm.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field *ngIf="restrictionsForm.controls.permitConfirmationNumber.enabled" @slideIn>
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.permitConfirmationNumberLabel" | transloco }}</label>
        <input matInput formControlName="permitConfirmationNumber" type="text" />
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.permitConfirmationNumber; name: ['required', 'pattern']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.permitConfirmationNumber; name: 'maxlength'; error as error"
        >
            {{
                "dtmAdminLibPermits.operationRestrictionsForm.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>

    <dtm-ui-date-field [formGroup]="restrictionsForm.controls.expirationDate">
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.expirationDateLabel" | transloco }}</label>
        <mat-date-range-input [rangePicker]="picker" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
            <input matStartDate formControlName="dateFrom" [placeholder]="datePickerPlaceholder" />
            <input matEndDate formControlName="dateTo" [placeholder]="datePickerPlaceholder" />
        </mat-date-range-input>
        <div
            class="field-error"
            *ngIf="
                (dateToControl.touched && dateToControl.errors?.required) || (dateFromControl.touched && dateFromControl.errors?.required)
            "
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>

        <mat-date-range-picker #picker></mat-date-range-picker>
    </dtm-ui-date-field>

    <dtm-ui-textarea-field [maxLength]="MAX_OPERATION_TARGET_LENGTH">
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.operationTargetLabel" | transloco }}</label>
        <textarea formControlName="operationTarget" matInput></textarea>
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.operationTarget; name: ['required', 'pattern']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
        <div class="field-error" *dtmUiFieldHasError="restrictionsForm.controls.operationTarget; name: 'maxlength'; error as error">
            {{
                "dtmAdminLibPermits.operationRestrictionsForm.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength }
            }}
        </div>
    </dtm-ui-textarea-field>

    <dtm-ui-select-field
        formControlName="localization"
        [placeholder]="'dtmAdminLibPermits.operationRestrictionsForm.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.localizationLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="localization.value" *ngFor="let localization of LocalizationType | keyvalue">
            {{ "dtmAdminLibPermits.operationRestrictionsForm.localizationSelectValue" | transloco : { value: localization.value } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.localization; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <ng-container *ngIf="restrictionsForm.controls.localization.value === LocalizationType.Kml">
        <dtm-ui-files-upload-field
            *ngIf="!restrictionsForm.controls.kmlFile.value.length"
            [allowedTypes]="ALLOWED_FILE_EXTENSION"
            [formControl]="restrictionsForm.controls.kmlFile"
        >
            <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.attachFileLabel" | transloco }}</label>
            <div class="field-hint">{{ "dtmAdminLibPermits.operationRestrictionsForm.attachFileHint" | transloco }}</div>
            <div
                class="field-error"
                *dtmUiFieldHasError="restrictionsForm.controls.kmlFile; name: ['required', 'requiredTouched']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
        </dtm-ui-files-upload-field>
        <dtm-ui-uploaded-files-display
            *ngIf="restrictionsForm.controls.kmlFile.value.length"
            canRemoveFile
            isDisplayFileVisible
            [uploadedFiles]="[restrictionsForm.controls.kmlFile.value[0]]"
            (tryRemoveFile)="removeFile()"
            (displayFile)="kmlFilePreview.emit(restrictionsForm.controls.kmlFile.value[0])"
        >
        </dtm-ui-uploaded-files-display>
    </ng-container>
    <dtm-ui-select-field
        *ngIf="restrictionsForm.controls.localization.value === LocalizationType.Dtm"
        formControlName="dtm"
        [placeholder]="'dtmAdminLibPermits.operationRestrictionsForm.chooseLabel' | transloco"
        [isClearable]="true"
    >
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.definedAreaLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let dtm of dtmLocations$ | ngrxPush" [value]="dtm">
            {{ dtm.name }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.dtm; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>

    <dtm-ui-select-field
        formControlName="riskMethodology"
        [placeholder]="'dtmAdminLibPermits.operationRestrictionsForm.chooseLabel' | transloco"
    >
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.riskMethodologyLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="version.value" *ngFor="let version of RiskAssessmentReference | keyvalue">
            {{ "dtmAdminLibPermits.operationRestrictionsForm.riskMethodologyValue" | transloco : { value: version.value } }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.riskMethodology; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>

    <dtm-ui-input-field *ngIf="restrictionsForm.controls.pdraNumber.enabled" @slideIn>
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.pdraNumberLabel" | transloco }}</label>
        <input matInput formControlName="pdraNumber" type="text" />
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.pdraNumber; name: ['required', 'pattern']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
        <div class="field-error" *dtmUiFieldHasError="restrictionsForm.controls.pdraNumber; name: 'maxlength'; error as error">
            {{
                "dtmAdminLibPermits.operationRestrictionsForm.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>

    <dtm-ui-select-field formControlName="sail" [placeholder]="'dtmAdminLibPermits.operationRestrictionsForm.chooseLabel' | transloco">
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.integrityLabel" | transloco }}</label>
        <dtm-ui-select-option [value]="sail" *ngFor="let sail of sailLevels">
            {{ sail | sailLevel }}
        </dtm-ui-select-option>
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.sail; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-select-field>
    <div>
        <dtm-ui-mission-type-form-control [isTooltipVisible]="false" formControlName="operationType"></dtm-ui-mission-type-form-control>
        <dtm-ui-form-errors class="preserve-error-space">
            <div
                class="field-error"
                *dtmUiFieldHasError="restrictionsForm.controls.operationType; name: ['required']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
        </dtm-ui-form-errors>
    </div>

    <dtm-admin-lib-confirm-tile formControlName="isDangerousGoodsTransport">
        <label>{{ "dtmAdminLibPermits.operationRestrictionsForm.isDangerousGoodsTransportLabel" | transloco }}</label>
        <div
            class="field-error"
            *dtmUiFieldHasError="restrictionsForm.controls.isDangerousGoodsTransport; name: ['required']"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-admin-lib-confirm-tile>
</div>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibPermits.container.requiredField" | transloco }}
</ng-template>
