import { ChangeDetectionStrategy, Component } from "@angular/core";
import { CaaFeatures, GlobalCapabilitiesState } from "@dtm-frontend/caa-admin-lib/shared";
import { DeviceSize, DeviceSizeService, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";

@Component({
    selector: "dtm-admin-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    protected readonly isCollapsed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly isMissionSearchFeatureAvailable$ = this.store.select(
        GlobalCapabilitiesState.isFeatureAvailable(CaaFeatures.MissionList)
    );

    constructor(private readonly store: Store, private readonly deviceSizeService: DeviceSizeService) {}

    protected collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
        }
    }
}
