import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
    ConfirmationDialogComponent,
    ConversationCategoryCode,
    DialogService,
    DisplayCountryService,
    ErrorMode,
    FILES_UPLOAD_API_PROVIDER,
    MessagesErrorType,
    OperatorsThread,
    getAttachmentIdsList,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, combineLatest, debounceTime, distinctUntilChanged, firstValueFrom, lastValueFrom } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";
import { MIN_SEARCH_TEXT_LENGTH } from "../../../operator/components/filters/filters.component";
import { OperatorErrorType, OperatorType } from "../../../shared";
import { ConversationsFilesApiService } from "../../services/api/conversations-files-api.service";
import { AddNewMessage, NewThreadAssignment } from "../../services/conversation.models";
import { ConversationActions } from "../../state/conversation.actions";
import { ConversationState } from "../../state/conversation.state";
import { NewThreadComponent } from "../shared/new-thread/new-thread.component";

interface MessagesComponentState {
    showMessageEditor: boolean;
    filtersCount: number;
    isAttachmentControlVisible: boolean;
}

const FILTER_DEBOUNCE_TIME = 500;

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-messages",
    templateUrl: "./messages.component.html",
    styleUrls: ["./messages.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: ConversationsFilesApiService }],
})
export class MessagesComponent implements OnDestroy, OnInit {
    protected readonly MessagesErrorType = MessagesErrorType;
    protected readonly OperatorType = OperatorType;
    protected readonly OperatorErrorType = OperatorErrorType;
    protected readonly ErrorMode = ErrorMode;
    protected readonly storedAvatarPictures$ = this.store.select(ConversationState.storedAvatarPictures);
    protected readonly assignees$ = this.store.select(ConversationState.conversationAssignees);
    protected readonly processingThreadsAssignments$ = this.store.select(ConversationState.processingThreadsAssignments);
    protected readonly processingThreadsCategory$ = this.store.select(ConversationState.processingThreadsCategory);
    protected readonly categories$ = this.store.select(ConversationState.conversationCategories);
    protected readonly interlocutorDetails$ = this.store.select(ConversationState.interlocutor).pipe(RxjsUtils.filterFalsy());
    protected readonly interlocutorDetailsError$ = this.store.select(ConversationState.operatorCapabilitiesError);
    protected readonly threadsList$ = this.store.select(ConversationState.operatorsThreads).pipe(RxjsUtils.filterFalsy());
    protected readonly isMessagesProcessing$ = this.store.select(ConversationState.isMessagesProcessing);
    protected readonly messages$ = this.store.select(ConversationState.messages);
    protected readonly messagesError$ = this.store.select(ConversationState.messagesError);
    protected readonly showMessageEditor$ = this.localStore.selectByKey("showMessageEditor");
    protected readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    protected readonly isAttachmentsControlVisible$ = this.localStore.selectByKey("isAttachmentControlVisible");
    protected readonly selectedThread$ = combineLatest([
        this.threadsList$.pipe(RxjsUtils.filterFalsy()),
        this.route.queryParams.pipe(map((params) => params.threadId)),
    ]).pipe(map(([list, threadId]: [OperatorsThread[], string]) => list.find((item) => item.id === threadId)));

    protected readonly selectedThreadAssignee$ = combineLatest([
        this.threadsList$.pipe(RxjsUtils.filterFalsy()),
        this.selectedThread$.pipe(RxjsUtils.filterFalsy()),
    ]).pipe(map(([threadsList, selectedThread]) => threadsList.find((threadItem) => threadItem.id === selectedThread.id)?.assignee));

    protected readonly editorControl = new UntypedFormControl(null);
    protected readonly conversationForm = new UntypedFormGroup({
        editor: this.editorControl,
    });

    protected readonly textSearchControl = new UntypedFormControl();

    constructor(
        private readonly store: Store,
        private readonly displayCountryService: DisplayCountryService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<MessagesComponentState>
    ) {
        localStore.setState({
            showMessageEditor: false,
            filtersCount: 0,
            isAttachmentControlVisible: false,
        });
    }

    public ngOnInit() {
        this.watchSearchControlChanges();
        this.watchParamsChange();
        this.handleEditorsVisibilityChange();
        this.handleThreadIdChange();
    }

    public ngOnDestroy() {
        this.store.dispatch([new ConversationActions.ClearInterlocutorData(), new ConversationActions.ClearMessagesData()]);
        this.dialogService.closeAll();
    }

    protected refreshCapabilities() {
        this.store.dispatch(
            new ConversationActions.GetOperatorCapabilities(
                this.route.snapshot.params.operatorId,
                this.route.snapshot.queryParams.searchByText ?? ""
            )
        );
    }

    protected changeAttachmentControlVisibility() {
        this.localStore.patchState({ isAttachmentControlVisible: true });
    }

    protected async reloadMessages() {
        const threadId = await firstValueFrom(
            this.selectedThread$.pipe(
                map((thread) => thread?.id),
                first(),
                untilDestroyed(this)
            )
        );

        this.store.dispatch(new ConversationActions.GetMessagesByThread(threadId));
    }

    protected changeThread(threadId: string) {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: { threadId },
            queryParamsHandling: "merge",
        });
    }

    protected changeAssignee(data: NewThreadAssignment) {
        this.store
            .dispatch(new ConversationActions.AssignThread(data))
            .pipe(
                switchMap(() => this.store.select(ConversationState.changeThreadAssignmentError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (error) {
                    this.showThreadAssignmentError();
                } else {
                    this.refreshThreads();
                }
            });
    }

    protected changeThreadsCategory(categoryCode: ConversationCategoryCode, threadId: string) {
        this.store
            .dispatch(new ConversationActions.ChangeThreadsCategory(categoryCode, threadId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(ConversationState.changeThreadsCategoryError);
                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dtmAdminLibConversation.messagesContainer.changeThreadError")
                    );
                } else {
                    this.refreshThreads();
                }
            });
    }

    protected openNewThreadContainer() {
        this.dialogService.open(NewThreadComponent, {
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            panelClass: "conversation-sheet",
        });
    }

    protected closeThread(thread: OperatorsThread) {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: this.translocoService.translate("dtmAdminLibConversation.messagesContainer.closeThreadConfirmText"),
                declineButtonLabel: this.translocoService.translate("dtmAdminLibConversation.messagesContainer.closeThreadCancelLabel"),
                confirmButtonLabel: this.translocoService.translate("dtmAdminLibConversation.messagesContainer.closeThreadConfirmLabel"),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.closeThreadRequest(thread)),
                switchMap(() => this.store.selectOnce(ConversationState.changeThreadError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    return;
                }

                this.toastrService.error(this.translocoService.translate("dtmAdminLibConversation.messagesContainer.closeThreadError"));
            });
    }

    protected markAsUnread(thread: OperatorsThread) {
        this.store.dispatch(new ConversationActions.ChangeThread({ isClosed: false, isRead: false }, thread.id));
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: { ...this.route.snapshot.queryParams, threadId: null },
            queryParamsHandling: "merge",
        });
    }

    protected changeEditorVisibility(isVisible: boolean) {
        if (isVisible) {
            this.localStore.patchState({ showMessageEditor: isVisible });

            return;
        }

        if (this.conversationForm.touched) {
            this.confirmAndClose();

            return;
        }

        this.localStore.patchState({ showMessageEditor: isVisible, isAttachmentControlVisible: false });
    }

    protected async sendMessage(subject: string, threadId: string) {
        const operatorDetails = await lastValueFrom(this.interlocutorDetails$.pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this)));
        this.conversationForm.markAllAsTouched();
        if (!this.conversationForm.valid) {
            return;
        }

        const messageToSend: AddNewMessage = {
            content: this.conversationForm.controls.editor.value.content,
            subject: subject,
            recipient: {
                owner: {
                    userId: operatorDetails.owner.userId,
                    firstName: operatorDetails.owner.firstName,
                    lastName: operatorDetails.owner.lastName,
                },
                id: operatorDetails.id,
                name: operatorDetails.name,
            },
            attachmentIds: getAttachmentIdsList(this.conversationForm.controls.editor.value.attachments),
            threadId,
        };

        this.store
            .dispatch(new ConversationActions.AddNewMessageToThread(messageToSend))
            .pipe(
                switchMap(() => this.store.select(ConversationState.sendMessageStatusSuccess)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((sendMessageStatusSuccess) =>
                sendMessageStatusSuccess ? this.handleSendMessageSuccess(messageToSend) : this.handleSendMessageError()
            );
    }

    private closeThreadRequest(thread: OperatorsThread): Observable<void> {
        const { isClosed, isRead, id } = thread;

        return this.store.dispatch(new ConversationActions.ChangeThread({ isClosed: !isClosed, isRead }, id));
    }

    private showThreadAssignmentError() {
        this.toastrService.error(this.translocoService.translate("dtmAdminLibConversation.threadsContainer.threadAssignmentFailedMessage"));
    }

    private handleEditorsVisibilityChange() {
        this.showMessageEditor$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => {
            this.conversationForm.reset();
        });
    }

    private handleThreadIdChange() {
        this.route.queryParams
            .pipe(
                map((params) => params.threadId),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((threadId) => {
                this.getMessages(threadId);

                if (this.store.selectSnapshot(ConversationState.operatorsThreads)?.some((thread) => thread.id === threadId)) {
                    return;
                }

                this.refreshThreads();
            });
    }

    private getMessages(threadId: string) {
        this.clearAndHideForm();
        this.store.dispatch(new ConversationActions.GetMessagesByThread(threadId));
    }

    private handleSendMessageSuccess(newMessage: AddNewMessage) {
        this.toastrService.success(this.translocoService.translate("dtmAdminLibConversation.newMessage.messageSendSuccessMessage"));
        this.clearAndHideForm();
        this.store.dispatch(new ConversationActions.GetMessagesByThread(newMessage.threadId));
    }

    private handleSendMessageError() {
        this.toastrService.error(this.translocoService.translate("dtmAdminLibConversation.newMessage.messageSendErrorMessage"));
    }

    private confirmAndClose() {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate("dtmAdminLibConversation.newMessage.declineMessageTitleText"),
                confirmationText: this.translocoService.translate("dtmAdminLibConversation.newMessage.declineMessageConfirmText"),
                declineButtonLabel: this.translocoService.translate("dtmAdminLibConversation.newMessage.declineMessageCancelLabel"),
                confirmButtonLabel: this.translocoService.translate("dtmAdminLibConversation.newMessage.declineMessageConfirmLabel"),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((isConfirmed) => {
                if (!isConfirmed) {
                    return;
                }
                this.clearAndHideForm();
            });
    }

    private clearAndHideForm() {
        this.conversationForm.reset();
        this.localStore.patchState({ showMessageEditor: false, isAttachmentControlVisible: false });
    }

    private refreshThreads() {
        this.store.dispatch(
            new ConversationActions.GetOperatorThreads({
                stakeholderId: this.route.snapshot.params.operatorId,
                searchByText: this.textSearchControl.value,
            })
        );
    }

    private watchSearchControlChanges() {
        this.textSearchControl.valueChanges.pipe(debounceTime(FILTER_DEBOUNCE_TIME), untilDestroyed(this)).subscribe((value: string) => {
            this.updateFiltersCount();

            if (this.textSearchControl.invalid) {
                return;
            }

            this.router.navigate(["."], {
                relativeTo: this.route,
                queryParams: { searchByText: value.length ? value : null },
                queryParamsHandling: "merge",
            });
        });
    }

    private watchParamsChange() {
        this.route.queryParams
            .pipe(
                map((params) => params.searchByText),
                distinctUntilChanged(),
                debounceTime(FILTER_DEBOUNCE_TIME),
                untilDestroyed(this)
            )
            .subscribe((searchByText) => {
                if (searchByText !== this.textSearchControl.value) {
                    // NOTE it changes form textSearch value when user changes queryParams from outside component ie. notifications
                    this.textSearchControl.reset(searchByText, { emitEvent: false });
                }

                this.refreshThreads();
            });
    }

    private updateFiltersCount() {
        this.localStore.patchState({ filtersCount: this.textSearchControl.value?.length >= MIN_SEARCH_TEXT_LENGTH ? 1 : 0 });
    }
}
