<div class="dialog-header">
    <div mat-dialog-title>{{ data.header }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>

<dtm-ui-loader-container [isShown]="isTrainingCenterProcessing$ | ngrxPush">
    <form [formGroup]="trainingCenterForm" mat-dialog-content dtmUiInvalidFormScrollable>
        <div class="section">
            <div>
                <ng-container *ngIf="!data.center; else operatorEditTemplate">
                    <dtm-admin-lib-operator-search-autocomplete-control
                        [formControl]="operatorControl"
                        (searchTextChange)="searchTextChange($event)"
                        [options]="operatorsList$ | ngrxPush"
                    >
                        <label>{{ "dtmAdminLibCenters.centerFormDialog.operator.findOperatorLabel" | transloco }}</label>

                        <div
                            class="field-error"
                            *dtmUiFieldHasError="operatorControl; name: ['required', 'invalidFind']"
                            [ngTemplateOutlet]="requiredErrorTemplate"
                        ></div>
                    </dtm-admin-lib-operator-search-autocomplete-control>
                </ng-container>

                <ng-template #operatorEditTemplate>
                    <dtm-ui-label-value [label]="'dtmAdminLibCenters.centerFormDialog.operator.operatorNameLabel' | transloco">
                        <div class="operator-preview">
                            {{ data.center?.name }}
                        </div>
                    </dtm-ui-label-value>
                </ng-template>

                <dtm-admin-lib-operator-details
                    *ngIf="operatorControl.value"
                    [operatorDetails]="operatorDetails$ | ngrxPush"
                ></dtm-admin-lib-operator-details>
            </div>

            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibCenters.centerFormDialog.nameLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="nameControl" />
                <div class="field-error" *dtmUiFieldHasError="nameControl; name: 'maxlength'; error as error">
                    {{ "dtmAdminLibCenters.centerFormDialog.nameTooLongError" | transloco : { max: error.requiredLength } }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="nameControl; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-input-field>

            <dtm-ui-input-field *ngIf="!data.center">
                <label>{{ "dtmAdminLibCenters.centerFormDialog.identificationNumberLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="identificationNumberControl" />
                <div class="field-error" *dtmUiFieldHasError="identificationNumberControl; name: 'maxlength'; error as error">
                    {{ "dtmAdminLibCenters.centerFormDialog.identificationNumberTooLongError" | transloco : { max: error.requiredLength } }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="identificationNumberControl; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-input-field>

            <dtm-ui-input-field>
                <label>{{ "dtmAdminLibCenters.centerFormDialog.administrativeDecisionLabel" | transloco }}</label>
                <input matInput type="text" [formControl]="administrativeDecisionNumberControl" />
                <div class="field-error" *dtmUiFieldHasError="administrativeDecisionNumberControl; name: 'maxlength'; error as error">
                    {{
                        "dtmAdminLibCenters.centerFormDialog.administrativeDecisionTooLongError" | transloco : { max: error.requiredLength }
                    }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="administrativeDecisionNumberControl; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-input-field>

            <dtm-ui-select-field [multiple]="true" [formControl]="trainingCodesControl">
                <label>{{
                    "dtmAdminLibCenters.centerFormDialog.authorizationScopeLabel" | transloco : { value: CentersTabType.TrainingOperators }
                }}</label>
                <dtm-ui-select-option *ngFor="let competency of data.capabilities" [value]="competency">
                    {{ competency }}
                </dtm-ui-select-option>

                <div
                    class="field-error"
                    *dtmUiFieldHasError="trainingCodesControl; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-select-field>

            <dtm-admin-lib-authorization-scope-chips-list
                *ngIf="trainingCodesControl.value?.length"
                [chipsList]="trainingCodesControl.value"
                (chipRemove)="removeTrainingCode($event)"
            >
            </dtm-admin-lib-authorization-scope-chips-list>

            <dtm-ui-date-field>
                <label>{{ "dtmAdminLibCenters.centerFormDialog.validUntilLabel" | transloco }}</label>
                <input
                    matInput
                    [formControl]="validUntilControl"
                    [matDatepicker]="picker"
                    *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                    [placeholder]="datePickerPlaceholder"
                />
                <mat-datepicker #picker></mat-datepicker>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="validUntilControl; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-date-field>
        </div>
    </form>

    <div mat-dialog-actions>
        <button type="button" class="button-primary action-button" (click)="save()">{{ data.confirmLabel }}</button>
    </div>
</dtm-ui-loader-container>

<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibCenters.centerFormDialog.requiredFieldError" | transloco }}
</ng-template>
