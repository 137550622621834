<div class="card" *ngrxLet="administrators$ as administrators">
    <div class="card-title">{{ "dtmAdminLibShared.operatorProfile.administratorList.title" | transloco }}</div>
    <dtm-ui-carousel>
        <ng-container *ngFor="let administrator of administrators">
            <div class="slide" *dtmUiCarouselSlides>
                <dtm-ui-label-value
                    [label]="'dtmAdminLibShared.operatorProfile.administratorList.personalNameLabel' | transloco"
                    [value]="administrator.firstName + ' ' + administrator.lastName"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmAdminLibShared.operatorProfile.administratorList.emailAddressLabel' | transloco"
                    [value]="administrator.email"
                    [canCopy]="true"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmAdminLibShared.operatorProfile.administratorList.telephoneNumberLabel' | transloco"
                    [value]="administrator.phoneNumber | formatPhoneNumber"
                    [canCopy]="true"
                ></dtm-ui-label-value>
            </div>
        </ng-container>
    </dtm-ui-carousel>
</div>
