<div
    *ngrxLet="{
        areActionsAvailable: areActionsAvailable$,
        administrativeFee: administrativeFee$
    } as vm"
    class="card"
>
    <div class="card-title">{{ "dtmAdminLibShared.operatorProfile.administrativeFee.titleTile" | transloco }}</div>
    <div class="main-container">
        <p *ngIf="vm.administrativeFee.isDeclarationOfExemption">
            {{ "dtmAdminLibShared.operatorProfile.administrativeFee.declarationOfExemptionMessage" | transloco }}
        </p>
        <ng-container *ngIf="vm.administrativeFee.filename">
            <p class="attachment-label">{{ "dtmAdminLibShared.operatorProfile.administrativeFee.attachmentLabel" | transloco }}</p>
            <div class="attachment">
                <dtm-ui-icon name="attachment"></dtm-ui-icon>
                <span>{{ vm.administrativeFee.filename }}</span>
                <button *ngIf="download.observed" (click)="download.emit()" type="button" class="button-icon">
                    <dtm-ui-icon name="download"></dtm-ui-icon>
                </button>
            </div>
        </ng-container>
    </div>
</div>
