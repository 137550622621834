<ng-container *ngrxLet="appliedFiltersLength$; let appliedFiltersLength">
    <dtm-ui-filters-container [filtersCount]="appliedFiltersLength">
        <form [formGroup]="filtersForm">
            <div class="filters-container">
                <dtm-ui-input-field class="control-search">
                    <label>{{ "dtmAdminLibOperator.operatorFilters.searchByTextLabel" | transloco }}</label>
                    <input matInput type="text" formControlName="searchByText" />
                    <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
                    <div class="field-error" *dtmUiFieldHasError="filtersForm.controls.searchByText; name: 'maxlength'">
                        {{
                            "dtmAdminLibOperator.operatorFilters.textSearchValueToLongError"
                                | transloco : { maxLength: MAX_SEARCH_TEXT_LENGTH }
                        }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="filtersForm.controls.searchByText; name: 'minlength'">
                        {{
                            "dtmAdminLibOperator.operatorFilters.textSearchValueToShortError"
                                | transloco : { minLength: MIN_SEARCH_TEXT_LENGTH }
                        }}
                    </div>
                </dtm-ui-input-field>

                <dtm-ui-select-field [multiple]="true" formControlName="status" class="control-status">
                    <label>{{ "dtmAdminLibOperator.operatorFilters.statusLabel" | transloco }}</label>
                    <dtm-ui-select-option *ngFor="let status of availableStatuses" [value]="status">
                        {{ "dtmAdminLibOperator.operatorFilters.statusValueLabel" | transloco : { value: status } }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field>

                <dtm-ui-select-field formControlName="type" [isClearable]="false" class="control-type">
                    <label>{{ "dtmAdminLibOperator.operatorFilters.typeLabel" | transloco }}</label>
                    <dtm-ui-select-option [value]="NULL_VALUE">
                        {{ "dtmAdminLibOperator.operatorFilters.allValueLabel" | transloco }}
                    </dtm-ui-select-option>
                    <dtm-ui-select-option *ngFor="let type of availableTypes" [value]="type">
                        {{ "dtmAdminLibOperator.operatorFilters.typeValueLabel" | transloco : { value: type } }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field>

                <dtm-admin-lib-single-select-filter
                    class="control-country"
                    [singleSelectFormControl]="filtersForm.controls.isDefaultCountryRegistration"
                    [label]="'dtmAdminLibOperator.operatorFilters.isDefaultCountryRegistrationLabel' | transloco"
                    [trueOptionLabel]="
                        'dtmAdminLibOperator.operatorFilters.isDefaultCountryRegistrationValueLabel'
                            | transloco : { value: SINGLE_SELECT_VALUES.True, filterValueLabelParam: defaultCountry }
                    "
                    [falseOptionLabel]="
                        'dtmAdminLibOperator.operatorFilters.isDefaultCountryRegistrationValueLabel'
                            | transloco : { value: SINGLE_SELECT_VALUES.False }
                    "
                    [allOptionLabel]="
                        'dtmAdminLibOperator.operatorFilters.isDefaultCountryRegistrationValueLabel' | transloco : { value: NULL_VALUE }
                    "
                ></dtm-admin-lib-single-select-filter>

                <dtm-ui-select-field formControlName="powerOfAttorneyStatus" [isClearable]="false" class="control-power-of-attorney">
                    <label>{{ "dtmAdminLibOperator.operatorFilters.powerOfAttorneyStatusLabel" | transloco }}</label>
                    <dtm-ui-select-option [value]="NULL_VALUE">
                        {{ "dtmAdminLibOperator.operatorFilters.allValueLabel" | transloco }}
                    </dtm-ui-select-option>
                    <dtm-ui-select-option *ngFor="let status of availablePowerOfAttorneyStatuses" [value]="status">
                        {{ "dtmAdminLibOperator.operatorFilters.powerOfAttorneyStatusValueLabel" | transloco : { value: status } }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field>

                <dtm-admin-lib-single-select-filter
                    class="waiting-competency-status"
                    [singleSelectFormControl]="filtersForm.controls.isWaitingCompetencyConfirmation"
                    [label]="'dtmAdminLibOperator.operatorFilters.isWaitingCompetencyConfirmationLabel' | transloco"
                    [trueOptionLabel]="
                        'dtmAdminLibOperator.operatorFilters.isWaitingCompetencyConfirmationValueLabel'
                            | transloco : { value: SINGLE_SELECT_VALUES.True }
                    "
                    [falseOptionLabel]="
                        'dtmAdminLibOperator.operatorFilters.isWaitingCompetencyConfirmationValueLabel'
                            | transloco : { value: SINGLE_SELECT_VALUES.False }
                    "
                    [allOptionLabel]="
                        'dtmAdminLibOperator.operatorFilters.isWaitingCompetencyConfirmationValueLabel' | transloco : { value: NULL_VALUE }
                    "
                ></dtm-admin-lib-single-select-filter>
            </div>
        </form>

        <dtm-ui-filter-chips-display
            selectedFiltersDisplaySlot
            [filtersMap]="filtersMap$ | ngrxPush"
            [filtersCount]="appliedFiltersLength"
            [formGroup]="filtersForm"
            [filtersValue]="filtersForm.value"
            (allFiltersReset)="clearFilters()"
        >
        </dtm-ui-filter-chips-display>
    </dtm-ui-filters-container>
</ng-container>
