import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { BasicDataModel, FILES_UPLOAD_API_PROVIDER, Permit, UavDetailsModel, UploadedFile } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { CrossBorderPermitWizardSteps } from "../../../../models/cross-border-permit.models";
import { PERMITS_ENDPOINTS } from "../../../../permits.tokens";
import { operationalRestrictionsUploadApiFactory } from "../../../../services/operational-restrictions-upload-api.factory";
import { OperationalRestrictionsUploadApiService } from "../../../../services/operational-restrictions-upload-api.service";
import { OPERATIONAL_RESTRICTIONS_API_ENDPOINTS } from "../../../../services/operational-restrictions-upload-api.tokens";

interface CrossBorderPermitSummaryComponentState {
    basicData: BasicDataModel | undefined;
    uavDetails: UavDetailsModel | undefined;
    permit: Permit | undefined;
    isProcessing: boolean;
    isUpdateMode: boolean;
}

@Component({
    selector: "dtm-admin-lib-cross-border-permit-summary",
    templateUrl: "./cross-border-permit-summary.component.html",
    styleUrls: ["./cross-border-permit-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: OperationalRestrictionsUploadApiService },
        {
            provide: OPERATIONAL_RESTRICTIONS_API_ENDPOINTS,
            useFactory: operationalRestrictionsUploadApiFactory,
            deps: [PERMITS_ENDPOINTS],
        },
    ],
})
export class CrossBorderPermitSummaryComponent {
    @Input() public set permit(value: Permit | undefined) {
        this.localStore.patchState({ permit: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set isUpdateMode(value: BooleanInput) {
        this.localStore.patchState({ isUpdateMode: coerceBooleanProperty(value) });
    }

    @Output() public readonly stepEdit = new EventEmitter<CrossBorderPermitWizardSteps>();
    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly addPermit = new EventEmitter<void>();
    @Output() public readonly kmlFilePreview = new EventEmitter<UploadedFile>();

    protected readonly permit$ = this.localStore.selectByKey("permit").pipe(RxjsUtils.filterFalsy());
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isUpdateMode$ = this.localStore.selectByKey("isUpdateMode");
    protected readonly CrossBorderPermitWizardSteps = CrossBorderPermitWizardSteps;

    constructor(private readonly localStore: LocalComponentStore<CrossBorderPermitSummaryComponentState>) {
        this.localStore.setState({
            basicData: undefined,
            uavDetails: undefined,
            permit: undefined,
            isProcessing: false,
            isUpdateMode: false,
        });
    }
}
