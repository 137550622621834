<dtm-ui-loader-container
    [isShown]="vm.isProcessing"
    *ngrxLet="{
        mission: mission$,
        selectedZoneId: selectedZoneId$,
        isProcessing: isProcessing$,
        missionData: missionData$,
        route: route$,
    } as vm"
>
    <div class="card-box scroll-shadows">
        <div class="card-box-header">
            <h2>{{ "dtmSharedMissionSearch.missionPreview.missionDetailsHeader" | transloco }}</h2>
            <button *ngIf="isExternalAppButtonVisible$ | ngrxPush" type="button" class="button-secondary" (click)="externalAppShow.emit()">
                <dtm-ui-icon name="external-link"></dtm-ui-icon>
                {{ "dtmSharedMissionSearch.missionPreview.showInExternalAppButtonLabel" | transloco }}
            </button>
        </div>
        <section>
            <dtm-mission-mission-search-preview-basic-details [mission]="vm.mission"></dtm-mission-mission-search-preview-basic-details>
            <!-- TODO: flightRequirements will be implemented later in REJ-2904 -->
            <!-- <dtm-mission-flight-requirements-->
            <!--     [missionContext]="MissionContextType.Operator"-->
            <!--     [zones]="vm.mission.flightRequirements.zones"-->
            <!--     [selectedZoneId]="vm.selectedZoneId"-->
            <!--     [authorityAcceptation]="vm.mission.flightRequirements.authorityAcceptation"-->
            <!--     [currentPlanAnalysisStatus]="vm.mission.flightRequirements.currentPlanAnalysisStatus"-->
            <!--     [formalJustification]="vm.mission.flightRequirements.formalJustification"-->
            <!--     [missionPhase]="vm.mission.status"-->
            <!--     (zoneSelect)="zoneSelect.emit($event)"-->
            <!-- ></dtm-mission-flight-requirements>-->

            <!-- TODO replace it with <dtm-mission-mission-details> component - REJ-2976 -->
            <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="true">
                <dtm-ui-expandable-simple-header headerSlot [label]="'dtmSharedMissionSearch.missionPreview.basicDataHeader' | transloco">
                </dtm-ui-expandable-simple-header>
                <dtm-mission-mission-data-simple [mission]="vm.missionData"></dtm-mission-mission-data-simple>
            </dtm-ui-expandable-panel>
            <dtm-ui-expandable-panel [hasHeaderSeparator]="false">
                <dtm-ui-expandable-simple-header
                    headerSlot
                    [label]="'dtmSharedMissionSearch.missionPreview.routeDetailsHeader' | transloco"
                >
                </dtm-ui-expandable-simple-header>
                <dtm-ui-route-details [route]="vm.route"></dtm-ui-route-details>
            </dtm-ui-expandable-panel>
            <dtm-ui-expandable-panel [hasHeaderSeparator]="false" *ngIf="vm.mission.isSoraRequired">
                <dtm-ui-expandable-simple-header headerSlot [label]="'dtmSharedMissionSearch.missionPreview.groundRiskHeader' | transloco">
                </dtm-ui-expandable-simple-header>
                <dtm-mission-population-density [populationDensity]="vm.mission.populationDensity"></dtm-mission-population-density>
            </dtm-ui-expandable-panel>
        </section>
    </div>
    <div class="visualization-container">
        <dtm-mission-mission-search-preview-map
            *ngIf="vm.route"
            class="map-container"
            [route]="vm.route"
            [shouldShowAreaBuffers]="vm.mission.category.type !== MissionCategory.Open"
        ></dtm-mission-mission-search-preview-map>
        <dtm-ui-empty-state *ngIf="!vm.route" [mode]="EmptyStateMode.Tertiary">
            <ng-container messageSlot>
                {{ "dtmSharedMissionSearch.missionPreview.routeEmptyMessage" | transloco }}
            </ng-container>
        </dtm-ui-empty-state>
        <dtm-mission-route-side-view
            class="side-view"
            [route]="vm.route"
            [isExpanded]="false"
            [isSimpleMode]="true"
            [areBuffersVisible]="vm.mission.flightType === MissionType.BVLOS"
        >
            <h2 headerSlot class="side-view-header">
                {{ "dtmSharedMissionSearch.missionPreview.routeSideViewHeader" | transloco }}
            </h2>
            <ng-container emptyMessageSlot>
                {{ "dtmSharedMissionSearch.missionPreview.routeSideViewEmptyMessage" | transloco }}
            </ng-container>
        </dtm-mission-route-side-view>
    </div>
</dtm-ui-loader-container>
