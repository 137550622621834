import { ContextOperator, GlobalFeatures } from "@dtm-frontend/shared/ui";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";

export interface Pilot {
    id: string;
    pilotNumber: string;
    competencies: PilotCompetency[];
}

export interface PilotCompetency {
    name: string;
    category: PilotCompetencyCategory;
}

export enum PilotCompetencyCategory {
    SPECIFIC = "SPECIFIC",
    OPEN = "OPEN",
    CERTIFIED = "CERTIFIED",
}

export interface GlobalCapabilitiesError {
    type: GlobalCapabilitiesErrorType;
}
export enum GlobalCapabilitiesErrorType {
    CannotGetUserGlobalCapabilities = "CannotGetUserGlobalCapabilities",
    Unknown = "Unknown",
}

export interface GlobalCapabilitiesPilot {
    id: string;
    operatorId: string;
    firstName: string;
    lastName: string;
    avatarUrl?: string;
}

export interface GlobalCapabilitiesUser {
    firstName: string;
    lastName: string;
    avatarUrl: string;
    isNationalNodeAuthenticated: boolean;
    isRequiredRegisterNationalNodeAuthenticatedUser: boolean;
}

export interface GlobalCapabilities {
    operators: ContextOperator[];
    pilot?: GlobalCapabilitiesPilot;
    isAllowedToRegisterOperator: boolean;
    isAllowedToRegisterPilot: boolean;
    user: GlobalCapabilitiesUser;
    elearningPortalUrl: string;
    languageTag: LanguageCode;
    isRequiredToAcceptTermsOfService: boolean;
    features: GlobalFeatures[];
}

export const OPERATOR_CONTEXT_PARAM = "operatorContext";
