import { ModuleWithProviders, NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { WeatherApiService } from "./services/weather-api.service";
import { WeatherState } from "./state/weather.state";

@NgModule({
    declarations: [],
    imports: [NgxsModule.forFeature([WeatherState])],
    exports: [],
})
export class WeatherModule {
    public static forRoot(): ModuleWithProviders<WeatherModule> {
        return {
            ngModule: WeatherModule,
            providers: [
                {
                    provide: WeatherApiService,
                    useClass: WeatherApiService,
                },
            ],
        };
    }
    public static forTest(): ModuleWithProviders<WeatherModule> {
        return {
            ngModule: WeatherModule,
            providers: [
                {
                    provide: WeatherApiService,
                    useValue: null,
                },
            ],
        };
    }
}
