import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { OperatorsContainerComponent } from "./components/operators-container/operators-container.component";

const routes: Routes = [
    {
        path: "operators",
        component: OperatorsContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmAdminLibOperator.routeTitles.operatorList",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OperatorRoutingModule {}
