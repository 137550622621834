import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AssociationPermitWizardSteps } from "../../../models/association-permit.models";
import { ASSOCIATION_PERMIT_WIZARD_ID } from "../association-permit-wizard.models";

@Component({
    selector: "dtm-admin-lib-association-permit-request-header",
    templateUrl: "./association-permit-request-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationPermitRequestHeaderComponent {
    protected readonly ASSOCIATION_PERMIT_WIZARD_ID = ASSOCIATION_PERMIT_WIZARD_ID;
    protected readonly AssociationPermitWizardSteps = AssociationPermitWizardSteps;
}
