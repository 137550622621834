import { UserContactErrorType } from "@dtm-frontend/shared/ui";
import { UserProfileErrorType } from "../services/user-profile.models";

export const getI18nUpdateProfileError = (errorType: UserProfileErrorType | UserContactErrorType): string => {
    const translation: Record<string, string> = {
        [UserContactErrorType.CannotSaveEmailAddress]: "dtmAdminLibUserProfile.notifications.cannotSaveEmailAddressError",
        [UserContactErrorType.CannotSavePhoneNumber]: "dtmAdminLibUserProfile.notifications.cannotSavePhoneNumberError",
        [UserContactErrorType.EmailConflict]: "dtmAdminLibUserProfile.notifications.emailConflictError",
        [UserContactErrorType.PhoneConflict]: "dtmAdminLibUserProfile.notifications.phoneConflictError",
        [UserProfileErrorType.Unknown]: "dtmAdminLibUserProfile.notifications.unknownError",
        [UserProfileErrorType.CannotSaveConversationCategories]:
            "dtmAdminLibUserProfile.notifications.cannotSaveConversationCategoriesError",
        [UserProfileErrorType.CannotSavePersonalData]: "dtmAdminLibUserProfile.notifications.cannotSavePersonalDataError",
        [UserProfileErrorType.CannotUpdateProfileLanguage]: "dtmAdminLibUserProfile.notifications.cannotUpdateProfileLanguageError",
        [UserProfileErrorType.CannotResetUserPassword]: "dtmAdminLibUserProfile.notifications.cannotResetUserPasswordError",
        [UserProfileErrorType.CannotSaveUserAvatar]: "dtmAdminLibUserProfile.notifications.cannotSaveProfileAvatar",
        [UserProfileErrorType.CannotDeleteUserAvatar]: "dtmAdminLibUserProfile.notifications.cannotDeleteUserAvatarErrorMessage",
    };

    return translation[errorType] || translation[UserProfileErrorType.Unknown];
};
