import { Injectable } from "@angular/core";
import {
    CompetenciesManagementNotificationType,
    MissionPlanningNotificationType,
    WebAppConversationNotificationType,
    WebAppMembershipInvitationNotificationType,
    WebAppMembershipNotificationType,
    WebAppPermissionNotificationType,
    WebAppUserNotificationType,
} from "./web-app-notifications.models";

@Injectable({
    providedIn: "root",
})
export class WebAppNotificationsService {
    public getAllowedNotifications(): string[] {
        // NOTE concat when other notification types are allowed in web-app
        return [
            ...this.getMembershipInvitationNotificationTypes(),
            ...this.getConversationNotificationTypes(),
            ...this.getMembershipNotificationTypes(),
            ...this.getPermissionsNotificationTypes(),
            ...this.getCompetenciesManagementNotificationTypes(),
            ...this.getUserNotificationTypes(),
            ...this.getMissionPlanningNotificationTypes(),
        ];
    }

    public getConversationNotificationTypes(): WebAppConversationNotificationType[] {
        return Object.values(WebAppConversationNotificationType);
    }

    public getMembershipInvitationNotificationTypes(): WebAppMembershipInvitationNotificationType[] {
        return Object.values(WebAppMembershipInvitationNotificationType);
    }

    public getMembershipNotificationTypes(): WebAppMembershipNotificationType[] {
        return Object.values(WebAppMembershipNotificationType);
    }

    public getPermissionsNotificationTypes(): WebAppPermissionNotificationType[] {
        return Object.values(WebAppPermissionNotificationType);
    }

    public getCompetenciesManagementNotificationTypes(): CompetenciesManagementNotificationType[] {
        return Object.values(CompetenciesManagementNotificationType);
    }

    public getUserNotificationTypes(): WebAppUserNotificationType[] {
        return Object.values(WebAppUserNotificationType);
    }

    public getMissionPlanningNotificationTypes(): MissionPlanningNotificationType[] {
        return Object.values(MissionPlanningNotificationType);
    }
}
