<div class="qualifications-header">
    <button type="button" class="button-icon" (click)="drawerClose.next()">
        <dtm-ui-icon class="close" name="close"></dtm-ui-icon>
    </button>
    <h2>{{ title$ | ngrxPush }}</h2>
</div>

<ul class="qualifications scroll-shadows">
    <li class="card" *ngFor="let qualification of qualifications$ | ngrxPush">
        <dtm-ui-icon *ngIf="qualification | invoke : isOpenIcon; else specificIconTemplate" name="shield" class="icon shield"></dtm-ui-icon>

        <div class="header">{{ qualification }}</div>
        <div class="content" [ngSwitch]="qualification">
            <ng-container *ngSwitchCase="PilotCompetencies.A1">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.A1" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.A2">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.A2" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.A3">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.A3" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.STS01">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.STS-01" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.STS02">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.STS-02" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.NSTS01">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.NSTS-01" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.NSTS02">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.NSTS-02" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.NSTS03">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.NSTS-03" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.NSTS04">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.NSTS-04" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.NSTS05">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.NSTS-05" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.NSTS06">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.NSTS-06" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.NSTS07">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.NSTS-07" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="PilotCompetencies.NSTS08">{{
                "dtmWebAppLibShared.operatorQualificationsPermits.NSTS-08" | transloco
            }}</ng-container>
        </div>
    </li>
</ul>

<ng-template #specificIconTemplate>
    <dtm-ui-icon name="shield-cross" class="icon shield"></dtm-ui-icon>
</ng-template>
