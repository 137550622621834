import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { EXAM_TRAINING_RESULTS_ENDPOINTS, ExamTrainingResultsEndpoints } from "../exam-training-results.tokens";
import { ExamResultListWithPages, ExamResultsErrorType } from "../models/exam-results.models";
import {
    ExamTrainingResultsErrorType,
    ExamTrainingResultsFilterParams,
    ExamTrainingResultsReportDetails,
} from "../models/exam-training-results.models";
import { TrainingResultListWithPages, TrainingResultsErrorType } from "../models/training-results.models";
import {
    ExamTrainingResultsReportDetailsResponseBody,
    GetExamResultsListResponseBody,
    GetTrainingResultsListResponseBody,
    convertExamTrainingResultReportDetailsResponseBodyToExamTrainingResultsReportDetails,
    convertGetExamResultListResponseBodyToResultListWithPages,
    convertGetTrainingResultListResponseBodyToResultListWithPages,
    getExamTrainingResultsParams,
} from "./exam-training-results-api.converters";

@Injectable({
    providedIn: "root",
})
export class ExamTrainingResultsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(EXAM_TRAINING_RESULTS_ENDPOINTS) private readonly endpoints: ExamTrainingResultsEndpoints
    ) {}

    public getExamResultsList(filters: ExamTrainingResultsFilterParams): Observable<ExamResultListWithPages> {
        const params: HttpParams = getExamTrainingResultsParams(filters);

        return this.httpClient.get<GetExamResultsListResponseBody>(this.endpoints.getExamResultsList, { params }).pipe(
            map((response) => convertGetExamResultListResponseBodyToResultListWithPages(response)),
            catchError(() => throwError(() => ({ type: ExamResultsErrorType.CannotGetExamResultsList })))
        );
    }

    public getTrainingResultsList(filters: ExamTrainingResultsFilterParams): Observable<TrainingResultListWithPages> {
        const params: HttpParams = getExamTrainingResultsParams(filters);

        return this.httpClient.get<GetTrainingResultsListResponseBody>(this.endpoints.getTrainingResultsList, { params }).pipe(
            map((response) => convertGetTrainingResultListResponseBodyToResultListWithPages(response)),
            catchError(() => throwError(() => ({ type: TrainingResultsErrorType.CannotGetTrainingResultsList })))
        );
    }

    public getExamTrainingResultsReportDetails(reportId: string): Observable<ExamTrainingResultsReportDetails> {
        return this.httpClient
            .get<ExamTrainingResultsReportDetailsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.manageExamTrainingResultsReportDetails, { reportId })
            )
            .pipe(
                map((response) => convertExamTrainingResultReportDetailsResponseBodyToExamTrainingResultsReportDetails(response)),
                catchError(() => throwError(() => ({ type: ExamTrainingResultsErrorType.CannotGetExamTrainingResultsReportDetails })))
            );
    }

    public addExamTrainingResults(reportId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.manageExamTrainingResultsReportDetails, { reportId }), null)
            .pipe(catchError(() => throwError(() => ({ type: ExamTrainingResultsErrorType.CannotAddExamTrainingResults }))));
    }

    public deleteExamResults(reportId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.manageExamConfirmation, { reportId }))
            .pipe(catchError(() => throwError(() => ({ type: ExamTrainingResultsErrorType.CannotDeleteResults }))));
    }

    public deleteTrainingResults(reportId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.manageTrainingConfirmation, { reportId }))
            .pipe(catchError(() => throwError(() => ({ type: ExamTrainingResultsErrorType.CannotDeleteResults }))));
    }
}
