<dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
    <table
        *ngrxLet="{ changeLog: changeLog$, expandedElement: expandedElementId$ } as vm"
        multiTemplateDataRows
        mat-table
        [dataSource]="vm.changeLog"
        class="dtm-table"
    >
        <ng-container matColumnDef="expandHandle">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button type="button" class="button-icon" (click)="$event.stopPropagation(); toggleExpandableRow(element.id)">
                    <dtm-ui-icon
                        name="arrow-right"
                        class="expand-handle"
                        [class.expanded]="element.id === vm.expandedElement"
                    ></dtm-ui-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAudit.changelogList.subjectTypeLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let changeLog">{{ changeLog.subjectType }}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAudit.changelogList.subjectIdentifierLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let changeLog">{{ changeLog.subjectIdentifier }}</td>
        </ng-container>
        <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAudit.changelogList.actionNameLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let changeLog">{{ changeLog.actionName }}</td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAudit.changelogList.createdAtLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let changeLog">{{ changeLog.createdAt | localizeDate }}</td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAudit.changelogList.userNameLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let changeLog">{{ changeLog.userName }}</td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let change" [attr.colspan]="displayedColumns.length">
                <div class="expanded-row-content" *ngIf="change.id === vm.expandedElement" @slideIn>
                    <p>{{ "dtmAdminLibAudit.changelogList.actionDetailsLabel" | transloco }}</p>
                    <pre>{{ change.actionDetails | json }}</pre>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-error
                    *ngIf="changeLogError$ | ngrxPush; else emptyListTemplate"
                    [mode]="ErrorMode.Primary"
                    [errorMessage]="'dtmAdminLibAudit.changelogList.errorMessage' | transloco"
                    (reload)="reload.emit()"
                >
                </dtm-ui-error>
                <ng-template #emptyListTemplate>
                    <dtm-ui-no-results></dtm-ui-no-results>
                </ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
                </dtm-ui-pagination>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-footer-row *matFooterRowDef="['noResults']" [class.hide-footer]="vm.changeLog.length" class="no-results-row">
            <dtm-ui-no-results></dtm-ui-no-results>
        </tr>
        <tr
            mat-row
            class="change-row"
            (click)="toggleExpandableRow(change.id)"
            *matRowDef="let change; columns: displayedColumns"
            [class.expanded]="(expandedElementId$ | ngrxPush) === change.id"
        ></tr>
        <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!vm.changeLog.length" class="pagination-row"></tr>
    </table>
</dtm-ui-loader-container>
