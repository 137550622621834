<dtm-ui-lib-wizard-header [wizardId]="CROSS_BORDER_PERMIT_WIZARD_ID">
    <dtm-ui-lib-wizard-step-header [stepId]="CrossBorderPermitWizardSteps.BasicInfo">
        {{ "dtmAdminLibPermits.crossBorderAndSpecificPermitPermitFlightWizardHeader.restrictionsStepTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="CrossBorderPermitWizardSteps.UavDetails">
        {{ "dtmAdminLibPermits.crossBorderAndSpecificPermitPermitFlightWizardHeader.uavDetailsStepTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="CrossBorderPermitWizardSteps.Summary">
        {{ "dtmAdminLibPermits.crossBorderAndSpecificPermitPermitFlightWizardHeader.summaryStepTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
</dtm-ui-lib-wizard-header>
