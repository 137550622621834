import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Administrator } from "../../../models/operator.models";

interface AdministratorListComponentState {
    administrators: Administrator[];
}

@Component({
    selector: "dtm-admin-lib-administrator-list[administrators]",
    templateUrl: "./administrator-list.component.html",
    styleUrls: ["./administrator-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdministratorListComponent {
    @Input() public set administrators(value: Administrator[]) {
        this.localStore.patchState({
            administrators: value,
        });
    }

    protected readonly administrators$ = this.localStore.selectByKey("administrators");

    constructor(private readonly localStore: LocalComponentStore<AdministratorListComponentState>) {
        this.localStore.setState({
            administrators: [],
        });
    }
}
