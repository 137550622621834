import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { ExamResult, ExamResultListWithPages, ExamResultsError } from "../models/exam-results.models";
import {
    ExamTrainingResultsErrorType,
    ExamTrainingResultsReportDetails,
    ExamTrainingResultsReportType,
} from "../models/exam-training-results.models";
import { TrainingResult, TrainingResultListWithPages, TrainingResultsError } from "../models/training-results.models";
import { ExamTrainingResultsApiService } from "../services/exam-training-results-api.service";
import { ExamTrainingResultsActions } from "./exam-training-results.actions";

interface ExamTrainingResultsStateModel {
    examResultsList: ExamResult[] | undefined;
    examResultsListError: ExamResultsError | undefined;
    isExamResultsListProcessing: boolean;
    examResultsPages: Page | undefined;
    examTrainingResultError: ExamTrainingResultsErrorType | undefined;
    isExamTrainingResultProcessing: boolean;
    trainingResultsList: TrainingResult[] | undefined;
    trainingResultsListError: TrainingResultsError | undefined;
    isTrainingResultsListProcessing: boolean;
    trainingResultsPages: Page | undefined;
    examTrainingResultsReportDetails: ExamTrainingResultsReportDetails | undefined;
    examTrainingResultsReportDetailsError: ExamTrainingResultsErrorType | undefined;
    isExamTrainingResultsReportDetailsProcessing: boolean;
    selectedExamTrainingResultsReportType: ExamTrainingResultsReportType | undefined;
}

const defaultState: ExamTrainingResultsStateModel = {
    examResultsList: undefined,
    examResultsListError: undefined,
    isExamResultsListProcessing: false,
    examResultsPages: undefined,
    examTrainingResultError: undefined,
    isExamTrainingResultProcessing: false,
    trainingResultsList: undefined,
    trainingResultsListError: undefined,
    isTrainingResultsListProcessing: false,
    trainingResultsPages: undefined,
    examTrainingResultsReportDetails: undefined,
    examTrainingResultsReportDetailsError: undefined,
    isExamTrainingResultsReportDetailsProcessing: false,
    selectedExamTrainingResultsReportType: undefined,
};

@State<ExamTrainingResultsStateModel>({
    name: "examTrainingResults",
    defaults: defaultState,
})
@Injectable()
export class ExamTrainingResultsState {
    constructor(private readonly resultsApiService: ExamTrainingResultsApiService) {}

    @Selector()
    public static examResultsList(state: ExamTrainingResultsStateModel): ExamResult[] | undefined {
        return state.examResultsList;
    }

    @Selector()
    public static examResultsListError(state: ExamTrainingResultsStateModel): ExamResultsError | undefined {
        return state.examResultsListError;
    }

    @Selector()
    public static examResultsPages(state: ExamTrainingResultsStateModel): Page | undefined {
        return state.examResultsPages;
    }

    @Selector()
    public static isExamResultsListProcessing(state: ExamTrainingResultsStateModel): boolean {
        return state.isExamResultsListProcessing;
    }

    @Selector()
    public static isExamTrainingResultProcessing(state: ExamTrainingResultsStateModel): boolean {
        return state.isExamTrainingResultProcessing;
    }

    @Selector()
    public static examTrainingResultError(state: ExamTrainingResultsStateModel): ExamTrainingResultsErrorType | undefined {
        return state.examTrainingResultError;
    }

    @Selector()
    public static trainingResultsList(state: ExamTrainingResultsStateModel): TrainingResult[] | undefined {
        return state.trainingResultsList;
    }

    @Selector()
    public static trainingResultsListError(state: ExamTrainingResultsStateModel): TrainingResultsError | undefined {
        return state.trainingResultsListError;
    }

    @Selector()
    public static trainingResultsPages(state: ExamTrainingResultsStateModel): Page | undefined {
        return state.trainingResultsPages;
    }

    @Selector()
    public static isTrainingResultsListProcessing(state: ExamTrainingResultsStateModel): boolean {
        return state.isTrainingResultsListProcessing;
    }

    @Selector()
    public static examTrainingResultsReportDetails(state: ExamTrainingResultsStateModel): ExamTrainingResultsReportDetails | undefined {
        return state.examTrainingResultsReportDetails;
    }

    @Selector()
    public static examTrainingResultsReportDetailsError(state: ExamTrainingResultsStateModel): ExamTrainingResultsErrorType | undefined {
        return state.examTrainingResultsReportDetailsError;
    }

    @Selector()
    public static isExamTrainingResultsReportDetailsProcessing(state: ExamTrainingResultsStateModel): boolean {
        return state.isExamTrainingResultsReportDetailsProcessing;
    }

    @Selector()
    public static selectedExamTrainingResultsReportType(state: ExamTrainingResultsStateModel): ExamTrainingResultsReportType | undefined {
        return state.selectedExamTrainingResultsReportType;
    }

    @Action(ExamTrainingResultsActions.GetExamResultsList)
    public getExamResultsList(context: StateContext<ExamTrainingResultsStateModel>, action: ExamTrainingResultsActions.GetExamResultsList) {
        context.patchState({ isExamResultsListProcessing: true, examResultsListError: undefined });

        return this.resultsApiService.getExamResultsList(action.filtersQuery).pipe(
            tap(({ content, pageSize, pageNumber, totalElements }: ExamResultListWithPages) =>
                context.patchState({
                    examResultsList: content,
                    examResultsPages: {
                        pageSize,
                        pageNumber,
                        totalElements,
                    },
                })
            ),
            catchError((examResultsListError) => {
                context.patchState({ examResultsListError, examResultsList: [] });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isExamResultsListProcessing: false,
                })
            )
        );
    }

    @Action(ExamTrainingResultsActions.GetTrainingResultsList)
    public getTrainingResultsList(
        context: StateContext<ExamTrainingResultsStateModel>,
        action: ExamTrainingResultsActions.GetTrainingResultsList
    ) {
        context.patchState({ isTrainingResultsListProcessing: true, trainingResultsListError: undefined });

        return this.resultsApiService.getTrainingResultsList(action.filtersQuery).pipe(
            tap(({ content, pageSize, pageNumber, totalElements }: TrainingResultListWithPages) =>
                context.patchState({
                    trainingResultsList: content,
                    trainingResultsPages: {
                        pageSize,
                        pageNumber,
                        totalElements,
                    },
                })
            ),
            catchError((trainingResultsListError) => {
                context.patchState({ trainingResultsListError, trainingResultsList: [] });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isTrainingResultsListProcessing: false,
                })
            )
        );
    }

    @Action(ExamTrainingResultsActions.GetExamTrainingResultsReportDetails)
    public getExamTrainingResultsReportDetails(
        context: StateContext<ExamTrainingResultsStateModel>,
        action: ExamTrainingResultsActions.GetExamTrainingResultsReportDetails
    ) {
        context.patchState({ isExamTrainingResultsReportDetailsProcessing: true, examTrainingResultsReportDetailsError: undefined });

        return this.resultsApiService.getExamTrainingResultsReportDetails(action.reportId).pipe(
            tap((examTrainingResultsReportDetails) => context.patchState({ examTrainingResultsReportDetails })),
            catchError((examTrainingResultsReportDetailsError) => {
                context.patchState({ examTrainingResultsReportDetailsError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isExamTrainingResultsReportDetailsProcessing: false,
                })
            )
        );
    }

    @Action(ExamTrainingResultsActions.AddExamTrainingResults)
    public addExamTrainingResults(
        context: StateContext<ExamTrainingResultsStateModel>,
        action: ExamTrainingResultsActions.AddExamTrainingResults
    ) {
        context.patchState({ isExamTrainingResultProcessing: true, examTrainingResultError: undefined });

        return this.resultsApiService.addExamTrainingResults(action.reportId).pipe(
            catchError((examResultError) => {
                context.patchState({ examTrainingResultError: examResultError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isExamTrainingResultProcessing: false,
                })
            )
        );
    }

    @Action(ExamTrainingResultsActions.SetExamTrainingResultsReportType)
    public SetExamTrainingResultsReportType(
        context: StateContext<ExamTrainingResultsStateModel>,
        action: ExamTrainingResultsActions.SetExamTrainingResultsReportType
    ) {
        context.patchState({ selectedExamTrainingResultsReportType: action.reportType });
    }

    @Action(ExamTrainingResultsActions.DeleteExamResults)
    public deleteExamResults(context: StateContext<ExamTrainingResultsStateModel>, action: ExamTrainingResultsActions.DeleteExamResults) {
        context.patchState({ isExamResultsListProcessing: true, examTrainingResultsReportDetailsError: undefined });

        return this.resultsApiService.deleteExamResults(action.reportId).pipe(
            catchError((examTrainingResultsReportDetailsError) => {
                context.patchState({ examTrainingResultsReportDetailsError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isExamResultsListProcessing: false,
                })
            )
        );
    }

    @Action(ExamTrainingResultsActions.DeleteTrainingResults)
    public deleteTrainingResults(
        context: StateContext<ExamTrainingResultsStateModel>,
        action: ExamTrainingResultsActions.DeleteTrainingResults
    ) {
        context.patchState({ isTrainingResultsListProcessing: true, examTrainingResultsReportDetailsError: undefined });

        return this.resultsApiService.deleteTrainingResults(action.reportId).pipe(
            catchError((examTrainingResultsReportDetailsError) => {
                context.patchState({ examTrainingResultsReportDetailsError });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isTrainingResultsListProcessing: false,
                })
            )
        );
    }
}
