import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { MILLISECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { Subject } from "rxjs";
import { NewQualification, OperationScenario } from "../../../models/operator.models";

@Component({
    selector: "dtm-admin-lib-add-qualification",
    templateUrl: "./add-qualification.component.html",
    styleUrls: ["./add-qualification.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddQualificationComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;
    protected readonly operationScenarioFormControl = new UntypedFormControl(null, Validators.required);
    protected readonly expirationDateFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
    protected readonly availableOperationScenarios = this.data.availableOperationScenarios;
    protected readonly addQualificationForm = new UntypedFormGroup({
        operationScenario: this.operationScenarioFormControl,
        expirationDate: this.expirationDateFormControl,
    });
    private readonly newValueSubject = new Subject<NewQualification>();
    public readonly newValue$ = this.newValueSubject.asObservable();
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private readonly data: {
            availableOperationScenarios: OperationScenario[];
        },
        private readonly matDialogRef: MatDialogRef<AddQualificationComponent>,
        private readonly translocoHelper: TranslationHelperService
    ) {}

    protected validateAndSubmit() {
        if (this.addQualificationForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        // TODO: REJ-2772 - Remove after fixing the issue with the date picker and codebase-wide date interpretation
        const expirationDate = new Date(
            this.expirationDateFormControl.value.getTime() -
                this.expirationDateFormControl.value.getTimezoneOffset() * MILLISECONDS_IN_MINUTE
        );

        const newQualification: NewQualification = {
            operationScenarioIds: this.getSelectedOperationScenariosIds(),
            expirationDate: expirationDate.toISOString(),
        };
        this.newValueSubject.next(newQualification);
    }

    protected assignMaxDateFromSelectedValues(values: OperationScenario[]) {
        const minimalValidityScenario = values.reduce((previousValue, currentValue) =>
            previousValue.grantValidityInMonths < currentValue.grantValidityInMonths ? previousValue : currentValue
        );
        this.expirationDateFormControl.enable();
        const maxDate = new Date(new Date().setMonth(new Date().getMonth() + minimalValidityScenario.grantValidityInMonths));
        this.expirationDateFormControl.setValue(maxDate);
    }

    private getSelectedOperationScenariosIds(): string[] {
        const selectedOperationScenarios = this.operationScenarioFormControl.value;

        return selectedOperationScenarios.map((value: OperationScenario) => value.id);
    }
}
