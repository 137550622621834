import { FilterConditions, Sorting } from "../models/mission-search.models";

export namespace MissionSearchActions {
    export class SetFilterConditions {
        public static readonly type = "[MissionSearch] Set filter conditions";

        constructor(public filterConditions: FilterConditions | undefined) {}
    }

    export class SetMissionsOrder {
        public static readonly type = "[MissionSearch] Set missions order";

        constructor(public sorting: Sorting) {}
    }

    export class FetchMissions {
        public static readonly type = "[MissionSearch] Fetch missions";

        constructor(public maxResultsSize?: number) {}
    }

    export class GetMission {
        public static readonly type = "[MissionSearch] Get mission";

        constructor(public missionId: string) {}
    }

    export class GetMissionRoute {
        public static readonly type = "[MissionSearch] Get mission route";

        constructor(public routeId: string) {}
    }

    export class ClearMissionData {
        public static readonly type = "[MissionSearch] Clear mission data";

        constructor() {}
    }
}
