<button type="button" [routerLink]="['/permits']" [queryParams]="{ activeTab: 1 }" class="button-secondary redirect-button">
    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
    {{ "dtmAdminLibPermits.associationPermitDetailsPreview.goBackToListButtonLabel" | transloco }}
</button>
<dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
    <div class="container">
        <dtm-ui-error *ngIf="permitError$ | ngrxPush; else permitDetailsTemplate" (reload)="refreshPermit()"></dtm-ui-error>
        <ng-template #permitDetailsTemplate>
            <div class="grid grid-maxsize-8 grid-maxsize-md-12">
                <mat-card>
                    <h2>{{ "dtmAdminLibPermits.associationPermitDetailsPreview.header" | transloco }}</h2>
                    <dtm-ui-association-permit-preview
                        [permit]="permit$ | ngrxPush"
                        [isCaaContactTileExpanded]="true"
                        [isOperatorInfoTileExpanded]="true"
                        [isPermitDetailsTileExpanded]="true"
                    ></dtm-ui-association-permit-preview>
                </mat-card>
            </div>
        </ng-template>
    </div>
</dtm-ui-loader-container>
