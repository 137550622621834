import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { AuditChangeLog, AuditChangeLogWithPages } from "./models";

export type GetAuditChangeLogResponseBody = PageResponseBody<AuditChangeLogResponseBody>;

interface AuditChangeLogResponseBody extends Omit<AuditChangeLog, "createdAt"> {
    createdAt: string;
}

export function convertGetAuditChangeLogResponseBodyToChangeLogWithPages(response: GetAuditChangeLogResponseBody): AuditChangeLogWithPages {
    return {
        content: response.content.map((change) => {
            let actionDetailsObject = change.actionDetails;
            try {
                actionDetailsObject = JSON.parse(change.actionDetails);
            } catch (error) {
                console.error(error);
            }

            return {
                subjectType: change.subjectType,
                subjectIdentifier: change.subjectIdentifier,
                id: change.id,
                actionName: change.actionName,
                actionDetails: actionDetailsObject,
                createdAt: new Date(change.createdAt),
                userName: change.userName,
            };
        }),
        pageSize: response.size,
        totalElements: response.totalElements,
        pageNumber: response.number,
    };
}
