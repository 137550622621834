<dtm-ui-editable-card
    class="editable-card"
    [label]="'dtmAdminLibUserProfile.personalData.titleLabel' | transloco"
    editButtonIcon="edit"
    [canLeaveEditModeOnSave]="personalDataFormGroup.valid"
    (actionSave)="save()"
    (editModeChange)="changeEditMode($event)"
>
    <div class="preview-data-info-container" previewContent *ngrxLet="{ firstName: firstName$, lastName: lastName$ } as vm">
        <dtm-ui-label-value
            [label]="'dtmAdminLibUserProfile.personalData.nameLabel' | transloco"
            [value]="vm.firstName + ' ' + vm.lastName"
        ></dtm-ui-label-value>
    </div>
    <ng-container editableContent>
        <form [formGroup]="personalDataFormGroup">
            <dtm-ui-input-field class="input-field">
                <label>
                    {{ "dtmAdminLibUserProfile.personalData.firstNameLabel" | transloco }}
                </label>
                <input matInput formControlName="firstName" />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="personalDataFormGroup.controls.firstName; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="personalDataFormGroup.controls.firstName; name: 'maxlength'; error as error">
                    {{ "dtmAdminLibUserProfile.personalData.firstNameLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field class="input-field">
                <label>
                    {{ "dtmAdminLibUserProfile.personalData.lastNameLabel" | transloco }}
                </label>
                <input matInput formControlName="lastName" />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="personalDataFormGroup.controls.lastName; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
                <div class="field-error" *dtmUiFieldHasError="personalDataFormGroup.controls.lastName; name: 'maxlength'; error as error">
                    {{ "dtmAdminLibUserProfile.personalData.lastNameLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
        </form>
    </ng-container>
</dtm-ui-editable-card>
<ng-template #requiredErrorTemplate>
    {{ "dtmAdminLibUserProfile.personalData.requiredFieldError" | transloco }}
</ng-template>
