import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { FILES_UPLOAD_API_PROVIDER, UploadedFile } from "@dtm-frontend/shared/ui";
import { BYTES_IN_MEGABYTE } from "@dtm-frontend/shared/utils";
import { Subject } from "rxjs";
import { EXAM_TRAINING_RESULTS_ENDPOINTS } from "../../exam-training-results.tokens";
import { ReportsUploadApiService } from "../../services/reports-upload/reports-upload-api.service";
import { REPORT_API_ENDPOINTS } from "../../services/reports-upload/reports-upload-api.token";
import { reportApiFactory } from "../../utils/report-api-factory";

const ALLOWED_FILE_EXTENSION = [".xlsx"];

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * BYTES_IN_MEGABYTE;

@Component({
    selector: "dtm-admin-lib-exam-training-results-upload-file-dialog",
    templateUrl: "./exam-training-results-upload-file-dialog.component.html",
    styleUrls: ["./exam-training-results-upload-file-dialog.component.scss"],
    providers: [
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: ReportsUploadApiService },
        {
            provide: REPORT_API_ENDPOINTS,
            useFactory: reportApiFactory,
            deps: [EXAM_TRAINING_RESULTS_ENDPOINTS],
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExamTrainingResultsUploadFileDialogComponent {
    private readonly newValueSubject = new Subject<string | undefined | null>();
    public readonly newValue$ = this.newValueSubject.asObservable();

    protected readonly fileControl = new FormControl<UploadedFile[] | undefined>(undefined, {
        validators: [Validators.required, Validators.maxLength(1)],
    });

    protected readonly ALLOWED_FILE_EXTENSION = ALLOWED_FILE_EXTENSION;
    protected readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;

    protected save() {
        if (this.fileControl.invalid) {
            this.fileControl.markAllAsTouched();

            return;
        }

        this.newValueSubject.next(this.fileControl.value?.[0].id);
    }

    protected cancel() {
        if (this.fileControl.value) {
            this.newValueSubject.next(null);
        } else {
            this.newValueSubject.next(undefined);
        }
    }
}
