import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { TrainingCenterDialogData } from "../../models/training-centers.models";

@Component({
    selector: "dtm-admin-lib-training-centers-preview-dialog",
    templateUrl: "./training-centers-preview-dialog.component.html",
    styleUrls: ["./training-centers-preview-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingCentersPreviewDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: TrainingCenterDialogData,
        public readonly dialog: MatDialogRef<TrainingCentersPreviewDialogComponent>
    ) {}
}
