import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { RouterLink } from "@angular/router";
import { SharedMissionModule } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { WizardModule } from "@dtm-frontend/shared/ui/wizard";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { AngularCesiumModule } from "@pansa/ngx-cesium";
import { SharedModule } from "../shared/shared.module";
import { AssociationPermitDetailsComponent } from "./components/association-permit-details/association-permit-details.component";
import { AssociationPermitRequestHeaderComponent } from "./components/association-permit-request-wizard/association-permit-request-header/association-permit-request-header.component";
import { AssociationPermitRequestWizardContentComponent } from "./components/association-permit-request-wizard/association-permit-request-wizard-content/association-permit-request-wizard-content.component";
import { AssociationPermitOperationTypeControlComponent } from "./components/association-permit-request-wizard/steps/association-permit-operation-type-control/association-permit-operation-type-control.component";
import { AssociationPermitRequestBasicDetailsStepComponent } from "./components/association-permit-request-wizard/steps/association-permit-request-basic-details-step/association-permit-request-basic-details-step.component";
import { AssociationPermitRequestPermitDetailsComponent } from "./components/association-permit-request-wizard/steps/association-permit-request-permit-details/association-permit-request-permit-details.component";
import { AssociationPermitRequestSummaryComponent } from "./components/association-permit-request-wizard/steps/association-permit-request-summary/association-permit-request-summary.component";
import { CrossBorderPermitRequestHeaderComponent } from "./components/cross-border-permit-request-wizard/cross-border-permit-request-header/cross-border-permit-request-header.component";
import { CrossBorderPermitRequestWizardComponent } from "./components/cross-border-permit-request-wizard/cross-border-permit-request-wizard.component";
import { CrossBorderPermitBasicDataComponent } from "./components/cross-border-permit-request-wizard/steps/cross-border-permit-basic-data/cross-border-permit-basic-data.component";
import { CrossBorderPermitSummaryComponent } from "./components/cross-border-permit-request-wizard/steps/cross-border-permit-summary/cross-border-permit-summary.component";
import { OperatorContactPersonControlComponent } from "./components/operator-contact-person-control/operator-contact-person-control.component";
import { PermitsContainerComponent } from "./components/permits-container/permits-container.component";
import { PermitsFiltersComponent } from "./components/permits-filters/permits-filters.component";
import { AdditionalAccidentTypeFormComponent } from "./components/shared-form-section-components/additional-accident-type-form/additional-accident-type-form.component";
import { AirRiskMitigationFormComponent } from "./components/shared-form-section-components/air-risk-mitigation-form/air-risk-mitigation-form.component";
import { ConfirmTileControlComponent } from "./components/shared-form-section-components/confirm-tile-control/confirm-tile-control.component";
import { ContainmentLevelReachedControlComponent } from "./components/shared-form-section-components/containment-level-reached-control/containment-level-reached-control.component";
import { FinalRiskClassAreaFormComponent } from "./components/shared-form-section-components/final-risk-class-area-form/final-risk-class-area-form.component";
import { GroundRiskCharacteristicFormComponent } from "./components/shared-form-section-components/ground-risk-charecteristic-form/ground-risk-characteristic-form.component";
import { GroundRiskMitigationFormComponent } from "./components/shared-form-section-components/ground-risk-mitigation-form/ground-risk-mitigation-form.component";
import { KmlPreviewDialogComponent } from "./components/shared-form-section-components/kml-preview-dialog/kml-preview-dialog.component";
import { OperationRestrictionsFormComponent } from "./components/shared-form-section-components/operation-restrictions-form/operation-restrictions-form.component";
import { OperatorInfoPanelComponent } from "./components/shared-form-section-components/operator-info-panel/operator-info-panel.component";
import { PermitUavDetailsStepComponent } from "./components/shared-form-section-components/permit-uav-details-step/permit-uav-details-step.component";
import { SpecificPermitDetailsComponent } from "./components/specific-permit-details/specific-permit-details.component";
import { SpecificPermitFlightRequestContentComponent } from "./components/specific-permit-flight-wizard-request/specific-permit-flight-request-content/specific-permit-flight-request-content.component";
import { SpecificPermitFlightRequestHeaderComponent } from "./components/specific-permit-flight-wizard-request/specific-permit-flight-request-header/specific-permit-flight-request-header.component";
import { SpecificPermitBasicDataStepComponent } from "./components/specific-permit-flight-wizard-request/steps/specific-permit-basic-data-step/specific-permit-basic-data-step.component";
import { SpecificPermitSummaryStepComponent } from "./components/specific-permit-flight-wizard-request/steps/specific-permit-summary-step/specific-permit-summary-step.component";
import { PermitStatusBadgeComponent } from "./components/specific-permits-list/permit-status-badge/permit-status-badge.component";
import { SpecificPermitsListComponent } from "./components/specific-permits-list/specific-permits-list.component";
import { PERMITS_ENDPOINTS } from "./permits.tokens";
import { PermitsApiService } from "./services/permits-api.service";
import { PermitsState } from "./state/permits.state";

@NgModule({
    declarations: [
        PermitsContainerComponent,
        PermitsFiltersComponent,
        SpecificPermitsListComponent,
        PermitStatusBadgeComponent,
        SpecificPermitFlightRequestContentComponent,
        SpecificPermitFlightRequestHeaderComponent,
        SpecificPermitBasicDataStepComponent,
        PermitUavDetailsStepComponent,
        OperationRestrictionsFormComponent,
        ConfirmTileControlComponent,
        OperatorInfoPanelComponent,
        GroundRiskCharacteristicFormComponent,
        FinalRiskClassAreaFormComponent,
        GroundRiskMitigationFormComponent,
        AirRiskMitigationFormComponent,
        ContainmentLevelReachedControlComponent,
        AdditionalAccidentTypeFormComponent,
        SpecificPermitSummaryStepComponent,
        SpecificPermitDetailsComponent,
        KmlPreviewDialogComponent,
        OperatorContactPersonControlComponent,
        AssociationPermitRequestWizardContentComponent,
        AssociationPermitRequestHeaderComponent,
        AssociationPermitRequestBasicDetailsStepComponent,
        AssociationPermitRequestPermitDetailsComponent,
        AssociationPermitOperationTypeControlComponent,
        AssociationPermitRequestSummaryComponent,
        CrossBorderPermitRequestWizardComponent,
        CrossBorderPermitRequestHeaderComponent,
        AssociationPermitDetailsComponent,
        CrossBorderPermitBasicDataComponent,
        CrossBorderPermitSummaryComponent,
    ],
    imports: [
        CommonModule,
        MatCardModule,
        NgxsModule.forFeature([PermitsState]),
        MatMenuModule,
        LetModule,
        PushModule,
        SharedUiModule,
        MatTabsModule,
        MatInputModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        SharedI18nModule,
        WizardModule,
        RouterLink,
        MatAutocompleteModule,
        MatDialogModule,
        AngularCesiumModule,
        SharedModule,
        SharedMissionModule,
    ],
    exports: [
        PermitStatusBadgeComponent,
        SpecificPermitsListComponent,
        PermitsFiltersComponent,
        SpecificPermitFlightRequestHeaderComponent,
        ConfirmTileControlComponent,
        OperationRestrictionsFormComponent,
        GroundRiskCharacteristicFormComponent,
        FinalRiskClassAreaFormComponent,
        ContainmentLevelReachedControlComponent,
        AirRiskMitigationFormComponent,
        AdditionalAccidentTypeFormComponent,
        OperatorInfoPanelComponent,
        SpecificPermitSummaryStepComponent,
        OperatorContactPersonControlComponent,
        SpecificPermitBasicDataStepComponent,
        AssociationPermitRequestBasicDetailsStepComponent,
        AssociationPermitRequestPermitDetailsComponent,
        AssociationPermitRequestSummaryComponent,
        GroundRiskMitigationFormComponent,
        PermitUavDetailsStepComponent,
        CrossBorderPermitSummaryComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmAdminLibPermits",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: PermitsApiService,
            useValue: undefined,
        },
    ],
})
export class PermitsModule {
    public static forRoot(): ModuleWithProviders<PermitsModule> {
        return {
            ngModule: PermitsModule,
            providers: [PermitsApiService],
        };
    }

    public static forTest(): ModuleWithProviders<PermitsModule> {
        return {
            ngModule: PermitsModule,
            providers: [
                {
                    provide: PermitsApiService,
                    useValue: null,
                },
                {
                    provide: PERMITS_ENDPOINTS,
                    useValue: {},
                },
            ],
        };
    }
}
