<dtm-ui-expandable-panel
    *ngrxLet="{
        collisionMissions: collisionMissions$,
        nearbyMissions: nearbyMissions$,
        isProcessing: isProcessing$,
        selectedOtherMissionId: selectedOtherMissionId$
    } as vm"
    [isExpanded]="isExpanded$ | ngrxPush"
    [hasHeaderSeparator]="false"
>
    <h2 headerSlot class="title" [ngSwitch]="true">
        <dtm-ui-icon
            name="information"
            class="warning"
            *ngSwitchCase="!vm.isProcessing && !!vm.nearbyMissions.length && !vm.collisionMissions.length"
        ></dtm-ui-icon>
        <dtm-ui-icon
            name="error-warning-fill"
            class="error"
            *ngSwitchCase="!vm.isProcessing && !!vm.collisionMissions.length"
        ></dtm-ui-icon>
        <dtm-ui-icon
            name="checkbox-circle-fill"
            *ngSwitchCase="!vm.isProcessing && !vm.collisionMissions.length && !vm.nearbyMissions.length"
        ></dtm-ui-icon>
        <span class="spinner" *ngSwitchCase="vm.isProcessing">
            <mat-spinner diameter="20" color="accent"></mat-spinner>
        </span>

        {{ "dtmSharedMission.trafficAnalysis.trafficAnalysisHeader" | transloco }}
    </h2>
    <div class="content">
        <dtm-ui-loader-container [isShown]="vm.isProcessing">
            <ng-container *ngIf="!vm.isProcessing">
                <ng-container *ngIf="vm.collisionMissions.length">
                    <p class="label">
                        {{ "dtmSharedMission.trafficAnalysis.flightsInConflictLabel" | transloco }}
                    </p>
                    <ul class="mission-list">
                        <li
                            *ngFor="let mission of vm.collisionMissions; let index = index"
                            class="conflicted"
                            [class.selected]="mission.id === vm.selectedOtherMissionId"
                            (click)="toggleSelection(mission.id)"
                        >
                            <ng-container
                                [ngTemplateOutlet]="missionTileTemplate"
                                [ngTemplateOutletContext]="{ $implicit: mission, index: index + 1 }"
                            ></ng-container>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="vm.nearbyMissions.length">
                    <p class="label">
                        {{ "dtmSharedMission.trafficAnalysis.otherFlightsLabel" | transloco }}
                    </p>
                    <ul class="mission-list">
                        <li
                            *ngFor="let mission of vm.nearbyMissions; let index = index"
                            [class.selected]="mission.id === vm.selectedOtherMissionId"
                            (click)="toggleSelection(mission.id)"
                        >
                            <ng-container
                                [ngTemplateOutlet]="missionTileTemplate"
                                [ngTemplateOutletContext]="{ $implicit: mission, index: vm.collisionMissions.length + index + 1 }"
                            ></ng-container>
                        </li>
                    </ul>
                </ng-container>

                <div class="chip-container" *ngIf="!vm.collisionMissions?.length && !vm.nearbyMissions?.length">
                    <div class="chip success">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        <p>
                            {{ "dtmSharedMission.trafficAnalysis.noOtherFlightsInAreaLabel" | transloco }}
                        </p>
                    </div>
                </div>
            </ng-container>
        </dtm-ui-loader-container>
    </div>
</dtm-ui-expandable-panel>

<ng-template #missionTileTemplate let-mission let-index="index">
    <div class="number">#{{ index }}</div>
    <div class="type" [matTooltip]="'dtmSharedMission.trafficAnalysis.missionTypeLabel' | transloco : { value: mission.flightType }">
        <dtm-ui-icon [name]="mission.flightType === MissionType.VLOS ? 'eye' : 'eye-off'"></dtm-ui-icon>
    </div>
    <div class="details">
        <p class="date">{{ mission.flightStartAtMin | localizeFriendlyDate }}</p>
        <p>
            {{ mission.flightStartAtMin | localizeDate : { timeStyle: "short" } }} -
            {{ mission.flightFinishAtMax | localizeDate : { timeStyle: "short" } }}, {{ mission.uavName }}
        </p>
    </div>
    <button type="button" class="button-icon zoom-button" (click)="$event.stopPropagation(); toggleZoom(mission.routeId)">
        <dtm-ui-icon name="road-map"></dtm-ui-icon>
    </button>
</ng-template>
