import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged, first } from "rxjs/operators";
import { NULL_VALUE } from "../../../shared/models/shared.models";
import { PermitFilterParams, PermitStatus, PermitsFilterFormKeys } from "../../models/permits.models";

const FILTER_DEBOUNCE_TIME = 300;

interface PermitsFiltersForm {
    searchByText: FormControl<string>;
    status: FormControl<PermitStatus[] | null>;
    dateFrom: FormControl<Date | null>;
    dateTo: FormControl<Date | null>;
}

interface FiltersComponentState {
    initialFilters: PermitFilterParams | undefined;
    appliedFiltersLength: number | undefined;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: PermitsFilterFormKeys.SearchByText,
        filterLabel: "dtmAdminLibPermits.permitsFilters.searchByTextLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: PermitsFilterFormKeys.Status,
        filterLabel: "dtmAdminLibPermits.permitsFilters.statusLabel",
        filterValueLabel: "dtmAdminLibPermits.permitsFilters.statusValueLabel",
    },
    {
        key: PermitsFilterFormKeys.DateFrom,
        filterLabel: "dtmAdminLibPermits.permitsFilters.dateFromLabel",
        type: FilterType.Date,
    },
    {
        key: PermitsFilterFormKeys.DateTo,
        filterLabel: "dtmAdminLibPermits.permitsFilters.dateToLabel",
        type: FilterType.Date,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-permits-filters",
    templateUrl: "./permits-filters.component.html",
    styleUrls: ["./permits-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PermitsFiltersComponent {
    @Input()
    public set initialFilters(value: PermitFilterParams | undefined) {
        this.localStore.patchState({ initialFilters: value });
    }

    @Output() public filtersChange: EventEmitter<Partial<PermitFilterParams>> = new EventEmitter();

    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly permitStatuses = Object.values(PermitStatus).filter((status) => status !== PermitStatus.Waiting);
    protected permitsFiltersForm = new FormGroup<PermitsFiltersForm>({
        searchByText: new FormControl("", { nonNullable: true }),
        status: new FormControl(null),
        dateFrom: new FormControl(null),
        dateTo: new FormControl(null),
    });
    protected readonly appliedFiltersLength$ = this.localStore.selectByKey("appliedFiltersLength");

    constructor(
        private readonly translocoHelper: TranslationHelperService,
        private readonly localStore: LocalComponentStore<FiltersComponentState>
    ) {
        this.localStore.setState({
            appliedFiltersLength: NULL_VALUE,
            initialFilters: undefined,
        });

        this.assignInitialFilters();
        this.watchFormAndEmitFiltersOnChange();
    }

    protected clearFilters() {
        this.permitsFiltersForm.reset();
    }

    private watchFormAndEmitFiltersOnChange(): void {
        this.permitsFiltersForm.valueChanges
            .pipe(debounceTime(FILTER_DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(() => {
                this.setActiveFiltersCount();
                this.filtersChange.emit(this.permitsFiltersForm.getRawValue());
            });
    }

    private assignInitialFilters(): void {
        this.localStore
            .selectByKey("initialFilters")
            .pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
            .subscribe((filters: PermitFilterParams) => {
                this.permitsFiltersForm.patchValue({ ...filters });
                this.setActiveFiltersCount();
            });
    }

    private setActiveFiltersCount(): void {
        this.localStore.patchState({
            appliedFiltersLength: Object.values(this.permitsFiltersForm.getRawValue()).flat().filter(FunctionUtils.isTruthy).length,
        });
    }
}
