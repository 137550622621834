import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperatorDetails, OperatorType, OwnerStatus } from "../../../models/operator.models";

interface OperatorOwnerDetailsComponentState {
    profile: OperatorDetails | undefined;
}

@Component({
    selector: "dtm-admin-lib-owner-tile[profile]",
    templateUrl: "./owner-tile.component.html",
    styleUrls: ["./owner-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OwnerTileComponent {
    @Input() public set profile(value: OperatorDetails | undefined) {
        this.localStore.patchState({ profile: value });
    }

    protected readonly profile$ = this.localStore.selectByKey("profile");
    protected readonly OwnerStatus = OwnerStatus;
    protected readonly OperatorType = OperatorType;

    constructor(private readonly localStore: LocalComponentStore<OperatorOwnerDetailsComponentState>) {
        localStore.setState({ profile: undefined });
    }
}
