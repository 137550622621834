import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { firstValueFrom } from "rxjs";
import { NULL_VALUE, Operator } from "../../../shared";
import { OperatorProfileUpdateHelperService } from "../../services/operator-profile-update-helper.service";
import { OperatorFilters, OperatorsFilterParams } from "../../services/operator.models";
import { OperatorActions } from "../../state/operator.actions";
import { OperatorState } from "../../state/operator.state";
import { DEFAULT_PAGE_SIZE } from "../../utils/default-operator.page";

interface OperatorsContainerState {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    filtersQuery: OperatorFilters | undefined;
    selectedOperator: Operator | undefined;
}

interface OperatorsQueryParams {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    searchByText?: string;
    type?: string;
    isDefaultCountryRegistration?: string;
    status?: string;
    powerOfAttorneyStatus?: string;
    isWaitingCompetencyConfirmation?: boolean | null;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-operators-container",
    templateUrl: "./operators-container.component.html",
    styleUrls: ["./operators-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, OperatorProfileUpdateHelperService],
})
export class OperatorsContainerComponent implements OnInit, OnDestroy {
    protected readonly pages$ = this.store.select(OperatorState.pages);
    protected readonly operators$ = this.store.select(OperatorState.operatorList);
    protected readonly operatorListError$ = this.store.select(OperatorState.operatorListError);
    protected readonly operatorError$ = this.store.select(OperatorState.operatorError);
    protected readonly selectedOperatorDetails$ = this.store.select(OperatorState.selectedOperatorDetails);
    protected readonly isProcessing$ = this.store.select(OperatorState.isProcessing);
    protected readonly filtersQuery$ = this.localStore.selectByKey("filtersQuery").pipe(RxjsUtils.filterFalsy());

    constructor(
        private readonly store: Store,
        private readonly bottomSheet: MatBottomSheet,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<OperatorsContainerState>
    ) {
        localStore.setState({
            [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? DEFAULT_PAGE_SIZE,
            filtersQuery: {
                searchByText: this.route.snapshot.queryParams.searchByText,
                isDefaultCountryRegistration: this.route.snapshot.queryParams.isDefaultCountryRegistration ?? NULL_VALUE,
                type: this.route.snapshot.queryParams.type ?? null,
                status: this.route.snapshot.queryParams?.status?.split(","),
                powerOfAttorneyStatus: this.route.snapshot.queryParams?.powerOfAttorneyStatus ?? null,
                isWaitingCompetencyConfirmation: this.route.snapshot.queryParams?.isWaitingCompetencyConfirmation ?? null,
            },
            selectedOperator: undefined,
        });
    }

    public ngOnDestroy() {
        this.store.dispatch(new OperatorActions.ClearOperatorDetails());
        this.bottomSheet.dismiss();
    }

    public ngOnInit() {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: OperatorsFilterParams) => {
            this.store.dispatch(new OperatorActions.GetOperators(queryParams));
        });
        this.store.dispatch(new OperatorActions.GetCapabilities());
    }

    protected changePage(event: PageEvent) {
        this.localStore.patchState({
            [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: event.pageSize,
            selectedOperator: undefined,
        });

        this.navigateByParams();
    }

    protected navigateByParams(shouldResetPage?: boolean) {
        const pageIndex = shouldResetPage ? 0 : this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM);
        const searchByText = this.localStore.selectSnapshotByKey("filtersQuery")?.searchByText;
        const isDefaultCountryRegistration = this.localStore.selectSnapshotByKey("filtersQuery")?.isDefaultCountryRegistration;
        const type = this.localStore.selectSnapshotByKey("filtersQuery")?.type;
        const status = this.localStore.selectSnapshotByKey("filtersQuery")?.status?.join(",");
        const powerOfAttorneyStatus = this.localStore.selectSnapshotByKey("filtersQuery")?.powerOfAttorneyStatus;
        const isWaitingCompetencyConfirmation = this.localStore.selectSnapshotByKey("filtersQuery")?.isWaitingCompetencyConfirmation;

        if (shouldResetPage) {
            this.localStore.patchState({ [PAGE_NUMBER_QUERY_PARAM]: pageIndex });
        }

        let params: OperatorsQueryParams = {
            [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
        };

        if (searchByText) {
            params = { ...params, searchByText: searchByText };
        }

        if (isDefaultCountryRegistration) {
            params = { ...params, isDefaultCountryRegistration };
        }

        if (type) {
            params = { ...params, type };
        }

        if (status) {
            params = { ...params, status };
        }

        if (powerOfAttorneyStatus) {
            params = { ...params, powerOfAttorneyStatus };
        }

        if (isWaitingCompetencyConfirmation !== NULL_VALUE) {
            params = { ...params, isWaitingCompetencyConfirmation };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    protected refreshList() {
        this.store.dispatch(new OperatorActions.GetOperators(this.route.snapshot.queryParams));
    }

    protected async refreshOperatorDetails() {
        const selectedOperator = await firstValueFrom(this.localStore.selectByKey("selectedOperator").pipe(untilDestroyed(this)));

        if (!selectedOperator) {
            return;
        }

        this.operatorSelected(selectedOperator);
    }

    protected operatorSelected(operator: Operator) {
        this.localStore.patchState({ selectedOperator: operator });
        this.store.dispatch(new OperatorActions.SelectOperator(operator.id, operator.type));
    }

    protected applyFilters(filters: OperatorFilters) {
        this.localStore.patchState({ filtersQuery: filters });
        this.navigateByParams(true);
    }
}
