<dtm-ui-wizard-step-wrapper
    *ngrxLet="{ existingPermitDetails: existingPermitDetails$, isProcessing: isProcessing$, areUavsAvailable: areUavsAvailable$ } as vm"
    (next)="validateFormAndGoToNextStep()"
    [title]="'dtmAdminLibPermits.crossBorderPermitRequest.basicDataHeader' | transloco"
    [stepsAmount]="3"
    [stepNumber]="1"
    [isBackButtonVisible]="!!vm.existingPermitDetails"
    (back)="goBack()"
    dtmUiInvalidFormScrollable
>
    <dtm-ui-loader-container [isShown]="vm.isProcessing">
        <ng-container [formGroup]="basicDataForm">
            <div class="contact-person-preview">
                <dtm-ui-select-field [compareWith]="caaUserCompare" formControlName="caaContactUser" [isClearable]="false">
                    <label>{{ "dtmAdminLibPermits.basicData.caaContactPersonSearchLabel" | transloco }}</label>
                    <dtm-ui-select-option *ngFor="let caaUser of caaContactUsers$ | ngrxPush" [value]="caaUser">
                        {{ caaUser.firstName }} {{ caaUser.lastName }}
                    </dtm-ui-select-option>
                    <div class="field-error" *dtmUiFieldHasError="basicDataForm.controls.caaContactUser; name: 'required'">
                        {{ "dtmAdminLibPermits.container.requiredField" | transloco }}
                    </div>
                </dtm-ui-select-field>
                <div class="caa-contact-user-info" @slideIn *ngIf="basicDataForm.controls.caaContactUser.value as caaContactUser">
                    <h2 class="contact-person-header">{{ "dtmAdminLibPermits.basicData.caaUserContactHeader" | transloco }}</h2>
                    <dtm-ui-permit-contact-person-preview [contactPerson]="caaContactUser"></dtm-ui-permit-contact-person-preview>
                </div>
            </div>
            <dtm-admin-lib-operator-search-autocomplete-control
                formControlName="operator"
                (searchTextChange)="searchTextChanged.emit($event)"
                [options]="operators$ | ngrxPush"
            >
                <label>{{ "dtmAdminLibPermits.findOperator.controlLabel" | transloco }}</label>
                <div class="field-error" *dtmUiFieldHasError="basicDataForm.controls.operator; name: 'required'">
                    {{ "dtmAdminLibPermits.container.requiredField" | transloco }}
                </div>
            </dtm-admin-lib-operator-search-autocomplete-control>
            <ng-container *ngIf="basicDataForm.controls.operator.value">
                <dtm-ui-info-message type="error" *ngIf="!vm.areUavsAvailable && !vm.isProcessing" class="no-available-uavs-info">
                    {{ "dtmAdminLibPermits.basicData.noAvailableUavModelsError" | transloco }}
                </dtm-ui-info-message>
                <h2 class="section-title">{{ "dtmAdminLibPermits.basicData.operatorContactSectionTitle" | transloco }}</h2>
                <dtm-admin-lib-operator-info-panel [operator]="basicDataForm.controls.operator.value"></dtm-admin-lib-operator-info-panel>
                <ng-container *ngIf="areUavsAvailable$ | ngrxPush">
                    <dtm-admin-lib-operator-contact-person-control
                        dtmUiMarkValueAccessorControlsAsTouched
                        formControlName="operatorContact"
                    ></dtm-admin-lib-operator-contact-person-control>

                    <h4 class="section-title">{{ "dtmAdminLibPermits.basicData.restrictionsSectionTitle" | transloco }}</h4>
                    <dtm-admin-lib-operation-restrictions-form
                        dtmUiMarkValueAccessorControlsAsTouched
                        formControlName="operationRestrictions"
                        [dtmLocations]="dtmLocations$ | ngrxPush"
                        [isPermitNumberDisabled]="!!vm.existingPermitDetails"
                        [isPermitConfirmationNumberEnabled]="true"
                        (kmlFilePreview)="kmlFilePreview.emit($event)"
                    ></dtm-admin-lib-operation-restrictions-form>
                    <h5 class="section-subtitle">{{ "dtmAdminLibPermits.basicData.groundRiskCharacteristicSubtitle" | transloco }}</h5>
                    <dtm-admin-lib-ground-risk-characteristic-form
                        [riskMethodology]="basicDataForm.controls.operationRestrictions.value?.riskMethodology"
                        dtmUiMarkValueAccessorControlsAsTouched
                        formControlName="groundRiskCharacteristic"
                    ></dtm-admin-lib-ground-risk-characteristic-form>
                    <h5 class="section-subtitle">{{ "dtmAdminLibPermits.basicData.finalRiskClassSubtitle" | transloco }}</h5>
                    <dtm-admin-lib-final-risk-class-area
                        dtmUiMarkValueAccessorControlsAsTouched
                        formControlName="finalRiskArea"
                    ></dtm-admin-lib-final-risk-class-area>
                    <h5 class="section-subtitle">{{ "dtmAdminLibPermits.basicData.groundRiskMitigationMeasureSubtitle" | transloco }}</h5>
                    <dtm-admin-lib-ground-risk-mitigation-form
                        [riskMethodology]="basicDataForm.controls.operationRestrictions.value?.riskMethodology"
                        dtmUiMarkValueAccessorControlsAsTouched
                        formControlName="groundRiskMitigation"
                    ></dtm-admin-lib-ground-risk-mitigation-form>
                    <h5 class="section-subtitle">{{ "dtmAdminLibPermits.basicData.airRiskMitigationMeasureSubtitle" | transloco }}</h5>
                    <dtm-admin-lib-air-risk-mitigation
                        formControlName="airRiskMitigation"
                        dtmUiMarkValueAccessorControlsAsTouched
                        [competencies]="competencies$ | ngrxPush"
                    ></dtm-admin-lib-air-risk-mitigation>
                    <div class="hint">
                        <h5 class="section-subtitle">{{ "dtmAdminLibPermits.basicData.additionalAccidentTypeSubtitle" | transloco }}</h5>
                        <dtm-ui-popover
                            [popoverText]="'dtmAdminLibPermits.basicData.additionalAccidentTypeHint' | transloco"
                        ></dtm-ui-popover>
                    </div>

                    <div class="info-block">
                        {{ "dtmAdminLibPermits.basicData.additionalAccidentDescription" | transloco }}
                        <br />
                        <a [href]="AVIATION_REPORTING_URL" target="_blank" class="link">{{ AVIATION_REPORTING_URL }}</a>
                    </div>
                    <dtm-admin-lib-additional-accident-type-form
                        formControlName="additionalAccidentType"
                        dtmUiMarkValueAccessorControlsAsTouched
                    ></dtm-admin-lib-additional-accident-type-form>
                </ng-container>
            </ng-container>
        </ng-container>
    </dtm-ui-loader-container>
</dtm-ui-wizard-step-wrapper>
