import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { UserProfileState } from "../state/user-profile.state";
import { USER_PROFILE_ENDPOINTS, UserProfileEndpoints } from "../user-profile.tokens";

@Injectable()
export class UserAvatarUploadApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(USER_PROFILE_ENDPOINTS) private readonly endpoints: UserProfileEndpoints,
        private store: Store
    ) {}

    public uploadFile(file: File): Observable<HttpEvent<UploadedFileBody>> {
        const userId = this.store.selectSnapshot(UserProfileState.userId);

        if (!userId) {
            return throwError(() => ({
                type: FileUploadErrorType.Unknown,
            }));
        }

        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .post<UploadedFileBody>(StringUtils.replaceInTemplate(this.endpoints.manageProfileAvatar, { userId }), formData, {
                reportProgress: true,
                responseType: "json",
                observe: "events",
            })
            .pipe(catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error))));
    }

    public getFile(fileId: string) {
        const userId = this.store.selectSnapshot(UserProfileState.userId);
        if (!userId) {
            return throwError(() => ({
                type: FileUploadErrorType.Unknown,
            }));
        }

        return this.httpClient.get(StringUtils.replaceInTemplate(this.endpoints.manageProfileAvatar, { fileId, userId }), {
            responseType: "blob",
            context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
        });
    }

    public getFilesCompressed() {
        return EMPTY;
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }
}
