import { HttpClient, HttpErrorResponse, HttpEvent, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { FileUploadErrorType, FilesUploadApi, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { IDENTITY_DOCUMENT_API_ENDPOINTS, IdentityDocumentApiEndpoints } from "./identity-document-api.token";

@Injectable()
export class IdentityDocumentUploadApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly store: Store,
        @Inject(IDENTITY_DOCUMENT_API_ENDPOINTS)
        private readonly endpoints: IdentityDocumentApiEndpoints
    ) {}

    public uploadFile(file: File): Observable<HttpEvent<UploadedFileBody>> {
        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .post<UploadedFileBody>(
                StringUtils.replaceInTemplate(this.endpoints.uploadFile, {
                    userId: this.store.selectSnapshot(AuthState.userId),
                }),
                formData,
                {
                    reportProgress: true,
                    responseType: "json",
                    observe: "events",
                }
            )
            .pipe(catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error))));
    }

    public getFile(fileId: string) {
        return this.httpClient.get(
            StringUtils.replaceInTemplate(this.endpoints.downloadFile, {
                userId: this.store.selectSnapshot(AuthState.userId),
                identityDocumentId: fileId,
            }),
            {
                responseType: "blob",
            }
        );
    }

    public getFilesCompressed() {
        console.error("Not implemented");

        return EMPTY;
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }
}
