import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { ExamTrainingResultsContainerComponent } from "./components/exam-training-results-container/exam-training-results-container.component";

const routes: Routes = [
    {
        path: "exam-training-results",
        component: ExamTrainingResultsContainerComponent,
        data: { titleTranslationKey: "dtmAdminLibExamTrainingResults.routeTitles.examTrainingResults" },
        canActivate: [DtmRoleGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ExamTrainingResultsRoutingModule {}
