<dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
    <mat-card>
        <h2>{{ "dtmWebAppLibShared.termsOfUseAcceptance.header" | transloco }}</h2>
        <dtm-ui-checkbox-field [formControl]="acceptTermsOfUseControl" required class="checkbox-with-button">
            <span class="label">{{ "dtmWebAppLibShared.termsOfUseAcceptance.acceptLabel" | transloco }}</span>
            <span class="link-text">
                <a
                    [href]="termsOfUseUrl"
                    rel="noopener noreferrer"
                    target="_blank"
                    class="button-link-small"
                    (click)="$event.stopPropagation()"
                    >{{ "dtmWebAppLibShared.termsOfUseAcceptance.termsOfUseLabel" | transloco }}</a
                ><span class="end-of-sentence">.</span>
            </span>
        </dtm-ui-checkbox-field>
        <dtm-ui-form-errors class="preserve-error-space">
            <div class="field-error" *ngIf="acceptTermsOfUseControl.touched && acceptTermsOfUseControl.invalid">
                {{ "dtmWebAppLibShared.termsOfUseAcceptance.acceptanceRequiredError" | transloco }}
            </div>
        </dtm-ui-form-errors>
        <div class="actions">
            <button type="button" class="button-primary" (click)="acceptTermsOfUse()">
                {{ "dtmWebAppLibShared.termsOfUseAcceptance.goToAppLabel" | transloco }}
            </button>
            <button type="button" class="button-secondary" (click)="logout.emit()">
                {{ "dtmWebAppLibShared.termsOfUseAcceptance.logoutLabel" | transloco }}
            </button>
        </div>
    </mat-card>
</dtm-ui-loader-container>
