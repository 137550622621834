import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { AuditChangeLog, AuditChangeLogError } from "../../services/models";

interface ChangeLogListComponentState {
    changeLog: AuditChangeLog[];
    isProcessing: boolean;
    changeLogError: AuditChangeLogError | undefined;
    expandedElementId: string | undefined;
    pagination: Page | undefined;
}

@Component({
    selector: "dtm-admin-lib-change-log-list",
    templateUrl: "./change-log-list.component.html",
    styleUrls: ["./change-log-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class ChangeLogListComponent {
    @Input() public set changeLog(value: AuditChangeLog[] | undefined) {
        this.localStore.patchState({ changeLog: value ?? [] });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set changeLogError(value: AuditChangeLogError | undefined) {
        this.localStore.patchState({ changeLogError: value });
    }

    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Output() public readonly reload = new EventEmitter<void>();
    @Output() public readonly pageChange = new EventEmitter<PageEvent>();

    protected readonly ErrorMode = ErrorMode;
    protected readonly changeLog$ = this.localStore.selectByKey("changeLog");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly changeLogError$ = this.localStore.selectByKey("changeLogError");
    protected readonly expandedElementId$ = this.localStore.selectByKey("expandedElementId");
    protected readonly displayedColumns = ["expandHandle", "type", "name", "event", "date", "user"];

    constructor(private readonly localStore: LocalComponentStore<ChangeLogListComponentState>) {
        localStore.setState({
            changeLog: [],
            isProcessing: false,
            changeLogError: undefined,
            expandedElementId: undefined,
            pagination: undefined,
        });
    }

    protected toggleExpandableRow(id: string): void {
        this.localStore.patchState((state) => ({
            expandedElementId: state.expandedElementId === id ? undefined : id,
        }));
    }
}
