import { ExamTrainingResultsFilterParams, ExamTrainingResultsReportType } from "../models/exam-training-results.models";

export namespace ExamTrainingResultsActions {
    export class GetExamResultsList {
        public static readonly type = "[Results] Get exam results list";
        constructor(public filtersQuery: ExamTrainingResultsFilterParams) {}
    }

    export class GetTrainingResultsList {
        public static readonly type = "[Results] Get training results list";
        constructor(public filtersQuery: ExamTrainingResultsFilterParams) {}
    }

    export class GetExamTrainingResultsReportDetails {
        public static readonly type = "[Results] Get exam training results report details";
        constructor(public reportId: string) {}
    }

    export class AddExamTrainingResults {
        public static readonly type = "[Results] Add exam training results";
        constructor(public reportId: string) {}
    }

    export class SetExamTrainingResultsReportType {
        public static readonly type = "[Results] Set exam training results report type";
        constructor(public reportType: ExamTrainingResultsReportType) {}
    }

    export class DeleteExamResults {
        public static readonly type = "[Results] Delete exam results";
        constructor(public reportId: string) {}
    }

    export class DeleteTrainingResults {
        public static readonly type = "[Results] Delete training results";
        constructor(public reportId: string) {}
    }
}
