import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from "@angular/forms";
import { ContainmentLevelReachedType } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ContainmentLevelReachedControlComponentState {
    isDisabled: boolean;
    levelState: boolean | null;
}

@Component({
    selector: "dtm-admin-lib-containment-level-reached-control",
    templateUrl: "./containment-level-reached-control.component.html",
    styleUrls: ["./containment-level-reached-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ContainmentLevelReachedControlComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ContainmentLevelReachedControlComponent),
            multi: true,
        },
    ],
})
export class ContainmentLevelReachedControlComponent implements ControlValueAccessor, Validator {
    protected readonly levelState$ = this.localStore.selectByKey("levelState");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");

    constructor(private readonly localStore: LocalComponentStore<ContainmentLevelReachedControlComponentState>) {
        this.localStore.setState({
            isDisabled: false,
            levelState: null,
        });
    }

    protected onChange: (levelState: ContainmentLevelReachedType) => void = FunctionUtils.noop;
    protected onTouched: () => void = FunctionUtils.noop;

    public registerOnChange(onChange: (value: ContainmentLevelReachedType) => void) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched;
    }

    public writeValue(levelState: ContainmentLevelReachedType) {
        if (levelState === null) {
            return;
        }

        this.localStore.patchState({ levelState: levelState === ContainmentLevelReachedType.HIGHER });
    }

    public validate(control: AbstractControl) {
        if (control.invalid) {
            return {
                required: true,
            };
        }

        return null;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.localStore.patchState({ isDisabled });
    }

    protected setLevelState(state: boolean): void {
        if (this.localStore.selectSnapshotByKey("levelState") === state) {
            return;
        }

        this.localStore.patchState({ levelState: state });
        this.onChange(state ? ContainmentLevelReachedType.HIGHER : ContainmentLevelReachedType.BASIC);
    }
}
