import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngxs/store";
import { PermitsActions } from "../../state/permits.actions";
import { PermitsState } from "../../state/permits.state";

@Component({
    selector: "dtm-admin-lib-association-permit-details",
    templateUrl: "./association-permit-details.component.html",
    styleUrls: ["./association-permit-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationPermitDetailsComponent implements OnDestroy {
    protected readonly permit$ = this.store.select(PermitsState.associationPermit);
    protected readonly isProcessing$ = this.store.select(PermitsState.isProcessing);
    protected readonly permitError$ = this.store.select(PermitsState.permitError);

    constructor(private readonly store: Store, private readonly route: ActivatedRoute) {}

    public ngOnDestroy() {
        this.store.dispatch(PermitsActions.ClearAssociationPermitDetails);
    }

    protected refreshPermit() {
        this.store.dispatch(new PermitsActions.GetAssociationPermitDetails(this.route.snapshot.params.id));
    }
}
