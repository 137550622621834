<dtm-ui-presentation-tile-with-action
    [titleTile]="'dtmAdminLibShared.operatorProfile.legalGuardian.titleTile' | transloco"
    *ngIf="legalGuardian$ | ngrxPush as legalGuardian"
>
    <ng-container actionButtonSlot>
        <ng-container *ngIf="isActionMenuVisible$ | ngrxPush; else actionMenuNotVisibleTemplate">
            <button class="button-icon" type="button" [matMenuTriggerFor]="menu">
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button type="button" mat-menu-item (click)="legalGuardianDetailsEdit.emit()">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.legalGuardian.editButton" | transloco }}
                </button>
                <button type="button" mat-menu-item (click)="openDocumentRejectDialog()">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.legalGuardian.deleteButton" | transloco }}
                </button>
                <button *ngIf="legalGuardian.signer" type="button" mat-menu-item (click)="legalGuardianSignedDocumentDownload.emit()">
                    <dtm-ui-icon name="download"></dtm-ui-icon>
                    {{ "dtmAdminLibShared.operatorProfile.legalGuardian.downloadSignedDocumentLabel" | transloco }}
                </button>
            </mat-menu>
        </ng-container>
        <ng-template #actionMenuNotVisibleTemplate>
            <button
                *ngIf="isEditButtonVisible$ | ngrxPush"
                class="button-secondary"
                type="button"
                (click)="legalGuardianDetailsEdit.emit()"
            >
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmAdminLibShared.operatorProfile.legalGuardian.editButton" | transloco }}
            </button>
        </ng-template>
    </ng-container>

    <div class="content">
        <span class="chip success">
            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
            {{ "dtmAdminLibShared.operatorProfile.operatorStatus.approved" | transloco }}
        </span>
        <dtm-ui-label-value
            [label]="'dtmAdminLibShared.operatorProfile.legalGuardian.nameLabel' | transloco"
            [value]="legalGuardian.firstName + ' ' + legalGuardian.lastName"
        >
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmAdminLibShared.operatorProfile.legalGuardian.emailAddressLabel' | transloco"
            [value]="legalGuardian.email"
        >
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmAdminLibShared.operatorProfile.legalGuardian.dateOfBirthLabel' | transloco"
            [value]="legalGuardian.dateOfBirth | localizeDate"
        >
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmAdminLibShared.operatorProfile.legalGuardian.telephoneNumberLabel' | transloco"
            [value]="legalGuardian.phoneNumber | formatPhoneNumber"
        ></dtm-ui-label-value>
        <dtm-ui-address-label-value [address]="legalGuardian.address"></dtm-ui-address-label-value>
        <dtm-ui-label-value [label]="'dtmAdminLibShared.operatorProfile.legalGuardian.nationalityLabel' | transloco">
            <dtm-ui-country-display [country]="legalGuardian.nationality"></dtm-ui-country-display>
        </dtm-ui-label-value>

        <ng-container *ngIf="legalGuardian.signer; else noSignatureTemplate">
            <h2 class="signer-title">{{ "dtmAdminLibShared.operatorProfile.legalGuardian.signerTitle" | transloco }}</h2>
            <dtm-ui-label-value
                [label]="'dtmAdminLibShared.operatorProfile.legalGuardian.signerDetailsLabel' | transloco"
                [value]="
                    'dtmAdminLibShared.operatorProfile.legalGuardian.signerDetailsValue'
                        | transloco
                            : {
                                  dateOfBirth: legalGuardian.signer.dateOfBirth | localizeDate,
                                  lastName: legalGuardian.signer.lastName,
                                  firstName: legalGuardian.signer.firstName
                              }
                "
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmAdminLibShared.operatorProfile.legalGuardian.signatureDateLabel' | transloco"
                [value]="legalGuardian.signer.signedAt | localizeDate"
            >
            </dtm-ui-label-value>
        </ng-container>
        <ng-template #noSignatureTemplate>
            <ng-container *ngIf="legalGuardian.dateOfBirth">
                <h2 class="signer-title">{{ "dtmAdminLibShared.operatorProfile.legalGuardian.signerTitle" | transloco }}</h2>
                <span>
                    {{ "dtmAdminLibShared.operatorProfile.legalGuardian.noSignerDetailsValue" | transloco }}
                </span>
            </ng-container>
        </ng-template>
    </div>
</dtm-ui-presentation-tile-with-action>
