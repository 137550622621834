import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ContextOperator, OperatorType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { GlobalCapabilitiesPilot } from "../../models/operator-context.models";

interface UserButtonComponentState {
    operatorContexts: ContextOperator[];
    selectedContext: ContextOperator | undefined;
    isPilot: boolean;
    isAllowedToRegisterOperator: boolean;
    isAllowedToRegisterPilot: boolean;
    isShortMode: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-context-button[operatorContexts][selectedContext]",
    templateUrl: "./operator-context-button.component.html",
    styleUrls: ["./operator-context-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorContextButtonComponent {
    @Input() public set operatorContexts(value: ContextOperator[] | undefined) {
        this.localStore.patchState({ operatorContexts: value ?? [] });
    }
    @Input() public set selectedContext(value: ContextOperator | undefined) {
        this.localStore.patchState({ selectedContext: value });
    }
    @Input() public set pilot(value: GlobalCapabilitiesPilot | undefined) {
        this.localStore.patchState({ isPilot: !!value });
    }
    @Input() public set isAllowedToRegisterOperator(value: BooleanInput) {
        this.localStore.patchState({ isAllowedToRegisterOperator: coerceBooleanProperty(value) });
    }
    @Input() public set isAllowedToRegisterPilot(value: BooleanInput) {
        this.localStore.patchState({ isAllowedToRegisterPilot: coerceBooleanProperty(value) });
    }
    @Input() public set isShortMode(value: BooleanInput) {
        this.localStore.patchState({ isShortMode: coerceBooleanProperty(value) });
    }
    @Output() public operatorSelect = new EventEmitter<ContextOperator>();
    @Output() public logout = new EventEmitter<void>();
    @Output() public redirect = new EventEmitter<string>();

    protected readonly operatorContexts$ = this.localStore.selectByKey("operatorContexts");
    protected readonly selectedContext$ = this.localStore.selectByKey("selectedContext").pipe(RxjsUtils.filterFalsy());
    protected readonly isPilot$ = this.localStore.selectByKey("isPilot");
    protected readonly canRegisterPilot$ = this.localStore.selectByKey("isAllowedToRegisterPilot");
    protected readonly isShortMode$ = this.localStore.selectByKey("isShortMode");
    protected readonly OperatorType = OperatorType;

    constructor(private readonly localStore: LocalComponentStore<UserButtonComponentState>) {
        localStore.setState({
            operatorContexts: [],
            selectedContext: undefined,
            isPilot: false,
            isAllowedToRegisterOperator: false,
            isAllowedToRegisterPilot: false,
            isShortMode: false,
        });
    }

    public selectOperatorContext(operator: ContextOperator) {
        if (operator.id === this.localStore.selectSnapshotByKey("selectedContext")?.id) {
            return;
        }
        this.operatorSelect.emit(operator);
    }
}
