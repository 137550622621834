<div class="wrapper" *ngrxLet="{ missionDates: missionDates$ } as vm">
    <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
    <div class="range">
        <div class="date-range-label">
            <span class="time-label">
                {{ vm.missionDates.startDate | localizeDate : { dateStyle: "short" } }}
            </span>
            -
            <span class="time-label">
                {{ vm.missionDates.endDate | localizeDate : { dateStyle: "short" } }}
            </span>
        </div>
        {{
            "dtmWebAppLibShared.missionDateRange.days"
                | transloco : { days: vm.missionDates.startDate | invoke : getDaysBetween : vm.missionDates.endDate }
        }}
    </div>
</div>
