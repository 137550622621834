import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AssociationPermit, ErrorMode } from "@dtm-frontend/shared/ui";
import { WizardActions } from "@dtm-frontend/shared/ui/wizard";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, combineLatest, map } from "rxjs";
import {
    AssociationPermitWizardBasicDetails,
    AssociationPermitWizardPermitDetails,
    AssociationPermitWizardSteps,
} from "../../../models/association-permit.models";
import { PermitsTab } from "../../../models/permits.models";
import { PermitsActions } from "../../../state/permits.actions";
import { PermitsState } from "../../../state/permits.state";
import { ASSOCIATION_PERMIT_WIZARD_ID } from "../association-permit-wizard.models";

interface AssociationPermitRequestWizardContentComponentState {
    basicData: AssociationPermitWizardBasicDetails | undefined;
    permitDetailsData: AssociationPermitWizardPermitDetails | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-admin-lib-association-permit-request-wizard-content",
    templateUrl: "./association-permit-request-wizard-content.component.html",
    styleUrls: ["../../styles/shared-permit-wizard-content-styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AssociationPermitRequestWizardContentComponent implements OnDestroy {
    protected readonly ASSOCIATION_PERMIT_WIZARD_ID = ASSOCIATION_PERMIT_WIZARD_ID;
    protected readonly AssociationPermitWizardSteps = AssociationPermitWizardSteps;
    protected readonly ErrorMode = ErrorMode;

    protected readonly isProcessing$ = this.store.select(PermitsState.isProcessing);
    protected readonly caaContactUsers$ = this.store.select(PermitsState.caaUsers);
    protected readonly existingAssociationPermit$ = this.store.select(PermitsState.associationPermit);
    protected readonly associationOperators$ = this.store.select(PermitsState.associationOperators);
    protected readonly permit$: Observable<AssociationPermit> = combineLatest([
        this.localStore.selectByKey("basicData").pipe(RxjsUtils.filterFalsy()),
        this.localStore.selectByKey("permitDetailsData").pipe(RxjsUtils.filterFalsy()),
    ]).pipe(map(([basicDetails, permitDetails]) => ({ ...basicDetails, ...permitDetails })));
    protected readonly areErrors$ = combineLatest([
        this.store.select(PermitsState.capabilitiesError),
        this.store.select(PermitsState.operatorListError),
    ]).pipe(map((errors) => errors.some((error) => error)));

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<AssociationPermitRequestWizardContentComponentState>,
        private readonly toastrService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router
    ) {
        localStore.setState({ basicData: undefined, permitDetailsData: undefined });
        this.goToBasicDetailsStep();
    }

    public ngOnDestroy() {
        this.store.dispatch(PermitsActions.ClearAssociationPermitDetails);
    }

    protected retryFetchData() {
        this.store.dispatch(PermitsActions.RetryFetchAssociationPermitRequestData);
    }

    protected searchOperator(searchText: string) {
        this.store.dispatch(new PermitsActions.GetAssociationOperatorList(searchText));
    }

    protected saveBasicDetailsAndGoToNextStep(formValue: AssociationPermitWizardBasicDetails) {
        this.localStore.patchState({ basicData: formValue });
        this.goToPermitDetailsStep();
    }

    protected goBackToList() {
        this.router.navigate(["/permits"], {
            queryParams: { activeTab: PermitsTab.Association },
        });
    }

    protected savePermitDetailsAndGoToNextStep(formValue: AssociationPermitWizardPermitDetails) {
        this.localStore.patchState({ permitDetailsData: formValue });
        this.goToSummary();
    }

    protected savePermit() {
        const basicData = this.localStore.selectSnapshotByKey("basicData");
        const permitDetailsData = this.localStore.selectSnapshotByKey("permitDetailsData");

        if (!basicData || !permitDetailsData) {
            return;
        }
        const permit = {
            ...basicData,
            ...permitDetailsData,
            id: this.store.selectSnapshot(PermitsState.associationPermit)?.id,
        };

        if (permit.id) {
            this.updateSpecificPermit(permit);

            return;
        }
        this.addPermit(permit);
    }

    protected goToStep(step: AssociationPermitWizardSteps) {
        switch (step) {
            case AssociationPermitWizardSteps.BasicInfo:
                this.goToBasicDetailsStep();
                break;
            case AssociationPermitWizardSteps.PermitDetails:
                this.goToPermitDetailsStep();
                break;
            case AssociationPermitWizardSteps.Summary:
                this.goToSummary();
                break;
        }
    }

    protected goToBasicDetailsStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(ASSOCIATION_PERMIT_WIZARD_ID, AssociationPermitWizardSteps.BasicInfo),
            new WizardActions.EnableSteps(ASSOCIATION_PERMIT_WIZARD_ID, [AssociationPermitWizardSteps.BasicInfo]),
        ]);
    }

    protected goToPermitDetailsStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(ASSOCIATION_PERMIT_WIZARD_ID, AssociationPermitWizardSteps.PermitDetails),
            new WizardActions.EnableSteps(ASSOCIATION_PERMIT_WIZARD_ID, [
                AssociationPermitWizardSteps.BasicInfo,
                AssociationPermitWizardSteps.PermitDetails,
            ]),
        ]);
    }

    protected goToSummary() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(ASSOCIATION_PERMIT_WIZARD_ID, AssociationPermitWizardSteps.Summary),
            new WizardActions.EnableSteps(ASSOCIATION_PERMIT_WIZARD_ID, [
                AssociationPermitWizardSteps.BasicInfo,
                AssociationPermitWizardSteps.PermitDetails,
                AssociationPermitWizardSteps.Summary,
            ]),
        ]);
    }

    private addPermit(permit: AssociationPermit) {
        this.store
            .dispatch(new PermitsActions.AddAssociationPermit(permit))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(PermitsState.addPermitError);

                if (error) {
                    this.toastrService.error(this.transloco.translate("dtmAdminLibPermits.addAssociationPermit.errorMessage"));

                    return;
                }

                this.toastrService.success(this.transloco.translate("dtmAdminLibPermits.addAssociationPermit.successMessage"));
                this.goBackToList();
            });
    }

    private updateSpecificPermit(permit: AssociationPermit) {
        this.store
            .dispatch(new PermitsActions.UpdateAssociationPermit(permit))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(PermitsState.updatePermitError);

                if (error) {
                    this.toastrService.error(this.transloco.translate("dtmAdminLibPermits.addAssociationPermit.updatePermitErrorMessage"));

                    return;
                }

                this.toastrService.success(this.transloco.translate("dtmAdminLibPermits.addAssociationPermit.updatePermitSuccessMessage"));
                this.goBackToList();
            });
    }
}
