import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "falsyDash",
})
export class FalsyDashPipe implements PipeTransform {
    public transform(value: string | undefined | null): string {
        return value || "-";
    }
}
