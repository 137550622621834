import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DashboardResolver } from "./services/dashboard.resolver";

const routes: Routes = [
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [DtmRoleGuard],
        data: { titleTranslationKey: "caaAdmin.routeTitles.dashboard" },
        resolve: {
            init: DashboardResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [DashboardResolver],
})
export class DashboardRoutingModule {}
