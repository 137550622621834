<dtm-ui-loader-container *ngrxLet="isUsersListProcessing$ as isUsersListProcessing" [isShown]="isUsersListProcessing">
    <table *ngIf="usersList$ | ngrxPush as users" mat-table [dataSource]="users" class="dtm-table">
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAdminUsers.usersList.firstNameLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAdminUsers.usersList.lastNameLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAdminUsers.usersList.emailLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let user">{{ user.email }}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAdminUsers.usersList.phoneNumberLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let user">{{ user.phoneNumber | formatPhoneNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "dtmAdminLibAdminUsers.usersList.actionsLabel" | transloco }}</th>
            <td mat-cell *matCellDef="let user">
                <button [matMenuTriggerFor]="actions" class="button-icon" type="button">
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>

                <mat-menu #actions="matMenu">
                    <button type="button" mat-menu-item (click)="userEdit.emit(user)">
                        <dtm-ui-icon name="edit"></dtm-ui-icon>
                        {{ "dtmAdminLibAdminUsers.usersList.editUserLabel" | transloco }}
                    </button>
                    <button type="button" mat-menu-item (click)="userDelete.emit(user)">
                        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                        {{ "dtmAdminLibAdminUsers.usersList.deleteUserLabel" | transloco }}
                    </button>
                    <button type="button" mat-menu-item (click)="passwordReset.emit(user)">
                        <dtm-ui-icon name="refresh"></dtm-ui-icon>
                        {{ "dtmAdminLibAdminUsers.usersList.resendPasswordLabel" | transloco }}
                    </button>
                </mat-menu>
            </td>
        </ng-container>
        <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-error
                    *ngIf="usersListError$ | ngrxPush; else emptyListTemplate"
                    [mode]="ErrorMode.Secondary"
                    (reload)="usersListRefresh.emit()"
                >
                </dtm-ui-error>
                <ng-template #emptyListTemplate>
                    <dtm-ui-no-results></dtm-ui-no-results>
                </ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
                </dtm-ui-pagination>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            mat-footer-row
            *matFooterRowDef="['noResults']"
            [class.hide-footer]="users.length || isUsersListProcessing"
            class="no-results-row"
        >
            <dtm-ui-no-results></dtm-ui-no-results>
        </tr>
        <tr mat-row *matRowDef="let operator; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!users.length" class="pagination-row"></tr>
    </table>
</dtm-ui-loader-container>
