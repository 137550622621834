<div class="dialog-header">
    <div mat-dialog-title>{{ "dtmAdminLibCenters.centerFormDialog.examinationEntitiesDataHeader" | transloco }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>

<div mat-dialog-content>
    <div class="preview-container">
        <dtm-ui-label-value [label]="'dtmAdminLibCenters.centerFormDialog.centerNameLabel' | transloco">
            <div class="value-container name">
                {{ data.center?.name }}
            </div>
        </dtm-ui-label-value>

        <div class="card">
            <div class="card-title">{{ "dtmAdminLibCenters.centerFormDialog.basicDataTitle" | transloco }}</div>
            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.identificationNumberLabel' | transloco"
                [value]="data.center?.identificationNumber"
            ></dtm-ui-label-value>

            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.administrativeDecisionLabel' | transloco"
                [value]="data.center?.administrativeDecisionNumber"
            ></dtm-ui-label-value>

            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.emailLabel' | transloco"
                [value]="data.center?.email"
            ></dtm-ui-label-value>

            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.telephoneNumberLabel' | transloco"
                [value]="data.center?.phoneNumber | formatPhoneNumber"
            ></dtm-ui-label-value>

            <dtm-ui-address-label-value [address]="data.center?.address"></dtm-ui-address-label-value>
        </div>

        <div class="card">
            <div class="card-title">{{ "dtmAdminLibCenters.centerFormDialog.permitsTitle" | transloco }}</div>
            <dtm-ui-label-value
                class="conducting-trainings"
                [label]="'dtmAdminLibCenters.centerFormDialog.conductingTrainingsLabel' | transloco"
            >
                <div *ngIf="data.center?.examCodes?.length; else noCodesDataTemplate" class="exams-container">
                    <span *ngFor="let examCode of data?.center?.examCodes || []">
                        {{ examCode | displayExamCode }}
                    </span>
                </div>

                <ng-template #noCodesDataTemplate>-</ng-template>
            </dtm-ui-label-value>

            <dtm-ui-label-value
                [label]="'dtmAdminLibCenters.centerFormDialog.validUntilLabel' | transloco"
                [value]="data.center?.validUntil | localizeDate"
            ></dtm-ui-label-value>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button type="button" class="button-primary action-button" (click)="dialog.close()">
        {{ "dtmAdminLibCenters.centerFormDialog.closeLabel" | transloco }}
    </button>
</div>
