<div *ngIf="profile$ | ngrxPush as profile" class="card">
    <div class="card-title">
        {{ "dtmAdminLibShared.operatorProfile.operatorDetails.titleTile" | transloco }}
    </div>
    <ng-container *ngIf="profile.type !== OperatorType.Personal; else personalOperatorTemplate">
        <dtm-ui-label-value
            *ngIf="profile.operatorInfo.registrationNumber; else nationalCourtRegisterTemplate"
            [label]="'dtmAdminLibShared.operatorProfile.operatorDetails.registrationNumberLabel' | transloco"
            [value]="profile.operatorInfo.registrationNumber"
        ></dtm-ui-label-value>
        <ng-template #nationalCourtRegisterTemplate>
            <dtm-ui-label-value
                *ngIf="profile.operatorInfo.nationalCourtRegister"
                [label]="'dtmAdminLibShared.operatorProfile.operatorDetails.nationalCourtRegisterLabel' | transloco"
                [value]="profile.operatorInfo.nationalCourtRegister"
            ></dtm-ui-label-value>
        </ng-template>
        <dtm-ui-label-value
            *ngIf="profile.operatorInfo.companyNumber"
            [label]="'dtmAdminLibShared.operatorProfile.operatorDetails.companyNumberLabel' | transloco"
            [value]="profile.operatorInfo.companyNumber"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmAdminLibShared.operatorProfile.operatorDetails.nameLabel' | transloco"
            [value]="profile.operatorInfo.name"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmAdminLibShared.operatorProfile.operatorDetails.emailAddressLabel' | transloco"
            [value]="profile.operatorInfo.email"
            [canCopy]="true"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmAdminLibShared.operatorProfile.operatorDetails.telephoneNumberLabel' | transloco"
            [value]="profile.operatorInfo.phoneNumber | formatPhoneNumber"
            [canCopy]="true"
        ></dtm-ui-label-value>
    </ng-container>
    <ng-template #personalOperatorTemplate>
        <dtm-ui-label-value
            [label]="'dtmAdminLibShared.operatorProfile.operatorDetails.personalNameLabel' | transloco"
            [value]="profile.operatorInfo.name"
        ></dtm-ui-label-value>
    </ng-template>
    <dtm-ui-address-label-value [address]="profile.operatorInfo.address"></dtm-ui-address-label-value>
</div>
