import { InjectionToken } from "@angular/core";

export interface OperationalRestrictionsApiEndpoints {
    uploadFile: string;
    downloadFile: string;
}

export const OPERATIONAL_RESTRICTIONS_API_ENDPOINTS = new InjectionToken<OperationalRestrictionsApiEndpoints>(
    "Operational restrictions upload api endpoints"
);
