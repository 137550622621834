import {
    ItineraryEditorType,
    MissionPlanRouteFlightZone,
    MissionPlanRouteSection,
    MissionPlanRouteSegment,
    SectionElementResponseBody,
    Waypoint,
} from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import {
    AuthorityAcceptationItem,
    MissionPhaseExtensions,
    MissionPlanRoute,
    MissionPreviewData,
    MissionProcessingPhase,
} from "../../shared";
import { FilterConditions, Mission, Paging, SearchArea, Sorting } from "../models/mission-search.models";
import {
    AuthorityAcceptation,
    MissionPlanCaaPermitDetailsResponseBody,
    MissionPlanDataResponseBody,
    MissionPlanRequestPayload,
    MissionPlanRouteResponseBody,
    MissionPlansResponseBody,
} from "./mission-search-api.models";

function convertSortingToMissionPlanRequestPayloadPage(sorting: Sorting, paging: Paging): MissionPlanRequestPayload["page"] {
    const sort: Array<{
        property: string;
        direction: "ASC" | "DESC";
    }> = [];

    if (sorting === Sorting.StartDateAsc) {
        sort.push({
            property: "flightStartAtMin",
            direction: "ASC",
        });
    } else if (sorting === Sorting.StartDateDesc) {
        sort.push({
            property: "flightStartAtMin",
            direction: "DESC",
        });
    }

    return {
        sort,
        page: paging.page,
        size: paging.size,
    };
}

export function convertFilterConditionsAndSortingToMissionPlanRequestPayload(
    filterConditions: FilterConditions | undefined,
    sorting: Sorting,
    paging: Paging
): MissionPlanRequestPayload {
    const listRequest: MissionPlanRequestPayload["listRequest"] = {};

    if (filterConditions?.missionId) {
        listRequest.planId = !filterConditions.missionId.id ? filterConditions.missionId.name : filterConditions.missionId.id;
    }

    if (filterConditions?.missionType) {
        listRequest.flightType = [filterConditions.missionType];
    }

    if (filterConditions?.operator) {
        listRequest.operator = filterConditions.operator.name;
    }

    if (filterConditions?.pilot) {
        listRequest.pilot = filterConditions.pilot.name;
    }

    if (filterConditions?.uav) {
        listRequest.uavSerialNumber = filterConditions.uav.name;
    }

    if (filterConditions?.status) {
        listRequest.processingPhase = [filterConditions.status];
    }

    if (filterConditions?.fromDate) {
        listRequest.flightDateFrom = filterConditions.fromDate.toISOString();
    }

    if (filterConditions?.toDate) {
        listRequest.flightDateTo = filterConditions.toDate.toISOString();
    }

    if (filterConditions?.area === SearchArea.Country) {
        // NOTE: Simple geometry that represents Poland
        listRequest.geometry = {
            type: "Polygon",
            coordinates: [
                [
                    /* eslint-disable no-magic-numbers */
                    [14.069638889, 49.0020432310001],
                    [14.069638889, 55.849716667],
                    [24.150833333, 55.849716667],
                    [24.150833333, 49.0020432310001],
                    [14.069638889, 49.0020432310001],
                    /* eslint-enable no-magic-numbers */
                ],
            ],
        };
    } else if (filterConditions?.area === SearchArea.Custom) {
        listRequest.geometry = filterConditions.customArea?.geometry ?? undefined;
    }

    return {
        listRequest,
        page: convertSortingToMissionPlanRequestPayloadPage(sorting, paging),
    };
}

export function convertMissionPlansResponseBodyToMissions(content: MissionPlansResponseBody[]): Mission[] {
    return content.map((item) => ({
        id: item.planId,
        flightStartAt: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightStartAtMin),
        flightStartAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightStartAtMin),
        flightStartAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightStartAtMax),
        flightFinishAt: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightFinishAtMax),
        flightFinishAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightFinishAtMin),
        flightFinishAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(item.flightFinishAtMax),
        isPathBased: item.pathBased,
        flightType: item.flightType,
        status: item.processingPhase,
        dtmNames: item.dtmNames,
        operatorNumber: item.operatorNumber,
        operatorName: item.operatorName,
        pilotNumber: item.pilotNumber,
        pilotName: item.pilotName,
        uavName: item.uavManufacturer + " " + item.uavModel,
        uavSerialNumbers: item.uavSerialNumbers,
    }));
}

function convertAcceptationData(data?: AuthorityAcceptation): AuthorityAcceptationItem | undefined {
    return data
        ? {
              acceptationStatus: data.acceptationStatus,
              comment: data.comment,
              updatedAt: DateUtils.convertStringDateToDateWithoutTimeZone(data.updatedAt),
              isConfirmedByUser: data.confirmedByUser,
          }
        : undefined;
}

export function convertPlanDataResponsesToMissionDetails(
    { plan }: MissionPlanDataResponseBody,
    caaPermitDetailsResponse: MissionPlanCaaPermitDetailsResponseBody
): MissionPreviewData {
    return {
        id: plan.id,
        routeId: plan.route?.id,
        flightType: plan.flightType,
        flightStartAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(plan.flightStartAtMin),
        flightStartAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(plan.flightStartAtMax),
        flightFinishAtMin: DateUtils.convertStringDateToDateWithoutTimeZone(plan.flightFinishAtMin),
        flightFinishAtMax: DateUtils.convertStringDateToDateWithoutTimeZone(plan.flightFinishAtMax),
        phase:
            plan.phase === MissionProcessingPhase.Accepted && plan.uncertainConditions
                ? MissionPhaseExtensions.AcceptedConditionally
                : plan.phase,
        operatorName: caaPermitDetailsResponse.crew.operatorName,
        pilotName: plan.capabilities.pilotName,
        uavName: plan.capabilities.uavName,
        uavSerialNumbers: plan.capabilities.uavSerialNumbers,
        trackersIdentifiers: plan.capabilities.trackersIdentifiers,
        category: plan.category,
        additionalCrew: plan.capabilities.additionalCrew,
        setupName: plan.capabilities.setupName,
        flightPurpose: plan.flightPurpose,
        itineraryEditorType: plan.itinerary?.editorType.toLowerCase() as ItineraryEditorType,
        rejectionJustification: plan.mission?.rejectionJustification,
        rejectedAt: plan.mission?.rejectedAt ? DateUtils.convertStringDateToDateWithoutTimeZone(plan.mission?.rejectedAt) : undefined,
        remarks: {
            authorityAcceptation: {
                dtm: convertAcceptationData(plan.remarks?.dtm),
                utm: convertAcceptationData(plan.remarks?.utm),
                dss: convertAcceptationData(plan.remarks?.dss),
            },
            justification: plan.remarks?.formalJustification
                ? {
                      shouldRequestForPriority: plan.remarks.formalJustification.requestForPriority,
                      reason: plan.remarks.formalJustification.reason,
                      attachmentIds: plan.remarks.formalJustification.attachmentIds ?? [],
                      attachments:
                          plan.remarks.formalJustification.attachments?.map(({ name, fileId }) => ({
                              id: fileId ?? "",
                              name: name ?? "",
                          })) ?? [],
                  }
                : undefined,
        },
    };
}

function parseWaypointResponseDates(waypoint: Waypoint, waypointIndex: number): Waypoint {
    const prefix = "A";

    return {
        ...waypoint,
        name: `${prefix}${waypointIndex}`,
        estimatedArriveAt: {
            min: new Date(waypoint.estimatedArriveAt.min),
            max: new Date(waypoint.estimatedArriveAt.max),
        },
    };
}

function convertSectionElementResponseBodyToMissionPlanRouteSection(sections?: SectionElementResponseBody[]): MissionPlanRouteSection[] {
    let sectionId = 0;

    return (
        sections?.map((section, index) => {
            const flightZone: MissionPlanRouteFlightZone | undefined = !section.flightZone
                ? undefined
                : {
                      center: parseWaypointResponseDates(
                          section.flightZone.center,
                          sections[index - 1]?.segment ? (sectionId += 2) : ++sectionId
                      ),
                      flightArea: section.flightZone.flightArea,
                      safetyArea: section.flightZone.safetyArea,
                      groundArea: section.flightZone.ground?.area,
                      groundAdjacentArea: section.flightZone.groundAdjacent?.area,
                      stopover: section.flightZone.stopover,
                      isRunway: section.flightZone.runway,
                  };

            const segment: MissionPlanRouteSegment | undefined = !section.segment
                ? undefined
                : {
                      fromWaypoint: parseWaypointResponseDates(section.segment.from, ++sectionId),
                      toWaypoint: parseWaypointResponseDates(section.segment.to, sectionId + 1),
                      flightArea: section.segment.flightArea,
                      safetyArea: section.segment.safetyArea,
                      groundArea: section.segment.ground?.area,
                      groundAdjacentArea: section.segment.groundAdjacent?.area,
                      elevationProfile: section.segment.elevationProfile,
                      distance: section.segment.distance,
                      speed: section.segment.speed,
                      duration: section.segment.duration,
                  };

            return { flightZone, segment, isActive: section.active };
        }) ?? []
    );
}

export function convertMissionPlanRouteResponseBodyToMissionPlanRoute(response: MissionPlanRouteResponseBody): MissionPlanRoute {
    return {
        routeId: response.routeId,
        planId: response.planId,
        missionId: response.missionId,
        elevations: response.elevations,
        estimatedDistance: response.estimatedDistance,
        sections: convertSectionElementResponseBodyToMissionPlanRouteSection(response.sections),
        isPathBased: response.pathBased,
    };
}
