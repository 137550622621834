import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { InvalidFormScrollableDirective, PhoneNumber, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import { Observable, Subject } from "rxjs";
import { AdminUser, NewAdminUserValue } from "../../services/models";
import { isConfirmationMailSameAsMail } from "../../utils/is-confirmation-mail-same-as-mail";

const MAX_NAME_LENGTH = 100;

@Component({
    selector: "dtm-admin-lib-add-admin-user-dialog",
    templateUrl: "./admin-user-form-dialog.component.html",
    styleUrls: ["./admin-user-form-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUserFormDialogComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    private readonly newValueSubject = new Subject<NewAdminUserValue>();
    public readonly newValue$ = this.newValueSubject.asObservable();
    protected readonly firstNameControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(MAX_NAME_LENGTH)],
        nonNullable: true,
    });
    protected readonly lastNameControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(MAX_NAME_LENGTH)],
        nonNullable: true,
    });
    protected readonly emailControl = new FormControl<string>("", {
        validators: [Validators.email, Validators.required],
        nonNullable: true,
    });
    protected readonly emailConfirmationControl = new FormControl<string>("", {
        validators: [isConfirmationMailSameAsMail, Validators.required],
        nonNullable: true,
    });
    protected readonly phoneNumberControl = new FormControl<PhoneNumber>(
        {
            number: "",
            countryCode: "PL",
        },
        { validators: requiredValidForSmsPhoneNumberValidator, nonNullable: true }
    );
    protected readonly adminUserForm = new FormGroup({
        firstName: this.firstNameControl,
        lastName: this.lastNameControl,
        email: this.emailControl,
        emailConfirmation: this.emailConfirmationControl,
        phoneNumber: this.phoneNumberControl,
    });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            user?: AdminUser;
            header: string;
            confirmLabel: string;
            isUserProcessing$: Observable<boolean>;
        }
    ) {
        if (data.user) {
            this.adminUserForm.setValue({
                firstName: data.user.firstName,
                lastName: data.user.lastName,
                email: data.user.email,
                emailConfirmation: data.user.email,
                phoneNumber: data.user.phoneNumber,
            });
        }
    }

    protected save() {
        this.adminUserForm.markAllAsTouched();
        if (this.adminUserForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.newValueSubject.next({
            firstName: this.adminUserForm.getRawValue().firstName,
            lastName: this.adminUserForm.getRawValue().lastName,
            phoneNumber: this.adminUserForm.getRawValue().phoneNumber,
            email: this.adminUserForm.getRawValue().email,
        });
    }

    protected validateConfirmationEmail() {
        if (this.emailConfirmationControl.value) {
            this.emailConfirmationControl.updateValueAndValidity();
        }
    }
}
