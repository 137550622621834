<dtm-ui-filters-container *ngrxLet="appliedFiltersLength$ as appliedFiltersLength" [filtersCount]="appliedFiltersLength">
    <form [formGroup]="filtersForm" class="filters-container">
        <dtm-ui-input-field>
            <label>{{ "dtmAdminLibUtmUsers.filters.searchTextLabel" | transloco }}</label>
            <input
                matInput
                type="text"
                formControlName="searchText"
                [placeholder]="'dtmAdminLibUtmUsers.filters.searchTextPlaceholder' | transloco"
            />
            <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
        </dtm-ui-input-field>
        <dtm-ui-select-field [multiple]="true" formControlName="statuses">
            <label>{{ "dtmAdminLibUtmUsers.filters.statusesLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let status of statuses" [value]="status">
                {{ "dtmAdminLibUtmUsers.filters.statusesValue" | transloco : { value: status } }}
            </dtm-ui-select-option>
        </dtm-ui-select-field>
        <dtm-admin-lib-single-select-filter
            [singleSelectFormControl]="filtersForm.controls.isDefaultCountry"
            [label]="'dtmAdminLibUtmUsers.filters.isDefaultCountryLabel' | transloco"
            [trueOptionLabel]="
                'dtmAdminLibUtmUsers.filters.isDefaultCountryValue'
                    | transloco : { value: SINGLE_SELECT_VALUES.True, filterValueLabelParam: defaultCountry }
            "
            [falseOptionLabel]="'dtmAdminLibUtmUsers.filters.isDefaultCountryValue' | transloco : { value: SINGLE_SELECT_VALUES.False }"
            [allOptionLabel]="'dtmAdminLibUtmUsers.filters.isDefaultCountryValue' | transloco : { value: NULL_VALUE }"
        >
        </dtm-admin-lib-single-select-filter>
        <dtm-admin-lib-single-select-filter
            [singleSelectFormControl]="filtersForm.controls.isLegalAge"
            [label]="'dtmAdminLibUtmUsers.filters.isLegalAgeLabel' | transloco"
            [trueOptionLabel]="'dtmAdminLibUtmUsers.filters.isLegalAgeValue' | transloco : { value: SINGLE_SELECT_VALUES.True }"
            [falseOptionLabel]="'dtmAdminLibUtmUsers.filters.isLegalAgeValue' | transloco : { value: SINGLE_SELECT_VALUES.False }"
            [allOptionLabel]="'dtmAdminLibUtmUsers.filters.isLegalAgeValue' | transloco : { value: NULL_VALUE }"
        >
        </dtm-admin-lib-single-select-filter>
        <dtm-admin-lib-single-select-filter
            [singleSelectFormControl]="filtersForm.controls.isWaitingIdentityDocument"
            [label]="'dtmAdminLibUtmUsers.filters.isWaitingIdentityDocumentLabel' | transloco"
            [trueOptionLabel]="
                'dtmAdminLibUtmUsers.filters.isWaitingIdentityDocumentValue' | transloco : { value: SINGLE_SELECT_VALUES.True }
            "
            [falseOptionLabel]="
                'dtmAdminLibUtmUsers.filters.isWaitingIdentityDocumentValue' | transloco : { value: SINGLE_SELECT_VALUES.False }
            "
            [allOptionLabel]="'dtmAdminLibUtmUsers.filters.isWaitingIdentityDocumentValue' | transloco : { value: NULL_VALUE }"
        >
        </dtm-admin-lib-single-select-filter>
        <dtm-admin-lib-single-select-filter
            [singleSelectFormControl]="filtersForm.controls.isWaitingCompletionRequest"
            [label]="'dtmAdminLibUtmUsers.filters.isWaitingCompletionRequestLabel' | transloco"
            [trueOptionLabel]="
                'dtmAdminLibUtmUsers.filters.isWaitingCompletionRequestValue' | transloco : { value: SINGLE_SELECT_VALUES.True }
            "
            [falseOptionLabel]="
                'dtmAdminLibUtmUsers.filters.isWaitingCompletionRequestValue' | transloco : { value: SINGLE_SELECT_VALUES.False }
            "
            [allOptionLabel]="'dtmAdminLibUtmUsers.filters.isWaitingCompletionRequestValue' | transloco : { value: NULL_VALUE }"
        >
        </dtm-admin-lib-single-select-filter>
    </form>
    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [filtersMap]="filtersMap"
        [filtersCount]="appliedFiltersLength"
        [formGroup]="filtersForm"
        [filtersValue]="filtersForm.value"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</dtm-ui-filters-container>
